<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal">({{ listShipmentBagCount }})</span>
        <div class="btn-add group-icon" style="text-align: right">
            <vs-button circle icon flat @click="OpenModalAdd()">
                    <i class="fal fa-plus-circle"></i>
            </vs-button>
            <vs-button circle icon flat @click="OpenModalDelete()">
                    <i class="fal fa-recycle"></i>
            </vs-button>
        </div>
      </h4>
    </div>

    <div class="box" :style="{marginTop: '20px'}">
      <div class="columns">
        <div class="column is-3">
          <div class="control">
            <label class="label">ເລກທີ່ຖົງ</label>
            <input
                class="input" name="search"
                v-model="searchItem"
                placeholder="ກະລຸນາພີມເລກບີນຖົງ..."
                @keyup.enter="FilterByShipmentBagNumber()">
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3" style="margin-top: 3px">
          <vs-button
              v-if="listShipmentBag.length !== 0"
              ref="button" flat
              @click="exportExcel()"
              class="form-btn-loading"
              :loading="loading">
            export excel
          </vs-button>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເລກຖົງ</vs-th>
            <vs-th>ຈໍານວນທີ່ໃຊ້</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in listShipmentBag" :key="index">
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>
              {{ item.bag_number }}
            </vs-td>
            <vs-th> {{ item.current_transit_number }}</vs-th>
            <vs-td>
              {{ la_status[item.status] }}
            </vs-td>
            <vs-td>{{ moment(item.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({ name: 'distribution.center.shipment.bag.reusable.detail',
                            params: { bagNumber: item.bag_number } })">
                  <i class="fad fa-eye"></i>
                </vs-button>
                <vs-button
                    circle icon flat @click="OpenModalEdit(item)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button
                    circle icon flat @click="printBarcode(item)">
                  <i class="fad fa-print"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddParcelBag @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditParcelBag :parcelBag="selectedShipmentBag" @close="close" @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <randomBag @close="close" @success="FetchData()"/>
      </template>
    </ModalDelete>

    <div style="opacity: 0">
      <barcode
          id="barcode"
          ref="barcode"
          :value="qrValue"
          tag="canvas"
          :options="{ displayValue: true, width: 3, height: 85, fontSize:30, background:'white'  }">
      </barcode>
    </div>
  </div>
</template>

<script>
import mixinListShipmentBag from '@/Mixins/Distribution-Center/Shipment-Orders/Resuable/List-Shipment-Bag';
import DatePicker from "vue2-datepicker";
import pagination from '@/Plugins/Paginations/Pagination';
import Loading from '@/views/Loading';
import AddParcelBag from './Dialog/Add';
import EditParcelBag from './Dialog/Edit';
import randomBag from "@views/DistributionCenter/Shipment-Orders/ParcelBagReusable/Dialog/randomBag";
export default {
  components: {
    AddParcelBag,
    EditParcelBag,
    DatePicker,
    pagination,
    Loading,
    randomBag
  },

  mixins: [mixinListShipmentBag],

  created() {
    this.FetchData();
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}
</style>
