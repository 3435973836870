<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t('shipment.cancel_return_to_sender') }}
      </h4>
    </template>

    <div class="columns" :style="{ marginTop: '10px' }">
      <div class="field column">
        <div class="control">
          <div class="control">
            <label class="label">{{ $t('ຂໍ້ມູນOrderDetail.ໝາຍເຫດ') }}
              <span class="text-danger">*</span>
            </label>
            <input
                class="input"
                type="text"
                name="remark"
                v-model="remark"
                v-validate="'max:191|required'"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading">
          {{ $t('common.edit') }}
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

export default {
  props: ['shipment_number'],
  data: () => ({
    loading: false,
    remark: '',
  }),
  created() {
    const dict = {
      custom: {
        remark: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', { max: 191 })}...)`,
        },
      },
    };
    Validator.localize("en", dict);

    this.FetchListBranch();
  }
  ,
  watch: {
    "remark": function () {
      this.server_errors.remark = "";
    },
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditItem();
        }
      });
    },
    EditItem() {
      this.loading = true;
      this.$http.post('v1/auth/branches/shipments/orders/tracking/cancel-returned-shipment',{
        'bill_number': this.shipment_number,
        'remark': this.remark
      }).then(() => {
        setTimeout(() => {
          this.loading = false;
          this.$emit("close");
          this.$emit("success");
          this.$notification.OpenNotification_EditItem_OnSuccess(
              "top-right",
              "dark",
              3000
          );
        }, 300);
      }).catch((error) => {
        this.loading = false;
        if (error.response.status === 422) {
          var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    }
  }
}
</script>
<style lang="scss">

</style>
