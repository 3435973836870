<template>
  <div class="template-upload">
    <h3>ເລືອກໄຟຣ Excel ທີ່ຕ້ອງການອັບໂຫລດ</h3>
    <input style="margin: 0;padding-top: 20px" id="file-input" type="file" v-on:change="selectFile">
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
    <vs-button style="border-radius: 3px;height: 30px;margin-top: 20px;left: 0" :loading="isLoading"
               :disabled="!!errorMessage || !selectedFile" v-on:click="uploadFile">Upload
    </vs-button>
  </div>
</template>

<script>

export default {
  data() {
    return {
      errorMessage: '',
      selectedFile: null,
      isLoading: false,
    };
  },
  methods: {
    selectFile(event) {
      let file = event.target.files[0];

      // Validate file type
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
        this.errorMessage = 'Invalid file type. Please upload an Excel file.';
        return;
      }

      this.selectedFile = file;
      this.errorMessage = '';
    },
    uploadFile() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('file', this.selectedFile);

      this.$http.post('delete-invoice-cod-specific', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          this.isLoading = false;
          this.errorMessage = '';
          this.selectedFile = null;
          this.$emit('close');
          this.$emit('success');
        }

      }).catch(error => {
        this.isLoading = false;
        this.errorMessage = error.response.data.message;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.template-upload {
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;

  #file-input {
    margin-left: 60px;
    margin-top: 20px;
  }

  .error-message {
    color: red;
    font-size: 10px;
    margin-top: 2px;
  }
}
</style>
