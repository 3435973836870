export default{
    data:()=>({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            userName: '',
            userEmail: '',
            roleId: '',
            userCardid: ''
        },
        images:{
            fileName: '',
            base64: '',
            size: '',
            file: '',
            type: '',
        },

        roleId: [],
        permissions: [],
        distributionCenters: [],
    }),

    methods:{
        ShowPermission(){
            this.$axios.get('show-permission-roles',{
                params:{
                    roleId: this.listDistributionCenter.roleId
                }
            }).then(res=>{
                this.permissions = res.data;
            });
        },

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        },

        EditItem(){
            this.btnLoading = true;
            this.listDistributionCenter.newProfile = this.images.fileName;
            this.listDistributionCenter.newFileSize = this.images.size;
            this.$axios.put('edit-distribution-center-users', this.listDistributionCenter).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        triggerInputClick(){
            let selector = this.$refs.file;
            if (selector) {
                selector.click();
            }
        },

        async onFileChange(e) {
            const file = e.target.files[0];
            const FileSize = (file.size / 1024 / 1024).toFixed(2);
            const extentsions = file.type.split("/");
            const validate_Ext = this.ValidateExtensions(extentsions[1]);

            if(validate_Ext == true && FileSize < 6){
                this.images.file = file;
                this.images.type = extentsions[1];
                this.images.size = this.FormatByte(file.size);
                this.images.base64 = await this.ToBase64(file);
                this.UploadFile();
            }
        }, // OnFile-Change

        ToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }, // Convert-Base64

        FormatByte(bytes){
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }, // FormatByte

        ValidateExtensions(ext){
            if(ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp'){
                return true;
            }else{
                return false;
            }
        },

        UploadFile(){
            let formData = new FormData();
            formData.append('imageFile', this.images.file);

            this.$axios.post('upload-file', formData).then((res)=>{
                this.images.fileName = res.data.data.fileName;

            }).catch(()=>{});
        }, // Upload-File

        Slit_Ext(value){
            const fileType =  value.split(".");
            return fileType[1];
        },

        FetchData(){
            this.isLoading = true;
            this.$http.get('v1/listing/distribution_centers').then(res=>{
                setTimeout(()=>{
                    this.isLoading = false;
                    this.distributionCenters = res.data;
                }, 100);
            }).catch(()=>{});
        },
    },

    watch: {
        'listDistributionCenter.userName':function(){
            this.server_errors.userName = '';
        },
        'listDistributionCenter.userEmail':function(){
            this.server_errors.userEmail = '';
        },
        'listDistributionCenter.roleId':function(){
            this.server_errors.roleId = '';
        },
        'listDistributionCenter.userCardid': function () {
            this.server_errors.userCardid = '';
        },
    },
}
