<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{fontWeight: 'bold',color: '#195BFF',fontSize: '18px',marginTop: '10px',}">
        <i class="fal fa-info-circle" :style="{ width: '30px', fontSize: '18px' }"></i>
        ຂໍ້ມູນລູກຄ້າ ວັນເປີດງານ
      </h4>
    </template>

    <!-- name -->
    <div class="field column">
      <div class="control">
        <label class="label">
          ຊື່
          <span class="text-danger">* {{ errors.first("name") }}</span>
          <span class="text-danger">
              {{ server_errors.name }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="name"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="listCustomer.name"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- surname -->
    <div class="field column">
      <div class="control">
        <label class="label">
          ນາມສະກຸນ
          <span class="text-danger"
          >* {{ errors.first("surname") }}</span
          >
          <span class="text-danger">
              {{ server_errors.surname }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="surname"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="listCustomer.surname"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!--gender-->
    <div class="field column" style="margin-top: 5px">
      <div class="control">
        <label class="label">
          ເພດ
          <span class="text-danger">* {{ errors.first("gender") }}</span>
          <span class="text-danger">{{ server_errors.gender }}</span>
        </label>
        <div class="columns">
          <div class="field column">
            <div class="control">
              <vs-radio v-model="listCustomer.gender" val="male">ຊາຍ</vs-radio>
            </div>
          </div>

          <div class="field column">
            <div class="control">
              <vs-radio v-model="listCustomer.gender" val="female">ຍິງ</vs-radio>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- tel -->
    <div class="field column">
      <label class="label" style="text-align: left">
        ເບີໂທ
        <span class="text-danger">* {{ errors.first("tel") }}</span>
        <span class="text-danger">
            {{ server_errors.tel }}
          </span>
      </label>
      <div class="field has-addons">
        <p class="control">
            <span class="select">
              <select style="min-width: 80px" v-model="telType">
                <option value="020">020</option>
                <option value="030">030</option>
              </select>
            </span>
        </p>
        <p class="control" style="flex: auto">
          <input
              class="input"
              type="text"
              name="tel"
              placeholder="XXXXXXX..."
              v-model="listCustomer.tel"
              v-validate="{
                required: true,
                numeric: true,
                max: telType === '020' ? 8 : 7,
                min: telType === '020' ? 8 : 7,
              }"
          />
        </p>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
        >ເເກ້ໄຂຂໍ້ມູນລູກຄ້າ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    surname: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    tel: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      numeric: "(ປ້ອນໄດ້ພຽງຕົວເລກ...)",
      max: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວອັກສອນ...)`;
      },
      min: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວອັກສອນ...)`;
      },
    },

  },
};
Validator.localize("en", dict);

export default {
  props: ["listCustomer"],
  data() {
    return {
      active: false,
      isLoading: false,
      server_errors: {
        name: "",
        surname: "",
        tel: "",
      },
      telType: "",
    };
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditData();
        }
      });
    },
    EditData() {
      this.btnLoading = true;
      this.$http.put('edit-celebrate/' + this.listCustomer.id, this.listCustomer)
          .then((res) => {
            if (res.data.success) {
              setTimeout(() => {
                this.$emit('close');
                this.$emit('success');
                this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
              }, 300);
            }
          }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },

  watch: {
    "listCustomer.customer_tel": function () {
      this.server_errors.tel = "";
    },
  },

  created() {
    if (this.listCustomer.tel.length === 8) {
      this.telType = "020"
    } else {
      this.telType = "030"
    }
    this.FetchAddress();
  }
};
</script>

<style lang="scss" scoped>
.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}

.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.35rem;
}

</style>