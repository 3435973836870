<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <li v-if="$can(['accountingoriginfranchise','accountingmanager'])"
            @click="Tabs('origin')" :class="{'is-active': isActive === 'origin'}">
          <a>Invoices ຍອດຈັດສົ່ງສຳເລັດຕົ້ນທາງ</a>
        </li>
<!--        <li v-if="$can(['accountingoriginfranchise','accountingmanager'])" @click="Tabs('destination')"-->
<!--            :class="{'is-active': isActive === 'destination'}">-->
<!--          <a>Invoices ຍອດຈັດສົ່ງສຳເລັດCDC</a>-->
<!--        </li>-->
      </ul>
    </div>

    <listInvoiceFranchise :onclickEvent="isActive"/>
  </div>
</template>

<script>
import listInvoiceFranchise from "@views/InvoiceFranchiseBranch/listInvoiceFranchise";

export default {
  components: {
    listInvoiceFranchise

  },

  data: () => ({
    isActive: '',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'tab.invoice.local', query: {tab: value}}).catch(() => {
      });
    }
  },
  created() {
    if (this.$route.query.tab === 'origin') {
      this.isActive = 'origin';
    } else {
      this.isActive = 'destination';
    }
  },
}
</script>

<style>

</style>
