<template>
    <div>
        <template>
            <h4 class="not-margin"
                :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-plus-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມ ໝວດໝູ່ພັດສະດຸ
            </h4>
        </template>
    
        <div class="field" :style="{marginTop: '20px'}">  
            <div class="control">
                <label class="label">
                    ໝວດໝູ່ ພັດສະດຸ
                    <span class="text-danger">* {{ errors.first('category') }}</span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input 
                    type="text" class="input" 
                    name="category" 
                    placeholder="ກະລຸນາປ້ອນໝວດໝູ່ກ່ອນ..." 
                    v-model="value.name"
                    v-validate="'required'"
                >
            </div>
        </div>
   
        <button class="button" :class="{'is-loading': btnLoading}" :style="{marginTop: '20px', width: '30%'}" @click="ValidateForm()">
            ເພີ່ມ ໝວດໝູ່
        </button>
    </div>
</template>

<script>
import mixinsAddParcelCategories from '@coms/../../src/Mixins/Parcel-Categories/Add';
import { ValidateVillages } from '@coms/../../src/Plugins/Validation/Validate-Villages';

export default {

    mixins: [mixinsAddParcelCategories],

    created(){
        ValidateVillages(this);
    }
}
</script>
<style lang="scss" scoped>
    .button{
        &:hover{
            color: $white;
        }
    }
</style>