export default {
    data: () => ({
        listDistributionCenterLinks: [],
        server_errors: {
            distributionCenterId: '',
            distribution_center_strict_type: '',
        },
        value: {},
        distributionCenterId: [],
        distribution_center_strict_type: null,
        btnLoading: false,
        distributionCenterStrictTypes: [
            {id: 'all', name: 'ຍິງເຄື່ອງເຂົ້າ-ອອກ'},
            {id: 'inbound', name: 'ຍິງເຄື່ອງເຂົ້າ'},
            {id: 'outbound', name: 'ຍິງເຄື່ອງອອກ'},
        ],
    }),
    methods: {
        FetchDistributionCenter() {
            this.isLoading = true;
            this.$http.get('v1/listing/distribution_centers').then(res => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(() => {
                    this.distributionCenters = res.data;
                }, 100);
            }).catch(() => {
            });
        },
        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }
            });
        },
        SaveItem() {
            this.btnLoading = true;
            this.$axios.post('add-distribution-center-links/' + this.$route.params.id, {
                distributionCenterId: this.distributionCenterId,
                strict_type: this.distribution_center_strict_type,
            }).then(() => {
                this.btnLoading = false;
                this.$emit('close');
                this.$emit('success');
            }).catch(() => {
            });
        }
    },
    watch: {
        'distributionCenterId': function () {
            this.server_errors.distributionCenterId = '';
        },
        distribution_center_strict_type() {
            this.server_errors.distribution_center_strict_type = '';
        },
    }
}
