export default {
    data:()=>({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },
        delivery: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },

        parts: [],
        provinces: [],
        districts: [],
        villages: [],

        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},
        selectedVillage: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        checkVillage: true,
        FormatPart_To_Laos:{
            'north': 'ພາກເໜືອ',
            'center': 'ພາກກາງ',
            'south': 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1'
        },

        // default to Montreal to keep it simple
        // change this to whatever makes sense
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,
    }),

    methods:{
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },

        // receives a place object via the autocomplete component
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({ position: marker });
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({ position: marker });
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });
        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },

        FetchData(){
            this.$axios.get('list-branch-address').then(res=>{
                const items = res.data.data;

                this.parts = items.parts;
                this.selectedPart = {...this.parts[0]};
                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;

            }).catch(()=>{});
        },

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }else{
                    this.$scrollTo('#scrollTo');
                }
            });
        },
        SaveItem(){
            this.delivery.villageId = this.selectedVillage.village_id;
            this.delivery.lat = this.center.lat;
            this.delivery.lng = this.center.lng;

            this.btnLoading = true;
            this.$axios.post('register-deliveries', this.delivery).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'list.deliveries'});
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                this.$scrollTo('#scrollTo');
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FilterProvince(partId){
            const result_checking = this.filterProvinces.filter(item=>{
                if(item.part_id == partId){
                    return item;
                }
            });
            this.provinces = result_checking;
            this.selectedProvince = {...this.provinces[0]};
        },
        FilterDistricts(provinceId){
            const result_checking = this.filterDistricts.filter(item=>{
                if(item.province_id == provinceId){
                    return item;
                }
            });
            this.districts = result_checking;
            this.selectedDistrict = {...this.districts[0]};
        },
        FilterVillages(districtId){
            const result_checking = this.filterVillages.filter(item=>{
                if(item.district_id == districtId){
                    return item;
                }
            });
            this.villages = result_checking;
            this.selectedVillage = {...this.villages[0]};
        }
    },

    watch:{
        'selectedPart.partId':function(partId){
            this.FilterProvince(partId);
        },
        'selectedProvince.proId':function(provinceId){
            this.FilterDistricts(provinceId);
        },
        'selectedDistrict.distId':function(districtId){
            this.FilterVillages(districtId);
        },

        // Clear-Errors
        'selectedVillage.village_id':function(){
            this.server_errors.villageId = '';
        },
        'delivery.prefix':function(){
            this.server_errors.prefix = '';
        },
        'delivery.name':function(){
            this.server_errors.name = '';
        },
        'delivery.email':function(){
            this.server_errors.email = '';
        },
        'delivery.tel':function(){
            this.server_errors.tel = '';
        },
    },
    mounted() {
        this.geolocate();
    },
}
