<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-list"></i>
          ລາຍລະອຽດ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
    </div>
    <div class="center" :style="{marginTop: '-10px'}">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ຈຳນວນທັງໝົດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :is-selected="true">
            <vs-td>{{ FormatPrice(total) }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຮ້ານຄ້າ</vs-th>
            <vs-th>ລູກຄ້າ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
            <vs-th>ເລກອ້າງອີງ</vs-th>
            <vs-th>ວັນທີ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="payment"
              v-for="(payment, index) in $vs.getPage(listPayments, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ payment.shop_name }}</vs-td>
            <vs-td>{{ payment.customer_name }}</vs-td>
            <vs-td>{{ FormatPrice(payment.amount) }}</vs-td>
            <vs-td>{{ payment.remark }}</vs-td>
            <vs-td>{{ payment.reference }}</vs-td>
            <vs-td>{{ moment(payment.created_at).format('DD-MM-YYYY HH:mm') }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import moment from "moment";

export default {
  components: {
    Loading,
    pagination,
  },

  data: () => ({
    invoice_status: '',
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    listPayments: [],
    // Pagination
    count: 0,
    total: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2021-08-01 00:00:00');
      return date < fixDay;
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        if (!res.data.error) {
          console.log(res.data.data.data)
          setTimeout(() => {
            this.listPayments = res.data.data.data.data;
            this.total = res.data.data.total;
            this.count = res.data.data.data.total;
            this.pagination = res.data.data.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/shop/invoice/detail/' + this.$route.params.id, {
        params: {
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
  watch: {
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
