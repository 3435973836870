<template>
    <div class="container">
        <div class="tabs" :style="{marginTop: '-30px', marginLeft: '-30px'}">
            <ul>
                <li :class="{'is-active': isActive == 'online'}" @click="Tabs('online')">
                    <a>ຄູປ໋ອງ ເປີດຕອນນີ້</a>
                </li>
                <li @click="Tabs('offline')" :class="{'is-active': isActive == 'offline'}">
                    <a>ຄູປ໋ອງ ປິດເເລ້ວ</a>
                </li>
            </ul>
        </div>

        <CouponOnline v-if="isActive == 'online'"/>
        <CouponOffline v-else/>
    </div>
</template>

<script>
import CouponOnline from './List-Coupon-Online';
import CouponOffline from './List-Coupon-Offline';

export default {
    components:{
        CouponOnline,
        CouponOffline
    },

    data:()=>({
        isActive: false,
    }), 

    methods:{
        Tabs(value){
            this.isActive = value;
            this.$router.push({name: 'tab.list.coupon', query:{tab: value}});
        }
    },
    created(){
        if(this.$route.query.tab == 'online'){
            this.isActive = 'online';
        }else if(this.$route.query.tab == 'offline'){
            this.isActive = 'offline';
        }else{
            this.isActive = 'online';
        }
    }
}
</script>

<style>

</style>