var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('h4',{staticClass:"not-margin",style:({
        fontWeight: 'bold',
        color: '#195BFF',
        fontSize: '18px',
        marginTop: '10px',
      })},[_c('i',{staticClass:"fal fa-info-circle",style:({ width: '30px', fontSize: '18px' })}),_vm._v(" ເລືອກຮູບພາບ ")])],_c('div',{staticClass:"field column",staticStyle:{"margin-bottom":"-10px"}},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(" ຮູບ "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("image")))]),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.server_errors.image)+" ")])]),(!_vm.images.base64)?_c('div',{staticClass:"box-upload-file",on:{"click":function($event){return _vm.triggerInputClick()}}},[_vm._m(0)]):_vm._e(),(_vm.images.base64)?_c('div',{staticClass:"has-profile",style:({ backgroundImage: `url(${_vm.images.base64})`}),on:{"click":function($event){return _vm.triggerInputClick()}}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"control"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('mimes:image/*|size:10240'),expression:"'mimes:image/*|size:10240'"}],ref:"file",staticClass:"input input-ctn",attrs:{"type":"file","name":"icon"},on:{"change":_vm.onFileChange}})])])]),_c('div',{ref:"target",staticClass:"center",attrs:{"id":"target"}},[_c('div',{staticClass:"con-btns"},[_c('vs-button',{ref:"button",staticClass:"form-btn-loading",attrs:{"flat":"","loading":_vm.loading},on:{"click":function($event){return _vm.ValidateForm()}}},[_vm._v("ສົ່ງຮູບພາບ ")])],1)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-msg"},[_c('i',{staticClass:"fal fa-cloud-upload"}),_c('p',{staticClass:"choose"},[_vm._v(" ກະລຸນາເລືອກຮູບ ")])])
}]

export { render, staticRenderFns }