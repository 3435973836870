<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມ ເເບນເນີ ( Banners )
            </h4>
        </template>

        <div class="field">
            <div class="control">
                <label class="label">
                    ຈັດລຽງ ( OrderBy ) 
                    <span class="text-danger">* {{ errors.first('orderBy') }}</span>
                    <span class="text-danger">
                        {{server_errors.orderBy}}
                    </span>
                </label>
                <input 
                    class="input" 
                    name="orderBy" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="value.orderBy" 
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field" :style="{marginTop: '20px'}">
            <div class="control">
                <label class="label">
                    ລິ້ງ ( Link ) 
                </label>
                <input 
                    class="input" 
                    name="link" 
                    placeholder="ກະລຸນາປ້ອນຊື່ຄູປ໋ອງກ່ອນ..." 
                    v-model="value.link" 
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">ເລືອກໂປຮໄຟລ
                    <span class="text-danger">* {{ errors.first('profile') }}</span>
                    <span class="text-danger">
                        {{server_errors.profile}}
                    </span>
                </label>
                <div class="box-upload-file" @click="triggerInputClick()" v-if="!images.base64">
                    <div class="text-msg">
                        <i class="fal fa-cloud-upload"></i>
                        <p class="choose">
                            ກະລຸນາເລືອກໂປຮໄຟລ
                        </p>
                    </div>
                </div>
                <div class="has-profile" @click="triggerInputClick()" :style="{ backgroundImage: `url(${images.base64})`}"
                v-if="images.base64"></div>

                <div v-show="false" class="control">
                    <input 
                        @change="onFileChange" ref="file" 
                        class="input input-ctn" type="file" name="profile"
                        v-validate="'required|mimes:jpeg,jpg,gif,png|size:5120'"
                        accept="image/png,image/gif,image/jpeg,image/jpg"
                    >
                </div> 
            </div>
        </div>
        
         <div ref="target" id="target" class="center" :style="{marginTop: '30px'}">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">ບັນທຶກ ເເບນເນີ</vs-button>
            </div>
        </div>

    </div>
</template>

<script>
import mixinsAddBanners from '@coms/../../src/Mixins/Banners/Add';
import { ValidateBanners } from '@coms/../../src/Plugins/Validation/Validate-Banners';


export default {

    mixins:[mixinsAddBanners],

    created(){
        ValidateBanners(this);
    }
}
</script>

<style lang="scss" scoped>
    .box-upload-file{
        border: 1px dashed rgba(100, 98, 98, 0.867);
        height: 200px;
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        &:hover{
            cursor: pointer;
            border: 1px dashed $primary-color;
            .text-msg{
                i{
                    color: $primary-color;
                }
                p{
                    color: $primary-color;
                }
            }
        }
        .text-msg{
            i{
                font-size: 25px;
                color: rgba(100, 98, 98, 0.867);
            }
            .choose{
                margin-top: 10px;
                color: rgba(100, 98, 98, 0.867);
            }
        }
    }
    .has-profile{
        border: none;
        height: 200px;
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        &:hover{
            cursor: pointer;
        }
    }
</style>