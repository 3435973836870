<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-48px', marginLeft: '-30px'}">
      <ul>
        <li v-if="$can(['accountingmanager', 'c-logistics-branch-accounting'])"
            :class="{'is-active': isActive === 'all'}"
            @click="Tabs('all')">
          <a>{{ $t('common.all') }}</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingoriginbranch',
        'accountingoriginfranchise', 'c-logistics-branch-accounting'])"
            @click="Tabs('origin_freight_fees')" :class="{'is-active': isActive === 'origin_freight_fees'}">
          <a>{{ $t('common.origin_freight_fees') }}</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingcdc', 'c-logistics-branch-accounting'])"
            @click="Tabs('destination_freight_fees')"
            :class="{'is-active': isActive === 'destination_freight_fees'}">
          <a>{{ $t('common.destination_freight_fees') }}</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingcod', 'c-logistics-branch-accounting'])"
            :class="{'is-active': isActive === 'origin_freight_fees_cod'}" @click="Tabs('origin_freight_fees_cod')">
          <a>{{ $t('common.origin_freight_fees_cod') }}</a>
        </li>
        <li v-if="$can(['accountingcod'])" :class="{'is-active': isActive === 'allForCod'}" @click="Tabs('allForCod')">
          <a>{{ $t('common.cod_order_list_to_increase_price_later') }}</a>
        </li>
      </ul>
    </div>

    <ListShipment :onclickEvent="onClick"/>
  </div>
</template>

<script>
import ListShipment from './List-Solve-Shipment';

export default {
  components: {
    ListShipment,
  },

  data: () => ({
    isActive: 'origin_freight_fees',
    onClick: '',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'account.tab.solve.shipments', query: {tab: value}}).catch(() => {
      });
      this.onClick = value;
    }
  },
  created() {
    if (this.$route.query.tab === 'origin_freight_fees') {
      this.isActive = 'origin_freight_fees';
    } else if (this.$route.query.tab === 'destination_freight_fees') {
      this.isActive = 'destination_freight_fees';
    } else if (this.$route.query.tab === 'origin_freight_fees_cod') {
      this.isActive = 'origin_freight_fees_cod';
    } else if (this.$route.query.tab === 'allForCod') {
      this.isActive = 'allForCod';
    } else if (this.$route.query.tab === 'all') {
      this.isActive = 'all';
    } else {
      this.$router.push({name: 'account.tab.solve.shipments', query: {tab: 'origin_freight_fees'}});
      this.isActive = 'origin_freight_fees';
      this.onClick = 'origin_freight_fees';
    }
  }
}
</script>

<style>

</style>
