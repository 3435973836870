export default {
    data: ()=>({
        password: '',
        server_errors:{
            password: '',
        },
        btnLoading: false,
    }),

    methods: {
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.ChangePassword();
                }
            });
        }, // ValidateForm
        ChangePassword(){
            this.btnLoading = true;
            this.$axios.post('change-password-user/' + this.userId, {
                password: this.password
            }).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }
    },
}