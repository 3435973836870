import { Validator } from 'vee-validate';

const validate = {
    custom: {
      username:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ຊື່ບໍ່ຄວນສັ້ນກວ່າ 2 ໂຕ)',
        max: '(ຊື່ບໍ່ຄວນຍາວກວ່າ 25 ໂຕ)',
      },
      user_tel:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ເບີໂທບໍ່ຄວນສັ້ນກວ່າ 7 ໂຕ)',
        max: '(ເບີໂທບໍ່ຄວນຍາວກວ່າ 15 ໂຕ)',
      },
      user_email:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        email: '(ອີເມວບໍ່ຖືກຕ້ອງ...)',
        min: '(ອີເມວບໍ່ຄວນສັ້ນກວ່າ 5 ໂຕ)',
        max: '(ອີເມວບໍ່ຄວນຍາວກວ່າ 255 ໂຕ)',
      },
      user_password:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ລະຫັດບໍ່ຄວນສັ້ນກວ່າ 6 ໂຕ)',
        max: '(ລະຫັດບໍ່ຄວນຍາວກວ່າ 225 ໂຕ)',
      },
      role:{
        required: '(ກະລຸນາເລືອກກ່ອນ...)',
      },
      user_profile:{
        required: '(ກະລຸນາເລືອກໂປຮໄຟລກ່ອນ)',
        mimes: '(ກະລູນາເລືອກ jpeg,jpg,png,gif ເທົ່ານັ້ນ...)',
        size: '(ຂະໜາດບໍ່ຄວນເກີນ 5 MB.)',
      },
      userCardid:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        max: '(ລະຫັດບໍ່ຄວນຍາວກວ່າ 255 ໂຕ)',
      },
    }
};

export const ValidateCallCenterUser = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

