<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ Permission-Role
                <span class="countTotal">({{permissionRoles.length}})</span>
                <span class="btn-add">
                    <vs-button
                        circle
                        icon
                        flat
                        @click="OpenModalAdd()"
                        v-if="permissions.length"
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                    <vs-button
                        circle
                        icon
                        flat
                        @click="CannotAdd_PermissionRole()"
                        v-else
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>
                        ລຳດັບ
                    </vs-th>
                    <vs-th>
                        ໜ້າທີ່
                    </vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                    :key="index"
                    v-for="(permissionRole, index) in $vs.getPage(permissionRoles, page, max)"
                    :data="permissionRole"
                >
                    <vs-td>
                        {{ index + 1 }}
                    </vs-td>
                    <vs-td>
                        {{ permissionRole.permissionName }}
                    </vs-td>
                    <vs-td style="text-align: right; width: 100px">
                        <div class="group-icon">
                            <vs-button
                                circle
                                danger
                                icon
                                flat
                                @click="OpenModalDelete(permissionRole.permissionId)"
                            >
                            <i class="fad fa-trash-alt"></i>
                            </vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(permissionRoles, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddPermissionRoles @close="close" :permissions="permissions" @success="FetchData()"/>
            </template>
        </ModalAdd>

        <ModalNotification>
            <template v-slot="{ close }">
                <Notifications @close="close"/>
            </template>
        </ModalNotification>

        <ModalDelete>
            <template v-slot="{ close }">
                <DeletePermissionRoles @close="close" :perRoleId="perRoleId" @success="FetchData()"/>
            </template>
        </ModalDelete>

    </div>
</template>
<script>
import AddPermissionRoles from './Dialog/Add';
import DeletePermissionRoles from './Dialog/Delete';
import Notifications from './Dialog/Notification';
import Loading from '../../Loading';


export default {
    components:{
        AddPermissionRoles,
        DeletePermissionRoles,
        Notifications,
        Loading
    },

    data:()=>({
        active: 1,
        isLoading: true,
        page: 1,
        max: 10,
        permissionRoles: [],  
        permissions: [],
        perRoleId: '',
    }),
    methods:{
        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        CannotAdd_PermissionRole(){
            this.$store.commit('modalNotification_State', true);
        },
        OpenModalDelete(perRoleId){
            this.perRoleId = perRoleId;
            this.$store.commit('modalDelete_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-permission-roles/' + this.$route.params.id).then(res=>{
                setTimeout(() => {
                    this.$emit('close');
                    this.isLoading = false;
                }, 200);
                setTimeout(()=>{
                    this.permissionRoles = res.data.permissionRoles;
                    this.permissions = res.data.permissions;
                }, 100);
    
            }).catch(()=>{});
        },
    },
    created(){
        if(this.$route.params.id){
            this.FetchData();
        }
    }
}
</script>
<style lang="scss">
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>