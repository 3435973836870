<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ເພີ່ມລາຍການ
      </h4>
    </template>

    <!-- type -->
    <div class="field columns column" style="margin-bottom: -30px">
      <div class="column">
        <div class="control">
          <label class="label">
            ປະເພດລາຍການ
            <span class="text-danger">* {{ errors.first("item_type") }}</span>
            <span class="text-danger">
              {{ server_errors.item_type }}
            </span>
          </label>
          <div class="select">
            <select class="input" v-model="item.item_type">
              <option value="item">ຂອງລາງວັນ</option>
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ໝວດໝູ່
            <span class="text-danger">* {{ errors.first("item_category_id") }}</span>
            <span class="text-danger">
              {{ server_errors.item_category_id }}
            </span>
          </label>
          <model-list-select
              :list="itemCategory"
              v-model="item.item_category_id"
              placeholder="ເລືອກໝວດໝູ່"
              option-text="name"
              option-value="id"
              selectedItem="name"
              name="shop_id"
          >
          </model-list-select>
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            flash sale
            <span class="text-danger">* {{ errors.first("flash_sale_status") }}</span>
            <span class="text-danger">
              {{ server_errors.flash_sale_status }}
            </span>
          </label>
          <div class="select">
            <select class="input" v-model="item.flash_sale_status">
              <option value="off">ປິດ</option>
              <option value="on">ເປີດ</option>
            </select>
          </div>
        </div>
      </div>
      <div class="column" v-if="item.flash_sale_status === 'on'">
        <div class="control">
          <label class="label">
            ໝົດເຂດ flash sale
            <span class="text-danger"> {{ errors.first("end_flash_sale") }}</span>
            <span class="text-danger">
              {{ server_errors.end_flash_sale }}
            </span>
          </label>
          <date-picker
              format="DD/MM/YYYY HH:mm:ss"
              value-type="YYYY-MM-DD HH:mm:ss"
              type="datetime"
              v-model="item.end_flash_sale"
              :editable="false"
              name="end_flash_sale"
          ></date-picker>
        </div>
      </div>
    </div>

    <!--SHOP name and price-->
    <div class="field columns column" style="margin-bottom: -30px">
      <div class="column">
        <div class="control">
          <label class="label">
            ຮ້ານຄ້າ
            <span class="text-danger">* {{ errors.first("shop_id") }}</span>
            <span class="text-danger">
              {{ server_errors.shop_id }}
            </span>
          </label>
          <model-list-select
              :list="shops"
              v-model="item.shop_id"
              placeholder="ເລືອກຮ້ານ"
              option-text="name"
              option-value="id"
              selectedItem="name"
              name="shop_id"
          >
          </model-list-select>
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ຊື່
            <span class="text-danger">* {{ errors.first("name") }}</span>
            <span class="text-danger">
              {{ server_errors.name }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="name"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
              v-model="item.name"
              v-validate="'required|max:191'"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ຈຳນວນແຕ້ມທີ່ໃຊ້ແລກ
            <span class="text-danger">* {{ errors.first("price") }}</span>
            <span class="text-danger">
              {{ server_errors.price }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="price"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
              v-model="item.price"
              v-validate="'required|numeric'"
          />
        </div>
      </div>

    </div>

    <div class="field columns column" style="margin-bottom: -30px">
      <div class="column" v-if="item.flash_sale_status === 'on'">
        <div class="control">
          <label class="label">
            ແຕ້ມທີ່ໃຊ້ແລກຕອນ flash sale
            <span class="text-danger">* {{ errors.first("flash_sale_price") }}</span>
            <span class="text-danger">
              {{ server_errors.flash_sale_price }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="flash_sale_price"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
              v-model="item.flash_sale_price"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ລາຄາຕົວຈິງ
            <span class="text-danger">* {{ errors.first("item_cost") }}</span>
            <span class="text-danger">
              {{ server_errors.item_cost }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="item_cost"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
              v-model="item.item_cost"
              v-validate="'required|numeric'"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ວັນທີໝົດເຂດ
            <span class="text-danger">* {{ errors.first("end_available") }}</span>
            <span class="text-danger">
              {{ server_errors.end_available }}
            </span>
          </label>
          <date-picker
              format="DD/MM/YYYY HH:mm:ss"
              value-type="YYYY-MM-DD HH:mm:ss"
              type="datetime"
              v-model="item.end_available"
              name="end_available"
              :editable="false"
          ></date-picker>
        </div>
      </div>
    </div>

    <!-- qty, amount per user and day -->
    <div class="field columns column" style="margin-bottom: -30px">
      <div class="column">
        <div class="control">
          <label class="label">
            ຈຳນວນ
            <span class="text-danger"> {{ errors.first("qty") }}</span>
            <span class="text-danger">
              {{ server_errors.qty }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="qty"
              placeholder="ຖ້າບໍ່ໃສ່ຈຳນວນຈະເປັນບໍ່ຈຳກັດ..."
              v-model="item.qty"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ຈຳນວນແລກຕໍ່ໜຶ່ງຄົນ
            <span class="text-danger"> {{ errors.first("amount_per_user") }}</span>
            <span class="text-danger">
              {{ server_errors.amount_per_user }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="amount_per_user"
              placeholder="ຖ້າບໍ່ໃສ່ຈຳນວນຈະເປັນບໍ່ຈຳກັດ..."
              v-model="item.amount_per_user"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ຈຳນວນແລກຕໍ່ມື້
            <span class="text-danger"
            > {{ errors.first("amount_per_day") }}</span
            >
            <span class="text-danger">
              {{ server_errors.amount_per_day }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="amount_per_day"
              placeholder="ຖ້າບໍ່ໃສ່ຈຳນວນຈະເປັນບໍ່ຈຳກັດ..."
              v-model="item.amount_per_day"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ຈຳນວນແລກລໍຖ້າດຳເນີນການ
            <span class="text-danger"> {{ errors.first("pending_exchange") }}</span>
            <span class="text-danger">
              {{ server_errors.pending_exchange }}
            </span>
          </label>

          <input
              class="input"
              type="text"
              name="qty"
              placeholder="..."
              v-model="item.pending_exchange"
          />
        </div>
      </div>
    </div>

    <div class="field columns column" style="margin-bottom: -30px">
      <div class="column">
        <div class="control">
          <label class="label">
            ໄລຍະເວລາໃນການມາເອົາຂອງລາງວັນ
            <span class="text-danger">* {{ errors.first("expired_duration") }}</span>
            <span class="text-danger">
              {{ server_errors.expired_duration }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="expired_duration"
              placeholder="ຖ້າບໍ່ໃສ່ຈຳນວນຈະເປັນບໍ່ຈຳກັດ..."
              v-model="item.expired_duration"
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">
            ສະຖານະເຄື່ອງ
            <span class="text-danger">* {{ errors.first("status") }}</span>
            <span class="text-danger">
              {{ server_errors.status }}
            </span>
          </label>
          <div class="select">
            <select class="input" v-model="item.status">
              <option value="0">ປິດ</option>
              <option value="1">ເປີດ</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="field columns column" style="margin-bottom: -20px">
      <div class="column">
        <div class="control">
          <label class="label">
            ຄຳອະທິບາຍ
            <span class="text-danger"> {{ errors.first("description") }}</span>
            <span class="text-danger">
              {{ server_errors.description }}
            </span>
          </label>
          <textarea
              class="textarea"
              v-model="item.description"
          />
        </div>
      </div>
    </div>

    <!-- icon -->
    <div class="field column" style="margin-bottom: -20px">
      <div class="control">
        <label class="label">
          ຮູບ
          <span class="text-danger"
          > {{ errors.first("image") }}</span
          >
          <span class="text-danger">
              {{ server_errors.image }}
            </span>
        </label>
        <div id="my-strictly-unique-vue-upload-multiple-image"
             style="display: flex; justify-content: center; margin-top: 5px">
          <vue-upload-multiple-image
              :maxImage="5"
              dragText="ເລືອກໄດ້ສູງສຸດ 5 ຮູບ"
              :showPrimary="false"
              browse-text="ເລືອກຮູບພາບ"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              :data-images="value.images"
          ></vue-upload-multiple-image>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >ແກ້ໄຂຮ້ານຄ້າ
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";
import {ModelListSelect} from 'vue-search-select';
import DatePicker from "vue2-datepicker";
import VueUploadMultipleImage from 'vue-upload-multiple-image'

const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },
    price: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      numeric: "(ກະລຸນາປ້ອນເປັນຕົວເລກ...)",
    },
    item_cost: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      numeric: "(ກະລຸນາປ້ອນເປັນຕົວເລກ...)",
    },
  },
};
Validator.localize("en", dict);


export default {
  props: [
    'item',
  ],
  components: {
    ModelListSelect,
    DatePicker,
    VueUploadMultipleImage
  },
  data() {
    return {
      itemCategory: [],
      active: false,
      isLoading: false,
      loading: false,
      shops: [],
      server_errors: {
        item_type: "",
        item_category_id: '',
        shop_id: "",
        name: "",
        price: "",
        item_cost: "",
        description: "",
        qty: "",
        image: "",
        pending_exchange: "",
        amount_per_user: "",
        amount_per_day: "",
        end_available: "",
      },
      value: {
        name: "",
        item_type: "item",
        images: [],
        newImages: [],
      },
    };
  },
  created() {
    this.item.images.filter((item) => {
      this.value.images.push({'path': item.image})
    });

    if (this.item.pending_exchange == null) {
      this.item.pending_exchange = 0;
    }
    this.listShop();
    this.listItemCategory();
  },
  methods: {
    uploadImageSuccess(formData) {
      this.loading = true;
      let newFormData = new FormData();
      newFormData.append('shop_item_id', this.item.id)
      newFormData.append('image[]', formData.get('file'));
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.$http.post('add/new/item/image', newFormData, config).then(() => {
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    },

    beforeRemove(index, done) {
      this.loading = true;
      const r = confirm("ລຶບຮູບ");
      if (r === true) {
        const item = this.item.images[index];
        this.$http.post('delete/item/image/' + item.id).then(() => {
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
        });
        done()
      }
    },
    // eslint-disable-next-line no-unused-vars
    editImage(formData, index) {
      let newFormData = new FormData();
      newFormData.append('image', formData.get('file'));
      const item = this.item.images[index];
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.loading = true;
      this.$http.post('update/item/image/' + item.id, newFormData, config).then(() => {
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    },

    listShop() {
      this.$http.get('list/shop').then((res) => {
        this.shops = res.data;
      })
    },
    listItemCategory() {
      this.$http.get('list/shop/item/category').then((res) => {
        this.itemCategory = res.data;
      })
    },

    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.loading = true;
      let formData = new FormData();
      formData.append('item_type', this.item.item_type);
      formData.append('item_category_id', this.item.item_category_id);
      formData.append('flash_sale_status', this.item.flash_sale_status);
      formData.append('shop_id', this.item.shop_id);
      formData.append('name', this.item.name);
      formData.append('price', this.item.price);
      formData.append('item_cost', this.item.item_cost);
      formData.append('pending_exchange', this.item.pending_exchange);
      formData.append('status', this.item.status);
      formData.append('description', this.item.description ?? '');
      formData.append('qty', this.item.qty ?? '');
      formData.append('expired_duration', this.item.expired_duration ?? '');
      formData.append('amount_per_user', this.item.amount_per_user ?? '');
      formData.append('amount_per_day', this.item.amount_per_day ?? '');
      formData.append('end_available', this.item.end_available ?? '');
      formData.append('end_flash_sale',
          (this.item.flash_sale_status === 'on')
              ? (this.item.end_flash_sale ?? '')
              : '');
      formData.append('flash_sale_price',
          (this.item.flash_sale_status === 'on')
              ? (this.item.flash_sale_price ?? '')
              : '');
      formData.append('_method', 'put')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.$http.post('edit/shop/item/' + this.item.id, formData, config).then(() => {
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
  watch: {
    "item.item_type": function () {
      this.server_errors.item_type = "";
    },
    "value.item_category_id": function () {
      this.server_errors.item_category_id = "";
    },
    "item.flash_sale_price": function () {
      this.server_errors.flash_sale_price = "";
    },
    "item.shop_id": function () {
      this.server_errors.shop_id = "";
    },
    "item.name": function () {
      this.server_errors.name = "";
    },
    "item.price": function () {
      this.server_errors.price = "";
    },
    "value.item_cost": function () {
      this.server_errors.item_cost = "";
    },
    "item.qty": function () {
      this.server_errors.qty = "";
    },
    "item.amount_per_user": function () {
      this.server_errors.amount_per_user = "";
    },
    "item.amount_per_day": function () {
      this.server_errors.amount_per_day = "";
    },
    "item.end_available": function () {
      this.server_errors.end_available = "";
    },
    "item.end_flash_sale": function () {
      this.server_errors.end_flash_sale = "";
    },
    "item.status": function () {
      this.server_errors.status = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;

  &:hover {
    cursor: pointer;
  }
}

.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}
</style>
