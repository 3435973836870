<template>
  <div class="container">
    <div class="columns">
      <div class="column is-6 box-title">
        <h4 class="has-text-left">
          <i class="fal fa-users"></i>
          ຂໍ້ມູນລູກຄ້າເຊັນສັນຍາ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
      <div class="column is-6">
        <div class="columns">
          <div class="column">
            <div style="display: flex; align-items: center; flex-direction: row">
              <label style="margin-right: 10px; margin-top: 2px;" class="label">
                ຄົ້ນຫາ
              </label>
              <input
                  style="margin-top: 0.025rem;"
                  class="input"
                  type="text"
                  name="searchItem"
                  v-model="searchItem"
                  @keyup.enter="FilterBySearch()"
              />
            </div>
          </div>
          <div class="column is-5">
            <div style="display: flex;">
              <div v-if="$can(['marketing','accountingmanager','suburbMarketing'])">
                <vs-button
                    ref="button" flat
                    @click="printShipment()"
                    class="form-btn-loading"
                    style="margin-top: 0"
                    :loading="loading"
                    :disabled="!isSelectingOrders">

                  export excel
                </vs-button>
              </div>
              <div v-if="$can(['marketing','accountingmanager'])">
                <vs-button circle icon flat @click="OpenModalAdd()"
                           style="padding-left: 8px; padding-right: 8px; margin-left: 18px; margin-top: -1px;">
                  <i class="fal fa-plus-circle"></i>
                </vs-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="center fit-width">
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບີໂທ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(customer, index) in customers"
              :is-selected="isRowSelected(customer.id)"
              :data="customer"
              @selected="onItemCheckBoxChanged(customer)">
            <vs-td>
              <div class="center con-checkbox">
                <vs-checkbox danger
                             :checked-force="isRowSelected(customer.id)"
                             :val="customer" @click="onItemCheckBoxChanged(customer)">
                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                </vs-checkbox>
              </div>
            </vs-td>
            <vs-td>{{ customer.customer_id }}</vs-td>
            <vs-td>{{ customer.name }}</vs-td>
            <vs-td>{{ customer.tel }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddCustomer @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import AddCustomer from "./Dialog/Add";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";

export default {
  components: {
    Loading,
    AddCustomer,
    pagination
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,

    customers: [],
    listCustomer: {},
    customerId: "",
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
  },
  methods: {
    printShipment() {
      const customers = this.actualSelectedItems.map(item => item.id);
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('marketing/print-customer', {
            customer_id: customers,
          },
          {responseType: 'blob'}
      ).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'ລາຍຊື່ລູກຄ້າເຊັນສັນຍາ.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.actualSelectedItems = [];
        this.FetchData();
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      })
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.customers = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.markSelectedItemsOnPageChanges(this.customers);
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('marketing/regular/customer', {
        params: {
          search: this.searchItem,
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },

    /**
     * @StartChecking
     */
    markSelectedItemsOnPageChanges(items) {
      const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
      const newSelectedItems = [];
      items.map((i) => {
        if (selectedInvoiceIds.includes(i.id)) {
          newSelectedItems.push(i);
        }
      });
      this.pageInvoices = newSelectedItems;
    },

    onCheckAllCheckBoxChanged(event) {
      if (this.isSelecting) {
        return;
      }
      /// If checked
      if (event.target.checked) {
        // if its not indeterminate
        if (!this.isIndeterminate) {
          const loading = this.BtnLoading();
          this.isSelecting = true;
          /// Select all current page
          this.pageInvoices = JSON.parse(JSON.stringify(this.customers));
          /// Select all from response data
          this.fetchInvoiceItemsResponse({
            current_page: 1,
            per_page: this.count
          }).then((res) => {
            this.actualSelectedItems = res.data.data;
          }).catch(() => {
          }).finally(() => {
            loading.close();
            this.isSelecting = false;
          });
        }
      } else {
        /// Clear all selected orders
        this.clearAllChecks();
      }
    },

    onItemCheckBoxChanged(invoice) {
      const invoiceId = invoice.id;
      let itemIndex = this.actualSelectedItems.findIndex((item) => {
        return item.id === invoiceId;
      });
      if (itemIndex !== -1) {
        this.actualSelectedItems.splice(itemIndex, 1);
      } else {
        this.actualSelectedItems.push(invoice);
      }
      this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
    },

    isRowSelected(id) {
      return this.pageInvoices.map((i) => i.id).includes(id);
    },

    clearAllChecks() {
      this.checkedAll = false;
      this.pageInvoices = [];
      this.actualSelectedItems = [];
    },

    /**
     * @EndChecking
     */
  },
  created() {
    this.FetchData();
    this.FetchData = this.lodash.debounce(this.FetchData, 500);
  },
  computed: {
    isSelectAllOrders() {
      return this.actualSelectedItems.length === this.count
          && this.count > 0 && !this.isSelecting;
    },
    isSelectingOrders() {
      return this.actualSelectedItems.length > 0;
    },
    isIndeterminate() {
      return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
    }
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
