<template>
  <div style="overflow: hidden">
    <div class="columns login-section">
      <div class="column is-half is-offset-one-quarter">
        <div class="login-component">
          <div class="parent">
            <div class="head">
              <a href="tel:1419">
                <span class="call has-text-centered"
                >1419 <i class="fas fa-phone"> </i>
                </span>
              </a>
            </div>
          </div>
          <div class="login-content">
            <div class="logo-header">
              <img :src="require('@/assets/logistic.png')"/>
            </div>
            <h1 class="lg-title">ລົງທະບຽນເຂົ້າຮ່ວມງານ</h1>
            <div class="lgoin-content">

              <div class="field">
                <div class="control">
                  <label>
                    ຊື່
                    <span class="text-danger">* {{ errors.first("name") }}</span>
                    <span class="text-danger">{{ server_errors.name }}</span>
                  </label>
                  <input
                      name="name"
                      class="input is-medium"
                      placeholder="ກາລຸນາປ້ອນຊື່"
                      type="text"
                      v-model="value.name"
                      v-validate="'required|max:191'"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label>
                    ນາມສະກຸນ
                    <span class="text-danger">* {{ errors.first("surname") }}</span>
                    <span class="text-danger">{{ server_errors.surname }}</span>
                  </label>
                  <input
                      class="input is-medium"
                      placeholder="ກາລຸນາປ້ອນນາມສະກຸນ"
                      name="surname"
                      type="text"
                      v-model="value.surname"
                      v-validate="'required|max:191'"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label>
                    ເພດ
                    <span class="text-danger">*</span>
                  </label>
                  <label class="radio">
                    <input
                        type="radio"
                        name="gender"
                        class="is-checkradio has-background-color is-danger"
                        v-model="value.gender"
                        value="male"
                    />
                    ຊາຍ
                  </label>
                  <label class="radio">
                    <input
                        type="radio"
                        name="gender"
                        class="is-checkradio has-background-color is-danger"
                        v-model="value.gender"
                        value="female"
                    />
                    ຍິງ
                  </label>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label>
                    ເບີໂທ
                    <span class="text-danger">* {{ errors.first("tel") }}</span>
                    <span class="text-danger">{{ server_errors.tel }}</span>
                  </label>
                  <div class="columns">
                    <div class="column">
                      <div class="field has-addons has-addons-right">
                        <p class="control">
                          <span class="select is-medium">
                            <select v-model="telType">
                              <option>020</option>
                              <option>030</option>
                            </select>
                          </span>
                        </p>
                        <p class="control is-expanded">
                          <input
                              class="input is-medium"
                              type="tel"
                              name="tel"
                              placeholder="XXXXXXXX..."
                              v-model="value.tel"
                              v-validate="{
                                required: true,
                                numeric: true,
                                max: telType === '020' ? 8 : 7,
                                min: telType === '020' ? 8 : 7,
              }"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-btn">
              <button class="button is-red is-fullwidth" @click="ValidateForm()">
                ລົງທະບຽນ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    surname: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    tel: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      numeric: "(ປ້ອນໄດ້ພຽງຕົວເລກ...)",
      max: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວອັກສອນ...)`;
      },
      min: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວອັກສອນ...)`;
      },
    },
  },
};
Validator.localize("en", dict);
export default {
  data() {
    return {
      server_errors: {
        name: "",
        surname: "",
        tel: "",
        gender: ""
      },
      value: {
        name: "",
        surname: "",
        tel: "",
        gender: "male",
      },
      telType: "020",
    }
  },
  watch: {
    "value.tel": function () {
      this.server_errors.tel = "";
    },
  },

  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      const loading = this.BtnLoading();
      this.$http
          .post("celebrate-register", this.value)
          .then((res) => {
            if (res.data.success) {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  name: "hal.success",
                });
              }, 500);
            }
          })
          .catch((error) => {
            loading.close();
            if (error.response.status === 422) {
              var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.8",
        background: "primary",
        opacity: 1,
        color: "#E11F26",
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>
<style scoped>

h1 {
  font-weight: 400;
  line-height: 1.3em;
  font-size: 26px;
}

input:checked {
  background-color: #E11F26;
}

.select select {
  width: 100px;
}

.login-section {
  display: flex;
  min-height: 100vh;
  background-image: url("https://hal-logistics.la/images/slider/bannernews.jpeg");
  background-color: #f5f7f9;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
}

.logo-header img {
  width: 150px;
  height: 150px;
}

.logo-header {
  text-align: center;
}

.login-future h1 {
  top: 20%;
  height: 650px;
  margin: 0;
  position: fixed;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateX(-25%);
  left: 0;
  right: 0;
  text-align: center;
}

.box-btn {
  margin-top: 40px;
}

.lg-title {
  text-align: center;
  margin-bottom: 24px;
}

.login-component {
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.125);
  height: auto;
  min-height: 720px;
}

.login-content {
  padding: 0px 28px 64px 28px;
}

.parent {
  display: flex;
}

.head {
  clip-path: circle(37.7% at 16% 0);
  background-color: #E11F26;
  height: 100px;
  width: 50%;
  text-align: left;
}

.head .call {
  color: white;
  padding-top: 20px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  height: 80px;
  min-height: 100px;
  max-height: 100px;
}

.head .call i {
  font-size: 32px;
  padding-left: 8px;
}

.button.is-red {
  color: #fff;
  background-color: #E11F26;
  height: 54px;
  min-height: 54px;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 400;
}

.button {
  border: none;
  transition: all 0.125s ease-in;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.12);
}

.radio {
  display: inline-block;
  margin-left: 0.6rem;
}

label {
  display: block;
  margin-bottom: 4px;
  font-family: "Open Sans", "BoonBaan", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.input.is-medium {
  font-weight: 400;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 769px) {
  .column.hidden {
    flex: none;
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .login-future h1 {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .login-component {
    box-shadow: none;
  }

  .head {
    clip-path: circle(50.7% at 24% 0);
    height: 80px;
    min-height: 80px;
  }

  .head .call {
    padding-top: 18px;
    padding-left: 8px;
  }

  .head .call i {
    font-size: 22px;
    padding-left: 4px;
  }
}
</style>