<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-30px', marginLeft: '-30px'}">
      <ul>
        <li :class="{'is-active': isActive == 'company'}" @click="Tabs('company')">
          <a>{{ $t('Sidebar.ລູກຄ້າບໍລິສັດ') }}</a>
        </li>
        <li @click="Tabs('pre-company')" :class="{'is-active': isActive == 'pre-company'}">
          <a>Pre-company</a>
        </li>
      </ul>
    </div>

    <GeneralCustomer v-if="isActive == 'company'"/>
    <PreGeneralCustomer v-else/>
  </div>
</template>

<script>
import GeneralCustomer from './list-company';
import PreGeneralCustomer from './list-pre-company';

export default {
  components:{
    GeneralCustomer,
    PreGeneralCustomer
  },

  data:()=>({
    isActive: false,
  }),

  methods:{
    Tabs(value){
      this.isActive = value;
      this.$router.push({name: 'tab.company.customer', query:{tab: value}});
    }
  },
  created(){
    if(this.$route.query.tab == 'company'){
      this.isActive = 'company';
    }else if(this.$route.query.tab == 'pre-company'){
      this.isActive = 'pre-company';
    }else{
      this.isActive = 'company';
    }
  }
}
</script>

<style>

</style>
