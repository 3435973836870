<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ຂະໜາດ ເເລະ ນ້ຳໜັກ ຂອງພັດສະດຸ
                <span class="countTotal">({{package_sizes.length}})</span>
                <span class="btn-add">
                    <vs-button
                        circle
                        icon
                        flat
                        @click="OpenModalAdd()"
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>
                        ລຳດັບ
                    </vs-th>
                    <vs-th>
                        ຂະໜາດພັດສະດຸ
                    </vs-th>
                    <vs-th>
                        ຈຳກັດຂະໜາດ ເເລະ ນ້ຳໜັກ
                    </vs-th>
                    <vs-th>ປະເພດເເພ໋ກເເກ໋ດ</vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                    :key="index" v-for="(package_size, index) in package_sizes"
                >
                    <vs-td>
                        {{ index + 1 }}
                    </vs-td>
                    <vs-td>
                        <p class="box-type-express" v-if="package_size.package_type_id == 1">
                            {{ package_size.name }}
                        </p>
                        <p class="box-type-logistics" v-if="package_size.package_type_id == 2">
                            {{ package_size.name }}
                        </p>
                        <p class="box-type-envelope" v-if="package_size.package_type_id == 3">
                            {{ package_size.name }}
                        </p>
                    </vs-td>
                    <vs-td :style="{width: '35%'}">
                        <span v-if="package_size.package_type_id == 1">
                            ຍາວ + ກ້ວາງ + ສູງ >
                            {{package_size.round}} cm
                        </span>
                        <span v-if="package_size.package_type_id == 1">
                            ນ້ຳໜັກບໍ່ເກີນ
                            {{ package_size.start_weight }} kg
                        </span>
                        <span v-if="package_size.package_type_id == 2">
                            ນ້ຳໜັກຂົນສົ່ງ
                            {{package_size.start_weight}}
                            <span v-if="package_size.end_weight == null">kg ຂື້ນໄປ</span>
                            <span v-if="package_size.end_weight !== null">
                                - {{package_size.end_weight}} kg
                            </span>
                        </span>
                        <span v-if="package_size.package_type_id == 3">
                            ນ້ຳໜັກບໍ່ເກີນ {{package_size.end_weight}} kg
                        </span>
                    </vs-td>
                    <vs-td>
                        <span :class="[{'text-danger': package_size.typeName == 'Express'}, {'has-text-link': package_size.typeName == 'Envelope'}]">
                            ( {{package_size.typeName}} )
                        </span>
                    </vs-td>
                    <vs-td style="text-align: right; width: 100px">
                        <div class="group-icon">
                            <vs-button
                                circle
                                icon
                                flat
                                @click="OpenModalEdit(package_size.id)"
                            >
                            <i class="fad fa-pencil-alt"></i>
                            </vs-button>
                            <vs-button
                                circle
                                danger
                                icon
                                flat
                                @click="OpenModalDelete(package_size.id)"
                            >
                            <i class="fad fa-trash-alt"></i>
                            </vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
                </template>
                <template #footer>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddPackageTypes @close="close" @success="FetchData()"/>
            </template>
        </ModalAdd>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditPackageTypes :packagesize="filterPackageSize" @close="close" @success="FetchData()"/>
            </template>
        </ModalEdit>

        <ModalDelete>
            <template v-slot="{ close }">
                <DeletePackageTypes @close="close" :packageSizeId="packageSizeId" @success="FetchData()"/>
            </template>
        </ModalDelete>

    </div>
</template>
<script>
import AddPackageTypes from './Dialog/Add';
import EditPackageTypes from './Dialog/Edit';
import DeletePackageTypes from './Dialog/Delete';
import Loading from '../../Loading';


export default {
    components:{
        AddPackageTypes,
        EditPackageTypes,
        DeletePackageTypes,
        Loading
    },

    data:()=>({
        active: 1,
        page: 1,
        max: 12,
        package_sizes: [],  
        filterPackageSize: {},
        packageSizeId: '',
        isLoading: true,
    }),
    methods:{

        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
  
        OpenModalEdit(packagesizeId){
            this.filterPackageSize = {...this.FilterItem(packagesizeId)};
            this.$store.commit('modalEdit_State', true);
        },
        OpenModalDelete(packagesizeId){
            this.packageSizeId = packagesizeId;
            this.$store.commit('modalDelete_State', true);
        },
        FilterItem(packagesizeId){
            return this.package_sizes.filter(item=>{
                return item.id == packagesizeId;
            })[0] || {};
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-package-sizes').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.package_sizes = res.data.package_sizes;
                }, 100);
            }).catch(()=>{
                
            });
        },
    },
    created(){
        this.FetchData();
    }
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
    .box-type-express{
        background: $crimson;
        color: $white;
        border-radius: 2px;
        padding: 3px;
        text-align: center;
        width: 60%;
    }
    .box-type-logistics{
        background: black;
        color: $white;
        border-radius: 2px;
        padding: 3px;
        text-align: center;
        width: 60%; 
    }
    .box-type-envelope{
        background:$primary-color;
        color: $white;
        border-radius: 2px;
        padding: 3px;
        text-align: center;
        width: 60%; 
    }
}
</style>