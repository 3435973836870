import Vue from 'vue';
import axios from 'axios';
import store from '../store'
import router from "../router";

const languages = {
    "EN": 'en',
    "LA": 'la',
    "CN": 'zh'
};


// Check Auth && Set Token To Header
if (store.getters['User/isAuth']) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
}

/// Default language through query param
const urlSearchParams = new URLSearchParams(window.location.search);
const {lang} = Object.fromEntries(urlSearchParams.entries());
if (lang) {
    axios.defaults.params = {}
    axios.defaults.params['lang'] = lang;
    localStorage.setItem('Lang', lang)
} else {
    const langLocal = localStorage.getItem('Lang');
    if (langLocal) {
        axios.defaults.params = {}
        axios.defaults.params['lang'] = languages[langLocal] ?? 'la';
    }
}

export const adminAxios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL,
    }
);
export const normalAxios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL_HTTP,
    }
);
export const deliveryAxios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL_DELIVERY,
    }
);

axios.interceptors.response.use(
    response => response,
    error => {
        // Check if it's a 401 response
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            router.push('/');
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$axiosDelivery = deliveryAxios;
Vue.prototype.$axios = adminAxios;
Vue.prototype.$http = normalAxios;
