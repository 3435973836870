<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ຜູ້ໃຊ້ລະບົບ ( Deliveries ) -
        <span class="countTotal">( {{ count }} )</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <model-list-select
                  :list="deliveries"
                  v-model="selectedDelivery"
                  placeholder="ເລືອກສາຂາກ່ອນ"
                  option-text="name"
                  option-value="id"
                  selectedItem="name"
              >
              </model-list-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດຜູ້ໃຊ້</vs-th>
            <vs-th>ໂປຮໄຟລ</vs-th>
            <vs-th>ຊື່ຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ບົດບາດ</vs-th>
            <vs-th>ໜ້າທີ່</vs-th>
            <vs-th>ສາຂາ ( Delivery )</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(deliveryUser, index) in DeliveryUsers"
          >
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              <span v-if="deliveryUser.userCardid !== null">( {{ deliveryUser.userCardid }} )</span>
              <span v-else>( .......... )</span>
            </vs-td>
            <vs-td>
              <img :src="deliveryUser.url_profile" class="profile">
            </vs-td>
            <vs-td>
              {{ deliveryUser.userName }}
            </vs-td>
            <vs-td>
              {{ deliveryUser.userEmail }}
            </vs-td>
            <vs-td>
              {{ deliveryUser.userTel }}
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-if="deliveryUser.roleUser.length">
              ( {{ JoinRoleUsers(deliveryUser.roleUser) }} )
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
              (........................)
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-if="deliveryUser.permissionRole.length">
                        <span class="text-danger">
                            ( {{ JoinPermissionUsers(deliveryUser.permissionRole).substring(0, 30) + "..." }} )
                        </span>
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
              (........................)
            </vs-td>
            <vs-td>
              {{ deliveryUser.delivery_name }}
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    circle icon flat
                    @click="OpenModalChangePassword(deliveryUser.userId)">
                  <i class="fal fa-lock-alt"></i>
                </vs-button>
                <vs-button circle icon @click="PreviewLocation(deliveryUser.userDeliveryId)">
                  <i class="fal fa-map-marker-check"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(deliveryUser.userDeliveryId)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="OpenModalDelete(deliveryUser.userDeliveryId)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()">
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddDeliveriesUser @close="close" @success="FetchData()" :roleUsers="roleUsers"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditDeliveriesUser
            :listDelivery="filterDeliveryUser"
            :roleUsers="roleUsers"
            @close="close"
            @success="FetchData()"
        />
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteDeliveriesUser @close="close" :deliveryUserId="deliveryUserId" @success="FetchData()"/>
      </template>
    </ModalDelete>

    <ModalPreviewFile>
      <template v-slot="{ close }">
        <PreviewLocation @close="close" :rider="filterDeliveryUser"/>
      </template>
    </ModalPreviewFile>

    <ModalClose>
      <template v-slot="{ close }">
        <ChangePassword @close="close" :userId="userId" @success="FetchData()"/>
      </template>
    </ModalClose>

  </div>
</template>

<script>
import AddDeliveriesUser from './Dialog/Add';
import EditDeliveriesUser from './Dialog/Edit';
import DeleteDeliveriesUser from './Dialog/Delete';
import PreviewLocation from './Dialog/Preview-Location';
import Loading from '../../../Loading';
import {ModelListSelect} from 'vue-search-select';
import * as helper from '../../../../Helpers/GetValue';
import pagination from '../../../../Plugins/Paginations/Pagination';
import ChangePassword from "@views/SuperAdmin/Deliveries/Users/Dialog/ChangePassword";

export default {
  components: {
    AddDeliveriesUser,
    EditDeliveriesUser,
    DeleteDeliveriesUser,
    PreviewLocation,
    Loading,
    pagination,
    ModelListSelect,
    ChangePassword,
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    roleUsers: [],
    DeliveryUsers: [],
    filterDeliveryUser: {},
    deliveryUserId: '',
    deliveries: [],
    selectedDelivery: {},
    searchItem: '',
    oldVal: '',

    // pagination
    offset: 10,
    pagination: {},
    per_page: 10,
    count: 0,
    userId: null,
  }),

  methods: {
    PreviewLocation(deliveryUserId) {
      this.filterDeliveryUser = {...this.filterItem(deliveryUserId)};
      this.$store.commit('modalPreviewFile_State', true);
    },
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(deliveryUserId) {
      this.filterDeliveryUser = {...this.filterItem(deliveryUserId)};
      this.$store.commit('modalEdit_State', true);
    },
    OpenModalChangePassword(userId) {
      this.userId = userId;
      this.$store.commit('modalClose_State', true);
    },
    filterItem(deliveryUserId) {
      return this.DeliveryUsers.filter(item => {
        return item.userDeliveryId == deliveryUserId;
      })[0] || {};
    },
    OpenModalDelete(deliveryUserId) {
      this.deliveryUserId = deliveryUserId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-delivery-users', {
        params: {
          deliveryId: this.selectedDelivery.id,
          searchData: this.searchItem,
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.DeliveryUsers = res.data.listDeliveryUsers.data;
          this.pagination = res.data.listDeliveryUsers;
          this.count = this.pagination.total;
        }, 100);
      }).catch(() => {
      });

      this.$axios.get('list-roles').then(res => {
        var roles = ["deliverymanager", "deliveryuser"];
        this.roleUsers = res.data.roles.filter(item => roles.includes(item.name));
      });
    },
    JoinRoleUsers(roleUser) {
      return roleUser.map(item => item.roleName).join(', ');
      //return this.listBranchUsers[0].roleUser.map(i=>i.roleName).join(',');
    },
    JoinPermissionUsers(perRoles) {
      return perRoles.map(item => item.permissionName).join(', ');
    },
    FetchDelivery() {
      this.$axios.get('list-deliveries').then(res => {
        setTimeout(() => {
          this.deliveries = res.data.listDeliveries;
          this.deliveries.unshift({
            id: "",
            name: 'ທັງໝົດ'
          });
          this.selectedDelivery = {...this.deliveries[0]};
        }, 100);
      }).catch(() => {
        this.isLoading = false;
        this.$emit('close');
      });
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    }
  },
  created() {
    this.FetchDelivery();
    this.FetchData();
  },
  watch: {
    'selectedDelivery': function () {
      this.FetchData();
    },
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    }
  }
}
</script>
<style lang="scss">

.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
