<template>
  <div id="target">
    <table>
      <tr style="font-family: 'Roboto', 'BoonBaan', Arial, Helvetica, sans-serif">
        <vs-th style="display: none;">ລະຫັດ</vs-th>
        <vs-th>ເພດ</vs-th>
        <vs-th>ຊື່</vs-th>
        <vs-th>ນາມສະກຸນ</vs-th>
        <vs-th>ເບີໂທ</vs-th>
        <vs-th>ສະຖານະ</vs-th>
      </tr>
      <tr :key="index" :data="customer" v-for="(customer, index) in value">
        <td>{{ customer.c_id }}</td>
        <td>{{ prefix[customer.gender] }}</td>
        <td>{{ customer.name }}</td>
        <td>{{ customer.surname }}</td>
        <td>{{ customer.tel }}</td>
        <td>{{ status[customer.invite_status] }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "ExcelTemplate",
  data() {
    return {
      prefix: {
        'male': 'ທ້າວ',
        'female': 'ນາງ'
      },
      timer: null,
      value: [],
      status: {
        'pending': 'ລົງທະບຽນແລ້ວ',
        'joined': 'ຮ່ວມງານແລ້ວ'
      },
    }
  },
  methods: {
    PrintExcel(selected) {
      this.value = selected;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const ws1 = XLSX.utils.table_to_sheet(
            document.getElementById("target"),
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, ws1);
        XLSX.writeFile(workbook, "ລາຍງານລູກຄ້າເຂົ້າຮ່ວມມື້ເປີດງານ.xlsx");
      }, 300);
    },
  }
}
</script>

<style scoped>

</style>
