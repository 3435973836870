<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-users" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເພີ່ມຜູ້ໃຊ້ລະບົບ
      </h4>
    </template>
    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ຊື່ຜູ້ໃຊ້ລະບົບ
          <span class="text-danger">* {{ errors.first('name') }}</span>
          <span class="text-danger">
                        {{ server_errors.name }}
                    </span>
        </label>
        <input class="input" name="name" type="text"
               placeholder="ກະລຸນາປ້ອນຊື່ຜູ້ໃຊ້ລະບົບກ່ອນ..." v-model="users.name"
               v-validate="'required|min:2|max:225'">
      </div>
    </div>

    <div class="field">
      <div class="control">
        <label class="label">
          ເບີໂທລະສັບ
          <span class="text-danger">* {{ errors.first('tel') }}</span>
          <span class="text-danger">
                        {{ server_errors.tel }}
                    </span>
        </label>
        <input
            class="input"
            type="text" name="tel"
            placeholder="ກະລຸນາປ້ອນເບີກ່ອນ..."
            v-model="users.tel"
            v-validate="'required|min:6|max:11'"
        >
      </div>
    </div>

    <div class="field" :style="{marginTop: '25px'}">
      <label class="label" :style="{textAlign: 'left'}">
        ປະເພດຜູ້ໃຊ້
        <span class="text-danger">* {{ errors.first('employee_type') }}</span>
        <span class="text-danger">
                    {{ server_errors.employee_type }}
                </span>
      </label>
      <div :style="{marginTop: '5px'}">
        <div class="select">
          <select class="input" v-model="users.employee_type">
            <option value="intern_marketing">ນັກສຶກສາຝຶກງານການຕະຫຼາດ</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field" :style="{marginTop: '25px'}">
      <label class="label" :style="{textAlign: 'left'}">
        ບົດບາດ ຜູ້ໃຊ້ລະບົບ
        <span class="text-danger">* {{ errors.first('role') }}</span>
        <span class="text-danger">
                    {{ server_errors.roleId }}
                </span>
      </label>
      <div :style="{marginTop: '5px'}">
        <vs-select
            filter
            multiple
            placeholder="ກະລຸນາເລືອກບົດບາດກ່ອນ..."
            v-if="roleUsers.length"
            name="role"
            @change="ShowPermission()"
            v-model="roleId">
          <vs-option :label="roleUser.name" :value="roleUser.id" v-for="(roleUser, i) in roleUsers" :key="i">
            {{ roleUser.name }}
          </vs-option>
        </vs-select>
      </div>
    </div>

    <div v-if="permissions.length">
      <hr>
      <template>
        <h4 class="not-margin"
            :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left', marginTop: '-15px' }">
          <i class="fal fa-users" :style="{ width: '25px', fontSize: '18px' }"></i>
          ໜ້າທີ່ຂອງບົດບາດ
        </h4>
      </template>
      <div class="box-list-permission">
        <div class="permission" v-for="(permission, idx) in permissions" :key="idx">
          {{ permission.permissionName }}
        </div>
      </div>
    </div>
    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
          ລົງທະບຽນ ຜູ້ໃຊ້ລະບົບ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import MixinsAddEmployee from '../../../../Mixins/InternMarketing/Add';
import {ValidateIntern} from '@/Plugins/Validation/Validate-Intern';


export default {
  props: ['roleUsers'],

  mixins: [MixinsAddEmployee],

  created() {
    ValidateIntern(this);
  }
}
</script>

<style lang="scss" scoped>
.label-title {
  text-align: left;
  margin-top: -15px;
}

.box-list-permission {
  text-align: left;
  margin-top: 10px;
  background: rgba(var(--vs-gray-2), 1);
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  max-width: 368px;

  .permission {
    background: $primary-color;
    padding: 5px 5px 5px 5px;
    color: $white;
    border-radius: 5px;
    width: auto;
    margin: 3px;
    text-align: center;
    font-size: 14px;
  }
}

.text-filename {
  .type-file {
    color: $primary-color !important;
  }
}

.text-size {
  font-size: 14px;

  .size {
    color: $primary-color !important;
  }
}
</style>
