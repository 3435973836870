<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-12">
        <h4 class="has-text-left">
          <i class="fal fa-gifts"></i>
          ກວດສອບພັດສະດຸ
          <span class="countTotal">( {{ total }} )</span>

          <span class="btn-add">
                <div class="columns">
                    <div class="field" :style="{marginRight: '2px'}">
                     <vs-button circle icon flat
                                style="padding-right: 10px;"
                                @click="exportShipmentIssueStocksToExcel"
                                :loading="isExporting">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="18px"
                             viewBox="0 0 384 512"><path fill="currentColor"
                                                         d="M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM208 216c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88.02h-52.66c-11 0-20.59 6.41-25 16.72-4.5 10.52-2.38 22.62 5.44 30.81l68.12 71.78c5.34 5.59 12.47 8.69 20.09 8.69s14.75-3.09 20.09-8.7l68.12-71.75c7.81-8.2 9.94-20.31 5.44-30.83-4.41-10.31-14-16.72-25-16.72H208V216zm42.84 120.02l-58.84 62-58.84-62h117.68z"></path></svg>
                       <b>ລາຍການພັດສະດຸ</b>
                     </vs-button>
                    </div>
           </div>
        </span>


        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label class="label">ຄົ້ນຫາ</label>
            <input class="input" placeholder="ຄົ້ນຫາ..." v-model="searchResult" @keyup.enter="SearchBranch()">
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label class="label">ປະເພດຂໍ້ມູນ :</label>
            <div class="select">
              <select class="input" @change="fetchShipmentIssueStocks()" v-model="selectedStatus.id">
                <option :value="status.id" v-for="(status, index) in statuses" :key="index">
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ສະຖານທີ່ :</label>
            <div class="select">
              <select class="input" @change="fetchShipmentIssueStocks()" v-model="selectedStoreType.id">
                <option :value="item.id" v-for="(item, index) in storeTypes" :key="index">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-if="selectedStoreType.id === 'branch'">
          <div class="field">
            <div class="control">
              <label class="label">ສາຂາ :</label>
              <model-list-select
                  :list="branches"
                  v-model="selectedBranch"
                  placeholder="ເລືອກສາຂາ"
                  option-text="name"
                  option-value="id"
                  selectedItem="name">
              </model-list-select>
            </div>
          </div>
        </div>
        <div class="column is-4" v-if="selectedStoreType.id === 'distribution_center'">
          <div class="field">
            <div class="control">
              <label class="label">ສູນຄັດແຍກ :</label>
              <model-list-select
                  :list="distributionCenters"
                  v-model="selectedDistributionCenter"
                  placeholder="ເລືອກສູນຄັດແຍກ"
                  option-text="name"
                  option-value="id"
                  selectedItem="name">
              </model-list-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ ລາຍການ</vs-th>
            <vs-th>ວັນທີ່ສະແກນ</vs-th>
            <vs-th>ຜູ້ສະແກນ</vs-th>
            <vs-th>ສະຖານທີ່ສະແກນ</vs-th>
            <vs-th>ປາຍທາງຂອງບິນ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index" v-for="(item, index) in shipmentIssueStockOrders">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td :style="{width: '15%', color: 'crimson'}">
              ( {{ item.shipment.shipment_number }} )
            </vs-td>
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td>{{ item.created_by_user !== null ? item.created_by_user.name : '.....' }}</vs-td>
            <vs-td>{{
                item.branch != null ? item.branch.name :
                    (item.distribution_center != null ? item.distribution_center.name : '.....')
              }}
            </vs-td>
            <vs-td>{{
                item.target_branch != null ? item.target_branch.name :
                    (item.target_distribution_center != null ? item.target_distribution_center.name :
                        item.shipment.end_branch.name)
              }}
            </vs-td>
            <vs-td></vs-td>
          </vs-tr>
        </template>

        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="fetchShipmentIssueStocks()"
          ></pagination>

          <Loading v-if="isLoading"/>
        </template>

      </vs-table>
    </div>

  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import pagination from '@/Plugins/Paginations/Pagination'
import Loading from '@/views/Loading';
import moment from 'moment';
import ShipmentIssueStock from "@/Mixins/ShipmentIssueStock/ShipmentIssueStock";
import {GetValueOnInput} from '@/Helpers/GetValue';
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    DatePicker,
    pagination,
    Loading,
    ModelListSelect,
  },

  data: () => ({
    searchResult: '',
    oldVal: '',
  }),

  methods: {
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    SearchBranch() {
      GetValueOnInput(this);
    },
    FetchData() {
      this.fetchShipmentIssueStocks();
    }
  },

  mixins: [ShipmentIssueStock],

}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 100px;
    height: 60px;
    border-radius: 5px;
  }
}
</style>
