export default {
    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },
        branches: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },
        profile: '',
        fileName: '',
        distributionCenters: [],
        selectDistributionCenter: {},

        parts: [],
        provinces: [],
        districts: [],
        villages: [],

        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},
        selectedVillage: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        checkVillage: true,

        types: [
            {id: 'branch', name: 'ສາຂາທົ່ວໄປ'},
            {id: 'franchise', name: 'ສາຂາເເຟນຊາຍ'},
            {id: 'delivery', name: 'ສາຂາດີລີເວີລີ່'},
            {id: 'local_branch', name: 'ຈຸດສູນແຈກຢາຍ'},
            {id: 'post_box', name: 'ກ່ອງໄປສະນີ'},
            {id: 'central', name: 'ສູນແກ້ໄຂ'},
            {id: 'supervisor_central', name: 'ສູນແກ້ໄຂໃຫຍ່'},
        ],
        selectedType: {id: 'branch', name: 'ສາຂາທົ່ວໄປ'},

        FormatPart_To_Laos: {
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1',
        },

        FormatZoneToLaos: {
            lo: 'ລາວ',
            th: 'ໄທ',
            ch: 'ຈີນ',
        },

        // default to Montreal to keep it simple
        // change this to whatever makes sense
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        selectLatLng: null,
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,

        // Service-Zone
        checkedAllServiceZone: false,
        checkedServiceZone: [],
    }),

    methods: {
        CheckedAllServiceZone() {
            this.checkedServiceZone = [];
            if (!this.checkedAllServiceZone) {
                for (let i in this.villages) {
                    this.checkedServiceZone.push(this.villages[i].village_id);
                }
            }
        },
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },

        focusFromLagLng() {
            if (this.selectLatLng) {
                const lagLng = this.selectLatLng.split(',');
                if (lagLng.length > 1) {
                    this.center.lat = parseFloat(lagLng[0]);
                    this.center.lng = parseFloat(lagLng[1]);
                    this.placeMarker();
                }
            }
        },
        // receives a place object via the autocomplete component
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({position: marker});
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({position: marker});
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });
        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },
        FetchData() {
            this.$axios.get('list-branch-address').then(res => {
                const items = res.data.data;

                this.parts = items.parts;
                this.selectedPart = {...this.parts[0]};
                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;

            }).catch(() => {
            });
        },
        FetchDistributionCenter() {
            this.isLoading = true;
            this.$http.get('v1/listing/distribution_centers').then(res => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(() => {
                    this.distributionCenters = res.data;
                    this.selectDistributionCenter = {...this.distributionCenters[0]};
                }, 100);
            }).catch(() => {
            });
        },

        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                } else {
                    this.$scrollTo('#scrollTo');
                }
            });
        },
        SaveItem() {
            this.branches.villageId = this.selectedVillage.village_id;
            this.branches.type = this.selectedType.id;
            this.branches.lat = this.center.lat;
            this.branches.lng = this.center.lng;
            this.branches.fileName = this.fileName;
            this.branches.serviceZone = this.checkedServiceZone;
            this.branches.distributionCenterId = this.selectDistributionCenter.id;

            this.btnLoading = true;
            this.$axios.post('register-branches', this.branches).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'list.branches', params: {type: 'all'}});
                    }, 300);
                } else {
                    this.$notification.OpenNotification_Error_Server('top-right', 'danger', 3000);
                }
            }).catch(error => {
                this.btnLoading = false;
                this.$scrollTo('#scrollTo');
                if (error.response.status == 422) {
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FilterProvince(partId) {
            const result_checking = this.filterProvinces.filter(item => {
                if (item.part_id == partId) {
                    return item;
                }
            });
            this.provinces = result_checking;
            this.selectedProvince = {...this.provinces[0]};
        },
        FilterDistricts(provinceId) {
            const result_checking = this.filterDistricts.filter(item => {
                if (item.province_id == provinceId) {
                    return item;
                }
            });
            this.districts = result_checking;
            this.selectedDistrict = {...this.districts[0]};
        },
        FilterVillages(districtId) {
            const result_checking = this.filterVillages.filter(item => {
                if (item.district_id == districtId) {
                    return item;
                }
            });
            this.villages = result_checking;
            this.selectedVillage = {...this.villages[0]};
            /// Fetch auto id
            this.fetchBranchCode(districtId);
        },

        // Upload Profile
        triggerInputClick() {
            let selector = this.$refs.file;
            if (selector) {
                selector.click();
            }
        }, // Trigger-Click

        async onFileChange(e) {
            const file = e.target.files[0];
            const FileSize = (file.size / 1024 / 1024).toFixed(2);
            const extentsions = file.type.split("/");
            const validate_Ext = this.ValidateExtensions(extentsions[1]);

            if (validate_Ext == true && FileSize < 6) {
                this.profile = file;
                this.UploadFile();
            }
        }, // OnFile-Change

        UploadFile() {
            this.btnLoading = true;
            let formData = new FormData();
            formData.append('imageFile', this.profile);

            this.$axios.post('upload-file', formData).then((res) => {
                this.fileName = res.data.data.fileName;
                this.btnLoading = false;
            }).catch(() => {
                this.btnLoading = false;
            });
        }, // Upload-File

        FormatByte(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }, // FormatByte

        ValidateExtensions(ext) {
            if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp') {
                return true;
            } else {
                return false;
            }
        },
        fetchBranchCode(districtId) {
            this.$http.get('v1/auth/branches/branch/codes-mapper',
                {
                    params: {
                        "district_id": districtId,
                        "type": this.selectedType.id
                    }
                }).then((res) => {
                const data = res.data;
                if (data) {
                    this.branches.prefix = data.code;
                }
            }).catch(() => {
                this.branches.prefix = null;
            });
        },
    },

    watch: {
        'selectedPart.partId': function (partId) {
            this.FilterProvince(partId);
        },
        'selectedProvince.proId': function (provinceId) {
            this.FilterDistricts(provinceId);
        },
        'selectedDistrict.distId': function (districtId) {
            this.FilterVillages(districtId);
        },

        // Clear-Errors
        'selectedVillage.village_id': function () {
            this.server_errors.villageId = '';
        },
        'branches.prefix': function () {
            this.server_errors.prefix = '';
        },
        'branches.name': function () {
            this.server_errors.name = '';
        },
        'branches.email': function () {
            this.server_errors.email = '';
        },
        'branches.tel': function () {
            this.server_errors.tel = '';
        },
        'checkedServiceZone': function (value) {
            if (value.length == this.villages.length) {
                this.checkedAllServiceZone = true;
                if (this.villages.length) {
                    this.checkedAllServiceZone = true;
                } else {
                    this.checkedAllServiceZone = false;
                }
            } else {
                this.checkedAllServiceZone = false;
            }
        },
        'center.lat': function () {
            this.selectLatLng = this.center.lat + ',' + this.center.lng
        }
    },
    mounted() {
        this.geolocate();
    },
}
