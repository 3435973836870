import Vue from 'vue';

const CallNotification = Vue.prototype;

export const OpenNotification_AddItem_OnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ບັນທຶກຂໍ້ມູນ ສຳເລັດເເລ້ວ...',
        text: 'ການບັນທຶກຂໍ້ມູນຂອງທ່ານ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_EditItem_OnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເເກ້ໄຂຂໍ້ມູນ ສຳເລັດເເລ້ວ...',
        text: 'ການເເກ້ໄຂຂໍ້ມູນຂອງທ່ານ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_DeleteItem_OnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ການລຶບຂໍ້ມູນ ສຳເລັດເເລ້ວ...',
        text: 'ການລຶບຂໍ້ມູນຂອງທ່ານ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_DeleteItem_OnUnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ການລຶບຂໍ້ມູນຜິດພາດ...',
        text: 'ອາດມີຮ້ານຄ້າສາຂາ ຫຼື ພະນັກງານຍັງຂຶ້ນກັບຮ້ານ...'
    });
}

export const openNotification = (data, position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: data.title || '',
        text: data.text || '',
    });
}

export const OpenNotification_DeleteItem_CannotDelete = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ການລຶບຂໍ້ມູນ ເກີດຂໍ້ຜິດພາດ...',
        text: 'ເເພ໋ກເເກ໋ດນີ້ ຖືກນຳໃຊ້ເເລ້ວ...'
    });
}

export const OpenNotification_Error_Server = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເກີດຂໍ້ຜິດພາດ...',
        text: 'ການບັນທຶກຂໍ້ມູນ ເກີດຂໍ້ຜິດພາດ...'
    });
}


export const OpenNotification_Error = (position, color, duration, text) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເກີດຂໍ້ຜິດພາດ...',
        text: text,
    });
}


export const OpenNotification_CloseItem_OnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ການປິດຂໍ້ມູນ ສຳເລັດເເລ້ວ...',
        text: 'ການປິດຂໍ້ມູນຂອງທ່ານ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_ReopenItem_OnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ການເປິດຂໍ້ມູນ ສຳເລັດເເລ້ວ...',
        text: 'ການເປິດຂໍ້ມູນຂອງທ່ານ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_Login_Success = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເຂົ້າສູ່ລະບົບ...',
        text: 'ການເຂົ້າສູ່ລະບົບ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_Logout_Success = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ອອກຈາກລະບົບ...',
        text: 'ການອອກຈາກລະບົບ ສຳເລັດເເລ້ວ...'
    });
}

export const OpenNotification_Msg_StartBranch = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເເຈ້ງເຕືອນກວດສອບສາຂາ...',
        text: 'ກະລຸນາກວດສອບສາຂາຕົ້ນທາງ ຫຼື ປາຍທາງກ່ອນ...'
    });
}

export const OpenNotification_Msg_Error_Provinces = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເເຈ້ງເຕືອນກວດສອບເເຂວງ...',
        text: 'ກະລຸນາກວດສອບເເຂວງຕົ້ນທາງ ຫຼື ປາຍທາງກ່ອນ...'
    });
}

export const OpenNotification_Msg_Error_CheckedCustomers = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເເຈ້ງເຕືອນກວດສອບລູກຄ້າກ່ອນ...',
        text: 'ກະລຸນາເລືອກລູກຄ້າ ທີ່ຕ້ອງການເເຈ້ງເຕືອນກ່ອນ...'
    });
}

export const OpenNotification_SignIn_Error_Server = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເກີດຂໍ້ຜິດພາດ...',
        text: 'ກະລຸນາກວດສອບ ລະຫັດຜ່ານ ຫຼື ອີເມວ...'
    });
}

export const OpenNotification_Approved_VIP = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ອະນຸມັດສຳເລັດ...',
        text: 'ລູກຄ້າດັ່ງກ່າວໄດ້ກາຍເປັນລູກຄ້າ VIP'
    });
}

export const OpenNotification_Celebrate_Joined = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເຂົ້າຮ່ວມສຳເລັດ...',
        text: 'ລູກຄ້າດັ່ງກ່າວໄດ້ເຂົ້າຮ່ວມງານແລ້ວ'
    });
}

export const OpenNotification_Reject_OrderRequests = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ຍົກເລີກລາຍການ...',
        text: 'ການຍົກເລີກລາຍການຂອງລູກຄ້າ ສຳເລັດເເລ້ວ'
    });
}

export const OpenNotification_Reject_OrderRequests_Errors = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເກີດຂໍ້ຜິດພາດ...',
        text: 'ການຍົກເລີກລາຍການຂອງລູກຄ້າ ເກີດຂໍ້ຜິດພາດ'
    });
}

export const OpenNotification_Cancel_SuccessOrder_Success = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ສຳເລັດ...',
        text: 'ການຍົກເລີກບິນສຳເລັດ'
    });
}

export const OpenNotification_New_Message = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ມີຂໍ້ຄວາມໃໝ່',
        text: 'ທ່ານມີຂໍ້ຄວາມໃໝ່ຈາກລູກຄ້າ'
    });
}

export const OpenNotification_confirm_PrizeResult_Success = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ສຳເລັດ...',
        text: 'ການຢຶນຢັນສຳເລັດ'
    });
}
export const OpenNotification_confirm_PrizeResult_UnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ຜິດພາດ...',
        text: 'ການຢຶນຢັນບໍ່ສຳເລັດ'
    });
}

export const OpenNotification_Cancel_SuccessOrder_UnSuccess = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ຜິດພາດ...',
        text: 'ການຍົກເລີກບິນບໍ່ສຳເລັດ'
    });
}

export const OpenNotification_CheckRoleExists = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ບໍ່ສາມາດລຶບອອກ...',
        text: 'ລາຍການນີ້ ຖືກນຳໃຊ້ເເລ້ວ'
    });
}

export const OpenNotification_CheckTypeImage = (position, color, duration, text) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ເກີດຂໍ້ຜິດພາດ...',
        text: text
    });
}

export const OpenNotification_ImportFile_Result_Success = (position, color, duration) => {
    CallNotification.$vs.notification({
        duration,
        color,
        position,
        title: 'ສຳເລັດ...',
        text: 'ການ Import ຂໍ້ມູນສຳເລັດ'
    });
}
