<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-history"></i>
          ປະຫວັດການເປີດກ່ອງ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຂອງລາງວັນ:</label>
            <div class="select">
              <select class="input" v-model="prize_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="point">ພ້ອຍ</option>
                <option value="object">ຄຳຄົມ</option>
                <option value="prize">ຂອງລາງວັນ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="status" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="pending">ຍັງບໍ່ໄດ້ຮັບ</option>
                <option value="received">ໄດ້ຮັບແລ້ວ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px" v-if="prizes.length > 0">
          <div v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])">
            <vs-button
                ref="button" flat
                @click="printPrizeResult()"
                class="form-btn-loading"
                :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ປະເພດ</vs-th>
            <vs-th>ລູກຄ້າ</vs-th>
            <vs-th>ວັນທີເປີດໄດ້</vs-th>
            <vs-th>ວັນທີໄດ້ຮັບ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th v-if="$can(['SuperAdmin','marketing','marketinghelper'])"></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="prize"
              v-for="(prize, index) in $vs.getPage(prizes, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ prize.name }}</vs-td>
            <vs-td>{{ laType[prize.type] }}</vs-td>
            <vs-td>{{ prize.customer_name }}</vs-td>
            <vs-td>{{ moment(prize.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td v-if="prize.status === 'received'">
              {{ moment(prize.updated_at).format('DD/MM/YYYY H:mm') }}
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ laStatus[prize.status] }}</vs-td>
            <vs-td style="text-align: right; width: 100px"
                   v-if="$can(['SuperAdmin','marketing','marketinghelper']) && prize.status === 'pending'">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="OpenModalEdit(prize.id)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td v-else></vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <ModalAdd>
      <template v-slot="{ close }">
        <AddShop @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <EditPrize @close="close"
                   :prize_result_id="item"
                   @success="FetchData()"/>
      </template>
    </ModalEdit>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import AddShop from "@/views/ExchangeItem/Shop/Dialog/Add"
import EditPrize from "@/views/Prize/Dialog/confirmCustomerGetPrize"
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Loading,
    pagination,
    AddShop,
    EditPrize,
    DatePicker
  },

  data: () => ({
    laStatus: {
      'pending': 'ຍັງບໍ່ໄດ້ຮັບ',
      'received': 'ໄດ້ຮັບແລ້ວ'
    },
    laType: {
      'object': 'ສິ່ງຂອງ',
      'point': 'ພ້ອຍ'
    },
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    page: 1,
    max: 10,
    isLoading: true,
    loading: false,
    prizes: [],
    item: '',
    prize_type: '',
    status: '',
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },
  methods: {
    printPrizeResult() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('export/prize/result', {
        start_date: this.start_date,
        end_date: this.end_date,
        search: this.searchItem,
        prize_type: this.prize_type,
        status: this.status,
        isApp: 'no',
        isPrint: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ລາຍການປະຫວັດເປີດກ່ອງສຸ່ມ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ລາຍການປະຫວັດເປີດກ່ອງສຸ່ມ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.prizes = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/prize/result', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          search: this.searchItem,
          prize_type: this.prize_type,
          status: this.status,
          isApp: 'no',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    OpenModalEdit(prize) {
      this.item = JSON.parse(JSON.stringify(prize));
      this.$store.commit("modalEdit_State", true);
    },
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
