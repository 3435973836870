<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-user"></i>
          ຂໍ້ມູນພ/ງຮ້ານຮ່ວມລາຍການ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
      <div class="column is-6" v-if="$can(['SuperAdmin','marketing','marketinghelper'])">
        <vs-button circle icon flat @click="OpenModalAdd()"
                   style="padding-left: 8px; padding-right: 8px; float: right">
          <i class="fal fa-plus-circle"></i>
        </vs-button>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຮ້ານ:</label>
            <model-list-select
                :list="listShops"
                v-model="selectedShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
                readonly
                onfocus="this.removeAttribute('readonly');"
            >
            </model-list-select>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th v-if="$can(['SuperAdmin','marketing','marketinghelper'])"></vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ອິເມລ</vs-th>
            <vs-th>ຮ້ານຄ້າ</vs-th>
            <vs-th>ປະເພດຜູ້ໃຊ້</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="shopUser"
              v-for="(shopUser, index) in $vs.getPage(shopUsers, page, max)">
            <vs-td style="text-align: right; width: 100px" v-if="$can(['SuperAdmin','marketing','marketinghelper'])">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="OpenModalEdit(shopUser)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
                <vs-button
                    circle icon flat
                    @click="OpenModalChangePassword(shopUser.id)">
                  <i class="fal fa-lock-alt"></i>
                </vs-button>
                <vs-button danger
                           circle icon flat @click="OpenModalCancel(shopUser)">
                  <i class="fad fa-trash"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ shopUser.name }}</vs-td>
            <vs-td>{{ shopUser.surname }}</vs-td>
            <vs-td>{{ shopUser.tel }}</vs-td>
            <vs-td>{{ shopUser.email }}</vs-td>
            <vs-td>{{ shopUser.shop_name }}</vs-td>
            <vs-td v-if="shopUser.role.length">
              {{ JoinRoleUsers(shopUser.role) }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <ModalAdd>
      <template v-slot="{ close }">
        <AddShop @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <EditShop @close="close"
                  :item="item"
                  @success="FetchData()"/>
      </template>
    </ModalEdit>
    <ModalCancel>
      <template v-slot="{ close }">
        <DeleteShopUser @close="close"
                    :item="item"
                    @success="FetchData()"/>
      </template>
    </ModalCancel>
    <ModalClose>
      <template v-slot="{ close }">
        <ChangePassword @close="close" :userId="userId" @success="FetchData()"/>
      </template>
    </ModalClose>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import AddShop from "@/views/ExchangeItem/ShopUser/Dialog/Add"
import EditShop from "@/views/ExchangeItem/ShopUser/Dialog/Edit"
import {ModelListSelect} from 'vue-search-select';
import ChangePassword from './Dialog/ChangePassword';
import DeleteShopUser from "@/views/ExchangeItem/ShopUser/Dialog/DeleteShopUser"
export default {
  components: {
    Loading,
    pagination,
    AddShop,
    EditShop,
    ModelListSelect,
    ChangePassword,
    DeleteShopUser
  },

  data: () => ({
    selectedShop: '',
    listShops: [],
    page: 1,
    max: 10,
    userId: '',
    isLoading: true,
    shop: '',
    shopUsers: [],
    item: {},
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'selectedShop': function () {
      this.FetchData();
    },
  },
  methods: {
    JoinRoleUsers(roleUser) {
      return roleUser.map(item => item.name).join(', ');
    },
    FetchShop() {
      this.$http.get('list/shop/' + '?type=other&status=open').then((res) => {
        this.listShops = res.data;
        this.listShops.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.shopUsers = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('shop_user', {
        params: {
          search: this.searchItem,
          shop_id: this.selectedShop,
          shop_status: 'open',
          shop_type: 'other',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    OpenModalEdit(shop) {
      this.item = JSON.parse(JSON.stringify(shop));
      this.$store.commit("modalEdit_State", true);
    },
    OpenModalChangePassword(userId) {
      this.userId = JSON.parse(JSON.stringify(userId));
      this.$store.commit('modalClose_State', true);
    },
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    OpenModalCancel(shop) {
      this.item = JSON.parse(JSON.stringify(shop));
      this.$store.commit("modalCancel_State", true);
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
    this.FetchShop();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
