<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ສາຂາເດີລິເວີລີ ທີ່ສາມາດເຊື່ອມໂຍງໄປຫາ
        <span class="countTotal">({{ branchDeliveryLinked.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="openAddLinkModal()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຈາກສາຂາເດລິເວີລີ່</vs-th>
            <vs-th>ສາຂາຈັດສົ່ງ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in branchDeliveryLinked" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ item.delivery_name }}</vs-td>
            <vs-td>{{ item.branch_name }}</vs-td>
            <vs-td>
              <vs-button circle icon flat danger
                         @click="openDeleteModal(item.destination_id)">
                <i class="fad fa-trash-alt"></i>
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddLinking @close="close" @success="fetchAllData" :branches="filterBranches"
                    :branch_delivery_id="branchDeliveryId"/>
      </template>
    </ModalAdd>
    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteLinked @close="close" @success="fetchAllData"
                      :branch_delivery_id="branchDeliveryId"
                      :id="targetDestinationId"/>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import AddLinking from './Dialog/Add';
import DeleteLinked from './Dialog/Delete';
import Loading from '@/views/Loading';

export default {
  components: {
    AddLinking,
    DeleteLinked,
    Loading
  },

  data: () => ({
    branches: [],
    branchDeliveryLinked: [],
    branchDeliveryId: '',
    targetDestinationId: null,
    isLoading: false,
  }),

  methods: {
    tabs(tab) {
      this.isActive = tab;
    },
    openAddLinkModal() {
      this.$store.commit('modalAdd_State', true);
    },
    openDeleteModal(id) {
      this.targetDestinationId = id;
      this.$store.commit('modalDelete_State', true);
    },
    fetchBranches() {
      this.isLoading = true;
      this.$http.get('v1/listing/branches?type=branch_franchise').then(({data}) => {
        this.branches = data;
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false;
      });
    },
    fetchData() {
      this.isLoading = true;
      this.$axios.get('branch-delivery-linked/' + this.$route.params.id).then(({data}) => {
        this.branchDeliveryLinked = data;
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false;
      });
    },
    fetchAllData() {
      this.branchDeliveryId = this.$route.params.id;
      this.fetchBranches();
      this.fetchData();
    },
  },
  computed: {
    filterBranches() {
      return this.branches.filter((item) => {
        const exists = this.branchDeliveryLinked.filter((_item) => _item.destination_id === item.id);
        return !(exists || []).length;
      });
    }
  },
  created() {
    this.fetchAllData();
  },

}
</script>
