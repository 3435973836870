<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ແກ້ໄຂຮ້ານຄ້າ
      </h4>
    </template>

    <!-- name -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ຊື່
          <span class="text-danger">* {{ errors.first("name") }}</span>
          <span class="text-danger">
              {{ server_errors.name }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="name"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="item.name"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- type -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ຮ້ານຄ້າ
          <span class="text-danger">* {{ errors.first("type") }}</span>
          <span class="text-danger">
              {{ server_errors.type }}
            </span>
        </label>
        <div class="columns">
          <div class="field column" v-if="$can(['SuperAdmin','marketing'])">
            <div class="control">
              <vs-radio v-model="item.type" val="hal">ຮຸ່ງອາລຸນ</vs-radio>
            </div>
          </div>
          <div class="field column">
            <div class="control">
              <vs-radio v-model="item.type" val="other">ເຊັນສັນຍາ</vs-radio>
            </div>
          </div>
<!--          <div class="field column">-->
<!--            <div class="control">-->
<!--              <vs-radio v-model="item.type" val="event">ກິດຈະກຳ</vs-radio>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>

    <!--type-->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ປະເພດຮ້ານຄ້າ
        </label>
        <div class="columns">
          <div class="field column">
            <div class="control">
              <vs-radio v-model="item.is_parent" val="yes">ຮ້ານຫຼັກ</vs-radio>
            </div>
          </div>
          <div class="field column">
            <div class="control">
              <vs-radio v-model="item.is_parent" val="no">ຮ້ານສາຂາ</vs-radio>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- status -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ສະຖານະຮ້ານ
          <span class="text-danger">* {{ errors.first("type") }}</span>
          <span class="text-danger">
              {{ server_errors.type }}
            </span>
        </label>
        <div class="columns">
          <div class="field column">
            <div class="control">
              <vs-radio v-model="item.status" val="open">ເປີດບໍລິການ</vs-radio>
            </div>
          </div>

          <div class="field column">
            <div class="control">
              <vs-radio v-model="item.status" val="close">ປິດບໍລິການ</vs-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--parent_ID-->
    <div class="field column" style="margin-bottom: -10px" v-if="isAble">
      <div class="control">
        <label class="label">
          ຮ້ານຫຼັກ
        </label>
        <div class="columns">
          <div class="field column">
            <div class="control">
              <model-list-select
                  :list="listShops"
                  v-model="item.parent_id"
                  placeholder="ເລືອກຮ້ານ"
                  option-text="name"
                  option-value="id"
                  selectedItem="name"
              >
              </model-list-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- tel -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ເບີໂທ
          <span class="text-danger"
          >* {{ errors.first("tel") }}</span
          >
          <span class="text-danger">
              {{ server_errors.tel }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="tel"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="item.tel"
            v-validate="'required|min:4|max:11|numeric'"
        />
      </div>
    </div>

    <!-- icon -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ຮູບ
          <span class="text-danger"
          > {{ errors.first("icon") }}</span
          >
          <span class="text-danger">
              {{ server_errors.icon }}
            </span>
        </label>
        <div class="box-upload-file" @click="triggerInputClick()" v-if="!images.base64">
          <div class="text-msg">
            <i class="fal fa-cloud-upload"></i>
            <p class="choose">
              ກະລຸນາເລືອກຮູບ
            </p>
          </div>
        </div>
        <div class="has-profile" @click="triggerInputClick()" :style="{ backgroundImage: `url(${images.base64})`}"
             v-if="images.base64"></div>

        <div v-show="false" class="control">
          <input
              @change="onFileChange" ref="file"
              class="input input-ctn" type="file" name="icon"
              v-validate="'mimes:image/*|size:5120'"
          >
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >ແກ້ໄຂຮ້ານຄ້າ
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";
import {ModelListSelect} from 'vue-search-select';
const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    tel: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      min: "(ປ້ອນຢ່າງຕ່ຳ 4 ຕົວເລກ)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 11 ຕົວເລກ)",
      numeric: "(ກະລຸນາປ້ອນເປັນຕົວເລກ)"
    },
  },
};
Validator.localize("en", dict);

export default {
  props: [
    'item',
  ],

  components:{
    ModelListSelect
  },

  data() {
    return {
      active: false,
      isLoading: false,
      loading: false,
      listShops: [],
      isAble: false,
      server_errors: {
        name: "",
        tel: "",
        icon: ""
      },
      images: {
        fileName: '',
        base64: '',
        size: '',
        file: '',
        type: '',
      }
    };
  },
  created() {
    this.isAble = this.item.is_parent === 'no';
    this.FetchShop();
    this.images.base64 = this.item.icon;
  },
  methods: {
    FetchShop() {
      this.$http.get('list/shop/' + '?isParent=yes&type=' + this.item.type).then((res) => {
        this.listShops = res.data;
      })
    },
    triggerInputClick() {
      let selector = this.$refs.file;
      if (selector) {
        selector.click();
      }
    }, // Trigger-Click
    async onFileChange(e) {
      const file = e.target.files[0];
      const FileSize = (file.size / 1024 / 1024).toFixed(2);
      const extensions = file.type.split("/");
      const validate_Ext = this.ValidateExtensions(extensions[1]);

      if (validate_Ext === true && FileSize < 6) {
        this.images.file = file;
        this.images.type = extensions[1];
        this.images.size = this.FormatByte(file.size);
        this.images.base64 = await this.ToBase64(file);
      }
    }, // OnFile-Change
    ToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }, // Convert-Base64
    FormatByte(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }, // FormatByte

    ValidateExtensions(ext) {
      return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'bmp';
    },

    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.loading = true;
      let formData = new FormData();
      formData.append('name', this.item.name);
      formData.append('type', this.item.type);
      formData.append('status', this.item.status);
      formData.append('tel', this.item.tel);
      formData.append('is_parent', this.item.is_parent);
      formData.append('icon', this.images.file);
      (this.item.is_parent === 'yes')
          ? formData.append('parent_id', '')
          : formData.append('parent_id', this.item.parent_id)
      formData.append('_method', 'put')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.$http.post('edit/shop/' + this.item.id, formData, config).then(() => {
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
  watch: {
    "item.name": function () {
      this.server_errors.name = "";
    },
    "item.is_parent": function () {
      this.isAble = this.item.is_parent === 'no';
    },
  },
};
</script>
<style lang="scss" scoped>
.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;

  &:hover {
    cursor: pointer;
  }
}

.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}
</style>
