<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນຜູ້ໃຊ້ລະບົບອື່ນໆ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="openModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ປະເພດຜູ້ໃຊ້:</label>
            <div class="select">
              <select class="input" v-model="employeeType" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="service">ພາບລັກ ແລະ ປ່ອງບໍລິການ</option>
                <option value="distribution">ຄວບຄຸມສູນຄັດແຍກ</option>
                <option value="manager">ຜູ້ບໍລິຫານ</option>
                <option value="marketing">ການຕະຫຼາດ</option>
                <option value="news">ຂ່າວສານ</option>
                <option value="head_intern_marketing">ຜູ້ຕິດຕາມນັກສຶກສາຝຶກງານການຕະຫຼາດ</option>
                <option value="marketing_helper">ຜູ້ຊ່ວຍປ້ອນຂໍ້ມູນຂອງລາງວັນ</option>
                <option value="suburb_marketing">ການຕະຫຼາດຕ່າງແຂວງ</option>
                <option value="hal_live">HAL LIVE</option>
                <option value="marketing_viewer">ການຕະຫຼາດຕິດຕາມຍອດບິນ</option>
                <option value="head_franchise">ຫົວໜ້າແຟນຊາຍ</option>
                <option value="hr">HR</option>
                <option value="other">ອື່ນໆ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດຜູ້ໃຊ້</vs-th>
            <vs-th>ຊື່ຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ຕຳແໜ່ງ</vs-th>
            <vs-th>ບົດບາດຂອງຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ໜ້າທີ່ຂອງບົດບາດ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listEmployees"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ item.userCardid }} )</vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ item.email }}</vs-td>
            <vs-td>{{ item.tel }}</vs-td>
            <vs-th>{{ la_type[item.employee_type] }}</vs-th>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }" v-if="item.roleUser.length">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ JoinRoleUsers(item.roleUser) }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else></vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }" v-if="item.permissionRole.length">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ JoinPermissionUsers(item.permissionRole) }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else></vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <!--                <vs-button circle icon flat @click="printQr(item)">-->
                <!--                  <i class="fad fa-print"></i>-->
                <!--                </vs-button>-->
                <vs-button
                    circle icon flat
                    @click="OpenModalChangePassword(item.userId)">
                  <i class="fal fa-lock-alt"></i>
                </vs-button>
                <vs-button circle icon flat @click="openModalEdit(item.userId)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="openModalDelete(item.userId)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()"
          >
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalClose>
      <template v-slot="{ close }">
        <ChangePassword @close="close" :userId="userId" @success="FetchData()"/>
      </template>
    </ModalClose>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddEmployee @close="close" @success="FetchData()" :roleUsers="roleUsers"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditEmployee :listEmployee="item" :roleUsers="roleUsers" @close="close"
                      @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteEmployee @close="close" :itemId="itemId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>
<script>
import AddEmployee from './Dialog/Add';
import EditEmployee from './Dialog/Edit';
import DeleteEmployee from './Dialog/Delete';
import Loading from '../../Loading';
import * as helper from '../../../Helpers/GetValue';
import pagination from '../../../Plugins/Paginations/Pagination';
import ChangePassword from "@views/SuperAdmin/Branches/Users/Dialog/ChangePassword.vue";


export default {
  components: {
    ChangePassword,
    AddEmployee,
    EditEmployee,
    DeleteEmployee,
    Loading,
    pagination
  },

  data: () => ({
    isLoading: true,
    employeeType: '',
    roleUsers: [],
    listEmployees: [],
    item: {},
    itemId: '',
    userId: '',
    searchItem: '',
    oldVal: '',
    la_type: {
      'service': 'ພາບລັກ ແລະ ປ່ອງບໍລິການ',
      'distribution': 'ຄວບຄຸມສູນຄັດແຍກ',
      'marketing': 'ການຕະຫຼາດ',
      'manager': 'ຜູ້ບໍລິຫານ',
      'news': 'ຂ່າວສານ',
      'head_intern_marketing': 'ຜູ້ຕິດຕາມນັກສຶກສາຝຶກງານການຕະຫຼາດ',
      'marketing_helper': 'ຜູ້ຊ່ວຍປ້ອນຂໍ້ມູນຂອງລາງວັນ',
      'suburb_marketing': 'ການຕະຫຼາດຕ່າງແຂວງ',
      'hal_live': 'HAL LIVE',
      'marketing_viewer': 'ຕິດຕາມຍອດບິນ',
      'head_franchise': 'ຫົວໜ້າແຟນຊາຍ',
      'hr': 'HR',
      'other': 'ອື່ນໆ'
    },
    // pagination
    offset: 10,
    pagination: {},
    per_page: 10,
    count: 0,
  }),
  methods: {
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
    // printQr(item) {
    //   const loading = this.BtnLoading();
    //   this.loading = true;
    //   this.$http.post('print/qr/intern-marketing', {
    //         reference_id: item.userId,
    //       },
    //       {responseType: 'blob'}
    //   ).then((response) => {
    //     const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
    //     const fileLink = document.createElement('a');
    //     fileLink.href = fileUrl;
    //     fileLink.setAttribute('download', 'QR INTERN MARKETING' + item.name + '.xlsx');
    //     document.body.appendChild(fileLink);
    //     fileLink.click();
    //     document.body.removeChild(fileLink);
    //     this.loading = false;
    //     loading.close()
    //   }).catch(() => {
    //     this.loading = false;
    //     loading.close()
    //   })
    // },
    OpenModalChangePassword(userId) {
      this.userId = userId;
      this.$store.commit('modalClose_State', true);
    },

    openModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    openModalEdit(itemId) {
      this.item = {...this.filterItem(itemId)};
      this.$store.commit('modalEdit_State', true);
    },
    filterItem(itemId) {
      return this.listEmployees.filter(item => {
        return item.userId === itemId;
      })[0] || {};
    },
    openModalDelete(itemId) {
      this.itemId = itemId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$http.get('list/employee', {
        params: {
          employee_type: this.employeeType,
          searchData: this.searchItem,
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listEmployees = res.data.data;
          this.pagination = res.data;
          this.count = res.data.total
        }, 100);
      }).catch(() => {
      });

      this.$axios.get('list-roles').then(res => {
        const roles = ["service", "marketing", "manager", "distribution", "news", "headinternmarketing",
          "marketinghelper","suburbMarketing","halcenterlive", "marketing-viewer", "head-franchise", "hr", "other", "TaxViewer", "importer", "injured-parcel-user",
        "settle-shipment-status-manager", "cleared-shipment-status-manager", "data-viewer" ];
        this.roleUsers = res.data.roles.filter(item => roles.includes(item.name));
      });
    },
    JoinRoleUsers(roleUser) {
      return roleUser.map(item => item.roleName).join(', ');
    },
    JoinPermissionUsers(perRoles) {
      return perRoles.map(item => item.permissionName).join(', ');
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    }
  },
  created() {
    this.FetchData();
  },
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    }
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
