export default {

    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            bag_bill_number: '',
        },
        parcelBag: {
            bag_bill_number: '',
        },

    }), // Data

    methods: {
        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }
            });
        }, // ValidateForm

        SaveItem() {
            this.btnLoading = true;

            this.$http.post('v1/auth/branches/shipments/orders/tracking/packing-parcel-orders/create-reusable-parcel-bag',
                this.parcelBag).then(() => {
                setTimeout(() => {
                    this.btnLoading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                }, 300);
            }).catch(error => {
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            }).finally(() => {
                this.btnLoading = false;
            });
        },
    },
    watch: {
        'parcelBag.bag_bill_number': function () {
            this.server_errors.bag_bill_number = '';
        },
    },
}
