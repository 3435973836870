<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-gifts"></i>
          ຂໍ້ມູນການແລກຂອງລາງວັນ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FilterByShipmentNumber()"
              >
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຮ້ານ:</label>
            <div class="select">
              <select class="input" v-model="shop_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="hal">ຮ້ານຄ້າຮຸ່ງອາລຸນ</option>
                <option value="other">ຮ້ານຄ້າເຊັນສັນຍາ</option>
                <option value="event">ກິດຈະກຳ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຮ້ານ:</label>
            <model-list-select
                :list="listShops"
                v-model="selectedShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຂອງລາງວັນ:</label>
            <div class="select">
              <select class="input" v-model="item_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="item">ຂອງລາງວັນ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຂອງລາງວັນ:</label>
            <model-list-select
                :list="listShopItems"
                v-model="selectedShopItem"
                placeholder="ເລືອກລາຍການ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">FLASH SALE:</label>
            <div class="select">
              <select class="input" v-model="is_flash_sale" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="on">ລາຍການ FLASH SALE</option>
                <option value="off">ລາຍການທົ່ວໄປ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ແລກຢູ່ສາຂາ:</label>
            <model-list-select
                :list="branches"
                v-model="selectedBranch"
                placeholder="ເລືອກສາຂາ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="exchange_status" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="yes">ແລກສຳເລັດ</option>
                <option value="no">ລໍຖ້າດຳເນີນການ</option>
                <option value="cancel">ຍົກເລີກ</option>
                <option value="expired">ໝົດອາຍຸ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <div v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])">
            <vs-button
                ref="button" flat
                @click="printListPointDetail()"
                class="form-btn-loading"
                :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ຈັດການ</vs-th>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ວັນທີລູກຄ້າແລກ</vs-th>
            <vs-th>ວັນທີພ/ງສະແກນ</vs-th>
            <vs-th>ຮ້ານ</vs-th>
            <vs-th>ລາຍການ</vs-th>
            <vs-th>ລູກຄ້າ</vs-th>
            <vs-th>ຈຳນວນຄະແນນ</vs-th>
            <vs-th>ສາຂາທີ່ລູກຄ້າເລືອກໄປເອົາ</vs-th>
            <vs-th>ແລກໂດຍ</vs-th>
            <vs-th>ສາຂາສະແກນ</vs-th>
            <vs-th>FLASH SALE</vs-th>
            <vs-th>ສະຖານະ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="exchangeItem"
              v-for="(exchangeItem, index) in $vs.getPage(exchangeItems, page, max)">
            <vs-td style="text-align: right; width: 100px" v-if="$can(['SuperAdmin','marketing','marketinghelper']) && exchangeItem.status === 'no'">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="OpenModalEdit(exchangeItem.id)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ moment(exchangeItem.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td v-if="exchangeItem.exchange_at">{{
                moment(exchangeItem.exchange_at).format('DD/MM/YYYY H:mm')
              }}
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ exchangeItem.shop_name }}</vs-td>
            <vs-td>{{ exchangeItem.shop_item_name }}</vs-td>
            <vs-td>{{ exchangeItem.customer_name }}</vs-td>
            <vs-td>{{ FormatPrice(exchangeItem.price) }}</vs-td>
            <vs-td v-if="exchangeItem.pick_up_name">{{ exchangeItem.pick_up_name }}</vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td v-if="exchangeItem.created_by_name">{{ exchangeItem.created_by_name }}</vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td v-if="exchangeItem.branch_name">{{ exchangeItem.branch_name }}</vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td>{{ la_flash_sale[exchangeItem.is_flash_sale] }}</vs-td>
            <vs-td>{{ la_status[exchangeItem.status] }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <ModalDelete>
      <template v-slot="{ close }">
        <cancelExchange @close="close"
                  :exchangeId="exchangeId"
                  @success="FetchData()"/>
      </template>
    </ModalDelete>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {ModelListSelect} from 'vue-search-select';
import cancelExchange from "@/views/ExchangeItem/Dialog/Delete"
export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect,
    cancelExchange
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: true,
    listShops: [],
    listShopItems: [],
    selectedShop: '',
    selectedBranch: '',
    selectedPickUp: '',
    is_flash_sale: '',
    branches: [],
    pickUp: [],
    exchangeItems: [],
    searchItem: "",
    exchange_status: '',
    selectedShopItem: '',
    oldVal: "",
    item_type: '',
    shop_type: '',
    exchangeId:'',
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    la_status: {
      'yes': 'ແລກສຳເລັດ',
      'no': 'ລໍຖ້າດຳເນີນການ',
      'expired': 'ໝົດກຳນົດ',
      'cancel': 'ຍົກເລີກ'
    },
    la_flash_sale: {
      'on': 'FLASH SALE',
      'off': 'ທົ່ວໄປ',
    },

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'shop_type': function () {
      this.FetchShop();
      this.FetchShopItem();
      this.FetchBranches();
    },
    'selectedShop': function () {
      this.pagination.current_page = 1;
      this.FetchShopItem();
      this.FetchData();
    },
    'selectedShopItem': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'selectedBranch': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'selectedPickUp': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },

  methods: {
    OpenModalEdit(exchangeId) {
      this.exchangeId = JSON.parse(JSON.stringify(exchangeId));
      this.$store.commit("modalDelete_State", true);
    },
    FilterByShipmentNumber() {
      helper.GetOldValueOnInput(this);
    },
    FetchShop() {
      this.$http.get('list/shop/' + '?type=' + this.shop_type).then((res) => {
        this.listShops = res.data;
        this.listShops.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FetchShopItem() {
      this.$http.get('list/shop/item', {
        params: {
          shop_type: this.shop_type,
          shop_id: this.selectedShop
        }
      }).then((res) => {
        this.listShopItems = res.data;
        this.listShopItems.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    printListPointDetail() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/list/exchange/item', {
        search: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
        branch_id: this.selectedBranch,
        shop_type: this.shop_type,
        shop_id: this.selectedShop,
        item_type: this.item_type,
        item_id: this.selectedShopItem,
        exchange_status: this.exchange_status,
        is_flash_sale: this.is_flash_sale,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ລາຍການແລກຂອງລາງວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ລາຍການແລກຂອງລາງວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "") + " ຄະແນນ";
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    FetchIssuerBranch() {
      this.$http.get('v1/listing/' + 'branches' + '?include=excluded').then((res) => {
        this.branches = res.data;
        this.branches.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    FetchPickUpBranch() {
      this.$http.get('v1/listing/' + 'branches' + '?include=excluded').then((res) => {
        this.pickUp = res.data;
        this.pickUp.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.exchangeItems = res.data.data.data;
          this.count = res.data.data.total;
          this.pagination = res.data.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/exchange/item', {
        params: {
          search: this.searchItem,
          start_date: this.start_date,
          end_date: this.end_date,
          branch_id: this.selectedBranch,
          pick_up: this.selectedPickUp,
          shop_type: this.shop_type,
          shop_id: this.selectedShop,
          item_type: this.item_type,
          item_id: this.selectedShopItem,
          is_flash_sale: this.is_flash_sale,
          exchange_status: this.exchange_status,
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchIssuerBranch();
    this.FetchPickUpBranch();
    this.FetchShop();
    this.FetchShopItem();
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
