export default {

    data:()=>({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            villageId: '',
        },

        parts: [],
        provinces: [],
        districts: [],
        villages: [],

        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},
        selectedVillage: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        checkVillage: true,
        types: [
            {id: 1, type: 'branch'},
            {id: 2, type: 'franchise'}
        ],
        selectedType: {},
        FormatPart_To_Laos:{
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1'
        },
        delivery:{},

        // default to Montreal to keep it simple
        // change this to whatever makes sense
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,
    }),

    methods:{
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },

        // receives a place object via the autocomplete component
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({ position: marker });
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({ position: marker });
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });
        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }else{
                    this.$scrollTo('#scrollTo');
                }
            });
        }, // Validate-Form

        EditItem(){
            this.delivery.villageId = this.selectedVillage.village_id;
            this.delivery.lat = this.center.lat;
            this.delivery.lng = this.center.lng;

            this.btnLoading = true;
            this.$axios.put('edit-deliveries', this.delivery).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'list.deliveries'});
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                this.$scrollTo('#scrollTo');
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }, // EditItems

        FetchData(){
            this.$axios.get('list-branch-address').then(res=>{
                const items = res.data.data;

                this.parts = items.parts;
                this.provinces = items.provinces;
                this.districts = items.districts;
                this.villages = items.villages;

                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;

            }).catch(()=>{});
        }, // FetchData-To-Server

        FetchDelivery(){
            this.$axios.get('list-edit-deliveries/' + this.$route.params.id).then(res=>{
                this.delivery = res.data.listDeliveries;
                this.selectedPart.partId = this.delivery.partId;
                this.selectedType.type = this.delivery.type;
                this.FilterProvinces(this.delivery.partId);

                if (!this.delivery.lat && !this.delivery.lng) {
                    this.geolocate();
                } else {
                    this.center.lat = this.delivery.lat;
                    this.center.lng = this.delivery.lng;
                    this.placeMarker();
                }
            }).catch(()=>{});
        },

        FilterProvinces(partId){
            console.log(partId);
            const result = this.filterProvinces.filter(item=>{
                if(item.part_id == partId){
                    return item;
                }
            });
            this.provinces = result;
            if(this.delivery.partId == partId){
                this.selectedProvince.proId = this.delivery.proId;
            }else{
                this.selectedProvince = {...this.provinces[0]};
            }
            this.FilterDistricts(this.selectedProvince.proId);
        }, // Filter-Provinces

        FilterDistricts(proId){
            const result = this.filterDistricts.filter(item=>{
                if(item.province_id == proId){
                    return item;
                }
            });
            this.districts = result;
            this.selectedDistrict.distId = this.delivery.distId;
            if(this.delivery.proId == proId){
                this.selectedDistrict.distId = this.delivery.distId;
            }else{
                this.selectedDistrict = {...this.districts[0]};
            }
            this.FilterVillages(this.selectedDistrict.distId);
        }, // Filter-Distircts

        FilterVillages(distId){
            const result = this.filterVillages.filter(item=>{
                if(item.district_id == distId){
                    return item;
                }
            });
            this.villages = result;
            if(this.delivery.distId == distId){
                this.selectedVillage.village_id = this.delivery.village_id;
            }else{
                this.selectedVillage = {...this.villages[0]};
            }
        }, // Filter-Villages
    },
    watch: {
        // Clear-Errors
        'selectedVillage.village_id':function(){
            this.server_errors.villageId = '';
        },
        'delivery.prefix':function(){
            this.server_errors.prefix = '';
        },
        'delivery.name':function(){
            this.server_errors.name = '';
        },
        'delivery.email':function(){
            this.server_errors.email = '';
        },
        'delivery.tel':function(){
            this.server_errors.tel = '';
        },
    }
}
