export default {
    data:()=>({
        isLoading: true,
        page: 1,
        max: 15,
        listOrderRequests: [],
        orderRequestId: '',
        searchOrderId: '',
        filterOrderRequest: {},

        toDate: new Date(),
        fromDate: new Date(),
        selectedStatus: {value: 'all', name: 'ທັງໝົດ'},
        statuses: [
            {value: 'all', name: 'ທັງໝົດ'},
            {value: 'pending', name: 'ລໍຖ້າອະນຸມັດ'},
            {value: 'confirm', name: 'ອະນຸມັດເເລ້ວ'},
            {value: 'on_the_way', name: 'ກຳລັງໄປຮັບເຄື່ອງ'},
            {value: 'success', name: 'ສຳເລັດເເລ້ວ'},
            {value: 'reject', name: 'ຖືກປະຕິເສດ'},
            {value: 'cancel', name: 'ຍົກເລີກເເລ້ວ'}
        ],
    }),
    methods: {
        ReportOrderRequests(){
            this.isLoading = true;
            this.$axiosDelivery.post('report-order-requests',{
                fromDate: this.fromDate,
                toDate: this.toDate,
                status: this.selectedStatus.value
            }).then((res)=>{
                setTimeout(()=>{
                    this.isLoading = false;
                    this.listOrderRequests = res.data.filterOrderRequests;
                }, 300);
            }).catch(()=>{
                this.isLoading = false;
            });
        },
        OpenModalViewOrderRequestProcessing(orderRequestId){
            this.filterOrderRequest = this.FilterOrderRequests(orderRequestId);
            this.$store.commit('modalPreviewFile_State', true);
        },
        FilterOrderRequests(orderRequestId){
            return this.listOrderRequests.filter(item=>{
                return item.id == orderRequestId;
            })[0] || {};
        },
    },
    watch:{
        'fromDate':function(){
            this.ReportOrderRequests();
        },
        'toDate':function(){
            this.ReportOrderRequests();
        }
    }
}