import client from "axios";
import router from "../router/index";

const axios = client.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

const state = {
  token: localStorage.getItem("access_token") || null,
  userProfile: {},
  msgErrors: "",
};

const getters = {
  LoggedIn(state) {
    return state.token !== null;
  },
  getUserType(state) {
    let profile = window.localStorage.getItem("user_profile");
    if (profile) {
      try {
        profile = JSON.parse(profile);
      } catch (err) {
        profile = {};
      }
    } else {
      profile = {};
    }

    if (Object.keys(state.userProfile).length) {
      return state.userProfile;
    }
    return profile.role_user;
  },
  getToken(state) {
    return state.token;
  },
  isAuth(state) {
    return state.token && state.token !== null;
  },
};

const mutations = {
  AdminSignin(state, token) {
    state.token = token;
  },
  destroyToken(state) {
    state.token = null;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  Commit_ErrorLogin(state, payload) {
    state.msgErrors = payload;
  },
};

const actions = {
  LoginUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("sign-in", {
          email: data.email,
          password: data.password,
        })
        .then((response) => {
          const token = response.data.access_token;
          localStorage.setItem("access_token", token); // ເກັບ Token ໄວ້ໃນ Localstorage ເພື່ອຈະນຳໄປໃຊ້ຂໍຂໍ້ມູນ
          context.commit("AdminSignin", token);
          context.commit("setUserProfile", response.data.authUser);
          window.localStorage.setItem(
            "user_profile",
            JSON.stringify(response.data.authUser)
          );
          window.localStorage.setItem(
            "roleUser",
            JSON.stringify(response.data.roleUser)
          );
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
          if (error.response.status == 401) {
            context.commit(
              "Commit_ErrorLogin",
              "ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ..."
            );
            setTimeout(() => {
              context.commit("Commit_ErrorLogin", "");
            }, 3000);
          } else if (error.response.status == 400) {
            context.commit(
              "Commit_ErrorLogin",
              "ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ..."
            );
            setTimeout(() => {
              context.commit("Commit_ErrorLogin", "");
            }, 3000);
          }
        });
    });
  },

  // Logout User
  destroyToken(context) {
    if (context.getters.LoggedIn) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
        axios
          .post("logout")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally((response) => {
            resolve(response);
            localStorage.removeItem("access_token"); // Remove Item Of Localstorage...
            localStorage.removeItem("user_profile"); // Remove Item Of Localstorage...
            localStorage.removeItem("roleUser"); // Remove Item Of Localstorage...
            context.commit("destroyToken");
            router
              .push({
                name: "sign-in",
              })
              .catch(() => {});
          });
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
