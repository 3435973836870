<template>
  <div>
    <div class="field" :style="{marginTop: '30px'}">
      <div class="field">
        <div class="control">
          <label class="label">ໝາຍເຫດ</label>
          <input
              class="input"
              v-model="invoice_remark"
              placeholder="ໝາຍເຫດ..."
          >
        </div>
      </div>
    </div>
    <vs-button ref="button" flat
               @click="updateCodInvoiceStatus()"
               class="form-btn-loading"
               :loading="loading">
      ເເກ້ໄຂສະຖານະ
    </vs-button>
  </div>
</template>

<script>

export default {
  props: [
    'invoiceIds',
  ],
  data: () => ({
    loading: false,
    invoice_remark: '',
    statusOptions: [
      {value: 'pending', name: 'ກຳລັງດຳເນີນການ'},
      {value: 'success', name: 'ສຳເລັດ'},
    ],
    selectedStatus: {
      value: 'pending', name: 'ເລືອກທັງໝົດ'
    },
  }),
  methods: {
    updateCodInvoiceStatus() {
      const invoiceIds = this.invoiceIds.map(item => item.id);
      this.loading = true;
      this.$http.post('admin/update-cod-balance-request-status', {
        invoices: invoiceIds,
        remark: this.invoice_remark,
      }).then((res) => {
        if (res.data.error === false){
          setTimeout(() => {
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 200);
        }
      }).catch(() => {
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>
