<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ຜູ້ໃຊ້ລະບົບ Call-Center
        <span class="countTotal">({{ listCallCenterUsers.length }})</span>
        <span class="btn-add">
          <vs-button circle icon flat @click="OpenModalAdd()">
              <i class="fal fa-plus-circle"></i>
          </vs-button>
        </span>
      </h4>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column is-4">
                <div class="field">
                    <div class="control">
                        <input
                            class="input"
                            placeholder="ຄົ້ນຫາ..."
                            v-model="searchItem"
                            @keyup.enter="FilterData()"
                        >
                    </div>
                </div>
            </div>
            <div class="column is-4">
                <div class="field">
                    <div class="control">
                        <model-list-select
                            :list="listCallCenters"
                            v-model="selectedListCallCenter"
                            placeholder="ເລືອກສາຂາກ່ອນ"
                            option-text="name"
                            option-value="id"
                            selectedItem="name"
                        >
                        </model-list-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ໂປຮໄຟລ</vs-th>
            <vs-th>ຊື່ຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ບົດບາດ</vs-th>
            <vs-th>ໜ້າທີ່</vs-th>
            <vs-th>ສາຂາ ( Call-Center )</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(callCenterUsers, index) in listCallCenterUsers"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td :style="{color: 'crimson'}">
              <span v-if="callCenterUsers.userCardid !== null">( {{callCenterUsers.userCardid}} )</span>
              <span v-else>( ............... )</span>
            </vs-td>
            <vs-td>
              <img :src="callCenterUsers.url_profile" class="profile">
            </vs-td>
            <vs-td>
              {{ callCenterUsers.userName }}
            </vs-td>
            <vs-td>
              {{ callCenterUsers.userEmail }}
            </vs-td>
            <vs-td>
              {{ callCenterUsers.userTel }}
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-if="callCenterUsers.roleUser.length">
              ( {{ JoinRoleUsers(callCenterUsers.roleUser) }} )
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
              (........................)
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-if="callCenterUsers.permissionRole.length">
              ( {{ JoinPermissionUsers(callCenterUsers.permissionRole) }} )
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
              (........................)
            </vs-td>
            <vs-td>
              {{callCenterUsers.callcenter_name}}
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    circle icon flat
                    @click="OpenModalChangePassword(callCenterUsers.userId)">
                  <i class="fal fa-lock-alt"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(callCenterUsers.callCenterUserId)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="OpenModalDelete(callCenterUsers.callCenterUserId)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="FetchData()"
          >
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalClose>
      <template v-slot="{ close }">
        <ChangePassword @close="close" :userId="userId" @success="FetchData()"/>
      </template>
    </ModalClose>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddCallCenterUser @close="close" @success="FetchData()" :roleUsers="roleUsers"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditCallCenterUser
            :listCallCenterUser="filterCallCenterUser"
            :roleUsers="roleUsers"
            @close="close"
            @success="FetchData()"
        />
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteCallCenterUser
            @close="close"
            :callCenterUserId="callCenterUserId"
            @success="FetchData()"
        />
      </template>
    </ModalDelete>

  </div>
</template>
<script>
import AddCallCenterUser from './Dialog/Add';
import EditCallCenterUser from './Dialog/Edit';
import DeleteCallCenterUser from './Dialog/Delete';
import Loading from '../../../Loading';
import { ModelListSelect } from 'vue-search-select';
import * as helper from '../../../../Helpers/GetValue';
import pagination from '../../../../Plugins/Paginations/Pagination';
import ChangePassword from "@views/SuperAdmin/Branches/Users/Dialog/ChangePassword.vue";


export default {
  components: {
    ChangePassword,
    AddCallCenterUser,
    EditCallCenterUser,
    DeleteCallCenterUser,
    Loading,
    ModelListSelect,
    pagination
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    userId: '',
    roleUsers: [],
    listCallCenterUsers: [],
    filterCallCenterUser: {},
    callCenterUserId: '',
    listCallCenters: [],
    selectedListCallCenter: {},
    searchItem: '',
    oldVal: '',

    // pagination
    offset: 10,
    pagination: {},
    per_page: 10
  }),

  methods: {
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(callCenterUserId) {
      this.filterCallCenterUser = {...this.FilterItem(callCenterUserId)};
      this.$store.commit('modalEdit_State', true);
    },
    FilterItem(callCenterUserId) {
      return this.listCallCenterUsers.filter(item => {
        return item.callCenterUserId == callCenterUserId;
      })[0] || {};
    },
    OpenModalChangePassword(userId) {
      this.userId = userId;
      this.$store.commit('modalClose_State', true);
    },
    OpenModalDelete(callCenterUserId) {
      this.callCenterUserId = callCenterUserId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-call-center-users', {
        params:{
          callCenterId: this.selectedListCallCenter.id,
          searchData: this.searchItem,
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listCallCenterUsers = res.data.listCallCenterUsers.data;
          this.pagination = res.data.listCallCenterUsers;
        }, 100);
      }).catch(() => {
        this.isLoading = false;
        this.$emit('close');
      });

      this.$axios.get('list-roles').then(res => {
        var roles = ["callcentervipcustomer", "chat-admin"];
        this.roleUsers = res.data.roles.filter(item => roles.includes(item.name));
      });
    },
    JoinRoleUsers(roleUser) {
      return roleUser.map(item => item.roleName).join(', ');
    },
    JoinPermissionUsers(perRoles) {
      return perRoles.map(item => item.permissionName).join(', ');
    },
    FetchCallCenter(){
      this.isLoading = true;
      this.$axios.get('list-call-centers').then(res=>{
        setTimeout(()=>{
          this.listCallCenters = res.data.ListCallCenters;
          this.listCallCenters.unshift({
              id: "",
              name: 'ທັງໝົດ'
          });
          this.selectedListCallCenter = {...this.listCallCenters[0]};
        }, 100);
      }).catch(()=>{});
    },
    FilterData(){
      helper.GetOldValueOnInput(this);
    }
  },
  OpenModalChangePassword(userId) {
    this.userId = userId;
    this.$store.commit('modalClose_State', true);
  },
  created() {
    this.FetchCallCenter();
    this.FetchData();
  },
  watch:{
    'selectedListCallCenter': function(){
      this.FetchData();
    },
    'searchItem': function(value){
      if(value == ''){
        this.FetchData();
      }
    }
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
