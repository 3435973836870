<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ເລືອກຮູບພາບ
      </h4>
    </template>

    <!-- icon -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ຮູບ
          <span class="text-danger"
          > {{ errors.first("image") }}</span
          >
          <span class="text-danger">
              {{ server_errors.image }}
            </span>
        </label>
        <div class="box-upload-file" @click="triggerInputClick()" v-if="!images.base64">
          <div class="text-msg">
            <i class="fal fa-cloud-upload"></i>
            <p class="choose">
              ກະລຸນາເລືອກຮູບ
            </p>
          </div>
        </div>
        <div class="has-profile" @click="triggerInputClick()" :style="{ backgroundImage: `url(${images.base64})`}"
             v-if="images.base64"></div>

        <div v-show="false" class="control">
          <input
              @change="onFileChange" ref="file"
              class="input input-ctn" type="file" name="icon"
              v-validate="'mimes:image/*|size:10240'"
          >
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >ສົ່ງຮູບພາບ
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    image: {
      mimes: "ປະເພດຮູບບໍ່ຖືກຕ້ອງ",
      size: "ຂະໜາດຮູບໄດ້ສູງສຸດ 10 MB",
    },
  },
};
Validator.localize("en", dict);
export default {
  props: [
    'chatRoomId',
    'messages'
  ],
  data() {
    return {
      active: false,
      isLoading: false,
      loading: false,
      isAble: false,
      server_errors: {
        image: ""
      },
      value: {
        image: [],
      },
      images: {
        fileName: '',
        base64: '',
        size: '',
        file: '',
        type: '',
      }
    };
  },
  methods: {
    triggerInputClick() {
      let selector = this.$refs.file;
      if (selector) {
        selector.click();
      }
    }, // Trigger-Click
    async onFileChange(e) {
      const file = e.target.files[0];
      const FileSize = (file.size / 1024 / 1024).toFixed(2);
      const extensions = file.type.split("/");
      const validate_Ext = this.ValidateExtensions(extensions[1]);

      if (validate_Ext === true && FileSize < 6) {
        this.images.file = file;
        this.images.type = extensions[1];
        this.images.size = this.FormatByte(file.size);
        this.images.base64 = await this.ToBase64(file);
      }
    }, // OnFile-Change
    ToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }, // Convert-Base64
    FormatByte(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }, // FormatByte

    ValidateExtensions(ext) {
      return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'bmp';
    },

    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      if (this.images.file !== '') {
        this.loading = true;
        let formData = new FormData();
        formData.append('image[]', this.images.file);
        formData.append('chatroom_id', this.chatRoomId);
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
        this.$http.post('send-message', formData, config).then((res) => {
          this.loading = false;
          this.messages.push(res.data.data.chatMessage);
          console.log(this.messages);
          this.$emit('close');
          this.$emit('success');
        }).catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
        });
      }
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
    FetchShop() {
      this.$http.get('list/shop/' + '?isParent=yes&type=' + this.value.type).then((res) => {
        this.listShops = res.data;
      })
    },
  },
  created() {
    if (this.$can('marketinghelper')) {
      this.value.type = 'other'
    } else {
      this.value.type = 'hal'
    }
    this.FetchShop();
  },
  mounted() {
    if (this.$can('marketinghelper')) {
      this.value.type = 'other'
    } else {
      this.value.type = 'hal'
    }
  },
  watch: {
    "value.name": function () {
      this.server_errors.name = "";
    },
    "value.isParent": function () {
      this.isAble = this.value.isParent === 'no';
    },
    "value.type": function () {
      this.FetchShop();
    },
  },
};
</script>
<style lang="scss" scoped>
.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;

  &:hover {
    cursor: pointer;
  }
}

.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}
</style>
