const Helpers = {}
Helpers.install = (Vue) => {
    const helpers = {
        numberFormatter(x) {
            return x ? x.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : 0
        },
    }
    Vue.prototype.$helpers = { ...helpers }
}
export default Helpers