<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-users" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມຜູ້ໃຊ້ລະບົບ
            </h4>
        </template>

        <div class="box-upload">
            <div class="box-img" @click="triggerInputClick">
                <img src="../../../../assets/user-permission.png" class="img" v-if="!images.base64">
                <img :src="images.base64" class="img" v-else>
                <div v-show="false" class="control">
                    <input
                        @change="onFileChange"
                        ref="file"
                        class="input input-ctn"
                        type="file"
                        name="user_profile"
                        v-validate="'required|mimes:jpeg,jpg,gif,png|size:5120'"
                        accept="image/png,image/gif,image/jpeg,image/jpg"
                    >
                </div>
            </div>
        </div>
        <div class="text-upload-img">
            <div class="text-filename">
                <p v-if="!errors.first('user_profile')">
                    <span v-if="!images.file">ເລືອກຮູບໂປຮໄຟລ</span>
                    <span v-else>ປະເພດໄຟລ:
                        <span class="type-file">{{images.type}}</span>
                    </span>
                </p>
                <span class="text-danger" v-if="errors.first('user_profile')">{{ errors.first('user_profile') }}</span>
            </div>
        </div>
        <p class="text-size" v-if="!errors.first('user_profile')">
            ຂະໜາດ: <span class="size">{{images.size !== '' ? images.size : '...... MB'}}</span>
        </p>
        <div class="field" :style="{marginTop: '15px'}">
            <div class="control">
                <label class="label">
                    ຊື່ຜູ້ໃຊ້ລະບົບ
                    <span class="text-danger">* {{ errors.first('username') }}</span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input class="input" name="username" type="text"
                placeholder="ກະລຸນາປ້ອນຊື່ຜູ້ໃຊ້ລະບົບກ່ອນ..." v-model="users.name"
                v-validate="'required|min:2|max:225'">
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ອີເມວ
                    <span class="text-danger">* {{ errors.first('user_email') }}</span>
                    <span class="text-danger">
                        {{server_errors.email}}
                    </span>
                </label>
                <input class="input" type="text" name="user_email" placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..."
                v-model="users.email" v-validate="'required|email|min:5|max:255'">
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເບີໂທລະສັບ
                    <span class="text-danger">* {{ errors.first('user_tel') }}</span>
                    <span class="text-danger">
                        {{server_errors.user_tel}}
                    </span>
                </label>
                <input
                    class="input"
                    type="text" name="user_tel"
                    placeholder="ກະລຸນາປ້ອນເບີກ່ອນ..."
                    v-model="users.user_tel"
                    v-validate="'required|min:7|max:15'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ລະຫັດຜ່ານ
                    <span class="text-danger">* {{ errors.first('user_password') }}</span>
                    <span class="text-danger">
                        {{server_errors.password}}
                    </span>
                </label>
                <PasswordInput v-model="users.password" name="user_password"
                             v-validate="'required|min:6|max:25'"
                             placeholder="ກະລຸນາປ້ອນລະຫັດຜ່ານກ່ອນ..." />
            </div>
        </div>

        <div class="field" :style="{marginTop: '25px'}">
            <label class="label" :style="{textAlign: 'left'}">
                ບົດບາດ ຜູ້ໃຊ້ລະບົບ
                <span class="text-danger">* {{ errors.first('role') }}</span>
                <span class="text-danger">
                    {{server_errors.roleId}}
                </span>
            </label>
            <div :style="{marginTop: '5px'}">
                <vs-select
                    filter
                    multiple
                    placeholder="ກະລຸນາເລືອກບົດບາດກ່ອນ..."
                    v-if="roleUsers.length"
                    name="role"
                    @change="ShowPermission()"
                    v-model="roleId">
                    <vs-option :label="roleUser.name" :value="roleUser.id" v-for="(roleUser, i) in roleUsers" :key="i">
                    {{roleUser.name}}
                    </vs-option>
                </vs-select>
            </div>
        </div>

        <div v-if="permissions.length">
            <hr>
            <template>
                <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left', marginTop: '-15px' }">
                    <i class="fal fa-users" :style="{ width: '25px', fontSize: '18px' }"></i>
                    ໜ້າທີ່ຂອງບົດບາດ
                </h4>
            </template>
            <div class="box-list-permission">
                <div class="permission" v-for="(permission, idx) in permissions" :key="idx">
                    {{permission.permissionName}}
                </div>
            </div>
        </div>
        <div ref="target" id="target" class="center">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
                    ລົງທະບຽນ ຜູ້ໃຊ້ລະບົບ
                </vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import MinxinsAddUser from '../../../../Mixins/Accountings/Add';
import { ValidateAccountUser } from '../../../../Plugins/Validation//Validate-AccountingUser';


export default {
    props: ['roleUsers'],

    mixins:[MinxinsAddUser],

    created(){
        ValidateAccountUser(this);
    }
}
</script>

<style lang="scss" scoped>
    .label-title{
        text-align: left;
        margin-top: -15px;
    }
    .box-list-permission{
        text-align: left;
        margin-top: 10px;
        background: rgba(var(--vs-gray-2), 1);
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        max-width: 368px;
        .permission{
            background: $primary-color;
            padding: 5px 5px 5px 5px;
            color: $white;
            border-radius: 5px;
            width: auto;
            margin: 3px;
            text-align: center;
            font-size: 14px;
        }
    }

    .box-upload{
        display: flex;
        margin-top: 25px;
        .box-img{
            margin: auto;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
            width: 105px;
            height: 105px;
            border-radius: 50px;
            &:hover{
                cursor: pointer;
            }
            .img{
                width: 100px;
                height: 100px;
                border-radius: 50px;
                margin-top: 3px;
            }
        }
    }
    .text-upload-img{
        margin-top: 10px;
        max-width: 100%;
        .msg{
            color: $crimson;
        }
    }
    .text-filename{
        .type-file{
            color: $primary-color !important;
        }
    }
    .text-size{
        font-size: 14px;
        .size{
            color: $primary-color !important;
        }
    }
</style>
