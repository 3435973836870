<template>
  <div class="container">
    <div class="box">
       <div class="field">
          <input class="input" placeholder="ຄົ້ນຫາເລກທີບີນ..." v-model="shipment_number" @keyup.enter="FilterShipmentNumber()">
      </div>
      <div class="center">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ລວມເປັນເງິນທັງໝົດ</vs-th>
              <vs-th>ລວມຈຳນວນບິນ</vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :is-selected="true">
              <vs-td>{{ FormatPrice(summary.total_price) }}</vs-td>
              <vs-td>{{ count }} ບີນ</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ວັນທີສ້າງອິນວອຍ</vs-th>
            <vs-th>ຜູ້ສ້າງບິນ</vs-th>
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ຈຳນວນເງິນ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              v-for="(item, index) in invoiceDetails" :key="index"
          >
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td>{{ item.created_by }}</vs-td>
            <vs-td>{{ item.shipment_number }}</vs-td>
            <vs-td>{{ item.qty || 0 }}</vs-td>
            <vs-td>{{ item.weight || 0 }}</vs-td>
            <vs-td>{{ item.start_branch }}</vs-td>
            <vs-td>{{ item.end_branch }}</vs-td>
            <!-- <vs-td>{{item.account_name}}</vs-td> -->
            <vs-td>{{ FormatPrice(item.paid) }}</vs-td>
            <vs-td>{{ la_status[item.invoice_detail_status] }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchDataCompany"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

  </div>
</template>

<script>
import mixinInvoiceCompanyDetail from '../../../Mixins/Accountings/Invoices/Details/Company-Detail';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
  components: {
    pagination
    // mixinInvoiceCODDetail
  },

  mixins: [mixinInvoiceCompanyDetail],

  created() {
    this.FetchDataCompany();
  }
}
</script>

<style lang="scss" scoped>
.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.box-summary {
  .box-total-price {
    border: 1px solid $crimson;
    // background: $crimson;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
  }

  .box-total-amount {
    border: 1px solid $primary-color;
    // background: $primary-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .box-total-shipment-bill {
    // background: $sub-color;
    border: 1px solid $sub-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }
}
</style>
