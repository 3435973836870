<template>
  <div class="container" :style="{marginTop: '5px'}">
    <div class="box-title">
      <h4>
        <i class="fal fa-info-circle"></i>
        ຂໍ້ມູນລູກຄ້າ VIP ລໍຖ້າການອະນຸມັດ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
            <div class="columns">
              <label style="margin-top: 15px; margin-right: 10px" class="label">
                ຄົ້ນຫາ
              </label>
              <input
                  style="margin-top: 5px; margin-right: 8px"
                  class="input"
                  type="text"
                  name="search"
                  v-model="search"
              />
            </div>
          </span>
      </h4>
    </div>
    <div class="center fit-width">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດລູກຄ້າ</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ທະນາຄານ</vs-th>
            <vs-th>ຊື່ບັນຊີ</vs-th>
            <vs-th>ເລກບັນຊີ</vs-th>
            <vs-th>ບັດປະຈຳຕົວ</vs-th>
            <vs-th>ປຶ້ມສຳມະໂນຄົວ</vs-th>
            <vs-th>ພາສປອຣ໌ຕ</vs-th>
            <vs-th>ເອກະສານສັນຍາ</vs-th>
            <vs-th>ບ້ານ</vs-th>
            <vs-th>ເມືອງ</vs-th>
            <vs-th>ແຂວງ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(customer, index) in $vs.getPage(customers, page, max)"
              :data="customer">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ customer.customer_id }}</vs-td>
            <vs-td>{{ customer.user_tel }}</vs-td>
            <vs-td>{{ customer.customer_name }}</vs-td>
            <vs-td>{{ customer.customer_surname }}</vs-td>
            <vs-td>{{ customer.bank_name }}</vs-td>
            <vs-td>{{ customer.account_name }}</vs-td>
            <vs-td>{{ customer.account_number }}</vs-td>
            <vs-td>
              <div v-if="customer.id_card && !isDocFile(customer.id_card)" class="img-size">
                <expandable-image
                    class="image"
                    :src=customer.id_card
                ></expandable-image>
              </div>

              <vs-button  v-else-if="customer.id_card && isDocFile(customer.id_card)" circle icon flat @click="download(customer.id_card)">
                <i class="fal fa-download"></i>
              </vs-button>

            </vs-td>
            <vs-td>
              <div v-if="customer.family_book && !isDocFile(customer.family_book)" class="img-size">
                <expandable-image
                    class="image"
                    :src=customer.family_book
                ></expandable-image>
              </div>

              <vs-button  v-else-if="customer.family_book && isDocFile(customer.family_book)" circle icon flat @click="download(customer.family_book)">
                <i class="fal fa-download"></i>
              </vs-button>

            </vs-td>
            <vs-td>
              <div v-if="customer.passport && !isDocFile(customer.passport)" class="img-size">
                <expandable-image
                    class="image"
                    :src=customer.passport
                ></expandable-image>
              </div>

              <vs-button  v-else-if="customer.passport && isDocFile(customer.passport)" circle icon flat @click="download(customer.passport)">
                <i class="fal fa-download"></i>
              </vs-button>

            </vs-td>
            <vs-td>
              <div v-if="customer.agreement && !isDocFile(customer.agreement)" class="img-size">
                <expandable-image
                    class="image"
                    :src=customer.agreement
                ></expandable-image>
              </div>

              <vs-button  v-else-if="customer.agreement && isDocFile(customer.agreement)" circle icon flat @click="download(customer.agreement)">
                <i class="fal fa-download"></i>
              </vs-button>

            </vs-td>
            <vs-td>{{ customer.village_name }}</vs-td>
            <vs-td>{{ customer.district_name }}</vs-td>
            <vs-td>{{ customer.province_name }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    circle icon flat
                    @click="OpenModalApproved(customer.id)">
                  <i class="fal fa-check-circle"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalDelete>
      <template v-slot="{ close }">
        <ApproveVip @close="close" :customerId="customerId" @success="FetchData()"/>
      </template>
    </ModalDelete>


  </div>
</template>
<script>

import Loading from '@/views/Loading';
import {mapActions} from "vuex";
import ApproveVip from '@/views/SuperAdmin/Customers/VIPCustomer/Dialog/confirmApprove'
import pagination from '@/Plugins/Paginations/Pagination';
export default {
  components: {
    Loading,
    ApproveVip,
    pagination
  },
  data: () => ({
    page: 1,
    max: 10,
    customers: [],
    customerId: '',
    isLoading: true,
    search: '',

    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  methods: {
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    FetchData() {
      this.isLoading = true;
      this.$http
          .get("list-customer", {
            params: {
              query: this.search,
              type: 'pending-cod',
              page: this.pagination.current_page,
            }
          })
          .then((res) => {
            setTimeout(() => {
              this.isLoading = false;
              this.$emit("close");
            }, 200);
            setTimeout(() => {
              this.customers = res.data.data;
              this.count = res.data.total;
              this.pagination = res.data;
            }, 100);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.LogoutUser();
            }
          });
    },


    getFileExtension(filename){
      return filename.split('.').pop();
    },

    isDocFile(name) {
      const ext = this.getFileExtension(name || '');
      return (['doc', 'docx', 'pdf'].includes((ext || '').toLowerCase()));
    },

    OpenModalApproved(customerId) {
      this.customerId = customerId;
      this.$store.commit("modalDelete_State", true);
    },

    download(filename) {
      const onlyFileName = filename.split('/').pop();
      this.$http.get(`download/${onlyFileName}`, { responseType: 'blob' })
          .then((response) => {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.setAttribute('download', filename)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
          }).catch((e) => console.log(e))
    },
  },

  watch: {
    search() {
      this.pagination.current_page = 1;
      this.FetchData()
    }
  },

  created() {
    this.FetchData();
    this.FetchData = this.lodash.debounce(this.FetchData, 600);
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;

}

.fit-width {
  white-space: nowrap;
}


</style>