export default {

    data:()=>({
        active: false,
        isLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },

        parts: [],
        provinces: [],
        districts: [],
        villages: [],

        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        checkVillage: true,

        translateInloas:{
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1'
        }
    }),

    methods:{

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        }, // Validate-Form

        EditItem(){
            this.listCallCenter.villageId = this.selectedVillage.village_id;

            const loading = this.BtnLoading();
            this.$axios.put('edit-call-centers', this.listCallCenter).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        loading.close();
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                loading.close();
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }, // EditItems

        BtnLoading(){
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        }, // Btn-Loading

        FetchData(){
            this.$axios.get('list-branch-address').then(res=>{
                const items = res.data.data;

                this.parts = items.parts;
                this.provinces = items.provinces;
                this.districts = items.districts;
                this.villages = items.villages;

                this.selectedPart = this.listCallCenter;
                this.selectedType = this.listCallCenter;

                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;

                this.FilterProvinces(this.selectedPart.partId);
                this.selectedProvince = this.listCallCenter;
                this.FilterDistricts(this.selectedProvince.proId);
                this.selectedDistrict = this.listCallCenter;
                this.FilterVillages(this.selectedDistrict.distId);
                this.selectedVillage = this.listCallCenter;
            }).catch(()=>{});
        }, // FetchData-To-Server

        FilterProvinces(partId){
            const result_checking = this.filterProvinces.filter(item=>{
                if(item.part_id == partId){
                    return item;
                }
            });
            this.provinces = result_checking;
            this.selectedProvince = {...result_checking[0]};

            this.FilterDistricts(this.selectedProvince.proId);
        }, // Filter-Provinces

        FilterDistricts(proId){
            const result_checking = this.filterDistricts.filter(item=>{
                if(item.province_id == proId){
                    return item;
                }
            });
            this.districts = result_checking;
            this.selectedDistrict = {...result_checking[0]};
            this.FilterVillages(this.selectedDistrict.distId);
        }, // Filter-Distircts

        FilterVillages(distId){
            const result_checking = this.filterVillages.filter(item=>{
                if(item.district_id == distId){
                    return item;
                }
            });
            this.villages = result_checking;
            this.selectedVillage = {...result_checking[0]};
        }, // Filter-Villages
    },
    watch: {
        'selectedVillage.village_id':function(){
            this.server_errors.villageId = '';
        },
        'listCallCenter.prefix':function(){
            this.server_errors.prefix = '';
        },
        'listCallCenter.name':function(){
            this.server_errors.name = '';
        },
        'listCallCenter.tel':function(){
            this.server_errors.tel = '';
        },
        'listCallCenter.email':function(){
            this.server_errors.email = '';
        }
    },
}
