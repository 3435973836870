<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-truck"></i>
          ຄິດໄລ່ຍອດ delivery ນະຄອນຫຼວງລົດໃຫຍ່
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດພາຫະນະ:</label>
            <div class="select">
              <select class="input" v-model="type">
                <option value="delivery">ລົດຈັກ</option>
                <option value="driver">ລົດໃຫຍ່</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="printSummaryDeliveryCapital()"
              class="form-btn-loading"
              :loading="loading">
            export excel
          </vs-button>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ຈຳນວນຮັບຕົ້ນທາງ</vs-th>
            <vs-th>ຍອດຮັບຕົ້ນທາງ</vs-th>
            <vs-th>ຈຳນວນຮັບປາຍທາງ</vs-th>
            <vs-th>ຍອດຮັບປາຍທາງ</vs-th>
            <vs-th>ຈຳນວນຈັດສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th>ຍອດຈັດສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th>ຈຳນວນຈັດສົ່ງປາຍທາງ</vs-th>
            <vs-th>ຍອດຈັດສົ່ງປາຍທາງ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="branch"
              v-for="(branch, index) in $vs.getPage(branches, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ branch.name }}</vs-td>
            <vs-td>{{ FormatPrice(branch.receiveOriginQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.receiveOriginFreight) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.receiveCdcQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.receiveCdcFreight) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.successOriginQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.successOriginFreight) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.successCdcQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.successCdcFreight) }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <CustomerExcelTemplate v-show="false" ref="ExportExcel"/>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import CustomerExcelTemplate from "@coms/printing/CustomerExcelTemplate";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  components: {
    Loading,
    pagination,
    CustomerExcelTemplate,
    DatePicker
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    branch: '',
    branches: [],
    searchItem: "",
    oldVal: "",
    totalPoint: 0,
    totalSpend: 0,
    transaction_type: '',
    transaction_status: '',
    branch_type: '',
    type: 'delivery',
    laType: {
      'delivery': 'ລົດຈັກ',
      'driver': 'ລົດໃຫຍ່'
    },
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    printSummaryDeliveryCapital() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/summary/driver-capital', {
        start_date: this.start_date,
        end_date: this.end_date,
        type: this.type,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', `ຄີດໄລ່ຍອດເດລີເວີລີນະຄອນຫຼວງ${this.laType[this.type]}ວັນທີ ${moment(this.start_date).format('DD-MM-YYYY')}.xlsx`);
        } else {
          fileLink.setAttribute('download', `ຄີດໄລ່ຍອດເດລີເວີລີນະຄອນຫຼວງ${this.laType[this.type]}ວັນທີ ${moment(this.start_date).format('DD-MM-YYYY')} ຫາ ${moment(this.end_date).format('DD-MM-YYYY')}.xlsx`);
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2018-08-01 00:00:00');
      return date < fixDay;
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.branches = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('summary/capital-delivery', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          print: 'no',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
