import {FormatDateTimeBeforeSendToServer} from '@/Helpers/Format';

export default {
    data: () => ({
        listShipmentBag: [],
        selectedShipmentBag: null,
        startDate: new Date(),
        searchItem: '',
        isLoading: false,
        listShipmentBagCount: 0,

        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10,

        oldVal: '',

        la_status: {
            'pending': 'ລໍຖ້າ',
            'processing': 'ກຳລັງດຳເນິນການ',
            'success': 'ສຳເລັດ',
            'cancelled': 'ຍົກເລີກ',
        }
    }),
    methods: {
        OpenModalEdit(distributionCenterBagItem) {
            this.selectedShipmentBag = {...distributionCenterBagItem};
            this.$store.commit('modalEdit_State', true);
        },
        FilterByShipmentBagNumber() {
            this.pagination.current_page = 1;
            this.FilterListOrders();
        },
        FilterListOrders() {
            this.isLoading = true;
            this.$http.get('distribution/center/shipment/bag', {
                params: {
                    date: FormatDateTimeBeforeSendToServer(this.startDate),
                    query: this.searchItem,
                    page: this.pagination.current_page,
                }
            }).then((res) => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.listShipmentBag = res.data.data;
                    this.pagination = res.data;
                    this.listShipmentBagCount = this.pagination.total;
                }, 300);
            }).catch(() => {
            });
        },
        PrintBagNumber(bagId) {
            this.isLoading = true;
            this.$http.post('distribution/center/print/bag', {
                    bag_id: bagId.id
                },
                {responseType: 'blob'}
            ).then((response) => {
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', 'ເລກກະເປົາ ' + bagId.bag_number + '.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                setTimeout(() => {
                    this.isLoading = false;
                }, 300)
            }).catch(() => {
                this.isLoading = false;
            })
        }
    },
    watch: {
        'startDate': function () {
           this.FilterListOrders();
        },
        'searchItem': function (value) {
            if (value == '') {
                this.FilterListOrders();
            }
       },
    }
}
