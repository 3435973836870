export default {
    data: () => ({
        active: false,
        isLoading: false,
        server_errors: {
            userName: '',
            userEmail: '',
            roleId: '',
            userCardid: ''
        },
        images: {
            fileName: '',
            base64: '',
            size: '',
            file: '',
            type: '',
        },

        roleId: [],
        permissions: [],
        listCallCenters: [],
    }),

    methods: {

        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-call-centers').then(res=>{
                setTimeout(()=>{
                    this.listCallCenters = res.data.ListCallCenters;
                }, 100);
            }).catch(()=>{});
        },
        ShowPermission() {
            this.$axios.get('show-permission-roles', {
                params: {
                    roleId: this.listCallCenterUser.roleId
                }
            }).then(res => {
                this.permissions = res.data;
            });
        },

        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        },

        EditItem() {
            const loading = this.BtnLoading();
            this.listCallCenterUser.newProfile = this.images.fileName;
            this.listCallCenterUser.newFileSize = this.images.size;
            this.$axios.put('edit-call-center-users', this.listCallCenterUser).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        loading.close();
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error => {
                loading.close();
                if (error.response.status == 422) {
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
                this.OpenNotification_Errors('top-right', 'danger');
            });
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        },

        triggerInputClick() {
            let selector = this.$refs.file;
            if (selector) {
                selector.click();
            }
        },

        async onFileChange(e) {
            const file = e.target.files[0];
            const FileSize = (file.size / 1024 / 1024).toFixed(2);
            const extentsions = file.type.split("/");
            const validate_Ext = this.ValidateExtensions(extentsions[1]);

            if (validate_Ext == true && FileSize < 6) {
                this.images.file = file;
                this.images.type = extentsions[1];
                this.images.size = this.FormatByte(file.size);
                this.images.base64 = await this.ToBase64(file);
                this.UploadFile();
            }
        }, // OnFile-Change

        ToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }, // Convert-Base64

        FormatByte(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }, // FormatByte

        ValidateExtensions(ext) {
            if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp') {
                return true;
            } else {
                return false;
            }
        },

        UploadFile() {
            let formData = new FormData();
            formData.append('imageFile', this.images.file);

            this.$axios.post('upload-file', formData).then((res) => {
                this.images.fileName = res.data.data.fileName;

            }).catch(() => {
            });
        }, // Upload-File

        Slit_Ext(value) {
            const fileType = value.split(".");
            return fileType[1];
        }
    },

    watch: {
        'listCallCenterUser.userName': function () {
            this.server_errors.userName = '';
        },
        'listCallCenterUser.userEmail': function () {
            this.server_errors.userEmail = '';
        },
        'listCallCenterUser.roleId': function () {
            this.server_errors.roleId = '';
        },
        'listCallCenterUser.userCardid': function () {
            this.server_errors.userCardid = '';
        }
    }
    ,
}