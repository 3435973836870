import {Validator} from 'vee-validate';

const validate = {
    custom: {
        bank_name: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
            max: '(ຊື່ບໍ່ຄວນຍາວກວ່າ 225 ໂຕ)',
        },
        account_name: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
            max: '(ຊື່ບໍ່ຄວນຍາວກວ່າ 225 ໂຕ)',
            regex:'(ກະລຸນາປ້ອນຕົວພິມໃຫຍ່ພາສາອັງກິດ)'
        },
        account_number: {
            numeric: '(ກະລຸນາປ້ອນຕົວເລກ)',
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
            min: '(ເລກບັນຊີສັ້ນກວ່າ 13 ໂຕ)',
            max: '(ເລກບັນຊີຍາວກວ່າ 18 ໂຕ)',
        },
    }
};

export const ValidateCustomer = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

