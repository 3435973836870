<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal" style="margin-right: 5px">({{ count }}) </span>

        ນ້ຳໜັກ
        <span class="countTotal">({{ weight }}) </span>
        <!--        <span class="btn-add" :style="{marginTop: '-5px !important'}"-->
        <!--              v-if="$route.query.tab === 'origin_freight_fees'-->
        <!--              || $route.query.tab === 'destination_freight_fees'">-->
        <!--                    ຍອດຄ່າຂົນສົ່ງທັງໝົດ: {{ FormatPrice(totalFreight) }}-->
        <!--        </span>-->
        <!--        <span class="btn-add" :style="{marginTop: '-5px !important'}"-->
        <!--              v-else>-->
        <!--                     ຍອດຄ່າຂົນສົ່ງທັງໝົດ: {{ FormatPrice(totalFreight) }} |  ຍອດຄ່າສິນຄ້າ: {{ FormatPrice(totalPrice) }}-->
        <!--        </span>-->
        <span class="btn-add">
          <div class="columns">
            <div class="field" :style="{marginRight: '5px'}">
             <vs-button
                 ref="button" flat
                 @click="printShipment()"
                 class="form-btn-loading"
                 :loading="loading"
                 :disabled="!isSelectingOrders">
               export excel
             </vs-button>
            </div>
            <div class="field" style="margin-right: 12px">
              <vs-button
                  ref="button"
                  @click="ClearData()"
                  class="form-cls-loading"
                  :loading="loading">
                ລ້າງຂໍ້ມູນ
              </vs-button>
            </div>
          </div>
        </span>
      </h4>
    </div>

    <div class="box" style="margin-bottom: 12px;margin-top: -12px;">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FilterByShipmentNumber()"
              >
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດການຍິງເຄື່ອງ:</label>
            <div class="select">
              <select class="input" v-model="selectedScanType">
                <option value="inbound">ຍິງເຄື່ອງເຂົ້າ</option>
                <option value="outbound">ຍິງເຄື່ອງອອກ</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຂົນສົ່ງ:</label>
            <div class="select">
              <select class="input" v-model="selectedDeliverType" @change="FetchData(groupBy = true)">
                <option value="express">express</option>
                <option value="logistics">logistics</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ສູນຄັດແຍກ:</label>
            <model-list-select
                :list="distributionCenters"
                v-model="selectedDistributionCenterId"
                placeholder="ສູນຄັດແຍກ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>

        <div class="column is-2">
          <div class="control field">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
      </div>
      <!--      <div class="columns">-->
      <!--        <div class="column is-2">-->
      <!--          <div class="control">-->
      <!--            <label class="label">ຜູ້ຮັບຜິດຊອບ:</label>-->
      <!--            <model-list-select-->
      <!--                :list="users"-->
      <!--                v-model="selectedUser"-->
      <!--                placeholder="ຜູ້ຮັບຜິດຊອບ"-->
      <!--                option-text="name"-->
      <!--                option-value="id"-->
      <!--                selectedItem="name">-->
      <!--            </model-list-select>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="center table-limit-height">
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ວັນທີດຳເນີນການ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ສ່ວນຫຼຸດ</vs-th>
            <vs-th v-if="$route.query.tab === 'origin_freight_fees'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'destination_freight_fees'">ຄ່າຂົນສົ່ງປາຍທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'origin_freight_fees_cod'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else>ຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ປະກັນໄພ</vs-th>
            <vs-th>ລວມຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ຄ່າຕີກັບ</vs-th>
            <vs-th>ຄ່າຝາກສາງ</vs-th>
            <vs-th>ຄ່າສິນຄ້າ</vs-th>
            <vs-th>ຊື່ຜູ້ສົ່ງ</vs-th>
            <vs-th>ຊື່ຜູ້ຮັບ</vs-th>
            <vs-th>ສູນຄັດແຍກ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ປະເພດການຊຳລະ</vs-th>
            <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
            <vs-th>ຜູ້ສະແກນ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listAllOrders"
              :is-selected="isRowSelected(item.id)"
              :data="item"
              @selected="onItemCheckBoxChanged(item)">
            <vs-td>
              <div class="center con-checkbox">
                <vs-checkbox danger
                             :checked-force="isRowSelected(item.id)"
                             :val="item" @click="onItemCheckBoxChanged(item)">
                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                </vs-checkbox>
              </div>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ item.shipment_number }} )</vs-td>
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td>{{ item.qty || 0 }}</vs-td>
            <vs-td>{{ item.weight || 0 }}</vs-td>
            <vs-td>
              <span v-if="item.total_discount_amount !== 0">{{ item.total_discount_amount }}ກີບ</span>
              <span v-if="item.total_discount_percent !== 0">{{ item.total_discount_percent }}%</span>
              <span v-if="item.total_discount_percent === 0 && item.total_discount_percent === 0">........</span>
            </vs-td>
            <vs-td>( {{ FormatPrice(item.sub_total_freight) }} )</vs-td>
            <vs-td>( {{ FormatPrice(item.insurance_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.reject_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_rental_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_price) }} )</vs-td>
            <vs-td>{{ item.sender_customer_name }}</vs-td>
            <vs-td>{{ item.receiver_customer_name }}</vs-td>
            <vs-td>{{ item.distribution_center_name }}</vs-td>
            <vs-td>{{ item.start_branch_name }}</vs-td>
            <vs-td>{{ item.end_branch_name }}</vs-td>
            <vs-td>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees'">ເກັບເງິນຕົ້ນທາງ</span>
              <span v-if="item.shipment_pay_type === 'destination_freight_fees'">ເກັບເງິນປາຍທາງ</span>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees_cod'">ເກັບເງິນຕົ້ນທາງ ( COD )</span>
              <span
                  v-if="item.shipment_pay_type === 'destination_freight_fees_cod'">ເກັບເງິນປາຍທາງ ( COD + CDC )</span>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              ( {{ item.shipment_type }} )
            </vs-td>
            <vs-td>{{ item.dis_user_name }}</vs-td>
            <vs-td
                v-if="item.tracking_state_id === 5 && item.shipment_status_id === 2">
              ເຄື່ອງຮອດສາຂາປາຍທາງ
            </vs-td>
            <vs-td v-else>{{ la_status[item.shipment_status_name] }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

import Loading from '../../Loading';
import MiXinReportDistributionCenter from '@/Mixins/Report/Accountant/List-Report-Distribution';
import DatePicker from "vue2-datepicker";
import {ModelListSelect} from 'vue-search-select';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
  components: {
    Loading,
    DatePicker,
    ModelListSelect,
    pagination
  },

  props: ['onclickEvent'],

  mixins: [MiXinReportDistributionCenter],

  data: () => ({
    isAdmin: true
  }),

  created() {
    this.FetchListDistributionCenter();
    this.FetchData();
  },

  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.ClearData();
        this.FetchData();
      }
    }
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}

.table-limit-height {
  .vs-table {
    width: 100%;
    font-size: 0.9rem;
    margin: 0;
    max-height: 500px;
  }
}

</style>
