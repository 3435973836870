<template>
  <div>
    <div class="field" :style="{marginTop: '40px'}">
      <div class="control">
        <label class="label">ວັນທີຊຳລະເງິນ</label>
        <vuejs-datepicker
            v-model="payDate"
            input-class="input"
            format="d/M/yyyy"
            name="valid_date"
            v-validate="'required'"
        >
        </vuejs-datepicker>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">ເງິນໂອນເກີນຄັ້ງກ່ອນ</label>
        <input
            class="input"
            v-model="debt_last_time"
            placeholder="ຈຳນວນເງິນໂອນເກີນຄັ້ງກ່ອນ..."
        >
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">ຄ່າທຳນຽມໂອນ</label>
        <input
            class="input"
            v-model="transfer_fee"
            placeholder="ຄ່າທຳນຽມໂອນ..."
        >
      </div>
    </div>
    <vs-button
        ref="button"
        flat @click="ExportsExcel_By_Total()"
        class="form-btn-loading"
        :loading="loading"
    >
      Export Excel
    </vs-button>
  </div>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker';
import {FormatDateTimeBeforeSendToServer, FormatDate} from '../../../Helpers/Format';

export default {
  props: [
    'invoiceId', 'type'
  ],
  components: {
    vuejsDatepicker
  },

  data: () => ({
    payDate: new Date(),
    loading: false,
    debt_last_time: 0,
    transfer_fee: 1000,
  }),
  methods: {
    ExportsExcel_By_Total() {
      const invoiceId = this.invoiceId.map(item => item.id);
      this.loading = true;
      this.$http.post('print-cod-invoice-total', {
            invoices: invoiceId,
            date: FormatDateTimeBeforeSendToServer(this.payDate),
            debt_last_time: this.debt_last_time,
            transfer_fee: this.transfer_fee,
            type: this.type
          },
          {responseType: 'blob'},
      ).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.type === 'before'){
          fileLink.setAttribute('download', 'Invoice COD ເຊົ້າ ແບບລວມ ວັນທີ ' + FormatDate(this.payDate) + '.xlsx');
        }else if(this.type === 'after'){
          fileLink.setAttribute('download', 'Invoice COD ແລງ ແບບລວມ ວັນທີ ' + FormatDate(this.payDate) + '.xlsx');
        }else{
          fileLink.setAttribute('download', 'Invoice COD ແບບລວມ ວັນທີ ' + FormatDate(this.payDate) + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      }).catch(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>