<template>
  <div class="container">
    <div class="box-title">
      <h4 style="margin-bottom: 4px;">
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ແພັກຄິງຫາສາຂາໂດຍກົງ
        <span class="countTotal">({{ distributionCenterLinks.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()" :disabled="!branches.length > 0">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
        </span>
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ສາຂາທີ່ແພັກເຂົ້າໂດຍກົງ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in distributionCenterLinks" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{
                (item.direct_linked_branch || {}).village?.district?.province?.prefix
              }}-{{ (item.direct_linked_branch || {}).name }}
            </vs-td>
            <vs-td>
              <vs-button circle icon flat danger
                         @click="OpenModalDelete(item.id)">
                <i class="fad fa-trash-alt"></i>
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <vs-dialog prevent-close v-model="addModalActive">
      <AddDistributionCenterLinks @close="closeModal('add')" @success="onSuccess()"
                                  :activeDistributionCenterId="routeDistributionCenterId"
                                  :branches="branches"/>
    </vs-dialog>

    <vs-dialog prevent-close v-model="deleteModalActive">
      <DeleteDistributionCenterLinks @close="closeModal('delete')" @success="onSuccess()"
                                     :distributionCenterBranchLinkedId="distributionCenterBranchLinkedId"/>
    </vs-dialog>

  </div>
</template>

<script>
import AddDistributionCenterLinks from './Dialog/AddPackingDirectLinklBranch.vue';
import DeleteDistributionCenterLinks from './Dialog/DeletePackingDirectLinkBranch.vue';
import Loading from '@/views/Loading';

export default {
  components: {
    AddDistributionCenterLinks,
    DeleteDistributionCenterLinks,
    Loading
  },

  data: () => ({
    distributionCenters: [],
    branches: [],
    distributionCenterLinks: [],
    distributionCenterBranchLinkedId: '',
    isLoading: false,
    addModalActive: false,
    deleteModalActive: false,
  }),
  computed: {
    routeDistributionCenterId() {
      return this.$route.params.id;
    }
  },

  methods: {
    closeModal(id) {
      if (id === 'add') {
        this.addModalActive = false;
      } else {
        this.deleteModalActive = false;
      }
    },
    OpenModalAdd() {
      this.addModalActive = true;
    },
    OpenModalDelete(distributionCenterBranchLinkedId) {
      this.distributionCenterBranchLinkedId = distributionCenterBranchLinkedId;
      this.deleteModalActive = true;
    },
    fetchBranches() {
      this.$http.get('v1/listing/branches?is_active=true').then(res => {
        this.branches = res.data;
      }).catch(() => {
      });
    },
    fetchData() {
      this.isLoading = true;
      this.$http.get('v1/auth/branches/distribution-center/link-map-data/direct-linked-branches',
          {
            params: {
              main_distribution_center_id: this.routeDistributionCenterId
            }
          }).then((res) => {
        this.distributionCenterLinks = res.data;
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onSuccess() {
      this.fetchData();
      this.fetchBranches();
    }
  },
  created() {
    this.fetchData();
    this.fetchBranches();
  }

}
</script>
