<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-chart-bar"></i>
          ລາຍການ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FilterByShipmentNumber()"
              >
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2" v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper', 'service',
        'accountingmanager', 'accountingcod', 'accountingcdc', 'accountingoriginfranchise', 'accountingoriginbranch'])">
          <div class="control">
            <label class="label">ສາຂາຫຼັກ:</label>
            <model-list-select
                :list="listMainShop"
                v-model="selectedMainShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2"
             v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper', 'service',
             'accountingmanager', 'accountingcod', 'accountingcdc', 'accountingoriginfranchise', 'accountingoriginbranch']) && selectedMainShop !== ''">
          <div class="control">
            <label class="label">ຮ້ານ:</label>
            <model-list-select
                :list="listShopsForManager"
                v-model="selectedShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2"
             v-else-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper', 'service',
             'accountingmanager', 'accountingcod', 'accountingcdc', 'accountingoriginfranchise', 'accountingoriginbranch']) && selectedMainShop === ''">
          <div class="control">
            <label class="label">ຮ້ານ:</label>
            <model-list-select
                :list="listShopsForAdmin"
                v-model="selectedShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-4" v-if="$can('shop-manager')">
          <div class="control">
            <label class="label">ຮ້ານ:</label>
            <model-list-select
                :list="listShopsForManager"
                v-model="selectedShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px" v-if="shopPayments.length > 0">
          <div
              v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper', 'shop-user','shop-manager', 'service',
              'accountingmanager', 'accountingcod', 'accountingcdc', 'accountingoriginfranchise', 'accountingoriginbranch'])">
            <vs-button v-if="!isActorAsMobileApp"
                       ref="button" flat
                       @click="printListPointDetail()"
                       class="form-btn-loading"
                       :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດທຸລະກຳ:</label>
            <div class="select">
              <select class="input" v-model="transaction_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="spend">ແລກເງິນສົດ</option>
                <option value="earn">ໄດ້ຮັບ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr :style="{marginTop: '-10px'}">
    <div class="center" :style="{marginTop: '-10px'}">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ຈຳນວນພ້ອຍ</vs-th>
            <!--            <vs-th>ຈຳນວນນຳໃຊ້</vs-th>-->
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :is-selected="true">
            <vs-td>{{ FormatPrice(earn) }}</vs-td>
            <!--            <vs-td>{{ FormatPrice(spend) }}</vs-td>-->
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລູກຄ້າ</vs-th>
            <vs-th>ຈຳນວນພ້ອຍ</vs-th>
            <vs-th>ເລກອ້າງອິງ</vs-th>
            <vs-th>ຄຳອະທິບາຍ</vs-th>
            <vs-th>ຮ້ານຄ້າ</vs-th>
            <vs-th>ປະເພດ</vs-th>
            <vs-th>ວັນທີ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="shopPayment"
              v-for="(shopPayment, index) in $vs.getPage(shopPayments, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ shopPayment.customer_name }}</vs-td>
            <vs-td>{{ FormatPrice(shopPayment.amount) }}</vs-td>
            <vs-td>{{ shopPayment.reference }}</vs-td>
            <vs-td>{{ shopPayment.remark }}</vs-td>
            <vs-td>{{ shopPayment.shop_name }}</vs-td>
            <vs-td> {{ laStatus[shopPayment.transaction_status] }}</vs-td>
            <vs-td>{{ moment(shopPayment.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect,
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: true,
    laStatus: {
      'earn': 'ໄດ້ຮັບ',
      'spend': 'ແລກເງິນສົດ',
    },
    transaction_type: '',
    listShopsForAdmin: [],
    listMainShop: [],
    selectedMainShop: '',
    listShopsForManager: [],
    shopPayments: [],
    selectedShop: '',
    searchItem: "",
    shop_type: "",
    oldVal: "",
    // Pagination
    count: 0,
    total: 0,
    earn: 0,
    spend: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'selectedShop': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'selectedMainShop': function () {
      this.FetchShopForTotalBranch();
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },
  computed: {
    isActorAsMobileApp() {
      return this.$route.query.actor == 'mobile_app';
    }
  },
  methods: {
    FilterByShipmentNumber() {
      helper.GetOldValueOnInput(this);
    },
    FetchShopForAdmin() {
      if (this.$can('marketinghelper')) {
        this.shop_type = 'other'
      } else if (this.$can('service') || this.$can('accountingmanager') || this.$can('accountingcod') ||
          this.$can('accountingcdc') || this.$can('accountingoriginfranchise') || this.$can('accountingoriginbranch')) {
        this.shop_type = 'hal';
      } else {
        this.shop_type = '';
      }
      this.$http.get('list/shop/?isParent=no&type=' + this.shop_type).then((res) => {
        this.listShopsForAdmin = res.data;
        this.listShopsForAdmin.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FetchMainShop() {
      if (this.$can('marketinghelper')) {
        this.shop_type = 'other'
      } else if (this.$can('service') || this.$can('accountingmanager') || this.$can('accountingcod') ||
          this.$can('accountingcdc') || this.$can('accountingoriginfranchise') || this.$can('accountingoriginbranch')) {
        this.shop_type = 'hal';
      } else {
        this.shop_type = '';
      }
      this.$http.get('list/shop/?isParent=yes&type=' + this.shop_type).then((res) => {
        this.listMainShop = res.data;
        this.listMainShop.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FetchShopForManager() {
      this.$http.get('list/shop/manager').then((res) => {
        this.listShopsForManager = res.data;
        this.listShopsForManager.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    FetchShopForTotalBranch() {
      this.$http.get('list/shop/manager', {
        params: {
          'shop_id': this.selectedMainShop
        }
      }).then((res) => {
        this.listShopsForManager = res.data;
        this.listShopsForManager.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    printListPointDetail() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/shop/payment', {
        search: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
        main_shop: this.selectedMainShop,
        transaction_type: this.transaction_type,
        shop_type: this.shop_type,
        shop_id: this.selectedShop,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ລາຍການຍອດຮ້ານຄ້າວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ລາຍການຍອດຮ້ານຄ້າວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "") + " ພ້ອຍ";
    },

    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.shopPayments = res.data.data.data;
          this.count = res.data.data.total;
          this.earn = res.data.earn;
          this.spend = res.data.spend;
          this.pagination = res.data.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('shop/payment', {
        params: {
          search: this.searchItem,
          start_date: this.start_date,
          end_date: this.end_date,
          shop_id: this.selectedShop,
          transaction_type: this.transaction_type,
          shop_type: this.shop_type,
          main_shop: this.selectedMainShop,
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    const item = window.localStorage.getItem('roleUser');
    const roleUsers = JSON.parse(item);
    if (roleUsers.includes('shop-manager')) {
      this.FetchShopForManager();
    } else if (roleUsers.includes('SuperAdmin') || roleUsers.includes('manager') || roleUsers.includes('marketing') || roleUsers.includes('marketinghelper') || roleUsers.includes('service')
        || this.$can('accountingmanager') || this.$can('accountingcod') || this.$can('accountingcdc') || this.$can('accountingoriginfranchise') || this.$can('accountingoriginbranch')) {
      this.FetchMainShop();
      this.FetchShopForAdmin();
    }
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
