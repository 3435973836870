<template>
  <div>
    <div class="box" style="margin-top: -24px; padding: 10px">
      <div class="columns is-multiline">
        <!--        <div class="column is-3">-->
        <!--          <div class="control">-->
        <!--            <label class="label">ຄົ້ນຫາ:</label>-->
        <!--            <input class="input" placeholder="ຄົ້ນຫາຂໍ້ມູນ..." v-model="shipment_number"-->
        <!--                   @keyup.enter="FilterShipmentNumber()">-->
        <!--          </div>-->

        <!--        </div>-->

        <div class="column is-4">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <!--        <div class="column is-3">-->
        <!--          <div class="control">-->
        <!--            <label class="label">ລາຍຊື່ລຸຸກຄ້າ:</label>-->
        <!--            <model-list-select-->
        <!--                :list="listCustomers"-->
        <!--                v-model="selectedCustomer"-->
        <!--                placeholder="ເລືອກລູກຄ້າ..."-->
        <!--                option-text="name"-->
        <!--                option-value="id"-->
        <!--                selectedItem="name"-->
        <!--            >-->
        <!--            </model-list-select>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="column is-4">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="selectedStatus.value" @change="FilterStatus()">
                <option v-for="(item, index) in status" :key="index" :value="item.value">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!--        <div class="column is-2">-->
        <!--          <div class="control">-->
        <!--            <label class="label">ຊ່ວງເວລາໂອນເງິນ:</label>-->
        <!--            <div class="select">-->
        <!--              <select class="input" v-model="type">-->
        <!--                <option value="">ທັງໝົດ</option>-->
        <!--                <option value="before">ໂອນຕອນເຊົ້າ</option>-->
        <!--                <option value="after">ໂອນຕອນແລງ</option>-->
        <!--              </select>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="column is-12" :style="{textAlign: 'center'}">
          <button
              class="button btn-export-sum"
              :style="{marginLeft: '10px'}"
              @click="UpdateInvoices()"
              :disabled="!isSelectingOrders"
          >
            ອັບເດດ {{ actualSelectedItems.length }} Invoice
          </button>
          <button
              class="button btn-export-split"
              :style="{marginLeft: '10px'}"
              @click="OpenModalPrintInvoiceCOD_Split()"
              :disabled="!isSelectingOrders"
          >
            Export - {{ actualSelectedItems.length }} ( ເເຍກ )
          </button>
          <button
              class="button btn-export-sum"
              :style="{marginLeft: '10px'}"
              @click="ExportExcel_By_Sum()"
              :disabled="!isSelectingOrders"
          >
            Export - {{ actualSelectedItems.length }} ( ລວມ )
          </button>
          <!--          @click="ExportExcel_Transaction(actualSelectedItems,createdDate,type)"-->
          <button ref="button"
                  class="button btn-export-sum"
                  :style="{marginLeft: '10px'}"
                  @click="ExportExcel_Transaction(actualSelectedItems,startDate)"
                  :disabled="!isSelectingOrders"
          >
            Export - {{ actualSelectedItems.length }} ( transaction )
          </button>
          <button ref="button"
                  class="button btn-export-sum"
                  :style="{marginLeft: '10px'}"
                  @click="importExcelTemplate"
          >
            Import Excel ເພື່ອລຶບອິນວອຍ
          </button>
        </div>
      </div>
      <hr :style="{marginTop: '-5px'}">
      <div class="center" :style="{marginTop: '-10px'}">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ລວມເປັນເງິນທັງໝົດ</vs-th>
              <!--              <vs-th>ຄ່າທຳນຽມ (ທນຄ)</vs-th>-->
              <!--              <vs-th>ຄ່າທຳນຽມ (COD)</vs-th>-->
              <vs-th>ລວມຄ່າທຳນຽມ</vs-th>
              <vs-th>ຄ່າສິນຄ້າຫຼັງ (ບກ)</vs-th>
              <vs-th>ລວມລູກຄ້າ</vs-th>
              <vs-th>ລວມບິນ</vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :is-selected="true">
              <vs-td>
                <span v-if="summary.total_price == 0">0 ກີບ</span>
                <span v-else>{{ FormatPrice(summary.total_price) }}</span>
              </vs-td>
              <!--              <vs-td>-->
              <!--                <span v-if="summary.bank_fee == 0">0 ກີບ</span>-->
              <!--                <span v-else>{{ FormatPrice(summary.bank_fee) }}</span>-->
              <!--              </vs-td>-->
              <!--              <vs-td>-->
              <!--                <span v-if="summary.cod_fee == 0">0 ກີບ</span>-->
              <!--                <span>{{ FormatPrice(summary.cod_fee) }} </span>-->
              <!--              </vs-td>-->
              <vs-td>
                <span v-if="summary.total_fee == 0">0 ກີບ</span>
                <span>{{ FormatPrice(summary.total_fee) }}</span>
              </vs-td>
              <vs-td>
                <span v-if="summary.transfer_to_customer == 0">0 ກີບ</span>
                <span>{{ FormatPrice(summary.transfer_to_customer) }}</span>
              </vs-td>
              <vs-td>{{ count }} ຄົນ</vs-td>
              <vs-td>{{ summary.shipment_amount }} ບິນ</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="center table-limit-height" style="margin-top: -15px;">
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th>ວັນທີສຳເລັດ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ເລກອິນວອຍ</vs-th>
            <vs-th>ຊື່ລູກຄ້າ</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ຊື່ບັນຊີ (ທນຄ)</vs-th>
            <!--            <vs-th>ຄ່າທຳນຽມ (ທນຄ)</vs-th>-->
            <!--            <vs-th>ຄ່າບໍລິການ (COD)</vs-th>-->
            <vs-th>ລວມຄ່າບໍລິການ</vs-th>
            <vs-th>ຄ່າສິນຄ້າຫຼັງ (ບກ)</vs-th>
            <vs-th>ຈຳນວນເງິນທັງໝົດ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in invoices"
              :is-selected="isRowSelected(item.id)"
              :data="item"
              @selected="onItemCheckBoxChanged(item)">
            <vs-td>
              <div class="center con-checkbox">
                <vs-checkbox danger
                             :checked-force="isRowSelected(item.id)"
                             :val="item" @click="onItemCheckBoxChanged(item)">
                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                </vs-checkbox>
              </div>
            </vs-td>
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td v-if="item.invoice_success_at">{{ FormatDate(item.invoice_success_at) }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ la_status[item.invoice_status] }}</vs-td>
            <vs-td>{{ item.invoice_id }}</vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ item.surname }}</vs-td>
            <vs-td>{{ item.account_name }}</vs-td>
            <!--            <vs-td>{{ FormatPrice(item.bank_fee) }}</vs-td>-->
            <!--            <vs-td>{{ FormatPrice(item.cod_fee) }}</vs-td>-->
            <vs-td>{{ FormatPrice(item.fee) || '0 ກີບ' }}</vs-td>
            <vs-td>{{ FormatPrice(item.transfer) }}</vs-td>
            <vs-td>{{ FormatPrice(item.invoice_total_price) }}</vs-td>
            <vs-td>{{ item.invoice_remark }}</vs-td>
            <vs-td>
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({name: 'accounting.invoice.cod.detail', params: {id: item.id}})"
                >
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalDelete>
      <template v-slot="{ close }">
        <ExportExcelSplit @close="close" :invoiceId="actualSelectedItems" @success="ExportSuccess"/>
        <!--        <ExportExcelSplit @close="close" :invoiceId="actualSelectedItems" :type="type" @success="ExportSuccess"/>-->
      </template>
    </ModalDelete>

    <ModalEdit>
      <template v-slot="{ close }">
        <ExportExcelTotal @close="close" :invoiceId="actualSelectedItems" @success="ExportSuccess"/>
        <!--        <ExportExcelTotal @close="close" :invoiceId="actualSelectedItems" :type="type" @success="ExportSuccess"/>-->
      </template>
    </ModalEdit>
    <ModalClose>
      <template v-slot="{ close }">
        <ImportExcelToDeleteInvoice
            @close="close"
            @success="FetchData"
        />
      </template>
    </ModalClose>

    <ModalPreviewFile>
      <template v-slot="{ close }">
        <EditStatementCOD @close="close"
                          :invoiceId="actualSelectedItems"
                          @success="afterEdit()"
        />
      </template>
    </ModalPreviewFile>

  </div>
</template>

<script>
import mixinListInvoices from '../../Mixins/Accountings/Invoices/Invoice-COD';
import DatePicker from "vue2-datepicker";
import Loading from '../Loading';
import ExportExcelSplit from './Dialog/COD-Split';
import ExportExcelTotal from './Dialog/COD-Total';
import EditStatementCOD from './Dialog/Edit-Statement-COD';
// import {ModelListSelect} from 'vue-search-select';
import pagination from '../../Plugins/Paginations/Pagination';
import ImportExcelToDeleteInvoice from "@views/Accounting/ImportExcelToDeleteInvoice.vue";

export default {
  mixins: [mixinListInvoices],

  components: {
    DatePicker,
    Loading,
    ExportExcelSplit,
    ExportExcelTotal,
    ImportExcelToDeleteInvoice,
    // ModelListSelect,
    EditStatementCOD,
    pagination
  },

  created() {
    this.FetchData();
  }
}
</script>

<style lang="scss" scoped>
.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.box-summary {
  .box-total-price {
    border: 1px solid $crimson;
    // background: $crimson;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
  }

  .box-total-amount {
    border: 1px solid $primary-color;
    // background: $primary-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .box-total-shipment-bill {
    // background: $sub-color;
    border: 1px solid $sub-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }
}
</style>
