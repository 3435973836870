<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ແກ່ໄຂຂໍ້ມູນພະນັກງານ
      </h4>
    </template>

    <!-- name -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ຊື່
          <span class="text-danger">* {{ errors.first("name") }}</span>
          <span class="text-danger">
              {{ server_errors.name }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="name"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="item.name"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- surname -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ນາມສະກຸນ
          <span class="text-danger">* {{ errors.first("surname") }}</span>
          <span class="text-danger">
              {{ server_errors.surname }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="surname"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="item.surname"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- tel -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ເບີໂທ
          <span class="text-danger">* {{ errors.first("tel") }}</span>
          <span class="text-danger">
              {{ server_errors.tel }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="tel"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="item.tel"
            v-validate="'required|min:4|max:13|numeric'"
        />
      </div>
    </div>

    <!-- shop -->
    <div class="field column" style="margin-bottom: -10px">
      <div class="control">
        <label class="label">
          ຮ້ານ
          <span class="text-danger">* {{ errors.first("shop_id") }}</span>
          <span class="text-danger">
              {{ server_errors.shop_id }}
            </span>
        </label>
        <model-list-select
            :list="listShops"
            v-model="item.shop_id"
            placeholder="ເລືອກຮ້ານ"
            option-text="name"
            option-value="id"
            selectedItem="name"
        >
        </model-list-select>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >ບັນທຶກຂໍ້ມູນຜູ້ໃຊ້
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";
import {ModelListSelect} from 'vue-search-select';

const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    surname: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    tel: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      min: "(ປ້ອນຢ່າງຕ່ຳ 4 ຕົວເລກ)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 13 ຕົວເລກ)",
      numeric: "(ກະລຸນາປ້ອນເປັນຕົວເລກ)"
    },
  },
};
Validator.localize("en", dict);

export default {
  props: [
    'item',
  ],
  components: {
    ModelListSelect
  },

  data() {
    return {
      active: false,
      isLoading: false,
      loading: false,
      listShops: [],
      changeRole: 'no',
      server_errors: {
        name: "",
        surname: '',
        tel: '',
        shop_id: '',
      },
    };
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.loading = true;
      this.$http.put('shop_user/' + this.item.id, this.item).then(() => {
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
    FetchShop() {
      this.$http.get('list/shop/' + '?type=other&status=open').then((res) => {
        this.listShops = res.data;
      })
    },
  },
  created() {
    this.FetchShop();
  },
  watch: {
    "item.name": function () {
      this.server_errors.name = "";
    },
    "item.surname": function () {
      this.server_errors.surname = "";
    },
    "item.tel": function () {
      this.server_errors.tel = "";
    },
    "item.password": function () {
      this.server_errors.password = "";
    },
    "item.shop_id": function () {
      this.server_errors.shop_id = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;

  &:hover {
    cursor: pointer;
  }
}

.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}
</style>
