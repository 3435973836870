<template>
    <div class="container">
        <template>
            <h4 class="not-margin" id="scrollTo"
                :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມ ຂ່າວສານທົ່ວໄປ
            </h4>
        </template>
    
        <div class="field" :style="{marginTop: '30px'}">  
            <div class="control">
                <label class="label">
                    ຫົວຂໍ້ ຂ່າວສານທົ່ວໄປ
                    <span class="text-danger">* {{ errors.first('title') }}</span>
                    <span class="text-danger">
                        {{server_errors.title}}
                    </span>
                </label>
                <input 
                    type="text" class="input" 
                    name="title" 
                    placeholder="ຫົວຂໍ້ ຂ່າວສານທົ່ວໄປ..." 
                    v-model="value.title"
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field">  
            <div class="control">
                <label class="label">
                    ລາຍລະອຽດຂ່າວສານ
                    <span class="text-danger">* {{ errors.first('description') }}</span>
                    <span class="text-danger">
                        {{server_errors.description}}
                    </span>
                </label>
                <textarea 
                    class="textarea" v-validate="'required'"
                    placeholder="ລາຍລະອຽດຂ່າວສານ..." 
                    name="description" rows="10" 
                    v-model="value.description"
                >
                </textarea>
            </div>
        </div>
   
        <button class="button" :class="{'is-loading': btnLoading}" :style="{marginTop: '40px', width: '15%'}" @click="ValidateForm()">
            ເພີ່ມ ຂ່າວສານທົ່ວໄປ
        </button>
    </div>
</template>

<script>
import mixinsAddNews from '@coms/../../src/Mixins/News/Add';
import { ValidateNews } from '@coms/../../src/Plugins/Validation/Validate-News';

export default {

    mixins: [mixinsAddNews],

    created(){
        ValidateNews(this);
    }
}
</script>
<style lang="scss" scoped>
    .button{
        &:hover{
            color: $white;
        }
    }
    .box-list-customers{
        margin-top: -10px;
        background: $white;
        width: 100%;
        padding: 10px;
        max-height: 320px;
        min-height: 60px;
        border-radius: 5px;
        overflow: hidden;
        overflow-y: scroll;
        .branch{
            background: $crimson;
            width: 100%;
            padding: 2px;
            height: 40px;
            border-radius: 2px;
            font-family: $font;
            color: $white;
        }
    }

    // Basic control styles
    .listhead {
        display: block;
        position: relative;
        padding-left: 30px;
        margin: 0 0 15px 0;
        cursor: pointer;
        font-size: 16px;
    }
    // Hide default browser input
    .listhead input{
        vertical-align:top;
        margin-right:10px;
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    // Custom control
    .control_indicator{
        position: absolute;
        top: 1px;
        left: 4px;
        height: 23px;
        width: 23px;
        background: $white;
        border-radius: 4px;
    }
    // Hover and focus
    .listhead:hover input ~ .control_indicatior,
    .listhead input:focus ~ .control_indicator {
        background: $white;
    }
    // Checked
    .listhead input:checked ~ .control_indicator{
        background: $white;
    }
    // Hide default browser input
    .listhead input:disabled ~ .control_indicator {
        background: $primary-color;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        content: '';
        position: absolute;
        display: none; 
    }

    .listhead input:checked ~ .control_indicator:after {
        display: block; // Show check
    }

    .listhead-checkbox .control_indicator:after {
        left: 9px;
        top: 4px;
        width: 5px;
        height: 12px;
        border: solid $crimson;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .box-not-found{
        padding: 20px;
        width: 100%;
        text-align: center;
        p{
            color: $crimson;
            font-size: 16px;
        }
    }
</style>