<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <template v-for="(tab, index) in tabsData">
          <li :key="index" v-if="$can(tab.permissions)" :class="{'is-active': activeTabIndex === index}"
              @click="changeTab(tab, index)">
            <a>{{ tab.title }}</a>
          </li>
        </template>
      </ul>
    </div>

    <div class="tab-content">
<!--      <keep-alive>-->
<!--        <component ref="componentRender"-->
<!--                   :key="currentTab.nameKey"-->
<!--                   :component="activeComponent"-->
<!--                   :is="activeComponent"-->
<!--                   :status="currentTab.status"-->
<!--                   @dataCountUpdated="updateDataCount"/>-->
<!--      </keep-alive>-->
        <component ref="componentRender"
                   :key="currentTab.nameKey"
                   :component="activeComponent"
                   :is="activeComponent"
                   :status="currentTab.status"
                   @dataCountUpdated="updateDataCount"/>
    </div>

  </div>
</template>

<script>
import ListRequestedGenericCOD from "@views/Accounting/Customer/COD/Generic/ListRequestedGenericCOD";

export default {
  components: {
    ListRequestedGenericCOD
  },

  data: () => ({
    activeTabIndex: 0,
    tabsData: [
      {
        title: 'ທັງໝົດ',
        nameKey: 'AllRequestedCustomerCodList',
        queryTabName: 'all',
        component: ListRequestedGenericCOD,
        permissions: ['accountingcod'],
        status: 'pending',
        count: 0,
      },
      {
        title: 'ໂອນປົກກະຕິ 0%',
        nameKey: 'StandardRequestedCustomerCodList',
        queryTabName: 'standard',
        component: ListRequestedGenericCOD,
        permissions: ['accountingcod'],
        status: 'pending',
        count: 0,
      },
      {
        title: 'ໂອນມື້ຖັດໄປ 1%',
        nameKey: 'NextDayRequestedCustomerCodList',
        queryTabName: 'next_day',
        component: ListRequestedGenericCOD,
        permissions: ['accountingcod'],
        status: 'pending',
        count: 0,
      },
      {
        title: 'ໂອນພາຍໃນມື້ 3%',
        nameKey: 'WithinDayRequestedCustomerCodList',
        queryTabName: 'within_day',
        component: ListRequestedGenericCOD,
        permissions: ['accountingcod'],
        status: 'pending',
        count: 0,
      },
    ],
  }),
  computed: {
    currentTab() {
      return this.tabsData[this.activeTabIndex];
    },
    activeComponent() {
      return this.currentTab.component;
    },
  },
  methods: {
    changeTab(tab, index) {
      this.activeTabIndex = index;
      const newRouteQuery = {
        path: this.$router.currentRoute.path,
        query: {tab: tab.queryTabName}
      };
      this.$router.replace(newRouteQuery).catch(() => {
      });
    },
    updateDataCount(dataCount) {
      this.tabsData[this.activeTabIndex].count = dataCount.count || 0;
    },
    checkTabFromRouteQuery() {
      const tabQuery = this.$route.query.tab;
      const tabResultIndex = this.tabsData.findIndex((item) => {
        return item.queryTabName === tabQuery;
      });
      if (tabResultIndex !== -1) {
        const tab = this.tabsData[tabResultIndex];
        this.changeTab(tab, tabResultIndex);
      }
    },
  },
  mounted() {
    this.checkTabFromRouteQuery()
  },
}
</script>

<style scoped lang="scss">
.tabs {
  ul li {
    font-weight: bold;
  }
}
</style>
