export default {
    data:()=>({
        orderDetails: [],
        order: {},
        trackingStatues: [],
        isLoading: false
    }),
    methods: {
        FetchOrderDetails(){
            this.isLoading = true;
            this.$http.get('marketing/shipment/order/detail/' + this.$route.params.id).then((res) => {
                this.orderDetails = res.data.parcels_in_shipment;
                this.order = res.data.shipment;
                setTimeout(()=>{
                    this.isLoading = false;
                    this.TrackingStatus();
                }, 500);
            }).catch(()=>{});
        },
        TrackingStatus(){
            this.$http.get('v1/orders/tracking/' + this.order.shipment_number).then((res) => {
                this.trackingStatues = res.data.tracking_events;
            }).catch(()=>{});
        }
    },
}
