<template>
    <div>
        <template>
            <h4
                    class="not-margin"
                    :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
            >
                <i
                        class="fal fa-info-circle"
                        :style="{ width: '30px', fontSize: '18px' }"
                ></i>
                ຂໍ້ມູນການແປງ
            </h4>
        </template>

        <div class="columns" :style="{ marginTop: '10px' }">
            <div class="field column">
                <div class="control">
                    <div class="control">
                        <label class="label">ສະຖານະ:</label>
                        <div class="select">
                            <select class="input" v-model="userKYC.status">
                                <option v-if="tabQuery !== 'confirm'" value="rejected">ຂໍ້ມູນຜິດ</option>
                                <option v-if="tabQuery !== 'confirm' && tabQuery !== 'rejected'" value="approved">
                                    ຢຶນຢັນ
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="control" v-if="tabQuery !== 'confirm'">
                        <label class="label">ໝາຍເຫດ
                        </label>
                        <input
                                class="input"
                                type="text"
                                name="remark"
                                v-model="userKYC.description"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div ref="target" id="target" class="center">
            <div class="con-btns">
                <vs-button
                        ref="button"
                        flat
                        @click="EditItem()"
                        class="form-btn-loading"
                        :loading="loading">
                    ເເກ້ໄຂ
                </vs-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['userKYC', 'tabQuery'],
    data: () => ({
        isEnable: true,
        loading: false,
    }),
    methods: {
        EditItem() {
            this.loading = true;
            this.$http.post('admin-user-kyc/' + this.userKYC.id, this.userKYC).then(() => {
                setTimeout(() => {
                    this.loading = false;
                    this.$emit("close");
                    this.$emit("success");
                    this.$notification.OpenNotification_EditItem_OnSuccess(
                        "top-right",
                        "dark",
                        3000
                    );
                }, 300);
            }).catch((error) => {
                this.loading = false;
                if (error.response.status === 422) {
                    var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }
    },
    created() {
        console.log(this.userKYC);
    }
}
</script>
<style lang="scss">

</style>
