<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນລູກຄ້າ ວັນເປີດງານ
        <span class="countTotal">({{ customers.length }})</span>
        <span class="btn-add">
          <div class="columns">
              <vs-button v-if="selected.length > 0" style="margin-right: 15px;"
                         icon flat @click="PrintExcel(selected)">
            <i class="fal fa-file-spreadsheet"></i>
          </vs-button>
           <label style="margin-top: 15px; margin-right: 10px" class="label">
              ຄົ້ນຫາ
            </label>
            <input style="margin-top: 4px; margin-right: 4px; min-width: 189px; max-width: 189px" class="input"
                   type="text" name="search" v-model="search"/>
            <div class="select control"
                 style="margin-right: 8px; margin-top: 0.25rem; min-width: 175px; max-width: 175px">
              <select v-model="gender">
                <option value="">ທັງໝົດ</option>
                <option value="male">ຊາຍ</option>
                <option value="female">ຍິງ</option>
              </select>
            </div>

            <div class="select control"
                 style="margin-right: 8px; margin-top: 0.25rem; min-width: 175px; max-width: 175px">
              <select v-model="invite_status">
                <option value="">ທັງໝົດ</option>
                <option value="pending">ລົງທະບຽນແລ້ວ</option>
                <option value="joined">ເຂົ້າຮ່ວມແລ້ວ</option>
              </select>
            </div>

              <vs-button circle icon flat @click="OpenModalAdd()" style="margin-right: 10px">
            <i class="fal fa-plus-circle"></i>
          </vs-button>

          </div>
        </span>
      </h4>
    </div>
    <div class="center fit-width">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th id="table-checkbox">
              <vs-checkbox
                  :indeterminate="selected.length === customers.length"
                  v-model="allCheck"
                  @change="selected = $vs.checkAll(selected, customers)"
              />
            </vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ເພດ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="customer"
              v-for="(customer, index) in $vs.getPage(customers, page, max)"
              :is-selected="!!selected.includes(customer)"
          >
            <vs-td checkbox id="table-checkbox" style="width: 10px">
              <vs-checkbox :val="customer" v-model="selected"/>
            </vs-td>
            <vs-td>{{ customer.c_id }}</vs-td>
            <vs-td>{{ prefix[customer.gender] }}</vs-td>
            <vs-td>{{ customer.name }}</vs-td>
            <vs-td>{{ customer.surname }}</vs-td>
            <vs-td>{{ customer.tel }}</vs-td>
            <vs-td>{{ status[customer.invite_status] }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="OpenModalNotification(customer.id)">
                  <i class="fal fa-check-circle"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(customer.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="OpenModalDelete(customer.id)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination
              v-model="page"
              :length="$vs.getLength(customers, max)"
          />
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddCustomer @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditCustomer
            :listCustomer="listCustomer"
            @close="close"
            @success="FetchData()"
        />
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DelCustomer @close="close" :customerId="customerId" @success="FetchData()"/>
      </template>
    </ModalDelete>

    <ModalNotification>
      <template v-slot="{ close }">
        <ConfirmJoined @close="close" :customerId="customerId" @success="FetchData()"/>
      </template>
    </ModalNotification>

    <ExcelTemplate v-show="false" ref="ExportExcel"/>
  </div>
</template>
<script>
import AddCustomer from "./Dialog/Add";
import Loading from "@/views/Loading";
import EditCustomer from "./Dialog/Edit";
import DelCustomer from "./Dialog/Delete";
import {mapActions} from "vuex";
import ExcelTemplate from "@coms/printing/ExcelTemplate";
import ConfirmJoined from "@views/SuperAdmin/Customers/CelebrateCustomer/Dialog/ConfirmJoined";

export default {
  components: {
    Loading,
    EditCustomer,
    DelCustomer,
    AddCustomer,
    ExcelTemplate,
    ConfirmJoined
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    selected: [],
    customers: [],
    listCustomer: {},
    customerId: "",
    search: "",
    allCheck: false,
    prefix: {
      'male': 'ທ້າວ',
      'female': 'ນາງ'
    },

    status: {
      'pending': 'ລົງທະບຽນແລ້ວ',
      'joined': 'ຮ່ວມງານແລ້ວ'
    },

    gender: "",
    invite_status:""
  }),
  watch: {
    search() {
      this.delaySearchData();
    },

    invite_status() {
      this.delaySearchData();
    },

    gender() {
      this.delaySearchData();
    }
  },
  methods: {
    PrintExcel(selected) {
      const ref = this.$refs["ExportExcel"];
      if (ref) {
        ref.PrintExcel(selected);
      }
    },

    filterCus(customerId) {
      return (
          this.customers.filter((item) => {
            return item.id === customerId;
          })[0] || {}
      );
    },

    FetchData() {
      this.isLoading = true;
      this.$http
          .get("list-celebrate")
          .then((res) => {
            setTimeout(() => {
              this.isLoading = false;
              this.$emit("close");
            }, 200);
            setTimeout(() => {
              this.customers = res.data;
            }, 100);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.LogoutUser();
            }
          });
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    delaySearchData() {
      const request = `invite_status=${this.invite_status}&gender=${this.gender}&query=${this.search}`;
      this.isLoading = true;
      this.$http
          .get(`list-celebrate?${request}`)
          .then((res) => {
            setTimeout(() => {
              this.isLoading = false;
              this.$emit("close");
            }, 200);
            setTimeout(() => {
              this.customers = res.data;
            }, 100);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.LogoutUser();
            }
          });
    },

    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },

    OpenModalEdit(customerId) {
      this.listCustomer = {...this.filterCus(customerId)};
      this.$store.commit("modalEdit_State", true);
    },

    OpenModalDelete(customerId) {
      this.customerId = customerId;
      this.$store.commit("modalDelete_State", true);
    },

    OpenModalNotification(customerId) {
      this.customerId = customerId;
      this.$store.commit("modalNotification_State", true);
    },

  },
  created() {
    this.FetchData();
    this.delaySearchData = this.lodash.debounce(this.delaySearchData, 300);
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;

}

#table-checkbox {
  width: 10px !important;
}

</style>