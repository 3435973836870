var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('h4',{staticClass:"not-margin",style:({
        fontWeight: 'bold',
        color: '#195BFF',
        fontSize: '18px',
        marginTop: '10px',
      })},[_c('i',{staticClass:"fal fa-info-circle",style:({ width: '30px', fontSize: '18px' })}),_vm._v(" ເພີ່ມລູກຄ້າເຊັນສັນຍາ ")])],_c('div',{staticClass:"field column"},[_c('label',{staticClass:"label",staticStyle:{"text-align":"left"}},[_vm._v(" ເບີໂທ "),_c('span',{staticClass:"text-danger"},[_vm._v("* "+_vm._s(_vm.errors.first("tel")))]),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.server_errors.tel)+" ")])]),_c('div',{staticClass:"field has-addons"},[_c('p',{staticClass:"control",staticStyle:{"flex":"auto"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.tel),expression:"value.tel"},{name:"validate",rawName:"v-validate",value:({
              required: true,
              numeric: true,
              max: 8,
              min: 7,
            }),expression:"{\n              required: true,\n              numeric: true,\n              max: 8,\n              min: 7,\n            }"}],staticClass:"input",attrs:{"type":"text","name":"tel","placeholder":"XXXXXXX..."},domProps:{"value":(_vm.value.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.value, "tel", $event.target.value)}}})])])]),_c('div',{ref:"target",staticClass:"center",attrs:{"id":"target"}},[_c('div',{staticClass:"con-btns"},[_c('vs-button',{ref:"button",staticClass:"form-btn-loading",attrs:{"flat":""},on:{"click":function($event){return _vm.ValidateForm()}}},[_vm._v("ບັນທຶກລູກຄ້າ ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }