export default {

    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            new_distribution_center: '',
        },
        listDistributionCenters: [],
        selectedItem: null,
        errorMessages: {
            ERROR_OUTBOUND_TO_OWNER: 'ປາຍທາງສູນຄັດແຍກບໍ່ຖືກຕ້ອງ',
            ERROR_UN_PROCESSABLE_BAG_BILL_NUMBER: 'ບໍ່ສາມາດປ່ຽນໄດ້',
            ERROR_INVALID_DISTRIBUTION_CENTER_DESTINATION_ID: 'ປາຍທາງສູນຄັດແຍກບໍ່ຖືກຕ້ອງ',
            ERROR_CANNOT_USE_CROSS_LINKED_DISTRIBUTION_CENTER_PATHS: 'ປາຍທາງສູນຄັດແຍກບໍ່ຖືກຕ້ອງ',
        }
    }),

    methods: {
        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        }, // Validate-Form

        EditItem() {
            this.btnLoading = true;
            this.$http.post('/v1/auth/branches/shipments/orders/tracking/packing-parcel-orders/change-distribution-center-destination',
                {
                    "bag_bill_number": this.selectedShipmentBag.bag_number,
                    "new_distribution_destination_id": this.selectedShipmentBag.distribution_center_id
                }).then(() => {
                setTimeout(() => {
                    this.btnLoading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                }, 300);
            }).catch(error => {
                this.btnLoading = false;
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    // eslint-disable-next-line no-unused-vars
                    for (let [key] of Object.entries(obj)) {
                        this.server_errors.new_distribution_center = this.errorMessages[key];
                    }
                } else if (error.response.status == 403) {
                    this.server_errors.new_distribution_center = 'ບໍ່ມີສິດໃນການແກ້ໄຂ';
                }
            });
        }, // EditItems
        fetchDistributionCenters() {
            this.btnLoading = true;
            this.$http.get('v1/listing/distribution_centers').then(res => {
                setTimeout(() => {
                    this.listDistributionCenters = res.data;
                }, 100);
            }).catch(() => {
            }).finally(() => {
                this.btnLoading = false;
            });
        },
    },
    watch: {
        'selectedShipmentBag.distribution_center_id': function () {
            this.server_errors.new_distribution_center = '';
        },
    },
}
