<template>
    <div class="container">
        <div class="box-title" id="scrollTo">
            <h4>
                <i class="fal fa-pencil-alt"></i> ເເກ້ໄຂ ເເພ໋ກເເກ໋ດ
            </h4>
        </div>

        <div class="columns is-multiline" :style="{marginTop: '20px'}">
            <div class="column is-4">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ຊື່ເເພ໋ກເເກ໋ດ
                            <span class="text-danger">* {{ errors.first('package_name') }}</span>
                            <span class="text-danger">
                                {{server_errors.name}}
                            </span>
                        </label>
                        <input 
                            class="input" name="package_name" 
                            placeholder="ຊື່ເເພ໋ກເເກ໋ດ..." 
                            v-model="packages.name" 
                            v-validate="'required'"
                        >
                    </div>
                </div>
            </div>
            <div class="column is-2">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ຂະໜາດພັດສະດຸ
                            <span class="text-danger">* {{ errors.first('package_size') }}</span>
                            <span class="text-danger">
                                {{server_errors.sizeName}}
                            </span>
                        </label>
                        <div class="select">
                            <select class="input" v-model="packages.package_size_id" name="package_size" v-validate="'required'">
                                <option v-for="(packageSize, index) in packageSizes" :key="index" :value="packageSize.id">
                                    {{packageSize.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-2">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ລາຄາຂົນສົ່ງ
                            <span class="text-danger">* {{ errors.first('package_price') }}</span>
                            <span class="text-danger">
                                {{server_errors.price}}
                            </span>
                        </label>
                        <currency-input 
                            v-model="packages.price" 
                            class="input" name="package_price"
                            placeholder="ລາຄາກ່ອງ..." 
                            v-validate="'required|numeric'"
                        />
                    </div>
                </div>
            </div>
            <div class="column is-2">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ລາຄາກ່ອງ
                            <span class="text-danger">* {{ errors.first('packing_box') }}</span>
                            <span class="text-danger">
                                {{server_errors.package_price}}
                            </span>
                        </label>
                        <currency-input 
                            v-model="packages.packing_box" 
                            class="input" name="packing_box"
                            placeholder="ລາຄາກ່ອງ..." 
                            v-validate="'required|numeric'"
                        />
                    </div>
                </div>
            </div>
            <div class="column is-2">
                <div class="control">
                    <label class="label">ປະເພດຂົນສົ່ງ</label>
                    <div class="select">
                        <select class="input" v-model="packages.freight_type">
                            <option v-for="(item, index) in freightTypes" :key="index" :value="item.type">
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column is-12">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ຮູບເເບບການຂົນສົ່ງ
                            <span class="text-danger">* {{ errors.first('is_across') }}</span>
                            <span class="text-danger">
                                {{server_errors.is_across}}
                            </span>
                        </label>
                        <div class="columns" :style="{marginTop: '-5px'}">
                            <div class="column is-2">
                                <vs-radio danger v-model="packages.is_across" val="true" :style="{marginLeft: '-80px'}" :disabled="packages.is_across == 'false'">
                                    ຕ່າງເເຂວງ
                                </vs-radio>
                            </div>
                            <div class="column is-2">
                                <vs-radio danger v-model="packages.is_across" val="false" :style="{marginLeft: '-120px'}" :disabled="packages.is_across == 'true'">
                                    ພາຍໃນເເຂວງ
                                </vs-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="columns">
            <div class="column is-12">
                <div class="control">
                    <label class="listhead listhead-checkbox" :style="{marginTop: '6px', marginLeft: '-3px'}">
                        <input 
                            type="checkbox" 
                            v-model="checkedAll_Provice" 
                            @click="CheckAllProvince()"
                        />
                        <p :style="{marginLeft: '10px', fontWeight: 'bold'}">
                            ເເຂວງທັງໝົດ
                            ( <span :style="{color: 'crimson'}">{{checked_Province.length}}</span> )
                            <span class="text-danger">
                                {{server_errors.provinceId}}
                            </span>
                        </p>
                        <div class="control_indicator"></div>
                    </label> 
                </div>
                <div class="box-province" :style="{marginTop: '15px'}">
                    <div class="columns is-multiline">
                        <div class="column is-2" v-for="(item, index) in Provinces" :key="index">
                            <div class="province">
                                <label class="listhead listhead-checkbox">
                                    <input 
                                        type="checkbox" 
                                        v-model="checked_Province" 
                                        :value="item.id" 
                                        :id="item.id"
                                    />
                                    <p :style="{marginTop: '6px'}">{{item.name}}</p>
                                    <div class="control_indicator"></div>
                                </label>
                            </div>
                        </div>
                        <div class="box-not-found" v-if="!Provinces.length">
                            <p>
                                ບໍ່ມີເເຂວງ...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-12" id="box-btn-save">
                <div ref="target" id="target" class="center">
                    <div class="con-btns">
                        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
                            <i class="fal fa-box-full"></i> ເເກ້ໄຂ ເເພ໋ກເເກ໋ດ
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
import MixinsEditPackages from '@coms/../../src/Mixins/Packages/Packages/Edit';
import { ValidatePackages } from '@coms/../../src/Plugins/Validation/Packages/Validation';

export default {
    
    mixins:[MixinsEditPackages],

    created(){
        this.FetchData();
        ValidatePackages(this);
    }
}
</script>

<style lang="scss" scoped>
.box-province{
    margin-top: 10px;
    background: $white;
    width: 100%;
    padding: 10px;
    max-height: 320px;
    min-height: 60px;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: scroll;
    .province{
        background: $crimson;
        width: 100%;
        padding: 2px;
        height: 40px;
        border-radius: 2px;
        font-family: $font;
        color: $white;
    }
}
// Basic control styles
    .listhead {
        display: block;
        position: relative;
        padding-left: 30px;
        margin: 0 0 15px 0;
        cursor: pointer;
        font-size: 16px;
    }
    // Hide default browser input
    .listhead input{
        vertical-align:top;
        margin-right:10px;
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    // Custom control
    .control_indicator{
        position: absolute;
        top: 1px;
        left: 4px;
        height: 23px;
        width: 23px;
        background: $white;
        border-radius: 4px;
    }
    // Hover and focus
    .listhead:hover input ~ .control_indicatior,
    .listhead input:focus ~ .control_indicator {
        background: $white;
    }
    // Checked
    .listhead input:checked ~ .control_indicator{
        background: $white;
    }
    // Hide default browser input
    .listhead input:disabled ~ .control_indicator {
        background: $primary-color;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        content: '';
        position: absolute;
        display: none; 
    }

    .listhead input:checked ~ .control_indicator:after {
        display: block; // Show check
    }

    .listhead-checkbox .control_indicator:after {
        left: 9px;
        top: 4px;
        width: 5px;
        height: 12px;
        border: solid $crimson;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    #box-btn-save{
        display: flex;
        .center{
            margin: 0 auto;
            margin-top: 10px;
            button{
                i{
                    width: 50px;
                }
                padding: 6px;
            }
        }
    }
</style>