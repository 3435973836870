<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{marginTop: '10px'}">
        <img src="../../../../../assets/delete-trash.png" class="img-delete">
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{fontSize: '18px', color: '#195BFF'}">
        ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການລຶບຂໍ້ມູນນີ້
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button
            :loading="loading"
            transparent :style="{color: 'crimson'}"
            @click="deleteItem()">
          ຢືນຢັນ
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          ຍົກເລີກ
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ['id', 'branch_id'],

  data: () => ({
    loading: false
  }),
  methods: {
    deleteItem() {
      this.loading = true;
      this.$axios.delete('branch-link/' + this.branch_id + '?target=' + this.id).then(res => {
        if (res.data.success) {
          this.$emit('close');
          this.$emit('success');
          this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
        }
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style lang="scss">

</style>
