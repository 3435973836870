<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal">({{ listShipmentBagCount }})</span>
        <!-- <span class="btn-add">
            <button class="button  is-danger btn-clear" @click="ClearData()">ລ້າງຂໍ້ມູນ</button>
        </span> -->
      </h4>
    </div>

    <div class="box" :style="{marginTop: '20px'}">
      <div class="columns">
        <div class="column is-6">
          <div class="control">
            <label class="label">ເລກທີ່ຖົງ</label>
            <input
                class="input" name="search"
                v-model="searchItem"
                placeholder="ກະລຸນາພີມເລກບີນຖົງ..."
                @keyup.enter="FilterByShipmentBagNumber()">
          </div>
        </div>
        <div class="column is-6">
          <div class="control">
            <label class="label">ເລີ່ມເເຕ່ວັນທີ</label>
            <vuejs-datepicker
                v-model="startDate"
                input-class="input"
                format="d-M-yyyy"
                name="valid_date"
                v-validate="'required'"
            >
            </vuejs-datepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເລກຖົງ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <!--            <vs-th>ຕົ້ນທາງ</vs-th>-->
            <!--            <vs-th>ປາຍທາງ ( ສູນຄັດເເຍກ )</vs-th>-->
            <!--            <vs-th>ປາຍທາງ ( ສາຂາ )</vs-th>-->
            <vs-th>ສະຖານະ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in listShipmentBag" :key="index">
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>
              {{ item.bag_number }}
            </vs-td>
            <vs-th> {{ item.start_branch }}</vs-th>
            <vs-th> {{ item.end_branch }}</vs-th>
            <!--            <vs-td>-->
            <!--              {{ item.start_route_name }}-->
            <!--            </vs-td>-->
            <!--            <vs-td>-->
            <!--              {{-->
            <!--                item.end_route_distribution_name != null ? item.end_route_distribution_name : '.............................'-->
            <!--              }}-->
            <!--            </vs-td>-->
            <!--            <vs-td>-->
            <!--              {{-->
            <!--                item.end_route_province_branch_name != null ? item.end_route_province_branch_name : '.............................'-->
            <!--              }}-->
            <!--            </vs-td>-->
            <vs-td>
              {{ la_status[item.status] }}
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="PrintBagNumber(item)">
                  <i class="fad fa-print"></i>
                </vs-button>
                <vs-button circle icon flat
                           @click="$router.push({name: 'distribution.center.shipment.bag.detail', params:{id: item.id}, query:{bag_number: item.bag_number}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
                <vs-button v-if="item.status === 'processing'"
                    circle icon flat @click="OpenModalEdit(item)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FilterListOrders()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalEdit>
      <template v-slot="{ close }">
        <ChangeDistributionCenterDialog :selectedShipmentBag="selectedShipmentBag"
                                        @close="close" @success="FilterListOrders()"/>
      </template>
    </ModalEdit>

  </div>
</template>

<script>
import mixinListShipmentBag from '../../../Mixins/Distribution-Center/Shipment-Orders/List-Shipment-Bag';
import vuejsDatepicker from 'vuejs-datepicker';
import pagination from '../../../Plugins/Paginations/Pagination';
import Loading from '../../Loading';
import ChangeDistributionCenterDialog from './Dialog/ChangeDistributionCenterDialog';

export default {
  components: {
    vuejsDatepicker,
    pagination,
    Loading,
    ChangeDistributionCenterDialog,
  },

  mixins: [mixinListShipmentBag],

  created() {
    this.FilterListOrders();
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}
</style>
