import { Validator } from 'vee-validate';

const validate = {
    custom: {
        orderBy:{
            required: '(ກະລຸນາປ້ອນ OrderBy ກ່ອນ...)',
            numeric: '(ກະລຸນາປ້ອນເປັນໂຕເລກ...)',
        },
        profile:{
            required: '(ກະລຸນາເລືອກໂປຮໄຟລກ່ອນ)',
            mimes: '(ກະລູນາເລືອກ jpeg,jpg,png,gif ເທົ່ານັ້ນ...)',
            size: '(ຂະໜາດບໍ່ຄວນເກີນ 5 MB.)',
        }
    }
};

export const ValidateBanners = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

