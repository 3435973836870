<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                  ຂໍ້ມູນ ຕິດຕໍ່ພວກເຮົາ
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>ລຳດັບ</vs-th>
                    <vs-th>ຊື່ບໍລິສັດ</vs-th>
                    <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
                    <vs-th>ອີເມວ</vs-th>
                    <vs-th>ເວັບໄຊ</vs-th>
                    <vs-th>ເຟສບຸກ</vs-th>
                    <vs-th>ທີ່ຢູ່ບໍລິສັດ</vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="index" v-for="(contact, index) in $vs.getPage(listContacts, page, max)" :data="contact">
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '400px'}">{{ contact.name }}</vs-td>
                        <vs-td :style="{width: '230px'}">{{ contact.tel }}</vs-td>
                        <vs-td :style="{width: '230px'}">{{ contact.email }}</vs-td>
                        <vs-td :style="{width: '230px'}">{{ contact.website }}</vs-td>
                        <vs-td :style="{width: '230px'}">{{ contact.facebook }}</vs-td>
                        <vs-td :style="{width: '300px'}">
                            ( <span>{{contact.address}}</span> )
                        </vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalEdit(contact.id)">
                                    <i class="fad fa-pencil-alt"></i>
                                </vs-button>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listContacts, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditContactUs @close="close" :listConatct="filterContact" @success="FetchData()"/>
            </template>
        </ModalEdit>

    </div>
</template>
<script>
import EditContactUs from './Dialog/Edit';
import Loading from '../../Loading';


export default {
    components:{
        EditContactUs,
        Loading
    },
    data:()=>({
        active: 1,
        page: 1,
        max: 15,
        listContacts: [],  
        contactId: '',
        filterContact: {},
        isLoading: true,
    }),
    methods:{
        OpenModalEdit(contactId){
            this.filterContact = {...this.FilterItem(contactId)};
            console.log(this.filterContact);
            this.$store.commit('modalEdit_State', true);
        },
        FilterItem(contactId){
            return this.listContacts.filter(item=>{
                return item.id == contactId;
            })[0] || {};
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-contact-us').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.listContacts = res.data.contactUs;
                }, 100);
            }).catch(()=>{
                this.isLoading = false;
                this.$emit('close');
            });
        },
        FormatPrice(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        }
    },
    created(){
        this.FetchData();
    },
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
    .profile{
        width: 100px;
        height: 60px;
        border-radius: 5px;
    }
}
</style>