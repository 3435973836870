<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t('shipment.ຂໍ້ມູນລູກຄ້າ') }} Pre-Company
      </h4>
    </template>

    <!-- name -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('shipment.name') }}
          <span class="text-danger">* {{ errors.first("name") }}</span>
          <span class="text-danger">
              {{ server_errors.name }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="name"
            :placeholder="$t('validator.required')"
            v-model="listCustomer.customer_name"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- email -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('shipment.email') }}
          <span class="text-danger"
          >* {{ errors.first("email") }}</span
          >
          <span class="text-danger">
              {{ server_errors.email }}
            </span>
        </label>
        <input
            class="input"
            type="email"
            name="email"
            :placeholder="$t('validator.required')"
            v-model="listCustomer.customer_email"
            v-validate="'required|max:191|email'"
        />
      </div>
    </div>

    <!-- tel -->
    <div class="field column">
      <label class="label" style="text-align: left">
        {{ $t('shipment.tel') }}
        <span class="text-danger">* {{ errors.first("tel_add") }}</span>
        <span class="text-danger">
            {{ server_errors.tel }}
          </span>
      </label>
      <div class="field has-addons">
        <p class="control">
            <span class="select">
              <select style="min-width: 80px" v-model="telType">
                <option value="020">020</option>
                 <option value="021">021</option>
                <option value="030">030</option>
                <option value="+86">+86</option>
              </select>
            </span>
        </p>
        <p class="control" style="flex: auto">
          <input
              class="input"
              type="text"
              name="tel_add"
              placeholder="XXXXXXX..."
              v-model="listCustomer.customer_tel"
              v-validate="{
                required: true,
                numeric: true,
                max: telLength,
                min: telLength,
              }"
          />
        </p>
      </div>
    </div>

    <!-- discount -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('ລາຍການOrderພັດສະດຸ.ສ່ວນຫຼຸດ') }} %
          <span class="text-danger"> {{ errors.first("discount") }}</span>
          <span class="text-danger">
              {{ server_errors.discount }}
            </span>
        </label>
        <number-input class="number-input" v-model="listCustomer.discount"
                      :placeholder="$t('ລາຍການOrderພັດສະດຸ.ສ່ວນຫຼຸດ') + '...'"/>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading">
          {{ $t('shipment.ເເກ້ໄຂຂໍ້ມູນລູກຄ້າ') }} Pre-company
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

export default {
  props: ["listCustomer"],
  data() {
    return {
      active: false,
      isLoading: false,
      server_errors: {
        name: "",
        email: "",
        tel: "",
        discount: ""
      },
      telType: "",
      telTypeLengths: {
        '020': 8,
        '021': 6,
        '+86': 11,
        'default': 7
      }
    };
  },
  computed: {
    telLength() {
      return this.telTypeLengths[this.telType] || this.telTypeLengths['default']
    },
    telPrefix() {
      const value = this.listCustomer.customer_tel.length
      return Object.keys(this.telTypeLengths).find(key => this.telTypeLengths[key] === value)
    },
    zone() {
      if (this.telLength === 11) {
        return 'zh'
      }
      return 'la'
    }
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditData();
        }
      });
    },
    EditData() {
      this.$http.put('edit-precompany/' + this.listCustomer.id, {
        'name': this.listCustomer.customer_name,
        'email': this.listCustomer.customer_email,
        'tel':this.listCustomer.customer_tel,
        'discount': this.listCustomer.discount,
        'zone': this.zone
      }).then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
          }, 300);
        }
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    },


    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },

  },
  watch: {
    "listCustomer.customer_tel": function () {
      this.server_errors.tel = "";
    },
    'listCustomer.customer_email': function () {
      this.server_errors.email = "";
    },
  },

  created() {
    this.telType = this.telPrefix

    const dict = {
      custom: {
        name: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
        },

        email: {
          required: `(${this.$t('validator.required')}...)`,
          email: `(${this.$t('validator.email_invalid')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
        },

        tel_add: {
          required: `(${this.$t('validator.required')}...)`,
          numeric: `(${this.$t('validator.numeric')})`,
          max: (name, rules) => {
            return `(${this.$t('validator.limit_character', {length: rules[0]})})...`;
          },
          min: (name, rules) => {
            return `(${this.$t('validator.limit_character', {length: rules[0]})})...`;
          },
        },

        password: {
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          min: `(${this.$t('validator.min_character',  {min: 6})}...)`,
        },

        discount: {
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          numeric: `(${this.$t('validator.numeric')})`,
        }
      },
    };
    Validator.localize("en", dict);
  }
};
</script>

<style lang="scss" scoped>
.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}

.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.35rem;
}

</style>
