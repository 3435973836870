export default {
    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            userCardid: '',
            name: '',
            email: '',
            tel: '',
            password: '',
            roleId: '',
        },

        roleId: [],
        permissions: [],
    }),

    methods: {
        ShowPermission() {
            this.$axios.get('show-permission-roles', {
                params: {
                    roleId: this.listEmployee.roleId
                }
            }).then(res => {
                this.permissions = res.data;
            });
        },

        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        },

        EditItem() {
            this.btnLoading = true;
            this.$http.put('edit/employee', this.listEmployee).then(res => {
                if (res.data.success === true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error => {
                this.btnLoading = false;
                if (error.response.status === 422) {
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
    },

    watch: {
        'listEmployee.name': function () {
            this.server_errors.name = '';
        },
        'listEmployee.email': function () {
            this.server_errors.email = '';
        },
        'listEmployee.tel': function () {
            this.server_errors.tel = '';
        },
        'listEmployee.roleId': function () {
            this.server_errors.roleId = '';
        },
        'listEmployee.userCardid': function () {
            this.server_errors.userCardid = '';
        }
    },
}
