<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <li v-if="$can(['accounting-local','accountingmanager'])"
            @click="Tabs('origin')" :class="{'is-active': isActive === 'origin'}">
          <a>ຍອດຈັດສົ່ງສຳເລັດຕົ້ນທາງ</a>
        </li>
        <li v-if="$can(['accounting-local','accountingmanager'])" @click="Tabs('destination')"
            :class="{'is-active': isActive === 'destination'}">
          <a>ຍອດຈັດສົ່ງສຳເລັດCDC</a>
        </li>
      </ul>
    </div>
    <listForInsertInvoiceLocal :onclickEvent="isActive"/>
  </div>
</template>

<script>
import listForInsertInvoiceLocal from "@views/InvoiceLocalBranch/ListForInsert/listForInsertInvoiceLocal";

export default {
  components: {
    listForInsertInvoiceLocal
  },

  data: () => ({
    isActive: 'origin',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'tab.insert.invoice.local', query: {tab: value}}).catch(() => {
      });
    }
  },
  created() {
    if (this.$route.query.tab === 'origin') {
      this.isActive = 'origin';
    }  else {
      this.isActive = 'destination';
    }
  },
}
</script>

<style>

</style>
