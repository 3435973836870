<template>
    <div>
        <template>
          <h4 class="not-margin" :style="{marginTop: '10px'}">
              <img src="../../../../assets/delete-trash.png" class="img-delete">
          </h4>
        </template>

        <div class="con-content" :style="{ marginTop: '5px' }">
            <p :style="{fontSize: '18px', color: '#195BFF'}">
                ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການລຶບຂໍ້ມູນນີ້
            </p>
        </div>

        <template>
          <div class="con-footer" :style="{ marginTop: '25px' }">
            <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="DeleteItem(packageSizeId)">
              ຢືນຢັນ
            </vs-button>
            <vs-button  @click="$emit('close')" dark transparent>
              ຍົກເລີກ
            </vs-button>
          </div>
        </template>
    </div>
</template>
<script>
export default {
    props:['packageSizeId'],
    
    data:()=>({
     loading: false
    }),
     methods:{
        DeleteItem(packageSizeId){
          this.loading = true;
          this.$axios.delete('delete-package-sizes/' + packageSizeId).then(res=>{
              if(res.data.success == true){
                setTimeout(() => {
                  this.loading = false;
                  this.$emit('close');
                  this.$emit('success');
                  this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
                }, 200);
              }
          }).catch(()=>{
            this.$emit('close');
            this.$notification.OpenNotification_CheckRoleExists('top-right', 'danger', 3000);
          });
        }
    }
}
</script>
<style lang="scss">

</style>