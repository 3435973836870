<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເພີ່ມ ຄູປອງ
      </h4>
    </template>
    <div class="field" :style="{marginTop: '20px'}">
      <div class="field">
        <div class="control">
          <label class="label">
            ຊື່ຄູປ່ອງ
            <span class="text-danger">* {{ errors.first('name') }}</span>
            <span class="text-danger">
                        {{ server_errors.name }}
                    </span>
          </label>
          <input
              class="input"
              name="name"
              placeholder="ກະລຸນາປ້ອນຊື່ຄູປ໋ອງກ່ອນ..."
              v-model="value.name"
              v-validate="'required'">
        </div>
      </div>
      <div class="control">
        <label class="label">
          ລະຫັດຄູປອງ
          <span class="text-danger">* {{ errors.first('code') }}</span>
          <span class="text-danger">
                        {{ server_errors.code }}
                    </span>
        </label>
        <input
            class="input"
            name="code"
            placeholder="ກະລຸນາປ້ອນລະຫັດຄູປອງກ່ອນ..."
            v-model="value.code"
            v-validate="'required'">
      </div>
      <div class="control">
        <div class="center">
          <vs-checkbox v-model="value.is_only_new_customer">
            ສໍາລັບລູກຄ້າໃຫມ່
          </vs-checkbox>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">
          ປະເພດສ່ວນຫຼຸດ
          <span class="text-danger">* {{ errors.first('discount_type') }}</span>
          <span class="text-danger">
              {{ server_errors.discount_type }}
          </span>
        </label>
        <div class="select">
          <select class="input" v-model="selectedCouponType.type"
                  name="discountType" v-validate="'required'">
            <option v-for="(types, index) in couponTypes" :key="index" :value="types.type">
              {{ types.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">
          ປະເພດຂົນສົ່ງ
          <span class="text-danger">* {{ errors.first('shipment_pay_type') }}</span>
          <span class="text-danger">
                        {{ server_errors.shipment_pay_type }}
                    </span>
        </label>
        <div class="select">
          <select class="input" v-model="selectedShipmentPayType.type"
                  name="discountType" v-validate="'required'">
            <option v-for="(type, index) in shipmentPayType" :key="index" :value="type.type">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">
          ສ່ວນຫຼຸດ
          <span class="text-danger">* {{ errors.first('discount_cash') || errors.first('discount') }}</span>
          <span class="text-danger">
              {{ server_errors.discount }}
          </span>
        </label>
        <currency-input
            v-if="selectedCouponType.type == 'cash'"
            v-model="value.discount_cash"
            class="input" name="discount_cash"
            v-validate="'required'"
            placeholder="ສ່ວນຫຼຸດພິເສດເປັນເງີນສົດ..."
        />
        <input
            v-else
            class="input" name="discount"
            placeholder="ສ່ວນຫຼຸດພິເສດເປັນເປີເຊັນ..."
            v-validate="'required'"
            v-model="value.discount_percent">
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">
          ຈຳນວນຄູປອງຕໍ່ຄົນ
          <span class="text-danger">* {{ errors.first('amount_per_user') }}</span>
          <span class="text-danger">
              {{ server_errors.amount_per_user }}
          </span>
        </label>
        <input
            class="input" name="amount_per_user"
            placeholder="ກະລຸນາປ້ອນສວ່ນຫຼຸດກ່ອນ..."
            v-validate="'required|numeric'"
            v-model="value.amount_per_user">
      </div>
    </div>
    <div class="field">
      <div class="columns is-contents-input">
        <div class="column first">
          <div class="control">
            <label class="label">
              ຈຳນວນໃຊ້ຕໍ່ມື້ຕໍ່ຄົນ
              <span class="text-danger">*</span>
            </label>
            <input
                class="input"
                name="total_amount_per_day"
                placeholder="ກະລຸນາປ້ອນຈໍານວນກ່ອນ..."
                v-model="value.total_amount_per_day"
                v-validate="'required|numeric'">
          </div>
          <span class="text-danger">{{ errors.first('total_amount_per_day') }}</span>
          <span class="text-danger">
              {{ server_errors.total_amount_per_day }}
          </span>
        </div>
        <div class="column second">
          <div class="control">
            <label class="label">
              ຈຳນວນຄູປອງທັງຫມົດ
              <span class="text-danger">*</span>
            </label>
            <input
                class="input"
                name="total_amount"
                placeholder="ກະລຸນາປ້ອນສວ່ນຫຼຸດກ່ອນ..."
                v-model="value.total_amount"
                v-validate="'required|numeric'">
          </div>
          <span class="text-danger"> {{ errors.first('total_amount') }}</span>
          <span class="text-danger">
             {{ server_errors.total_amount }}
          </span>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="columns is-contents-input">
        <div class="column first">
          <div class="control">
            <label class="label">
              ຄ່າຂົນສົ່ງຂັ້ນຕໍ່າ
            </label>
            <input
                class="input"
                name="minimum_amount"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
                v-model="value.minimum_amount"
                v-validate="'numeric'">
          </div>
          <span class="text-danger"> {{ errors.first('minimum_amount') }}</span>
          <span class="text-danger">
                          {{ server_errors.minimum_amount }}
            </span>
        </div>
        <div class="column second" v-if="selectedCouponType.type === 'percent'">
          <div class="control">
            <label class="label">
              ຫລຸດສູງສຸດ
            </label>
            <input
                class="input"
                name="maximum_discount"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
                v-model="value.maximum_discount"
                v-validate="'numeric'">
          </div>
          <span class="text-danger"> {{ errors.first('maximum_discount') }}</span>
          <span class="text-danger">
                          {{ server_errors.maximum_discount }}
                      </span>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="columns is-contents-input">
        <div class="column first">
          <div class="control">
            <label class="label">
              ນໍ້າຫນັກເລີ່ມຕົ້ນ
            </label>
            <input
                class="input"
                name="start_weight"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
                v-model="value.start_weight"
                v-validate="'numeric'">
          </div>
          <span class="text-danger"> {{ errors.first('start_weight') }}</span>
          <span class="text-danger">
                          {{ server_errors.start_weight }}
                      </span>
        </div>
        <div class="column second">
          <div class="control">
            <label class="label">
              ນໍ້າຫນັກສູງສຸດ
            </label>
            <input
                class="input"
                name="end_weight"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
                v-model="value.end_weight"
                v-validate="'numeric'">
          </div>
          <span class="text-danger"> {{ errors.first('end_weight') }}</span>
          <span class="text-danger">
                          {{ server_errors.end_weight }}
                      </span>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">
          ວັນທີ່ໝົດອາຍຸ
          <span class="text-danger">* {{ errors.first('valid_date') }}</span>
          <span class="text-danger">
                        {{ server_errors.valid_date }}
                    </span>
        </label>
        <vuejs-datepicker
            v-model="value.valid_date"
            input-class="input"
            format="d-M-yyyy"
            name="valid_date"
            v-validate="'required'">
        </vuejs-datepicker>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">ບັນທຶກ
          ຄູປ໋ອງ
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import mixinsAddCoupon from '@coms/../../src/Mixins/Coupons/Add';
import vuejsDatepicker from 'vuejs-datepicker';
import {ValidateCoupons} from '@coms/../../src/Plugins/Validation/Coupons/Validation';


export default {
  components: {
    vuejsDatepicker
  },
  mixins: [mixinsAddCoupon],

  created() {
    ValidateCoupons(this);
  }
}
</script>

<style>

</style>
