<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ຂໍ້ມູນການແປງ
      </h4>
    </template>

    <div class="columns" :style="{ marginTop: '10px' }">
      <div class="field column">
        <div class="control">
          <div class="control">
            <label class="label">ສາຂາ:</label>
            <model-list-select
                :list="branches"
                v-model="item.branch_id"
                placeholder="ເລືອກສາຂາປາຍທາງ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading">
          ເເກ້ໄຂ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import {ModelListSelect} from 'vue-search-select';

export default {
  props: ['item'],
  components: {ModelListSelect},
  data: () => ({
    isEnable: true,
    loading: false,
    branches: [],
  }),
  created() {
    this.FetchListBranch();
  },

  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditItem();
        }
      });
    }
    ,
    FetchListBranch() {
      this.$http.get('v1/listing/branches?include=excluded').then((res) => {
        this.branches = res.data;
        this.branches.unshift({
          id: "",
          name: 'ບໍ່ມີ'
        });
      })
    }
    ,
    EditItem() {
      let formData = new FormData();
      formData.append('branch_id', this.item.branch_id);
      this.loading = true;
      this.$http.post('link/shop/branch/' + this.item.id, formData).then(() => {
        setTimeout(() => {
          this.loading = false;
          this.$emit("close");
          this.$emit("success");
          this.$notification.OpenNotification_EditItem_OnSuccess(
              "top-right",
              "dark",
              3000
          );
        }, 300);
      }).catch((error) => {
        this.loading = false;
        if (error.response.status === 422) {
          var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    }
  }
}
</script>
<style lang="scss">

</style>
