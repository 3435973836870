<template>
  <div class="columns login-section">
    <div class="column is-primary hidden container">
      <div class="login-future">
        <h1>ລະບົບບໍລິຫານຈັດການ ຮຸ່ງອາລຸນຂົນສົ່ງດ່ວນ</h1>
      </div>
    </div>
    <div class="column is-8-tablet is-12-mobile is-5-desktop is-4 login-component ">
      <Loading class="login-loading" v-if="isFirstLoading"/>
      <div class="logo-header">
        <img src="../../assets/logistic.png">
      </div>
      <h1>ເຂົ້າສູ່ລະບົບ ຮຸ່ງອາລຸນຂົນສົ່ງດ່ວນ</h1>

      <div class="lgoin-content" :style="{marginTop: '40px'}">
        <div class="field">
          <div class="control">
            <label>ອີເມວ
              <span class="text-danger">* {{ errors.first('email') }}</span>
            </label>
            <input
                class="input is-medium"
                placeholder="ກາລຸນາປ້ອນອີເມວ..."
                v-model="users.email" name="email"
                v-validate="'required|email|max:255'"
                @keyup.enter="$refs.password.focus()"
            >
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label>ລະຫັດຜ່ານ
              <span class="text-danger">* {{ errors.first('password') }}</span>
            </label>
            <input
                type="password" name="password"
                class="input is-medium"
                placeholder="ກາລຸນາປ້ອນລະຫັດຜ່ານ..."
                v-model="users.password"
                v-validate="'required|min:6|max:15'"
                ref="password" @keyup.enter="ValidateForm()"
            >
          </div>
        </div>
      </div>
      <div class="box-btn">
        <button class="button is-red" @click="ValidateForm()" :class="{'is-loading': btnLoading}">
          ເຂົ້າສູ່ລະບົບ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {ValidateFormSignIn} from '@coms/../../src/Plugins/Validation/Validate-Sign-In';
import Loading from "@views/Loading";
import router from "@/router";

export default {
  components: {
    Loading,
  },
  data: () => ({
    users: {
      email: '',
      password: '',
    },
    btnLoading: false,
    isFirstLoading: true,
    tabQueryInvoice: '',
  }),
  methods: {
    ...mapActions({
      AdminLogin: 'User/LoginUser'
    }),

    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.AdminSignIn();
        }
      });
    }, // ValidateForm

    AdminSignIn() {
      this.btnLoading = true;
      this.AdminLogin(this.users).then(({access_token}) => {
        /// re assign token
        const bearerToken = `Bearer ${access_token}`;
        this.$axiosDelivery.defaults.headers.common['Authorization'] = bearerToken;
        this.$axios.defaults.headers.common['Authorization'] = bearerToken;
        this.$http.defaults.headers.common['Authorization'] = bearerToken;
        /// re assign token

        /// After everything is ok
        if (this.$can(['accountingmanager', 'accountingoriginbranch', 'accountingoriginfranchise'])) {
          this.tabQueryInvoice = 'origin_freight'
        } else if (this.$can('accountingcdc')) {
          this.tabQueryInvoice = 'destination_freight'
        } else {
          this.tabQueryInvoice = 'parcel_price'
        }

        const item = window.localStorage.getItem('roleUser');
        const roleUsers = JSON.parse(item);
        if (roleUsers.includes('manager')) {
          router.push({name: 'list.dashboard'});
        } else if (roleUsers.includes('SuperAdmin')) {
          router.push({name: 'tab.solve.shipments', query: {tab: 'all'}});
        } else if (roleUsers.includes('deliverymanager')) {
          router.push({name: 'delivery.tab.order.request', query: {tab: 'pending'}});
        } else if (roleUsers.includes('branchmanager') || roleUsers.includes('franchise-manager')) {
          router.push({name: 'branch.tab.list.order', query: {tab: 'all'}});
        } else if (roleUsers.includes('accountingmanager') || roleUsers.includes('accountingoriginbranch') || roleUsers.includes('accountingoriginfranchise') || roleUsers.includes('accountingcdc') || roleUsers.includes('accountingcod')) {
          if (localStorage.getItem('Lang') === 'CN') {
            router.push({name: 'account.tab.all.shipments', query: {tab: 'all'}});
          } else {
            router.push({name: 'account.tab.list.order', query: {tab: this.tabQueryInvoice}});
          }
        } else if (roleUsers.includes('accounting-local')) {
          router.push({name: 'tab.invoice.local', query: {tab: 'origin'}});
        } else if (roleUsers.includes('distributioncentermanager')) {
          router.push({name: 'distribution.center.tab.all.shipments', query: {tab: 'all'}});
        } else if (roleUsers.includes('callcentervipcustomer')) {
          router.push({name: 'tab.solve.shipments', query: {tab: 'all'}});
        } else if (roleUsers.includes('service')) {
          router.push({name: 'account.tab.solve.shipments', query: {tab: 'all'}});
        } else if (roleUsers.includes('distribution')) {
          router.push({name: 'distribution.center.shipment.bag'});
        } else if (roleUsers.includes('marketing')) {
          router.push({name: 'marketing.tab.shipment'});
        } else if (roleUsers.includes('news')) {
          router.push({name: 'list.news'});
        } else if (roleUsers.includes('headinternmarketing')) {
          router.push({name: 'list.intern.marketing'});
        } else if (roleUsers.includes('marketinghelper')) {
          router.push({name: 'list.shop.item'});
        } else if (roleUsers.includes('suburbMarketing')) {
          router.push({name: 'marketing.tab.shipment', query: {tab: 'all'}});
        } else if (roleUsers.includes('halcenterlive')) {
          router.push({name: 'hal.live.coupon'});
        } else if (roleUsers.includes('shop-user') || roleUsers.includes('shop-manager')) {
          router.push({name: 'shop.payment'});
        } else if (roleUsers.includes('marketing-viewer') || roleUsers.includes('head-franchise')) {
          router.push({name: 'shipment.qty.graph'});
        } else if (roleUsers.includes('hr')) {
          router.push({name: 'feedback.index'});
        } else if (roleUsers.includes('TaxViewer')) {
          router.push({name: 'shipment.branch.quantities'});
        } else if (roleUsers.includes('injured-parcel-user')) {
          router.push({name: 'injuredParcel.index'});
        } else if (roleUsers.includes('settle-shipment-status-manager')) {
          router.push({name: 'shipment.settlement.index'});
        } else if (roleUsers.includes('cleared-shipment-status-manager')) {
          router.push({name: 'shipment.cleared.index'});
        } else if (roleUsers.includes('branchuser')) {
          router.push({name: 'parcelBranch.index'});
        } else if (roleUsers.includes('data-viewer')) {
          router.push({name: 'weekly.data.report'});
        }
        setTimeout(() => {
          this.btnLoading = false;
        }, 300);
      }).catch(() => {
        this.$notification.OpenNotification_SignIn_Error_Server('top-right', 'danger', 3000);
        setTimeout(() => {
          this.btnLoading = false;
        }, 300);
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.isFirstLoading = false;
    }, 520);
  },
  created() {
    ValidateFormSignIn(this);
  }
}
</script>
<style lang="scss">
.login-loading {
  .vs-loading {
    background: rgba(var(--vs-background), var(--vs-opacity)) !important;
  }
}
</style>
<style lang="scss" scoped>
h1 {
  font-weight: 600;
  line-height: 1.3em;
  font-size: 26px;
}

.login-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-height: 100vh;
  background-image: url("https://hal-logistics.la/images/slider/bannernews.jpeg");
  background-color: #f5f7f9;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
}

.logo-header img {
  width: 150px;
  height: 150px;
}

.login-future {
  height: 650px;
}

.login-future h1 {
  top: 20%;
  height: 650px;
  margin: 0;
  position: fixed;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateX(-25%);
  left: 0;
  right: 0;
}

.login-component {
  padding: 48px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .125);
}

.button.is-red {
  color: #fff;
  background-color: #E13530;
  height: 54px;
  min-height: 54px;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 600;
}

.button {
  border: none;
  transition: all .125s ease-in;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .12);
}

label {
  display: block;
  margin-bottom: 4px;
  font-family: Prompt;
  font-size: 16px;
  font-weight: 800;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 769px) {
  .column.hidden {
    flex: none;
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .login-future h1 {
    display: none;
  }
}
</style>
