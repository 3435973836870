<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-gifts"></i>
          ລາຍການຍິງເຊັນຮັບ COD
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FilterByShipmentNumber()"
              >
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px" v-if="this.listSignCod.length > 0">
          <vs-button
              ref="button" flat
              @click="printListSignCdc()"
              class="form-btn-loading"
              :loading="loading">
            export excel
          </vs-button>
        </div>
        <div class="column is-3 box-title">
          <h4 class="control">
            ຍອດຈັດສົ່ງທັງໝົດ: {{ FormatPrice(totalFreight) }} ກີບ
          </h4>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ເລກບິນ</vs-th>
            <vs-th>ວັນທີຝາກ</vs-th>
            <vs-th>ວັນທີຈັດສົ່ງ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ຄ່າສິນຄ້າ</vs-th>
            <vs-th>ຊື່ຜູ້ຝາກ</vs-th>
            <vs-th>ຊື່ຜູ້ຮັບ</vs-th>
            <vs-th>ຕົ້ນທາງ</vs-th>
            <vs-th>ປາຍທາງ</vs-th>
            <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
            <vs-th>ສ້າງໂດຍ</vs-th>
            <vs-th>ຈັດສົ່ງໂດຍ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ລາຍເຊັນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="item"
              v-for="(item, index) in $vs.getPage(listSignCod, page, max)">
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({name: 'list.shipment.detail', params:{id: item.id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ item.shipment_number }}</vs-td>
            <vs-td>{{ moment(item.start_date_actual).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td>{{ moment(item.paid_at).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td>{{ item.pieces }}</vs-td>
            <vs-td>{{ FormatPrice(item.weight) }}</vs-td>
            <vs-td>{{ FormatPrice(item.paid) }}</vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.sender_customer_name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.receiver_customer_name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.start_branch_name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.end_branch_name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td>{{ item.shipment_type }}</vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.created_by_name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.sign_name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td>{{ la_status[item.shipment_status_name] }}</vs-td>
            <vs-td>
              <div v-if="item.signature_file" class="img-size">
                <expandable-image
                    class="image"
                    :src=item.signature_file
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.remark }}
                </template>
              </vs-tooltip>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: true,
    listSignCod: [],
    searchItem: "",
    oldVal: "",
    totalFreight: 0,
    la_status_shipment_type: {
      'origin_freight_fees': 'ເກັບເງິນຕົ້ນທາງ',
      'destination_freight_fees': 'ເກັບເງິນ ( CDC )',
      'origin_freight_fees_cod': 'ເກັບເງິນ ( COD )',
      'destination_freight_fees_cod': 'ເກັບເງິນ ( COD & CDC )',
    },
    la_status: {
      'Pending': 'ລໍຖ້າດໍາເນີນການ',
      'Processing': 'ກຳລັງດຳເນີນການ',
      'Delivering': 'ກໍາລັງຈັດສົ່ງ',
      'Success': 'ຈັດສົ່ງສຳເລັດ',
      'Single Sign': 'ຮັບເຄື່ອງແບບແຍກ',
      'Timeout': 'ຈັດສົ່ງເຄື່ອງມື້ອື່ນ',
      'Contact Error': 'ເບີຕິດຕໍ່ບໍ່ໄດ້,ບໍ່ສາມາດຈັດສົ່ງ',
      'Lost': 'ເຄື່ອງເສຍ',
      'Return To Sender': 'ຈັດສົ່ງຄືນຜູ້ຝາກ',
      'Address Error': 'ທີ່ຢູ່ຜິດ(ປ່ຽນທີຢູ່ໃຫມ່)',
      'Trouble': 'ສະຖານະບໍ່ປົກກະຕິ',
      'Disposed': 'ທໍາລາຍ',
      'Customer Request Cancel': 'ລູກຄ້າຍົກເລີກ',
      'Wrong Flow': 'ເຄື່ອງທີ່ຍິງຂ້າມ',
      'Expires': 'ໝົດເຂດຮັບເຄື່ອງ',
      'BillWasSettled': 'ຮຽກເກັບເງິນສຳເລັດແລ້ວ',
      'SuccessfullyCompensated': 'ຊົດເຊີຍເງິນສຳເລັດ',
    },
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },

  methods: {
    FilterByShipmentNumber() {
      helper.GetOldValueOnInput(this);
    },
    printListSignCdc() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/list/sign/cod', {
        search: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ລາຍການເຊັນຮັບCODວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ລາຍການເຊັນຮັບCODວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      if (price < 1 && price >= 0) {
        return price
      }
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    FetchData() {
      this.isLoading = true;
      this.$http.get('list/sign/cod', {
        params: {
          search: this.searchItem,
          start_date: this.start_date,
          end_date: this.end_date,
          page: this.pagination.current_page,
          per_page: this.limit_page,
        }
      }).then((res) => {
        setTimeout(() => {
          this.listSignCod = res.data.data.data;
          this.count = res.data.data.total;
          this.pagination = res.data.data;
          this.totalFreight = res.data.totalFreight
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      })
    },

    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    }
    ,
    ...
        mapActions({
          LogoutUser: "User/destroyToken",
        }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    }
    ,
  },
  created() {
    this.FetchData();
  }
  ,
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
