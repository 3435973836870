<template>
  <div>
    <div class="field" :style="{marginTop: '30px'}">
      <div class="control">
        <label class="label">ວັນທີສຳເລັດ:</label>
        <date-picker
            format="DD/MM/YYYY HH:mm"
            value-type="YYYY-MM-DD HH:mm"
            type="date"
            v-model="success_date"
            :disabled-date="disabledAfterToday"
            :clearable="false"
            name="start_date"
        ></date-picker>
      </div>
    </div>
    <vs-button ref="button" flat
               @click="updateInvoice()"
               class="form-btn-loading"
               :loading="loading"
    >
      ອິນວອຍສຳເລັດ
    </vs-button>
  </div>
</template>

<script>

import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  props: [
    'invoiceId',
  ],

  components: {
    DatePicker
  },

  data: () => ({
    success_date: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    loading: false,
  }),
  methods: {
    updateInvoice() {
      this.loading = true;
      this.$http.put('edit/shop/invoice/' + this.invoiceId, {
        'success_at': this.success_date
      }).then((res) => {
        console.log(res)
        if (!res.data.error) {
          setTimeout(() => {
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
      }).catch(() => {
      });
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>
