import { Validator } from 'vee-validate';

const validate = {
    custom: {
      name:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ຊື່ບໍ່ຄວນສັ້ນກວ່າ 2 ໂຕ)',
        max: '(ຊື່ບໍ່ຄວນຍາວກວ່າ 225 ໂຕ)',
      },
      tel:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ເບີໂທບໍ່ຄວນສັ້ນກວ່າ 6 ໂຕ)',
        max: '(ເບີໂທບໍ່ຄວນຍາວກວ່າ 11 ໂຕ)',
      },
    }
};

export const ValidateIntern = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

