<template>
    <div>
        <template>
            <h4 class="not-margin"
                :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-pencil-alt" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເເກ້ໄຂ ຂໍ້ມູນບ້ານ
            </h4>
        </template>
        
        <div class="field" :style="{marginTop: '20px'}">  
            <div class="control">
                <label class="label">
                    ເລືອກເເຂວງ 
                    <span class="text-danger">* {{ errors.first('province') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedProvince.id" name="province" 
                        v-validate="'required'" @change="FilterDistricts(selectedProvince.id)">
                        <option :value="pro.id" v-for="(pro, index) in provinces" :key="index">
                            {{pro.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">  
            <div class="control">
                <label class="label">
                    ເລືອກເມືອງ
                    <span class="text-danger">* {{ errors.first('district') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedDistrict.id" name="district" 
                        v-validate="'required'" @change="FilterVillages(selectedDistrict.id)">
                        <option :value="dist.id" v-for="(dist, index) in districts" :key="index">
                            {{dist.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">  
            <div class="control">
                <label class="label">
                    ບ້ານ 
                    <span class="text-danger">* {{ errors.first('village') }}</span>
                    <span class="text-danger"> {{ msgError }}</span>
                </label>
                <multiselect 
                    v-model="selectedVillage" 
                    :options="villages" 
                    placeholder="ກະລຸນາປ້ອນຂະເເໜງ..."
                    :multiple="true" :taggable="true" 
                    label="name" track-by="id" 
                    @tag="addTag" name="village"
                    v-validate="'required'"
                    select-label="ບ້ານທີ່ມີໃນລະບົບເເລ້ວ"
                    tag-placeholder="+ ເພີ່ມໃຫມ່"
                    deselect-label="ລົບອອກ"
                    :max="1"
                >
                </multiselect>
            </div>
        </div>
   
        <button class="button" :style="{marginTop: '20px', width: '30%'}" @click="ValidateForm()" :class="{'is-loading': btnLoading}">
            ເເກ້ໄຂ ບ້ານ
        </button>
    </div>
</template>

<script>
import MixinsEditVillages from '@coms/../../src/Mixins/Address/Villages/Edit';
import { ValidateVillages } from '@coms/../../src/Plugins/Validation/Validate-Villages';

export default {

    props:['listVillage'],

    mixins: [MixinsEditVillages],

    created(){
        this.FetchData();
        ValidateVillages(this);
    }
}
</script>
<style lang="scss" scoped>
    .button{
        &:hover{
            color: $white;
        }
    }
</style>