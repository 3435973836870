<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-house"></i>
          ລາຍການຍອດ COD ລູກຄ້າ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຈັດລຳດັບ:</label>
            <div class="select">
              <select class="input" v-model="order_by" @change="FetchData">
                <option value="shipment_qty">ຕາມຈຳນວນບິນ</option>
                <option value="total_price">ຕາມລາຄາ COD</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດລູກຄ້າ:</label>
            <div class="select">
              <select class="input" v-model="customer_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="isNewCod">ເປັນລູກຄ້າຕັ້ງແຕ່ 18/10</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="printLCodShipmentDetail()"
              class="form-btn-loading"
              :loading="loading">
            export excel
          </vs-button>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ຈຳນວນບິນ</vs-th>
            <vs-th>ຈຳນວນເງິນ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="shipment"
              v-for="(shipment, index) in $vs.getPage(codShipments, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ shipment.name }}</vs-td>
            <vs-td>{{ shipment.tel }}</vs-td>
            <vs-td>{{ FormatPrice(shipment.shipment_qty) }}</vs-td>
            <vs-td>{{ FormatPrice(shipment.total_price) }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Loading,
    pagination,
    DatePicker
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: true,
    loading: false,
    customer_type: '',
    order_by: 'shipment_qty',
    codShipments: [],
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },
  methods: {
    printLCodShipmentDetail() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/list/cod/customer', {
        search: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
        customer_type: this.customer_type,
        order_by: this.order_by,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ຍອກບິນລູກຄ້າCODວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ຍອກບິນລູກຄ້າCODວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "") + "";
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.codShipments = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/cod/customer', {
        params: {
          search: this.searchItem,
          start_date: this.start_date,
          end_date: this.end_date,
          customer_type: this.customer_type,
          order_by: this.order_by,
          print: 'no',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
