export default {
    data:()=>({
        value: {},
        server_errors:{
            orderBy: '',
            profile: '',
        },
        images:{
            fileName: '',
            base64: '',
            size: '',
            file: '',
            type: '',
        },
        btnLoading: false,
    }),
    methods: {

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }
            });
        }, // ValidateForm
        
        SaveItem(){
            this.btnLoading = true;
            this.$axios.post('add-banners', {
                link: this.value.link,
                orderBy: this.value.orderBy,
                profile: this.images.fileName,
            }).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch((error)=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
        BtnLoading(){
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        }, // Btn-Loading

        triggerInputClick() {
            let selector = this.$refs.file;
            if (selector) {
                selector.click();
            }
        }, // Trigger-Click
        async onFileChange(e) {
            const file = e.target.files[0];
            const FileSize = (file.size / 1024 / 1024).toFixed(2); 
            const extentsions = file.type.split("/");
            const validate_Ext = this.ValidateExtensions(extentsions[1]);

            if(validate_Ext == true && FileSize < 6){
                this.images.file = file;
                this.images.type = extentsions[1];
                this.images.size = this.FormatByte(file.size);
                this.images.base64 = await this.ToBase64(file);
                this.UploadFile(file);
            }
        }, // OnFile-Change

        ToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }, // Convert-Base64

        FormatByte(bytes){
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }, // FormatByte

        ValidateExtensions(ext){
            if(ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp'){
                return true;
            }else{
                return false;
            }
        },

        UploadFile(file){
                let formData = new FormData();
                formData.append('imageFile', file);

                this.$axios.post('upload-file', formData).then((res)=>{
                    this.images.fileName = res.data.data.fileName;
                }).catch(()=>{});
        }
    },
}