export default {
    data:()=>({
        provinces: [],
        selectedProvince: {},
        districts: [],
        selectedDistrict: {},
        villages: [],
        selectedVillage: [],
        
        filterDistricts: [],
        filterVillages: [],

        msgError: '',
        btnLoading: false,
    }),
    methods: {
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if(result){
                    this.EditItem();
                }
            });
        },

        EditItem(){
            this.btnLoading = true;

            let village = this.selectedVillage.map(item => {
                return item.id || item.name;
            }).join(',');

            this.$axios.put('edit-villages',{
                id: this.listVillage.id,
                village: village,
                districtId: this.selectedDistrict.id
            }).then(()=>{
                setTimeout(()=>{
                    this.btnLoading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                }, 300);
            }).catch(()=>{
                this.btnLoading = false;
                this.$emit('close');
                this.$emit('success');
                this.$notification.OpenNotification_Error_Server('top-right', 'danger', 3000);
            });
        },

        FetchData(){
            this.$axios.get('list-addresses').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
                setTimeout(()=>{
                    this.filterDistricts = res.data.districts;
                    this.filterVillages = res.data.listVillages;

                    this.provinces = res.data.provinces;
                    this.selectedProvince.id = this.listVillage.proId;
                    this.districts = res.data.districts;

                    this.FilterDistricts(this.listVillage.proId);
                    this.selectedDistrict.id = this.listVillage.distId;
                    this.villages = res.data.listVillages;

                    this.FilterVillages(this.listVillage.distId);
                }, 100);
            }).catch(()=>{});
        },

        addTag(newTag){
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.villages.push(tag);
            this.selectedVillage.push(tag);
        },

        FilterDistricts(proId){
            const result = this.filterDistricts.filter(item => {
                return item.province_id == proId;
            });
            this.districts = result;
            this.selectedDistrict = {...result[0]};

            if(this.listVillage.proId == proId){
                const selected = result.filter(item => {
                    return item.id == this.listVillage.distId;
                }, [] || {});
                this.selectedDistrict = {...selected[0]}
            }
            this.FilterVillages(this.selectedDistrict.id);
        },
        FilterVillages(districtId){
            this.selectedVillage = [];
            
            const result = this.filterVillages.filter(item => {
                return item.distId == districtId;
            });
            this.villages = result;

            if(this.listVillage.distId == districtId){
                const selected = result.filter(item => {
                    return item.id == this.listVillage.id;
                });
                this.selectedVillage.push(selected[0]);
            }
        }
    },
}