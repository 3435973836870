<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການເລກບິນທັງໝົດ
        <span class="countTotal">({{ count }})</span>
      </h4>
    </div>

    <div class="box" :style="{marginTop: '20px'}">
      <div class="columns">
        <div class="column is-12">
          <div class="control">
            <label class="label">ຄົ້ນຫາເລກບິນ</label>
            <input
                class="input" name="search"
                v-model="searchItem"
                placeholder="ກະລຸນາພີມເລກບີນ..."
                @keyup.enter="FilterByShipmentBagNumber()">
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລໍາດັບ</vs-th>
            <vs-th>ເລກບິນ</vs-th>
            <vs-th>ຕົ້ນທາງ</vs-th>
            <vs-th>ປາຍທາງ</vs-th>
            <vs-th>ວັນທີ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listShipmentBag"
              :data="item">
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td v-if="item.parcel_in_shipment">
              {{ item.parcel_in_shipment.parcel_bill_number }}
            </vs-td>
            <vs-td v-if="item.parcel_in_shipment.shipment">
              {{ item.parcel_in_shipment.shipment.start_branch.name }}
            </vs-td>
            <vs-td v-if="item.parcel_in_shipment.shipment">
              {{ item.parcel_in_shipment.shipment.end_branch.name  }}
            </vs-td>
            <vs-td>{{ moment(item.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FilterListOrders()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import mixinListShipmentBagRecyclingDetail from '@/Mixins/Distribution-Center/Shipment-Orders/Resuable/List-Shipment-Bag-Recycling-Detail';
import pagination from '@/Plugins/Paginations/Pagination';
import Loading from '@/views/Loading';

export default {
  components: {
    pagination,
    Loading
  },

  mixins: [mixinListShipmentBagRecyclingDetail],

  created() {
    this.FilterListOrders();
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}
</style>
