<template>
    <div class="container" :style="{marginTop: '5px'}">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                  ຂໍ້ມູນ ໂປຮໂມຮຊັ່ນ
                <span class="countTotal">({{listPromotions.length}})</span>
                <span class="btn-add">
                    <vs-button
                        circle
                        icon
                        flat
                        @click="OpenModalAdd()"
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>
                        ລຳດັບ
                    </vs-th>
                    <vs-th>
                        ໂປຮໂມຮຊັ່ນ
                    </vs-th>
                    <vs-th>
                        ສ່ວນຫຼຸດ
                    </vs-th>
                    <vs-th>
                        ປະເພດສ່ວນຫຼຸດ
                    </vs-th>
                    <vs-th>
                        ຮູບເເບບ (ໂປຮໂປຮຊັ່ນ)
                    </vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                    :key="index" v-for="(promotion, index) in listPromotions"
                >
                    <vs-td>
                        {{ index + 1 }}
                    </vs-td>
                    <vs-td :style="{width: '230px'}">
                        {{ promotion.name }}
                    </vs-td>
                    <vs-td>
                        <span v-if="promotion.discount_type == 'cash'">{{ FormatPrice(promotion.discount) }}</span>
                        <span v-else>{{promotion.discount}}%</span>
                    </vs-td>
                    <vs-td>
                        <span>
                            {{ promotion.discount_type == 'percent' ? 'ເປີເຊັນ' : 'ເງີນສົດ' }}
                        </span>
                    </vs-td>
                    <vs-td>
                        <span v-if="promotion.level == 0 && promotion.is_across == 'true'">
                            ຕ່າງບ້ານ
                        </span>
                        <span v-if="promotion.level == 0 && promotion.is_across == 'false'">
                            ພາຍໃນບ້ານ
                        </span>

                        <span v-if="promotion.level == 1 && promotion.is_across == 'true'">
                            ຕ່າງເມືອງ
                        </span>
                         <span v-if="promotion.level == 1 && promotion.is_across == 'false'">
                            ພາຍໃນເມືອງ
                        </span>

                        <span v-if="promotion.level == 2 && promotion.is_across == 'true'">
                            ຕ່າງເເຂວງ
                        </span>
                         <span v-if="promotion.level == 2 && promotion.is_across == 'false'">
                            ພາຍໃນເເຂວງ
                        </span>

                         <span v-if="promotion.level == 3 && promotion.is_across == 'true'">
                            ຂ້າມພາກ
                        </span>
                         <span v-if="promotion.level == 3 && promotion.is_across == 'false'">
                            ບໍ່ຂ້າມພາກ
                        </span>
                    </vs-td>
                    <vs-td style="text-align: right; width: 100px">
                        <div class="group-icon">
                            <vs-button
                                circle icon flat danger
                                @click="OpenModalClosed(promotion.id)"
                            >
                            <i class="fal fa-times-circle"></i>
                            </vs-button>
                            <vs-button
                                circle
                                icon
                                flat
                                @click="OpenModalEdit(promotion.id)"
                            >
                            <i class="fad fa-pencil-alt"></i>
                            </vs-button>
                            <vs-button
                                circle danger icon flat
                                @click="OpenModalDelete(promotion.id)"
                            >
                            <i class="fad fa-trash-alt"></i>
                            </vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
                </template>
                <template #footer>
                    <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset" @paginate="FetchData()"></pagination>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalDelete>
            <template v-slot="{ close }">
                <DeletePromotions @close="close" :promotionId="promotionId" @success="FetchData()"/>
            </template>
        </ModalDelete>

        <ModalClose>
             <template v-slot="{ close }">
                <ClosePromotions @close="close" :promotionId="promotionId" @success="FetchData()"/>
            </template>
        </ModalClose>

    </div>
</template>
<script>
import DeletePromotions from './Dialog/Delete';
import ClosePromotions from './Dialog/Close';
import Loading from '../../Loading';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
    components:{
        DeletePromotions,
        ClosePromotions,
        Loading,
        pagination
    },
    data:()=>({
        active: 1,
        page: 1,
        max: 15,
        listPromotions: [],  
        promotionId: '',
        isLoading: true,

        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10
    }),
    methods:{
        OpenModalAdd(){
            this.$router.push({name: 'add.promotion'});
        },
        OpenModalEdit(promotionId){
            this.$router.push({name: 'edit.promotion', params:{id: promotionId}});
        },
        OpenModalDelete(promotionId){
            this.promotionId = promotionId;
            this.$store.commit('modalDelete_State', true);
        },
        OpenModalClosed(promotionId){
            this.promotionId = promotionId;
            this.$store.commit('modalClose_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-promotions',{
                params:{
                    page: this.pagination.current_page,
                    per_page: this.per_page
                }
            }).then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.listPromotions = res.data.promotion_Onlines.data;
                    this.pagination = res.data.promotion_Onlines;
                }, 100);
            }).catch(()=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
            });
        },
        FormatPrice(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        }
    },
    created(){
        this.FetchData();
    },
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>