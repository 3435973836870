export default{
    data:()=>({
        btnLoading: false,
        levels: [],
        selectedLevel: {},

        startParts: [],
        selectedStartPart: {},
        endParts: [],
        selectedEndPart: {},

        startProvinces: [],
        selectedStartProvince: {},
        endProvinces: [],
        selectedEndProvince: {},

        startDistricts: [],
        selectedStartDistrict: {},
        endtDistricts: [],
        selectedEndDistrict: {},

        startVillages: [],
        selectedStartVillage: {},
        endVillages: [],
        selectedEndVillage: {},

        province_ForDistricts: [],
        selectedProvince_ForDistrict: {},
        province_ForFilterVillages: [],
        selectedProvince_ForVillage: {},
        district_ForVillages: [],
        selectedDistrict_ForVillage: {},


        server_errors:{
            name: '',
            discountType: '',
            level: '',
            is_across: '',
        },

        value: {
            is_across: 'true',
            reverse: true,
            discount_percent: '',
            discount_cash: 0,
            expired_date: new Date(),
        },
        msgBtn_isAcross1: '',
        msgBtn_isAcross2: '',

        FarmatParts:{
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້'
        },

        showBox_Part: false,
        showBox_Province: false,
        showBox_District: false,
        showBox_Village: false,

        showSelected: false,

        promotionTypes:[
            {type: 'percent', name: 'ເປີເຊັນ'},
            {type: 'cash', name: 'ເງີນສົດ'}
        ],
        selectedProType: {type: 'percent', name: 'ເປີເຊັນ'},


        Selected_StartBranch: [],
        Selected_EndBranch: [],
        SelectedAll_StartBranch: false,
        SelectedAll_EndBranch: false,

        startBranches: [],
        endBranches: [],

        inputSearch_StartBranch: '',
        inputSearch_EndBranch: '',

        showMsg_StartBranch: true,
        showMsg_EndBranch: true,
    }),

    methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    if(this.CheckLevel_BeforeSavePromotion() == true){
                        this.SavePromotions();
                    }else{
                        this.$notification.OpenNotification_Msg_StartBranch('top-right', 'danger', 3000);
                    }
                }
            });
        },

        CheckLevel_BeforeSavePromotion(){
            if(this.value.is_across == 'true'){
                if(this.Selected_StartBranch.length > 0 && this.Selected_EndBranch.length > 0){
                    return true;
                }else{
                    return false;
                }
            }else{
                if(this.Selected_StartBranch.length > 0){
                    return true;
                }else{
                    return false;
                }
            }
        },

        SavePromotions(){
            this.value.startBranches = this.Selected_StartBranch;
            this.value.endBranches = this.Selected_EndBranch;
            this.value.proType = this.selectedProType.type;
            this.value.level = this.selectedLevel.level;
            if(this.selectedLevel.level == 3){
                this.value.startId = this.selectedStartPart.id;
                this.value.endId = '';
                if(this.value.is_across == 'true'){
                    this.value.endId = this.selectedEndPart.id;
                }
            }else if(this.selectedLevel.level == 2){
                this.value.startId = this.selectedStartProvince.id;
                this.value.endId = '';
                if(this.value.is_across == 'true'){
                    this.value.endId = this.selectedEndProvince.id;
                }
            }else if(this.selectedLevel.level == 1){
                this.value.startId = this.selectedStartDistrict.id;
                this.value.endId = '';
                if(this.value.is_across == 'true'){
                    this.value.endId = this.selectedEndDistrict.id;
                }
            }else if(this.selectedLevel.level == 0){
                this.value.startId = this.selectedStartVillage.id;
                this.value.endId = '';
                if(this.value.is_across == 'true'){
                    this.value.endId = this.selectedEndVillage.id;
                }
            }
            
            this.btnLoading = true;
            this.$axios.post('add-promotions', this.value).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'tab.list.promotion', query:{tab: 'online'}});
                    }, 300);
                }
            }).catch((error)=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // à»ƒàºŠà»‰àºàº²àº™àº¥àº¹àºšàº‚à»à»‰àº¡àº¹àº™à»€àºžàº²àº°àº§à»ˆàº²àº‚à»à»‰àº¡àº¹àº™ errors àº—àºµà»ˆàºªàº»à»ˆàº‡àº¡àº²àºˆàº²àº Server àº™àº±à»‰àº™à»€àº›àº±àº™ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FetchData(){
            this.$axios.get('list-info-packages').then(res=>{
                const data = res.data;

                this.levels = data.levels;
                this.selectedLevel = {...this.levels[0]};
                this.CheckLevel(this.selectedLevel);

                this.startParts = data.parts;
                this.selectedStartPart = {...this.startParts[0]};
                this.endParts = data.parts;
                this.selectedEndPart = {...this.endParts[0]};

                this.startProvinces = data.provinces;
                this.selectedStartProvince = {...this.startProvinces[0]};
                this.endProvinces = data.provinces;
                this.selectedEndProvince = {...this.endProvinces[0]};

                this.startDistricts = data.districts;
                this.selectedStartDistrict = {...this.startDistricts[0]};
                this.endDistricts = data.districts;
                this.selectedEndDistrict = {...this.endDistricts[0]};

                this.startVillages = data.villages;
                this.selectedStartVillage = {...this.startVillages[0]};
                this.endVillages = data.villages;
                this.selectedEndVillage = {...this.endVillages[0]};

                this.province_ForDistricts = data.provinces;
                this.selectedProvince_ForDistrict = {...this.province_ForDistricts[0]};
                this.province_ForFilterVillages = data.provinces;
                this.selectedProvince_ForVillage = {...this.province_ForFilterVillages[0]};
                this.district_ForVillages = data.districts;
                this.selectedDistrict_ForVillage = {...this.district_ForVillages[0]};

                if(this.value.is_across == 'true'){
                    this.showSelected = true;
                }else{
                    this.showSelected = false;
                }

                this.SelectedAllStartBranch();
                this.SelectedAllEndBranch();
            }).catch(()=>{});
        },

        CheckLevel(value){
            if(value == '3'){
                this.msgBtn_isAcross1 = 'ຂ້າມພາກ';
                this.msgBtn_isAcross2 = 'ບໍ່ຂ້າມພາກ';
            }else if(value == '2'){
                this.msgBtn_isAcross1 = 'ຕ່າງເເຂວງ';
                this.msgBtn_isAcross2 = 'ພາຍໃນເເຂວງ';
            }else if(value == '1'){
                this.msgBtn_isAcross1 = 'ຕ່າງເມືອງ';
                this.msgBtn_isAcross2 = 'ພາຍໃນເມືອງ';
            }else if(value == '0'){
                this.msgBtn_isAcross1 = 'ຕ່າງບ້ານ';
                this.msgBtn_isAcross2 = 'ພາຍໃນບ້ານ';
            }
        },

        Check_StartBranches(id){
            if(id !== undefined){
                this.$axios.get('check-branches/' + this.selectedLevel.level + '/' + id).then((res)=>{
                    if(res.data.success == true){
                        this.startBranches = res.data.branches;
                        this.SelectedAll_StartBranch = false;
                        this.SelectedAllStartBranch();
                    }else{
                        this.startBranches = [];
                        this.Selected_StartBranch = [];
                        this.SelectedAll_StartBranch = false;
                    }
                }).catch(()=>{});
            }else{
                this.startBranches = [];
                this.Selected_StartBranch = [];
                this.SelectedAll_StartBranch = false;
            }
        },
        Check_EndBranches(id){
            if(id !== undefined){
                this.$axios.get('check-branches/' + this.selectedLevel.level + '/' + id).then((res)=>{
                    if(res.data.success == true){
                        this.endBranches = res.data.branches;
                        this.SelectedAll_EndBranch = false;
                        this.SelectedAllEndBranch();
                    }else{
                        this.endBranches = [];
                        this.Selected_EndBranch = [];
                        this.SelectedAll_EndBranch = false;
                    }
                }).catch(()=>{});
            }else{
                this.endBranches = [];
                this.Selected_EndBranch = [];
                this.SelectedAll_EndBranch = false;
            }
        },

        SelectedAllStartBranch() {
            this.Selected_StartBranch = [];
            if(!this.SelectedAll_StartBranch){
                for (let i in this.startBranches) {
                    this.Selected_StartBranch.push(this.startBranches[i].id);
                }
            }
        },
        SelectedAllEndBranch() {
            this.Selected_EndBranch = [];
            if(!this.SelectedAll_EndBranch){
                for (let i in this.endBranches) {
                    this.Selected_EndBranch.push(this.endBranches[i].id);
                }
            }
        },
        escapeRegExp(string) {
            string = string.toString()
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
    },

    computed: {
		FilterStartBranch(){
			var c = this.escapeRegExp(this.inputSearch_StartBranch)
            const pattern = new RegExp(c, 'i')
            return this.startBranches.filter(c => {
                return pattern.test(c.branch_name);
            });
        },
        FilterEndBranch(){
			var c = this.escapeRegExp(this.inputSearch_EndBranch)
            const pattern = new RegExp(c, 'i')
            return this.endBranches.filter(c => {
                return pattern.test(c.branch_name)
            });
        },
        Filter_ForDistricts() {
            const resultStart = this.startDistricts.filter(item => {
                return item.province_id == this.selectedProvince_ForDistrict.id;
            });
            const resultEnd = this.startDistricts.filter(item => {
                return item.province_id == this.selectedProvince_ForDistrict.id;
            });
            this.selectedStartDistrict = {...resultStart[0]};
            this.selectedEndDistrict = {...resultEnd[0]};
            return resultStart, resultEnd;
        },
        FilterDistrict_ForVillages() {
            const resultDistricts = this.district_ForVillages.filter(item => {
                return item.province_id == this.selectedProvince_ForVillage.id;
            });
            this.selectedDistrict_ForVillage = {...resultDistricts[0]};
            return resultDistricts;
        },
        Filter_ForVillages() {
            const resultStartVillages = this.startVillages.filter(item => {
                return item.district_id == this.selectedDistrict_ForVillage.id;
            });
            const resultEndVillages = this.endVillages.filter(item => {
                return item.district_id == this.selectedDistrict_ForVillage.id;
            });

            this.selectedEndVillage = {...resultEndVillages[0]};
            this.selectedStartVillage = {...resultStartVillages[0]};
            return resultStartVillages, resultEndVillages;
        },
	},

    watch:{
        'Selected_StartBranch':function(value){
            if(value.length == this.startBranches.length){
                this.SelectedAll_StartBranch = true;
                if(this.startBranches.length){
                    this.SelectedAll_StartBranch = true;
                }else{
                    this.SelectedAll_StartBranch = false;
                }
            }else{
                this.SelectedAll_StartBranch = false;
            }
        },
        'Selected_EndBranch':function(value){
            if(value.length == this.endBranches.length){
                this.SelectedAll_EndBranch = true;
                if(this.endBranches.length){
                    this.SelectedAll_EndBranch = true;
                }else{
                    this.SelectedAll_EndBranch = false;
                }
            }else{
                this.SelectedAll_EndBranch = false;
            }
        },
        'selectedLevel.level':function(value){
            this.CheckLevel(value);
            if(value == 3){
                this.showBox_Province = false;
                this.showBox_District = false;
                this.showBox_Village = false;
                this.showBox_Part = true;
                this.Check_StartBranches(this.selectedStartPart.id);
                this.Check_EndBranches(this.selectedEndPart.id);
            }else if(value == 2){
                this.showBox_Part = false;
                this.showBox_District = false;
                this.showBox_Village = false;
                this.showBox_Province = true;
                this.Check_StartBranches(this.selectedEndPart.id);
                this.Check_EndBranches(this.selectedEndPart.id);
            }else if(value == 1){
                this.showBox_Part = false;
                this.showBox_Province = false;
                this.showBox_Village = false;
                this.showBox_District = true;
                this.Check_StartBranches(this.selectedStartDistrict.id);
                this.Check_EndBranches(this.selectedEndDistrict.id);
            }else if(value == 0){
                this.showBox_Part = false;
                this.showBox_Province = false;
                this.showBox_District = false;
                this.showBox_Village = true;
                this.Check_StartBranches(this.selectedStartVillage.id);
                this.Check_EndBranches(this.selectedEndVillage.id);
            }
        },
        'value.is_across':function(value){
            this.showSelected = value == 'true';
            if(value == 'false'){
                this.value.reverse = false;
            }else{
                this.value.reverse = true;
            }
        },
        'selectedStartPart.id':function(value){
            this.Check_StartBranches(value);
        },
        'selectedEndPart.id':function(value){
            this.Check_EndBranches(value);
        },
        'selectedStartProvince.id':function(value){
            this.Check_StartBranches(value);
        },
        'selectedEndProvince.id':function(value){
            this.Check_EndBranches(value);
        },
        'selectedStartDistrict.id':function(value){
            this.Check_StartBranches(value);
        },
        'selectedEndDistrict.id':function(value){
            this.Check_EndBranches(value);
        },
        'selectedStartVillage.id':function(value){
            this.Check_StartBranches(value);
        },
        'selectedEndVillage.id':function(value){
            this.Check_EndBranches(value);
        },

        'inputSearch_StartBranch':function(){
            if(!this.FilterStartBranch.length){
                this.showMsg_StartBranch = false;
            }else{
                this.showMsg_StartBranch = true;
            }
        },
        'inputSearch_EndBranch':function(){
            if(!this.FilterEndBranch.length){
                this.showMsg_EndBranch = false;
            }else{
                this.showMsg_EndBranch = true;
            }
        }
    }

}