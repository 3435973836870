<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-credit-card-blank"></i>
          ຄູປອງ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ເບີໂທ
              <span class="text-danger">{{ errors.first('sender_phone_number') }}</span>
            </label>
            <input class="input" name="sender_phone_number" type="text"
                   placeholder="ປ້ອນເບີໂທ" v-model="item.sender_phone_number"
                   v-validate="'required|numeric|min:6|max:8'">
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ລະຫັດຄູປອງ
              <span class="text-danger">{{ errors.first('coupon') }}</span>
            </label>
            <input class="input" name="coupon" type="text"
                   placeholder="ປ້ອນຄູປອງ" v-model="item.coupon"
                   v-validate="'required'">
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">
              ຜົນໄດ້ຮັບ
            </label>
            <input style="color: black" class="input" name="coupon" type="text"
                   placeholder="..." disabled v-model="server_errors.coupon_handle_error">
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="validateForm()"
              class="form-btn-loading"
              :loading="loading">
            ຢືນຢັນການໃຊ້ລະຫັດ
          </vs-button>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <!--        <div class="column is-2" style="margin-top: 3px">-->
        <!--          <vs-button-->
        <!--              ref="button" flat-->
        <!--              @click="printUsedCoupon()"-->
        <!--              class="form-btn-loading"-->
        <!--              :disable="isActive"-->
        <!--              :loading="loadingExcel">-->
        <!--            export excel-->
        <!--          </vs-button>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ວັນທີໃຊ້</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ສ່ວນຫຼຸດ</vs-th>
            <vs-th>ລະຫັດຄູປອງ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="usedCoupon"
              v-for="(usedCoupon, index) in $vs.getPage(usedCoupons, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ moment(usedCoupon.used_at).format('DD-MM-YYYY HH:mm') }}</vs-td>
            <vs-td>{{ usedCoupon.customer_name }}</vs-td>
            <vs-td>{{ usedCoupon.customer_tel }}</vs-td>
            <vs-td>{{ usedCoupon.discount }}</vs-td>
            <vs-td>{{ usedCoupon.code }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {Validator} from "vee-validate";

const dict = {
  custom: {
    sender_phone_number: {
      required: "ກະລຸນາປ້ອນ",
      min: 'ຂັ້ນຕ່ຳ 6 ຕົວອັກສອນ',
      max: 'ສູງສຸດ 8 ຕົວອັກສອນ',
      numeric: 'ປ້ອນຕົວເລກເທົ່ານັ້ນ'
    },
    coupon: {
      required: "ກະລຸນາປ້ອນ",
    },
  },
};
Validator.localize("en", dict);
export default {
  components: {
    Loading,
    pagination,
    DatePicker,
  },

  data: () => ({
    page: 1,
    max: 12,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    usedCoupons: [],
    errorMessages: {
      ERROR_INVALID_COUPON: 'ລະຫັດຄູປອງບໍ່ຖືກຕ້ອງ',
      ERROR_INVALID_CUSTOMER: 'ເບີໂທລູກຄ້າບໍ່ຖືກຕ້ອງ',
      ERROR_COUPON_USE_FOR_NEW_CUSTOMER: 'ຄູປອງໃຊ້ສຳຫຼັບລູກຄ້າໃໝ່ເທົ່ານັ້ນ',
      ERROR_COUPON_AMOUNT_EXCEEDED: 'ຈຳນວນຄູປອງເກີນກຳນົດທີ່ໃຊ້',
      ERROR_COUPON_EXCEED_USAGE_PER_DAY: 'ຈຳນວນຄູປອງເກີນກຳນົດທີ່ໃຊ້ຕໍ່ມື້ສຳຫຼັບລູກຄ້າເບີນີ້',
      ERROR_COUPON_EXCEED_USAGE: 'ລູກຄ້າໃຊ້ຄູປອງເຖິງຈຳນວນທີ່ກຳນົດແລ້ວ',
      ERROR_COUPON_NOT_MATCH_CUSTOMER_ID: 'ລະຫັດຄູປອງບໍ່ແມ່ນສຳຫຼັບລູກຄ້າເບີນີ້'
    },
    isLoading: false,
    item: {
      sender_phone_number: '',
      coupon: ''
    },
    isActive: true,
    loading: false,
    loadingExcel: false,
    server_errors: {
      coupon_handle_error: '',
    },
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 12,
    limit_page: 12,
    pagination: {},
  }),
  methods: {
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.confirmExternalCoupon();
        }
      });
    },
    confirmExternalCoupon() {
      this.loading = true;
      this.$http.post('v1/auth/branches/shipments/orders/customers/coupons/external/apply', {
        'sender_phone_number': this.item.sender_phone_number,
        'coupon': this.item.coupon
      }).then(() => {
        this.loading = false;
        this.pagination.current_page = 1;
        this.server_errors.coupon_handle_error = 'ສຳເລັດ';
        this.item.sender_phone_number = '';
        this.item.coupon = '';
        this.$validator.reset();
        this.FetchData();
      }).catch((error) => {
        this.loading = false;
        if (error && error.response && error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key] of Object.entries(obj)) {
            this.server_errors.coupon_handle_error = this.errorMessages[key];
          }
        }
      });
    },

    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    FetchData() {
      this.isLoading = true;
      this.$http
          .get("v1/auth/branches/shipments/orders/customers/coupons/external/list", {
            params: {
              q: this.searchItem,
              start_date: this.start_date,
              end_date: this.end_date,
              page: this.pagination.current_page,
              per_page: this.limit_page,
            }
          })
          .then((res) => {
            setTimeout(() => {
              this.usedCoupons = res.data.data;
              this.count = res.data.total;
              this.pagination = res.data;
              this.isLoading = false;
            }, 100);
          })
          .catch((error) => {
            if (error && error.response && error.response.status === 401) {
              this.LogoutUser();
            }
          });
    },
    printUsedCoupon() {

    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
  watch: {
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'isActive': function () {
      this.isActive = this.usedCoupons.length > 0;
    },
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
