<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-gift"></i>
          ລາຍການຂອງລາງວັນ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
      <div class="column is-6" v-if="$can(['SuperAdmin','marketing','marketinghelper'])">
        <vs-button circle icon flat @click="OpenModalAdd()"
                   style="padding-left: 8px; padding-right: 8px; float: right">
          <i class="fal fa-plus-circle"></i>
        </vs-button>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div v-if="$can(['SuperAdmin','marketing'])" class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຮ້ານ:</label>
            <div class="select">
              <select class="input" v-model="shop_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="hal">ຮ້ານຄ້າຮຸ່ງອາລຸນ</option>
                <option value="other">ຮ້ານຄ້າຮ່ວມລາຍການ</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="$can(['marketinghelper'])" class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຮ້ານ:</label>
            <div class="select">
              <select class="input" v-model="shop_type" @change="FetchData">
                <option value="other">ຮ້ານຄ້າຮ່ວມລາຍການ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຮ້ານ:</label>
            <model-list-select
                :list="listShops"
                v-model="selectedShop"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ໝວດໝູ່:</label>
            <model-list-select
                :list="listShopItemCategory"
                v-model="selectedShopItemCategory"
                placeholder="ເລືອກໝວດໝູ່"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
<!--        <div class="column is-2">-->
<!--          <div class="control">-->
<!--            <label class="label">FLASH SALE:</label>-->
<!--            <div class="select">-->
<!--              <select class="input" v-model="is_flash_sale" @change="FetchData">-->
<!--                <option value="">ທັງໝົດ</option>-->
<!--                <option value="on">ລາຍການ FLASH SALE</option>-->
<!--                <option value="off">ລາຍການທົ່ວໄປ</option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="item_status" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="false">ປິດ</option>
                <option value="1">ເປີດ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th v-if="$can(['SuperAdmin','marketing','marketinghelper'])">ຈັດການ</vs-th>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເປີດ/ປິດ</vs-th>
            <vs-th>ຮູບ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ແຕ້ມທີ່ໃຊ້ແລກ</vs-th>
            <vs-th>ແຕ້ມຕອນ FLASH SALE</vs-th>
            <vs-th>ລາຄາຕົວຈິງ</vs-th>
            <vs-th>ສະຖານະ FLASH SALE</vs-th>
            <vs-th>ໝົດເຂດ FLASH SALE</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ຈຳນວນແລກລໍຖ້າດຳເນີນການ</vs-th>
            <vs-th>ຈຳນວນແລກສຳເລັດ</vs-th>
            <vs-th>ຈຳນວນແລກຕໍ່ໜຶ່ງຄົນ</vs-th>
            <vs-th>ຈຳນວນແລກຕໍ່ມື້</vs-th>
            <vs-th>ໝົດເຂດ</vs-th>
            <vs-th>ໄລຍະເວລາໃນການມາເອົາຂອງລາງວັນ</vs-th>
            <vs-th>ຮ້ານຄ້າ</vs-th>
            <vs-th>ປະເພດລາຍການ</vs-th>
            <vs-th>ໝວດໝູ່</vs-th>
            <vs-th>ຄຳອະທິບາຍ</vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="shopItem"
              v-for="(shopItem, index) in $vs.getPage(shopItems, page, max)">
            <vs-td style="text-align: right; width: 100px" v-if="$can(['SuperAdmin','marketing','marketinghelper'])">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="OpenModalEdit(shopItem)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td v-if="shopItem.status === 0" style="color:red">{{ LaStatus[shopItem.status] }}</vs-td>
            <vs-td v-else style="color: green;">{{ LaStatus[shopItem.status] }}</vs-td>
            <vs-td>
              <div v-if="shopItem.images[0]" class="img-size">
                <expandable-image
                    class="image"
                    :src=shopItem.images[0].image
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ shopItem.name }}</vs-td>
            <vs-td>{{ FormatPrice(shopItem.price) }}</vs-td>
            <vs-td v-if="shopItem.flash_sale_price">{{ FormatPrice(shopItem.flash_sale_price) }}</vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td>{{ FormatPrice(shopItem.item_cost) }}</vs-td>
            <vs-td>{{ shopItem.flash_sale_status }}</vs-td>
            <vs-td v-if="shopItem.end_flash_sale">{{
                moment(shopItem.end_flash_sale).format('DD/MM/YYYY H:mm')
              }}
            </vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td v-if="shopItem.qty">{{ FormatPrice(shopItem.qty) }}</vs-td>
            <vs-td v-else>ບໍ່ຈຳກັດ</vs-td>
            <vs-td>{{ FormatPrice(shopItem.pending_exchange) }}</vs-td>
            <vs-td>{{ FormatPrice(shopItem.success_exchange) }}</vs-td>
            <vs-td v-if="shopItem.amount_per_user">{{ FormatPrice(shopItem.amount_per_user) }}</vs-td>
            <vs-td v-else>ບໍ່ຈຳກັດ</vs-td>
            <vs-td v-if="shopItem.amount_per_day">{{ FormatPrice(shopItem.amount_per_day) }}</vs-td>
            <vs-td v-else>ບໍ່ຈຳກັດ</vs-td>
            <vs-td v-if="shopItem.end_available">{{ moment(shopItem.end_available).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td v-else>ບໍ່ມີໝົດເຂດ</vs-td>
            <vs-td v-if="shopItem.expired_duration">{{ FormatPrice(shopItem.expired_duration) }} ມື້</vs-td>
            <vs-td v-else>ບໍ່ຈຳກັດ</vs-td>
            <vs-td>{{ shopItem.shop_name }}</vs-td>
            <vs-td>{{ laType[shopItem.item_type] }}</vs-td>
            <vs-td>{{ shopItem.item_category_name }}</vs-td>
            <vs-td>{{ shopItem.description }}</vs-td>
            <vs-td>{{ moment(shopItem.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <ModalAdd>
      <template v-slot="{ close }">
        <AddShop @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <EditShop @close="close"
                  :item="item"
                  @success="FetchData()"/>
      </template>
    </ModalEdit>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import AddShop from "@/views/ExchangeItem/ShopItem/Dialog/Add"
import EditShop from "@/views/ExchangeItem/ShopItem/Dialog/Edit"
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    Loading,
    pagination,
    AddShop,
    EditShop,
    ModelListSelect
  },

  data: () => ({
    laType: {
      'item': 'ຂອງລາງວັນ',
      'discount_amount': 'ສ່ວນຫຼຸດເປັນຈຳນວນ',
      'discount_percent': 'ສ່ວນຫຼຸດເປັນ %'
    },
    LaStatus: {
      0: 'ປິດ',
      1: 'ເປີດ'
    },
    is_flash_sale: '',
    isParent: '',
    page: 1,
    max: 10,
    isLoading: true,
    shopItem_type: '',
    selectedShop: '',
    listShops: [],
    selectedShopItemCategory: '',
    listShopItemCategory: [],
    shopItems: [],
    shop_type: '',
    item_status: '',
    item: {},
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'shop_type': function () {
      this.FetchShop();
    },

    'selectedShopItemCategory': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },

    'selectedShop': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },
  methods: {
    FetchShop() {
      this.$http.get('list/shop/' + '?type=' + this.shop_type + '&isParent=' + this.isParent).then((res) => {
        this.listShops = res.data;
        this.listShops.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FetchShopItemCategory() {
      this.$http.get('list/shop/item/category').then((res) => {
        this.listShopItemCategory = res.data;
        this.listShopItemCategory.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.shopItems = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/shop/item', {
        params: {
          search: this.searchItem,
          shop_type: this.shop_type,
          shop_id: this.selectedShop,
          item_status: this.item_status,
          is_flash_sale: this.is_flash_sale,
          item_category_id: this.selectedShopItemCategory,
          isApp: 'no',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    OpenModalEdit(shop) {
      this.item = JSON.parse(JSON.stringify(shop));
      this.$store.commit("modalEdit_State", true);
    },
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  mounted() {
    if (this.$can('marketinghelper')) {
      this.shop_type = 'other'
      this.isParent = 'no'
    } else {
      this.shop_type = '';
      this.isParent = ''
    }
  },
  created() {
    if (this.$can('marketinghelper')) {
      this.shop_type = 'other'
      this.isParent = 'no'
    } else {
      this.shop_type = '';
      this.isParent = ''
    }
    this.FetchShop();
    this.FetchShopItemCategory();
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
