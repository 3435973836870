<template>
    <div class="container">
      <div class="tabs" :style="{marginTop: '-48px', marginLeft: '-30px'}">
        <ul>
<!--          <li :class="{'is-active': isActive === 'origin_freight_fees'}" @click="Tabs('origin_freight_fees')">-->
<!--            <a>ເກັບເງິນຕົ້ນທາງ</a>-->
<!--          </li>-->
<!--          <li @click="Tabs('destination_freight_fees')" :class="{'is-active': isActive === 'destination_freight_fees'}">-->
<!--            <a>ເກັບເງິນ ( CDC )</a>-->
<!--          </li>-->
<!--          <li @click="Tabs('origin_freight_fees_cod')" :class="{'is-active': isActive === 'origin_freight_fees_cod'}">-->
<!--            <a>ເກັບເງິນ ( COD )</a>-->
<!--          </li>-->
          <li @click="Tabs('all')" :class="{'is-active': isActive === 'all'}">
            <a>ທັງໝົດ</a>
          </li>
        </ul>
      </div>

        <ListOrders :onclickEvent="onClick"/>
    </div>
</template>

<script>
import ListOrders from './List-Orders';

export default {
    components:{
        ListOrders,
    },

    data:()=>({
        isActive: false,
        onClick: '',
    }),
    methods:{
        Tabs(value){
            this.isActive = value;
            this.$router.push({name: 'distribution.center.tab.all.shipments', query:{tab: 'all'}}).catch(()=>{});
            this.onClick = value;
        }
    },
    created(){
        // if(this.$route.query.tab === 'origin_freight_fees'){
        //     this.isActive = 'origin_freight_fees';
        // }else if(this.$route.query.tab === 'destination_freight_fees'){
        //     this.isActive = 'destination_freight_fees';
        // }else if(this.$route.query.tab === 'origin_freight_fees_cod'){
        //     this.isActive = 'origin_freight_fees_cod';
        // }else if(this.$route.query.tab === 'destination_freight_fees_cod'){
        //     this.isActive = 'destination_freight_fees_cod';
        // }else{
            this.$router.push({name: 'distribution.center.tab.all.shipments', query:{tab: 'all'}}).catch(()=>{});
            this.isActive = 'all';
            this.onClick = 'all';
        // }
    }
}
</script>

<style>

</style>
