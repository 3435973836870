export default{
    data:()=>({
        btnLoading: false,
        levels: [],
        selectedLevel: {},

        startParts: [],
        selectedStartPart: {},
        endParts: [],
        selectedEndPart: {},

        startProvinces: [],
        selectedStartProvince: {},
        endProvinces: [],
        selectedEndProvince: {},

        startDistricts: [],
        selectedStartDistrict: {},
        endtDistricts: [],
        selectedEndDistrict: {},

        startVillages: [],
        selectedStartVillage: {},
        endVillages: [],
        selectedEndVillage: {},

        province_ForDistricts: [],
        selectedProvince_ForDistrict: {},
        province_ForFilterVillages: [],
        selectedProvince_ForVillage: {},
        district_ForVillages: [],
        selectedDistrict_ForVillage: {},


        server_errors:{
            name: '',
            discountType: '',
            level: '',
            is_across: '',
        },

        value: {
            is_across: 'true',
            reverse: true,
            discount_percent: '',
            discount_cash: 0,
            expired_date: new Date(),
        },
        msgBtn_isAcross1: '',
        msgBtn_isAcross2: '',

        FarmatParts:{
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້'
        },

        showBox_Part: false,
        showBox_Province: false,
        showBox_District: false,
        showBox_Village: false,

        showSelected: false,

        promotionTypes:[
            {type: 'percent', name: 'ເປີເຊັນ'},
            {type: 'cash', name: 'ເງີນສົດ'}
        ],
        selectedProType: {type: 'percent', name: 'ເປີເຊັນ'},


        Selected_StartBranch: [],
        Selected_EndBranch: [],
        SelectedAll_StartBranch: false,
        SelectedAll_EndBranch: false,

        startBranches: [],
        endBranches: [],

        inputSearch_StartBranch: '',
        inputSearch_EndBranch: '',

        showMsg_StartBranch: true,
        showMsg_EndBranch: true,

        promotion: {},
        oldLevel: '',
        oldDiscountType: '',
        oldDiscount: 0,
        selectedBox: {},
        startBranchId: [],
        endBranchId: [],
        //
        oldStartPart: '',
        oldEndPart: '',
        oldStarProvince: '',
        oldEndProvince: '',
        oldStartDistrict: '',
        oldEndDistrict: '',
        oldStartVillage: '',
        oldEndVillage: '',

        // Disabled
        disabledSelectedLevel: false,
    }),

    methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    if(this.CheckLevel_BeforeSavePromotion() == true){
                        this.EditPromotions();
                    }else{
                        this.$notification.OpenNotification_Msg_StartBranch('top-right', 'danger', 3000);
                    }
                }
            });
        },

        CheckLevel_BeforeSavePromotion(){
            if(this.value.is_across == 'true'){
                if(this.Selected_StartBranch.length > 0 && this.Selected_EndBranch.length > 0){
                    return true;
                }else{
                    return false;
                }
            }else{
                if(this.Selected_StartBranch.length > 0){
                    return true;
                }else{
                    return false;
                }
            }
        },

        EditPromotions(){
            this.promotion.startBranches = this.Selected_StartBranch;
            this.promotion.endBranches = this.Selected_EndBranch;
           
            if(this.promotion.level == 3){
                this.promotion.startId = this.selectedStartPart.id;
                this.promotion.endId = '';
                if(this.promotion.is_across == 'true'){
                    this.promotion.endId = this.selectedEndPart.id;
                }
            }else if(this.promotion.level == 2){
                this.promotion.startId = this.selectedStartProvince.id;
                this.promotion.endId = '';
                if(this.promotion.is_across == 'true'){
                    this.promotion.endId = this.selectedEndProvince.id;
                }
            }else if(this.promotion.level == 1){
                this.promotion.startId = this.selectedStartDistrict.id;
                this.promotion.endId = '';
                if(this.promotion.is_across == 'true'){
                    this.promotion.endId = this.selectedEndDistrict.id;
                }
            }else if(this.promotion.level == 0){
                this.promotion.startId = this.selectedStartVillage.id;
                this.promotion.endId = '';
                if(this.promotion.is_across == 'true'){
                    this.promotion.endId = this.selectedEndVillage.id;
                }
            }
            
            this.btnLoading = true;
            this.$axios.put('edit-promotions', this.promotion).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'tab.list.promotion', query:{tab: 'online'}});
                    }, 300);
                }
            }).catch((error)=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FetchData(){
            this.$axios.get('list-info-packages').then(res=>{
                const data = res.data;

                this.levels = data.levels;
                this.selectedLevel = {...this.levels[0]};

                this.startParts = data.parts;
                this.selectedStartPart = {...this.startParts[0]};
                this.endParts = data.parts;
                this.selectedEndPart = {...this.endParts[0]};

                this.startProvinces = data.provinces;
                this.selectedStartProvince = {...this.startProvinces[0]};
                this.endProvinces = data.provinces;
                this.selectedEndProvince = {...this.endProvinces[0]};

                this.startDistricts = data.districts;
                this.selectedStartDistrict = {...this.startDistricts[0]};
                this.endDistricts = data.districts;
                this.selectedEndDistrict = {...this.endDistricts[0]};

                this.startVillages = data.villages;
                this.endVillages = data.villages;

                this.province_ForDistricts = data.provinces;
                this.province_ForFilterVillages = data.provinces;
                this.selectedProvince_ForVillage = {...this.province_ForFilterVillages[0]};
                this.district_ForVillages = data.districts;
                this.selectedDistrict_ForVillage = {...this.district_ForVillages[0]};
            }).catch(()=>{});
        },

        FetchPromotion(){
            if(this.$route.params.id){
                this.$axios.get('get-promotions/' + this.$route.params.id).then((res)=>{
                    this.promotion = res.data.promotion;
                    this.startBranchId = res.data.startBranchId;
                    this.endBranchId = res.data.endBranchId;
                    this.oldLevel = this.promotion.level;
                    this.oldDiscountType = this.promotion.discount_type;
                    this.oldDiscount = this.promotion.discount;
                    this.selectedBox = res.data.selectedBox;
                    if(this.promotion.level == 1){
                        this.selectedProvince_ForDistrict.id = this.selectedBox.id;
                    }
                    if(this.promotion.level == 0){
                        this.selectedProvince_ForVillage.id = this.selectedBox.id;
                    }
                    
                    if(this.promotion.is_across == 'true'){
                        this.showSelected = true;
                    }else{
                        this.showSelected = false;
                    }
                    this.CheckLevel(this.promotion.level);
                    this.ShowBoxLevel(this.promotion.level);
                    this.CheckSelectedItem();
                }).catch(()=>{

                });
            }
        },

        CheckLevel(value){
            if(value == 3){
                this.msgBtn_isAcross1 = 'ຂ້າມພາກ';
                this.msgBtn_isAcross2 = 'ບໍ່ຂ້າມພາກ';
            }else if(value == 2){
                this.msgBtn_isAcross1 = 'ຕ່າງເເຂວງ';
                this.msgBtn_isAcross2 = 'ພາຍໃນເເຂວງ';
            }else if(value == 1){
                this.msgBtn_isAcross1 = 'ຕ່າງເມືອງ';
                this.msgBtn_isAcross2 = 'ພາຍໃນເມືອງ';
            }else if(value == 0){
                this.msgBtn_isAcross1 = 'ຕ່າງບ້ານ';
                this.msgBtn_isAcross2 = 'ພາຍໃນບ້ານ';
            }
        },
        ShowBoxLevel(value){
            if(value == 3){
                this.showBox_Province = false;
                this.showBox_District = false;
                this.showBox_Village = false;
                this.showBox_Part = true;
            }else if(value == 2){
                this.showBox_Part = false;
                this.showBox_District = false;
                this.showBox_Village = false;
                this.showBox_Province = true;
                // Disabled Selected Leve For Level Province
                this.disabledSelectedLevel = true;
            }else if(value == 1){
                this.showBox_Part = false;
                this.showBox_Province = false;
                this.showBox_Village = false;
                this.showBox_District = true;
                // Disable Selected Level For Level District
                this.disabledSelectedLevel = true;
            }else if(value == 0){
                this.showBox_Part = false;
                this.showBox_Province = false;
                this.showBox_District = false;
                this.showBox_Village = true;
                this.disabledSelectedLevel = true;
            }
        },

        Check_StartBranches(id){
            if(id !== undefined){
                this.$axios.get('check-branches/' + this.promotion.level + '/' + id).then((res)=>{
                    if(res.data.success == true){
                        this.startBranches = res.data.branches;
                        this.SelectedAll_StartBranch = false;
                        if(this.promotion.level == 3){
                            if(this.selectedStartPart.id !== this.oldStartPart){
                                this.SelectedAllStartBranch();
                            }else{
                                this.Selected_StartBranch = this.startBranchId;
                            }
                        }else if(this.promotion.level == 2){
                            if(this.selectedStartProvince.id !== this.oldStarProvince){
                                this.SelectedAllStartBranch();
                            }else{
                                this.Selected_StartBranch = this.startBranchId;
                            }
                        }else if(this.promotion.level == 1){
                            if(this.selectedStartDistrict.id !== this.oldStartDistrict){
                                this.SelectedAllStartBranch();
                            }else{
                                this.Selected_StartBranch = this.startBranchId;
                            }
                        }else if(this.promotion.level == 0){
                            if(this.selectedStartVillage.id !== this.oldStartVillage){
                                this.SelectedAllStartBranch();
                            }else{
                                this.Selected_StartBranch = this.startBranchId;
                            }
                        }
                    }else{
                        this.startBranches = [];
                        this.Selected_StartBranch = [];
                        this.SelectedAll_StartBranch = false;
                    }
                }).catch(()=>{});
            }else{
                this.startBranches = [];
                this.Selected_StartBranch = [];
                this.SelectedAll_StartBranch = false;
            }
        },
        Check_EndBranches(id){
            if(id !== undefined){
                this.$axios.get('check-branches/' + this.promotion.level + '/' + id).then((res)=>{
                    if(res.data.success == true){
                        this.endBranches = res.data.branches;
                        this.SelectedAll_EndBranch = false;
                        if(this.promotion.level == 3){
                            if(this.selectedEndPart.id !== this.oldEndPart){
                                this.SelectedAllEndBranch();
                            }else{
                                this.Selected_EndBranch = this.endBranchId;
                            }
                        }else if(this.promotion.level == 2){
                            if(this.selectedEndProvince.id !== this.oldEndProvince){
                                this.SelectedAllEndBranch();
                            }else{
                                this.Selected_EndBranch = this.endBranchId;
                            }
                        }else if(this.promotion.level == 1){
                            if(this.selectedEndDistrict.id !== this.oldEndDistrict){
                                this.SelectedAllEndBranch();
                            }else{
                                this.Selected_EndBranch = this.endBranchId;
                            }
                        }else if(this.promotion.level == 0){
                            if(this.selectedEndVillage.id !== this.oldEndVillage){
                                this.SelectedAllEndBranch();
                            }else{
                                this.Selected_EndBranch = this.endBranchId;
                            }
                        }
                    }else{
                        this.endBranches = [];
                        this.Selected_EndBranch = [];
                        this.SelectedAll_EndBranch = false;
                    }
                }).catch(()=>{});
            }else{
                this.endBranches = [];
                this.Selected_EndBranch = [];
                this.SelectedAll_EndBranch = false;
            }
        },

        SelectedAllStartBranch() {
            this.Selected_StartBranch = [];
            if(!this.SelectedAll_StartBranch){
                for (let i in this.startBranches) {
                    this.Selected_StartBranch.push(this.startBranches[i].id);
                }
            }
        },
        SelectedAllEndBranch() {
            this.Selected_EndBranch = [];
            if(!this.SelectedAll_EndBranch){
                for (let i in this.endBranches) {
                    this.Selected_EndBranch.push(this.endBranches[i].id);
                }
            }
        },

        Selected_StartPart(partId){
            this.Check_StartBranches(partId);
        },
        Selected_EndPart(partId){
            this.Check_EndBranches(partId);
        },
        Selected_StartProvince(provinceId){
            this.Check_StartBranches(provinceId);
        },
        Selected_EndProvince(provinceId){
            this.Check_EndBranches(provinceId);
        },
        Selected_Province_ForDistrict(){
            this.Check_StartBranches(this.selectedStartDistrict.id);
        },

        ClearSelected_SetIndexItem(){
            this.selectedStartPart = {...this.startParts[0]};
            this.selectedEndPart = {...this.endParts[0]};
            this.selectedStartProvince = {...this.startProvinces[0]};
            this.selectedEndProvince = {...this.endProvinces[0]};
            this.selectedProvince_ForDistrict = {...this.province_ForDistricts[0]}
            this.selectedStartDistrict = {...this.startDistricts[0]};
            this.selectedEndDistrict = {...this.endDistricts[0]};
            this.selectedStartDistrict = {...this.startDistricts[0]};
            this.selectedEndDistrict = {...this.endDistricts[0]};
        }, // SetIndex-Item = [0];

        CheckSelectedItem(){
            if(this.oldLevel == 3){
                this.selectedStartPart.id = this.promotion.start_id;
                this.selectedEndPart.id = this.promotion.end_id;
                this.oldStartPart = this.promotion.start_id;
                this.oldEndPart = this.promotion.end_id;
            }else if(this.oldLevel == 2){
                this.selectedStartProvince.id = this.promotion.start_id;
                this.selectedEndProvince.id = this.promotion.end_id;
            }else if(this.oldLevel == 1){
                this.selectedStartDistrict.id = this.promotion.start_id;
                this.selectedEndDistrict.id = this.promotion.end_id;
                this.selectedProvince_ForDistrict.id = this.selectedBox.id;
                this.Check_StartBranches(this.promotion.start_id);
                this.Check_EndBranches(this.promotion.end_id);
            }else if(this.oldLevel == 0){
                this.selectedStartVillage.id = this.promotion.start_id;
                this.selectedEndVillage.id = this.promotion.end_id;
            }
        },


        escapeRegExp(string) {
            string = string.toString()
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
    },

    computed: {
		FilterStartBranch(){
			var c = this.escapeRegExp(this.inputSearch_StartBranch)
            const pattern = new RegExp(c, 'i')
            return this.startBranches.filter(c => {
                return pattern.test(c.branch_name);
            });
        },
        FilterEndBranch(){
			var c = this.escapeRegExp(this.inputSearch_EndBranch)
            const pattern = new RegExp(c, 'i')
            return this.endBranches.filter(c => {
                return pattern.test(c.branch_name)
            });
        },
        Filter_ForDistricts() {
            const resultStart = this.startDistricts.filter(item => {
                return item.province_id == this.selectedProvince_ForDistrict.id;
            });
            const resultEnd = this.startDistricts.filter(item => {
                return item.province_id == this.selectedProvince_ForDistrict.id;
            });
            
            if(this.promotion.level !== this.oldLevel){
                this.selectedStartDistrict = {...resultStart[0]};
                this.selectedEndDistrict = {...resultEnd[0]};
            }
            return resultStart, resultEnd;
        },
        FilterDistrict_ForVillages() {
            const resultDistricts = this.district_ForVillages.filter(item => {
                return item.province_id == this.selectedProvince_ForVillage.id;
            });
            this.selectedDistrict_ForVillage = {...resultDistricts[0]};
            if(this.promotion.level == this.oldLevel){
                this.selectedDistrict_ForVillage.id = this.selectedBox.distId;
            }
            return resultDistricts;
        },
        Filter_ForVillages() {
            const resultStartVillages = this.startVillages.filter(item => {
                return item.district_id == this.selectedDistrict_ForVillage.id;
            });
            const resultEndVillages = this.endVillages.filter(item => {
                return item.district_id == this.selectedDistrict_ForVillage.id;
            });
            this.selectedEndVillage = {...resultEndVillages[0]};
            this.selectedStartVillage = {...resultStartVillages[0]};
            if(this.promotion.level == this.oldLevel){
                this.selectedEndVillage.id = this.promotion.start_id;
                this.selectedStartVillage.id = this.promotion.end_id;
            }
            return resultStartVillages, resultEndVillages;
        },
	},

    watch:{
        'promotion.discount_type':function(newType){
            if(newType !== this.oldDiscountType){
                this.promotion.discount = 0;
            }else{
                this.promotion.discount = this.oldDiscount;
            }
        },
        'Selected_StartBranch':function(value){
            if(value.length == this.startBranches.length){
                this.SelectedAll_StartBranch = true;
                if(this.startBranches.length){
                    this.SelectedAll_StartBranch = true;
                }else{
                    this.SelectedAll_StartBranch = false;
                }
            }else{
                this.SelectedAll_StartBranch = false;
            }
        },
        'Selected_EndBranch':function(value){
            if(value.length == this.endBranches.length){
                this.SelectedAll_EndBranch = true;
                if(this.endBranches.length){
                    this.SelectedAll_EndBranch = true;
                }else{
                    this.SelectedAll_EndBranch = false;
                }
            }else{
                this.SelectedAll_EndBranch = false;
            }
        },
        'promotion.level':function(value){
            this.CheckLevel(value);
            if(value !== this.oldLevel){
                this.ClearSelected_SetIndexItem();
            }else{
                this.CheckSelectedItem();
            }
            if(value == 3){
                this.showBox_Province = false;
                this.showBox_District = false;
                this.showBox_Village = false;
                this.showBox_Part = true;
                this.Check_StartBranches(this.selectedStartPart.id);
                this.Check_EndBranches(this.selectedEndPart.id);
            }else if(value == 2){
                this.oldStarProvince = this.promotion.start_id;
                this.oldEndProvince = this.promotion.end_id;

                this.showBox_Part = false;
                this.showBox_District = false;
                this.showBox_Village = false;
                this.showBox_Province = true;
                this.Check_StartBranches(this.selectedStartProvince.id);
                this.Check_EndBranches(this.selectedEndProvince.id);
            }else if(value == 1){
                this.oldStartDistrict = this.promotion.start_id;
                this.oldEndDistrict = this.promotion.end_id;

                this.showBox_Part = false;
                this.showBox_Province = false;
                this.showBox_Village = false;
                this.showBox_District = true;
                this.Check_StartBranches(this.selectedStartDistrict.id);
                this.Check_EndBranches(this.selectedEndDistrict.id);
            }else if(value == 0){
                this.oldStartVillage = this.promotion.start_id;
                this.oldEndVillage = this.promotion.end_id;

                this.showBox_Part = false;
                this.showBox_Province = false;
                this.showBox_District = false;
                this.showBox_Village = true;
                this.Check_StartBranches(this.selectedStartVillage.id);
                this.Check_EndBranches(this.selectedEndVillage.id);
            }
        },
        'promotion.is_across':function(value){
            this.showSelected = value == 'true';
            if(value == 'false'){
                this.promotion.reverse = false;
            }
        },
        'selectedStartDistrict.id':function(value){
            this.Check_StartBranches(value);
        },
        'selectedEndDistrict.id':function(value){
            this.Check_EndBranches(value);
        },
        'selectedStartVillage.id':function(value){
            this.Check_StartBranches(value);
        },
        'selectedEndVillage.id':function(value){
            this.Check_EndBranches(value);
        },

        'inputSearch_StartBranch':function(){
            if(!this.FilterStartBranch.length){
                this.showMsg_StartBranch = false;
            }else{
                this.showMsg_StartBranch = true;
            }
        },
        'inputSearch_EndBranch':function(){
            if(!this.FilterEndBranch.length){
                this.showMsg_EndBranch = false;
            }else{
                this.showMsg_EndBranch = true;
            }
        }
    }

}