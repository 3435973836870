<template>
    <div class="container" :style="{marginTop: '5px'}">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                  ຂໍ້ມູນ ເເພ໋ກເເກ໋ດ
                <span class="countTotal">({{listPackages.length}})</span>
                <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>ລຳດັບ</vs-th>
                    <vs-th>ເເພ໋ກເເກ໋ດ</vs-th>
                    <vs-th>ຂະໜາດພັດສະດຸ</vs-th>
                    <vs-th>ລາຄາຂົນສົ່ງ</vs-th>
                    <vs-th>ລາຄາກ່ອງ</vs-th>
                    <vs-th>ຮູບເເບບຂົນສົ່ງ</vs-th>
                    <vs-th>ປະເພດເເພ໋ດເເກ໋ດ</vs-th>
                    <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                    :key="index"
                    v-for="(packages, index) in $vs.getPage(listPackages, page, max)"
                    :data="packages"
                >
                    <vs-td>
                        {{ index + 1 }}
                    </vs-td>
                     <vs-td :style="{width: '230px'}">
                        {{ packages.name }}
                    </vs-td>
                    <vs-td>
                        {{ packages.sizeName }}
                    </vs-td>
                    <vs-td>
                        {{ FormatPrice(packages.price) }}
                    </vs-td>
                    <vs-td>
                        <span v-if="packages.packing_box !== 0">{{ FormatPrice(packages.packing_box) }}</span>
                        <span v-else>ບໍ່ມີເເກັດ</span>
                    </vs-td>
                    <vs-td>
                        <span v-if="packages.is_across == 'true'">
                            ຕ່າງເເຂວງ
                        </span>
                         <span v-if="packages.is_across == 'false'">
                            ພາຍໃນເເຂວງ
                        </span>
                    </vs-td>
                    <vs-td>
                        <span :class="{'text-danger': packages.typeName == 'Express'}">
                            ( {{packages.typeName}} )
                        </span>
                    </vs-td>
                    <vs-td>
                        <span v-if="packages.freight_type == 'origin'" :style="{color: 'green'}">( ຂົນສົ່ງຕົ້ນທາງ )</span>
                        <span v-if="packages.freight_type == 'destination'" :style="{color: '#195BFF'}">( ຂົນສົ່ງປາຍທາງ )</span>
                    </vs-td>
                    <vs-td style="text-align: right">
                        <div class="group-icon">
                            <vs-button circle icon flat @click="OpenModal_ReOpenPackage(packages.id)">
                                <i class="fal fa-folder-open"></i>
                            </vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listPackages, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalClose>
             <template v-slot="{ close }">
                <ReopenPackages @close="close" :packageId="packageId" @success="FetchData()"/>
            </template>
        </ModalClose>

    </div>
</template>
<script>
import ReopenPackages from './Dialog/Reopen';
import Loading from '../../Loading';

export default {
    components:{
        ReopenPackages,
        Loading
    },
    data:()=>({
        active: 1,
        page: 1,
        max: 15,
        listPackages: [],  
        packageId: '',
        isLoading: true,
        filterPackage: {},
    }),
    methods:{

        OpenModal_ReOpenPackage(packageId){
            this.packageId = packageId;
            this.$store.commit('modalClose_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-packages/' + this.$route.params.type).then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.listPackages = res.data.packageOfflines;
                }, 100);
            }).catch(()=>{
                
            });
        },
        FormatPrice(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        }
    },
    created(){
        this.FetchData();
    },
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>