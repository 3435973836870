<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '10px'}">
      <h4 style="margin-top: -10px;">
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
                    <vs-button :style="{marginTop: '-10px'}"
                               ref="button" flat
                               @click="AddInvoices()"
                               class="form-btn-loading"
                               :loading="loading"
                               :disabled="!isSelectingOrders"
                    >
                        ສ້າງ Invoices
                    </vs-button>
                </span>
      </h4>
    </div>

    <div class="box" :style="{marginTop: '-10px'}">
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <label class="label">ເລກທີບິນ:</label>
              <input
                  class="input"
                  placeholder="ເລກທີບິນ..."
                  type="text" name="shipment_number"
                  v-model="shipment_number"
                  @keyup.enter="FilterShipmentNumber()"
              >
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ເເຕ່ວັນທີ</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຫາວັນທີ</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3" v-if="$can('accountingmanager')">
          <div class="control">
            <label class="label">ປະເພດສາຂາຕົ້ນທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedBranchType">
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-3" v-if="$can('accountingoriginbranch')">
          <div class="control">
            <label class="label">ປະເພດສາຂາຕົ້ນທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedBranchType">
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-3" v-if="$can('accountingoriginfranchise')">
          <div class="control">
            <label class="label">ປະເພດສາຂາຕົ້ນທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedBranchType">
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center table-limit-height" style="margin-top: -15px;">
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ວັນທີຈ່າຍ</vs-th>
            <vs-th>ປະກັນໄພ</vs-th>
            <vs-th>ຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ລະຫັດລູກຄ້າ</vs-th>
            <vs-th>ຊື່ຜູ້ຮັບ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in invoices"
              :is-selected="isRowSelected(item.id)"
              :data="item"
              @selected="onItemCheckBoxChanged(item)">
            <vs-td>
              <div class="center con-checkbox">
                <vs-checkbox danger
                             :checked-force="isRowSelected(item.id)"
                             :val="item" @click="onItemCheckBoxChanged(item)">
                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                </vs-checkbox>
              </div>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ item.shipment_number }} )</vs-td>
            <vs-td>{{ FormatDate(item.paid_at) }}</vs-td>
            <vs-td v-if="item.insurance_price">( {{ FormatPrice(item.insurance_price) }} )</vs-td>
            <vs-td v-else>(0 ກີບ)</vs-td>
            <vs-td>( {{ FormatPrice(item.paid) }} )</vs-td>
            <vs-td>{{ item.customer_id }}</vs-td>
            <vs-td>{{ item.receiver_name }}</vs-td>
            <vs-td>{{ item.start_branch }}</vs-td>
            <vs-td>{{ item.end_branch }}</vs-td>
            <vs-td>{{ item.created_by }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import MixinShipmentCompany from '../../../Mixins/Shipments/Company/List-Shipment';
import DatePicker from "vue2-datepicker";
import Loading from '../../Loading';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
  mixins: [MixinShipmentCompany],
  components: {
    DatePicker,
    Loading,
    pagination
  },

  mounted() {
    if (this.$can('accountingoriginfranchise')){
      this.selectedBranchType = 'franchise'
    }else{
      this.selectedBranchType = 'branch'
    }
    this.FetchData();
  }
}
</script>

<style lang="scss" scoped>
.btn-clear {
  background: $crimson;
}

.table-limit-height {
  .vs-table {
    width: 100%;
    font-size: 0.9rem;
    margin: 0;
    max-height: 500px;
  }
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.box-summary {
  .box-total-price {
    border: 1px solid $crimson;
    // background: $crimson;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
  }

  .box-total-amount {
    border: 1px solid $primary-color;
    // background: $primary-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .box-total-shipment-bill {
    // background: $sub-color;
    border: 1px solid $sub-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }
}
</style>
