<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-gifts"></i>
                ລາຍງານ ລາຍການທັງໝົດ
                <span class="countTotal">({{listOrderRequests.length}})</span>
            </h4>
        </div>

        <div class="box">
            <div class="columns">
                <div class="column is-4">
                    <div class="control">
                        <label class="label">ເເຕ່ວັນທີ :</label>
                        <vuejs-datepicker 
                            v-model="fromDate"
                            input-class="input"
                            format="d-M-yyyy"
                            name="valid_date"
                            v-validate="'required'"
                        >
                        </vuejs-datepicker>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="control">
                        <label class="label">ຫາວັນທີ :</label>
                        <vuejs-datepicker 
                            v-model="toDate"
                            input-class="input"
                            format="d-M-yyyy"
                            name="valid_date"
                            v-validate="'required'"
                        >
                        </vuejs-datepicker>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="control">
                        <label class="label">ສະຖານະ :</label>
                        <div class="select">
                            <select class="input" @change="ReportOrderRequests()" v-model="selectedStatus.value">
                                <option :value="status.value" v-for="(status, index) in statuses" :key="index">
                                    {{status.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="center">
            <vs-table>
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ລະຫັດ ລາຍການ</vs-th>
                        <vs-th>ປະເພດພາຫະນະ</vs-th>
                        <vs-th>ຊື່ລູກຄ້າ</vs-th>
                        <vs-th>ປະເພດພັດສະດຸ</vs-th>
                        <vs-th>ຈຳນວນ</vs-th>
                        <vs-th>ນ້ຳໜັກ</vs-th>
                        <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th>
                        <vs-th>ສະຖານະ</vs-th>
                        <th>ວັນທີ່ສ້າງລາຍການ</th>
                        <th>ເວລານັດຮັບເຄື່ອງ</th>
                        <vs-th></vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                        :key="index" :data="orderPending"
                        v-for="(orderPending, index) in $vs.getPage(listOrderRequests, page, max)"
                    >
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '15%', color: 'crimson'}">
                            ( {{orderPending.order_id}} )
                        </vs-td>
                         <vs-td>
                            <span v-if="orderCancle.vehicle_type == 'motorcycle'">ລົດຈັກ</span>
                            <span v-if="orderCancle.vehicle_type == 'car'">ລົດໃຫຍ່</span>
                        </vs-td>
                        <vs-td>{{orderPending.customer_name}}</vs-td>
                        <vs-td>{{orderPending.categ_name}}</vs-td>
                        <vs-td>{{orderPending.amount}}</vs-td>
                        <vs-td>{{orderPending.weight}} kg</vs-td>
                        <vs-td>
                            <span :style="{color: '#195BFF'}">
                                ( {{orderPending.rider_name !== null ? orderPending.rider_name : '.................'}} )
                            </span>
                        </vs-td>
                        <vs-td>
                            <span v-if="orderPending.status == 'pending'" :style="{color: '#F57920'}">ລໍຖ້າອະນຸມັດ</span>
                            <span v-if="orderPending.status == 'confirm'" :style="{color: '#195BFF'}">ອະນຸມັດເເລ້ວ</span>
                            <span v-if="orderPending.status == 'on_the_way'" :style="{color: 'green'}">ກຳລັງໄປຮັບເຄື່ອງ</span>
                            <span v-if="orderPending.status == 'success'" :style="{color: '#195BFF'}">ສຳເລັດເເລ້ວ</span>
                            <span v-if="orderPending.status == 'reject'" :style="{color: 'crimson'}">ຖືກປະຕິເສດ</span>
                            <span v-if="orderPending.status == 'cancel'" :style="{color: 'crimson'}">ຍົກເລີກເເລ້ວ</span>
                        </vs-td>
                        <vs-td>{{FormatDate(orderPending.created_at)}}</vs-td>
                        <vs-td>{{orderPending.schedule_time !== null ? orderPending.schedule_time : '...............'}}</vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalViewOrderRequestProcessing(orderPending.id)">
                                    <i class="fad fa-eye"></i>
                                </vs-button>
                            </div>
                        </vs-td>  
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listOrderRequests, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalDelete>
            <template v-slot="{ close }">
                <RejectOrderRequest @close="close" :orderRequestId="orderRequestId" @success="FetchOrderRequests()"/>
            </template>
        </ModalDelete>

        <ModalPreviewFile>
            <template v-slot="{ close }">
                <PreviewOrderRequest @close="close" :OrderRequest="filterOrderRequest" @success="FetchOrderRequests()"/>
            </template>
        </ModalPreviewFile>

    </div>
</template>

<script>
import mixinsReportOrderRequests from '@coms/../../src/Mixins/Reports/Report-OrderRequest';
import Loading from '../../Loading';
import RejectOrderRequest from '../Dialog/Reject';
import PreviewOrderRequest from '../Dialog/View-Order';
import vuejsDatepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    components:{
        Loading,
        RejectOrderRequest,
        PreviewOrderRequest,
        vuejsDatepicker
    },

    mixins:[mixinsReportOrderRequests],
    methods:{
        FormatDate(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
    },
    created(){
        this.ReportOrderRequests();
    }
}
</script>

<style>
.vs-pagination-content{
    z-index: 0 !important;
}
</style>