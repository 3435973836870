import { Validator } from 'vee-validate';

const validate = {
    custom: {
        district:{
            required: '( ກະລຸນາເລືອກເມືອງກ່ອນ... )',
        },
        village:{
            required: '( ກະລຸນາປ້ອນບ້ານກ່ອນ... )',
        },
        category:{
            required: '( ກະລຸນາປ້ອນໝວດໝູ່ກ່ອນ... )'
        }
    }
};

export const ValidateVillages = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}