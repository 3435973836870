<template>
  <div class="container">
    <div class="columns login-section">
      <div class="column is-half is-offset-one-quarter register">
        <div class="head">
          <h1 class="title has-text-centered">ທ່ານລົງທະບຽນສຳເລັດແລ້ວ</h1>
        </div>
        <div class="card register">
          <img alt="Vue logo" :src="require('@/assets/hal-register.jpg')" />
          <div class="card-content">
            <div>
<!--              <div class="logo">-->
<!--                <img alt="Vue logo" :src="require('@/assets/logistic.png')" />-->
<!--              </div>-->
              <p class="title register">
                ກະລຸນາແຈ້ງເບີ ຫຼື ຊື່ເວລາເຂົ້າຮ່ວມງານ ເພື່ອຮັບເອົາບັດ ແລະຂອງລາງວັນພາຍໃນງານ
                <br />
                ຂອບໃຈທ່ານທີ່ໃຊ້ບໍລິການຮຸ່ງອາລຸນຂົນສົ່ງດ່ວນ
              </p>
              <div class="columns">
                <div class="column">
                  <a class="button btn" href="tel:1419"> ສາຍດ່ວນ 1419 </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  props: {
    msg: String,
  },
  mounted() {
    window.scrollTo(0, 0)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo img {
  max-height: 200px;
  min-width: auto;
}
.container{
margin-top: 0;
  overflow: hidden;
}
.title.register {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.logo img::before {
  box-shadow: 1px solid #e21312;
}
.logo img::after {
  box-shadow: 1px solid #e21312;
}
.card {
  box-shadow: none;
}
.btn {
  background: #e21312;
  color: #fff;
  margin-top: 12px;
  border: none;
}
.btn .icon {
  padding-left: 8px;
}
.login-section {
  display: flex;
  min-height: 100vh;
  background-color: #f5f7f9;
  background-image: url("https://hal-logistics.la/images/slider/bannernews.jpeg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
}
.column.register {
  padding-left: 0px;
  padding-right: 0px;
}
.card.register {
  min-height: 720px;
}
.head {
  /* clip-path: circle(49.7% at 18% 0);*/
  background-color: #E11013;
  height: 100px;
  text-align: left;
  display: flex;
}
.has-text-centered {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
</style>
