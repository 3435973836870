<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-lock-alt" :style="{ width: '25px', fontSize: '18px' }"></i>
        ປ່ຽນລະຫັດຜ່ານ
      </h4>
    </template>

    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ລະຫັດຜ່ານໃໝ່
          <span class="text-danger">* {{ errors.first('user_password') }}</span>
          <span class="text-danger">
                    {{ server_errors.password }}
                </span>
        </label>
        <PasswordInput v-model="password" name="user_password"
                       v-validate="'required|min:6|max:25'"
                       placeholder="ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່ກ່ອນ..."/>
      </div>
    </div>

    <div ref="target" id="target" class="center" :style="{marginTop: '30px'}">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
          ປ່ຽນລະຫັດຜ່ານ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import MixinChangePassword from '@/Mixins/Distribution-Center/User/ChangePassword';
import {ValidateUserPassword} from '@/Plugins/Validation/PasswordValidation';

export default {

  props: ['userId'],

  mixins: [MixinChangePassword],

  created() {
    ValidateUserPassword(this);
  }
}
</script>
<style lang="scss" scoped>
.label-title {
  text-align: left;
  margin-top: -15px;
}

.box-list-permission {
  text-align: left;
  margin-top: 10px;
  background: rgba(var(--vs-gray-2), 1);
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  max-width: 368px;

  .permission {
    background: $primary-color;
    padding: 5px 5px 5px 5px;
    color: $white;
    border-radius: 5px;
    width: auto;
    margin: 3px;
    text-align: center;
    font-size: 14px;
  }
}

.box-upload {
  display: flex;
  margin-top: 25px;

  .box-img {
    margin: auto;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
    width: 105px;
    height: 105px;
    border-radius: 50px;

    &:hover {
      cursor: pointer;
    }

    .img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      margin-top: 3px;
    }
  }
}

.text-upload-img {
  margin-top: 10px;
  max-width: 100%;

  .msg {
    color: $crimson;
  }
}

.text-filename {
  .type-file {
    color: $primary-color !important;
  }
}

.text-size {
  font-size: 14px;

  .size {
    color: $primary-color !important;
  }
}
</style>
