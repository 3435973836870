export default {
    data:()=>({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            villageId: '',
        },
        value: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },

        parts: [],
        provinces: [],
        districts: [],
        villages: [],

        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},
        selectedVillage: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        checkVillage: true,

        translateInloas:{
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1'
        }
    }),

    methods:{
        FetchData(){
            this.$axios.get('list-branch-address').then(res=>{
                const items = res.data.data;
                this.parts = items.parts;
                this.selectedPart = {...this.parts[0]};
                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;
            }).catch(()=>{});
        },

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }else{
                    this.$emit('scrollTo');
                }
            });
        },
        SaveItem(){
            this.btnLoading = true;
            this.value.villageId = this.selectedVillage.village_id;
            this.$axios.post('register-vehicle-departments', this.value).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FilterProvince(partId){
            const result_checking = this.filterProvinces.filter(item=>{
                if(item.part_id == partId){
                    return item;
                }
            });
            this.provinces = result_checking;
            this.selectedProvince = {...this.provinces[0]};
        },
        FilterDistricts(provinceId){
            const result_checking = this.filterDistricts.filter(item=>{
                if(item.province_id == provinceId){
                    return item;
                }
            });
            this.districts = result_checking;
            this.selectedDistrict = {...this.districts[0]};
        },
        FilterVillages(districtId){
            const result_checking = this.filterVillages.filter(item=>{
                if(item.district_id == districtId){
                    return item;
                }
            });
            this.villages = result_checking;
            this.selectedVillage = {...this.villages[0]};
        }
    },

    watch:{
        'selectedPart.partId':function(partId){
            this.FilterProvince(partId);
        },
        'selectedProvince.proId':function(provinceId){
            this.FilterDistricts(provinceId);
        },
        'selectedDistrict.distId':function(districtId){
            this.FilterVillages(districtId);
        },

        // Clear-Errors
        'selectedVillage.village_id':function(){
            this.server_errors.villageId = '';
        },
        'value.prefix':function(){
            this.server_errors.prefix = '';
        },
        'value.name':function(){
            this.server_errors.name = '';
        },
        'value.tel':function(){
            this.server_errors.tel = '';
        },
        'value.email':function(){
            this.server_errors.email = '';
        }
    }
}
