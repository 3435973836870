<template>
    <div class="container">
        <GmapMap
            :center="center"
            :zoom="16"
            style="width:100%; height:300px;"
        >
        <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            @click="center=m.position"
            :draggable="true"
            @dragend="onLocation"
            :icon="markerOptions"
            :animation="2"
            ref="markers"
        />
        </GmapMap>
    </div>
</template>

<script>
export default {
    props:['rider'],
    
    data:()=>({
        loading: false,
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,
    }),
    methods:{
        // Set-Location
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },
        placeMarker() {
            this.markers = [];     
            const marker = {
                lat: this.center.lat,
                lng: this.center.lng,
            };
            this.markers.push({ position: marker });
            this.animateMarker();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        }, // End Set-Location
    },

    created() {
        this.center.lat = this.rider.lat;
        this.center.lng = this.rider.lng;
        this.placeMarker();
    },
}
</script>

<style>

</style>