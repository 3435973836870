<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-gift"></i>
          ລາຍການຂອງລາງວັນ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
      <!--      <div class="column is-6" v-if="$can(['SuperAdmin','marketing','marketinghelper'])">-->
      <!--        <vs-button circle icon flat @click="OpenModalAdd()"-->
      <!--                   style="padding-left: 8px; padding-right: 8px; float: right">-->
      <!--          <i class="fal fa-plus-circle"></i>-->
      <!--        </vs-button>-->
      <!--      </div>-->
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຮູບ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ປະເພດ</vs-th>
            <vs-th>ຈຳນວນພ້ອຍ</vs-th>
            <vs-th>ທັງໝົດ</vs-th>
            <vs-th>ຮັບແລ້ວ</vs-th>
            <vs-th>ຈຳນວນຕໍ່ໜຶ່ງຄົນ</vs-th>
            <vs-th>ຈຳນວນຕໍ່ມື້</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th v-if="$can(['SuperAdmin','marketing','marketinghelper'])"></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="prize"
              v-for="(prize, index) in $vs.getPage(prizes, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>
              <div v-if="prize.image" class="img-size">
                <expandable-image
                    class="image"
                    :src=prize.image
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ prize.name }}</vs-td>
            <vs-td>{{ laType[prize.type] }}</vs-td>
            <vs-td v-if="prize.point_amount">{{ FormatPrice(prize.point_amount) }}</vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td>{{ FormatPrice(prize.total) }}</vs-td>
            <vs-td>{{ FormatPrice(prize.received) }}</vs-td>
            <vs-td v-if="prize.amount_per_user">{{ FormatPrice(prize.amount_per_user) }}</vs-td>
            <vs-td v-else>ບໍ່ຈຳກັດ</vs-td>
            <vs-td v-if="prize.amount_per_day">{{ FormatPrice(prize.amount_per_day) }}</vs-td>
            <vs-td v-else>ບໍ່ຈຳກັດ</vs-td>
            <vs-td v-if="prize.status === 'open'" style="color: green">{{ laStatus[prize.status] }}</vs-td>
            <vs-td v-else style="color: red">{{ laStatus[prize.status] }}</vs-td>
            <vs-td style="text-align: right; width: 100px" v-if="$can(['SuperAdmin','marketing','marketinghelper'])">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="OpenModalEdit(prize)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <ModalAdd>
      <template v-slot="{ close }">
        <AddShop @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <EditPrize @close="close"
                  :item="item"
                  @success="FetchData()"/>
      </template>
    </ModalEdit>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import AddShop from "@/views/ExchangeItem/Shop/Dialog/Add"
import EditPrize from "@/views/Prize/Dialog/Edit"

export default {
  components: {
    Loading,
    pagination,
    AddShop,
    EditPrize
  },

  data: () => ({
    laStatus: {
      'open': 'ເປີດ',
      'close': 'ປິດ'
    },
    laType: {
      'object': 'ສິ່ງຂອງ',
      'point': 'ພ້ອຍ'
    },
    page: 1,
    max: 10,
    isLoading: true,
    prizes: [],
    item: {},
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
  },
  methods: {
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.prizes = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/prize', {
        params: {
          search: this.searchItem,
          isApp: 'no',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    OpenModalEdit(prize) {
      this.item = JSON.parse(JSON.stringify(prize));
      this.$store.commit("modalEdit_State", true);
    },
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
