import { FormatDateTime, FormatNumber} from '@/Helpers/Format';

export default {
    data: () => ({
        shipment_number: '',
        change_branch: {},
        shipment_detail: {},
        loading: true,
        listAllOrders: {},
        // Value
        selectedAllStatus: null,
        searchItem: '',
        shipment: {},
        oldVal: '',
        //LA_Status
        la_status: {
            'Pending': 'ລໍຖ້າດໍາເນີນການ',
            'Processing': 'ກຳລັງດຳເນີນການ',
            'Delivering': 'ກໍາລັງຈັດສົ່ງ',
            'Success': 'ຈັດສົ່ງສຳເລັດ',
            'Single Sign': 'ຮັບເຄື່ອງແບບແຍກ',
            'Timeout': 'ຈັດສົ່ງເຄື່ອງມື້ອື່ນ',
            'Contact Error': 'ເບີຕິດຕໍ່ບໍ່ໄດ້,ບໍ່ສາມາດຈັດສົ່ງ',
            'Lost': 'ເຄື່ອງເສຍ',
            'Return To Sender': 'ຈັດສົ່ງຄືນຜູ້ຝາກ',
            'Address Error': 'ທີ່ຢູ່ຜິດ(ປ່ຽນທີຢູ່ໃຫມ່)',
            'Trouble': 'ສະຖານະບໍ່ປົກກະຕິ',
            'Disposed': 'ທໍາລາຍ',
            'Customer Request Cancel': 'ລູກຄ້າຍົກເລີກ',
            'Wrong Flow': 'ເຄື່ອງທີ່ຍິງຂ້າມ',
            'Expires': 'ໝົດເຂດຮັບເຄື່ອງ',
            'BillWasSettled': 'ຮຽກເກັບເງິນສຳເລັດແລ້ວ',
            'SuccessfullyCompensated': 'ຊົດເຊີຍເງິນສຳເລັດ'
        },

        la_status_shipment_type: {
            'origin_freight_fees': 'ເກັບເງິນຕົ້ນທາງ',
            'destination_freight_fees': 'ເກັບເງິນ ( CDC )',
            'origin_freight_fees_cod': 'ເກັບເງິນ ( COD )',
            'destination_freight_fees_cod': 'ເກັບເງິນ ( COD & CDC )',
            'all': 'ທັງໝົດ'
        }
    }),
    methods: {
        FormatDate(value) {
            return FormatDateTime(value);
        },

        FormatPrice(price) {
            if (price !== 0) {
                return FormatNumber(price, this.$t('common.ກີບ'));
            }
            return 0 + ' ' + this.$t('common.ກີບ');
        },

        fetchInvoiceItemsResponse() {
            return this.$axios.get('account/list/solve/shipments', {
                params: {
                    shipment_pay_type: this.$route.query.tab,
                    query: this.searchItem,
                }
            })
        },

        FetchData() {
            this.loading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.listAllOrders = res.data;
                    this.loading = false;
                }, 300);
            }).catch(() => {
                this.loading = false;
            });
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },
    },
}
