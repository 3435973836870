<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນການລົງທະບຽນ
        <span class="countTotal">({{ count }})</span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ລາຍຊື່ນັກສຶກສາ:</label>
            <model-list-select
                :list="listIntern"
                v-model="selectedIntern"
                placeholder="ເລືອກ..."
                option-text="name"
                option-value="userId"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 25px">
          <div class="field">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="printIntern()"
                  class="form-btn-loading"
                  style="margin-top: 0"
                  :loading="loading">
                export excel
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ວັນທີລົງທະບຽນ</vs-th>
            <vs-th>ຊື່ລູກຄ້າ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ຄົນແນະນຳ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listEmployees"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{
                moment(item.created_at).format('DD/MM/YYYY HH:mm')
              }}
            </vs-td>
            <vs-td>{{ item.name }} {{ item.surname }}</vs-td>
            <vs-td>{{ item.tel }}</vs-td>
            <vs-th>{{ item.employee_name }}</vs-th>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()"
          >
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from '../../Loading';
import * as helper from '../../../Helpers/GetValue';
import pagination from '../../../Plugins/Paginations/Pagination';
import moment from "moment";
import DatePicker from "vue2-datepicker";
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect
  },

  data: () => ({
    isLoading: true,
    employeeType: 'intern_marketing',
    roleUsers: [],
    loading: false,
    listEmployees: [],
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    item: {},
    itemId: '',
    searchItem: '',
    oldVal: '',
    // pagination
    offset: 10,
    pagination: {},
    per_page: 10,
    count: 0,
    listIntern: [],
    selectedIntern: ''
  }),
  methods: {
    fetchIntern() {
      this.$http.get('list/intern', {
        params: {
          employee_type: 'intern_marketing',
          print: 'no',
        }
      }).then(res => {
        setTimeout(() => {
          this.listIntern = res.data;
          this.listIntern.unshift({
            userId: "",
            name: 'ທັງໝົດ'
          });
        }, 300);
      }).catch(() => {

      });
    },

    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },

    printQr(item) {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('print/qr/intern-marketing', {
            reference_id: item.userId,
          },
          {responseType: 'blob'}
      ).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'QR INTERN MARKETING' + item.name + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      })
    },

    printIntern() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('print/intern/register', {
        search: this.searchItem,
        start_date: this.startDate,
        end_date: this.endDate,
        reference_id: this.selectedIntern,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'ລາຍຊື່ການລົງທະບຽນຂອງນັກສຶກສາ ' + moment(this.startDate).format('DD-MM-YYYY') +
            ' ຫາວັນທີ ' + moment(this.endDate).format('DD-MM-YYYY') + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FetchData() {
      this.isLoading = true;
      this.$http.get('list/intern/register', {
        params: {
          search: this.searchItem,
          start_date: this.startDate,
          end_date: this.endDate,
          reference_id: this.selectedIntern,
          print: 'no',
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listEmployees = res.data.data;
          this.pagination = res.data;
          this.count = res.data.total
        }, 100);
      }).catch(() => {
      });
    },

    FilterData() {
      helper.GetOldValueOnInput(this);
    }
  },
  created() {
    this.fetchIntern();
    this.FetchData();
  },
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'startDate': function () {
      this.FetchData();
    },
    'endDate': function () {
      this.FetchData();
    },
    'selectedIntern': function () {
      this.FetchData();
    },
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
