<template>
    <div>
        <template>
            <h4 class="not-margin"
                :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-plus-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມ ສະຖານະການຈັດສົ່ງ
            </h4>
        </template>
    
        <div class="field" :style="{marginTop: '20px'}">  
            <div class="control">
                <label class="label">
                    ສະຖານະການຈັດສົ່ງ
                    <span class="text-danger">* {{ errors.first('name') }}</span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input 
                    type="text" class="input" 
                    name="name" 
                    placeholder="ສະຖານະການຈັດສົ່ງ..." 
                    v-model="value.name"
                    v-validate="'required'"
                >
            </div>
        </div>
   
        <button class="button" :class="{'is-loading': btnLoading}" :style="{marginTop: '20px', width: '30%'}" @click="ValidateForm()">
            ເພີ່ມ ສະຖານະ
        </button>
    </div>
</template>

<script>
import mixinsAddShipmentStatuses from '@coms/../../src/Mixins/Shipment-Status/Add';
import { Validations } from '@coms/../../src/Plugins/Validation/Validation';

export default {

    mixins: [mixinsAddShipmentStatuses],

    created(){
        Validations(this);
    }
}
</script>
<style lang="scss" scoped>
    .button{
        &:hover{
            color: $white;
        }
    }
</style>