<template>
    <div class="container">
        <div class="columns box-title is-12">
            <div class="column is-4">
                <h4 class="has-text-left">
                    <i class="fal fa-list"></i>
                    ລາຍການ
                    <span class="countTotal">({{ count }})</span>
                </h4>
            </div>
            <div class="column is-4">
                <div class="control">
                    <label class="label">ປະເພດລາຍການ:</label>
                    <div class="select">
                        <select class="input" v-model="list_type" @change="FetchData">
                            <option value="">ທັງໝົດ</option>
                            <option value="promotion">ໂປຣໂມຊັນ (ສະແດງຢູ່ໜ້າພ້ອຍ)</option>
                            <option value="event">ເຫດການ (ສະແດງຢູ່ໜ້າທຳອິດທີ່ເຂົ້າແອັບ)</option>
                            <option value="shop">ຮ້ານຄ້າ</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column is-4">
                <vs-button circle icon flat @click="OpenModalAdd()"
                           style="padding-left: 8px; padding-right: 8px; float: right">
                    <i class="fal fa-plus-circle"></i>
                </vs-button>
            </div>
        </div>
        <div class="center fit-width">
            <vs-table ref="table">
                <template #thead>
                    <vs-tr>
                        <vs-th></vs-th>
                        <vs-th>ລຳດັບການສະແດງ</vs-th>
                        <vs-th>ປະເພດ</vs-th>
                        <vs-th>ຮູບ</vs-th>
                        <vs-th>ຫົວຂໍ້</vs-th>
                        <vs-th>ຄຳອະທິບາຍ</vs-th>
                        <vs-th>ສະຖານະ</vs-th>
                        <vs-th>url</vs-th>
                        <vs-th>ວັນທີສ້າງ</vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                            :key="index"
                            :data="eventPage"
                            v-for="(eventPage, index) in $vs.getPage(eventPages, page, max)">
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat
                                           @click="OpenModalEdit(eventPage)">
                                    <i class="fad fa-pencil"></i>
                                </vs-button>
                            </div>
                        </vs-td>
                        <vs-td>{{ eventPage.priority }}</vs-td>
                        <vs-td>{{ laType[eventPage.type] }}</vs-td>
                        <vs-td>
                            <div v-if="eventPage.image" class="img-size">
                                <expandable-image
                                        class="image"
                                        :src=eventPage.image
                                ></expandable-image>
                            </div>
                        </vs-td>
                        <vs-td>{{ eventPage.title }}</vs-td>
                        <vs-td>{{ eventPage.description }}</vs-td>
                        <vs-td v-if="eventPage.status === 'on'" style="color: green">{{
                            laStatus[eventPage.status]
                            }}
                        </vs-td>
                        <vs-td v-else style="color: red">{{ laStatus[eventPage.status] }}</vs-td>
                        <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
                            <vs-tooltip>
                                <i class="fal fa-info-square"></i>
                                <template #tooltip>
                                    {{ eventPage.url }}
                                </template>
                            </vs-tooltip>
                        </vs-td>
                        <vs-td>{{ moment(eventPage.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                                @paginate="FetchData"></pagination>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>
        <ModalAdd>
            <template v-slot="{ close }">
                <AddShop @close="close" @success="FetchData()"/>
            </template>
        </ModalAdd>
        <ModalEdit>
            <template v-slot="{ close }">
                <EditShop @close="close"
                          :item="item"
                          @success="FetchData()"/>
            </template>
        </ModalEdit>
    </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import AddShop from "@/views/EventPage/Dialog/Add"
import EditShop from "@/views/EventPage/Dialog/Edit"

export default {
    components: {
        Loading,
        pagination,
        AddShop,
        EditShop
    },

    data: () => ({
        laStatus: {
            'on': 'ເປີດ',
            'off': 'ປິດ'
        },
        laType: {
            'promotion': 'ໂປຣໂມຊັນ',
            'event': 'ເທດສະການ',
            'shop': 'ຮ້ານຄ້າແນະນຳ'
        },
        list_type: '',
        page: 1,
        max: 10,
        isLoading: true,
        eventPage_type: '',
        eventPages: [],
        item: {},
        searchItem: "",
        oldVal: "",
        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},
    }),
    methods: {
        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.eventPages = res.data.data;
                    this.count = res.data.total;
                    this.pagination = res.data;
                    this.isLoading = false;
                    this.oldVal = ''
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('event_page', {
                params: {
                    type: this.eventPage_type,
                    isApp: 'no',
                    list_type: this.list_type,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page,
                }
            })
        },

        OpenModalEdit(eventPage) {
            this.item = JSON.parse(JSON.stringify(eventPage));
            this.$store.commit("modalEdit_State", true);
        },
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        },
        ...mapActions({
            LogoutUser: "User/destroyToken",
        }),
        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },
    },
    created() {
        this.FetchData();
    },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
