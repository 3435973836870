export default {
    data: () => ({
        active: false, isLoading: false, btnLoading: false, server_errors: {
            bag_bill_number: '',
        },
        errorMessages: {
            ERROR_INVALID_BAG_BILL_NUMBER: 'ບໍ່ສາມາດແກ້ໄຂໄດ້',
        }
    }),

    methods: {

        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        },

        EditItem() {
            this.btnLoading = true;

            this.parcelBag.bag_bill_number = this.parcelBag.bag_number;
            this.$http.post('v1/auth/branches/shipments/orders/tracking/packing-parcel-orders/update-reusable-parcel-bag/'
                + this.parcelBag.id,
                this.parcelBag).then(() => {
                setTimeout(() => {
                    this.btnLoading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                }, 300);
            }).catch(error => {
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }

                    // eslint-disable-next-line no-unused-vars
                    for (let [key] of Object.entries(obj)) {
                        if(this.errorMessages[key]) {
                            this.server_errors.bag_bill_number = this.errorMessages[key];
                        }
                    }

                } else if (error.response.status == 403) {
                    this.server_errors.bag_bill_number = 'ບໍ່ມີສິດໃນການແກ້ໄຂ';
                }

            }).finally(() => {
                this.btnLoading = false;
            });
        },
    },

    watch: {
        'parcelBag.bag_bill_number': function () {
            this.server_errors.bag_bill_number = '';
        },
    },
}
