<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ຂໍ້ມູນ ຂະໜາດ ເເລະ ນ້ຳໜັກ ພັດສະດຸ
            </h4>
        </template>

        <div :style="{marginTop: '20px'}">
            <div class="field">
                <div class="control">
                    <label class="label">
                        ປະເພດເເພ໋ກເເກ໋ດ
                        <span class="text-danger">* {{ errors.first('package_type') }}</span>
                        <span class="text-danger">
                            {{server_errors.package_type}}
                        </span>
                    </label>
                    <div class="select">
                        <select class="input" v-model="packagesize.package_type_id" name="package_type" v-validate="'required'">
                            <option v-for="(packageType, index) in packageTypes" :key="index" :value="packageType.id">
                                {{packageType.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="field">
                <div class="control">
                    <label class="label">
                        ຂະໜາດພັດສະດຸ
                        <span class="text-danger">* {{ errors.first('package_size') }}</span>
                        <span class="text-danger">
                            {{server_errors.name}}
                        </span>
                    </label>
                    <input 
                        class="input" name="package_size" 
                        placeholder="ກະລຸນາປ້ອນຂະໜາດພັດສະດຸ..." 
                        v-model="packagesize.name" 
                        v-validate="'required'"
                    >
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <label class="label">
                        ນ້ຳໜັກເລີ່ມຕົ້ນ ( kg )
                        <span class="text-danger">* {{ errors.first('start_weight') }}</span>
                        <span class="text-danger">
                            {{server_errors.start_weight}}
                        </span>
                    </label>
                    <input 
                        class="input" name="start_weight" 
                        placeholder="ກະລຸນາປ້ອນຂະໜາດເປັນ kg..." 
                        v-model="packagesize.start_weight" 
                        v-validate="'required'"
                    >
                </div>
            </div>
            <div class="field" v-if="isEndWeight_Disable">
                <div class="control">
                    <label class="label">
                        ນ້ຳໜັກໃຫຍ່ສຸດ ( kg )
                    </label>
                    <input 
                        class="input" name="end_weight" 
                        placeholder="ກະລຸນາປ້ອນຂະໜາດເປັນ kg..." 
                        v-model="packagesize.end_weight" 
                        v-validate="'required'"
                    >
                </div>
            </div>
            <div class="field" v-if="isRound_Disable">
                <div class="control">
                    <label class="label">
                        ບໍລິມາດ ( cm )
                    </label>
                    <input 
                        class="input" name="round" 
                        placeholder="ກະລຸນາປ້ອນບໍລິມາດເປັນ cm..." 
                        v-model="packagesize.round" 
                    >
                </div>
            </div>
        </div>
        <div ref="target" id="target" class="center">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :disabled="btnLoading">ເເກ້ໄຂ ຂະໜາດພັດສະດຸ</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import MixinEditPackageSizes from '@coms/../../src/Mixins/Packages/Sizes/Edit';
import {ValidatePackageSize} from '@coms/../../src/Plugins/Validation/Packages/Validation';


export default {

    props:['packagesize'],

    mixins:[MixinEditPackageSizes],

    created(){
        this.FetchData();
        this.CheckPackageType();
        ValidatePackageSize(this);
    }
}
</script>
<style lang="scss" scoped>
    
</style>