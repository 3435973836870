<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{marginTop: '10px'}">
        <img :src="require('@/assets/delete-trash.png')" class="img-delete">
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{fontSize: '18px', color: '#195BFF'}">
        ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນວ່າຕ້ອງການລົບ
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="Cancel()">
          ຢືນຢັນ
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          ຍົກເລີກ
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ["invoiceId"],
  data() {
    return {
      active: false,
      loading: false,
    };
  },

  methods: {
    Cancel() {
      this.loading = true;
      this.$http.delete('delete/shop/invoice/' + this.invoiceId)
          .then((res) => {
            if (!res.data.error) {
              setTimeout(() => {
                this.loading = false;
                this.$emit('close');
                this.$emit('success');
                this.$notification.OpenNotification_confirm_PrizeResult_Success('top-right', 'primary', 3500);
              }, 300);
            } else {
              setTimeout(() => {
                this.loading = false;
                this.$emit('close');
                this.$emit('success');
                this.$notification.OpenNotification_confirm_PrizeResult_UnSuccess('top-right', 'danger', 3500);
              }, 300);
            }
          })
          .catch(() => {
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            setTimeout(() => {
              this.loading = false;
              this.$emit('close');
              this.$emit('success');
              this.$notification.OpenNotification_confirm_PrizeResult_UnSuccess('top-right', 'warning', 3000);
            }, 300);
          });
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  }
};
</script>
