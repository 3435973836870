import moment from 'moment';

export const FormatDateTime = (date) => {
    if (date) {
        return moment(String(date)).format('DD/MM/YYYY HH:mm')
    }
}

export const FormatDateTimeBeforeSendToServer = (date) => {
    if (date) {
        return moment(String(date)).format('YYYY-MM-DD')
    }
}

export const FormatNumber = (price, $unit) => {
    if (price) {
        var p = price.toFixed(2).split(".");
        return p[0].split("").reverse().reduce(function (acc, price, i) {
            return price == "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
        }, "") + ($unit != null ? $unit : ' ກີບ');
    }
}

export const FormatBillNumber = (price, $unit) => {
    if (price) {
        var p = price.toFixed(2).split(".");
        return p[0].split("").reverse().reduce(function (acc, price, i) {
            return price == "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
        }, "") + ($unit != null ? ( ' ' + $unit) : ' ບີນ');
    }
}

export const FormatDate = (date) => {
    if (date) {
        return moment(String(date)).format('DD-MM-YYYY')
    }
}
export const numberFormatter = (x) => {
    return x ? x.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : 0
}



