import { Validator } from 'vee-validate';

const validate = {
    custom: {
      name:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ຊື່ບໍ່ຄວນສັ້ນກວ່າ 2 ໂຕ)',
        max: '(ຊື່ບໍ່ຄວນຍາວກວ່າ 225 ໂຕ)',
      },
      tel:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ເບີໂທບໍ່ຄວນສັ້ນກວ່າ 4 ໂຕ)',
        max: '(ເບີໂທບໍ່ຄວນຍາວກວ່າ 13 ໂຕ)',
      },
      email:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        email: '(ອີເມວບໍ່ຖືກຕ້ອງ...)',
        min: '(ອີເມວບໍ່ຄວນສັ້ນກວ່າ 5 ໂຕ)',
        max: '(ອີເມວບໍ່ຄວນຍາວກວ່າ 255 ໂຕ)',
      },
      role:{
        required: '(ກະລຸນາເລືອກກ່ອນ...)',
      },
      userCardid:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        max: '(ລະຫັດບໍ່ຄວນຍາວກວ່າ 255 ໂຕ)',
      }
    }
};

export const ValidateEmployee = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

