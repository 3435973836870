import { Validator } from 'vee-validate';

const validate = {
    custom: {
        name:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        tel:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
            min: '( ເບີໂທບໍ່ຄວນໜ້ອຍກວ່າ 6 ໂຕເລກ... )',
            max: '( ເບີໂທບໍ່ຄວນຫຼາຍກວ່າ 15 ໂຕເລກ... )'
        },
        email:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
            email: '( ອີເມວບໍ່ຖືກຕ້ອງ... )'
        },
        address:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        part:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        province:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        district:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        village:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        prefix:{
            required: '( ກະລຸນາປ້ອນລະຫັດກ່ອນ... )',
            min: '( ລະຫັດບໍ່ຄວນໜ້ອຍກວ່າ 3 ຕົວອັກສອນ... )',
            max: '( ລະຫັດບໍ່ຄວນຫຼາຍກວ່າ 15 ຕົວອັກສອນ... )'
        }
    }
};

export const ValidateDeliveries = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

