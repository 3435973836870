<template>
    <vs-dialog prevent-close v-model="active">
        <slot v-bind="{ close }"/>
    </vs-dialog>
</template>

<script>

export default {
    computed: {
        active: {
            set(val) {
               this.$store.commit('modalPreviewLocation_State', val);
            },
            get() {
                return this.$store.state.modalPreviewLocationState;
            }
        }
    },
    methods:{
        close() {
            this.active = false;
        },
    }
}
</script>
<style lang="scss" scoped>
    .vs-dialog-content{
        background: $white;
    }
</style>