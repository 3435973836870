<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
      </h4>
    </div>

    <div class="box" style="margin-bottom: 12px;margin-top: -12px;">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາເລກບິນເທົ່ານັ້ນ:</label>
              <input
                  class="input"
                  placeholder="ເລກບິນເທົ່ານັ້ນ"
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FetchData()"
              >
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="center table-limit-height">
      <vs-table v-if="Object.keys( listAllOrders ).length !== 0" :loading="loading">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ລາຍລະອຽດບິນ</vs-th>
            <vs-th>ຮູບລາຍເຊັນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ວັນທີຝາກເຄື່ອງ</vs-th>
            <vs-th>ວັນທີແກ້ໄຂບິນ</vs-th>
            <vs-th>ວັນທີເຄື່ອງຮອດ</vs-th>
            <vs-th>ວັນທີຈັດສົ່ງສຳເລັດ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ສ່ວນຫຼຸດ</vs-th>
            <vs-th v-if="$route.query.tab === 'origin_freight_fees'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'destination_freight_fees'">ຄ່າຂົນສົ່ງປາຍທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'origin_freight_fees_cod'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else>ຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ປະກັນໄພ</vs-th>
            <vs-th>ລວມຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ຄ່າຕີກັບ</vs-th>
            <vs-th>ຄ່າຝາກສາງ</vs-th>
            <vs-th>ຄ່າສິນຄ້າ</vs-th>
            <vs-th>ຊື່ຜູ້ສົ່ງ</vs-th>
            <vs-th>ຊື່ຜູ້ຮັບ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ປະເພດການຊຳລະ</vs-th>
            <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
            <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th>
            <vs-th>ຜູ້ຈັດສົ່ງ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    v-if="listAllOrders.shipment_status_id === 3 && $can('SuperAdmin')"
                    circle icon flat
                    @click="OpenModalCancel(listAllOrders.shipment_number)">
                  <i class="fad fa-window-close"></i>
                </vs-button>
                <vs-button circle icon flat
                           @click="$router.push({name: 'list.shipment.detail', params:{id: listAllOrders.id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td
                v-if="listAllOrders.tracking_state_id === 5 && (listAllOrders.shipment_status_id === 2 || listAllOrders.shipment_status_id === 6 || listAllOrders.shipment_status_id === 8)">
              ເຄື່ອງຮອດສາຂາປາຍທາງ
            </vs-td>
            <vs-td v-else>{{ la_status[listAllOrders.shipment_status_name] }}</vs-td>
            <vs-td>{{ listAllOrders.tracking }}</vs-td>
            <vs-td>
              <div v-if="listAllOrders.signature_file" class="img-size">
                <expandable-image
                    class="image"
                    :src=listAllOrders.signature_file
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ listAllOrders.remark }}</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ listAllOrders.shipment_number }} )</vs-td>
            <vs-td v-if="listAllOrders.shipment_status_id === 1">{{ FormatDate(listAllOrders.created_at) }}</vs-td>
            <vs-td v-else>{{ FormatDate(listAllOrders.start_date_actual) }}</vs-td>
            <vs-td v-if="listAllOrders.shipment_status_id !== 1
            && listAllOrders.shipment_status_id !== 2
            && listAllOrders.shipment_status_id !== 3
            && listAllOrders.shipment_status_id !== 11">{{ FormatDate(listAllOrders.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="listAllOrders.tracking_state_id === 5 || listAllOrders.tracking_state_id === 6">
              {{ FormatDate(listAllOrders.end_date_actual) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="listAllOrders.shipment_status_id === 11">
              {{ FormatDate(listAllOrders.end_date_actual) }}
            </vs-td>
            <vs-td v-else-if="listAllOrders.shipment_status_id === 3">
              {{ FormatDate(listAllOrders.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td>{{ listAllOrders.pieces || 0 }}</vs-td>
            <vs-td>{{ listAllOrders.weight || 0 }}</vs-td>
            <vs-td>
              <span v-if="listAllOrders.total_discount_amount !== 0">{{ listAllOrders.total_discount_amount }}ກີບ</span>
              <span v-if="listAllOrders.total_discount_percent !== 0">{{ listAllOrders.total_discount_percent }}%</span>
              <span v-if="listAllOrders.total_discount_amount === 0 && listAllOrders.total_discount_percent === 0">........</span>
            </vs-td>
            <vs-td>( {{ FormatPrice(listAllOrders.sub_total_freight) }} )</vs-td>
            <vs-td>( {{ FormatPrice(listAllOrders.total_insurance_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.reject_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.total_rental_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.total_price) }} )</vs-td>
            <vs-td>{{ listAllOrders.sender_customer_name }}</vs-td>
            <vs-td>{{ listAllOrders.receiver_customer_name }}</vs-td>
            <vs-td>{{ listAllOrders.start_branch_name }}</vs-td>
            <vs-td>{{ listAllOrders.end_branch_name }}</vs-td>
            <vs-td>
              <span v-if="listAllOrders.shipment_pay_type === 'origin_freight_fees'">ເກັບເງິນຕົ້ນທາງ</span>
              <span v-if="listAllOrders.shipment_pay_type === 'destination_freight_fees'">ເກັບເງິນປາຍທາງ</span>
              <span v-if="listAllOrders.shipment_pay_type === 'origin_freight_fees_cod'">ເກັບເງິນຕົ້ນທາງ ( COD )</span>
              <span v-if="listAllOrders.shipment_pay_type === 'destination_freight_fees_cod'">ເກັບເງິນປາຍທາງ ( COD + CDC )</span>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              ( {{ listAllOrders.shipment_type }} )
            </vs-td>
            <vs-td>{{ listAllOrders.created_by_name }}</vs-td>

            <vs-td v-if="listAllOrders.shipment_status_id === 3">{{ listAllOrders.sign_name }}</vs-td>
            <vs-td v-else></vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Loading v-if="loading"/>
        </template>
      </vs-table>
      <vs-table v-else></vs-table>
    </div>
    <ModalCancel>
      <template v-slot="{ close }">
        <cancelSuccessOrder @close="close"
                            :shipment_number="shipment_number"
                            @success="FetchData()"/>
      </template>
    </ModalCancel>
  </div>
</template>

<script>

import Loading from '@/views/Loading';
import MixinBranchListOrders from '@/Mixins/Shipments/Solve-Shipment';
import cancelSuccessOrder from "@views/Accounting/Shipments/Dialog/CancelSuccessOrder";

export default {
  components: {
    Loading,
    cancelSuccessOrder
  },

  props: ['onclickEvent'],

  mixins: [MixinBranchListOrders],

  methods: {
    OpenModalCancel(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalCancel_State", true);
    },
  },
  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.listAllOrders = {};
        this.searchItem = '';
        this.FetchData();
      }
    }
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}

.table-limit-height {
  .vs-table {
    width: 100%;
    font-size: 0.9rem;
    margin: 0;
    max-height: 500px;
  }
}

</style>
