<template>
  <div>
    <div class="box" style="margin-top: -24px; padding: 10px">
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="startDate"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="endDate"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຄົ້ນຫາຂໍ້ມູນ:</label>
            <button
                class="button btn-export-sum"
                :style="{width: '50%'}"
                @click="fetchData()">
              ຄົ້ນຫາ
            </button>
          </div>
        </div>
        <div class="column is-12" :style="{textAlign: 'center'}">
          <button v-if="status === 'pending'" ref="button"
                  class="button btn-export-sum"
                  :style="{marginLeft: '10px'}"
                  @click="exportExcelNoteRequestCod()">
            Export - ລາຍການລູກຄ້າບໍ່ກົດສະຫຼຸບຍອດ
          </button>
          <button
              v-if="status === 'pending'"
              class="button btn-export-sum"
              :style="{marginLeft: '10px'}"
              @click="openUpdateInvoiceModal()"
              :disabled="!isSelectingOrders">
            ອັບເດດ {{ actualSelectedItems.length }} Invoice
          </button>
          <button
              class="button btn-export-sum"
              :style="{marginLeft: '10px'}"
              @click="exportSummarizeData(actualSelectedItems,startDate)"
              :disabled="!isSelectingOrders">
            Export - {{ actualSelectedItems.length }} ( ສະຫຼຸບລວມ )
          </button>
          <button
              class="button btn-export-sum"
              :style="{marginLeft: '10px'}"
              @click="openExportExcelByTotalModal()"
              :disabled="!isSelectingOrders">
            Export - {{ actualSelectedItems.length }} ( ລາຍລະອຽດລວມ )
          </button>
          <button v-if="status === 'pending'" ref="button"
                  class="button btn-export-split"
                  :style="{marginLeft: '10px'}"
                  @click="exportExcelTransaction(actualSelectedItems,startDate)"
                  :disabled="!isSelectingOrders">
            Export - {{ actualSelectedItems.length }} ( IBank )
          </button>
        </div>
      </div>
      <hr :style="{marginTop: '-5px'}">
      <div class="center" :style="{marginTop: '-10px'}">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ລວມເປັນເງິນທັງໝົດ</vs-th>
              <vs-th>ລວມຄ່າທຳນຽມໂອນທີ່ໄດ້ຈາກລູກຄ້າ</vs-th>
              <vs-th>ລວມຄ່າທຳນຽມ COD</vs-th>
              <vs-th>ລວມຄ່າທຳນຽມ</vs-th>
              <vs-th>ຄ່າສິນຄ້າຫຼັງ (ບກ)</vs-th>
              <vs-th>ລວມລູກຄ້າ</vs-th>
              <vs-th>ລວມບິນ</vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :is-selected="true">
              <vs-td>
                <span v-if="sum_sub_total_price === 0">0 ກີບ</span>
                <span v-else>{{ FormatPrice(sum_sub_total_price) }}</span>
              </vs-td>
              <vs-td>
                <span v-if="sum_transfer_fee === 0">0 ກີບ</span>
                <span v-else>{{ FormatPrice(sum_transfer_fee) }}</span>
              </vs-td>
              <vs-td>
                <span v-if="sum_cod_fee === 0">0 ກີບ</span>
                <span v-else>{{ FormatPrice(sum_cod_fee) }}</span>
              </vs-td>
              <vs-td>
                <span v-if="sum_total_fee === 0">0 ກີບ</span>
                <span v-else>{{ FormatPrice(sum_total_fee) }}</span>
              </vs-td>
              <vs-td>
                <span v-if="sum_total_price === 0">0 ກີບ</span>
                <span v-else>{{ FormatPrice(sum_total_price) }}</span>
              </vs-td>
              <vs-td>
                <span v-if="count === 0">0 ຄົນ</span>
                <span v-else>{{ count }} ຄົນ</span>
              </vs-td>
              <vs-td>
                <span v-if="sum_qty === 0">0 ບິນ</span>
                <span v-else>{{ sum_qty }} ບິນ</span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="center table-limit-height" style="margin-top: -15px;">
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ວັນທີກົດ</vs-th>
            <vs-th v-if="status === 'pending'">ວັນທີທີ່ຈະໂອນ</vs-th>
            <vs-th v-else>ວັນທີໂອນສຳເລັດ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ເລກອິນວອຍ</vs-th>
            <vs-th>ຊື່ລູກຄ້າ</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ຊື່ບັນຊີ (ທນຄ)</vs-th>
            <vs-th>ລວມຄ່າບໍລິການ</vs-th>
            <vs-th>ຄ່າສິນຄ້າຫຼັງ (ບກ)</vs-th>
            <vs-th>ຈຳນວນເງິນທັງໝົດ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
                    <vs-tr
                        :key="index"
                        v-for="(item, index) in invoices"
                        :is-selected="isRowSelected(item.id)"
                        :data="item"
                        @selected="onItemCheckBoxChanged(item)">
                      <vs-td>
                        <div class="center con-checkbox">
                          <vs-checkbox danger
                                       :checked-force="isRowSelected(item.id)"
                                       :val="item" @click="onItemCheckBoxChanged(item)">
                            <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                          </vs-checkbox>
                        </div>
                      </vs-td>
                      <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
                      <vs-td v-if="item.estimate_receive_date && status === 'pending'">{{ FormatDate(item.estimate_receive_date) }}</vs-td>
                      <vs-td v-else>{{FormatDate(item.paid_at)}}</vs-td>
                      <vs-td>{{ la_status[item.status] }}</vs-td>
                      <vs-td>{{ item.invoice_number }}</vs-td>
                      <vs-td>{{ item.customer.name }}</vs-td>
                      <vs-td>{{ item.customer.surname }}</vs-td>
                      <vs-td>{{ item.customer.account_name }}</vs-td>
                      <vs-td>{{ FormatPrice(item.total_fee) || '0 ກີບ' }}</vs-td>
                      <vs-td>{{ FormatPrice(item.total_price) }}</vs-td>
                      <vs-td>{{ FormatPrice(item.sub_total_price) }}</vs-td>
                      <vs-td>{{ item.remark }}</vs-td>
                    </vs-tr>
        </template>
        <template #footer>
          <Pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="fetchData"/>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <vs-dialog prevent-close v-model="modalActive.editCustomerCodStatusModal">
      <EditRequestedCustomerCODStatusModal @close="closeModal('editCustomerCodStatusModal')"
                                           :invoiceIds="actualSelectedItems"
                                           @success="afterEdit"/>
    </vs-dialog>

    <vs-dialog prevent-close v-model="modalActive.exportTotalListCustomerCodModal">
      <ExportTotalListRequestedGenericCODModal @close="closeModal('exportTotalListCustomerCodModal')"
                                               :invoiceIds="actualSelectedItems"
                                               @success="exportSuccess"/>
    </vs-dialog>

  </div>
</template>

<script>
import ListRequestedGenericCOD
  from "@/views/Accounting/Customer/COD/Generic/Controllers/ListRequestedGenericCODController";
import DatePicker from "vue2-datepicker";
import Loading from '@/views/Loading';
import EditRequestedCustomerCODStatusModal
  from '@/views/Accounting/Customer/COD/Generic/Components/Modals/EditRequestedGenericCODStatus.vue';
import ExportTotalListRequestedGenericCODModal
  from '@/views/Accounting/Customer/COD/Generic/Components/Modals/ExportTotalListRequestedGenericCOD.vue';
import Pagination from '@/Plugins/Paginations/Pagination';

export default {
  mixins: [ListRequestedGenericCOD],

  components: {
    DatePicker,
    Loading,
    Pagination,
    ExportTotalListRequestedGenericCODModal,
    EditRequestedCustomerCODStatusModal,
  },

  created() {
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.box-summary {
  .box-total-price {
    border: 1px solid $crimson;
    // background: $crimson;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
  }

  .box-total-amount {
    border: 1px solid $primary-color;
    // background: $primary-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .box-total-shipment-bill {
    // background: $sub-color;
    border: 1px solid $sub-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }
}
</style>
