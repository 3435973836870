<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນລູກຄ້າ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
          <div class="columns">
           <label style="margin-top: 15px; margin-right: 10px" class="label">
              ຄົ້ນຫາ
            </label>
            <input
                style="margin-top: 4px; margin-right: 4px"
                class="input"
                type="text"
                name="search"
                v-model="search"
            />
          <vs-button circle icon flat @click="OpenModalAdd()"
                     style="padding-left: 8px; padding-right: 8px; margin-right: 8px">
            <i class="fal fa-plus-circle"></i>
          </vs-button>
          </div>
        </span>
      </h4>
    </div>
    <div class="center fit-width">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th style="width: 60px">ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ຮູບພາບ</vs-th>
            <vs-th>ບ້ານ</vs-th>
            <vs-th>ເມືອງ</vs-th>
            <vs-th>ແຂວງ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="customer"
              v-for="(customer, index) in $vs.getPage(customers, page, max)"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ customer.customer_id }}</vs-td>
            <vs-td>{{ customer.customer_name }}</vs-td>
            <vs-td>{{ customer.customer_surname }}</vs-td>
            <vs-td>{{ customer.user_tel }}</vs-td>
            <vs-td>
              <div v-if="customer.profile_picture" class="img-size">
                <expandable-image
                    class="image"
                    :src=customer.profile_picture
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ customer.village_name }}</vs-td>
            <vs-td>{{ customer.district_name }}</vs-td>
            <vs-td>{{ customer.province_name }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    circle
                    icon
                    flat
                    @click="OpenModalEdit(customer.id)"
                >
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddCustomer @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditCustomer
            :listCustomer="listCustomer"
            @close="close"
            @success="FetchData()"
        />
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DelCustomer @close="close" :customerId="customerId" @success="FetchData()"/>
      </template>
    </ModalDelete>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import AddCustomer from "./Dialog/Add";
import EditCustomer from "./Dialog/Edit";
import DelCustomer from "./Dialog/Delete";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';

export default {
  components: {
    Loading,
    AddCustomer,
    EditCustomer,
    DelCustomer,
    pagination
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,

    customers: [],
    listCustomer: {},
    customerId: "",
    search: "",

    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

  }),
  watch: {
    search() {
      this.pagination.current_page = 1;
      this.FetchData();
    }
  },
  methods: {

    filterCus(customerId) {
      return (
          this.customers.filter((item) => {
            return item.id === customerId;
          })[0] || {}
      );
    },

    FetchData() {
      this.isLoading = true;
      this.$http
          .get("list-customer", {
            params: {
              query: this.search,
              type: 'customer',
              page: this.pagination.current_page,
            }
          })
          .then((res) => {
            setTimeout(() => {
              this.isLoading = false;
              this.$emit("close");
            }, 200);
            setTimeout(() => {
              this.customers = res.data.data;
              this.count = res.data.total;
              this.pagination = res.data;
            }, 100);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.LogoutUser();
            }
          });
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },

    OpenModalEdit(customerId) {
      this.listCustomer = {...this.filterCus(customerId)};
      this.$store.commit("modalEdit_State", true);
    },

    OpenModalDelete(customerId) {
      this.customerId = customerId;
      this.$store.commit("modalDelete_State", true);
    },

  },
  created() {
    this.FetchData();
    this.FetchData = this.lodash.debounce(this.FetchData, 500);
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
