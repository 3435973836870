<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-list"></i>
          ລາຍການລູກຄ້າ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div v-if="$route.query.tab === 'confirm'" class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <!--        <div class="column is-2" style="margin-top: 3px">-->
        <!--          <vs-button-->
        <!--              v-if="listAutoApprovedCod.length !== 0"-->
        <!--              ref="button" flat-->
        <!--              @click="exportExcel('all', null)"-->
        <!--              class="form-btn-loading"-->
        <!--              :loading="loading">-->
        <!--            export excel-->
        <!--          </vs-button>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບິໂທ</vs-th>
            <vs-th>ຊື່ບັນຊີ</vs-th>
            <vs-th>ເລກບັນຊີ</vs-th>
            <vs-th>ຜູ້ດຳເນີນການ</vs-th>
            <vs-th>ວັນທີດຳເນີນການ</vs-th>
            <vs-th>ໂປຣຟາຍ</vs-th>
            <vs-th>ບັດປະຈຳຕົວ</vs-th>
            <vs-th>ພາສປອດ</vs-th>
            <vs-th>ສຳມະໂນຄົວ</vs-th>
<!--            <vs-th>ເອກະສານ</vs-th>-->
            <vs-th>ປຶ້ມທະນາຄານ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="autoApproved"
              v-for="(autoApproved, index) in listAutoApprovedCod">
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    v-if="autoApproved.status !== 'confirm'"
                    circle icon flat
                    @click="OpenModalEdit(autoApproved)">
                  <i class="fad fa-pen"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td v-if="autoApproved.description">{{ autoApproved.description }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ autoApproved.customer_name }}</vs-td>
            <vs-td>{{ autoApproved.tel }}</vs-td>
            <vs-td>{{ autoApproved.account_name }}</vs-td>
            <vs-td>{{ autoApproved.account_number }}</vs-td>
            <vs-td v-if="autoApproved.confirm_by">{{ autoApproved.confirm_by }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td v-if="autoApproved.status === 'pending'">
              {{ moment(autoApproved.created_at).format('DD/MM/YYYY HH:mm') }}
            </vs-td>
            <vs-td v-else-if="autoApproved.status === 'confirm'">
              {{ moment(autoApproved.confirm_at).format('DD/MM/YYYY HH:mm') }}
            </vs-td>
            <vs-td v-else>{{ moment(autoApproved.updated_at).format('DD/MM/YYYY HH:mm') }}</vs-td>
            <vs-td>
              <div v-if="autoApproved.profile_picture && !isDocFile(autoApproved.profile_picture) && autoApproved.profile_picture !== defaultImage" class="img-size">
                <expandable-image
                    class="image"
                    :src=autoApproved.profile_picture
                ></expandable-image>
              </div>
              <vs-button v-else-if="autoApproved.profile_picture && isDocFile(autoApproved.profile_picture)" circle icon
                         flat
                         @click="download(autoApproved.profile_picture)">
                <i class="fal fa-download"></i>
              </vs-button>
            </vs-td>
            <vs-td>
              <div v-if="autoApproved.id_card && !isDocFile(autoApproved.id_card)" class="img-size">
                <expandable-image
                    class="image"
                    :src=autoApproved.id_card
                ></expandable-image>
              </div>
              <vs-button v-else-if="autoApproved.id_card && isDocFile(autoApproved.id_card)" circle icon flat
                         @click="download(autoApproved.id_card)">
                <i class="fal fa-download"></i>
              </vs-button>
            </vs-td>
            <vs-td>
              <div v-if="autoApproved.passport && !isDocFile(autoApproved.passport)" class="img-size">
                <expandable-image
                    class="image"
                    :src=autoApproved.passport
                ></expandable-image>
              </div>
              <vs-button v-else-if="autoApproved.passport && isDocFile(autoApproved.passport)" circle icon flat
                         @click="download(autoApproved.passport)">
                <i class="fal fa-download"></i>
              </vs-button>
            </vs-td>
            <vs-td>
              <div v-if="autoApproved.family_book && !isDocFile(autoApproved.family_book)" class="img-size">
                <expandable-image
                    class="image"
                    :src=autoApproved.family_book
                ></expandable-image>
              </div>
              <vs-button v-else-if="autoApproved.family_book && isDocFile(autoApproved.family_book)" circle icon flat
                         @click="download(autoApproved.family_book)">
                <i class="fal fa-download"></i>
              </vs-button>
            </vs-td>
<!--            <vs-td>-->
<!--              <div v-if="autoApproved.agreement && !isDocFile(autoApproved.agreement)" class="img-size">-->
<!--                <expandable-image-->
<!--                    class="image"-->
<!--                    :src=autoApproved.agreement-->
<!--                ></expandable-image>-->
<!--              </div>-->
<!--              <vs-button v-else-if="autoApproved.agreement && isDocFile(autoApproved.agreement)" circle icon flat-->
<!--                         @click="download(autoApproved.agreement)">-->
<!--                <i class="fal fa-download"></i>-->
<!--              </vs-button>-->
<!--            </vs-td>-->
            <vs-td>
              <div v-if="autoApproved.bank_account_book && !isDocFile(autoApproved.bank_account_book)" class="img-size">
                <expandable-image
                    class="image"
                    :src=autoApproved.bank_account_book
                ></expandable-image>
              </div>
              <vs-button v-else-if="autoApproved.bank_account_book && isDocFile(autoApproved.bank_account_book)" circle icon flat
                         @click="download(autoApproved.bank_account_book)">
                <i class="fal fa-download"></i>
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <ModalEdit>
      <template v-slot="{ close }">
        <EditStatus @close="close"
                    :autoApproved="autoApproved"
                    :tabQuery="$route.query.tab"
                    @success="FetchData()"/>
      </template>
    </ModalEdit>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/CusorPagination';
import DatePicker from "vue2-datepicker";
import moment from "moment";
import EditStatus from "@views/SuperAdmin/AccountantCod/AutoApprovedCod/Dialog/EditStatus";

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    EditStatus
  },
  props: ['onclickEvent'],
  data: () => ({
    autoApproved_status: '',
    autoApproved: {},
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    listAutoApprovedCod: [],
    // Pagination
    count: 0,
    total: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    defaultImage: 'https://hal.hal-logistics.la/images/customers/default.png',
    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    download(filename) {
      const onlyFileName = filename.split('/').pop();
      this.$http.get(`download/${onlyFileName}`, {responseType: 'blob'})
          .then((response) => {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.setAttribute('download', filename)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
          }).catch((e) => console.log(e))
    },
    exportExcel(id, shop_name) {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post(`export/shop/autoApproved/${id}`, {
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.autoApproved_status
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (id === 'all') {
          if (this.start_date === this.end_date) {
            fileLink.setAttribute('download', 'ລາຍການອິນວອຍຮ້ານຄ້າທັງໝົດວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
          } else {
            fileLink.setAttribute('download', 'ລາຍການອິນວອຍຮ້ານຄ້າທັງໝົດວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
          }
        } else {
          if (this.start_date === this.end_date) {
            fileLink.setAttribute('download', `ລາຍການອິນວອຍ ${shop_name} ວັນທິ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
          } else {
            fileLink.setAttribute('download', `ລາຍການອິນວອຍ ${shop_name} ວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
          }
        }

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2021-08-01 00:00:00');
      return date < fixDay;
    },
    OpenModalEdit(autoApprovedId) {
      this.autoApproved = JSON.parse(JSON.stringify(autoApprovedId));
      this.$store.commit('modalEdit_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.listAutoApprovedCod = res.data.data.data.data;
            this.count = res.data.data.totalQty;
            this.pagination = res.data.data.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    isDocFile(name) {
      const ext = this.getFileExtension(name || '');
      return (['doc', 'docx', 'pdf'].includes((ext || '').toLowerCase()));
    },
    getFileExtension(filename) {
      return filename.split('.').pop();
    },
    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/auto/cod/approved', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          status: this.$route.query.tab,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
  watch: {
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
    'onclickEvent': function (value) {
      if (value !== '') {
        this.listAutoApprovedCod = {};
        this.FetchData();
      }
    }
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
