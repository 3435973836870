export default{
    data:()=>({
        btnLoading: false,
        packageSizes: [],
        selectedPackageSize: {},

        server_errors:{
            package_name: '',
            package_price: '',
            packing_box: '',
            level: '',
            is_across: '',
            packageSizeId: '',
        },

        freightTypes: [
            {'id': 1, 'type': 'origin', 'name': 'ຂົນສົ່ງຕົ້ນທາງ'},
            {'id': 2, 'type': 'destination', 'name': 'ຂົນສົ່ງປາຍທາງ'}
        ],

        packages: {},
        Provinces: [],
        checked_Province: [],
        checkedAll_Provice: false,
    }),

    methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditData();
                }
            });
        },

        EditData(){
            this.btnLoading = true;
            this.$axios.put('edit-packages',{
                id: this.packages.id,
                name: this.packages.name,
                price: this.packages.price,
                packing_box: this.packages.packing_box,
                is_across: this.packages.is_across,
                packageSizeId: this.packages.package_size_id,
                ProvinceId: this.checked_Province,
                freightType: this.packages.freight_type
            }).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'tab.list.packages', params:{type: 'all'}}).catch(()=>{});
                    }, 300);
                }
            }).catch((error)=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FetchData(){
            this.$axios.get('list-info-packages').then(res=>{
                const data = res.data;
                this.packageSizes = data.package_sizes;
                this.startParts = data.parts;
                this.Provinces = data.provinces;
            }).catch(()=>{}).finally(()=>{
                this.$axios.get('list-edit-packages/' + this.$route.params.id).then((res)=>{
                    this.packages = res.data.packages;
                    this.checked_Province = res.data.provinceIds;
                }).catch(()=>{});
            });
        },

        CheckAllProvince(){
            this.server_errors.ProvinceId = '';
            this.checked_Province = [];
            if(!this.checkedAll_Provice){
                for (let i in this.Provinces) {
                    this.checked_Province.push(this.Provinces[i].id);
                }
            }
        },
    },
    watch:{
        'checked_Province':function(value){
            this.server_errors.ProvinceId = '';
            if(value.length == this.Provinces.length){
                this.checkedAll_Provice = true;
                if(this.Provinces.length){
                    this.checkedAll_Provice = true;
                }else{
                    this.checkedAll_Provice = false;
                }
            }else{
                this.checkedAll_Provice = false;
            }
        }
    }
}