<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <li
            @click="Tabs('origin')" :class="{'is-active': isActive === 'origin'}">
          <a>Invoices ເກັບເງິນຕົ້ນທາງ</a>
        </li>
        <li @click="Tabs('destination')"
            :class="{'is-active': isActive === 'destination'}">
          <a>Invoices ເກັບເງິນCDC</a>
        </li>
        <li @click="Tabs('cod_branch')"
            :class="{'is-active': isActive === 'cod_branch'}">
          <a>Invoices ເກັບເງິນCOD</a>
        </li>
      </ul>
    </div>

    <listInvoiceBranch :onclickEvent="isActive"/>
  </div>
</template>

<script>
import listInvoiceBranch from "@views/Branch/InvoiceBranch/listInvoiceBranch";

export default {
  components: {
    listInvoiceBranch

  },
  data: () => ({
    isActive: '',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'tab.invoice.branch', query: {tab: value}}).catch(() => {
      });
    }
  },
  created() {
    if (this.$route.query.tab === 'origin') {
      this.isActive = 'origin';
    } else if(this.$route.query.tab === 'destination') {
      this.isActive = 'destination';
    }else {
      this.isActive = 'cod_branch';
    }
  },
}
</script>

<style>

</style>
