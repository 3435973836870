<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເເກ້ໄຂ ຂໍ້ມູນຕິດຕໍ່ພວກເຮົາ
            </h4>
        </template>

        <div class="field" :style="{marginTop: '20px'}">
            <div class="control">
                <label class="label">
                    ຊື່ບໍລິສັດ
                    <span class="text-danger">* {{ errors.first('name') }}</span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input 
                    class="input" name="name" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="listConatct.name" 
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເບີໂທຕິດຕໍ່
                    <span class="text-danger">* {{ errors.first('tel') }}</span>
                    <span class="text-danger">
                        {{server_errors.tel}}
                    </span>
                </label>
                <input 
                    class="input" name="tel" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="listConatct.tel" 
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ອີເມວ
                    <span class="text-danger">* {{ errors.first('email') }}</span>
                    <span class="text-danger">
                        {{server_errors.email}}
                    </span>
                </label>
                <input 
                    class="input" name="email" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="listConatct.email" 
                    v-validate="'required|email|max:255'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເຟສບຸກ
                    <span class="text-danger">* {{ errors.first('website') }}</span>
                    <span class="text-danger">
                        {{server_errors.website}}
                    </span>
                </label>
                <input 
                    class="input" name="website" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="listConatct.website" 
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເຟສບຸກ
                    <span class="text-danger">* {{ errors.first('facebook') }}</span>
                    <span class="text-danger">
                        {{server_errors.facebook}}
                    </span>
                </label>
                <input 
                    class="input" name="facebook" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="listConatct.facebook" 
                    v-validate="'required'"
                >
            </div>  
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ທີ່ຢູ່ບໍລິສັດ
                    <span class="text-danger">* {{ errors.first('address') }}</span>
                    <span class="text-danger">
                        {{server_errors.address}}
                    </span>
                </label>
                <input 
                    class="input" name="address" 
                    placeholder="ກະລຸນາປ້ອນລະຫັດຄູປ໋ອງກ່ອນ..." 
                    v-model="listConatct.address" 
                    v-validate="'required'"
                >
            </div>
        </div>

        <div ref="target" id="target" class="center" :style="{marginTop: '30px'}">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading">ເເກ້ໄຂ ຂໍ້ມູນຕິດຕໍ່ພວກເຮົາ</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidateContactUs } from '@coms/../../src/Plugins/Validation/Validate-Contact-Us';

export default {
    props:['listConatct'],

    data:()=>({
        server_errors:{
            name: '',
            tel: '',
            email: '',
            facebook: '',
            website: '',
            address: '',
        }
    }),
    methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            })
        },
        EditItem(){
            const loading = this.BtnLoading();
            this.$axios.put('edit-contact-us', this.listConatct).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        loading.close();
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(()=>{});
        },
        BtnLoading(){
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        }, // Btn-Loading
    },
    created(){
        ValidateContactUs(this);
    }
}
</script>

<style>

</style>