<template>
    <div>
        <template>
          <h4 class="not-margin" :style="{marginTop: '10px'}">
              <img :src="profile" class="img-profile">
          </h4>
        </template>
    </div>
</template>
<script>
export default {
    props:['profile'],
    
    data:()=>({
     loading: false
    }),
     methods:{
        DeleteItem(profile){
          this.loading = true;
          this.$axios.delete('delete-branches/' + profile).then(res=>{
              if(res.data.success == true){
                  setTimeout(() => {
                    this.loading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
                  }, 300);
              }
          }).catch(()=>{});
        }
    }
}
</script>
<style scoped lang="scss">
.img-profile{
    width: 100%;
    //height: 500px;
}
</style>