<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-list"></i>
          ລາຍການຂາຍເລກ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ງວດວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາງວດວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາ:</label>
            <model-list-select
                :list="listBranches"
                v-model="selectedBranch"
                placeholder="ເລືອກຮ້ານ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະການຖືກລາງວັນ:</label>
            <div class="select">
              <select class="input" v-model="is_win" @change="FetchData()">
                <option value="">ທັງໝົດ</option>
                <option value="1">ຖືກເລກ</option>
                <option value="0">ບໍ່ຖືກເລກ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະບິນ:</label>
            <div class="select">
              <select class="input" v-model="is_success" @change="FetchData()">
                <option value="">ທັງໝົດ</option>
                <option value="1">ລູກຄ້າຊຳລະແລ້ວ</option>
                <option value="0">ລູກຄ້າຍັງບໍ່ທັນຊຳລະ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              v-if="listLotteries.length !== 0"
              ref="button" flat
              @click="exportExcel()"
              class="form-btn-loading"
              :loading="loading">
            export excel
          </vs-button>
        </div>
      </div>
    </div>
    <!--    <hr :style="{marginTop: '-10px'}">-->
    <!--    <div class="center" :style="{marginTop: '-10px'}">-->
    <!--      <vs-table>-->
    <!--        <template #thead>-->
    <!--          <vs-tr>-->
    <!--            <vs-th>ຈຳນວນທັງໝົດ</vs-th>-->
    <!--          </vs-tr>-->
    <!--        </template>-->
    <!--        <template #tbody>-->
    <!--          <vs-tr :is-selected="true">-->
    <!--            <vs-td>{{ FormatPrice(total) }}</vs-td>-->
    <!--          </vs-tr>-->
    <!--        </template>-->
    <!--      </vs-table>-->
    <!--    </div>-->
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເລກບິນ</vs-th>
            <vs-th>ງວດທີ</vs-th>
            <vs-th>ງວດວັນທີ</vs-th>
            <vs-th>ສະຖານະບິນ</vs-th>
            <vs-th>ວັນທີຊຳລະເງິນ</vs-th>
            <vs-th>ຈຳນວນເລກທີ່ຊື້</vs-th>
            <vs-th>ຈຳນວນເງິນທັງໝົດ</vs-th>
            <vs-th>ຖືdກລາງວັນ</vs-th>
            <vs-th>ຈຳນວນເງິນທີ່ຖືກລາງວັນ</vs-th>
            <vs-th>ລູກຄ້າ</vs-th>
            <vs-th>ສາຂາ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="lottery"
              v-for="(lottery, index) in $vs.getPage(listLotteries, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td v-if="lottery.bill_number" :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ lottery.bill_number }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td v-else>ບໍ່ມີ</vs-td>
            <vs-td>{{ lottery.draw_no }}</vs-td>
            <vs-td>{{ moment(lottery.draw_date).format('DD/MM/YYYY') }}</vs-td>
            <vs-td v-if="lottery.is_success === 1">ຊຳລະແລ້ວ</vs-td>
            <vs-td v-else-if="lottery.is_success === 0 && lottery.is_cancelled === 1">ຍົກເລີກ</vs-td>
            <vs-td v-else>ຍັງບໍ່ທັນຊຳລະ</vs-td>
            <vs-td v-if="lottery.success_at">{{ moment(lottery.success_at).format('DD/MM/YYYY HH:mm') }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ countOrderSuccessDetail(lottery.order_details) }}</vs-td>
            <vs-td>{{ FormatPrice(lottery.total_amount) }}</vs-td>
            <vs-td v-if="lottery.is_win === 1">ຖືກເລກ</vs-td>
            <vs-td v-else>ບໍ່ຖືກ</vs-td>
            <vs-td>{{ FormatPrice(lottery.win_total_amount) }}</vs-td>
            <vs-td>{{ lottery.customer.name }} {{ lottery.customer.tel }}</vs-td>
            <vs-td>{{ lottery.bought_from_branch.name }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <!--    <ModalEdit>-->
    <!--      <template v-slot="{ close }">-->
    <!--        <editInvoice @close="close"-->
    <!--                     :invoiceId="invoice_id"-->
    <!--                     @success="FetchData()"/>-->
    <!--      </template>-->
    <!--    </ModalEdit>-->
    <!--    <ModalDelete>-->
    <!--      <template v-slot="{ close }">-->
    <!--        <confirm-delete-invoice @close="close"-->
    <!--                     :invoiceId="invoice_id"-->
    <!--                     @success="FetchData()"/>-->
    <!--      </template>-->
    <!--    </ModalDelete>-->
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {ModelListSelect} from 'vue-search-select';
// import editInvoice from "@views/ShopInvoicePoint/Dialog/editInvoice";
// import confirmDeleteInvoice from "@views/ShopInvoicePoint/Dialog/confirmDeleteInvoice";
export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect
    // editInvoice,
    // confirmDeleteInvoice
  },

  data: () => ({
    invoice_status: '',
    invoice_id: '',
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    listLotteries: [],
    listMainShop: [],
    selectedMainShop: '',
    listBranches: [],
    selectedBranch: '',
    is_win: '',
    is_success: '',
    // Pagination
    count: 0,
    total: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    // exportExcel(id, shop_name) {
    //   const loading = this.BtnLoading();
    //   this.loading = true;
    //   this.$http.post(`export/shop/invoice/${id}`, {
    //     start_date: this.start_date,
    //     end_date: this.end_date,
    //     status: this.invoice_status
    //   }, {responseType: 'blob'}).then((res) => {
    //     const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
    //     const fileLink = document.createElement('a');
    //     fileLink.href = fileUrl;
    //     if (id === 'all') {
    //       if (this.start_date === this.end_date) {
    //         fileLink.setAttribute('download', 'ລາຍການອິນວອຍຮ້ານຄ້າທັງໝົດວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
    //       } else {
    //         fileLink.setAttribute('download', 'ລາຍການອິນວອຍຮ້ານຄ້າທັງໝົດວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
    //       }
    //     } else {
    //       if (this.start_date === this.end_date) {
    //         fileLink.setAttribute('download', `ລາຍການອິນວອຍ ${shop_name} ວັນທິ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
    //       } else {
    //         fileLink.setAttribute('download', `ລາຍການອິນວອຍ ${shop_name} ວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
    //       }
    //     }
    //
    //     document.body.appendChild(fileLink);
    //     fileLink.click();
    //     document.body.removeChild(fileLink);
    //     this.loading = false;
    //     loading.close()
    //   }).catch(() => {
    //     this.loading = false;
    //     loading.close()
    //   });
    // },

    countOrderSuccessDetail($detail) {
      let count = 0;
      if (!Array.isArray($detail)) {
        return count;
      }

      $detail.forEach(function (item) {
        if (item.is_active === 1) {
          count++;
        }
      })
      return count;
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2021-08-01 00:00:00');
      return date < fixDay;
    },
    OpenModalEdit(invoiceId) {
      this.invoice_id = JSON.parse(JSON.stringify(invoiceId));
      this.$store.commit('modalEdit_State', true);
    },
    OpenModalDelete(invoiceId) {
      this.invoice_id = JSON.parse(JSON.stringify(invoiceId));
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.listLotteries = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
        }, 300);
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    FetchListBranch() {
      this.$http.get('v1/listing/' + 'branches' + '?type=' + '&include=excluded').then((res) => {
        this.listBranches = res.data;
        this.listBranches.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('v1/auth/users/me/third-party-services/lottery-system/services/customer-lottery-orders', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          lottery_system_service_provider: 'NAKRALOTTOSystemServiceProvider',
          is_440: "0",
          is_win: this.is_win,
          is_success: this.is_success,
          use_paginator: true,
          bought_from_id: this.selectedBranch,
          bought_from_type: 'branch',
          page: current_page ?? this.pagination.current_page,
          limit: per_page ?? this.limit_page,
        }
      })
    },

    exportExcel() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('print/lottery/histories', {
        start_date: this.start_date,
        end_date: this.end_date,
        lottery_system_service_provider: 'NAKRALOTTOSystemServiceProvider',
        is_440: "0",
        is_win: this.is_win,
        is_success: this.is_success,
        use_paginator: true,
        bought_from_id: this.selectedBranch,
        bought_from_type: 'branch',
        is_export: 1
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ລາຍການຊື້ຫວຍງວດວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ລາຍການຊື້ຫວຍງວດວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
    this.FetchListBranch();
  },
  watch: {
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
    'selectedBranch': function () {
      this.FetchData();
    },
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
