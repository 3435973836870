<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <gmap-autocomplete
                    ref="searchInput"
                    class="input"
                    placeholder="ຄົ້ນຫາເເຜນທີ່..."
                    @place_changed="setPlace"
                    :options="{fields: ['geometry', 'formatted_address', 'name']}">
                </gmap-autocomplete>
                <span class="horizontal-divider"></span>
            </div>
            <div class="column is-12">
                <GmapMap
                    :center="center"
                    :zoom="16"
                    style="width:100%; height:450px;"
                >
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center=m.position"
                    :draggable="true"
                    @dragend="onLocation"
                    :icon="markerOptions"
                    :animation="2"
                    ref="markers"
                />
                </GmapMap>
            </div>
            <div class="column is-12" :style="{display: 'flex'}">
                 <div ref="target" id="target" class="center">
                    <div class="con-btns">
                        <vs-button ref="button" flat class="form-btn-loading" @click="GetLocations()">
                            <i class="fal fa-location" :style="{width: '35px'}"></i>
                            ຢືນຢັນ ເເຜນທີ່
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data:()=>({
        // default to Montreal to keep it simple
        // change this to whatever makes sense
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,
    }),
    methods:{
        GetLocations(){
            this.$emit('close');
            this.$emit('location', this.center);
        },
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },
        // receives a place object via the autocomplete component
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({ position: marker });
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({ position: marker });
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });
        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },
    },
    mounted() {
        // console.log(this.location);
        if(this.location.lat == null && this.location.lng == null){
            this.geolocate();
        }else{
            this.center.lat = this.location.lat;
            this.center.lng = this.location.lng;
            this.placeMarker();
        }
    },
    computed:{
        location:{
            set(val) {
               this.$store.commit('setLocation', val);
            },
            get() {
                return this.$store.state.LanLng;
            }
        }
    }
}
</script>

<style>

</style>