export const GetOldValueOnInput = (context) => {
    if (context.searchItem !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.searchItem) {
                context.oldVal = context.searchItem;        // set old value
                context.pagination.current_page = 1;    // set default current_page = 1;
                context.FetchData();            // filter from server
            }
        } else {
            if (context.searchItem !== '') {
                context.pagination.current_page = 1;
                context.FetchData();
            }
            context.oldVal = context.searchItem;
        }
    } else {
        context.oldVal = '';
    }
}

export const VillageGetOldValueOnInput = (context) => {
    if (context.searchResult !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.searchResult) {
                context.oldVal = context.searchResult;
                context.pagination.current_page = 1;
                context.FetchVillages();
            }
        } else {
            if (context.searchResult !== '') {
                context.pagination.current_page = 1;
                context.FetchVillages();
            }
            context.oldVal = context.searchResult;
        }
    } else {
        context.oldVal = '';
    }
}

export const BranchGetValueOnInput = (context) => {
    if (context.searchResult !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.searchResult) {
                context.oldVal = context.searchResult;
                context.pagination.current_page = 1;
                context.FetchData();
            }
        } else {
            if (context.searchResult !== '') {
                context.pagination.current_page = 1;
                context.FetchData();
            }
            context.oldVal = context.searchResult;
        }
    } else {
        context.oldVal = '';
    }
}

export const AccountingGetValueOnInput = (context) => {
    if (context.shipment_number !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.shipment_number) {
                context.oldVal = context.shipment_number;
                context.pagination.current_page = 1;
                context.FetchData();
            }
        } else {
            if (context.shipment_number !== '') {
                context.pagination.current_page = 1;
                context.FetchData();
            }
            context.oldVal = context.shipment_number;
        }
    } else {
        context.oldVal = '';
    }
}

export const AccountingGetValueOnInputCompany = (context) => {
    if (context.shipment_number !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.shipment_number) {
                context.oldVal = context.shipment_number;
                context.pagination.current_page = 1;
                context.FetchDataCompany();
            }
        } else {
            if (context.shipment_number !== '') {
                context.pagination.current_page = 1;
                context.FetchDataCompany();
            }
            context.oldVal = context.shipment_number;
        }
    } else {
        context.oldVal = '';
    }
}

export const GetValueOnInput = (context) => {
    if (context.searchResult !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.searchResult) {
                context.oldVal = context.searchResult;
                context.pagination.current_page = 1;
                context.FetchData();
            }
        } else {
            if (context.searchResult !== '') {
                context.pagination.current_page = 1;
                context.FetchData();
            }
            context.oldVal = context.searchResult;
        }
    } else {
        context.oldVal = '';
    }
}

export const accountingGetValueOnInput = (context) => {
    if (context.shipment_number !== '') {
        if (context.oldVal !== '') {
            if (context.oldVal !== context.shipment_number) {
                context.oldVal = context.shipment_number;
                context.pagination.current_page = 1;
                context.FetchData();
            }
        } else {
            if (context.shipment_number !== '') {
                context.pagination.current_page = 1;
                context.FetchData();
            }
            context.oldVal = context.shipment_number;
        }
    } else {
        context.oldVal = '';
    }
}


