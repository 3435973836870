<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px' }">
                <i class="fal fa-info-circle" :style="{ width: '30px', fontSize: '18px' }"></i>
                ຂໍ້ມູນ ບົດບາດຂອງຜູ້ໃຊ້ລະບົບ
            </h4>
        </template>

        <div class="field" :style="{marginTop: '25px'}">
            <div class="control">
                <label class="label">
                    ຊື່ບົດບາດ
                    <span class="text-danger">
                        * {{ errors.first('name') }}
                    </span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input 
                    class="input" name="name" 
                    placeholder="ກະລຸນາປ້ອນກ່ອນ..." 
                    v-model="listRole.name" 
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    displayName 
                    <span class="text-danger">
                        * {{ errors.first('displayName') }}
                    </span>
                    <span class="text-danger">
                        {{server_errors.display_name}}
                    </span>
                </label>
                <input 
                    class="input" name="displayName" 
                    placeholder="display name..." 
                    v-model="listRole.display_name" 
                    v-validate="'required'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    description
                </label>
                <textarea 
                    class="textarea has-fixed-size" 
                    rows="3" 
                    placeholder="description..." 
                    v-model="listRole.description" 
                >
                </textarea>
            </div>
        </div>
        <div ref="target" id="target" class="center">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :disabled="btnLoading">ເເກ້ໄຂ ບົດບາດ</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    name:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
    },
    displayName:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
    },
    description:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
    }
  }
};
Validator.localize('en', dict);


export default {
    props:[
        'listRole'
    ],
    data:()=>({
        active: true,
        server_errors: {
            display_name: '',
            name: '',
            description: '',
        },
        btnLoading: false,
    }),
     methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditData();
                }
            });
        },
        EditData(){
            const loading = this.BtnLoading();
            this.btnLoading = true;
            this.$axios.put('edit-roles', this.listRole).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        loading.close();
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                loading.close();
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
        BtnLoading(){
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        }
    }
}
</script>