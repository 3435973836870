import {
    FormatDateTime,
    FormatDateTimeBeforeSendToServer,
    FormatNumber,
    FormatBillNumber
} from '@/Helpers/Format';
import * as helper from '../../../Helpers/GetValue';
import moment from "moment";
export default {

    data: () => ({
        checkedAll: false,
        isLoading: true,
        invoices: [],
        pageInvoices: [], //@changed
        invoice_id: '',
        createdDate: moment(new Date()).format('YYYY-MM-DD'),
        listCustomers: [],
        selectedCustomer: '',
        summary: {},
        status: [
            {value: '', name: 'ທັງໝົດ'},
            {value: 'pending', name: 'ກຳລັງດຳເນີນການ'},
            {value: 'success', name: 'ສຳເລັດ'},
            {value: 'fail', name: 'ລົ້ມເຫຼວ'}
        ],

        la_status: {
            pending: 'ກຳລັງດຳເນີນການ',
            success: 'ສຳເລັດ',
            fail: 'ລົ້ມເຫຼວ',
        },

        selectedStatus: {
            value: '', name: 'ເລືອກທັງໝົດ'
        },
        //export
        actualSelectedItems: [],
        isSelecting: false,

        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},

        shipment_number: '',
        oldVal: '',
    }), // Data

    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },

        FilterShipmentNumber() {
            helper.AccountingGetValueOnInput(this);
        },
        FilterStatus() {
            this.FetchData();
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('list-invoice/company', {
                params: {
                    shipment_number: this.shipment_number,
                    date: FormatDateTimeBeforeSendToServer(this.createdDate),
                    customer: this.selectedCustomer,
                    invoice_status: this.selectedStatus.value,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page
                }
            })
        },

        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.pagination = res.data.invoices;
                    this.invoices = res.data.invoices.data;
                    this.summary = res.data.summary;
                    this.count = res.data.invoices.total;
                    this.markSelectedItemsOnPageChanges(this.invoices);
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        FetchCustomers() {
            this.$http.get('list-invoice/company', {
                params: {
                    date: FormatDateTimeBeforeSendToServer(this.createdDate),
                }
            }).then((res) => {
                setTimeout(() => {
                    this.listCustomers = res.data.list_customers;
                    this.listCustomers.unshift({
                        id: "",
                        name: 'ທັງໝົດ'
                    });
                }, 300);
            }).catch(() => {

            });
        },
        OpenModalPrintInvoiceCOD_Split() {
            this.$store.commit('modalDelete_State', true);
        },
        ExportExcel_By_Sum() {
            this.$store.commit('modalEdit_State', true);
        },
        EditStatementOrigin(invoiceId) {
            invoiceId.paid_type = this.$route.query.tab;
            this.invoice_id = (JSON.parse(JSON.stringify(invoiceId)));
            this.$store.commit('modalPreviewFile_State', true);
        },
        FormatDate(value) {
            return FormatDateTime(value);
        },
        FormatPrice(price) {
            return FormatNumber(price);
        },
        FormatBillNumber(price) {
            return FormatBillNumber(price, this.$t('common.ບີນ'));
        },
        ExportSuccess() {
            this.clearAllChecked(); //@changed
        },
        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) { //@changed
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },
        onCheckAllCheckBoxChanged(event) { //@changed
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if its not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.invoices));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.invoices.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecked();
            }
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },
        isRowSelected(id) { //@changed
            return this.pageInvoices.map((i) => i.id).includes(id);
        },
        clearAllChecked() { //@changed
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },
        /**
         * @EndChecking
         */
    },
    watch: {
        'createdDate': function () {
            this.FetchData();
            this.FetchCustomers();
        },
        'selectedCustomer': function () {
            this.FetchData();
        },
        // @changed - removed
        'shipment_number': function (value) {
            if (value == '') {
                this.FetchData();
            }
        },
    },
    computed: { //@changed
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && !this.isSelectAllOrders;
        }
    },
}
