<template>
  <div id="target">
    <table>
      <tr style="font-family: 'Roboto', 'BoonBaan', Arial, Helvetica, sans-serif">
        <th>ລຳດັບ</th>
        <th>ລະຫັດ</th>
        <th>ຊື່</th>
        <th>ເບີໂທ</th>
        <th>ບ້ານ</th>
        <th>ເມືອງ</th>
        <th>ແຂວງ</th>
        <th>ສ້າງໂດຍ</th>
        <th>ສາຂາ</th>
        <th>ປະເພດລູກຄ້າ</th>
        <th>ວັນທີສະໝັກລູກຄ້າທົ່ວໄປ</th>
        <th>ວັນທີກາຍເປັນລູກຄ້າ COD</th>
      </tr>
      <tr :key="index" :data="customer" v-for="(customer, index) in value">
        <td>{{ index + 1 }}</td>
        <td>{{ customer.customer_id }}</td>
        <td>{{ customer.name }}</td>
        <td>{{ customer.tel }}</td>
        <td>{{ customer.village }}</td>
        <td>{{ customer.district }}</td>
        <td>{{ customer.province }}</td>
        <td>{{ customer.created_by || 'ລົງທະບຽນເອງ' }}</td>
        <td>{{ customer.branch_name || 'ບໍ່ມີ' }}</td>
        <td>{{ customer_type[customer.customer_type] }}</td>
        <td>{{ moment(customer.created_at).format('DD/MM/YYYY HH:mm') }}</td>
        <td v-if="customer.approved_cod_at">
          {{ moment(customer.approved_cod_at).format('DD/MM/YYYY HH:mm') }}
        </td>
        <td v-else>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "CustomerExcelTemplate",
  data() {
    return {
      timer: null,
      value: [],
      customer_type: {
        'general': 'ລູກຄ້າທົ່ວໄປ',
        'vip': 'ລູກຄ້າCOD',
        'company': 'ລູກຄ້າບໍລີສັດ'
      },
    }
  },
  methods: {
    PrintExcel(selected, start_date, end_date) {
      this.value = selected;
      const startDate = moment(start_date).format('DD-MM-YYYY');
      const endDate = moment(end_date).format('DD-MM-YYYY')
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const ws1 = XLSX.utils.table_to_sheet(
            document.getElementById("target"),
            {raw: true}
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, ws1);
        XLSX.writeFile(workbook, "ລາຍງານການລົງທະບຽນລູກຄ້າແຕ່ວັນທີ " + startDate + "ຫາວັນທີ " + endDate + ".xlsx");
      }, 300);
    },
  }
}
</script>

<style scoped>

</style>
