<template>
  <div class="container">
    <div class="box-title">
      <h4 style="margin-bottom: 4px;">
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ສູນຄັດເເຍກ ທີ່ສາມາດເຊື່ອມໂຍງໄປຫາ
        <span class="countTotal">({{ distributionCenterLinks.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()" :disabled="!distributionCenters.length > 0">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
        </span>
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ສູນຄັດເເຍກ</vs-th>
            <vs-th>ການເຊື່ອມຕໍ່</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ທີ່ຢູ່</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in distributionCenterLinks" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ distributionCenterStrictTypes[item.strict_type] }}</vs-td>
            <vs-td>{{ item.tel }}</vs-td>
            <vs-td>{{ item.village_name }}</vs-td>
            <vs-td>
              <vs-button circle icon flat danger
                         @click="OpenModalDelete(item.id)">
                <i class="fad fa-trash-alt"></i>
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <vs-dialog prevent-close v-model="addModalActive">
      <AddDistributionCenterLinks
          @close="closeModal('add')" @success="onSuccess()" :distributionCenters="distributionCenters"/>
    </vs-dialog>

    <vs-dialog prevent-close v-model="deleteModalActive">
      <DeleteDistributionCenterLinks @close="closeModal('delete')" @success="onSuccess()"
                                     :distributionCenterId="distributionCenterId"/>
    </vs-dialog>

  </div>
</template>

<script>
import AddDistributionCenterLinks from './Dialog/Add';
import DeleteDistributionCenterLinks from './Dialog/Delete';
import Loading from '@/views/Loading';

export default {
  components: {
    AddDistributionCenterLinks,
    DeleteDistributionCenterLinks,
    Loading
  },

  data: () => ({
    distributionCenters: [],
    distributionCenterLinks: [],
    distributionCenterId: '',
    isLoading: false,
    distributionCenterStrictTypes:
        {all: 'ຍິງເຄື່ອງເຂົ້າ-ອອກ', inbound: 'ຍິງເຄື່ອງເຂົ້າ', outbound: 'ຍິງເຄື່ອງອອກ'},
    addModalActive: false,
    deleteModalActive: false,
  }),

  methods: {
    closeModal(id) {
      if (id === 'add') {
        this.addModalActive = false;
      } else {
        this.deleteModalActive = false;
      }
    },
    OpenModalAdd() {
      this.addModalActive = true;
    },
    OpenModalDelete(distributionCenterId) {
      this.distributionCenterId = distributionCenterId;
      this.deleteModalActive = true;
    },
    FetchDistributionCenter() {
      this.$axios.get('get-distribution-center-links/' + this.$route.params.id).then(res => {
        setTimeout(() => {
          this.distributionCenters = res.data.listDistributionCenters;
        }, 100);
      }).catch(() => {
      });
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-distribution-center-links/' + this.$route.params.id).then((res) => {
        setTimeout(() => {
          this.isLoading = false;
          this.distributionCenterLinks = res.data.listDistributionCenterLinks;
        }, 300);
      }).catch(() => {
      });
    },
    onSuccess() {
      this.FetchDistributionCenter();
      this.FetchData();
    }
  },
  created() {
    this.FetchData();
    this.FetchDistributionCenter();
  }

}
</script>
