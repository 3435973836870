<template>
  <div id="welcome" class="section">
    <div class="columns login-section">
      <div class="column is-half is-offset-one-quarter register">
        <div class="head">
          <h1 class="title is-4 has-text-centered">ລົງທະບຽນເຂົ້າຮ່ວມງານ</h1>
        </div>
        <div class="card register">
          <div class="image-event">
            <img alt="event-image" :src="require('@/assets/hal-register.jpg')" />
          </div>
          <div class="card-content">
            <div>
              <p>
                ລົງທະບຽນເຂົ້າຮ່ວມງານຈັບສະຫຼາກແຈກໂຊກໃຫຍ່ຜູ້ໂຊກດີປະຈຳປີ 2020
                ຂອງບໍລິສັດຮຸ່ງອາລຸນຂົນສົ່ງດ່ວນ
              </p>
              <div class="columns">
                <div class="column">
                  <button class="button btn" @click="Register()">
                    ລົງທະບຽນ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  props: {
    msg: String,
  },
  methods: {
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.8",
        background: "primary",
        opacity: 1,
        color: "#E13530",
      });
    },
    Register() {
      const loading = this.BtnLoading();
      setTimeout(() => {
        loading.close();
        this.$router.push({
          name: "hal.register",
        });
      }, 500);
    },
  },

  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.section {
  padding: 0 0.75rem;

  .image-event {
    display: flex;
    align-items: center;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    background-color: #e21312;
    max-height: 60vh;
    overflow: hidden;
  }

  .image-event img {
    margin: auto;
    max-height: 60vh;
    @media screen and (max-width: 380px) and (min-width: 351px){
      max-height: 56vh;
    }

    @media screen and (max-width: 350px) {
      max-height: 52vh;
    }

  }
}
.logo img {
  max-height: 200px;
  min-width: auto;
}

.card {
  box-shadow: none;
}

.btn {
  background: #E11013;
  color: #fff;
  margin-top: 14px;
  border: none;
  border-radius: 70px;
  min-width: 140px;
  font-size: 18px;
  font-weight: 700;
  height: 2.35em;
}

.btn .icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.login-section {
  display: flex;
  min-height: 100vh;
  background-image: url("https://hal-logistics.la/images/slider/bannernews.jpeg");
  background-color: #f5f7f9;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
}

.column.register {
  padding-left: 0px;
  padding-right: 0px;
}

.card.register {
  min-height: 620px;
}

.head {
  background-color: #E11013;
  height: 64px;
  text-align: left;
  display: flex;
  border-bottom: 2px solid #f1f1f1;
}

.has-text-centered {
  margin: auto;
  color: white;
}

</style>