<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເພີ່ມ ສູນຄັດເເຍກແພັກຫາສາຂາໂດຍກົງ
      </h4>
    </template>

    <div class="field" :style="{marginTop: '20px'}">
      <div class="control">
        <div class="control" :style="{marginTop: '5px'}">
          <label class="label">
            ສາຂາທີ່ແພັກຫາໂດຍກົງ
            <span class="text-danger">* {{ errors.first('direct_linked_branch_id') }}</span>
            <span class="text-danger">
                        {{ server_errors.direct_linked_branch_id }}
           </span>
          </label>
          <model-list-select
              v-validate="'required'"
              :list="transformedBranches"
              v-model="direct_linked_branch_id"
              name="direct_linked_branch_id"
              placeholder="ເລືອກສາຂາເຊື່ອມ "
              option-text="name"
              option-value="id"
              selectedItem="name">
          </model-list-select>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">ບັນທຶກ
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import {ValidateDistributionCenter} from '@coms/../../src/Plugins/Validation/Validate-Distribution-Center';
import {ModelListSelect} from 'vue-search-select';


export default {
  components: {
    ModelListSelect,
  },

  props: ['distributionCenters', 'branches', 'activeDistributionCenterId'],

  data: () => ({
    listDistributionCenterLinks: [],
    server_errors: {
      main_distribution_center_id: '',
      direct_linked_branch_id: '',
    },
    value: {},
    main_distribution_center_id: null,
    direct_linked_branch_id: null,
    btnLoading: false,
  }),
  computed: {
    transformedBranches() {
      return this.branches.map((item) => {
        return {
          id: item.id,
          name: `${item.province.prefix}-${item.name}`,
        }
      });
    }
  },
  methods: {
    FetchDistributionCenter() {
      this.isLoading = true;
      this.$http.get('v1/listing/distribution_centers').then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.distributionCenters = res.data;
        }, 100);
      }).catch(() => {
      });
    },
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.btnLoading = true;
      this.$http.post('v1/auth/branches/distribution-center/link-map-data/direct-linked-branches/create', {
        main_distribution_center_id: this.activeDistributionCenterId,
        direct_linked_branch_id: this.direct_linked_branch_id
      }).then(() => {
        this.btnLoading = false;
        this.$emit('close');
        this.$emit('success');
      }).catch((errors) => {
        if (errors.response?.data?.errors) {
          this.$notification.openNotification(
              {
                title: 'ການເພີ່ມຂໍ້ມູນບໍ່ສໍາເລັດ',
                text: 'ບໍ່ສາມາດເພີ່ມໄດ້ ສາຂາທີ່ເຊື່ອມບໍ່ຖືກຕ້ອງ ຫລື ສາຂາຖືກລິ້ງຢູ່ສ່ວນອື່ນ',
              },
              'top-right', 'danger', 5000);
        } else {
          this.$notification.openNotification('ບໍ່ສາມາດເພີ່ມໄດ້ ເກີດຂໍ້ຜິດພາດ.',
              'top-right', 'danger', 5000);
        }
      }).finally(() => {
        this.btnLoading = false;
      });
    }
  },
  watch: {
    'main_distribution_center_id': function () {
      this.server_errors.main_distribution_center_id = '';
    },
    direct_linked_branch_id() {
      this.server_errors.direct_linked_branch_id = '';
    }
  },

  created() {
    ValidateDistributionCenter(this);
  }
}
</script>

<style>

</style>
