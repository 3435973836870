<template>
  <div class="container">
    <div class="columns box-title is-12" style="margin-top: -10px;">
      <div class="column is-6">
        <h4 class="has-text-left" style="margin-top: -26px;">
          <i class="fal fa-list"></i>
          ລາຍລະອຽດ
        </h4>
      </div>

    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th v-if="$route.params.type === 'origin'">ວັນທີສ້າງບິນ</vs-th>
            <vs-th v-else>ວັນທີເຊັນຮັບ</vs-th>
            <vs-th v-if="$route.params.type === 'origin'">ຜູ້ສ້າງບິນ</vs-th>
            <vs-th v-else>ຜູ້ເຊັນຮັບ</vs-th>
            <vs-th>ເລກບິນ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th v-if="$route.params.type !== 'cod_branch'">ປະກັນໄພ</vs-th>
            <vs-th>ລາຄາ</vs-th>
            <vs-th>ຕົ້ນທາງ</vs-th>
            <vs-th>ປາຍທາງ</vs-th>
            <vs-th v-if="$route.params.type !== 'origin'">ສາຂາເຊັນຮັບ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="payment"
              v-for="(payment, index) in $vs.getPage(listPayments, page, max)">
            <vs-td v-if="$route.params.type === 'origin'">
              {{ moment(payment.shipment_payment.shipment.start_date_actual).format('DD/MM/YYYY HH:mm') }}
            </vs-td>
            <vs-th v-else>{{ moment(payment.sign_at).format('DD/MM/YYYY HH:mm') }}</vs-th>
            <vs-td v-if="$route.params.type === 'origin'">{{ payment.shipment_payment.shipment.owner.name }}</vs-td>
            <vs-th v-else>{{ payment.sign_by }}</vs-th>
            <vs-td>{{ payment.shipment_payment.shipment.shipment_number }}</vs-td>
            <vs-td>{{ payment.shipment_payment.shipment.pieces }}</vs-td>
            <vs-td>{{ payment.weight }}</vs-td>
            <vs-td v-if="$route.params.type !== 'cod_branch'">{{ FormatPrice(payment.insurance_price) }}</vs-td>
            <vs-td>{{ FormatPrice(payment.paid) }}</vs-td>
            <vs-td> {{ payment.shipment_payment.shipment.start_branch.village.district.province.prefix }} -
              {{ payment.shipment_payment.shipment.start_branch.name }}
            </vs-td>
            <vs-td>{{ payment.shipment_payment.shipment.end_branch.village.district.province.prefix }} -
              {{ payment.shipment_payment.shipment.end_branch.name }}
            </vs-td>
            <vs-td v-if="$route.params.type !== 'origin'">
              {{ payment.sign_branch }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="fetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/CusorPagination';
import * as helper from "@/Helpers/GetValue";
import moment from "moment";

export default {
  components: {
    Loading,
    pagination,
  },

  data: () => ({
    laStatus: {
      'pending': 'ກຳລັງດຳເນີນການ',
      'success': 'ສຳເລັດ'
    },
    page: 1,
    max: 10,
    isLoading: false,
    listPayments: [],
    listBranches: [],
    selectedBranch: '',
    selectedStatus: '',
    // Pagination
    count: 0,
    totalPaid: 0,
    totalShipment: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),

  methods: {
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      const fixDay = moment('2022-06-01 00:00:00');
      today.setHours(0, 0, 0, 0);
      return date > today || date < fixDay;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2022-06-01 00:00:00');
      return date < fixDay;
    },
    fetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        if (!res.data.error) {
          this.listPayments = res.data.data.data;
          this.pagination = res.data.data;
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('invoice/branch/detail/' + this.$route.params.type + '/' + this.$route.params.id, {
        params: {
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },

  created() {
    this.fetchData()
  },

  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.listPayments = [];
        this.count = 0;
        this.totalShipment = 0;
        this.totalPaid = 0;
        this.pagination = {};
      }
    }
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
