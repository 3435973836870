import { render, staticRenderFns } from "./List-shipment-COD.vue?vue&type=template&id=43101f3f&scoped=true"
import script from "./List-shipment-COD.vue?vue&type=script&lang=js"
export * from "./List-shipment-COD.vue?vue&type=script&lang=js"
import style0 from "./List-shipment-COD.vue?vue&type=style&index=0&id=43101f3f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43101f3f",
  null
  
)

export default component.exports