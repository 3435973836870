<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{marginTop: '10px'}">
        <img src="../../../../../assets/delete-trash.png" class="img-delete">
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{fontSize: '18px', color: '#195BFF'}">
        ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການລຶບຂໍ້ມູນນີ້
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="DeleteItem(vehicleDepartmentId)">
          ຢືນຢັນ
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          ຍົກເລີກ
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  props: ['vehicleDepartmentId'],

  data: () => ({
    loading: false
  }),
  methods: {
    DeleteItem(userId) {
      this.loading = true;
      this.$axios.delete('delete-vehicle-department-users/' + userId).then(res => {
        if (res.data.success) {
            this.$emit('success');
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'success', 3000);
        } else {
          this.$notification.openNotification({
                title: 'ການລົບຂໍ້ມູນບໍ່ສໍາເລັດ',
                text: res.data.msg,
              },
              'top-right', 'danger', 4000);
        }
        this.loading = false;
        setTimeout(() =>  this.$emit('close'), 200);
      }).catch(() => {
      });
    }
  }
}
</script>
<style lang="scss">

</style>
