<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add" :style="{marginTop: '-5px !important'}"
              v-if="$route.query.tab === 'origin_freight_fees'
              || $route.query.tab === 'destination_freight_fees'">
                    ຍອດຄ່າຂົນສົ່ງທັງໝົດ: {{ FormatPrice(totalFreight) }}
        </span>
        <span class="btn-add" :style="{marginTop: '-5px !important'}"
              v-else>
                     ຍອດຄ່າຂົນສົ່ງທັງໝົດ: {{ FormatPrice(totalFreight) }} |  ຍອດຄ່າສິນຄ້າ: {{ FormatPrice(totalPrice) }}
        </span>
        <span class="btn-add">
                    <button class="button  is-danger btn-clear" @click="ClearData()">ລ້າງຂໍ້ມູນ</button>
                </span>
      </h4>
    </div>

    <div class="box" :style="{marginTop: '20px'}">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ເລກທີບິນ:</label>
              <input
                  class="input"
                  placeholder="ເລກທີບິນ..."
                  type="text" name="shipment_number"
                  v-model="searchItem"
                  @keyup.enter="FilterByShipmentNumber()"
              >
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຂົນສົ່ງ:</label>
            <div class="select">
              <select class="input" v-model="selectedDeliverType" @change="FetchData(groupBy = true)">
                <option value="">ທັງໝົດ</option>
                <option value="express">express</option>
                <option value="logistics">logistics</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດສາຂາຕົ້ນທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedStartBranchType" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດສາຂາປາຍທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedEndBranchType" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາຕົ້ນທາງ:</label>
            <model-list-select
                :list="startBranches"
                v-model="selectedStartBranch"
                placeholder="ເລືອກສາຂາຕົ້ນທາງ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາປາຍທາງ:</label>
            <model-list-select
                :list="endBranches"
                v-model="selectedEndBranch"
                placeholder="ເລືອກສາຂາປາຍທາງ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control field">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th v-if="$route.query.tab === 'origin_freight_fees'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'destination_freight_fees'">ຄ່າຂົນສົ່ງປາຍທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'origin_freight_fees_cod'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else>ຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ປະກັນໄພ</vs-th>
            <vs-th>ລວມຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ຄ່າສິນຄ້າ</vs-th>
            <vs-th>ຊື່ຜູ້ສົ່ງ</vs-th>
            <vs-th>ຊື່ຜູ້ຮັບ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ປະເພດການຊຳລະ</vs-th>
            <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
            <vs-th>ທີ່ຢູ່ຜູ້ຮັບ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in listAllOrders" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ item.shipment_number }} )</vs-td>
            <vs-td>{{ FormatDate(item.start_date_actual) }}</vs-td>
            <vs-td>{{ item.qty || 0 }}</vs-td>
            <vs-td>{{ item.weight || 0 }}</vs-td>
            <vs-td>( {{ FormatPrice(item.sub_total_freight) }} )</vs-td>
            <vs-td>( {{ FormatPrice(item.total_freight - item.sub_total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_price) }} )</vs-td>
            <vs-td>{{ item.sender_customer_name }}</vs-td>
            <vs-td>{{ item.receiver_customer_name }}</vs-td>
            <vs-td>{{ item.start_branch_name }}</vs-td>
            <vs-td>{{ item.end_branch_name }}</vs-td>
            <vs-td>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees'">ເກັບເງີນຕົ້ນທາງ</span>
              <span v-if="item.shipment_pay_type === 'destination_freight_fees'">ເກັບເງີນປາຍທາງ</span>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees_cod'">ເກັບເງີນຕົ້ນທາງ ( COD )</span>
              <span v-if="item.shipment_pay_type === 'destination_freight_fees_cod'">ເກັບເງີນປາຍທາງ ( COD )</span>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              ( {{ item.shipment_type }} )
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ item.location }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="$router.push({name: 'list.shipment.detail', params:{id: item.shipment_id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

import Loading from '../../../Loading';
import MixinBranchListPreOrders from '../../../../Mixins/Shipments/List-Shipment-Pre-Order';
import DatePicker from "vue2-datepicker";
import {ModelListSelect} from 'vue-search-select';
import pagination from '../../../../Plugins/Paginations/Pagination';


export default {
  components: {
    Loading,
    DatePicker,
    ModelListSelect,
    pagination
  },

  props: ['onclickEvent'],

  mixins: [MixinBranchListPreOrders],

  created() {
    this.FetchListStartBranch();
    this.FetchListEndBranch();
    this.FetchData();
  },

  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.ClearData();
        this.FetchData();
      }
    }
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}
</style>
