<template>
    <div :style="{textAlign: 'left'}">
        <template>
            <h4 class="not-margin" :style="{marginTop: '10px'}">
                <ul>
                    <li>ເລກທີ່ບີນ: {{OrderRequest.order_id}}</li>
                    <li>ຊື່ລູກຄ້າ: {{OrderRequest.customer_name}}</li>
                    <li>ເບີໂທຕິດຕໍ່: {{OrderRequest.customer_tel}}</li>
                    <li>ຜູ້ຮັບຜິດຊອບ: {{OrderRequest.rider_name !== null ? OrderRequest.rider_name : '..............'}}</li>
                    <hr :style="{marginTop: '5px'}">
                    <li :style="{marginTop: '-15px'}">ປະເພດພັດສະດຸ: {{OrderRequest.categ_name}}</li>
                    <li>ຈຳນວນ: {{OrderRequest.amount}} |
                        <span>ນ້ຳໜັກ: {{OrderRequest.weight}} kg</span>
                    </li>
                    <li>ສະຖານທີ່ໄປຮັບເຄື່ອງ: {{OrderRequest.pro_name}}</li>
                    <li>ສະຖານະ ( ຮັບເຄື່ອງ ) : 
                        <span v-if="OrderRequest.status == 'pending'" :style="{color: '#F57920', textDecoration: 'underline'}">ລໍຖ້າອະນຸມັດ</span>
                        <span v-if="OrderRequest.status == 'confirm'" :style="{color: '#195BFF', textDecoration: 'underline'}">ອະນຸມັດເເລ້ວ</span>
                        <span v-if="OrderRequest.status == 'on_the_way'" :style="{color: 'green', textDecoration: 'underline'}">ກຳລັງໄປຮັບເຄື່ອງ</span>
                        <span v-if="OrderRequest.status == 'success'" :style="{color: '#195BFF', textDecoration: 'underline'}">ສຳເລັດເເລ້ວ</span>
                        <span v-if="OrderRequest.status == 'reject'" :style="{color: 'crimson', textDecoration: 'underline'}">ຖືກປະຕິເສດ</span>
                        <span v-if="OrderRequest.status == 'cancel'" :style="{color: 'crimson', textDecoration: 'underline'}">ຍົກເລີກເເລ້ວ</span>
                    </li>
                </ul>
            </h4>
            <div class="box-map">
                <GmapMap
                    :center="center"
                    :zoom="16"
                    style="width:100%; height:300px;"
                >
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center=m.position"
                    :draggable="true"
                    @dragend="onLocation"
                    :icon="markerOptions"
                    :animation="2"
                    ref="markers"
                />
                </GmapMap>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props:['OrderRequest'],
    
    data:()=>({
        loading: false,
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,
    }),
     methods:{
        DeleteItem(profile){
          this.loading = true;
          this.$axios.delete('delete-branches/' + profile).then(res=>{
              if(res.data.success == true){
                  setTimeout(() => {
                    this.loading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
                  }, 300);
              }
          }).catch(()=>{});
        },

        // Set-Location
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },
        placeMarker() {
            this.markers = [];     
            const marker = {
                lat: this.center.lat,
                lng: this.center.lng,
            };
            this.markers.push({ position: marker });
            this.animateMarker();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        }, // End Set-Location

    },
    created(){
        this.center.lat = this.OrderRequest.lat;
        this.center.lng = this.OrderRequest.lng;
        this.placeMarker();
    }
}
</script>
<style scoped lang="scss">
.img-profile{
    width: 100%;
    //height: 500px;
}
ul{
    li{
        font-size: 15px;
    }
}
.box-map{
    margin-top: 20px;
}
</style>