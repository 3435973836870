import moment from "moment";

export default {
    data: () => ({
        isLoading: true,
        loading: false,
        startDate: moment(new Date()).subtract(5, "months").format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        shipmentIssueStockOrders: [],
        selectedStatus: {id: null, name: ''},
        storeTypes: [{id: 'branch', name: 'ສາຂາ'}, {id: 'distribution_center', name: 'ສູນຄັດແຍກ'}],
        selectedStoreType: {id: 'branch', name: 'ສາຂາ'},
        statuses: [],
        branches: [],
        distributionCenters: [],

        /** pagination */
        offset: 10,
        pagination: {},
        per_page: 10,
        total: 0,
        selectedBranch: {
            id: 'all',
            name: 'ທັງຫມົດ',
        },
        selectedDistributionCenter: {
            id: 'all',
            name: 'ທັງຫມົດ',
        },
        isExporting: false,
        allSelected: {
            id: 'all',
            name: 'ທັງຫມົດ',
        }
    }),
    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        fetchShipmentIssueStocks() {
            this.isLoading = true;
            const isLookingForBranch = this.selectedStoreType.id === 'branch';

            this.$http.get('v1/auth/branches/shipments/orders/checking/shipment-issue-stocks', {
                params: {
                    branch_id: isLookingForBranch ? this.selectedBranch.id : null,
                    distribution_center_id: !isLookingForBranch ? this.selectedDistributionCenter.id : null,
                    issuer_type: this.selectedStoreType.id,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    query: this.searchResult,
                    issue_category: this.selectedStatus.id,
                    issue_status: 'unresolved',
                    page: this.pagination.current_page,
                    use_paginator: true,
                    force_id: 'true',
                    limit: this.per_page,
                }
            }).then((res) => {
                this.isLoading = false;
                this.shipmentIssueStockOrders = res.data.data;
                this.pagination = res.data;
                this.total = this.pagination.total;
            }).catch(() => {
                this.isLoading = false;
            });
        },
        fetchShipmentIssueStocksCategories() {
            this.isLoading = true;
            this.$http.get('v1/listing/issue_stock_categories?includes=all').then((res) => {
                this.statuses = res.data;
                this.selectedStatus = JSON.parse(JSON.stringify(this.statuses[0]));
                this.fetchShipmentIssueStocks();
            }).catch(() => {
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        fetchBranches() {
            this.$http.get('v1/listing/branches').then(res => {
                this.branches = res.data;
                this.branches.unshift(this.allSelected)
                this.selectedBranch = this.allSelected;
            }).catch(() => {
            });
        },
        fetchDistributionCenters() {
            this.$http.get('v1/listing/distribution_centers').then(res => {
                this.distributionCenters = res.data;
                this.distributionCenters.unshift(this.allSelected);
            }).catch(() => {
            });
        },
        exportShipmentIssueStocksToExcel() {
            if(this.isExporting) {
                return;
            }

            this.isExporting = true;
            this.$store.dispatch('exportShipmentIssueStocksToExcel', {
                httpClient: this.$http,
                issue_category: this.selectedStatus.name,
                body: {
                    branch_id: this.selectedBranch.id,
                    distribution_center_id: this.selectedDistributionCenter.id,
                    issuer_type: this.selectedStoreType.id,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    query: this.searchResult,
                    issue_category: this.selectedStatus.id,
                    issue_status: 'unresolved',
                    page: this.pagination.current_page,
                    use_paginator: true,
                    force_id: 'true',
                    limit: this.per_page,
                }
            }).catch(() => {
            }).finally(() => {
                this.isExporting = false;
            })
        },

    },
    created() {
        /// Fetch all data
        this.fetchBranches();
        this.fetchDistributionCenters();
        this.fetchShipmentIssueStocksCategories();
    },
    watch: {
        'startDate': function () {
            this.fetchShipmentIssueStocks();
        },
        'endDate': function () {
            this.fetchShipmentIssueStocks();
        },
        "selectedBranch.id": function (val) {
            if (val != null) {
                this.fetchShipmentIssueStocks();
            }
        },
        "selectedDistributionCenter.id": function (val) {
            if (val != null) {
                this.fetchShipmentIssueStocks();
            }
        }
    }
}
