<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                    ເພີ່ມ ໂປຮໂມຮຊັ່ນ
            </h4>
        </div>

        <div class="box-input">
            <div class="columns is-multiline">
                <div class="column is-6">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                ໂປຮໂມຮຊັ່ນ
                                <span class="text-danger">* {{ errors.first('name') }}</span>
                                <span class="text-danger">
                                    {{server_errors.name}}
                                </span>
                            </label>
                            <input 
                                class="input" name="name" 
                                placeholder="ໂປຮໂມຮຊັ່ນ..." 
                                v-model="value.name" 
                                v-validate="'required'"
                            >
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                ປະເພດສ່ວນຫຼຸດ
                                <span class="text-danger">* {{ errors.first('discountType') }}</span>
                                <span class="text-danger">
                                    {{server_errors.discountType}}
                                </span>
                            </label>
                            <div class="select">
                                <select class="input" v-model="selectedProType.type"
                                    name="discountType" v-validate="'required'">
                                    <option v-for="(proType, index) in promotionTypes" :key="index" :value="proType.type">
                                        {{proType.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                ສ່ວນຫຼຸດພິເສດ
                                <span class="text-danger">* {{ errors.first('discount') }}</span>
                                <span class="text-danger">
                                    {{server_errors.discount}}
                                </span>
                            </label>
                            <currency-input 
                                v-if="selectedProType.type == 'cash'"
                                v-model="value.discount_cash" 
                                class="input" 
                                name="discount_cash"
                                placeholder="ສ່ວນຫຼຸດພິເສດ..." 
                            />
                            <input 
                                class="input" 
                                placeholder="ສ່ວນຫຼຸດພິເສດ..." 
                                v-model="value.discount_percent" 
                                v-else
                            >
                        </div>
                    </div>
                </div>
                <div class="column is-2">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                ວັນໝົດອາຍຸ
                                <span class="text-danger">* {{ errors.first('expired_date') }}</span>
                                <span class="text-danger">
                                    {{server_errors.expired_date}}
                                </span>
                            </label>
                            <vuejs-datepicker 
                                v-model="value.expired_date" 
                                input-class="input"
                                format="d-M-yyyy"
                                name="expired_date"
                                v-validate="'required'"
                            >
                            </vuejs-datepicker>
                        </div>
                    </div>
                </div>

                <div class="column is-3">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                ລະດັບ ໂປຮໂມຮຊັ່ນ
                                <span class="text-danger">* {{ errors.first('level') }}</span>
                                <span class="text-danger">
                                    {{server_errors.level}}
                                </span>
                            </label>
                            <div class="select">
                                <select class="input" v-model="selectedLevel.level"
                                    name="level" v-validate="'required'">
                                    <option v-for="(level, index) in levels" :key="index" :value="level.level">
                                        {{level.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="field">
                        <div class="control">
                            <label class="label" :style="{marginLeft: '15px'}">
                                ຮູບເເບບ ໂປຮໂມຮຊັ່ນ
                                <span class="text-danger">* {{ errors.first('is_across') }}</span>
                                <span class="text-danger">
                                    {{server_errors.is_across}}
                                </span>
                            </label>
                            <div class="columns" :style="{marginTop: '-5px', marginLeft: '-20px'}">
                                <div class="column is-4">
                                    <vs-radio danger v-model="value.is_across" val="true">
                                        {{msgBtn_isAcross1}}
                                    </vs-radio>
                                </div>
                                <div class="column is-4">
                                    <vs-radio danger v-model="value.is_across" val="false">
                                        {{msgBtn_isAcross2}}
                                    </vs-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                Reverse Promotion
                                <span class="text-danger">* {{ errors.first('reverse') }}</span>
                                <span class="text-danger">
                                    {{server_errors.reverse}}
                                </span>
                            </label>
                            <label class="listhead listhead-checkbox" :style="{marginTop: '6px', marginLeft: '-3px'}">
                                <input 
                                    type="checkbox" 
                                    v-model="value.reverse" 
                                    :disabled="!showSelected"
                                />
                                <p :style="{marginLeft: '10px', fontWeight: 'bold'}" :class="{'text-disable': !showSelected}">
                                    ຕ້ອງການກັບຄືນ (Reverse)
                                </p>
                                <div class="control_indicator"></div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="column is-12">
                    <div class="columns is-multiline" v-if="showBox_Part">
                        <div class="column" :class="[{'is-6': showSelected} || {'is-12': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ພາກຕົ້ນທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedStartPart.id">
                                            <option v-for="(startPart, index) in startParts" :key="index" :value="startPart.id">
                                                {{FarmatParts[startPart.name]}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-6" v-if="showSelected">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ພາກປາຍທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedEndPart.id">
                                            <option v-for="(endPart, index) in endParts" :key="index" :value="endPart.id">
                                                {{FarmatParts[endPart.name]}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="columns is-multiline" v-if="showBox_Province">
                        <div class="column" :class="[{'is-6': showSelected} || {'is-12': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເເຂວງຕົ້ນທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedStartProvince.id">
                                            <option v-for="(startProvince, index) in startProvinces" :key="index" :value="startProvince.id">
                                                {{startProvince.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-6" v-if="showSelected">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເເຂວງປາຍທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedEndProvince.id">
                                            <option v-for="(endProvince, index) in endProvinces" :key="index" :value="endProvince.id">
                                                {{endProvince.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="columns is-multiline" v-if="showBox_District">
                        <div class="column" :class="[{'is-4': showSelected}, {'is-6': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເລືອກເເຂວງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedProvince_ForDistrict.id">
                                            <option v-for="(province, index) in province_ForDistricts" :key="index" :value="province.id">
                                                {{province.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="column" :class="[{'is-4': showSelected}, {'is-6': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເມືອງຕົ້ນທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedStartDistrict.id">
                                            <option v-for="(startDistrict, index) in Filter_ForDistricts" :key="index" :value="startDistrict.id">
                                                {{startDistrict.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-4" v-if="showSelected">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເມືອງປາຍທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedEndDistrict.id">
                                            <option v-for="(endDistrict, index) in Filter_ForDistricts" :key="index" :value="endDistrict.id">
                                                {{endDistrict.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="columns is-multiline" v-if="showBox_Village">
                        <div class="column" :class="[{'is-3': showSelected}, {'is-4': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເລືອກເເຂວງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedProvince_ForVillage.id">
                                            <option v-for="(province, index) in province_ForFilterVillages" :key="index" :value="province.id">
                                                {{province.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column" :class="[{'is-3': showSelected}, {'is-4': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ເລືອກເມືອງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedDistrict_ForVillage.id">
                                            <option v-for="(district, index) in FilterDistrict_ForVillages" :key="index" :value="district.id">
                                                {{district.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column" :class="[{'is-3': showSelected}, {'is-4': !showSelected}]">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ບ້ານຕົ້ນທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedStartVillage.id">
                                            <option v-for="(startVillage, index) in Filter_ForVillages" :key="index" :value="startVillage.id">
                                                {{startVillage.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-3" v-if="showSelected">
                            <div class="field">
                                <div class="control">
                                    <label class="label">
                                        ບ້ານປາຍທາງ
                                    </label>
                                    <div class="select">
                                        <select class="input" v-model="selectedEndVillage.id">
                                            <option v-for="(endVillage, index) in Filter_ForVillages" :key="index" :value="endVillage.id">
                                                {{endVillage.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr :style="{background: '#ffff'}">

        <div class="columns is-multiline">
            <div class="column" :class="[{'is-6': showSelected}, {'is-12': !showSelected}]">
                <div class="columns">
                    <div class="column is-6">
                        <div class="control">
                            <label class="listhead listhead-checkbox" :style="{marginTop: '6px', marginLeft: '-3px'}">
                                <input 
                                    type="checkbox" 
                                    v-model="SelectedAll_StartBranch" 
                                    @click="SelectedAllStartBranch()"
                                />
                                <p :style="{marginLeft: '10px', fontWeight: 'bold'}">
                                    ສາຂາ ຕົ້ນທາງທັງໝົດ
                                    ( <span :style="{color: 'crimson'}">{{FilterStartBranch.length}}</span> ) 
                                </p>
                                <div class="control_indicator"></div>
                            </label>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field">
                            <input class="input" type="text" placeholder="ຄົ້ນຫາສາຂາ..." v-model="inputSearch_StartBranch">
                        </div>
                    </div>
                </div>
                <div class="box-start-branches" :style="{marginTop: '-15px'}">
                    <div class="columns is-multiline">
                        <div class="column" :class="[{'is-4': showSelected}, {'is-2': !showSelected}]"
                            v-for="(item, index) in FilterStartBranch" :key="index">
                            <div class="branch" v-if="showMsg_StartBranch">
                                <label class="listhead listhead-checkbox">
                                    <input 
                                        type="checkbox" 
                                        v-model="Selected_StartBranch" 
                                        :value="item.id" 
                                        :id="item.id"
                                    />
                                    <p :style="{marginTop: '6px'}">{{item.name}}</p>
                                    <div class="control_indicator"></div>
                                </label>
                            </div>
                        </div>
                        <div class="box-not-found" v-if="!showMsg_StartBranch || !FilterStartBranch.length">
                            <p>
                                ບໍ່ມີສາຂາ ຕົ້ນທາງ...
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-6" v-if="showSelected">
                <div class="columns">
                    <div class="column is-6">
                        <div class="control">
                            <label class="listhead listhead-checkbox" :style="{marginTop: '6px', marginLeft: '-3px'}">
                                <input 
                                    type="checkbox" 
                                    v-model="SelectedAll_EndBranch" 
                                    @click="SelectedAllEndBranch()"
                                />
                                <p :style="{marginLeft: '10px', fontWeight: 'bold'}">
                                    ສາຂາ ປາຍທາງທັງໝົດ
                                    ( <span :style="{color: 'crimson'}">{{FilterEndBranch.length}}</span> )
                                </p>
                                <div class="control_indicator"></div>
                            </label> 
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field">
                            <input class="input" type="text" placeholder="ຄົ້ນຫາສາຂາ..." v-model="inputSearch_EndBranch">
                        </div>
                    </div>
                </div>
                <div class="box-end-branches" :style="{marginTop: '-15px'}">
                    <div class="columns is-multiline">
                        <div class="column is-4" v-for="(item, index) in FilterEndBranch" :key="index">
                            <div class="branch" v-if="showMsg_EndBranch">
                                <label class="listhead listhead-checkbox">
                                    <input 
                                        type="checkbox" 
                                        v-model="Selected_EndBranch" 
                                        :value="item.id" 
                                        :id="item.id"
                                    />
                                    <p :style="{marginTop: '6px'}">{{item.name}}</p>
                                    <div class="control_indicator"></div>
                                </label>
                            </div>
                        </div>
                        <div class="box-not-found" v-if="!showMsg_EndBranch || !FilterEndBranch.length">
                            <p>
                                ບໍ່ມີສາຂາ ປາຍທາງ...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-12" id="box-btn-save">
                <div ref="target" id="target" class="center">
                    <div class="con-btns">
                        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
                            <i class="fal fa-box-full"></i> ບັນທຶກ ໂປຮໂມຮຊັ່ນ
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import mixinsAddPromotion from '@coms/../../src/Mixins/Promotions/Add';
import vuejsDatepicker from 'vuejs-datepicker';
import { ValidatePromotions } from '@coms/../../src/Plugins/Validation/Promotions/Validation';


export default {
    components: {
        vuejsDatepicker
    },

    mixins:[mixinsAddPromotion],

    created(){
        this.FetchData();
        ValidatePromotions(this);
    }
}
</script>

<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .box-start-branches{
        margin-top: 10px;
        background: $white;
        width: 100%;
        padding: 10px;
        max-height: 320px;
        min-height: 60px;
        border-radius: 5px;
        overflow: hidden;
        overflow-y: scroll;
        .branch{
            background: $crimson;
            width: 100%;
            padding: 2px;
            height: 40px;
            border-radius: 2px;
            font-family: $font;
            color: $white;
        }
    }
    .box-not-found{
        padding: 20px;
        width: 100%;
        text-align: center;
        p{
            color: $crimson;
            font-size: 16px;
        }
    }
    .box-end-branches{
        margin-top: 10px;
        background: $white;
        width: 100%;
        padding: 10px;
        max-height: 320px;
        min-height: 60px;
        border-radius: 5px;
        overflow: hidden;
        overflow-y: scroll;
        .branch{
            background: $crimson;
            width: 100%;
            padding: 2px;
            height: 40px;
            border-radius: 2px;
            font-family: $font;
            color: $white;
        }
    }

    // Basic control styles
    .listhead {
        display: block;
        position: relative;
        padding-left: 30px;
        margin: 0 0 15px 0;
        cursor: pointer;
        font-size: 16px;
    }
    // Hide default browser input
    .listhead input{
        vertical-align:top;
        margin-right:10px;
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    // Custom control
    .control_indicator{
        position: absolute;
        top: 1px;
        left: 4px;
        height: 23px;
        width: 23px;
        background: $white;
        border-radius: 4px;
    }
    // Hover and focus
    .listhead:hover input ~ .control_indicatior,
    .listhead input:focus ~ .control_indicator {
        background: $white;
    }
    // Checked
    .listhead input:checked ~ .control_indicator{
        background: $white;
    }
    // Hide default browser input
    .listhead input:disabled ~ .control_indicator {
        background: $primary-color;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        content: '';
        position: absolute;
        display: none; 
    }

    .listhead input:checked ~ .control_indicator:after {
        display: block; // Show check
    }

    .listhead-checkbox .control_indicator:after {
        left: 9px;
        top: 4px;
        width: 5px;
        height: 12px;
        border: solid $crimson;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    #box-btn-save{
        display: flex;
        .center{
            margin: 0 auto;
            margin-top: 10px;
            button{
                i{
                    width: 50px;
                }
                padding: 6px;
            }
        }
    }
    .text-disable{
        color: #969494;
    }
}
</style>