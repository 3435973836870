import * as helper from '../../Helpers/GetValue';
import {FormatDateTime, FormatNumber} from '@/Helpers/Format';
import moment from "moment";

export default {
    data: () => ({
        listAllOrders: [],
        startBranches: [],
        selectedStartBranch: '',
        selectedStartBranchType: '',
        endBranches:[],
        selectedEndBranch:'',
        selectedEndBranchType: '',
        isLoading: true,
        count: 0,
        selectedDeliverType: '',
        // Value
        startDate: moment(new Date()).format('YYYY-MM-DD 00:00'),
        endDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        searchItem: '',

        totalFreight: 0,
        totalPrice: 0,

        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10,

        oldVal: '',
    }),
    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },

        FormatDate(value) {
            return FormatDateTime(value);
        },
        FormatPrice(price) {
            if (price !== 0) {
                return FormatNumber(price);
            }
            return 0 + ' ກີບ';
        },

        FetchData() {
            this.isLoading = true;
            this.$http.get('list-shipment/pre-order', {
                params: {
                    shipment_pay_type: this.$route.query.tab,
                    start_branch: this.selectedStartBranch,
                    end_branch: this.selectedEndBranch,
                    startBranchType: this.selectedStartBranchType,
                    endBranchType: this.selectedEndBranchType,
                    deliverType: this.selectedDeliverType,
                    shipment_number: this.searchItem,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.pagination.current_page,
                    per_page: this.per_page,
                }
            }).then((res) => {
                setTimeout(() => {
                    this.listAllOrders = res.data.shipments.data;
                    this.pagination = res.data.shipments;
                    this.count = res.data.shipments.total;
                    this.totalFreight = res.data.totalFreight;
                    this.totalPrice = res.data.totalPrice;
                    this.isLoading = false;
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },
        FetchListStartBranch() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedStartBranchType + '&include=excluded').then((res) => {
                this.startBranches = res.data;
                this.startBranches.unshift({
                    id: "",
                    name: 'ທັງໝົດ'
                });
            })
        },

        FetchListEndBranch() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedEndBranchType + '&include=excluded').then((res) => {
                this.endBranches = res.data;
                this.endBranches.unshift({
                    id: "",
                    name: 'ທັງໝົດ'
                });
            })
        },

        ClearData() {
            this.startDate = moment(new Date()).format('YYYY-MM-DD 00:00');
            this.endDate = moment(new Date()).format('YYYY-MM-DD HH:mm');
            this.selectedStartBranch = '';
            this.selectedEndBranch = '';
            this.searchItem = '';
            this.selectedEndBranchType = '';
            this.selectedStartBranchType = '';
            this.FetchListStartBranch();
            this.FetchListEndBranch();
            this.FetchData();
        },

        FilterOrders() {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        FilterByShipmentNumber() {
            helper.GetOldValueOnInput(this);
        }
    },
    watch: {
        'searchItem': function (value) {
            if (value === '') {
                this.FetchData();
            }
        },
        'startDate': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'endDate': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'selectedEndBranch': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'selectedStartBranch': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'selectedStartBranchType': function () {
            this.pagination.current_page = 1;
            this.FetchListStartBranch();
            this.FetchData();
        },
        'selectedEndBranchType': function () {
            this.pagination.current_page = 1;
            this.FetchListEndBranch();
            this.FetchData();
        }
    }
}
