<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <li v-if="$can(['accountingoriginfranchise','accountingmanager'])"
            @click="Tabs('origin')" :class="{'is-active': isActive === 'origin'}">
          <a>ຍອດຈັດສົ່ງສຳເລັດຕົ້ນທາງ</a>
        </li>
<!--        <li v-if="$can(['accountingoriginfranchise','accountingmanager'])" @click="Tabs('destination')"-->
<!--            :class="{'is-active': isActive === 'destination'}">-->
<!--          <a>ຍອດຈັດສົ່ງສຳເລັດCDC</a>-->
<!--        </li>-->
      </ul>
    </div>
    <listForInsertInvoiceFranchise :onclickEvent="isActive"/>
  </div>
</template>

<script>
import listForInsertInvoiceFranchise from "@views/InvoiceFranchiseBranch/ListForInsert/listForInsertInvoiceFranchise";

export default {
  components: {
    listForInsertInvoiceFranchise
  },

  data: () => ({
    isActive: 'origin',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'tab.insert.invoice.franchise', query: {tab: value}}).catch(() => {
      });
    }
  },
  created() {
    if (this.$route.query.tab === 'origin') {
      this.isActive = 'origin';
    }  else {
      this.isActive = 'destination';
    }
  },
}
</script>

<style>

</style>
