import {FormatDateTime, FormatNumber} from '@/Helpers/Format';
import * as helper from '../../../Helpers/GetValue';
import moment from "moment";


export default {

    data: () => ({
        loading: false,
        checkedAll: false,
        isLoading: true,
        invoices: [],
        invoiceId: [],
        pageInvoices: [],
        startDate: moment(new Date()).format('YYYY-MM-DD 00:00'),
        endDate: moment(new Date()).format('YYYY-MM-DD 23:59'),

        branchTypes: [
            {value: 'branch', name: 'ສາຂາຫຼັກ'},
            {value: 'franchise', name: 'ສາຂາເເຟນຊາຍ'},
        ],

        selectedBranchType: 'branch',

        //export
        actualSelectedItems: [],
        isSelecting: false,

        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},

        shipment_number: '',
        oldVal: '',

        // Push Shipment Company
        pushShipments: [],

        companyCustomer: [],
        selectedCompanyCustomer: ''

    }), // Data

    methods: {
        FetchCompanyCustomer() {
            this.$http.get('list-company-customer').then((res) => {
                this.companyCustomer = res.data;
                this.companyCustomer.unshift({
                    id: "",
                    name: 'ທັງໝົດ'
                });
            })
        },

        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },

        AddInvoices() {
            if (this.actualSelectedItems.length > 0) {
                const loading = this.BtnLoading();
                this.loading = true;
                this.actualSelectedItems.filter(item => {
                    const exists = this.pushShipments.some(shipment => shipment.shipment_payment_id === item.id);
                    if (!exists) {
                        this.pushShipments.push(
                            {
                                shipment_payment_id: item.id,
                                cus_id: item.cus_id,
                                start_branch_id: item.start_branch_id,
                                end_branch_id: item.end_branch_id,
                                paid_for_id: item.paid_for_id,
                                paid_for_type: item.paid_for_type,
                                paid: item.paid,
                                insurance_price: item.insurance_price
                            }
                        );
                    }
                });
                this.$http.post('add-invoice', {
                    value: this.pushShipments,
                    type: this.$route.query.tab
                }).then(() => {
                    setTimeout(() => {
                        this.loading = false;
                        loading.close();
                        this.FetchData();
                        this.pushShipments = [];
                        this.actualSelectedItems = [];
                    }, 300);
                }).catch(() => {
                    this.loading = false;
                    loading.close();
                });
            }
        },

        FilterShipmentNumber() {
            helper.AccountingGetValueOnInput(this);
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('list-shipment/invoice', {
                params: {
                    shipment_number: this.shipment_number,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    company_customer_id: this.selectedCompanyCustomer,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page,
                    type: this.$route.query.tab,
                    branch_type: this.selectedBranchType,
                    branch: this.selectedBranch ?? null,
                }
            })
        },

        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.pagination = res.data;
                    this.invoices = res.data.data;
                    this.count = res.data.total;
                    this.markSelectedItemsOnPageChanges(this.invoices);
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        FormatDate(value) {
            return FormatDateTime(value);
        },
        FormatPrice(price) {
            return FormatNumber(price);
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },


        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) {
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },
        onCheckAllCheckBoxChanged(event) {
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if its not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.invoices));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecks();
            }
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },

        isRowSelected(id) {
            return this.pageInvoices.map((i) => i.id).includes(id);
        },

        clearAllChecks() {
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },
        /**
         * @EndChecking
         */
    },
    watch: {
        'startDate': function () {
            this.FetchData();
        },
        'endDate': function () {
            this.FetchData();
        },

        'selectedBranchType': function () {
            this.FetchData();
        },

        'selectedCompanyCustomer': function () {
            this.FetchData();
        },

        'shipment_number': function (value) {
            if (value === '') {
                this.FetchData();
            }
        },
    },
    computed: {
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
        }
    },
}
