<template>
    <div>
        <template>
          <h4 class="not-margin" :style="{marginTop: '10px'}">
              <img src="../../../../assets/user-permission.png" class="img-delete">
          </h4>
        </template>

        <div class="con-content" :style="{ marginTop: '5px' }">
            <p :style="{fontSize: '18px', color: '#195BFF'}">
                Permission-Role ຂອງທ່ານຄົບເເລ້ວເດີ <br>
                ບໍ່ສາມາດເພີ່ມອີກເເລ້ວ
            </p>
        </div>
    </div>
</template>
<script>
export default {

    data:()=>({
        
    }),
    methods:{
        
    }
}
</script>
<style lang="scss">

</style>