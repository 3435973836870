<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-48px', marginLeft: '-30px'}">
      <ul>
        <li @click="tabs('branch')" :class="{'is-active': isActive === 'branch'}">
          <a>ສາຂາ</a>
        </li>
        <li v-if="isDelivery" :class="{'is-active': isActive === 'delivery'}"
            @click="tabs('delivery')">
          <a>ເດລິເວີລີ້</a>
        </li>
      </ul>
    </div>
    <component :is="isActive"></component>
  </div>
</template>

<script>

import delivery from "@views/SuperAdmin/Branches/Link/DeliveryBranchLink";
import branch from "@views/SuperAdmin/Branches/Link/BranchLink";

const DELIVERY = 'delivery';

export default {
  components: {
    delivery,
    branch,
  },

  data: () => ({
    isActive: 'branch',
    tabsData: ['branch', 'delivery'],
    isDelivery: false,
  }),

  methods: {
    tabs(tab) {
      this.isActive = tab;
      this.$router.replace({name: 'branch-links', query: {tab}}).catch(() => {
      });
    },
  },
  created() {
    const tab = this.$route.query.tab;
    const type = this.$route.params.type;
    this.isDelivery = type === DELIVERY;
    if (tab && this.tabsData.includes(tab)) {
      if (!this.isDelivery && tab === 'delivery') {
        return;
      }
      this.tabs(tab);
    }
  },

}
</script>
