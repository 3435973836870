export default {
    data:()=>({
        btnLoading: false,
        server_errors: {
            title: '',
            description: '',
            userId: '',
        },
        value: {},
    }),
    methods: {
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();   
                }else{
                    this.$scrollTo('#scrollTo');
                }
            });
        },

        SaveItem(){
            this.btnLoading = true;
            this.$axios.post('add-news',{
                title: this.value.title,
                description: this.value.description,
            }).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'list.news'});
                    }, 300);
                }
            }).catch((error)=>{
                this.btnLoading = false;
                this.$scrollTo('#scrollTo');
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }
    }
}