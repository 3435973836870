import {FormatDate, FormatDateTimeBeforeSendToServer} from "@/Helpers/Format";
import moment from "moment";

export default {
    data: () => ({
        listAllOrders: [],
        startBranches: [],
        selectedStartBranchType: '',
        selectedStartBranch: '',
        endBranches: [],
        selectedEndBranchType: '',
        selectedEndBranch: '',
        shipmentStatus: [],
        selectedStatus: '',
        users: [],
        signUsers: [],
        selectedUser: '',
        selectedSignUser: '',
        max: 10,
        page: 1,
        isLoading: true,
        userCreateBy: [],
        signUserCreateBy: [],
        count: 0,

        // Value
        startDate: moment(new Date()).format('YYYY-MM-DD 00:00'),
        endDate: moment(new Date()).format('YYYY-MM-DD 23:59'),
        selectedAllEndBranch: null,
        selectedAllStatus: null,
        selectedAllUser: null,
        searchItem: '',

        // GroupBy UserId
        groupBy: false,
        totalFreight: 0,
        totalPrice: 0,
        selectedUserType: '',
        selectedSignUserType: '',
        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10,

        //Export
        actualSelectedItems: [],
        isSelecting: false,
        pageInvoices: [],
        loading: false,
        checkedAll: false,

        la_status: {
            'Pending': 'ລໍຖ້າດໍາເນີນການ',
            'Processing': 'ກຳລັງດຳເນີນການ',
            'Delivering': 'ກໍາລັງຈັດສົ່ງ',
            'Success': 'ຈັດສົ່ງສຳເລັດ',
            'Single Sign': 'ຮັບເຄື່ອງແບບແຍກ',
            'Timeout': 'ຈັດສົ່ງເຄື່ອງມື້ອື່ນ',
            'Contact Error': 'ເບີຕິດຕໍ່ບໍ່ໄດ້,ບໍ່ສາມາດຈັດສົ່ງ',
            'Lost': 'ເຄື່ອງເສຍ',
            'Return To Sender': 'ຈັດສົ່ງຄືນຜູ້ຝາກ',
            'Address Error': 'ທີ່ຢູ່ຜິດ(ປ່ຽນທີຢູ່ໃຫມ່)',
            'Trouble': 'ສະຖານະບໍ່ປົກກະຕິ',
            'Disposed': 'ທໍາລາຍ',
            'Customer Request Cancel': 'ລູກຄ້າຍົກເລີກ',
            'Wrong Flow': 'ເຄື່ອງທີ່ຍິງຂ້າມ',
            'Expires': 'ໝົດເຂດຮັບເຄື່ອງ',
            'BillWasSettled': 'ຮຽກເກັບເງິນສຳເລັດແລ້ວ',
            'SuccessfullyCompensated': 'ຊົດເຊີຍເງິນສຳເລັດ'
        },

        la_status_shipment_type: {
            'origin_freight_fees': 'ເກັບເງິນຕົ້ນທາງ',
            'destination_freight_fees': 'ເກັບເງິນ ( CDC )',
            'origin_freight_fees_cod': 'ເກັບເງິນ ( COD )',
            'destination_freight_fees_cod': 'ເກັບເງິນ ( COD & CDC )',
            'all': 'ທັງໝົດ'
        },

        oldVal: '',
        selectedSenderType: '',
        selectedReceiverType: '',
        selectedDeliverType: '',
    }),
    methods: {
        nullsafe(instance, func) {
            try {
                return func(instance);
            } catch (_) {
                return undefined
            }
        },
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        printShipment() {
            if (this.listAllOrders.length > 0) {
                const loading = this.BtnLoading();
                this.loading = true;
                this.$http.post('print/branch/new/shipment', {
                        shipment_pay_type: this.$route.query.tab,
                        start_branch: this.selectedStartBranch,
                        startBranchType: this.selectedStartBranchType,
                        end_branch: this.selectedEndBranch,
                        endBranchType: this.selectedEndBranchType,
                        query: this.searchItem,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        deliverType: this.selectedDeliverType,
                        senderType: this.selectedSenderType,
                        receiverType: this.selectedReceiverType,
                        shipment_status_id: this.selectedStatus,
                        userId: this.selectedUser,
                        signId: this.selectedSignUser,
                        userType: this.selectedUserType,
                        signUserType: this.selectedSignUserType,
                        scan_type: this.scanType
                    },
                    {responseType: 'blob'}
                ).then((response) => {
                    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                    const fileLink = document.createElement('a');
                    fileLink.href = fileUrl;
                    if (FormatDateTimeBeforeSendToServer(this.startDate) === FormatDateTimeBeforeSendToServer(this.endDate)) {
                        fileLink.setAttribute('download', 'ລາຍການອໍເດີ' + this.la_status_shipment_type[this.$route.query.tab] + 'ປະຈຳວັນທີ ' + FormatDate(this.startDate) + '.xlsx');
                    } else {
                        fileLink.setAttribute('download', 'ລາຍການອໍເດີ' + this.la_status_shipment_type[this.$route.query.tab] + 'ປະຈຳວັນທີ ' + FormatDate(this.startDate) + 'ຫາວັນທີ ' + FormatDate(this.endDate) + '.xlsx');
                    }

                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.actualSelectedItems = [];
                    this.FetchData();
                    this.loading = false;
                    loading.close();
                }).catch(() => {
                    this.loading = false;
                    loading.close();
                })
            }
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('new/shipment/orders', {
                params: {
                    shipment_pay_type: this.$route.query.tab,
                    start_branch: this.selectedStartBranch,
                    startBranchType: this.selectedStartBranchType,
                    end_branch: this.selectedEndBranch,
                    endBranchType: this.selectedEndBranchType,
                    query: this.searchItem,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    deliverType: this.selectedDeliverType,
                    senderType: this.selectedSenderType,
                    receiverType: this.selectedReceiverType,
                    shipment_status_id: this.selectedStatus,
                    userId: this.selectedUser,
                    signId: this.selectedSignUser,
                    userType: this.selectedUserType,
                    signUserType: this.selectedSignUserType,
                    cursor: current_page ?? this.pagination.cursor,
                    per_page: per_page ?? this.limit_page,
                    scan_type: this.scanType
                }
            })
        },

        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.listAllOrders = res.data.shipment.data;
                    this.pagination = res.data.shipment;
                    this.count = res.data.total;
                    this.totalFreight = res.data.totalFreight;
                    this.totalPrice = res.data.totalPrice;
                    this.userCreateBy = res.data.users;
                    this.signUserCreateBy = res.data.signUsers;
                    this.isLoading = false;
                    if (this.groupBy === false) {
                        if (this.userCreateBy !== null) {
                            this.FilterUsers();
                        }
                        if (this.signUserCreateBy !== null) {
                            this.FilterSignUsers();
                        }
                    }
                    // this.markSelectedItemsOnPageChanges(this.listAllOrders);
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        FetchListEndBranch() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedEndBranchType + '&include=excluded').then((res) => {
                this.endBranches = res.data;
                this.endBranches.unshift({
                    id: "",
                    name: this.$t("common.ທັງໝົດ"),
                });
            })
        },

        FetchListStartBranch() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedEndBranchType + '&include=excluded').then((res) => {
                this.startBranches = res.data;
                this.startBranches.unshift({
                    id: "",
                    name: this.$t("common.ທັງໝົດ"),
                });
            })
        },

        FetchShipmentStatus() {
            this.$http.get('shipment/statuses').then((res) => {
                this.shipmentStatus = res.data;
                this.selectedStatus = this.selectedAllStatus;
                this.shipmentStatus.splice(2, 0, {id: 'Arrived', name: 'Arrived'})
            }).catch(() => {
            });
        },
        ClearData() {
            this.startDate = moment(new Date()).format('YYYY-MM-DD 00:00');
            this.endDate = moment(new Date()).format('YYYY-MM-DD 23:59');
            this.selectedEndBranch = '';
            this.selectedStatus = '';
            this.selectedSenderType = '';
            this.selectedReceiverType = '';
            this.selectedUser = '';
            this.selectedSignUser = '';
            this.searchItem = '';
            this.selectedEndBranchType = '';
            this.FetchListEndBranch();
            this.FetchShipmentStatus();
        },
        FilterOrders() {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },
        FilterByShipmentNumber() {
            this.oldVal = this.searchItem;
        }
        // /**
        //  * @StartChecking
        //  */
        // markSelectedItemsOnPageChanges(items) {
        //     const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
        //     const newSelectedItems = [];
        //     items.map((i) => {
        //         if (selectedInvoiceIds.includes(i.id)) {
        //             newSelectedItems.push(i);
        //         }
        //     });
        //     this.pageInvoices = newSelectedItems;
        // },
        // onCheckAllCheckBoxChanged(event) {
        //     if (this.isSelecting) {
        //         return;
        //     }
        //     /// If checked
        //     if (event.target.checked) {
        //         // if its not indeterminate
        //         if (!this.isIndeterminate) {
        //             const loading = this.BtnLoading();
        //             this.isSelecting = true;
        //             /// Select all current page
        //             this.pageInvoices = JSON.parse(JSON.stringify(this.listAllOrders));
        //             /// Select all from response data
        //             this.fetchInvoiceItemsResponse({
        //                 current_page: 1,
        //                 per_page: this.count
        //             }).then((res) => {
        //                 this.actualSelectedItems = res.data.shipment.data;
        //             }).catch(() => {
        //             }).finally(() => {
        //                 loading.close();
        //                 this.isSelecting = false;
        //             });
        //         }
        //     } else {
        //         /// Clear all selected orders
        //         this.clearAllChecks();
        //     }
        // },
        //
        // onItemCheckBoxChanged(invoice) {
        //     const invoiceId = invoice.id;
        //     let itemIndex = this.actualSelectedItems.findIndex((item) => {
        //         return item.id === invoiceId;
        //     });
        //     if (itemIndex !== -1) {
        //         this.actualSelectedItems.splice(itemIndex, 1);
        //     } else {
        //         this.actualSelectedItems.push(invoice);
        //     }
        //     this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        // },
        //
        // isRowSelected(id) {
        //     return this.pageInvoices.map((i) => i.id).includes(id);
        // },
        // clearAllChecks() {
        //     this.checkedAll = false;
        //     this.pageInvoices = [];
        //     this.actualSelectedItems = [];
        // },
        // /**
        //  * @EndChecking
        //  */
    },
    // watch: {
    //     'searchItem': function (value) {
    //         if (value === '') {
    //             this.FetchData();
    //         }
    //     },
    //     'startDate': function () {
    //         this.pagination.current_page = 1;
    //         this.FetchData();
    //     },
    //     'endDate': function () {
    //         this.pagination.current_page = 1;
    //         this.FetchData();
    //     },
    //     'selectedEndBranch': function () {
    //         this.pagination.current_page = 1;
    //         this.FetchData();
    //     },
    //     'selectedUser': function () {
    //         this.pagination.current_page = 1;
    //         this.groupBy = true;
    //         this.FetchData();
    //     },
    //     'selectedSignUser': function () {
    //         this.pagination.current_page = 1;
    //         this.groupBy = true;
    //         this.FetchData();
    //     },
    //     'selectedEndBranchType': function () {
    //         this.pagination.current_page = 1;
    //         this.FetchListEndBranch();
    //         this.FetchData();
    //     }
    // },
    // computed: {
    //     isSelectAllOrders() {
    //         return this.actualSelectedItems.length === this.count
    //             && this.count > 0 && !this.isSelecting;
    //     },
    //     isSelectingOrders() {
    //         return this.actualSelectedItems.length > 0;
    //     },
    //     isIndeterminate() {
    //         return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
    //     }
    // },
}
