<template>
  <div>
    <div class="tabs container card-tabs">
      <ul>
        <li v-for="(tab, index) in tabs" :key="index" :class="{ 'is-active': activeTab === index }">
          <a style="font-weight: 500" @click="changeTab(index)">{{ tab.title }}
            <slot name="tab-title" :count="tab.count"></slot>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <keep-alive>
        <component @dataCountUpdated="updateDataCount"
                   :key="currentTab.nameKey"
                   ref="componentRender"
                   :is="activeComponent"/>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentTab() {
      return this.tabs[this.activeTab];
    },
    activeComponent() {
      return this.currentTab.component;
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
      this.$router.replace({
        name: 'list.distribution.center.links',
        query: {
          active_tab: this.currentTab.nameKey,
        }
      }).catch(() => {

      })
    },
    updateDataCount(dataCount) {
      this.tabs[this.activeTab].count = dataCount.count || 0;
    },
    checkRouteQueryActiveTab() {
      const activeTabKey = this.$route.query.active_tab || 'DistributionCenterLinks';
      let matchedTabIndex = this.tabs.findIndex((tab) => tab.nameKey === activeTabKey);
      if (matchedTabIndex === -1) {
        matchedTabIndex = 0;
      }
      this.changeTab(matchedTabIndex);
    }
  },
  mounted() {
    this.checkRouteQueryActiveTab()
  }
};
</script>

<style scoped lang="scss">
.container {
  margin-top: 12px;
  margin-bottom: 13px;
}

.card-tabs {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 0 2px;

  ul {
    margin: 0;
  }
}
</style>
