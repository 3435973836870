import { render, staticRenderFns } from "./list-cod-customer-shipment.vue?vue&type=template&id=14fa1404"
import script from "./list-cod-customer-shipment.vue?vue&type=script&lang=js"
export * from "./list-cod-customer-shipment.vue?vue&type=script&lang=js"
import style0 from "./list-cod-customer-shipment.vue?vue&type=style&index=0&id=14fa1404&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports