import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import can from './Helpers/Can'
import * as Notification from './Plugins/Notification'
import Helpers from './Helpers/index'
import i18n from './i18n'

import './Plugins/Axios';
import VeeValidate from 'vee-validate';
import VueCurrencyInput from 'vue-currency-input';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueScrollTo  from 'vue-scrollto';
import Multiselect from 'vue-multiselect';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

//pubnub
import PubNubVue from "pubnub-vue";
Vue.use(Helpers)
Vue.use(PubNubVue, {
    subscribeKey: 'sub-c-76fea1c5-4882-49c5-95d2-0970af2a54f6',
    publishKey: 'pub-c-99fd4a6b-8e6c-4a56-864d-0378067ed03c'
});

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

//dateTime
import DatePicker from 'vue2-datepicker';
Vue.prototype.DatePicker = DatePicker;
import 'vue2-datepicker/index.css';

//moment
import moment from 'moment'
Vue.prototype.moment = moment

// Search-Select
import 'vue-search-select/dist/VueSearchSelect.css';


import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles

import lodash from 'lodash';

// Import Components Modal
import ModalAdd from './components/Modals/Add';
import ModalEdit from './components/Modals/Edit';
import ModalDelete from './components/Modals/Delete';
import ModalCancel from './components/Modals/Cancel';
import ModalClose from './components/Modals/Close';
import ModalPrint from './components/Modals/PrintModal';
import ModalNotification from './components/Modals/Notification';
import ModalPreviewLocation from './components/Modals/Preview-Location';
import ModalPreviewFile from './components/Modals/Preview-File';

// Import expand image
import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)

import VueBarcode from '@chenfengyuan/vue-barcode';
Vue.component(VueBarcode.name, VueBarcode);

import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

//upload multiImage
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

Vue.component('ModalAdd', ModalAdd);
Vue.component('ModalEdit', ModalEdit);
Vue.component('ModalDelete', ModalDelete);
Vue.component('ModalCancel', ModalCancel);
Vue.component('ModalPrint', ModalPrint);
Vue.component('ModalClose', ModalClose);
Vue.component('ModalNotification', ModalNotification);
Vue.component('ModalPreviewLocation', ModalPreviewLocation);
Vue.component('ModalPreviewFile', ModalPreviewFile);
Vue.component('multiselect', Multiselect);
Vue.component('vue-timepicker', VueTimepicker);
Vue.use(VueNumberInput);

/**
 * Start Register Input password
 */
Vue.component('PasswordInput', () => import('@coms/PasswordInput'));
/**
 * End Register Input password
 */

// money input
const pluginOptions = {
  globalOptions: {
      locale: 'en-US',
      currency: {suffix: ' ກີບ'},
      valueAsInteger: true,
      precision: 0,
      distractionFree: false,
      min: 1000000,
      max: 300000000,
      validateOnInput: false
  }
};
Vue.use(VueCurrencyInput, pluginOptions);

// Vue-ScrollTo
Vue.use(VueScrollTo);

Vue.use(VeeValidate);
Vue.use(Vuesax, {});
Vue.prototype.lodash = lodash;


Vue.config.productionTip = false
Vue.prototype.$can = can;
Vue.prototype.$notification = Notification;

// Google Map
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyDmJNoeYGL2ZH2_P09i-nmg8nf-c4_i2q4',
    key: 'AIzaSyCgUsajH7cANXTJDpXJCT9x2n2Hj9kN0OE',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
