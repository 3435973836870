import moment from "moment";

export default {
    data: () => ({
        isLoading: true,
        loading: false,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        listOrderRequests: [],
        selectedStatus: {value: 'all', name: 'ທັງໝົດ'},
        statuses: [
            {value: 'all', name: 'ທັງໝົດ'},
            {value: 'pending', name: 'ລໍຖ້າອະນຸມັດ'},
            {value: 'confirm', name: 'ອະນຸມັດເເລ້ວ'},
            {value: 'on_the_way', name: 'ກຳລັງໄປຮັບເຄື່ອງ'},
            {value: 'success', name: 'ສຳເລັດເເລ້ວ'},
            {value: 'reject', name: 'ຖືກປະຕິເສດ'},
            {value: 'cancel', name: 'ຍົກເລີກເເລ້ວ'}
        ],

        /** pagination */
        offset: 10,
        pagination: {},
        per_page: 10,
        total: 0,

    }),
    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },
        printShipment() {
            const loading = this.BtnLoading();
            this.loading = true;
            this.$axios.post('print/order/requests', {
                startDate: this.startDate,
                endDate: this.endDate,
                status: this.selectedStatus.value
            }, {responseType: 'blob'}).then((res) => {
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', 'ລາຍການເອີ້ນຮັບເຄື່ອງວັນທີ ' + moment(this.startDate).format('DD-MM-YYYY') +
                    ' ຫາວັນທີ ' + moment(this.endDate).format('DD-MM-YYYY') + '.xlsx'
                );
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.loading = false;
                loading.close()
            }).catch(() => {
                this.loading = false;
                loading.close()
            });
        },
        ReportOrderRequests() {
            this.isLoading = true;
            this.$axios.post('report/all/order/requests', {
                startDate: this.startDate,
                endDate: this.endDate,
                status: this.selectedStatus.value,
                page: this.pagination.current_page,
                per_page: this.per_page
            }).then((res) => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.listOrderRequests = res.data.listOrderRequests.data;
                    this.pagination = res.data.listOrderRequests;
                    this.total = res.data.listOrderRequests.total;
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },
    },
    created() {
        this.ReportOrderRequests();
    },
    watch: {
        'startDate': function () {
            this.ReportOrderRequests();
        },
        'endDate': function () {
            this.ReportOrderRequests();
        }
    }
}
