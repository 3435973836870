<template>
    <div :style="{textAlign: 'left'}">
        <template>
            <h4 class="not-margin" :style="{marginTop: '10px'}">
                <ul>
                    <li>ເລກທີ່ບີນ: 
                        <span class="text-danger">{{OrderRequest.order_id}}</span>
                    </li>
                    <li>ຊື່ລູກຄ້າ: {{OrderRequest.customer_name}}</li>
                    <li>ເບີໂທຕິດຕໍ່: {{OrderRequest.customer_tel}}</li>
                    <li>ຜູ້ຮັບຜິດຊອບ: 
                        <span :style="{color: 'green'}">{{OrderRequest.rider_name !== null ? OrderRequest.rider_name : '..............'}}</span>
                    </li>
                    <hr :style="{marginTop: '5px'}">
                    <li :style="{marginTop: '-15px'}">ປະເພດພັດສະດຸ: {{OrderRequest.categ_name}}</li>
                    <li>ຈຳນວນ: {{OrderRequest.amount}} |
                        <span>ນ້ຳໜັກ: {{OrderRequest.weight}} kg</span>
                    </li>
                    <li>ສະຖານທີ່ໄປຮັບເຄື່ອງ: {{OrderRequest.pro_name}}</li>
                    <li>ສະຖານະ ( ຮັບເຄື່ອງ ) : 
                        <span v-if="OrderRequest.status == 'reject'" :style="{color: 'crimson', textDecoration: 'underline'}">ປະຕິເສດເເລ້ວ</span>
                        <span v-if="OrderRequest.status == 'cancel'" :style="{color: 'crimson', textDecoration: 'underline'}">ຍົກເລີກເເລ້ວ</span>
                    </li>
                </ul>
                <hr :style="{marginTop: '5px'}">
                <p class="text-reason" :style="{marginTop: '-18px'}">ເຫດຜົນທີ່ປະຕິເສດ:</p>
                <p class="detail">
                    - {{OrderRequest.reason}}
                </p>
            </h4>
        </template>
    </div>
</template>
<script>
export default {
    props:['OrderRequest'],
    
    data:()=>({
        loading: false,
    }),
     methods:{

    },
    created(){
        
    }
}
</script>
<style scoped lang="scss">
    .text-reason{
        font-weight: bold;
    }
    ul{
        li{
            font-size: 15px;
        }
    }
    .detail{
        width: 100%;
        font-size: 15px;
    }
</style>