import Vue from 'vue'
import Vuex from 'vuex'


// Modules
import User from './User';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        branchId: "",
        distributionCenterId: "",
        routeSystem: [],
        modalAddState: false,
        modalEditState: false,
        modalDeleteState: false,
        modalPrintState: false,
        modalCancelState: false,
        modalNotificationState: false,
        modalCloseState: false,
        modalPreviewLocationState: false,
        modalPreviewFileState: false,
        modalSelectedLocationState: false,
        service_role: "",
        // Location
        LanLng: {},

        sideBarCollapse: false,
    },
    mutations: {
        setPrintRouteSystem(state, value) {
            state.routeSystem = value;
        },
        set_BranchId(state, value) {
            state.branchId = value;
        },
        set_service_role(state, value) {
            state.service_role = value;
        },
        set_DistributionCenter(state, value) {
            state.distributionCenterId = value;
        },
        modalAdd_State(state, value) {
            state.modalAddState = value;
        },
        modalNotification_State(state, value) {
            state.modalNotificationState = value;
        },
        modalEdit_State(state, value) {
            state.modalEditState = value;
        },
        modalDelete_State(state, value) {
            state.modalDeleteState = value;
        },

        modalCancel_State(state, value) {
            state.modalCancelState = value;
        },

        modalClose_State(state, value) {
            state.modalCloseState = value;
        },

        modalPrint_State(state, value) {
            state.modalPrintState = value;
        },

        modalPreviewLocation_State(state, value) {
            state.modalPreviewLocationState = value;
        },
        modalPreviewFile_State(state, value) {
            state.modalPreviewFileState = value;
        },
        modalSelectedLocation_State(state, value) {
            state.modalSelectedLocationState = value;
        },

        // Set Location
        setLocation(state, value) {
            state.LanLng = value;
        },
        toggleSidebar(state) {
            state.sideBarCollapse = !state.sideBarCollapse;
        },
    },
    getters: {
        getRouteSystem(state) {
            return state.routeSystem;
        },
        get_branch_id(state) {
            return state.branchId;
        },
        get_distributionCenter_id(state) {
            return state.distributionCenterId;
        },
        checkIsServiceRole(state) {
            return state.service_role;
        }
    },
    actions: {
        downloadFile(context, {fileName, response}) {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a');
            fileLink.href = fileUrl;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
        },
        exportInjuredParcel(context, item) {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Locale': 'lo'
                };
                item.methodItem.post('print/injured-parcel', {
                    start_date: item.start_date,
                    end_date: item.end_date,
                    is_print: 1,
                }, {headers, responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ລາຍການພັດສະດຸສູນຫາຍ ເສຍຫາຍ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportPostBoxToExcel(context, httpClient) {
            return new Promise((resolve, reject) => {
                httpClient.post('v1/auth/post-box/boxes/export', {}, {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ກ່ອງໄປສະນີ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportBranchUsersToExcel(context, httpClient) {
            return new Promise((resolve, reject) => {
                httpClient.post('admin/exports/branches/users', {}, {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ຜູ້ໃຊ້ແຕ່ລະສາຂາ ປະຈຳວັນທີ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportDistributionCenterUsersToExcel(context, httpClient) {
            return new Promise((resolve, reject) => {
                httpClient.post('admin/exports/distribution-centers/users', {}, {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ຜູ້ໃຊ້ແຕ່ລະສູນຄັດແຍກ ປະຈຳວັນທີ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportVehicleUsersToExcel(context, httpClient) {
            return new Promise((resolve, reject) => {
                httpClient.post('admin/exports/vehicle/users', {}, {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ຜູ້ໃຊ້ແຕ່ລະພະແນກພາຫະນະ ປະຈຳວັນທີ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportUsersUsageToExcel(context, httpClient) {
            return new Promise((resolve, reject) => {
                httpClient.post('admin/exports/users-usages/coupons', {}, {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ຜູ້ໃຊ້ຄູປອງແຕ່ເລີ່ມຈົນຮອດວັນທີ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportShipmentIssueStocksToExcel(context, payload) {
            return new Promise((resolve, reject) => {
                payload.httpClient.post('v1/auth/branches/shipments/exports/orders/checking/shipment-issue-stocks',
                    payload.body,
                    {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ຂໍ້ມູນລາຍການພັດສະດຸ | ${payload.issue_category} - ${payload.body.start_date || ''} - ${payload.body.end_date || ''}  ວັນທີ ${(new Date()).toLocaleDateString()}.xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },

        exportReturningReturnedShipmentToExcel(context, payload) {
            let api = 'v1/auth/branches/shipments/exports/orders/checking/shipment-returning';
            let filename = "";

            if(payload.returned) {
                api = 'v1/auth/branches/shipments/exports/orders/checking/shipment-returned';
                filename = "Export - ຕີກັບລໍຖ້າຍິງອອກ";
            } else {
                filename = `Export - ເຄື່ອງຄ້າງສາງເກີນ ${payload.body.due_days} ວັນ`;
            }

            return new Promise((resolve, reject) => {
                payload.httpClient.post(api,
                    payload.body,
                    {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `ຂໍ້ມູນລາຍການພັດສະດຸ | ${filename} - ວັນທີ ${(new Date()).toLocaleDateString()} .xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
        exportBranchListToExcel(context, payload) {
            let api = 'admin/branches-export';
            const filename = `Export - ລາຍການສາຂາທັງຫມົດ`;

            return new Promise((resolve, reject) => {
                payload.httpClient.post(api,
                    payload.body,
                    {responseType: 'blob'}).then(
                    async (response) => {
                        await context.dispatch('downloadFile', {
                            response,
                            fileName: `${filename} - ວັນທີ ${(new Date()).toLocaleDateString()} .xlsx`
                        })
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                })
            });
        },
    },
    modules: {
        User,
    }
})
