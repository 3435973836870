<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                  ຂໍ້ມູນ ເເບນເນີ ( Banners ) -
                <span class="countTotal">({{listBanners.length}})</span>
                <span class="btn-add">
                    <vs-button
                        circle
                        icon
                        flat
                        @click="OpenModalAdd()"
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>ລຳດັບ</vs-th>
                    <vs-th>ໂປຮໄຟລເເບນເນີ</vs-th>
                    <vs-th>ລິ້ງ ( Link )</vs-th>
                    <vs-th>ຈັດລຽງ ( OrderBy )</vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="index" v-for="(banner, index) in $vs.getPage(listBanners, page, max)" :data="banner">
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td>
                            <img :src="banner.profile" class="profile">
                        </vs-td>
                        <vs-td :style="{width: '230px'}">{{ banner.link }}</vs-td>
                        <vs-td>
                            ( <span :style="{color: 'crimson'}">{{banner.order_by}}</span> )
                        </vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalEdit(banner.id)">
                                    <i class="fad fa-pencil-alt"></i>
                                </vs-button>
                                <vs-button circle danger icon flat @click="OpenModalDelete(banner.id)">
                                    <i class="fad fa-trash-alt"></i>
                                </vs-button>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listBanners, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddBanners @close="close" @success="FetchData()"/>
            </template>
        </ModalAdd>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditBanners @close="close" :listBanner="filterBanners" @success="FetchData()"/>
            </template>
        </ModalEdit>

        <ModalDelete>
            <template v-slot="{ close }">
                <DeleteBanners @close="close" :bannerId="bannerId" @success="FetchData()"/>
            </template>
        </ModalDelete>

    </div>
</template>
<script>
import AddBanners from './Dialog/Add';
import EditBanners from './Dialog/Edit';
import DeleteBanners from './Dialog/Delete';
import Loading from '../../Loading';
import moment from 'moment';


export default {
    components:{
        AddBanners,
        EditBanners,
        DeleteBanners,
        Loading
    },
    data:()=>({
        active: 1,
        page: 1,
        max: 15,
        listBanners: [],  
        bannerId: '',
        filterBanners: {},
        isLoading: true,
    }),
    methods:{
        FormatDate(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        OpenModalEdit(bannerId){
            this.filterBanners = {...this.FilterItem(bannerId)};
            this.$store.commit('modalEdit_State', true);
        },
        FilterItem(bannerId){
            return this.listBanners.filter(item=>{
                return item.id == bannerId;
            })[0] || {};
        },
        OpenModalDelete(bannerId){
            this.bannerId = bannerId;
            this.$store.commit('modalDelete_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-banners').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.listBanners = res.data.listBanners;
                }, 100);
            }).catch(()=>{
                this.isLoading = false;
                this.$emit('close');
            });
        },
        FormatPrice(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        }
    },
    created(){
        this.FetchData();
    },
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
    .profile{
        width: 100px;
        height: 60px;
        border-radius: 5px;
    }
}
</style>