<template>
  <div class="container" v-if="$can(['manager', 'SuperAdmin'])">
    <div>
      <h1><i class="fal fa-home-alt"></i> {{ $t("Dashboard.ສາຂາທັງໝົດ") }}</h1>
    </div>
    <!-- start branch -->
    <div class="columns" :style="{ marginTop: '25px' }">
      <div class="column is-3" @click="RouterPush('allBranch')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-building"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{ $t("Dashboard.ສາຂາທົ່ວໄປ") }}</h2>
                <h1>{{ AllBranches }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3" @click="RouterPush('allFranshise')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-layer-group"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.Franchises")}}</h2>
                <h1>{{ AllFranshises }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3" @click="RouterPush('allDistributionCenter')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-warehouse"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.ສູນຄັດແຍກ")}}</h2>
                <h1>{{ AllDistributionCenters }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3" @click="RouterPush('allUser')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-user-friends"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.ພະນັກງານ")}}</h2>
                <h1>{{ AllUsers }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end branch -->

    <!-- start order -->
    <!-- <div>
      <h1>Orders</h1>
    </div>
    <div class="columns">
      <div class="column">
        <div class="card order">
          <div class="card-content">
            <div class="columns">
              <div class="column is-2">
                <h2>ມື້ນີ້</h2>
                <h1>40,000</h1>
                <hr>
                <h3>200,000,000 <span>K</span></h3>
              </div>
              <div class="column is-2">
                <h2>ວານນີ້</h2>
                <h1>45,000</h1>
                <hr>
                <h3>220,000,000 <span>K</span></h3>
              </div>
              <div class="column is-2">
                <h2>ອາທິດນີ້</h2>
                <h1>350,000</h1>
                <h3>1,500,000,000 <span>K</span></h3>
              </div>
              <div class="column is-2">
                <h2>ເດືອນນີ້</h2>
                <h1>1,050,000</h1>
                <h3>4,200,000,000 <span>K</span></h3>
              </div>
              <div class="column is-2">
                <h2>ເດືອນແລ້ວນີ້</h2>
                <h1>1,050,000</h1>
                <h3>4,300,000,000 <span>K</span></h3>
              </div>
              <div class="column is-2">
                <h2>ປີນີ້</h2>
                <h1>15,050,000</h1>
                <h3>15,200,000,000 <span>K</span></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- end order -->

    <!-- start customers -->
    <div>
      <h1>
        <span><i class="fal fa-address-card"></i></span> {{$t("Dashboard.ລູກຄ້າ")}}
      </h1>
    </div>
    <div class="columns">
      <div class="column is-3" @click="RouterPush('allCustomer')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-user-friends"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.ລູກຄ້າພ/ງລົງທະບຽນ")}}</h2>
                <h1>{{ numberFormatter(AllCustomersByEmployee) }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3" @click="RouterPush('allCustomerByEmployee')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-user-alt"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.ລູກຄ້າລົງທະບຽນເອງ")}}</h2>
                <h1>{{ numberFormatter(AllCustomers) }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3" @click="RouterPush('allCompany')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-address-card"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.ລູກຄ້າທີ່ເປັນບໍລິສັດ")}}</h2>
                <h1>{{ numberFormatter(AllCompany) }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3" @click="RouterPush('allVipCustomer')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <span class="has-text-white">VIP</span>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.ລູກຄ້າ VIP")}}</h2>
                <h1>
                  <span style="color: #e13530">{{
                    numberFormatter(AllPendingVipCustomer)
                  }}</span>
                  / {{ numberFormatter(AllApprovedVipCustomer) }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end customers -->
    <!-- start Package -->
    <div>
      <h1>
        <span><i class="fal fa-shipping-fast"></i></span> {{$t("Dashboard.ເເພ໋ກເເກ໋ດ ( Packages )")}}
      </h1>
    </div>
    <div class="columns">
      <div class="column is-4" @click="RouterPush('allPackageExpress')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-money-check-alt"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.Express")}}</h2>
                <h1>{{ AllPackageExpress }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4" @click="RouterPush('allPackageLogistics')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-money-check-alt"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.Logistics")}}</h2>
                <h1>{{ AllPackageLogistics }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4" @click="RouterPush('allPackageEnvelope')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-money-check-alt"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2>{{$t("Dashboard.Envelope")}}</h2>
                <h1>{{ AllPackageEnvelope }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Package -->

    <!-- start Promotion -->
    <div>
      <h1>
        <span><i class="fal fa-bullhorn"></i></span> {{$t("Dashboard.ໂປຮໂມຮຊັ່ນ ( Promotions )")}}
      </h1>
    </div>
    <div class="columns">
      <div class="column is-6" @click="RouterPush('allPromotionOnline')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-bullhorn"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2 style="color: green">{{$t("Dashboard.ຍັງເປີດ")}}</h2>
                <h1>{{ AllPromotionOnlines }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6" @click="RouterPush('allPromotionOffline')">
        <div class="card">
          <div class="card-content">
            <div class="card-parent">
              <div class="card-left-section">
                <div class="card-box">
                  <i class="fal fa-bullhorn"></i>
                </div>
              </div>
              <div class="card-right-section">
                <h2 style="color: #e13530">{{$t("Dashboard.ປິດເເລ້ວ")}}</h2>
                <h1>{{ AllPromotionOfflines }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinsDashboard from "@coms/../../src/Mixins/Dashboard/dashboard";

export default {
  mixins: [mixinsDashboard],

  created() {
    this.FetchData();
  },
};
</script>

<style lang="scss" scoped>
.content h1 {
  font-size: 1.3rem;
  text-align: left;
}

.card-parent {
  width: 100%;
  display: flex;
}

.card-left-section {
  width: 30%;
  margin-right: 14px;
}

.card-right-section {
  width: 70%;

  h1 {
    text-align: center;
  }
}

.card-box {
  background-color: #e13530;
  padding: 14px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: inline-block;
}

.card-box i {
  color: #ffffff;
  display: block;
}

.card h1 {
  font-size: 2rem;
  margin: 0;
}

.card h2 {
  font-size: 1.2rem;
}

.card {
  border-radius: 4px;
  border: none !important;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2);
}

.card.order {
  background: #e13530;
  color: #fff;
}

.order h1,
.order h2 {
  color: #fff;
  cursor: pointer;
}

.order h3 {
  color: #000;
  cursor: pointer;
  font-size: 16px;
}

.card.order h1 {
  font-size: 20px;
}

.card.order h2 {
  font-size: 18px;
}

.card.order .column {
  border-right: 1px solid #fff;
}

.card.order .column:last-child {
  border-right: none;
}

.card.order h1:nth-child {
  border-right: none;
}

.card.order:hover {
  box-shadow: none;
}

.card:hover {
  transition: all ease 0.1;
  cursor: pointer;
}

.columns {
  .column {
    h1 {
      text-align: center;
    }

    h3 {
      color: $white;
    }
  }
}
</style>
