<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-lock-alt" :style="{ width: '25px', fontSize: '18px' }"></i>
        ປ່ຽນລະຫັດຜ່ານ
      </h4>
    </template>

    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ລະຫັດຜ່ານໃໝ່
          <span class="text-danger">* {{ errors.first('password') }}</span>
          <span class="text-danger">
                    {{ server_errors.password }}
                </span>
        </label>
        <PasswordInput v-model="password" name="password"
                       type="password"
                       v-validate="'required|min:6|max:15'"
                       placeholder="ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່ກ່ອນ..."/>
      </div>
    </div>

    <div ref="target" id="target" class="center" :style="{marginTop: '30px'}">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="loading">
          ປ່ຽນລະຫັດຜ່ານ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";
const dict = {
  custom: {
    password: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 15 ຕົວອັກສອນ...)",
    },
  },
};
Validator.localize("en", dict);
export default {
  props: ['userId'],
  data() {
    return {
      active: false,
      isLoading: false,
      loading: false,
      password: '',
      server_errors: {
        password: ''
      },
    };
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.loading = true;
      this.$http.post('shop/user/changePassword/' + this.userId, {
        'password' : this.password
      }).then(() => {
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    btnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.label-title {
  text-align: left;
  margin-top: -15px;
}

.box-list-permission {
  text-align: left;
  margin-top: 10px;
  background: rgba(var(--vs-gray-2), 1);
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  max-width: 368px;

  .permission {
    background: $primary-color;
    padding: 5px 5px 5px 5px;
    color: $white;
    border-radius: 5px;
    width: auto;
    margin: 3px;
    text-align: center;
    font-size: 14px;
  }
}

.box-upload {
  display: flex;
  margin-top: 25px;

  .box-img {
    margin: auto;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
    width: 105px;
    height: 105px;
    border-radius: 50px;

    &:hover {
      cursor: pointer;
    }

    .img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      margin-top: 3px;
    }
  }
}

.text-upload-img {
  margin-top: 10px;
  max-width: 100%;

  .msg {
    color: $crimson;
  }
}

.text-filename {
  .type-file {
    color: $primary-color !important;
  }
}

.text-size {
  font-size: 14px;

  .size {
    color: $primary-color !important;
  }
}
</style>
