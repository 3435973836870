<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ບ້ານທັງໝົດ
                <span class="countTotal">({{count}})</span>
                <span class="btn-add">
                    <input
                        class="input"
                        placeholder="ຄົ້ນຫາ..."
                        v-model="searchResult"
                        :style="{width: '300px'}"
                       @keyup.enter="SearchVillage()"
                    >
                </span>
                <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>

        <div class="center">
            <vs-table>
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ບ້ານ</vs-th>
                        <vs-th>ເມືອງ ເເລະ ເເຂວງ</vs-th>
                        <vs-th></vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                        :key="index" :data="village"
                        v-for="(village, index) in listVillages"
                    >
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '15%'}">
                            {{village.name}}
                        </vs-td>
                        <vs-td>
                            ( {{village.distName}} - {{village.proName}} )
                        </vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalEdit(village.id)">
                                    <i class="fad fa-pencil-alt"></i>
                                </vs-button>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset" @paginate="FetchVillages()"></pagination>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddVillages
                    @close="close"
                    @success="FetchData()"
                />
            </template>
        </ModalAdd>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditVillages
                    :listVillage="filterVillage"
                    @close="close"
                    @success="FetchData()"
                />
            </template>
        </ModalEdit>

    </div>
</template>

<script>
import Loading from '../../../Loading';
import AddVillages from '../Villages/Dialog/Add';
import EditVillages from '../Villages/Dialog/Edit';
import pagination from '../../../../Plugins/Paginations/Pagination';
import { VillageGetOldValueOnInput } from '../../../../Helpers/GetValue';

export default {
    components:{
        Loading,
        AddVillages,
        EditVillages,
        pagination
    },

    data:()=>({
        isLoading: true,
        listVillages: [],
        filterVillage: {},
        villageId: '',
        count: 0,
        translateInloas: {
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            north: 'ພາກເໜືອ',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1'
        },
        searchResult: '',
        oldVal: '',

        // pagination
        offset: 10,
        pagination: {},
        per_page: 10
    }),
    methods:{
        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        OpenModalEdit(villageId){
            this.filterVillage = {...this.FilterVillage(villageId)};
            this.$store.commit('modalEdit_State', true);
        },
        OpenModalDelete(villageId){
            this.villageId = villageId;
            this.$store.commit('modalDelete_State', true);
        },
        FilterVillage(villageId){
            return this.listVillages.filter(item=>{
                return item.id == villageId;
            })[0] || {};
        },
        FetchVillages(){
            this.isLoading = true;
            this.$axios.get('list-villages',{
                params:{
                    page: this.pagination.current_page,
                    per_page: this.per_page,
                    result: this.searchResult
                }
            }).then(res=>{
                setTimeout(()=>{
                    this.listVillages = res.data.villages.data;
                    this.count = res.data.count;
                    this.pagination = res.data.villages;
                    this.isLoading = false;
                }, 300);
            }).catch(()=>{});
        },
        SearchVillage(){
            VillageGetOldValueOnInput(this);
        }
    },
    created(){
        this.FetchVillages();
    },
    watch:{
        'searchResult': function(value){
            if(value == ''){
                this.FetchVillages();
            }
        }
    }
}
</script>
<style lang="scss">
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
.btn-add{
    margin-top: -10px !important;
}
</style>
