<template>
  <div class="container" :style="{marginTop: '5px'}">
    <div class="box-title">
      <h4>
        <i class="fal fa-box-full"></i>
        ຂໍ້ມູນ ຄຸປອງ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
                    <div class="columns">
                        <div class="field" :style="{marginRight: '2px'}">
                         <vs-button circle icon flat
                                    style="padding-right: 10px;"
                                    @click="exportUsersUsagesToExcel"
                                    :loading="isExporting">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="18px"
                                 viewBox="0 0 384 512"><path fill="currentColor"
                                                             d="M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM208 216c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88.02h-52.66c-11 0-20.59 6.41-25 16.72-4.5 10.52-2.38 22.62 5.44 30.81l68.12 71.78c5.34 5.59 12.47 8.69 20.09 8.69s14.75-3.09 20.09-8.7l68.12-71.75c7.81-8.2 9.94-20.31 5.44-30.83-4.41-10.31-14-16.72-25-16.72H208V216zm42.84 120.02l-58.84 62-58.84-62h117.68z"></path></svg>
                           <b>ລາຍການນໍາໃຊ້</b>
                         </vs-button>
                        </div>
                        <div class="field" style="margin-right: 12px">
                           <vs-button circle icon flat @click="OpenModalAdd()">
                              <i class="fal fa-plus-circle"></i>
                    </vs-button>
               </div>
           </div>
        </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-12">
          <div class="control">
            <label class="label">ຄົ້ນຫາ</label>
            <input class="input" placeholder="ຄົ້ນຫາ..." v-model="searchResult" @keyup.enter="Search()">
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດຄູປ໋ອງ</vs-th>
            <vs-th>ຊື່ຄູປ໋ອງ</vs-th>
            <vs-th>ສ່ວນຫຼຸດ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ວັນທີ່ໝົດອາຍຸ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="index" v-for="(coupon, index) in listCoupons">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>
              ( <span :style="{color: 'crimson'}">{{ coupon.code }}</span> )
            </vs-td>
            <vs-td :style="{width: '230px'}">{{ coupon.name }}</vs-td>
            <vs-td>
              <span v-if="coupon.discount_type == 'percent'">{{ coupon.discount }} %</span>
              <span v-else>{{ FormatDiscount(coupon.discount) }}</span>
            </vs-td>
            <vs-td><span>{{ coupon.total_amount }}</span></vs-td>
            <vs-td><span>{{ FormatDate(coupon.valid_date) }}</span></vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat danger @click="OpenModalClosed(coupon.id)">
                  <i class="fal fa-times-circle"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(coupon.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="OpenModalDelete(coupon.id)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddCoupons @close="close" @success="FetchData()"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditCoupons @close="close" :filterCoupon="filterCoupon" @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeletePromotions @close="close" :couponId="couponId" @success="FetchData()"/>
      </template>
    </ModalDelete>

    <ModalClose>
      <template v-slot="{ close }">
        <ClosePromotions @close="close" :couponId="couponId" @success="FetchData()"/>
      </template>
    </ModalClose>

  </div>
</template>
<script>
import AddCoupons from './Dialog/Add';
import EditCoupons from './Dialog/Edit';
import DeletePromotions from './Dialog/Delete';
import ClosePromotions from './Dialog/Close';
import Loading from '../../Loading';
import moment from 'moment';
import pagination from '../../../Plugins/Paginations/Pagination';
import {BranchGetValueOnInput} from "@/Helpers/GetValue";

export default {
  components: {
    AddCoupons,
    EditCoupons,
    DeletePromotions,
    ClosePromotions,
    Loading,
    pagination
  },
  data: () => ({
    active: 1,
    listCoupons: [],
    couponId: '',
    filterCoupon: {},
    isLoading: true,

    searchResult: '',
    oldVal: '',
    count: 0,
    isExporting: false,

    // Pagination
    offset: 10,
    pagination: {},
    per_page: 10
  }),
  watch: {
    'searchResult': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
  },
  methods: {
    FormatDiscount(num) {
      var p = num.toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, num, i) {
        return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + " ກີບ";
    },
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(couponId) {
      this.filterCoupon = {...this.FilterItem(couponId)};
      this.filterCoupon.only_new_customer = !!this.filterCoupon.only_new_customer;
      this.$store.commit('modalEdit_State', true);
    },
    FilterItem(couponId) {
      return this.listCoupons.filter(item => {
        return item.id == couponId;
      })[0] || {};
    },
    OpenModalDelete(couponId) {
      this.couponId = couponId;
      this.$store.commit('modalDelete_State', true);
    },
    OpenModalClosed(couponId) {
      this.couponId = couponId;
      this.$store.commit('modalClose_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-coupons', {
        params: {
          result: this.searchResult,
          page: this.pagination.current_page,
          per_page: this.per_page
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listCoupons = res.data.listCouponOnlines.data;
          this.pagination = res.data.listCouponOnlines;
          this.count = this.pagination.total;

        }, 100);
      }).catch(() => {

      });
    },
    FormatPrice(num) {
      var p = num.toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, num, i) {
        return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + " ກີບ";
    },
    Search() {
      BranchGetValueOnInput(this);
    },
    exportUsersUsagesToExcel() {
      this.isExporting = true;
      this.$store.dispatch('exportUsersUsageToExcel', this.$http)
          .catch(() => {
          }).finally(() => {
        this.isExporting = false;
      })
    },
  },
  created() {
    this.FetchData();
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}
</style>
