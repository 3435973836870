<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        {{ $t('ລາຍການOrderພັດສະດຸ.ລາຍການທັງໝົດ') }}
      </h4>
    </div>

    <div class="box" style="margin-bottom: 12px;margin-top: -12px;">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">{{ $t('ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາເລກບິນເທົ່ານັ້ນ') }}:</label>
              <input
                  class="input"
                  :placeholder="$t('ລາຍການOrderພັດສະດຸ.ເລກບິນເທົ່ານັ້ນ')"
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FetchData()"
              >
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="center table-limit-height">
      <vs-table v-if="Object.keys( listAllOrders ).length !== 0" :loading="loading">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສະຖານະ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ລາຍລະອຽດບິນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຮູບລາຍເຊັນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ໝາຍເຫດ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ເລກທີບິນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີຝາກເຄື່ອງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີແກ້ໄຂບິນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີເຄື່ອງຮອດ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີຈັດສົ່ງສຳເລັດ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຈຳນວນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ນ້ຳໜັກ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສ່ວນຫຼຸດ") }}</vs-th>
            <vs-th v-if="$route.query.tab === 'origin_freight_fees'">{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງຕົ້ນທາງ") }}</vs-th>
            <vs-th v-else-if="$route.query.tab === 'destination_freight_fees'">{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງປາຍທາງ") }}</vs-th>
            <vs-th v-else-if="$route.query.tab === 'origin_freight_fees_cod'">{{$t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງຕົ້ນທາງ")}}</vs-th>
            <vs-th v-else>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ປະກັນໄພ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ລວມຄ່າຂົນສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຕີກັບ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຝາກສາງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າສິນຄ້າ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຊື່ຜູ້ສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຊື່ຜູ້ຮັບ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາຕົ້ນທາງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາປາຍທາງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດການຊຳລະ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຂົນສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຜູ້ຮັບຜິດຊອບ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຜູ້ຈັດສົ່ງ") }}</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <!--                change sender-->
                <vs-button
                    v-if="!$can('service') && $route.query.tab !== 'all' && $route.query.tab !== 'allForCod' && listAllOrders.shipment_status_id !== 3"
                    circle icon flat
                    @click="OpenModalPrint(listAllOrders.shipment_number)">
                  <i class="fad fa-phone"></i>
                </vs-button>
                <!--                cancel address error-->
                <vs-button
                    v-if="$route.query.tab !== 'allForCod'   && listAllOrders.shipment_status_id === 6"
                    circle icon flat
                    @click="OpenModalPreviewFile(listAllOrders.shipment_number)">
                  <i class="fad fa-map-marker-check"></i>
                </vs-button>
                <!--                edit freight-->
                <vs-button v-if="!$can('service') && $route.query.tab !== 'all' && $route.query.tab !== 'allForCod'" circle icon flat
                           @click="OpenModalEdit(listAllOrders.shipment_number)">
                  <i class="fad fa-pencil"></i>
                </vs-button>
                <!--                change branch or reject-->
                <vs-button v-if="$route.query.tab !== 'allForCod'
                            && listAllOrders.shipment_status_id !== 8
                            && listAllOrders.shipment_status_id !== 3
                            && listAllOrders.reject_freight === 0"
                           circle icon flat
                           @click="OpenModalDelete(listAllOrders)">
                  <i class="fad fa-house"></i>
                </vs-button>
                <!--                add cod price-->
                <vs-button
                    v-if=" !$can('service') && $route.query.tab === 'allForCod' && (listAllOrders.shipment_status_id === 2 || listAllOrders.shipment_status_id === 13 || listAllOrders.shipment_status_id === 6)"
                    circle icon
                    flat
                    @click="OpenModalClose(listAllOrders.shipment_number)">
                  <i class="fad fa-money-bill"></i>
                </vs-button>
                <!--                cancel success order-->
                <vs-button
                    v-if="$route.query.tab !== 'allForCod' && listAllOrders.shipment_status_id === 3"
                    circle icon flat
                    @click="OpenModalCancel(listAllOrders.shipment_number)">
                  <i class="fad fa-window-close"></i>
                </vs-button>
                <!--                cancel reject-->
                <vs-button
                    v-if="$route.query.tab !== 'allForCod'
                    && (listAllOrders.shipment_status_id === 8
                    || (listAllOrders.shipment_status_id === 13 && listAllOrders.reject_freight !== 0))"
                    circle icon flat
                    @click="OpenModalAdd(listAllOrders.shipment_number)">
                  <i class="fad fa-window-close"></i>
                </vs-button>
                <vs-button circle icon flat
                           @click="$router.push({name: 'account.list.shipment.detail', params:{id: listAllOrders.id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td
                v-if="listAllOrders.tracking_state_id === 5 && (listAllOrders.shipment_status_id === 2 || listAllOrders.shipment_status_id === 6 || listAllOrders.shipment_status_id === 8)">
              {{ $t("ລາຍການOrderພັດສະດຸ.ເຄື່ອງຮອດສາຂາປາຍທາງ") }}
            </vs-td>
            <vs-td v-else> {{ $t('common.' + listAllOrders.shipment_status_name) }}</vs-td>
            <vs-td>{{ listAllOrders.tracking }}</vs-td>
            <vs-td>
              <div v-if="listAllOrders.signature_file" class="img-size">
                <expandable-image
                    class="image"
                    :src=listAllOrders.signature_file
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ listAllOrders.remark }}</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ listAllOrders.shipment_number }} )</vs-td>
            <vs-td v-if="listAllOrders.shipment_status_id === 1">{{ FormatDate(listAllOrders.created_at) }}</vs-td>
            <vs-td v-else>{{ FormatDate(listAllOrders.start_date_actual) }}</vs-td>
            <vs-td v-if="listAllOrders.shipment_status_id !== 1
            && listAllOrders.shipment_status_id !== 2
            && listAllOrders.shipment_status_id !== 3
            && listAllOrders.shipment_status_id !== 11">{{ FormatDate(listAllOrders.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="listAllOrders.tracking_state_id === 5 || listAllOrders.tracking_state_id === 6">
              {{ FormatDate(listAllOrders.end_date_actual) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="listAllOrders.shipment_status_id === 11">
              {{ FormatDate(listAllOrders.end_date_actual) }}
            </vs-td>
            <vs-td v-else-if="listAllOrders.shipment_status_id === 3">
              {{ FormatDate(listAllOrders.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td>{{ listAllOrders.pieces || 0 }}</vs-td>
            <vs-td>{{ listAllOrders.weight || 0 }}</vs-td>
            <vs-td>
              <span v-if="listAllOrders.total_discount_amount !== 0">{{ listAllOrders.total_discount_amount }}{{$t('common.ກີບ')}}</span>
              <span v-if="listAllOrders.total_discount_percent !== 0">{{ listAllOrders.total_discount_percent }}%</span>
              <span v-if="listAllOrders.total_discount_amount === 0 && listAllOrders.total_discount_percent === 0">........</span>
            </vs-td>
            <vs-td>( {{ FormatPrice(listAllOrders.sub_total_freight) }} )</vs-td>
            <vs-td>( {{ FormatPrice(listAllOrders.total_insurance_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.reject_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.total_rental_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(listAllOrders.total_price) }} )</vs-td>
            <vs-td>{{ listAllOrders.sender_customer_name }}</vs-td>
            <vs-td>{{ listAllOrders.receiver_customer_name }}</vs-td>
            <vs-td>{{ listAllOrders.start_branch_name }}</vs-td>
            <vs-td>{{ listAllOrders.end_branch_name }}</vs-td>
            <vs-td>
              <span v-if="listAllOrders.shipment_pay_type === 'origin_freight_fees'">
                {{ $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນຕົ້ນທາງ") }}
              </span>
              <span v-if="listAllOrders.shipment_pay_type === 'destination_freight_fees'">
                {{ $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນປາຍທາງ") }}
              </span>
              <span v-if="listAllOrders.shipment_pay_type === 'origin_freight_fees_cod'">
                {{ $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນຕົ້ນທາງ ( COD )") }}
              </span>
              <span v-if="listAllOrders.shipment_pay_type === 'destination_freight_fees_cod'">
                {{ $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນປາຍທາງ ( COD + CDC )") }}
              </span>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              ( {{ listAllOrders.shipment_type }} )
            </vs-td>
            <vs-td>{{ listAllOrders.created_by_name }}</vs-td>

            <vs-td v-if="listAllOrders.shipment_status_id === 3">{{ listAllOrders.sign_name }}</vs-td>
            <vs-td v-else></vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Loading v-if="loading"/>
        </template>
      </vs-table>
      <vs-table v-else></vs-table>
    </div>
    <ModalEdit>
      <template v-slot="{ close }">
        <shipmentEdit @close="close"
                      :shipment_number="shipment_number"
                      :tab_query="$route.query.tab"
                      @success="FetchData()"/>
      </template>
    </ModalEdit>
    <ModalDelete>
      <template v-slot="{ close }">
        <shipmentBranch @close="close" :change_branch="change_branch" @success="FetchData()"/>
      </template>
    </ModalDelete>
    <ModalClose>
      <template v-slot="{ close }">
        <addCodPrice @close="close"
                     :shipment_number="shipment_number"
                     @success="FetchData()"/>
      </template>
    </ModalClose>
    <ModalPrint>
      <template v-slot="{ close }">
        <ChangeSender @close="close"
                      :shipment_number="shipment_number"
                      @success="FetchData()"/>
      </template>
    </ModalPrint>
    <ModalPreviewFile>
      <template v-slot="{ close }">
        <CancelAddressErrorOrder @close="close"
                                 :shipment_number="shipment_number"
                                 @success="FetchData()"/>
      </template>
    </ModalPreviewFile>
    <ModalCancel>
      <template v-slot="{ close }">
        <cancelSuccessOrder @close="close"
                            :shipment_number="shipment_number"
                            @success="FetchData()"/>
      </template>
    </ModalCancel>
    <ModalAdd>
      <template v-slot="{ close }">
        <CancelRejectOrder @close="close"
                           :shipment_number="shipment_number"
                           @success="FetchData()"/>
      </template>
    </ModalAdd>
  </div>
</template>

<script>

import Loading from '../../Loading';
import MixinBranchListOrders from '../../../Mixins/Accountings/Solve-Shipment';
import shipmentEdit from '@/views/Accounting/SolveShipments/Dialog/Edit'
import shipmentBranch from '@/views/Accounting/SolveShipments/Dialog/Delete'
import addCodPrice from '@/views/Accounting/SolveShipments/Dialog/AddCodPrice'
import cancelSuccessOrder from "@views/Accounting/SolveShipments/Dialog/CancelSuccessOrder";
import ChangeSender from "@views/Accounting/SolveShipments/Dialog/ChangeSender.vue";
import CancelAddressErrorOrder from "@views/Accounting/SolveShipments/Dialog/CancelAddressErrorOrder.vue";
import CancelRejectOrder from "@views/Accounting/SolveShipments/Dialog/CancelRejectOrder.vue";

export default {
  components: {
    Loading,
    shipmentEdit,
    shipmentBranch,
    addCodPrice,
    cancelSuccessOrder,
    ChangeSender,
    CancelAddressErrorOrder,
    CancelRejectOrder
  },

  props: ['onclickEvent'],

  mixins: [MixinBranchListOrders],

  methods: {
    OpenModalEdit(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalEdit_State", true);
    },

    OpenModalClose(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalClose_State", true);
    },

    OpenModalCancel(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalCancel_State", true);
    },

    OpenModalDelete(listAllOrders) {
      this.change_branch = JSON.parse(JSON.stringify(listAllOrders));
      this.$store.commit("modalDelete_State", true);
    },

    OpenModalPrint(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalPrint_State", true);
    },
    OpenModalPreviewFile(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalPreviewFile_State", true);
    },
    OpenModalAdd(shipmentNumber) {
      this.shipment_number = JSON.parse(JSON.stringify(shipmentNumber));
      this.$store.commit("modalAdd_State", true);
    }
  },
  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.listAllOrders = {};
        this.searchItem = '';
        this.FetchData();
      }
    }
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}

.table-limit-height {
  .vs-table {
    width: 100%;
    font-size: 0.9rem;
    margin: 0;
    max-height: 500px;
  }
}

</style>
