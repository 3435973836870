<template>
    <div>
        <template>
            <h4 class="not-margin"
                :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-plus-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມ Call-Center
            </h4>
        </template>
        
        <div class="field" :style="{marginTop: '20px'}">
            <div class="control">
                <label class="label">
                    Call-Center
                    <span class="text-danger">* {{ errors.first('name') }}</span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input class="input" name="name" placeholder="ກະລຸນາປ້ອນຊື່ກ່ອນ..." v-model="value.name" v-validate="'required'">
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເບີໂທຕິດຕໍ່ 
                    <span class="text-danger">* {{ errors.first('tel') }}</span>
                    <span class="text-danger">
                        {{server_errors.tel}}
                    </span>
                </label>
                <input 
                    class="input" name="tel" 
                    placeholder="ກະລຸນາປ້ອນເບີໂທຕິດຕໍ່ກ່ອນ..." 
                    v-model="value.tel" 
                    v-validate="'required|min:6|max:15'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ອີເມວ 
                    <span class="text-danger">* {{ errors.first('email') }}</span>
                    <span class="text-danger">
                        {{server_errors.email}}
                    </span>
                </label>
                <input 
                    class="input" name="email" 
                    placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..." 
                    v-model="value.email" 
                    v-validate="'required|max:255'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກພາກ 
                    <span class="text-danger">* {{ errors.first('part') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedPart.partId" name="part" v-validate="'required'">
                        <option :value="part.partId" v-for="(part, index) in parts" :key="index">
                            {{translateInloas[part.name]}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກເເຂວງ 
                    <span class="text-danger">* {{ errors.first('province') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedProvince.proId" name="province" v-validate="'required'">
                        <option :value="province.proId" v-for="(province, index) in provinces" :key="index">
                            {{province.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກເມືອງ 
                    <span class="text-danger">* {{ errors.first('district') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedDistrict.distId" name="district" v-validate="'required'">
                        <option :value="district.distId" v-for="(district, index) in districts" :key="index">
                            {{district.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກບ້ານ
                    <span class="text-danger">* {{ errors.first('village') }}</span>
                    <span class="text-danger">
                        {{server_errors.villageId}}
                    </span>
                </label>
                <div class="select">
                    <select v-model="checkVillage" v-if="!villages.length" :style="{color: 'grey'}">
                        <option :value="checkVillage">
                            ບໍ່ມີຂໍ້ມູນບ້ານ...
                        </option>
                    </select>

                    <select v-model="selectedVillage.village_id" name="village" v-validate="'required'" v-else>
                        <option :value="village.village_id" v-for="(village, index) in villages" :key="index">
                            {{village.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
   
        <div ref="target" id="target" class="center">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading">ລົງທະບຽນ Call-Center</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import MixinsAddCallCenter from '../../../../Mixins/Call-Center/Add';
import { ValidateCallCenter } from '@coms/../../src/Plugins/Validation/Validate-Call-Centers';

export default {

    mixins: [MixinsAddCallCenter],

    created(){
        this.FetchData();
        ValidateCallCenter(this);
    }
}
</script>
<style lang="scss" scoped>
    
</style>