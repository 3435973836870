<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ສາຂາສູນຄັດເເຍກພັດສະດຸ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
            <div class="columns">
              <div class="field" style="margin-right: 12px">
                 <vs-button circle icon flat @click="OpenModalAdd()">
                    <i class="fal fa-plus-circle"></i>
                 </vs-button>
              </div>
          </div>
        </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-6">
          <div class="control">
            <label class="label">ຄົ້ນຫາ</label>
            <input class="input" placeholder="ຄົ້ນຫາ..." v-model="searchResult" @keyup.enter="Search()">
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ເລືອກພາກ</label>
            <div class="select">
              <select class="input" v-model="selectedPart" @change="Filter_Branch_By_Part(selectedPart)">
                <option :value="selectedAll">ທັງໝົດ</option>
                <option v-for="(part, index) in parts" :key="index" :value="part.partId">
                  {{ translateInloas[part.name] }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ເລືອກເເຂວງ</label>
            <div class="select">
              <select class="input" v-model="selectedProvince" @change="Filter_Branch_By_Province(selectedProvince)"
                      :disabled="disabled_SelectedProvince">
                <option :value="selectedAll">ທັງໝົດ</option>
                <option v-for="(province, index) in provinces" :key="index" :value="province.proId">
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ເລືອກເມືອງ</label>
            <div class="select">
              <select class="input" v-model="selectedDistrict" @change="Filter_Branch_By_District(selectedDistrict)"
                      :disabled="disabled_SelectedDistrict">
                <option :value="selectedAll">ທັງໝົດ</option>
                <option v-for="(district, index) in districts" :key="index" :value="district.distId">
                  {{ district.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ສູນຄັດເເຍກ</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ສະຖານທີ່</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              v-for="(distributionCenter, index) in $vs.getPage(distributionCenters, page, max)"
              :key="index" :data="distributionCenter">
            <vs-td :style="{textAlign: 'center'}">{{ index + 1 }}</vs-td>
            <vs-td :style="{maxWidth: '120px', overflow: 'hidden',
             textOverflow: 'ellipsis', color: 'crimson'}">
              ( {{ distributionCenter.prefix }} )
            </vs-td>
            <vs-td :style="{maxWidth: '120px'}">{{ distributionCenter.name }}</vs-td>
            <vs-td :style="{width: '20%'}">{{ distributionCenter.tel }}</vs-td>
            <vs-td :style="{maxWidth: '160px', overflow: 'hidden',
             textOverflow: 'ellipsis', color: 'crimson'}">
              <vs-tooltip>
                {{ distributionCenter.email }}
                <template #tooltip>
                  {{ distributionCenter.email }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td style="color: #195BFF; font-size: 16px">
              <vs-tooltip>
                {{ distributionCenter.villageName }}
                <template #tooltip>
                  ( {{ translateInloas[distributionCenter.partName] }},
                  {{ distributionCenter.proName }},
                  {{ distributionCenter.distName }},
                  {{ distributionCenter.villageName }} )
                </template>
              </vs-tooltip>

            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat color="amazon" @click="AddDistributionCenterLink(distributionCenter.id)">
                  <i class="fal fa-link"></i>
                </vs-button>
                <vs-button v-if="$can('SuperAdmin')"  circle icon flat @click="AddDistributionUsers(distributionCenter.id)">
                  <i class="fal fa-plus-circle"></i>
                </vs-button>
                <vs-button
                    circle icon flat @click="OpenModalEdit(distributionCenter.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button v-if="$can('SuperAdmin')" circle danger icon flat @click="OpenModalDelete(distributionCenter.id)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></Pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd :scrollTo="scrollTo">
      <template v-slot="{ close }">
        <AddDistributionCenters
            @close="close"
            @scrollTo="ScrollTo()"
            @success="FetchData()"
        />
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditDistributionCenters :ListDistributionCenter="filterDistributionCenter" @close="close"
                                 @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteDistributionCenters @close="close" :distributionCenterId="distributionCenterId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import Loading from '../../Loading';
import AddDistributionCenters from './Dialog/Add';
import EditDistributionCenters from './Dialog/Edit';
import DeleteDistributionCenters from './Dialog/Delete';
import {BranchGetValueOnInput} from "@/Helpers/GetValue";
import Pagination from "@/Plugins/Paginations/Pagination";

export default {
  components: {
    Loading,
    AddDistributionCenters,
    EditDistributionCenters,
    DeleteDistributionCenters,
    Pagination,
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,

    distributionCenters: [],
    filterDistributionCenter: {},
    distributionCenterId: '',
    translateInloas: {
      center: 'ພາກກາງ',
      south: 'ພາກໃຕ້',
      north: 'ພາກເໜືອ',
      northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
      southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
      southern: 'ພາກໃຕ້-1'
    },
    scrollTo: false,
    parts: [],
    selectedPart: '',
    selectedAll: 'all',

    provinces: [],
    selectedProvince: '',
    districts: [],
    selectedDistrict: '',

    count: 0,

    filterProvinces: [],
    filterDistricts: [],

    disabled_SelectedProvince: true,
    disabled_SelectedDistrict: true,
    searchResult: '',
    oldVal: '',

    // Pagination
    offset: 10,
    pagination: {},
    per_page: 10,
    // isExporting: false,
  }),
  watch: {
    'searchResult': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
  },
  methods: {
    ScrollTo() {
      this.scrollTo = true;
    },
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(distributionCenterId) {
      this.filterDistributionCenter = {...this.FilterItem(distributionCenterId)};
      this.$store.commit('modalEdit_State', true);
    },
    FilterItem(distributionCenterId) {
      return this.distributionCenters.filter(item => {
        return item.id == distributionCenterId;
      })[0] || {};
    },
    OpenModalDelete(distributionCenterId) {
      this.distributionCenterId = distributionCenterId;
      this.$store.commit('modalDelete_State', true);
    },
    AddDistributionUsers(distributionCenterId) {
      this.$router.push({name: 'list.distribution.center.users', query: {id: distributionCenterId}});
    },
    AddDistributionCenterLink(distributionCenterId) {
      this.$router.push({name: 'list.distribution.center.links', params: {id: distributionCenterId}});
    },

    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-distribution-centers',
          {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
              partId: this.selectedPart,
              provinceId: this.selectedProvince,
              districtId: this.selectedDistrict,
              result: this.searchResult,
            },
          }
      ).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.distributionCenters = res.data.distributionCenters.data;
          this.pagination = res.data.distributionCenters;
          this.count = this.pagination.total;
        }, 100);
      }).catch(() => {
      });
    },
    FetchAddresses() {
      this.$axios.get('list-branch-address').then(res => {
        const items = res.data.data;
        this.parts = items.parts;
        this.selectedPart = this.selectedAll;
        this.provinces = items.provinces;
        this.selectedProvince = this.selectedAll;
        this.districts = items.districts;
        this.selectedDistrict = this.selectedAll;

        this.filterProvinces = items.provinces;
        this.filterDistricts = items.districts;
      }).catch(() => {
      });
    },

    Filter_Branch_By_Part(partId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedProvince = this.selectedAll;
      this.selectedDistrict = this.selectedAll;
      this.FilterProvince(partId);
      this.FetchData();
      setTimeout(() => {
        if (partId == 'all') {
          this.isLoading = false;
          this.disabled_SelectedProvince = true;
          this.disabled_SelectedDistrict = true;
          return;
        }
        this.isLoading = false;
        this.disabled_SelectedProvince = false;
      }, 200);
    },

    Filter_Branch_By_Province(provinceId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedDistrict = this.selectedAll;
      this.FilterDistrict(provinceId);
      this.FetchData();
      setTimeout(() => {
        if (provinceId == 'all') {
          this.disabled_SelectedDistrict = true;
          if (this.selectedPart == 'all') {
            this.isLoading = false;
          } else {
            this.Filter_Branch_By_Part(this.selectedPart);
          }
        } else {
          this.isLoading = false;
          this.disabled_SelectedDistrict = false;
        }
      }, 200);
    },

    Filter_Branch_By_District(districtId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.FetchData();
      setTimeout(() => {
        if (districtId == 'all') {
          if (this.selectedProvince == 'all') {
            this.isLoading = false;
            return;
          } else {
            this.Filter_Branch_By_Province(this.selectedProvince);
          }
        }
        this.isLoading = false;
      }, 200);
    },

    // Filter Provinces
    FilterProvince(partId) {
      const result = this.filterProvinces.filter(item => {
        return item.part_id == partId;
      });
      this.provinces = JSON.parse(JSON.stringify(result));
    },
    // Filter Districts
    FilterDistrict(districtId) {
      const result = this.filterDistricts.filter(item => {
        return item.province_id == districtId;
      });
      this.districts = JSON.parse(JSON.stringify(result));
    },
    Search() {
      BranchGetValueOnInput(this);
    },
  },
  created() {
    this.FetchData();
    this.FetchAddresses();
  },
}
</script>

<style>

</style>
