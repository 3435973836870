<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ເພີ່ມລູກຄ້າເຊັນສັນຍາ
      </h4>
    </template>
    <!-- tel -->
    <div class="field column">
        <label class="label" style="text-align: left">
          ເບີໂທ
          <span class="text-danger">* {{ errors.first("tel") }}</span>
          <span class="text-danger">
            {{ server_errors.tel }}
          </span>
        </label>
        <div class="field has-addons">
          <p class="control" style="flex: auto">
            <input
                class="input"
                type="text"
                name="tel"
                placeholder="XXXXXXX..."
                v-model="value.tel"
                v-validate="{
                required: true,
                numeric: true,
                max: 8,
                min: 7,
              }"
            />
          </p>
        </div>
    </div>
    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
        >ບັນທຶກລູກຄ້າ
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    tel: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      numeric: "(ປ້ອນໄດ້ພຽງຕົວເລກ...)",
      max: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວເລກສູງສຸດ)`;
      },
      min: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວເລກຢ່າງຕ່ຳ)`;
      },
    },
  },
};
Validator.localize("en", dict);

export default {
  data() {
    return {
      active: false,
      isLoading: false,
      server_errors: {
        tel: "",
      },
      value: {
        tel: "",
      },
    };
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      const loading = this.BtnLoading();
      this.$http
          .post("marketing/update-to-regular/customer", this.value)
          .then((res) => {
            if (res.data.success) {
              setTimeout(() => {
                loading.close();
                this.$emit("close");
                this.$emit("success");
                this.$notification.OpenNotification_AddItem_OnSuccess(
                    "top-right",
                    "dark",
                    3000
                );
              }, 300);
            }
          })
          .catch((error) => {
            loading.close();
            if (error.response.status === 422) {
              var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
  watch: {
    "value.tel": function () {
      this.server_errors.tel = "";
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
