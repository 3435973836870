<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນນັກສຶກສາຝຶກງານ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="openModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 25px">
          <div class="field">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="printIntern()"
                  class="form-btn-loading"
                  style="margin-top: 0"
                  :loading="loading">
                export excel
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ຊື່ຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ຈຳນວນທີ່ແນະນຳລູກຄ້າ</vs-th>
            <!--            <vs-th>ບົດບາດຂອງຜູ້ໃຊ້ລະບົບ</vs-th>-->
            <!--            <vs-th>ໜ້າທີ່ຂອງບົດບາດ</vs-th>-->
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listEmployees"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>HAL|{{ item.userId }}</vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ item.tel }}</vs-td>
            <vs-th>{{ item.customer_amount }}</vs-th>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="printQr(item)">
                  <i class="fad fa-print"></i>
                </vs-button>
                <vs-button circle icon flat @click="openModalEdit(item.userId)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="openModalDelete(item.userId)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()"
          >
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddEmployee @close="close" @success="FetchData()" :roleUsers="roleUsers"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditEmployee :listEmployee="item" @close="close"
                      @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteEmployee @close="close" :itemId="itemId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>
<script>
import AddEmployee from './Dialog/Add';
import EditEmployee from './Dialog/Edit';
import DeleteEmployee from './Dialog/Delete';
import Loading from '../../Loading';
import * as helper from '../../../Helpers/GetValue';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
  components: {
    AddEmployee,
    EditEmployee,
    DeleteEmployee,
    Loading,
    pagination
  },

  data: () => ({
    isLoading: true,
    employeeType: 'intern_marketing',
    roleUsers: [],
    loading: false,
    listEmployees: [],
    item: {},
    itemId: '',
    searchItem: '',
    oldVal: '',
    // pagination
    offset: 10,
    pagination: {},
    per_page: 10,
    count: 0,
  }),
  methods: {
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
    printQr(item) {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('print/qr/intern-marketing', {
            reference_id: item.userId,
          },
          {responseType: 'blob'}
      ).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'QR INTERN MARKETING' + item.name + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      })
    },

    openModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    openModalEdit(itemId) {
      this.item = {...this.filterItem(itemId)};
      this.$store.commit('modalEdit_State', true);
    },
    filterItem(itemId) {
      return this.listEmployees.filter(item => {
        return item.userId === itemId;
      })[0] || {};
    },
    openModalDelete(itemId) {
      this.itemId = itemId;
      this.$store.commit('modalDelete_State', true);
    },
    printIntern() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('print/intern', {
        employee_type: this.employeeType,
        searchData: this.searchItem,
        print: 'yes',
        page: this.pagination.current_page,
        per_page: this.per_page,
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'ລາຍຊື່ນັກສຶກສາຝຶກງານ.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },
    FetchData() {
      this.isLoading = true;
      this.$http.get('list/intern', {
        params: {
          employee_type: this.employeeType,
          searchData: this.searchItem,
          print: 'no',
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listEmployees = res.data.data;
          this.pagination = res.data;
          this.count = res.data.total
        }, 100);
      }).catch(() => {
      });

      this.$http.get('head/intern/marketing/list-roles').then(res => {
        const roles = ["internmarketing"];
        this.roleUsers = res.data.roles.filter(item => roles.includes(item.name));
      });
    },

    FilterData() {
      helper.GetOldValueOnInput(this);
    }
  },
  created() {
    this.FetchData();
  },
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    }
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
