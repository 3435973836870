<template>
  <div class="container">
    <div class="columns box-title is-12" style="margin-top: -10px;">
      <div class="column is-6">
        <h4 class="has-text-left" style="margin-top: -26px;">
          <i class="fal fa-list"></i>
          ລາຍການອິນວອຍ
        </h4>
      </div>

    </div>
    <div class="box" :style="{marginTop: '-20px'}">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :min="disableDateCustomer"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="fetchData()"
              class="form-btn-loading"
              :loading="loading">
            ຄົ້ນຫາ
          </vs-button>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ເລກອິນວອຍ</vs-th>
            <vs-th>ວັນທີສ້າງອິນວອຍ</vs-th>
            <vs-th>ວັນທີສຳເລັດອິນວອຍ</vs-th>
            <vs-th v-if="$route.query.tab === 'origin'">ລູກຄ້າລາຍເດືອນ</vs-th>
            <vs-th v-if="$route.query.tab === 'origin'">ລາຍຮັບສາຂາ</vs-th>
            <vs-th>ລວມເປັນເງິນ</vs-th>
            <vs-th>ຈຳນວນບິນ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ຮູບພາບ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="payment"
              v-for="(payment, index) in $vs.getPage(listPayments, page, max)">
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({name: 'list.invoice.branch.detail', params: {id: payment.id, type: invoiceType}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
                <vs-button circle icon flat
                           @click="exportInvoice(payment.id, payment.recipient.village.district.province.prefix+'-'+payment.recipient.name)">
                  <i class="fad fa-print"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ payment.invoice_id }}</vs-td>
            <vs-td>{{ moment(payment.created_at).format('DD/MM/YYYY HH:mm') }}</vs-td>
            <vs-td v-if="payment.invoice_success_at">{{
                moment(payment.invoice_success_at).format('DD/MM/YYYY HH:mm')
              }}
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td v-if="$route.query.tab === 'origin'">{{ FormatPrice(payment.companyFreight) }}</vs-td>
            <vs-td v-if="$route.query.tab === 'origin'">
              {{ FormatPrice(payment.invoice_total_price - payment.companyFreight) }}
            </vs-td>
            <vs-td>{{ FormatPrice(payment.invoice_total_price) }}</vs-td>
            <vs-td>{{ FormatPrice(payment.totalDetail) }}</vs-td>
            <vs-td>{{ laStatus[payment.invoice_status] }}</vs-td>
            <vs-td>
              <div v-if="payment.payment_img" class="img-size">
                <expandable-image
                    class="image"
                    :src=payment.payment_img
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ payment.invoice_remark }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="fetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/CusorPagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
  },

  props: ['onclickEvent'],

  data: () => ({
    laStatus: {
      'pending': 'ກຳລັງດຳເນີນການ',
      'success': 'ສຳເລັດ'
    },
    invoiceType: 'origin',
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    listPayments: [],
    listBranches: [],
    selectedBranch: '',
    selectedStatus: '',
    // Pagination
    count: 0,
    totalPaid: 0,
    totalShipment: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),

  methods: {
    exportInvoice(id, branch_name) {
      const loading = this.BtnLoading();
      this.loading = true;
      let type = '';
      if (this.$route.query.tab === 'origin') {
        type = 'ຕົ້ນທາງ';
      } else if (this.$route.query.tab === 'destination') {
        type = 'CDC'
      } else {
        type = 'COD'
      }
      this.$http.post(`print/invoice/branch/${this.invoiceType}/${id}`, {
        'print': 'yes',
        'start_date': this.start_date,
        'end_date': this.end_date
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type} ${branch_name} ວັນທິ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type} ${branch_name} ວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      const fixDay = moment('2022-06-01 00:00:00');
      today.setHours(0, 0, 0, 0);
      return date > today || date < fixDay;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2022-06-01 00:00:00');
      return date < fixDay;
    },
    fetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        if (!res.data.error) {
          this.listPayments = res.data.data.data;
          this.pagination = res.data.data;
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('invoice/branch', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          type: this.$route.query.tab,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.invoiceType = this.$route.query.tab;
  },
  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.listPayments = [];
        this.count = 0;
        this.totalShipment = 0;
        this.totalPaid = 0;
        this.pagination = {};
        this.invoiceType = this.$route.query.tab;
        this.fetchData();
      }
    }
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
