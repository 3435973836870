export default {

    data:()=>({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            email: '',
            password: '',
            profile:'',
            fileSize:'',
            roleId: '',
        },
        users: {
            name: '',
            email: '',
            password: '',
        },

        roleId: [],
        permissions: [],

        images:{
            fileName: '',
            base64: '',
            size: '',
            file: '',
            type: '',
        },
        vehicleDepartments: [],
        selectedVehicleDepartments: {},

    }), // Data

    methods:{
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-vehicle-departments').then(res=>{
                setTimeout(()=>{
                    this.vehicleDepartments = res.data.ListVehicleDepartments;
                    this.selectedVehicleDepartments = {...this.vehicleDepartments[0]};
                }, 100);
            }).catch(()=>{});
        },
        ShowPermission(){
            this.$axios.get('show-permission-roles',{
                params:{
                    roleId: this.roleId
                }
            }).then(res=>{
                this.permissions = res.data;
            });
        }, // Show-Permissions

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }
            });
        }, // ValidateForm

        SaveItem(){
            this.btnLoading = true;
            this.users.roleId = this.roleId;
            this.users.profile = this.images.fileName;
            this.users.fileSize = this.images.size;
            this.users.vehicleDepartmentId = this.selectedVehicleDepartments.id;

            this.$axios.post('register-vehicle-department-users', this.users).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }, // Register User

        triggerInputClick() {
            let selector = this.$refs.file;
            if (selector) {
                selector.click();
            }
        }, // Trigger-Click

        async onFileChange(e) {
            const file = e.target.files[0];
            const FileSize = (file.size / 1024 / 1024).toFixed(2);
            const extentsions = file.type.split("/");
            const validate_Ext = this.ValidateExtensions(extentsions[1]);

            if(validate_Ext == true && FileSize < 6){
                this.images.file = file;
                this.images.type = extentsions[1];
                this.images.size = this.FormatByte(file.size);
                this.images.base64 = await this.ToBase64(file);
                this.UploadFile();
            }
        }, // OnFile-Change

        ToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }, // Convert-Base64

        FormatByte(bytes){
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }, // FormatByte

        ValidateExtensions(ext){
            if(ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp'){
                return true;
            }else{
                return false;
            }
        },

        UploadFile(){
            let formData = new FormData();
            formData.append('imageFile', this.images.file);

            this.$axios.post('upload-file', formData).then((res)=>{
                this.images.fileName = res.data.data.fileName;
                this.server_errors.profile = '';
                this.server_errors.fileSize = '';
            }).catch(()=>{});
        }, // Upload-File
    },
    watch: {
        'users.userCardid':function(){
            this.server_errors.userCardid = '';
        },
        'users.name':function(){
            this.server_errors.name = '';
        },
        'users.email':function(){
            this.server_errors.email = '';
        },
        'users.password':function(){
            this.server_errors.password = '';
        },
        'roleId':function(){
            this.server_errors.roleId = '';
        }
    },
}
