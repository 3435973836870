import {FormatDate, FormatNumber} from '@/Helpers/Format';
import moment from "moment";

export default {
    props: {
        status: {
            type: String,
            default: 'pending',
        }
    },
    data: () => ({
        modalActive: {
            editCustomerCodStatusModal: false,
            exportTotalListCustomerCodModal: false,
            exportSummarizeListCustomerCodModal: false,
        },
        checkedAll: false,
        isLoading: true,
        invoices: [],
        invoiceId: [],
        pageInvoices: [],
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        sum_cod_fee: 0,
        sum_qty: 0,
        sum_sub_total_price: 0,
        sum_total_fee: 0,
        sum_total_price: 0,
        sum_transfer_fee: 0,
        listCustomers: [],
        selectedCustomer: '',
        //export
        actualSelectedItems: [],
        isSelecting: false,

        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},
        la_status: {
            pending: 'ກຳລັງດຳເນີນການ',
            success: 'ສຳເລັດ',
        },
        shipment_number: '',
        oldVal: '',
    }), // Data

    methods: {
        closeModal(id) {
            this.modalActive[id] = false;
        },

        openUpdateInvoiceModal() {
            this.modalActive.editCustomerCodStatusModal = true;
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('admin/cod-customer-request-balance-list', {
                params: {
                    start_date: this.startDate,
                    end_date: this.endDate,
                    request_option: this.$route.query.tab,
                    status: this.status,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page
                }
            })
        },

        afterEdit() {
            this.invoices = [];
            this.fetchData();
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
            this.sum_cod_fee = 0;
            this.sum_qty = 0;
            this.sum_sub_total_price = 0;
            this.sum_total_fee = 0;
            this.sum_total_price = 0;
            this.sum_transfer_fee = 0;
            this.count = 0;
            this.pagination = {};
        },

        fetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse()
                .then((res) => {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.pagination = res.data.data;
                        this.invoices = res.data.data.data;
                        this.sum_cod_fee = res.data.data.sum_cod_fee;
                        this.sum_qty = res.data.data.sum_qty;
                        this.sum_sub_total_price = res.data.data.sum_sub_total_price;
                        this.sum_total_fee = res.data.data.sum_total_fee;
                        this.sum_total_price = res.data.data.sum_total_price;
                        this.sum_transfer_fee = res.data.data.sum_transfer_fee;
                        this.count = res.data.data.total;
                        this.markSelectedItemsOnPageChanges(this.invoices);
                    }, 300);
                }).catch(() => {
                this.isLoading = false;
            });
        },
        openExportExcelByTotalModal() {
            this.modalActive.exportTotalListCustomerCodModal = true;
        },

        exportSummarizeData(items,date){
            const invoiceId = items.map(item => item.id);
            const loading = this.$vs.loading()
            this.$http.post('admin/export-cod-balance-summarize', {
                    invoices: invoiceId,
                },
                {responseType: 'blob'}
            ).then((response) => {
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', 'COD ສັງລວມ ວັນທີ ' + FormatDate(date) + '.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                loading.close();
            }).catch(() => {
                loading.close();
            })
        },

        exportExcelTransaction(items,date) {
            const invoiceId = items.map(item => item.id);
            const loading = this.$vs.loading()
            this.$http.post('admin/export-cod-balance-template', {
                    invoices: invoiceId,
                },
                {responseType: 'blob'}
            ).then((response) => {
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', 'COD Transaction ວັນທີ ' + FormatDate(date) + '.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                loading.close();
            }).catch(() => {
                loading.close();
            })
        },

        exportExcelNoteRequestCod() {
            const loading = this.$vs.loading()
            this.$http.post('admin/export-cod-balance-not-request', {},
                {responseType: 'blob'}
            ).then((response) => {
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', 'ລາຍການລູກຄ້າຍັງບໍ່ກົດຢືນຢັນຍອດເງິນ COD.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                loading.close();
            }).catch(() => {
                loading.close();
            })
        },

        FormatDate(value) {
            return moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm');
        },
        FormatPrice(price) {
            let value = parseFloat(price);
            if (value !== 0) {
                return FormatNumber(parseFloat(price));
            }
            return '0 ກີບ';
        },
        exportSuccess() {
            this.clearAllChecked();
        },

        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) {
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },
        onCheckAllCheckBoxChanged(event) {
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if it's not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.invoices));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.data.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecked();
            }
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },

        isRowSelected(id) { //@changed
            return this.pageInvoices.map((i) => i.id).includes(id);
        },
        clearAllChecked() { //@changed
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },
        /**
         * @EndChecking
         */
    },

    computed: {
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
        }
    },
}
