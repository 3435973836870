<template>
  <div class="container">
    <div class="box-title" id="scrollTo">
      <h4>
        <i class="fal fa-edit"></i> ແກ້ໄຂ ຂໍ້ມູນກ່ອງໄປສະນີ
      </h4>
    </div>

    <div class="columns is-multiline" :style="{marginTop: '20px'}">
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ລະຫັດກ່ອງໄປສະນີ
              <span class="text-danger">* {{ errors.first('code') }}</span>
              <span class="text-danger">
                                {{ server_errors.code }}
                            </span>
            </label>
            <input
                class="input" name="code"
                placeholder="ກະລຸນາປ້ອນລະຫັດກ່ອງໄປສະນີ"
                v-model="post_box.code"
                v-validate="'required|max:25|min:3'">
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກຂະຫນາດ
              <span class="text-danger">* {{ errors.first('post_box_size') }}</span>
            </label>
            <div class="select">
              <select v-model="post_box.size_id" name="post_box_size">
                <option :value="item.id" v-for="(item, index) in postBoxSizes" :key="index">
                  {{ item.size }} - {{ item.width }} cm {{ item.height }} cm
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກສາຂາ
              <span class="text-danger">* {{ errors.first('branch_id') }}</span>
            </label>
            <div :style="{marginTop: '2px'}">
              <vs-select
                  filter
                  placeholder="ເລືອກສາຂາ..."
                  name="branch_id"
                  class="vs-select-filter-cs"
                  v-model="post_box.branch_id" v-if="branches.length > 0"
                  v-validate="'required'">
                <vs-option
                    v-for="(item, index) in branches"
                    :label="`${item.prefix}-${item.name}(${(item.district || {}).name }), ${(item.province || {}).name}))`"
                    :value="item.id"
                    :key="index">
                  {{ item.prefix }}-{{ item.name }}({{ (item.district || {}).name }},{{ (item.province || {}).name }})
                </vs-option>
              </vs-select>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ຊື່ເຈົ້າຂອງ
              <span class="text-danger">* {{ errors.first('customer_name') }}</span>
              <span class="text-danger">
                                {{ server_errors.customer_name }}
                            </span>
            </label>
            <input
                class="input" name="customer_name"
                placeholder="ກະລຸນາປ້ອນ..."
                v-model="post_box.customer_name"
                v-validate="'required|min:2|max:25'"
            >
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ເບີໂທ
              <span class="text-danger">* {{ errors.first('customer_phone_number') }}</span>
              <span class="text-danger">
                                {{ server_errors.customer_phone_number }}
                            </span>
            </label>
            <input
                class="input" name="customer_phone_number"
                type="tel"
                placeholder="ກະລຸນາປ້ອນ..."
                v-model="post_box.customer_phone_number"
                v-validate="'required|min:6|max:15'"
            >
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ທີ່ຢູ່
              <span class="text-danger">* {{ errors.first('customer_address') }}</span>
              <span class="text-danger">
                                {{ server_errors.customer_address }}
                            </span>
            </label>
            <input
                class="input" name="customer_address"
                placeholder="ກະລຸນາປ້ອນ..."
                v-model="post_box.customer_address"
                v-validate="'required|min:3|max:80'"
            >
          </div>
        </div>
      </div>

      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ສະຖານະ
              <span class="text-danger">* {{ errors.first('is_active') }}</span>
            </label>
            <div class="select">
              <select v-model="post_box.is_active" name="is_active">
                <option :value="item.id" v-for="(item, index) in statues" :key="index">
                  {{ item.id === 1 ? 'ເປີດ' : 'ປິດ' }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="field">
          <div class="control">
            <label class="label">
              ທີ່ຢູ່ Lag,Lng
              <span class="text-danger">* {{ errors.first('lat_lng') }}</span>
              <span class="text-danger" v-if="server_errors.lat">
                                ກະລຸນາເລືອກທີ່ຢູ່ຂອງກ່ອງໄປສະນີ
                            </span>
            </label>
            <input
                class="input" name="lat_lng"
                placeholder="ຕົວຢ່າງ: lat,lng"
                v-model="selectLatLng"
                v-validate="'required'"
            >
          </div>
        </div>
      </div>
      <div class="column is-3" :style="{display: 'flex'}">
        <div ref="target" id="target" class="center" style="
        margin: 0; align-items: flex-start; padding-top: 19px;">
          <vs-button ref="button" flat class="form-btn-general" @click="focusFromLagLng">
            <i class="fal fa-map-marked" :style="{width: '35px'}"></i> Focus
          </vs-button>
        </div>
      </div>

      <div class="column is-12">
        <gmap-autocomplete
            ref="searchInput"
            class="input"
            placeholder="ຄົ້ນຫາເເຜນທີ່..."
            @place_changed="setPlace"
            :options="{fields: ['geometry', 'formatted_address', 'name']}">
        </gmap-autocomplete>
        <span class="horizontal-divider"></span>
      </div>
      <div class="column is-12">
        <GmapMap
            :center="center"
            :zoom="16"
            style="width:100%; height:450px;"
        >
          <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center=m.position"
              :draggable="true"
              @dragend="onLocation"
              :icon="markerOptions"
              :animation="2"
              ref="markers"
          />
        </GmapMap>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12" :style="{display: 'flex'}">
        <div ref="target" id="target" class="center">
          <div class="con-btns">
            <vs-button ref="button" flat @click="validateForm()" class="form-btn-loading" :loading="isSubmitLoading">
              <i class="fal fa-pencil-alt" :style="{width: '35px'}"></i> ແ້ກໄຂ ກ່ອງໄປສະນີ
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ValidateBranches} from '@coms/../../src/Plugins/Validation/Branch/Branch';

export default {
  data: () => ({
    id: null,
    isSubmitLoading: false,
    server_errors: {
      code: '',
      size_id: ''
    },
    center: {
      lat: 18.1189434,
      lng: 102.290218,
    },
    selectLatLng: null,
    markers: [],
    currentPlace: null,
    currentAddress: '',
    markerOptions: {
      // eslint-disable-next-line global-require
      url: require('@coms/../../src/assets/marker.png'),
      size: {
        width: 25,
        height: 45,
        f: 'px',
        b: 'px',
      },
      scaledSize: {
        width: 25,
        height: 45,
        f: 'px',
        b: 'px',
      },
    },
    timer: null,
    branches: [],
    postBoxSizes: [],
    statues: [
      {id: 0},
      {id: 1}
    ],
    post_box: {
      code: '',
      size_id: null,
      branch_id: null,
      lat: null,
      lng: null,
      customer_name: '',
      customer_phone_number: '',
      customer_address: '',
      is_active: 1
    },
  }),
  watch: {
    'center.lat': function () {
      this.selectLatLng = this.center.lat + ',' + this.center.lng
    }
  },
  methods: {
    /**
     * @MAP
     */
    onLocation(evt) {
      this.center.lat = evt.latLng.lat();
      this.center.lng = evt.latLng.lng();
    },
    focusFromLagLng() {
      if (this.selectLatLng) {
        const lagLng = this.selectLatLng.split(',');
        if (lagLng.length > 1) {
          this.center.lat = parseFloat(lagLng[0]);
          this.center.lng = parseFloat(lagLng[1]);
          this.placeMarker();
        }
      }
    },
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
      this.placeMarker();
    },
    placeMarker() {
      this.markers = [];
      this.places = [];
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({position: marker});
        this.center = marker;
        this.animateMarker();
      } else {
        const marker = {
          lat: this.center.lat,
          lng: this.center.lng,
        };
        this.markers.push({position: marker});
        this.animateMarker();
      }
    },
    animateMarker() {
      this.$nextTick(() => {
        const obj = this.$refs.markers[0].$markerObject;
        if (obj) {
          obj.setAnimation(1);
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            obj.setAnimation(null);
          }, 800);
        }
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.placeMarker();
      });
    },
    /**
     * @END MAP
     */
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveItem();
        } else {
          this.$scrollTo('#scrollTo');
        }
      });
    },
    saveItem() {
      this.post_box.lat = this.center.lat
      this.post_box.lng = this.center.lng
      this.btnLoading = true;
      this.$http.post('v1/auth/post-box/update/' + this.id, this.post_box).then(() => {
        this.btnLoading = false;
        this.$emit('close');
        this.$emit('success');
        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
        this.$router.push({name: 'list.post_boxes'});
      }).catch(error => {
        this.$scrollTo('#scrollTo');
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      }).finally(() => this.btnLoading = false);
    },
    fetchPostBoxSizes() {
      return this.$http.get('v1/listing/post_box_sizes').then((res) => {
        this.postBoxSizes = res.data;
        if (this.postBoxSizes.length) {
          this.post_box.size_id = this.postBoxSizes[0].id
        }
      })
    },
    fetchBranches() {
      return this.$http.get('v1/listing/branches').then((res) => {
        this.branches = res.data;
        if (this.branches.length) {
          this.post_box.branch_id = this.branches[0].id
        }
      })
    },
    fetchData(id) {
      this.$http.get('v1/auth/post-box/boxes/' + id).then((res) => {
        const data = res.data;
        if (data && data.id == id) {
          this.id = data.id;
          const customer = data.customer;
          this.post_box = {
            ...data,
            branch_id: data.contract.branch_id,
            customer_name: customer.name,
            customer_phone_number: customer.phone_number,
            customer_address: customer.address,
          }
          this.center = {
            lat: this.post_box.lat,
            lng: this.post_box.lng,
          }
          this.placeMarker();
        } else {
         // this.$router.push({name: 'list.post_boxes'});
        }
      })
    },
  },
  async created() {
    await this.fetchPostBoxSizes();
    await this.fetchBranches();
    this.fetchData(this.$route.params.id);
    ValidateBranches(this);
  }
}
</script>

<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  #target {
    margin: 0 auto;
    right: 10px;

    .con-btns {
      width: 200px;
    }
  }

  .input {
    font-family: $font;
  }

  .box-upload-file {
    border: 1px dashed #6e6c6c;
    padding: 8px;
    width: 100%;
    margin-top: 2px;

    &:hover {
      cursor: pointer;
    }

    .text {
      text-align: left;
      color: #6e6c6c;

      i {
        width: 25px;
      }
    }

    .text-color {
      color: $primary-color;
    }
  }
}

// Basic control styles
.listhead {
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 0 0 15px 0;
  cursor: pointer;
  font-size: 16px;
}

// Hide default browser input
.listhead input {
  vertical-align: top;
  margin-right: 10px;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

// Custom control
.control_indicator {
  position: absolute;
  top: 1px;
  left: 4px;
  height: 23px;
  width: 23px;
  background: $white;
  border-radius: 4px;
}

// Hover and focus
.listhead:hover input ~ .control_indicatior,
.listhead input:focus ~ .control_indicator {
  background: $white;
}

// Checked
.listhead input:checked ~ .control_indicator {
  background: $white;
}

// Hide default browser input
.listhead input:disabled ~ .control_indicator {
  background: $primary-color;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.listhead input:checked ~ .control_indicator:after {
  display: block; // Show check
}

.listhead-checkbox .control_indicator:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 12px;
  border: solid $crimson;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.box-villages {
  margin-top: -30px;
  background: $white;
  width: 100%;
  padding: 10px;
  max-height: 320px;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;

  .village {
    background: $crimson;
    width: 100%;
    padding: 2px;
    height: 40px;
    border-radius: 2px;
    font-family: $font;
    color: $white;
  }
}

.box-not-found {
  padding: 20px;
  width: 100%;
  text-align: center;

  p {
    color: $crimson;
    font-size: 16px;
  }
}
</style>
