<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t('ຂໍ້ມູນOrderDetail.ຂໍ້ມູນ Order') }} ({{ $t('ລາຍການOrderພັດສະດຸ.ປະເພດສາຂາປາຍທາງ') }}
        {{ this.branch_type }})
      </h4>
      <span class="text-danger">
              {{ laError[server_errors.error] }}
            </span>
    </template>

    <div class="columns" :style="{ marginTop: '10px' }">
      <div class="field column">
        <div class="control">
          <label class="label">
            {{ $t('ຂໍ້ມູນOrderDetail.ຄ່າສິນຄ້າ') }}
            <span class="text-danger">* {{ errors.first("price") }}</span>
            <span class="text-danger">
              {{ server_errors.price }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="price"
              v-model="price"
              v-validate="'max:191|required|numeric|min_value:1'"
          />
          <div class="control">
            <label class="label"> {{ $t('ຂໍ້ມູນOrderDetail.ໝາຍເຫດ') }}
              <span class="text-danger">* {{ errors.first("remark") }}</span>
              <span class="text-danger">
              {{ server_errors.remark }}
            </span>
            </label>
            <input
                class="input"
                type="text"
                name="remark"
                v-model="remark"
                v-validate="'max:191|required'"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >{{ $t('ຂໍ້ມູນOrderDetail.ໃສ່ລາຄາ') }}
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

export default {
  props: ["shipment_number"],
  data() {
    return {
      branch_type: '',
      active: false,
      loading: false,
      freight: 0,
      price: 0,
      remark: '',
      shipment_detail: {},
      laError: {
        'ERROR ALL PARCEL NOT SAME STATE': 'ສະຖານະກ່ອງບໍ່ຢູ່ບ່ອນດຽວກັນ',
        'ERROR INVALID BILL NUMBER': 'ເລກບິນບໍ່ຖືກຕ້ອງ',
        'ERROR UN PROCESSABLE BILL NUMBER': 'ບິນບໍ່ຢູ່ໃນສະຖານະກຳລັງດຳເນີນການ',
        'ERROR NOT REGISTER COD': 'ລູກຄ້າບໍ່ໄດ້ລົງທະບຽນ COD'
      },
      server_errors: {
        price: "",
        freight: "",
        insurance_price: "",
        remark: "",
        error: ""
      },
    };
  },
  watch: {
    "shipment_detail": function () {
      this.freight = this.shipment_detail.parcels[0].freight;
      this.price = this.shipment_detail.parcels[0].price;
      this.insurance_price = this.shipment_detail.parcels[0].insurance_price;
      this.branch = this.shipment_detail.parcels[0].receive_branch_id;
      this.remark = null;
      this.$validator.reset();
    },
    "price": function () {
      this.server_errors.price = "";
    },
    "remark": function () {
      this.server_errors.remark = "";
    },
  },
  created() {
    const dict = {
      custom: {
        price: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          numeric: `(${this.$t('validator.numeric')})`,
          min_value:  `(${this.$t('validator.min_value', {min: 0})})`
        },
        remark: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
        },
      },
    };
    Validator.localize("en", dict);
    this.laError = {
      'ERROR ALL PARCEL NOT SAME STATE': this.$t('shipment.ສະຖານະກ່ອງບໍ່ຢູ່ບ່ອນດຽວກັນ'),
      'ERROR INVALID BILL NUMBER': this.$t('shipment.ເລກບິນບໍ່ຖືກຕ້ອງ'),
      'ERROR UN PROCESSABLE BILL NUMBER': this.$t('shipment.ບິນບໍ່ຢູ່ໃນສະຖານະກຳລັງດຳເນີນການ'),
      'ERROR NOT REGISTER COD': this.$t('shipment.ລູກຄ້າບໍ່ໄດ້ລົງທະບຽນ COD')
    };
    this.getDetail();
  },

  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditData();
        }
      });
    },

    branchTypeName(type) {
      if (type === 'branch') {
        return this.$t('common.main_branch')
      } else if (type === 'delivery') {
        return this.$t('ລາຍການOrderພັດສະດຸ.ເດລີເວີລີ')
      } else if (type === 'franchise') {
        return this.$t('ລາຍການOrderພັດສະດຸ.ສາຂາແຟນຊາຍ')
      } else {
        return this.$t('common.ຈຸດແຈກຢາຍ')
      }
    },

    getDetail() {
      this.$http.get('v1/auth/branches/shipments/orders/' + this.shipment_number + '/details')
          .then((res) => {
            this.branch_type = this.branchTypeName(res.data.end_branch.type)
            this.shipment_detail = {
              id: res.data.id,
              receive_branch_id: res.data.end_branch_id,
              receiver: {
                full_name: !res.data.receiver_customer.surname ? res.data.receiver_customer.name : res.data.receiver_customer.name + " " + res.data.receiver_customer.surname,
                phone_number: res.data.receiver_customer.tel,
                location: res.data.optional_metas.shipment_address.location
              },
              village_name: res.data.optional_metas.shipment_address.village_name,
              remark: res.data.remark,
              parcels: res.data.parcel_in_shipments.map((item) => {
                const parcelMeta = item.parcel;
                return {
                  id: parcelMeta.id,
                  name: parcelMeta.name,
                  width: parcelMeta.width,
                  height: parcelMeta.height,
                  length: parcelMeta.length,
                  weight: parcelMeta.weight,
                  dimension_length: parcelMeta.dimension_length,
                  volume: parcelMeta.volume,
                  weight_unit: parcelMeta.weight_unit,
                  volume_unit: parcelMeta.volume_unit,
                  size: parcelMeta.size ?? null,
                  size_meta: parcelMeta.size_meta ?? null,
                  price: parcelMeta.price,
                  insurance_price: parcelMeta.insurance_price,
                  freight: parcelMeta.freight,
                  detail: parcelMeta.detail,
                  parcel_type: parcelMeta.parcel_type,
                  parcel_category_id: parcelMeta.parcel_category_id
                }
              })
            }
          })
          .catch(() => {
            this.loading = false;
          });
    },

    EditData() {
      const {parcels} = this.shipment_detail;
      this.shipment_detail.remark = this.remark;
      this.shipment_detail.parcels = parcels.map((item) => {
        return {
          ...item,
          price: this.price,
          insurance_price: null,
          freight: this.freight
        }
      });
      this.loading = true;
      this.$http.post("add-cod-price", this.shipment_detail)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_EditItem_OnSuccess(
                  "top-right",
                  "dark",
                  3000
              );
            }, 300);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 422) {
              const obj = err.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
};
</script>
