import { Validator } from 'vee-validate';

const validate = {
    custom: {
        title:{
            required: '( ກະລຸນາປ້ອນຫົ້ວຂໍ້ກ່ອນ... )',
        },
        description:{
            required: '( ກະລຸນາປ້ອນລາຍລະອຽດກ່ອນ... )',
        },
        customers:{
            required: '( ກະລຸນາເລືອກລູກຄ້າກ່ອນ... )'
        }
    }
};

export const ValidateNews = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}