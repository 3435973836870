<template>
  <nav class="pagination is-centered is-rounded" role="navigation" aria-label="pagination">
    <a class="pagination-previous" @click.prevent="changePage(pagination.prev_page_url)"
       :disabled="pagination.prev_page_url == null">{{$t('common.previous')}}</a>
    <a class="pagination-next" @click.prevent="changePage(pagination.next_page_url)"
       :disabled="pagination.next_page_url == null">{{$t('common.next')}}</a>
  </nav>
</template>
<script>
export default {
  props: ['pagination', 'offset'],

  methods: {
    changePage(page) {
      if (page != null){
        const pattern = "cursor=";
        // var truncateAfter = function (str, pattern) {
        //   return str.slice(0, str.indexOf(pattern));
        // }
        this.pagination.cursor = page.slice(page.indexOf(pattern) + pattern.length);
        this.$emit('paginate');
      }
    },
  },
}
</script>
