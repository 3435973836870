<template>
    <div>
        <template>
          <h4 class="not-margin" :style="{marginTop: '10px'}">
              <img src="../../../assets/delete-trash.png" class="img-delete">
          </h4>
        </template>

        <div class="con-content" :style="{ marginTop: '5px' }">
            <div class="field">
              <div class="control">
                <label class="label">ເຫດຜົນ:
                    <span class="text-danger">* {{ errors.first('reason') }}</span>
                    <span class="text-danger">
                        {{server_errors.reason}}
                    </span>
                </label>
                <textarea 
                  placeholder="ກະລຸນາປ້ອນເຫດຜົນທີ່ປະຕິເສດ..." 
                  class="textarea has-fixed-size" 
                  rows="2" v-model="reason"
                  name="reason" v-validate="'required'"
                ></textarea>
              </div>
            </div>
        </div>

        <template>
          <div class="con-footer" :style="{ marginTop: '25px' }">
            <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="ValidateForm(orderRequestId)">
              ຢືນຢັນ
            </vs-button>
            <vs-button  @click="$emit('close')" dark transparent>
              ຍົກເລີກ
            </vs-button>
          </div>
        </template>
    </div>
</template>
<script>
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    reason:{
        required: '(ກະລຸນາປ້ອນເຫດຜົນກ່ອນ...)',
    },
  }
};
Validator.localize('en', dict);

export default {
  props:['orderRequestId'],
  
  data:()=>({
    loading: false,
    reason: '',
    server_errors:{
      reason: '',
    }
  }),
  methods:{
    ValidateForm(orderRequestId){
        this.$validator.validateAll().then((result) => {
            if (result) {
                this.DeleteItem(orderRequestId);
            }
        });
    },
    DeleteItem(orderRequestId){
      this.loading = true;
      this.$axiosDelivery.get('reject-order-requests/' + orderRequestId,{
        params:{
          reason: this.reason
        }
      }).then(res=>{
          if(res.data.success == true){
              setTimeout(() => {
                this.loading = false;
                this.$emit('close');
                this.$emit('success');
                this.$notification.OpenNotification_Reject_OrderRequests('top-right', 'dark', 3000);
              }, 300);
          }
      }).catch(()=>{
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
        this.$notification.OpenNotification_Reject_OrderRequests_Errors('top-right', 'danger', 3000);
      });
    }
  }
}
</script>
<style lang="scss">

</style>