<template>
  <div class="container">
    <div class="columns box-title is-12" style="margin-top: -10px;">
      <div class="column is-6">
        <h4 class="has-text-left" style="margin-top: -26px;">
          <i class="fal fa-list"></i>
          ລາຍການອິນວອຍ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box" :style="{marginTop: '-20px'}">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາປາຍທາງ:</label>
            <model-list-select
                :list="listBranches"
                v-model="selectedBranch"
                placeholder="ເລືອກສາຂາ..."
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="selectedStatus">
                <option value="">ທັງໝົດ</option>
                <option value="0">ກຳລັງດຳເນີນການ</option>
                <option value="1">ສຳເລັດ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="fetchData()"
              class="form-btn-loading"
              :loading="loading">
            ຄົ້ນຫາ
          </vs-button>
        </div>
      </div>
      <div class="columns" style="margin-top: -20px">
        <div class="column is-6" style="margin-top: -20px;margin-right: -10px">
          <vs-button
              ref="button" flat
              @click="exportSplit('all',null)"
              class="form-btn-loading"
              :disabled="listPayments.length === 0"
              style="width: 45%; float: right"
              :loading="loading">
            Export - {{ count }} ( ເເຍກ )
          </vs-button>
        </div>
        <div class="column is-6" style="margin-top: -20px;margin-left: -10px">
          <vs-button
              ref="button" flat
              @click="exportTotal()"
              class="form-btn-loading"
              :disabled="listPayments.length === 0"
              style="width: 45%; float: left"
              :loading="loading">
            Export - {{ count }} ( ລວມ )
          </vs-button>
        </div>
      </div>
    </div>

    <div class="center" :style="{marginTop: '-10px'}">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລວມເປັນເງິນທັງໝົດ</vs-th>
            <vs-th>ລວມສາຂາ</vs-th>
            <vs-th>ລວມບິນ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :is-selected="true">
            <vs-td v-if="totalPaid !== 0">{{ FormatPrice(totalPaid) }} ກີບ</vs-td>
            <vs-td v-else>0 ກີບ</vs-td>
            <vs-td>{{ count }} ສາຂາ</vs-td>
            <vs-td>{{ totalShipment }} ບິນ</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເລກອິນວອຍ</vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th>ວັນທີສຳເລັດ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ລາຄາ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ຮູບພາບ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="payment"
              v-for="(payment, index) in $vs.getPage(listPayments, page, max)">
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="exportSplit(payment.id, payment.branch_name)">
                  <i class="fad fa-print"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ payment.number }}</vs-td>
            <vs-td>{{ moment(payment.created_at).format('DD/MM/YYYY HH:mm') }}</vs-td>
            <vs-td v-if="payment.success_at">{{ moment(payment.success_at).format('DD/MM/YYYY HH:mm') }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ payment.branch_name }}</vs-td>
            <vs-td>{{ FormatPrice(payment.paid) }}</vs-td>
            <vs-td>{{ laStatus[payment.status] }}</vs-td>
            <vs-td>
              <div v-if="payment.image_url" class="img-size">
                <expandable-image
                    class="image"
                    :src=payment.image_url
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ payment.remark }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="fetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/CusorPagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect
  },

  props: ['onclickEvent'],

  data: () => ({
    laStatus: {
      0: 'ກຳລັງດຳເນີນການ',
      1: 'ສຳເລັດ'
    },
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    listPayments: [],
    listBranches: [],
    selectedBranch: '',
    selectedStatus: '',
    // Pagination
    count: 0,
    totalPaid: 0,
    totalShipment: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),

  methods: {
    exportSplit(id, branch_name) {
      const loading = this.BtnLoading();
      this.loading = true;
      const type = (this.$route.query.tab === 'origin') ? 'ຍອດສຳເລັດບິນຕົ້ນທາງ' : 'ຍອດສຳເລັດບິນປາຍທາງ'
      this.$http.post(`export/invoice/franchise/split/${id}`, {
        start_date: this.start_date,
        end_date: this.end_date,
        type: this.$route.query.tab,
        branch_id: this.selectedBranch,
        status: this.selectedStatus,
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (id === 'all') {
          if (this.start_date === this.end_date) {
            fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type}ທັງໝົດວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
          } else {
            fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type}ທັງໝົດວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
          }
        } else {
          if (this.start_date === this.end_date) {
            fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type}${branch_name} ວັນທິ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
          } else {
            fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type}${branch_name} ວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
          }
        }

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },
    exportTotal() {
      const loading = this.BtnLoading();
      this.loading = true;
      const type = (this.$route.query.tab === 'origin') ? 'ຍອດສຳເລັດບິນຕົ້ນທາງ' : 'ຍອດສຳເລັດບິນປາຍທາງ'
      this.$http.post(`export/invoice/franchise/total`, {
        start_date: this.start_date,
        end_date: this.end_date,
        type: this.$route.query.tab,
        branch_id: this.selectedBranch,
        status: this.selectedStatus,
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type}ທັງໝົດວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', `ລາຍການອິນວອຍ${type}ທັງໝົດວັນທີ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2021-08-01 00:00:00');
      return date < fixDay;
    },
    fetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.listPayments = res.data.data.data.data;
            this.count = res.data.data.total;
            this.totalShipment = res.data.data.totalShipment;
            this.totalPaid = res.data.data.totalPaid;
            this.pagination = res.data.data.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/invoice/franchise', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          type: this.$route.query.tab,
          branch_id: this.selectedBranch,
          status: this.selectedStatus,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    fetchBranch() {
      this.$http.get('v1/listing/' + 'branches' + '?type=franchise' + '&include=excluded').then((res) => {
        this.listBranches = res.data;
        this.listBranches.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.fetchBranch();
  },
  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.listPayments = [];
        this.count = 0;
        this.totalShipment = 0;
        this.totalPaid = 0;
        this.pagination = {};
      }
    }
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
