<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t('shipment.ລົງທະບຽນລູກຄ້າປະເພດບໍລິສັດ') }}
      </h4>
    </template>

    <!-- name -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('shipment.name') }}
          <span class="text-danger">* {{ errors.first("name") }}</span>
          <span class="text-danger">
              {{ server_errors.name }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="name"
            :placeholder="$t('validator.required')"
            v-model="value.name"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- email -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('shipment.email') }}
          <span class="text-danger"
          >* {{ errors.first("email") }}</span
          >
          <span class="text-danger">
              {{ server_errors.email }}
            </span>
        </label>
        <input
            class="input"
            type="email"
            name="email"
            :placeholder="$t('validator.required')"
            v-model="value.email"
            v-validate="'required|max:191|email'"
        />
      </div>
    </div>

    <!-- password -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('shipment.password') }}
          <span class="text-danger"> {{ errors.first("password") }}</span>
          <span class="text-danger">
              {{ server_errors.password }}
            </span>
        </label>
        <input
            autocomplete="off"
            class="input"
            type="password"
            name="password"
            :placeholder="$t('shipment.password') + '...'"
            v-model="value.password"
            v-validate="'max:191|min:6'"
        />
      </div>
    </div>

    <!-- tel -->
    <div class="field column">
      <label class="label" style="text-align: left">
        {{ $t('shipment.tel') }}
        <span class="text-danger">* {{ errors.first("tel_add") }}</span>
        <span class="text-danger">
            {{ server_errors.tel }}
          </span>
      </label>
      <div class="field has-addons">
        <p class="control">
            <span class="select">
              <select style="min-width: 80px" v-model="telType">
                <option value="020">020</option>
                <option value="021">021</option>
                <option value="030">030</option>
                <option value="+86">+86</option>
              </select>
            </span>
        </p>
        <p class="control" style="flex: auto">
          <input
              class="input"
              type="text"
              name="tel_add"
              placeholder="XXXXXXX..."
              v-model="value.tel"
              v-validate=" {
                required: true,
                numeric: true,
                max: telLength,
                min: telLength,
              }"
          />
        </p>
      </div>
    </div>

    <!-- discount -->
    <div class="field column">
      <div class="control">
        <label class="label">
          {{ $t('ລາຍການOrderພັດສະດຸ.ສ່ວນຫຼຸດ') }} %
          <span class="text-danger"> {{ errors.first("discount") }}</span>
          <span class="text-danger">
              {{ server_errors.discount }}
            </span>
        </label>
        <number-input class="number-input" v-model="value.discount"
                      :placeholder="$t('ລາຍການOrderພັດສະດຸ.ສ່ວນຫຼຸດ') + '...'"/>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading">
          {{ $t('shipment.ບັນທຶກລູກຄ້າປະເພດບໍລິສັດ') }}
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

export default {
  data() {
    return {
      active: false,
      isLoading: false,
      server_errors: {
        name: "",
        email: "",
        tel: "",
        password: "",
        discount: ""
      },
      value: {
        name: "",
        email: "",
        tel: "",
        password: "",
        discount: 0,
      },
      telType: "020",
    };
  },
  computed: {
    telLength() {
      const telTypeLengths = {
        '020': 8,
        '021': 6,
        '+86': 11,
        'default': 7
      };
      return telTypeLengths[this.telType] || telTypeLengths['default']
    },
    zone() {
      if (this.telLength === 11) {
        return 'zh'
      }
      return 'la'
    }
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      const loading = this.BtnLoading();
      this.$http
          .post("insert-company", {...this.value, zone: this.zone})
          .then((res) => {
            if (res.data.success) {
              setTimeout(() => {
                loading.close();
                this.$emit("close");
                this.$emit("success");
                this.$notification.OpenNotification_AddItem_OnSuccess(
                    "top-right",
                    "dark",
                    3000
                );
              }, 300);
            }
          })
          .catch((error) => {
            loading.close();
            if (error.response.status === 422) {
              var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
  watch: {
    "value.tel": function () {
      this.server_errors.tel = "";
    },
    "value.email": function () {
      this.server_errors.email = "";
    },
  },
  created() {
    const dict = {
      custom: {
        name: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
        },

        email: {
          required: `(${this.$t('validator.required')}...)`,
          email: `(${this.$t('validator.email_invalid')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
        },

        tel_add: {
          required: `(${this.$t('validator.required')}...)`,
          numeric: `(${this.$t('validator.numeric')})`,
          max: (name, rules) => {
            return `(${this.$t('validator.limit_character', {length: rules[0]})})...`;
          },
          min: (name, rules) => {
            return `(${this.$t('validator.limit_character', {length: rules[0]})})...`;
          },
        },

        password: {
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          min: `(${this.$t('validator.min_character',  {min: 6})}...)`,
        },

        discount: {
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          numeric: `(${this.$t('validator.numeric')})`,
        }
      },
    };
    Validator.localize("en", dict);
  },
};
</script>
<style lang="scss" scoped>
.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.25rem;
}
</style>
