<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        ລົງທະບຽນລູກຄ້າ
      </h4>
    </template>

    <!-- customer_name -->
    <div class="field column">
      <div class="control">
        <label class="label">
          ຊື່
          <span class="text-danger">* {{ errors.first("name") }}</span>
          <span class="text-danger">
              {{ server_errors.name }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="name"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="value.name"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- surname -->
    <div class="field column">
      <div class="control">
        <label class="label">
          ນາມສະກຸນ
          <span class="text-danger"
          >* {{ errors.first("surname") }}</span
          >
          <span class="text-danger">
              {{ server_errors.surname }}
            </span>
        </label>
        <input
            class="input"
            type="text"
            name="surname"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="value.surname"
            v-validate="'required|max:191'"
        />
      </div>
    </div>

    <!-- tel -->
    <div class="field column">
        <label class="label" style="text-align: left">
          ເບີໂທ
          <span class="text-danger">* {{ errors.first("tel") }}</span>
          <span class="text-danger">
            {{ server_errors.tel }}
          </span>
        </label>
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select style="min-width: 80px" v-model="telType">
                <option value="020">020</option>
                <option value="030">030</option>
              </select>
            </span>
          </p>
          <p class="control" style="flex: auto">
            <input
                class="input"
                type="text"
                name="tel"
                placeholder="XXXXXXX..."
                v-model="value.tel"
                v-validate="{
                required: true,
                numeric: true,
                max: telType === '020' ? 8 : 7,
                min: telType === '020' ? 8 : 7,
              }"
            />
          </p>
        </div>
    </div>

    <!-- password -->
    <div class="field column">
      <div class="control">
        <label class="label">
          ລະຫັດຜ່ານ
          <span class="text-danger">* {{ errors.first("password") }}</span>
          <span class="text-danger">
              {{ server_errors.password }}
            </span>
        </label>
        <input
            autocomplete="off"
            class="input"
            type="password"
            name="password"
            placeholder="ລະຫັດຜ່ານ..."
            v-model="value.password"
            v-validate="'required|max:191|min:6'"
        />
      </div>
    </div>
    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
        >ບັນທຶກລູກຄ້າ
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    surname: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },

    tel: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      numeric: "(ປ້ອນໄດ້ພຽງຕົວເລກ...)",
      max: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວອັກສອນ...)`;
      },
      min: (name, rules) => {
        return `(ຕ້ອງປ້ອນ ${rules[0]} ຕົວອັກສອນ...)`;
      },
    },

    password: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
      min: "(ກະລຸນາປ້ອນຢ່າງຕຳ່ 6 ຕົວອັກສອນ...)"
    },
  },
};
Validator.localize("en", dict);

export default {
  data() {
    return {
      active: false,
      isLoading: false,
      server_errors: {
        name: "",
        surname: "",
        tel: "",
        password: ""
      },
      value: {
        name: "",
        surname: "",
        tel: "",
        password: ""
      },
      telType: "020",
    };
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      const loading = this.BtnLoading();
      this.$http
          .post("admin-add-customer", this.value)
          .then((res) => {
            if (res.data.success) {
              setTimeout(() => {
                loading.close();
                this.$emit("close");
                this.$emit("success");
                this.$notification.OpenNotification_AddItem_OnSuccess(
                    "top-right",
                    "dark",
                    3000
                );
              }, 300);
            }
          })
          .catch((error) => {
            loading.close();
            if (error.response.status === 422) {
              var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
  watch: {
    "value.tel": function () {
      this.server_errors.tel = "";
    },
  },
};
</script>
<style lang="scss" scoped>
</style>