<template>
    <div class="container" :style="{marginTop: '5px'}">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                  ຂໍ້ມູນ ຄຸປ໋ອງ
                <span class="countTotal">({{listCoupons.length}})</span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>ລຳດັບ</vs-th>
                    <vs-th>ລະຫັດຄູປ໋ອງ</vs-th>
                    <vs-th>ຊື່ຄູປ໋ອງ</vs-th>
                    <vs-th>ສ່ວນຫຼຸດ</vs-th>
                    <vs-th>ຈຳນວນ</vs-th>
                    <vs-th>ວັນທີ່ໝົດອາຍຸ</vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="index" v-for="(coupon, index) in $vs.getPage(listCoupons, page, max)" :data="coupon">
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td>
                            ( <span :style="{color: 'crimson'}">{{coupon.code}}</span> )
                        </vs-td>
                        <vs-td :style="{width: '230px'}">{{ coupon.name }}</vs-td>
                        <vs-td>
                            <span v-if="coupon.discount_type == 'percent'">{{coupon.discount}} %</span>
                            <span v-else>{{FormatDiscount(coupon.discount)}}</span>
                        </vs-td>
                        <vs-td><span>{{coupon.total_amount}}</span></vs-td>
                        <vs-td><span>{{FormatDate(coupon.valid_date)}}</span></vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat primary @click="OpenModalReopen(coupon.id)">
                                    <i class="fal fa-folder-open"></i>
                                </vs-button>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listCoupons, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalClose>
             <template v-slot="{ close }">
                <ReopenCoupons @close="close" :couponId="couponId" @success="FetchData()"/>
            </template>
        </ModalClose>

    </div>
</template>
<script>
import ReopenCoupons from './Dialog/Reopen';
import Loading from '../../Loading';
import moment from 'moment';


export default {
    components:{
        ReopenCoupons,
        Loading
    },
    data:()=>({
        active: 1,
        page: 1,
        max: 15,
        listCoupons: [],  
        couponId: '',
        isLoading: true,
    }),
    methods:{
        FormatDiscount(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        },
        FormatDate(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
        OpenModalReopen(couponId){
            this.couponId = couponId;
            this.$store.commit('modalClose_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-coupons').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.listCoupons = res.data.listCouponOfflines;
                }, 100);
            }).catch(()=>{
                
            });
        },
        FormatPrice(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        }
    },
    created(){
        this.FetchData();
    },
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>