import { Validator } from 'vee-validate';

const validate_packagesizes = {
    custom: {
        package_size:{
            required: '(ກະລຸນາປ້ອນເເພ໋ກເເກ໋ດກ່ອນ...)',
        },
        start_weight:{
            required: '(ກະລຸນາປ້ອນຂະໜາດກ່ອນ...)',
        },
        package_type:{
            required: '(ກະລຸນາເລືອກປະເພດກ່ອນ...)',
        }
    },
};

const validate_packages = {
    custom: {
        package_name:{
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        package_size:{
            required: '(ກະລຸນາເລືອກ່ອນ...)',
        },
        package_price:{
            required: '(ກະລຸນາເລືອກ່ອນ...)',
            numeric: '(ຄາລາຄວນເປັນໂຕເລກ...)',
        },
        packing_box:{
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
            numeric: '(ຄາລາຄວນເປັນໂຕເລກ...)',
        },
        level:{
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
    },
};

export const ValidatePackageSize = (Vue) => {
    Validator.localize('en', validate_packagesizes);
    Vue.$validator.localize('en', validate_packagesizes);
}

export const ValidatePackages = (Vue) => {
    Validator.localize('en', validate_packages);
    Vue.$validator.localize('en', validate_packages);
}
