<template>
  <div>
    <div class="tabs card-tabs">
      <ul>
        <li v-for="(item, index) in titles" :key="index">
          <a style="font-weight: 500">{{ item.name }}
            <div class="badge-color">
              ({{ item.count }})
            </div>
          </a>
        </li>
      </ul>
      <vs-button @click="exportBranchesToExcel" success style="border-radius: 4px !important;"
                 :loading="isExporting">
        Export Excel
      </vs-button>
    </div>
    <div class="container">
      <div class="box-title">
        <h4>
          <i class="fal fa-users"></i>
          ຂໍ້ມູນ ສາຂາທັງໝົດ
          <span class="countTotal">({{ count }})</span>
          <span class="btn-add">
          <div class="columns">
<!--        <div class="field" :style="{marginRight: '2px'}">-->
            <!--               <vs-button circle icon flat-->
            <!--                          style="padding-right: 10px;"-->
            <!--                          @click="exportUsersToExcel"-->
            <!--                          :loading="isExporting">-->
            <!--                  <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="18px"-->
            <!--                       viewBox="0 0 384 512"><path fill="currentColor"-->
            <!--                                                   d="M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM208 216c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88.02h-52.66c-11 0-20.59 6.41-25 16.72-4.5 10.52-2.38 22.62 5.44 30.81l68.12 71.78c5.34 5.59 12.47 8.69 20.09 8.69s14.75-3.09 20.09-8.7l68.12-71.75c7.81-8.2 9.94-20.31 5.44-30.83-4.41-10.31-14-16.72-25-16.72H208V216zm42.84 120.02l-58.84 62-58.84-62h117.68z"></path></svg>-->
            <!--                 <b>ລາຍຊື່ຜູ້ໃຊ້</b>-->
            <!--               </vs-button>-->
            <!--              </div>-->
              <div class="field" style="margin-right: 12px">
                 <vs-button circle icon flat @click="OpenModalAdd()">
                    <i class="fal fa-plus-circle"></i>
                 </vs-button>
              </div>
          </div>
        </span>
        </h4>
      </div>

      <div class="box">
        <div class="columns">
          <div class="column is-4">
            <div class="control">
              <label class="label">ຄົ້ນຫາ</label>
              <input class="input" placeholder="ຄົ້ນຫາ..." v-model="searchResult" @keyup.enter="SearchBranch()">
            </div>
          </div>
          <div class="column is-2">
            <div class="control">
              <label class="label">ປະເພດສາຂາ</label>
              <div class="select">
                <select v-model="selectedType.id" name="branch">
                  <option :value="type.id" v-for="(type, index) in types" :key="index">
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-2">
            <div class="control">
              <label class="label">ເລືອກພາກ</label>
              <div class="select">
                <select class="input" v-model="selectedPart" @change="Filter_Branch_By_Part(selectedPart)">
                  <option :value="selectedAll">ທັງໝົດ</option>
                  <option v-for="(part, index) in parts" :key="index" :value="part.partId">
                    {{ translateInloas[part.name] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-2">
            <div class="control">
              <label class="label">ເລືອກເເຂວງ</label>
              <div class="select">
                <select class="input" v-model="selectedProvince" @change="Filter_Branch_By_Province(selectedProvince)"
                        :disabled="disabled_SelectedProvince">
                  <option :value="selectedAll">ທັງໝົດ</option>
                  <option v-for="(province, index) in provinces" :key="index" :value="province.proId">
                    {{ province.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-2">
            <div class="control">
              <label class="label">ເລືອກເມືອງ</label>
              <div class="select">
                <select class="input" v-model="selectedDistrict" @change="Filter_Branch_By_District(selectedDistrict)"
                        :disabled="disabled_SelectedDistrict">
                  <option :value="selectedAll">ທັງໝົດ</option>
                  <option v-for="(district, index) in districts" :key="index" :value="district.distId">
                    {{ district.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ລຳດັບ</vs-th>
              <vs-th>ລະຫັດ</vs-th>
              <vs-th>ສາຂາສາງພັດສະດຸ</vs-th>
              <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
              <vs-th>ອີເມວ</vs-th>
              <vs-th>ສະຖານທີ່</vs-th>
              <vs-th>ປະເພດສາຂາ</vs-th>
              <vs-th>ສະຖານະ</vs-th>
              <vs-th></vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr
                :key="index" v-for="(branch, index) in branches">
              <vs-td :style="{textAlign: 'center'}">{{ index + 1 }}</vs-td>
              <vs-td :style="{maxWidth: '100px', overflow: 'hidden',
             textOverflow: 'ellipsis', color: 'crimson'}">
                <vs-tooltip>
                  ( {{ branch.code != '-' ? branch.code : branch.prefix }} )
                  <template #tooltip>
                    ( {{ branch.code != '-' ? branch.code : branch.prefix }} )
                  </template>
                </vs-tooltip>
              </vs-td>
              <vs-td :style="{maxWidth: '150px'}">{{ branch.name }}</vs-td>
              <vs-td :style="{width: '20%'}">{{ branch.tel }}</vs-td>
              <vs-td :style="{maxWidth: '150px'}">
                <vs-tooltip>
                  {{ branch.email !== null ? branch.email : '...' }}
                  <template #tooltip>
                    {{ branch.email !== null ? branch.email : '...' }}
                  </template>
                </vs-tooltip>
              </vs-td>
              <vs-td style="color: #195BFF; font-size: 16px">
                <vs-tooltip>
                  {{ branch.villageName }}
                  <template #tooltip>
                    ( {{ translateInloas[branch.partName] }},
                    {{ branch.proName }},
                    {{ branch.distName }},
                    {{ branch.villageName }} )
                  </template>
                </vs-tooltip>
              </vs-td>
              <vs-td :style="{maxWidth: '95px'}">
                <span v-if="branch.type === 'branch'">ສາຂາທົ່ວໄປ</span>
                <span v-if="branch.type === 'franchise'">ສາຂາເເຟນຊາຍ</span>
                <span v-if="branch.type === 'delivery'">ສາຂາດີລີເວີລີ່</span>
                <span v-if="branch.type === 'local_branch'">ຈຸດສູນແຈກຢາຍ</span>
                <span v-else>{{ branchInLaos[branch.type] || '' }}</span>
              </vs-td>
              <vs-td>
                <span style="color: green" v-if="branch.status === 1">ເປີດ</span>
                <span style="color: red;" v-else>ປິດ</span>
              </vs-td>
              <vs-td style="text-align: right; width: 100px">
                <div class="group-icon">
                  <vs-button circle icon flat color="amazon" @click="linkOtherBranches(branch)">
                    <i class="fal fa-link"></i>
                  </vs-button>
                  <vs-button circle icon flat @click="PreviewProfile(branch.profile)">
                    <i class="fal fa-image"></i>
                  </vs-button>
                  <vs-button v-if="$can('SuperAdmin')" circle icon flat @click="ViewBranchUsers(branch.id)">
                    <i class="fal fa-plus-circle"></i>
                  </vs-button>
                  <vs-button circle icon flat @click="OpenModalEdit(branch.id)">
                    <i class="fad fa-pencil-alt"></i>
                  </vs-button>
                  <vs-button v-if="$can('SuperAdmin')" circle danger icon flat @click="OpenModalDelete(branch.id)">
                    <i class="fad fa-trash-alt"></i>
                  </vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
          <template #footer>
            <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                        @paginate="FetchData($route.params.type)"></pagination>
            <Loading v-if="isLoading"/>
          </template>
        </vs-table>
      </div>

      <ModalDelete>
        <template v-slot="{ close }">
          <DeleteBranches @close="close" :branchId="branchId" @success="FetchData($route.params.type)"/>
        </template>
      </ModalDelete>

      <ModalPreviewFile>
        <template v-slot="{ close }">
          <PreViewFile @close="close" :profile="profile"/>
        </template>
      </ModalPreviewFile>

    </div>
  </div>
</template>
<script>
import DeleteBranches from './Dialog/Delete';
import PreViewFile from './Dialog/Preview-File';
import Loading from '../../Loading';
import pagination from '../../../Plugins/Paginations/Pagination';
import {BranchGetValueOnInput} from '@/Helpers/GetValue';

export default {
  components: {
    DeleteBranches,
    PreViewFile,
    Loading,
    pagination
  },

  data: () => ({
    isLoading: true,
    isExporting: false,
    branches: [],
    filterBranches: [],
    branchId: '',
    profile: '',
    translateInloas: {
      center: 'ພາກກາງ',
      south: 'ພາກໃຕ້',
      north: 'ພາກເໜືອ',
      northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
      southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
      southern: 'ພາກໃຕ້-1'
    },
    branchInLaos: {
      branch: 'ສາຂາທົ່ວໄປ',
      franchise: 'ສາຂາເເຟນຊາຍ',
      delivery: 'ສາຂາດີລີເວີລີ່',
      local_branch: 'ຈຸດສູນແຈກຢາຍ',
      post_box: 'ກ່ອງໄປສະນີ',
      central: 'ສູນແກ້ໄຂ',
      supervisor_central: 'ສູນແກ້ໄຂໃຫຍ່',
    },
    parts: [],
    selectedPart: '',
    selectedAll: 'all',

    provinces: [],
    selectedProvince: '',
    districts: [],
    selectedDistrict: '',
    count: 0,

    filterProvinces: [],
    filterDistricts: [],

    disabled_SelectedProvince: true,
    disabled_SelectedDistrict: true,
    searchResult: '',
    oldVal: '',

    // Pagination
    offset: 10,
    pagination: {},
    per_page: 10,

    // isExporting: false,

    types: [
      {id: 'all', name: 'ທັງໝົດ'},
      {id: 'branch', name: 'ສາຂາທົ່ວໄປ'},
      {id: 'franchise', name: 'ສາຂາເເຟນຊາຍ'},
      {id: 'delivery', name: 'ສາຂາດີລີເວີລີ່'},
      {id: 'local_branch', name: 'ສາຂາຈຸດແຈກຢາຍ'},
      {id: 'post_box', name: 'ກ່ອງໄປສະນີ'},
      {id: 'central', name: 'ສູນແກ້ໄຂ'},
      {id: 'supervisor_central', name: 'ສູນແກ້ໄຂໃຫຍ່'},
    ],
    selectedType: {id: 'all', name: 'ທັງໝົດ'},
    titles: [
      {count: '0', name: 'ສາຂາຫຼັກ(HL)-ເປີດ'},
      {count: '0', name: 'ສາຂາຫຼັກ(HL)-ປິດ'},
      {count: '0', name: 'ສາຂາຕົວແທນ(HC)-ເປີດ'},
      {count: '0', name: 'ສາຂາຕົວແທນ(HC)-ປິດ'},
    ],
  }),
  methods: {
    linkOtherBranches(branch) {
      this.$router.push({name: 'branch-links', params: {id: branch.id, type: branch.type}});
    },
    PreviewProfile(profile) {
      this.profile = profile;
      this.$store.commit('modalPreviewFile_State', true);
    },
    ViewBranchUsers(branchId) {
      this.$router.push({name: 'list.branch.users', query: {branch_id: branchId}});
    },
    OpenModalAdd() {
      this.$router.push({name: 'add.branches'});
    },
    OpenModalEdit(branchId) {
      this.$router.push({name: 'edit.branches', params: {id: branchId}});
    },
    OpenModalDelete(branchId) {
      this.branchId = branchId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      // this.$route.params.type
      this.isLoading = true;
      this.$axios.get('list-branches', {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          partId: this.selectedPart,
          provinceId: this.selectedProvince,
          districtId: this.selectedDistrict,
          result: this.searchResult,
          type: this.selectedType.id
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.branches = res.data.branches.data;
          this.filterBranches = res.data.branches.data;
          this.pagination = res.data.branches;
          this.count = this.pagination.total;
        }, 100);
      }).catch(() => {
      });
    },
    FetchAddresses() {
      this.$axios.get('list-branch-address').then(res => {
        const items = res.data.data;

        this.parts = items.parts;
        this.selectedPart = this.selectedAll;
        this.provinces = items.provinces;
        this.selectedProvince = this.selectedAll;
        this.districts = items.districts;
        this.selectedDistrict = this.selectedAll;

        this.filterProvinces = items.provinces;
        this.filterDistricts = items.districts;
      }).catch(() => {
      });
    },

    Filter_Branch_By_Part(partId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedProvince = this.selectedAll;
      this.selectedDistrict = this.selectedAll;
      this.FilterProvince(partId);
      this.FetchData();
      setTimeout(() => {
        if (partId == 'all') {
          this.isLoading = false;
          this.disabled_SelectedProvince = true;
          this.disabled_SelectedDistrict = true;
          return;
        }
        this.isLoading = false;
        this.disabled_SelectedProvince = false;
      }, 200);
    },

    Filter_Branch_By_Province(provinceId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedDistrict = this.selectedAll;
      this.FilterDistrict(provinceId);
      this.FetchData();
      setTimeout(() => {
        if (provinceId == 'all') {
          this.disabled_SelectedDistrict = true;
          if (this.selectedPart == 'all') {
            this.isLoading = false;
            return;
          } else {
            this.Filter_Branch_By_Part(this.selectedPart);
          }
        } else {
          this.isLoading = false;
          this.disabled_SelectedDistrict = false;
        }
      }, 200);
    },

    Filter_Branch_By_District(districtId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.FetchData();
      setTimeout(() => {
        if (districtId == 'all') {
          if (this.selectedProvince == 'all') {
            this.isLoading = false;
            return;
          } else {
            this.Filter_Branch_By_Province(this.selectedProvince);
          }
        }
        this.isLoading = false;
      }, 200);
    },

    // Filter Provinces
    FilterProvince(partId) {
      const result = this.filterProvinces.filter(item => {
        return item.part_id == partId;
      });
      this.provinces = JSON.parse(JSON.stringify(result));
    },
    // Filter Districts
    FilterDistrict(districtId) {
      const result = this.filterDistricts.filter(item => {
        return item.province_id == districtId;
      });
      this.districts = JSON.parse(JSON.stringify(result));
    },
    SearchBranch() {
      BranchGetValueOnInput(this);
    },
    exportBranchesToExcel() {
      if (this.isExporting) {
        return;
      }
      this.isExporting = true;

      const body = {};
      this.$store.dispatch('exportBranchListToExcel', {
        httpClient: this.$http,
        body: body
      }).catch(() => {
      }).finally(() => {
        this.isExporting = false;
      })
    },
    fetchBranchesCount() {
      this.$axios.get('branches-counting').then(res => {
        const counting = res.data.count;
        this.titles[0].count = counting.main_opened_count;
        this.titles[1].count = counting.main_closed_count;
        this.titles[2].count = counting.other_opened_count;
        this.titles[3].count = counting.other_closed_count;
        console.log(counting);
      }).catch(() => {
      });
    }
  },
  created() {
    if (this.$route.params.type) {
      this.FetchData();
    }
    this.FetchAddresses();
    this.fetchBranchesCount();
  },
  watch: {
    'searchResult': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
    'selectedType.id': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    }
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}
</style>

<style scoped lang="scss">
.card-tabs {
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: -13px;

  ul {
    margin: 0;
  }
}

.badge-color {
  font-size: 24px;
  font-weight: 900;
  margin-left: 12px;
  color: red;
}

.tabs {
  a {
    border-bottom-color: #FFFFFF;

    &:hover {
      border-bottom-color: #FFFFFF;
    }
  }

  ul {
    border-bottom-color: #FFFFFF;
  }
}
</style>
