export default {
    data:()=>({
        active: false,
        btnLoading: false,
        server_errors: {
            package_type: '',
            package_size: '',
            start_weight: '',
        },
        value: {},

        packageTypes: [],
        selected_PackageType: {},

        isEndWeight_Disable: false,
        isRound_Disable: true,
    }),

    methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditData();
                }
            });
        },
        EditData(){
            if(this.packagesize.package_type_id == 1){
                this.packagesize.end_weight = null;
            }else if(this.packagesize.package_type_id == 2){
                this.packagesize.round = null;
            }
            const loading = this.BtnLoading();
            this.btnLoading = true;
            this.$axios.put('edit-package-sizes', this.packagesize).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        loading.close();
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                loading.close();
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
        BtnLoading(){
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        },
        FetchData(){
            this.$axios.get('list-package-types').then((res)=>{
                this.packageTypes = res.data.package_types;
                this.selected_PackageType = {...this.packageTypes[0]};
            }).catch(()=>{});
        },
        CheckPackageType(){
            if(this.packagesize.package_type_id == 1){
                this.isEndWeight_Disable = false;
                this.isRound_Disable = true;
            }else{
                this.isEndWeight_Disable = true;
                this.isRound_Disable = false;
            }
        }
    },
    watch:{
        'packagesize.package_type_id':function(value){
            if(value == 1){
                this.isEndWeight_Disable = false;
                this.isRound_Disable = true;
            }else{
                this.isEndWeight_Disable = true;
                this.isRound_Disable = false;
            }
        }
    }
}