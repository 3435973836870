<template>
  <div class="container">
    <div class="box-title" id="scrollTo">
      <h4>
        <i class="fal fa-plus-circle"></i> ເພີ່ມ ຂໍ້ມູນສາຂາພັດສະດຸ
      </h4>
    </div>

    <div class="columns is-multiline" :style="{marginTop: '20px'}">
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ລະຫັດສາຂາ
              <span class="text-danger">* {{ errors.first('prefix') }}</span>
              <span class="text-danger">
                                {{ server_errors.prefix }}
                            </span>
            </label>
            <input
                class="input" name="prefix"
                placeholder="ກະລຸນາປ້ອນລະຫັດສາຂາ..."
                v-model="branches.prefix"
                v-validate="'required|max:25|min:3'"
            >
            <label class="label">
              *ເລືອກເມືອງລະບົບດຶງ ID ອໍໂຕ້
            </label>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ສາຂາພັດສະດຸ
              <span class="text-danger">* {{ errors.first('name') }}</span>
              <span class="text-danger">
                                {{ server_errors.name }}
                            </span>
            </label>
            <input class="input" name="name" placeholder="ກະລຸນາປ້ອນສາຂາກ່ອນ..." v-model="branches.name"
                   v-validate="'required'">
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ເບີໂທຕິດຕໍ່
              <span class="text-danger">* {{ errors.first('tel') }}</span>
              <span class="text-danger">
                                {{ server_errors.tel }}
                            </span>
            </label>
            <input
                class="input" name="tel"
                placeholder="ກະລຸນາປ້ອນເບີໂທຕິດຕໍ່ກ່ອນ..."
                v-model="branches.tel"
                v-validate="'required|max:15|min:6'"
            >
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ອີເມວ
              <span class="text-danger">{{ errors.first('email') }}</span>
              <span class="text-danger">
                                {{ server_errors.email }}
                            </span>
            </label>
            <input
                class="input" name="email"
                placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..."
                v-model="branches.email"
                v-validate="'email'"
            >
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກພາກ
              <span class="text-danger">* {{ errors.first('part') }}</span>
            </label>
            <div class="select">
              <select v-model="selectedPart.partId" name="part">
                <option :value="part.partId" v-for="(part, index) in parts" :key="index">
                  {{ FormatPart_To_Laos[part.name] }}-{{ FormatZoneToLaos[part.zone_code] || '' }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກເເຂວງ
              <span class="text-danger">* {{ errors.first('province') }}</span>
            </label>
            <div class="select">
              <select v-model="selectedProvince.proId" name="province">
                <option :value="province.proId" v-for="(province, index) in provinces" :key="index">
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກເມືອງ
              <span class="text-danger">* {{ errors.first('district') }}</span>
            </label>
            <div class="select">
              <select v-model="selectedDistrict.distId" name="district">
                <option :value="district.distId" v-for="(district, index) in districts" :key="index">
                  {{ district.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກບ້ານ
              <span class="text-danger">* {{ errors.first('village') }}</span>
              <span class="text-danger">
                                {{ server_errors.villageId }}
                            </span>
            </label>
            <div class="select">
              <select v-model="checkVillage" v-if="!villages.length" :style="{color: 'grey'}">
                <option :value="checkVillage">
                  ບໍ່ມີຂໍ້ມູນບ້ານ...
                </option>
              </select>

              <select v-model="selectedVillage.village_id" name="village" v-else>
                <option :value="village.village_id" v-for="(village, index) in villages" :key="index">
                  {{ village.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກປະເພດສາຂາ
              <span class="text-danger">* {{ errors.first('branch') }}</span>
            </label>
            <div class="select">
              <select v-model="selectedType.id" name="branch">
                <option :value="type.id" v-for="(type, index) in types" :key="index">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="control">
            <label class="label">
              ເລືອກສູນຄັດເເຍກ
              <span class="text-danger">* {{ errors.first('distributioncenter') }}</span>
            </label>
            <div class="select">
              <select v-model="selectDistributionCenter.id" name="distributioncenter">
                <option :value="item.id" v-for="(item, index) in distributionCenters" :key="index">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="control">
          <label class="label">
            ເລືອກໂປຮໄຟລ
            <span class="text-danger">* {{ errors.first('profile') }}</span>
          </label>
        </div>
        <div class="box-upload-file" @click="triggerInputClick">
          <p class="text" :class="{'text-color': fileName}">
            <i class="fal fa-cloud-upload"></i>
            {{ fileName !== '' ? fileName : 'ກະລຸນາເລືອກໂປຮໄຟລ...' }}
          </p>
        </div>
        <div v-show="false" class="control">
          <input
              @change="onFileChange" ref="file"
              class="input input-ctn"
              type="file" name="profile"
              v-validate="'required|mimes:jpeg,jpg,gif,png|size:5120'"
              accept="image/png,image/gif,image/jpeg,image/jpg"
          >
        </div>
      </div>

      <div class="column is-12">
        <div class="control">
          <label class="listhead listhead-checkbox" :style="{marginTop: '6px', marginLeft: '-3px'}">
            <input
                type="checkbox"
                v-model="checkedAllServiceZone"
                @click="CheckedAllServiceZone()"
            />
            <p :style="{marginLeft: '10px', fontWeight: 'bold'}">
              ເຂດທີ່ບໍລິການ ສົ່ງເຖີງທີ່ - ຮັບເຖີງບ່ອນ
              ( <span :style="{color: 'crimson'}">{{ checkedServiceZone.length }}</span> )
            </p>
            <div class="control_indicator"></div>
          </label>
        </div>
      </div>
      <div class="column is-12">
        <div class="box-villages">
          <div class="columns is-multiline">
            <div class="column is-2" v-for="(village, index) in villages" :key="index">
              <div class="village">
                <label class="listhead listhead-checkbox">
                  <input
                      type="checkbox"
                      v-model="checkedServiceZone"
                      :value="village.village_id"
                      :id="village.village_id"
                  />
                  <p :style="{marginTop: '6px'}">{{ village.name }}</p>
                  <div class="control_indicator"></div>
                </label>
              </div>
            </div>
            <div class="box-not-found" v-if="!villages.length">
              <p>
                ບໍ່ມີເຂດບໍລິການ ສົ່ງເຖີງທີ່ - ຮັບເຖີງບ່ອນ...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="field">
          <div class="control">
            <label class="label">
              ທີ່ຢູ່ Lag,Lng
            </label>
            <input
                class="input" name="customer_address"
                placeholder="ຕົວຢ່າງ: lat,lng"
                v-model="selectLatLng"
            >
          </div>
        </div>
      </div>
      <div class="column is-3" :style="{display: 'flex'}">
        <div ref="target" id="target" class="center" style="
        margin: 0; align-items: flex-start; padding-top: 19px;">
          <vs-button ref="button" flat class="form-btn-general" @click="focusFromLagLng">
            <i class="fal fa-map-marked" :style="{width: '35px'}"></i> Focus
          </vs-button>
        </div>
      </div>

      <div class="column is-12">
        <gmap-autocomplete
            ref="searchInput"
            class="input"
            placeholder="ຄົ້ນຫາເເຜນທີ່..."
            @place_changed="setPlace"
            :options="{fields: ['geometry', 'formatted_address', 'name']}">
        </gmap-autocomplete>
        <span class="horizontal-divider"></span>
      </div>
      <div class="column is-12">
        <GmapMap
            :center="center"
            :zoom="16"
            style="width:100%; height:450px;"
        >
          <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center=m.position"
              :draggable="true"
              @dragend="onLocation"
              :icon="markerOptions"
              :animation="2"
              ref="markers"
          />
        </GmapMap>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12" :style="{display: 'flex'}">
        <div ref="target" id="target" class="center">
          <div class="con-btns">
            <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
              <i class="fal fa-pencil-alt" :style="{width: '35px'}"></i> ລົງທະບຽນ ສາຂາສາງ
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MixinsAddBranches from '../../../../Mixins/Branch/Add';
import {ValidateBranches} from '@coms/../../src/Plugins/Validation/Branch/Branch';

export default {
  mixins: [MixinsAddBranches],

  created() {
    this.FetchData();
    this.FetchDistributionCenter();
    ValidateBranches(this);
  }
}
</script>

<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  #target {
    margin: 0 auto;
    right: 10px;

    .con-btns {
      width: 200px;
    }
  }

  .input {
    font-family: $font;
  }

  .box-upload-file {
    border: 1px dashed #6e6c6c;
    padding: 8px;
    width: 100%;
    margin-top: 2px;

    &:hover {
      cursor: pointer;
    }

    .text {
      text-align: left;
      color: #6e6c6c;

      i {
        width: 25px;
      }
    }

    .text-color {
      color: $primary-color;
    }
  }
}

// Basic control styles
.listhead {
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 0 0 15px 0;
  cursor: pointer;
  font-size: 16px;
}

// Hide default browser input
.listhead input {
  vertical-align: top;
  margin-right: 10px;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

// Custom control
.control_indicator {
  position: absolute;
  top: 1px;
  left: 4px;
  height: 23px;
  width: 23px;
  background: $white;
  border-radius: 4px;
}

// Hover and focus
.listhead:hover input ~ .control_indicatior,
.listhead input:focus ~ .control_indicator {
  background: $white;
}

// Checked
.listhead input:checked ~ .control_indicator {
  background: $white;
}

// Hide default browser input
.listhead input:disabled ~ .control_indicator {
  background: $primary-color;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.listhead input:checked ~ .control_indicator:after {
  display: block; // Show check
}

.listhead-checkbox .control_indicator:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 12px;
  border: solid $crimson;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.box-villages {
  margin-top: -30px;
  background: $white;
  width: 100%;
  padding: 10px;
  max-height: 320px;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;

  .village {
    background: $crimson;
    width: 100%;
    padding: 2px;
    height: 40px;
    border-radius: 2px;
    font-family: $font;
    color: $white;
  }
}

.box-not-found {
  padding: 20px;
  width: 100%;
  text-align: center;

  p {
    color: $crimson;
    font-size: 16px;
  }
}
</style>
