import {FormatDateTime, FormatNumber} from '@/Helpers/Format';
import * as helper from '../../../../Helpers/GetValue';

export default {
    data: () => ({
        isLoading: false,
        invoiceDetails: [],
        summary: {},
        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},

        la_status: {
            pending: 'ກຳລັງດຳເນີນການ',
            success: 'ສຳເລັດ',
        },

        shipment_number: '',
        oldVal: '',
    }),
    methods: {
        FilterShipmentNumber(){
            helper.AccountingGetValueOnInput(this);
        },

        FetchData() {
            this.$http.get('list-invoice-detail/cod-branch/' + this.$route.params.id, {
                params: {
                    page: this.pagination.current_page,
                    per_page: this.limit_page,
                    shipment_number: this.shipment_number
                }
            }).then((res) => {
                this.invoiceDetails = res.data.invoice_details.data;
                this.summary = res.data.summary;
                this.pagination = res.data.invoice_details
                this.count = res.data.invoice_details.total
            }).catch(() => {

            });
        },

        FormatPrice(price) {
            return FormatNumber(price);
        },
        FormatDate(date) {
            return FormatDateTime(date);
        }
    },
    watch:{
        'shipment_number': function(value){
            if(value === ''){
                this.FetchData();
            }
        },
    }
}