<template>
  <div>
    <div class="field" :style="{marginTop: '40px'}">
      <h4 class="not-margin"
          :style="{ marginBottom: '18px', fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'center' }">
        <i class="fal fa-money-bill" :style="{ width: '25px', fontSize: '18px' }"></i>
        ວັນ/ເດືອນ/ປີ ຮຽກເກັບເງິນ
      </h4>
      <vuejs-datepicker
          v-model="payDate"
          input-class="input"
          format="d/M/yyyy"
          name="valid_date"
          v-validate="'required'"
      >
      </vuejs-datepicker>
    </div>
    <vs-button ref="button" flat @click="ExportsExcel_By_Split()" class="form-btn-loading" :loading="loading">
      Export Excel
    </vs-button>
  </div>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker';
import {FormatDateTimeBeforeSendToServer, FormatDate} from '../../../Helpers/Format';

export default {
  props: [
    'invoiceId', 'checkAll'
  ],
  components: {
    vuejsDatepicker
  },

  data: () => ({
    payDate: new Date(),
    loading: false
  }),
  methods: {
    ExportsExcel_By_Split() {
      const invoiceId = this.invoiceId.map(item => item.id);
      this.loading = true;
      this.$http.post('print-company-invoice-split', {
            invoices: invoiceId,
            date: FormatDateTimeBeforeSendToServer(this.payDate),
          },
          {responseType: 'blob'},
      ).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'Invoice ລູກຄ້າລາຍເດືອນ ' + FormatDate(this.payDate) + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>
