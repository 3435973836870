<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-48px', marginLeft: '-30px'}">
      <ul>
        <li :class="{'is-active': isActive === 'pending'}" @click="Tabs('pending')">
          <a>ລໍຖ້າ</a>
        </li>
        <li :class="{'is-active': isActive === 'wrong'}" @click="Tabs('wrong')">
          <a>ຂໍ້ມູນຜິດ</a>
        </li>
        <li :class="{'is-active': isActive === 'solve'}" @click="Tabs('solve')">
          <a>ລູກຄ້າແກ້ໄຂແລ້ວ</a>
        </li>
        <li :class="{'is-active': isActive === 'confirm'}" @click="Tabs('confirm')">
          <a>ຢືນຢັນ</a>
        </li>
      </ul>
    </div>

    <ListAuto :onclickEvent="onClick"/>
  </div>
</template>

<script>
import ListAuto from './ListAutoApproved';

export default {
  components: {
    ListAuto,
  },

  data: () => ({
    isActive: 'all',
    onClick: '',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'tab.auto.approved.cod', query: {tab: value}}).catch(() => {
      });
      this.onClick = value;
    }
  },
  created() {
    if (this.$route.query.tab === 'pending') {
      this.isActive = 'pending';
    } else if (this.$route.query.tab === 'wrong') {
      this.isActive = 'wrong';
    } else if (this.$route.query.tab === 'solve') {
      this.isActive = 'solve';
    } else if (this.$route.query.tab === 'confirm') {
      this.isActive = 'confirm';
    } else {
      this.$router.push({name: 'tab.auto.approved.cod', query: {tab: 'pending'}});
      this.isActive = 'pending';
      this.onClick = 'pending';
    }
  }
}
</script>

<style>

</style>
