<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-bicycle"></i>
          ຄິດໄລ່ຍອດ delivery ຕ່າງແຂວງລົດຈັກ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="printSummaryDeliveryCapital()"
              class="form-btn-loading"
              :loading="loading">
            export excel
          </vs-button>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຍອດຮັບ</vs-th>
            <vs-th>ຍອດຈັດສົ່ງ</vs-th>
            <vs-th>ຍອດທັງໝົດ</vs-th>
            <vs-th>ລວມ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="branch"
              v-for="(branch, index) in $vs.getPage(branches, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ branch.name }}</vs-td>
            <vs-td>{{ FormatPrice(branch.receiveShipmentQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.successShipmentQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.totalShipmentQty) }}</vs-td>
            <vs-td>{{ FormatPrice(branch.totalPrice) }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <CustomerExcelTemplate v-show="false" ref="ExportExcel"/>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import CustomerExcelTemplate from "@coms/printing/CustomerExcelTemplate";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  components: {
    Loading,
    pagination,
    CustomerExcelTemplate,
    DatePicker
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    branch: '',
    branches: [],
    searchItem: "",
    oldVal: "",
    totalPoint: 0,
    totalSpend: 0,
    transaction_type: '',
    transaction_status: '',
    branch_type: '',
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    printSummaryDeliveryCapital() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/summary/delivery-province', {
        start_date: this.start_date,
        end_date: this.end_date,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ຄີດໄລ່ຍອດເດລີເວີລີຕ່າງແຂວງລົດຈັກວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ຄີດໄລ່ຍອດເດລີເວີລີີຕ່າງແຂວງລົດຈັກວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2018-08-01 00:00:00');
      return date < fixDay;
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.branches = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('summary/province-delivery', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          print: 'no',
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
