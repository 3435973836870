<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ໝວດໝູ່ພັດສະດຸ
                <span class="countTotal">({{listParcelCategories.length}})</span>
                <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>

        <div class="center">
            <vs-table>
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ໝວດໝູ່ພັດສະດຸ</vs-th>
                        <vs-th></vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                        :key="index" :data="category"
                        v-for="(category, index) in $vs.getPage(listParcelCategories, page, max)"
                    >
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '15%'}">
                            {{category.name}}
                        </vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalEdit(category.id)">
                                    <i class="fad fa-pencil-alt"></i>
                                </vs-button>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listParcelCategories, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddParcelCategories
                    @close="close"
                    @success="FetchData()"
                />
            </template>
        </ModalAdd>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditParcelCategories
                    :listParcelCategory="filterParcelCategory"
                    @close="close"
                    @success="FetchData()"
                />
            </template>
        </ModalEdit>

    </div>
</template>

<script>
import Loading from '../../Loading';
import AddParcelCategories from './Dialog/Add';
import EditParcelCategories from './Dialog/Edit';

export default {
    components:{
        Loading,
        AddParcelCategories,
        EditParcelCategories
    },

    data:()=>({
        page: 1,
        max: 15,
        isLoading: true,


        listParcelCategories: [],
        filterParcelCategory: {},
        categoryId: '',
    }),
    methods:{
        // ViewBranchUsers(branchId){
        //     this.$router.push({name: 'list.branch.users', params:{id: branchId}});
        // },
        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        OpenModalEdit(categoryId){
            this.filterParcelCategory = {...this.FilterParcelCategory(categoryId)};
            this.$store.commit('modalEdit_State', true);
        },
        OpenModalDelete(categoryId){
            this.categoryId = categoryId;
            this.$store.commit('modalDelete_State', true);
        },
        FilterParcelCategory(categoryId){
            return this.listParcelCategories.filter(item=>{
                return item.id == categoryId;
            })[0] || {};
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-parcel-categories').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
                setTimeout(()=>{
                    this.listParcelCategories = res.data.listParcelCategories;
                }, 100);
            }).catch(()=>{});
        },
    },
    created(){
        this.FetchData();
    }
}
</script>
<style lang="scss">
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>
