import { Validator } from 'vee-validate';

const validate = {
    custom: {
      user_password:{
        required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        min: '(ລະຫັດບໍ່ຄວນສັ້ນກວ່າ 6 ໂຕ)',
        max: '(ລະຫັດບໍ່ຄວນຍາວກວ່າ 25 ໂຕ)',
      },
    }
};

export const ValidateUserPassword = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

