<template>
    <div class="container">
        <div class="tabs" :style="{marginTop: '-30px', marginLeft: '-30px'}">
            <ul>
                <li :class="{'is-active': isActive == 'online'}" @click="Tabs('online')">
                    <a>ເເພ໋ກເເກ໋ດ ເປີດຕອນນີ້</a>
                </li>
                <li @click="Tabs('offline')" :class="{'is-active': isActive == 'offline'}">
                    <a>ເເພ໋ກເເກ໋ດ ປິດເເລ້ວ</a>
                </li>
            </ul>
        </div>

        <!-- components Online && Offline Of Packages -->
        <ListPackageOnline v-if="isActive == 'online'"/>
        <ListPackageOffline v-else/>

    </div>
</template>

<script>
import ListPackageOnline from './List-Package-Online';
import ListPackageOffline from './List-Package-Offline';

export default {
    components:{
        ListPackageOnline,
        ListPackageOffline
    },

    data:()=>({
        isActive: '',
    }),

    methods:{
        Tabs(value){
            this.isActive = value;
            this.$router.push({name: 'tab.list.packages', query:{tab: value}});
        }
    },
    created(){
        if(this.$route.query.tab == 'online'){
            this.isActive = 'online';
        }else if(this.$route.query.tab == 'offline'){
            this.isActive = 'offline';
        }else{
            this.isActive = 'online';
        }
    }
}
</script>

<style>

</style>