<template>
    <div class="container">
        <div class="box-title" id="scrollTo">
            <h4>
                <i class="fal fa-plus-circle"></i> ເພີ່ມ ຂໍ້ມູນສາຂາ ( Delivery )
            </h4>
        </div>

        <div class="columns is-multiline" :style="{marginTop: '20px'}">
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ລະຫັດ ( Delivery )
                            <span class="text-danger">* {{ errors.first('prefix') }}</span>
                            <span class="text-danger">
                                {{server_errors.prefix}}
                            </span>
                        </label>
                        <input 
                            class="input" name="prefix" 
                            placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..." 
                            v-model="delivery.prefix" 
                            v-validate="'required|max:15|min:3'"
                        >
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ສາຂາສາງ ( Delivery )
                            <span class="text-danger">* {{ errors.first('name') }}</span>
                            <span class="text-danger">
                                {{server_errors.name}}
                            </span>
                        </label>
                        <input 
                            class="input" name="name" 
                            placeholder="ກະລຸນາປ້ອນສາຂາສາງກ່ອນ..." 
                            v-model="delivery.name" 
                            v-validate="'required'"
                        >
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ເບີໂທຕິດຕໍ່ 
                            <span class="text-danger">* {{ errors.first('tel') }}</span>
                            <span class="text-danger">
                                {{server_errors.tel}}
                            </span>
                        </label>
                        <input 
                            class="input" name="tel" 
                            placeholder="ກະລຸນາປ້ອນເບີໂທຕິດຕໍ່ກ່ອນ..." 
                            v-model="delivery.tel" 
                            v-validate="'required|max:15|min:6'"
                        >
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ອີເມວ 
                            <span class="text-danger">* {{ errors.first('email') }}</span>
                            <span class="text-danger">
                                {{server_errors.email}}
                            </span>
                        </label>
                        <input 
                            class="input" name="email" 
                            placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..." 
                            v-model="delivery.email" 
                            v-validate="'required|email'"
                        >
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ເລືອກພາກ 
                            <span class="text-danger">* {{ errors.first('part') }}</span>
                        </label>
                        <div class="select">
                            <select v-model="selectedPart.partId" name="part" @change="FilterProvinces(selectedPart.partId)">
                                <option :value="part.partId" v-for="(part, index) in parts" :key="index">
                                    {{FormatPart_To_Laos[part.name]}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ເລືອກເເຂວງ 
                            <span class="text-danger">* {{ errors.first('province') }}</span>
                        </label>
                        <div class="select">
                            <select v-model="selectedProvince.proId" name="province" @change="FilterDistricts(selectedProvince.proId)">
                                <option :value="province.proId" v-for="(province, index) in provinces" :key="index">
                                    {{province.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ເລືອກເມືອງ 
                            <span class="text-danger">* {{ errors.first('district') }}</span>
                        </label>
                        <div class="select">
                            <select v-model="selectedDistrict.distId" name="district" @change="FilterVillages(selectedDistrict.distId)">
                                <option :value="district.distId" v-for="(district, index) in districts" :key="index">
                                    {{district.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <div class="control">
                        <label class="label">
                            ເລືອກບ້ານ
                            <span class="text-danger">* {{ errors.first('village') }}</span>
                            <span class="text-danger">
                                {{server_errors.villageId}}
                            </span>
                        </label>
                        <div class="select">
                            <select v-model="checkVillage" v-if="!villages.length" :style="{color: 'grey'}">
                                <option :value="checkVillage">
                                    ບໍ່ມີຂໍ້ມູນບ້ານ...
                                </option>
                            </select>

                            <select v-model="selectedVillage.village_id" name="village" v-else>
                                <option :value="village.village_id" v-for="(village, index) in villages" :key="index">
                                    {{village.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12" :style="{marginTop: '15px'}">
                <gmap-autocomplete
                    ref="searchInput"
                    class="input"
                    placeholder="ຄົ້ນຫາເເຜນທີ່..."
                    @place_changed="setPlace"
                    :options="{fields: ['geometry', 'formatted_address', 'name']}">
                </gmap-autocomplete>
                <span class="horizontal-divider"></span>
            </div>
            <div class="column is-12">
                <GmapMap
                    :center="center"
                    :zoom="16"
                    style="width:100%; height:450px;"
                >
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center=m.position"
                    :draggable="true"
                    @dragend="onLocation"
                    :icon="markerOptions"
                    :animation="2"
                    ref="markers"
                />
                </GmapMap>
            </div>
        </div>
        <div class="columns">
            <div class="column is-12" :style="{display: 'flex'}">
                 <div ref="target" id="target" class="center">
                    <div class="con-btns">
                        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
                            <i class="fal fa-pencil-alt" :style="{width: '35px'}"></i> ເເກ້ໄຂ ສາຂາ (Delivery)
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MixinsEditDeliveries from '../../../../Mixins/Deliveries/Edit';
import { ValidateDeliveries } from '@coms/../../src/Plugins/Validation/Validate-Deliveries';

export default {
    mixins: [MixinsEditDeliveries],

    created(){
        this.FetchData();
        this.FetchDelivery();
        ValidateDeliveries(this);
    }
}
</script>

<style lang="scss">
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
    #target{
        margin: 0 auto;
        right: 10px;
        .con-btns{
            width: 250px;
        }
    }
    .input{
        font-family: $font;
    }
}
</style>