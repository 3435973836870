<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        {{$t('ຂໍ້ມູນOrderDetail.ຂໍ້ມູນລາຍການ')}}
      </h4>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <ul>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ເລກທີບິນ')}}: {{ order.shipment_number }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ວັນທີຝາກເຄື່ອງ')}}: {{ FormatDate(order.start_date_actual) }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ປະເພດຜູ້ຝາກ')}}: {{ $t('common.' + (order.sender_customer_type || 'general')) }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ຊື່ຜູ້ຝາກ')}}: {{ order.sender_customer_name }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ເບີໂທຜູ້ຝາກ')}}: {{ order.sender_customer_tel }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ຊື່ຜູ້ຮັບ')}}: {{ order.receiver_customer_name }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ເບີໂທຜູ້ຮັບ')}}: {{ order.receiver_customer_tel }}</li>
          </ul>
        </div>
        <div class="column is-4">
          <ul>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ສ່ວນຫຼຸດທັງໝົດ')}}:
              <span v-if="order.total_discount_amount !== 0">{{ order.total_discount_amount }}{{$t('ຂໍ້ມູນOrderDetail.ກີບ')}}</span>
              <span v-if="order.total_discount_percent !== 0">{{ order.total_discount_percent }}%</span>
              <span v-if="order.total_discount_percent === 0 && order.total_discount_percent === 0">........</span>
            </li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ຄ່າຂົນສົ່ງ')}}: {{ FormatPrice(order.sub_total_freight) }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ປະກັນໄພ')}}: {{ FormatPrice(order.total_freight - order.sub_total_freight) }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ລວມຄ່າຂົນສົ່ງ')}}: {{ FormatPrice(order.total_freight) }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ຄ່າສິນຄ້າ')}}: {{ FormatPrice(order.total_price) }}</li>
          </ul>
        </div>
        <div class="column is-4">
          <ul>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ສາຂາຕົ້ນທາງ')}}: {{ order.start_branch_name }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ສາຂາປາຍທາງ')}}: {{ order.end_branch_name }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ຜູ້ຮັບຜິດຊອບ')}}: {{ order.created_by_name }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ຜູ້ຈັດສົ່ງ')}}: {{ order.sign_name }}</li>
            <li>{{$t('ຂໍ້ມູນOrderDetail.ປະເພດຊຳລະ')}}:
              <span v-if="order.shipment_pay_type === 'origin_freight_fees'">( {{$t('ຂໍ້ມູນOrderDetail.ເກັບເງິນຕົ້ນທາງ')}} )</span>
              <span v-if="order.shipment_pay_type === 'destination_freight_fees'">( {{$t('ຂໍ້ມູນOrderDetail.ເກັບເງິນປາຍທາງ')}} )</span>
              <span v-if="order.shipment_pay_type === 'origin_freight_fees_cod'">( {{$t('ຂໍ້ມູນOrderDetail.ເກັບເງິນຕົ້ນທາງ - COD')}} )</span>
              <span
                  v-if="order.shipment_pay_type === 'destination_freight_fees_cod'">( {{$t('ຂໍ້ມູນOrderDetail.ເກັບເງີນປາຍທາງ - (COD - CDC)')}} )</span>
            </li>
          </ul>
        </div>
      </div>
      <hr :style="{marginTop: '5px'}">
      <div class="columns">
        <div class="column is-6">
          <div class="details">{{$t('ຂໍ້ມູນOrderDetail.ລາຍລະອຽດ')}}
            <div class="text">
              {{ order.description !== "" ? order.description : '...........' }}
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="details">{{$t('ຂໍ້ມູນOrderDetail.ໝາຍເຫດ')}}
            <div class="text">
              {{ order.remark !== "" ? order.remark : '.................' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-title" :style="{marginTop: '15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        {{$t('ຂໍ້ມູນOrderDetail.ຂໍ້ມູນລາຍລະອຽດລາຍການ')}}
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລຳດັບ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ເລກທີບິນ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ການເຄື່ອນໄຫວລ່າສຸດຂອງພັດສະດຸ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ພັດສະດຸ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ຈຳນວນ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ສ່ວນຫຼຸດ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລາຄາພັດສະດຸ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລວມຄ່າຂົນສົ່ງ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລວມທັງໝົດ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລວງກວ້າງ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລວງສູງ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລວງຍາວ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ນ້ຳໜັກ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ຂະໜາດ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ລາຍລະອຽດ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ປະເພດພັດສະດຸ')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ເເພ໋ກເເກ໋ດທີ່ໃຊ້')}}</vs-th>
            <vs-th>{{$t('ຂໍ້ມູນOrderDetail.ຜູ້ຮັບຜິດຊອບ')}}</vs-th>
            <!-- <vs-th></vs-th> -->
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in orderDetails" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td style="font-weight: bold;">{{ item.parcel_bill_number }}</vs-td>
            <vs-td>
              <div v-if="latestTrackingDetails[item.parcel_bill_number]">
                <p style="font-weight: bold; font-size: 12px">
                  {{ latestTrackingDetails[item.parcel_bill_number].message }}
                </p>
                <p style="font-weight: normal; font-size: 11px">{{$t('ຂໍ້ມູນOrderDetail.ວັນທີເຄື່ອນໄຫວ')}}
                  <span>{{ FormatDate(latestTrackingDetails[item.parcel_bill_number].date) }}</span>
                </p>
              </div>
            </vs-td>
            <vs-td>{{ item.parcel_name != null ? item.parcel_name : '.................' }}</vs-td>
            <vs-td>{{ item.quantity }}</vs-td>
            <vs-td>
              <span v-if="item.discount_amount !== 0">{{ FormatPrice(item.discount_amount) }}{{$t('ຂໍ້ມູນOrderDetail.ກີບ')}}</span>
              <span v-if="item.discount_percent !== 0">{{ item.discount_percent }}%</span>
              <span v-if="item.discount_percent === 0 && item.discount_amount === 0">........</span>
            </vs-td>
            <vs-td>{{ item.parcel_price }}</vs-td>
            <vs-td>{{ FormatPrice(item.sub_total_freight) }}</vs-td>
            <vs-td>{{ FormatPrice(item.total_freight) }}</vs-td>
            <vs-td>{{ item.parcel_width }}</vs-td>
            <vs-td>{{ item.parcel_height }}</vs-td>
            <vs-td>{{ item.parcel_length }}</vs-td>
            <vs-td>{{ item.parcel_weight }}</vs-td>
            <vs-td>{{ item.parcel_size !== null ? item.parcel_size : '...........' }}</vs-td>
            <vs-td>{{ item.parcel_detail !== "" ? item.parcel_detail : '.................' }}</vs-td>
            <vs-td>{{ item.parcel_category_name }}</vs-td>
            <vs-td>{{ item.package_name !== null ? item.package_name : '...................' }}</vs-td>
            <vs-td>{{ item.created_by_name }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div>
        <Loading v-if="isLoading"/>
      </div>
    </div>

    <div class="box-title" :style="{marginTop: '20px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        {{$t('ຂໍ້ມູນOrderDetail.ສະຖານະ ການເຄື່ອນໄຫວຂອງພັດສະດຸ')}}
        {{ orderDetails.length > 1 ?  ' | ' + $t('ຂໍ້ມູນOrderDetail.ເລກທີບິນ') + ' ' + order.shipment_number + '-01' : '' }}
      </h4>
    </div>


    <div class='wrapper'>
      <div class='steps' id='steps'>
        <div class='step' v-for="(trackingStatus, index) in trackingStatues" :key="index">
          <div class='number completed'>
            <svg viewBox="0 0 512 512" width="100" title="check">
              <path
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/>
            </svg>
          </div>
          <div class='info'>
            <p class='title'>
              {{ trackingStatus.message }}
            </p>
            <p class='text'>{{$t('ຂໍ້ມູນOrderDetail.ວັນທີເຄື່ອນໄຫວ')}}
              <span>{{ FormatDate(trackingStatus.date) }}</span>
            </p>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import MixinShipmentDetails from '../../../Mixins/Accountings/List-Shipment-Detail';
import moment from 'moment';
import Loading from '../../Loading';

export default {
  components: {
    Loading
  },


  mixins: [MixinShipmentDetails],

  data: () => ({
    lao_customer_type: {
      'vip': 'cod',
      'general': 'ລູກຄ້າທົ່ວໄປ',
      'company': 'ລູກຄ້າບໍລິສັດ'
    }
  }), // Data
  methods: {
    FormatPrice(num) {
      var p = (num || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, num, i) {
        return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + ' ' + this.$t('common.ກີບ');
    },
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
  },

  created() {
    this.FetchOrderDetails();
  }
}
</script>

<style lang="scss" scoped>
.box {
  min-height: 140px;
  text-align: left;

  ul {
    margin-left: 5px;
    margin-top: -5px;

    li {
      list-style-type: none;
    }
  }

  .details {
    font-size: 16px;
    font-weight: bold;
    margin-top: -15px;

    .text {
      border: 1px solid #2222;
      padding: 10px;
      min-height: 50px;
      border-radius: 5px;
      margin-top: 5px;
      font-weight: normal;
    }
  }
}


@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

$primary: #007EA7;


body {
  font-family: "Open Sans", sans-serif;
  background-color: #001829;
}

.wrapper {
  background: $white;
  padding: 15px;
  text-align: left;
  margin-top: 20px;
}

.steps {
  max-width: 100%;
  //   margin: 0 auto;
}

.step {
  display: flex;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 18px;
    top: 32px;
    height: 0;
    width: 2px;
    background-color: $crimson;
  }

  .info {
    margin: 8px 0 20px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
    color: rgba(7, 7, 7, 0.993);
  }

  .text {
    font-size: 14px;
    color: rgba(7, 7, 7, 0.993);
    padding-bottom: 0;
    margin: 0;
    min-width: 280px;

    span {
      margin-left: 50px;
      text-align: right !important;
    }
  }

  &:not(:last-child) {
    &:after {
      height: 58%;
    }

  }
}

.number {
  width: 32px;
  height: 32px;
  background-color: rgba(100, 99, 99, 0.527);
  border-radius: 50%;
  border: none;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin-right: 14px;

  &.completed {
    background-color: $crimson;
  }

  svg {
    width: 16px;
    height: 16px;
    object-fit: contain;

    path {
      fill: white;
    }
  }
}

</style>
