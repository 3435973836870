import {FormatDateTime, FormatNumber} from '@/Helpers/Format';
import * as helper from '../../../Helpers/GetValue';
import moment from "moment";

export default {

    data: () => ({
        checkedAll: false,
        isLoading: true,
        invoices: [],
        invoiceId: [],
        pageInvoices: [],
        invoice_id: '',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        branchTypes: [
            {value: 'branch', name: 'ສາຂາຫຼັກ'},
            {value: 'franchise', name: 'ສາຂາເເຟນຊາຍ'}
        ],
        selectedBranchType: 'branch',
        status: [
            {value: '', name: 'ເລືອກທັງໝົດ'},
            {value: 'pending', name: 'ກຳລັງດຳເນິນການ'},
            {value: 'success', name: 'ສຳເລັດ'},
        ],
        la_status: {
            pending: 'ກຳລັງດຳເນີນການ',
            success: 'ສຳເລັດ',
        },
        selectedStatus: {
            value: '', name: 'ເລືອກທັງໝົດ'
        },
        summary: {},
        listBranches: [],
        selectedBranch: '',

        //export
        actualSelectedItems: [],
        isSelecting: false,

        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},

        shipment_number: '',
        oldVal: '',
    }), // Data

    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        EditStatementOrigin(invoiceId) {
            invoiceId.paid_type = this.$route.query.tab;
            this.invoice_id = JSON.parse(JSON.stringify(invoiceId));
            this.$store.commit('modalPreviewFile_State', true);
        },

        FilterShipmentNumber() {
            helper.AccountingGetValueOnInput(this);
        },
        FilterStatus() {
            this.FetchData();
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('list-invoice', {
                params: {
                    shipment_number: this.shipment_number,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    invoice_type: this.$route.query.tab,
                    branch_type: this.selectedBranchType,
                    branch: this.selectedBranch,
                    invoice_status: this.selectedStatus.value,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page
                }
            })
        },

        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.pagination = res.data.invoices;
                    this.invoices = res.data.invoices.data;
                    this.summary = res.data.summary;
                    this.count = res.data.invoices.total;
                    this.markSelectedItemsOnPageChanges(this.invoices);
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        FetchBranches() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedBranchType + '&include=excluded').then((res) => {
                this.listBranches = res.data;
                this.listBranches.unshift({
                    id: "",
                    name: this.$t('common.all')
                });
            })
        },

        OpenModalPrintInvoiceCOD_Split() {
            this.$store.commit('modalDelete_State', true);
        },
        ExportExcel_By_Sum() {
            this.$store.commit('modalEdit_State', true);
        },

        FormatDate(value) {
            return FormatDateTime(value);
        },
        FormatPrice(price) {
            return FormatNumber(price);
        },
        ExportSuccess() {
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) {
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },
        onCheckAllCheckBoxChanged(event) {
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if its not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.invoices));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.invoices.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecks();
            }
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },

        isRowSelected(id) {
            return this.pageInvoices.map((i) => i.id).includes(id);
        },
        clearAllChecks() {
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },
        /**
         * @EndChecking
         */
    },
    watch: {
        'startDate': function () {
            this.FetchBranches();
            this.FetchData();
        },
        'endDate': function () {
            this.FetchBranches();
            this.FetchData();
        },
        'selectedBranchType': function () {
            this.FetchData();
            this.FetchBranches();
        },
        'selectedBranch': function () {
            this.FetchData();
        },
        'shipment_number': function (value) {
            if (value == '') {
                this.FetchData();
            }
        },
    },
    computed: {
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
        }
    },
}
