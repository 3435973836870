<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{marginTop: '10px'}">
        <img :src="require('@/assets/cancel.png')" class="img-delete">
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{fontSize: '18px', color: '#195BFF'}">
        {{ $t('ຂໍ້ມູນOrderDetail.ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການຍົກເລີກປ່ຽນປາຍທາງ')}}
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="Cancel()">
          {{ $t('ຂໍ້ມູນOrderDetail.ຢືນຢັນ')}}
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          {{ $t('ຂໍ້ມູນOrderDetail.ຍົກເລີກ')}}
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ["shipment_number"],
  data() {
    return {
      active: false,
      loading: false,
    };
  },

  methods: {
    Cancel() {
      let data = {
        shipment_number: this.shipment_number,
      }
      this.loading = true;
      this.$http.post("admin/account/cancel-address-error", data)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_EditItem_OnSuccess(
                  "top-right",
                  "dark",
                  3000
              );
            }, 300);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 422) {
              const obj = err.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            } else {
              this.server_errors.error = err.response.data.message ?? null;
            }
          });
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  }
};
</script>
