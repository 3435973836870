<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ຜູ້ໃຊ້ລະບົບ ພະເເນກບັນຊີ
        <span class="countTotal">({{ listAccountingUsers.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດຜູ້ໃຊ້</vs-th>
            <vs-th>ໂປຮໄຟລ</vs-th>
            <vs-th>ຊື່ຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ບົດບາດຂອງຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ໜ້າທີ່ຂອງບົດບາດ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(accountingUser, index) in listAccountingUsers"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ accountingUser.userCardid }} )</vs-td>
            <vs-td>
              <img :src="accountingUser.url_profile" class="profile">
            </vs-td>
            <vs-td>{{ accountingUser.userName }}</vs-td>
            <vs-td>{{ accountingUser.userEmail }}</vs-td>
            <vs-td>{{ accountingUser.userTel }}</vs-td>
            <vs-td :style="{color: '#195BFF'}" v-if="accountingUser.roleUser.length">
              ( {{ JoinRoleUsers(accountingUser.roleUser) }} )
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
              (........................)
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-if="accountingUser.permissionRole.length">
              ( {{ JoinPermissionUsers(accountingUser.permissionRole).substring(0, 30) + "..." }} )
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
              (........................)
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    circle icon flat
                    @click="OpenModalChangePassword(accountingUser.userId)">
                  <i class="fal fa-lock-alt"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(accountingUser.accountingUserId)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="OpenModalDelete(accountingUser.accountingUserId)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()"
          >
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalClose>
      <template v-slot="{ close }">
        <ChangePassword @close="close" :userId="userId" @success="FetchData()"/>
      </template>
    </ModalClose>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddAccountingUser @close="close" @success="FetchData()" :roleUsers="roleUsers"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditAccountingUser :listAccountingUser="accountingUser" :roleUsers="roleUsers" @close="close"
                            @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteAccountingUser @close="close" :accountingUserId="accountingUserId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>
<script>
import AddAccountingUser from './Dialog/Add';
import EditAccountingUser from './Dialog/Edit';
import DeleteAccountingUser from './Dialog/Delete';
import Loading from '../../Loading';
import * as helper from '../../../Helpers/GetValue';
import pagination from '../../../Plugins/Paginations/Pagination';
import ChangePassword from "@views/SuperAdmin/Branches/Users/Dialog/ChangePassword.vue";


export default {
  components: {
    ChangePassword,
    AddAccountingUser,
    EditAccountingUser,
    DeleteAccountingUser,
    Loading,
    pagination
  },

  data: () => ({
    isLoading: true,

    roleUsers: [],
    listAccountingUsers: [],
    accountingUser: {},
    accountingUserId: '',
    searchItem: '',
    oldVal: '',
    userId: '',
    // pagination
    offset: 10,
    pagination: {},
    per_page: 10
  }),
  methods: {
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalChangePassword(userId) {
      this.userId = userId;
      this.$store.commit('modalClose_State', true);
    },
    OpenModalEdit(accountingUserId) {
      this.accountingUser = {...this.filterItem(accountingUserId)};
      this.$store.commit('modalEdit_State', true);
    },
    filterItem(accountingUserId) {
      return this.listAccountingUsers.filter(item => {
        return item.accountingUserId == accountingUserId;
      })[0] || {};
    },
    OpenModalDelete(accountingUserId) {
      this.accountingUserId = accountingUserId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-accounting-users', {
        params: {
          searchData: this.searchItem,
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listAccountingUsers = res.data.listAccountingUsers.data;
          this.pagination = res.data.listAccountingUsers;
        }, 100);
      }).catch(() => {
      });

      this.$axios.get('list-roles').then(res => {
        var roles = ["accountingmanager", "accountingcod", "accountingcdc", "accountingoriginbranch", "accountingoriginfranchise", "accounting-local"];
        this.roleUsers = res.data.roles.filter(item => roles.includes(item.name));
      });
    },
    JoinRoleUsers(roleUser) {
      return roleUser.map(item => item.roleName).join(', ');
      //return this.listAccountingUsers[0].roleUser.map(i=>i.roleName).join(',');
    },
    JoinPermissionUsers(perRoles) {
      return perRoles.map(item => item.permissionName).join(', ');
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    }
  },
  created() {
    this.FetchData();
  },
  watch: {
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    }
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
