export default {

    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            userCardid: '',
            name: '',
            email: '',
            tel: '',
            password: '',
            roleId: '',
        },
        users: {
            userCardid: '',
            name: '',
            email: '',
            tel: '',
            password: '',
            employee_type: 'service',
        },

        roleId: [],
        permissions: [],

        images: {
            fileName: '',
            base64: '',
            size: '',
            file: '',
            type: '',
        }

    }), // Data

    methods: {
        ShowPermission() {
            this.$axios.get('show-permission-roles', {
                params: {
                    roleId: this.roleId
                }
            }).then(res => {
                this.permissions = res.data;
            });
        }, // Show-Permissions

        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveItem();
                }
            });
        }, // ValidateForm

        SaveItem() {
            this.btnLoading = true;
            this.users.roleId = this.roleId;
            this.$http.post('register/employee', this.users).then(res => {
                if (res.data.success === true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error => {
                this.btnLoading = false;
                if (error.response.status === 422) {
                    const obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }, // Register User
    },
    watch: {
        'users.userCardid': function () {
            this.server_errors.userCardid = '';
        },
        'users.name': function () {
            this.server_errors.name = '';
        },
        'users.email': function () {
            this.server_errors.email = '';
        },
        'users.tel': function () {
            this.server_errors.tel = '';
        },
        'users.password': function () {
            this.server_errors.password = '';
        },
        'roleId': function () {
            this.server_errors.roleId = '';
        }
    },
}
