import moment from "moment";

export default {
    data: () => ({
        qrValue: '123',
        isShow: false,
        listShipmentBag: [],
        selectedShipmentBag: null,
        start_date: moment(new Date()).subtract(10, "days").format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD'),
        searchItem: '',
        isLoading: false,
        listShipmentBagCount: 0,
        loading: false,
        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10,

        oldVal: '',

        la_status: {
            'pending': 'ລໍຖ້າ',
            'processing': 'ກຳລັງດຳເນິນການ',
            'success': 'ສຳເລັດ',
            'cancelled': 'ຍົກເລີກ',
        }
    }),
    methods: {
        async printBarcode(item) {
            this.qrValue = item.bag_number;
            this.$nextTick(() => {
                const fileLink = document.createElement('a');
                let ref = this.$refs.barcode
                fileLink.href = ref.$el.toDataURL('image/png')
                fileLink.setAttribute('download', `${this.qrValue}.png`);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            })
        },

        exportExcel() {
            const loading = this.BtnLoading();
            this.loading = true;
            this.$http.post(`print/reusable/bag`, {
                start_date: this.start_date,
                end_date: this.end_date,
            }, {responseType: 'blob'}).then((res) => {
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                if (this.start_date === this.end_date) {
                    fileLink.setAttribute('download', `ລາຍການກະເປົາທີ່ສ້າງວັນທິ ` + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
                } else {
                    fileLink.setAttribute('download', `ລາຍການກະເປົາທີ່ສ້າງວັນທິ ` + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
                }

                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.loading = false;
                loading.close()
            }).catch(() => {
                this.loading = false;
                loading.close()
            });
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        OpenModalAdd() {
            this.$store.commit('modalAdd_State', true);
        },
        OpenModalEdit(distributionCenterBagItem) {
            this.selectedShipmentBag = {...distributionCenterBagItem};
            this.$store.commit('modalEdit_State', true);
        },
        OpenModalDelete(distributionCenterBagItem) {
            this.selectedShipmentBag = {...distributionCenterBagItem};
            this.$store.commit('modalDelete_State', true);
        },
        FilterByShipmentBagNumber() {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        FetchData() {
            this.isLoading = true;
            this.$http.get('v1/auth/branches/shipments/orders/parcel-bag/reusable/list', {
                params: {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    query: this.searchItem,
                    page: this.pagination.current_page,
                    use_paginator: true,
                }
            }).then((res) => {
                this.listShipmentBag = res.data.data;
                this.pagination = res.data;
                this.listShipmentBagCount = this.pagination.total;
            }).catch(() => {
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
    watch: {
        'start_date': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'end_date': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'searchItem': function (value) {
            if (value === '') {
                this.FetchData();
            }
        },
    }
}
