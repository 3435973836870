export default{
    data:()=>({
        btnLoading: false,
        packageSizes: [],
        Provinces: [],
        selectedPackageSize: {},
        server_errors:{
            package_name: '',
            package_price: '',
            packing_box: '',
            ProvinceId: '',
            is_across: '',
            packageSizeId: '',
        },

        value: {
            is_across: 'true',
        },
        selectedFreightType: {
            'id': 1, 'type': 'origin', 'name': 'origin'
        },
        freightTypes: [
            {'id': 1, 'type': 'origin', 'name': 'ຂົນສົ່ງຕົ້ນທາງ'},
            {'id': 2, 'type': 'destination', 'name': 'ຂົນສົ່ງປາຍທາງ'}
        ],

        checked_Province: [],
        checkedAll_Provice: false,
    }),

    methods:{
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    if(this.CheckLevel_BeforeSavePackages() == true){
                        this.SaveData();
                    }else{
                        this.$notification.OpenNotification_Msg_Error_Provinces('top-right', 'danger', 3000);
                    }
                }
            });
        },
        CheckLevel_BeforeSavePackages(){
            if(this.checked_Province.length > 0){
                return true;
            }
            return false;
        },

        SaveData(){
            this.value.packageSizeId = this.selectedPackageSize.id;
            this.value.ProvinceId = this.checked_Province;
            this.value.freightType = this.selectedFreightType.type;

            this.btnLoading = true;
            this.$axios.post('add-packages', this.value).then((res)=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'tab.list.packages', params:{type: 'all'}}).catch(()=>{});
                    }, 300);
                }
            }).catch((error)=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },

        FetchData(){
            this.$axios.get('list-info-packages').then(res=>{
                const data = res.data;

                this.packageSizes = data.package_sizes;
                this.selectedPackageSize = {...this.packageSizes[0]};
                this.Provinces = data.provinces;

                if(this.value.is_across == 'true'){
                    this.showSelected = true;
                }else{
                    this.showSelected = false;
                }
            }).catch(()=>{});
        },

        CheckAllProvince(){
            this.server_errors.ProvinceId = '';
            this.checked_Province = [];
            if(!this.checkedAll_Provice){
                for (let i in this.Provinces) {
                    this.checked_Province.push(this.Provinces[i].id);
                }
            }
        }
    },

    watch:{
        'checked_Province':function(value){
            this.server_errors.ProvinceId = '';
            if(value.length == this.Provinces.length){
                this.checkedAll_Provice = true;
                if(this.Provinces.length){
                    this.checkedAll_Provice = true;
                }else{
                    this.checkedAll_Provice = false;
                }
            }else{
                this.checkedAll_Provice = false;
            }
        }
    }
}