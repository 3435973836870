export default {
    data: () => ({
        loading: false,
        listShipmentBag: [],
        isLoading: false,
        // Pagination
        offset: 10,
        pagination: {},
        limit_page: 600,
        count: 0,
        searchItem: '',
    }),
    methods: {
        FilterByShipmentBagNumber() {
            this.pagination.current_page = 1;
            this.FilterListOrders();
        },
        fetchItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get(`/v1/auth/branches/shipments/orders/parcel-bag/reusable/${this.$route.params.id}/details/recycling`,
                {
                    params: {
                        query: this.searchItem,
                        page: current_page ?? this.pagination.current_page,
                        per_page: per_page ?? this.limit_page,
                        use_paginator: true,
                    }
                })
        },
        FilterListOrders() {
            this.isLoading = true;
            this.fetchItemsResponse().then((res) => {
                this.pagination = res.data;
                this.listShipmentBag = res.data.data;
                this.count = res.data.total;
            }).catch(() => {

            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
    watch: {
        'searchItem': function (value) {
            if (value === '') {
                this.FilterListOrders();
            }
        },
    }
}
