export default {

    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            villageId: '',
        },

        parts: [],
        provinces: [],
        districts: [],
        villages: [],
        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},
        selectedVillage: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        distributionCenters: [],

        checkVillage: true,
        types: [
            {id: 'branch', name: 'ສາຂາທົ່ວໄປ'},
            {id: 'franchise', name: 'ສາຂາເເຟນຊາຍ'},
            {id: 'delivery', name: 'ສາຂາດີລີເວີລີ່'},
            {id: 'local_branch', name: 'ຈຸດສູນແຈກຢາຍ'},
            {id: 'post_box', name: 'ກ່ອງໄປສະນີ'},
            {id: 'central', name: 'ສູນແກ້ໄຂ'},
            {id: 'supervisor_central', name: 'ສູນແກ້ໄຂໃຫຍ່'},
        ],
        selectedType: {},
        FormatPart_To_Laos: {
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1',
        },

        FormatZoneToLaos: {
            lo: 'ລາວ',
            th: 'ໄທ',
            ch: 'ຈີນ',
        },
        branches: {},
        profile: '',
        fileName: '',

        // default to Montreal to keep it simple
        // change this to whatever makes sense
        center: {
            lat: 18.1189434,
            lng: 102.290218,
        },
        selectLatLng: null,
        markers: [],
        currentPlace: null,
        address: '',
        currentAddress: '',
        markerOptions: {
            // eslint-disable-next-line global-require
            url: require('@coms/../../src/assets/marker.png'),
            size: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
            scaledSize: {
                width: 25,
                height: 45,
                f: 'px',
                b: 'px',
            },
        },
        timer: null,

        // Service-Zone
        checkedAllServiceZone: false,
        checkedServiceZone: [],

        /// Close branch
        closeBranch: false,
        lockDestinationBranch: false,
        blockCreated: false,
        blockReceiveCod: false,
        blockReceiveCdc: false,
        blockReceiveLogistics: false,
        isOpenNewBranchPromotion: false,
        newBranchPromotionExpiredDate: "",
        lockOutboundTimeBranch: null,
    }),

    methods: {
        CheckedAllServiceZone() {
            this.checkedServiceZone = [];
            if (!this.checkedAllServiceZone) {
                for (let i in this.villages) {
                    this.checkedServiceZone.push(this.villages[i].village_id);
                }
            }
        },

        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
        },
        focusFromLagLng() {
            if (this.selectLatLng) {
                const lagLng = this.selectLatLng.split(',');
                if (lagLng.length > 1) {
                    this.center.lat = parseFloat(lagLng[0]);
                    this.center.lng = parseFloat(lagLng[1]);
                    this.placeMarker();
                }
            }
        },
        // receives a place object via the autocomplete component
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({position: marker});
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({position: marker});
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                const obj = this.$refs.markers[0].$markerObject;
                if (obj) {
                    obj.setAnimation(1);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        obj.setAnimation(null);
                    }, 800);
                }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });
        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },

        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                } else {
                    this.$scrollTo('#scrollTo');
                }
            });
        }, // Validate-Form

        EditItem() {
            this.branches.villageId = this.selectedVillage.village_id;
            this.branches.type = this.selectedType.id;
            this.branches.lat = this.center.lat;
            this.branches.lng = this.center.lng;
            this.branches.newProfile = this.fileName;
            this.branches.serviceZone = this.checkedServiceZone;
            this.branches.is_locked = this.closeBranch;
            this.branches.is_destination_locked = this.lockDestinationBranch;
            this.branches.is_created_locked = this.blockCreated;
            this.branches.is_cod_locked = this.blockReceiveCod;
            this.branches.is_cdc_locked = this.blockReceiveCdc;
            this.branches.is_logistics_locked = this.blockReceiveLogistics;
            this.branches.new_branch_promotion_status = this.isOpenNewBranchPromotion ? "open" : "closed";

            const lockOutboundTime = this.lockOutboundTimeBranch;
            if (lockOutboundTime && typeof lockOutboundTime != 'string') {
                this.branches.lock_outbound_time = `${lockOutboundTime.HH}:${lockOutboundTime.mm}`;
            } else {
                this.branches.lock_outbound_time = lockOutboundTime;
            }

            this.btnLoading = true;
            this.$axios.put('edit-branches', this.branches).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                        this.$router.push({name: 'list.branches', params: {type: 'all'}});
                    }, 300);
                }
            }).catch(error => {
                this.btnLoading = false;
                this.$scrollTo('#scrollTo');
                if (error.response.status == 422) {
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }, // EditItems
        FetchData() {
            this.$axios.get('list-branch-address').then(res => {
                const items = res.data.data;

                this.parts = items.parts;
                this.provinces = items.provinces;
                this.districts = items.districts;
                this.villages = items.villages;

                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;
                this.FetchBranch();
            }).catch(() => {
            });
        }, // FetchData-To-Server

        FetchBranch() {
            this.$axios.get('list-edit-branches/' + this.$route.params.id).then(res => {
                this.branches = res.data.listbranch;
                this.selectedPart.partId = this.branches.partId;
                this.selectedType.id = this.branches.type;
                this.FilterProvinces(this.branches.partId);
                this.checkedServiceZone = res.data.serviceZoneId;
                this.closeBranch = this.branches.is_locked;
                this.lockDestinationBranch = this.branches.is_destination_locked;
                this.lockOutboundTimeBranch = this.branches.lock_outbound_time;
                this.blockCreated = this.branches.is_created_locked;
                this.blockReceiveCod = this.branches.is_cod_locked;
                this.blockReceiveCdc = this.branches.is_cdc_locked;
                this.blockReceiveLogistics = this.branches.is_logistics_locked;
                this.isOpenNewBranchPromotion = false;
                if (this.branches.is_open_new_promotion) {
                    this.isOpenNewBranchPromotion = this.branches.is_open_new_promotion.status === "open";
                    if (this.isOpenNewBranchPromotion) {
                        this.newBranchPromotionExpiredDate = this.branches.is_open_new_promotion.expired_date || "";
                    }
                }

                if (!this.branches.lat && !this.branches.lng) {
                    this.geolocate();
                } else {
                    this.center.lat = this.branches.lat;
                    this.center.lng = this.branches.lng;
                    this.placeMarker();
                }
            }).catch(() => {
            });
        },
        FetchDistributionCenter() {
            this.isLoading = true;
            this.$http.get('v1/listing/distribution_centers').then(res => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(() => {
                    this.distributionCenters = res.data;
                }, 100);
            }).catch(() => {
            });
        },

        FilterProvinces(partId) {
            const result = this.filterProvinces.filter(item => {
                if (item.part_id == partId) {
                    return item;
                }
            });
            this.provinces = result;
            if (this.branches.partId == partId) {
                this.selectedProvince.proId = this.branches.proId;
            } else {
                this.selectedProvince = {...this.provinces[0]};
            }
            this.FilterDistricts(this.selectedProvince.proId);
        }, // Filter-Provinces

        FilterDistricts(proId) {
            const result = this.filterDistricts.filter(item => {
                if (item.province_id == proId) {
                    return item;
                }
            });
            this.districts = result;
            this.selectedDistrict.distId = this.branches.distId;
            if (this.branches.proId == proId) {
                this.selectedDistrict.distId = this.branches.distId;
            } else {
                this.selectedDistrict = {...this.districts[0]};
            }
            this.FilterVillages(this.selectedDistrict.distId);
        }, // Filter-Distircts

        FilterVillages(distId) {
            const result = this.filterVillages.filter(item => {
                if (item.district_id == distId) {
                    return item;
                }
            });
            this.villages = result;
            if (this.branches.distId == distId) {
                this.selectedVillage.village_id = this.branches.village_id;
            } else {
                this.selectedVillage = {...this.villages[0]};
            }
            this.checkedAllServiceZone = false;
            this.checkedServiceZone = [];
        }, // Filter-Villages

        // Upload Profile
        triggerInputClick() {
            let selector = this.$refs.file;
            if (selector) {
                selector.click();
            }
        }, // Trigger-Click

        async onFileChange(e) {
            const file = e.target.files[0];
            const FileSize = (file.size / 1024 / 1024).toFixed(2);
            const extentsions = file.type.split("/");
            const validate_Ext = this.ValidateExtensions(extentsions[1]);

            if (validate_Ext == true && FileSize < 6) {
                this.profile = file;
                this.UploadFile();
            }
        }, // OnFile-Change
        FormatByte(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }, // FormatByte

        ValidateExtensions(ext) {
            if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp') {
                return true;
            } else {
                return false;
            }
        },

        UploadFile() {
            this.btnLoading = true;
            let formData = new FormData();
            formData.append('imageFile', this.profile);

            this.$axios.post('upload-file', formData).then((res) => {
                this.fileName = res.data.data.fileName;
                this.btnLoading = false;
            }).catch(() => {
                this.btnLoading = false;
            });
        }, // Upload-File
    },
    watch: {
        // Clear-Errors
        'selectedVillage.village_id': function () {
            this.server_errors.villageId = '';
        },
        'branches.prefix': function () {
            this.server_errors.prefix = '';
        },
        'branches.name': function () {
            this.server_errors.name = '';
        },
        'branches.email': function () {
            this.server_errors.email = '';
        },
        'branches.tel': function () {
            this.server_errors.tel = '';
        },
        'checkedServiceZone': function (value) {
            if (value.length == this.villages.length) {
                this.checkedAllServiceZone = true;
                if (this.villages.length) {
                    this.checkedAllServiceZone = true;
                } else {
                    this.checkedAllServiceZone = false;
                }
            } else {
                this.checkedAllServiceZone = false;
            }
        },
        'center.lat': function () {
            this.selectLatLng = this.center.lat + ',' + this.center.lng
        }
    }
}
