<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-users"></i>
          ລູກຄ້າທັງໝົດ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
      <div class="column is-6">
        <div style="float: right">
          <div v-if="$can(['SuperAdmin','accountingcod'])">
            <vs-button
                ref="button" flat
                @click="printShipment(start_date, end_date)"
                class="form-btn-loading"
                style="margin-top: 0"
                :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດລູກຄ້າ:</label>
            <div class="select">
              <select class="input" v-model="customer_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="all">ທັງໝົດລວມ pre-customer</option>
                <option value="general">ທົ່ວໄປ</option>
                <option value="vip">COD</option>
                <option value="company">ບໍລິສັດ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດລົງທະບຽນ:</label>
            <div class="select">
              <select class="input" v-model="register_type" @change="FetchData">
                <option value="">ທັງໝົດ</option>
                <option value="self">ລົງທະບຽນເອງ</option>
                <option value="employee">ພະນັກງານລົງທະບຽນໃຫ້</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາທີ່ສ້າງ:</label>
            <model-list-select
                :list="listBranches"
                v-model="branch"
                placeholder="ສາຂາທີ່ສ້າງ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ບ້ານ</vs-th>
            <vs-th>ເມືອງ</vs-th>
            <vs-th>ແຂວງ</vs-th>
            <vs-th>ສ້າງໂດຍ</vs-th>
            <vs-th>ສາຂາ</vs-th>
            <vs-th>ປະເພດລູກຄ້າ</vs-th>
            <vs-th>ວັນທີສະໝັກລູກຄ້າທົ່ວໄປ</vs-th>
            <vs-th>ວັນທີກາຍເປັນລູກຄ້າ COD</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="customer"
              v-for="(customer, index) in $vs.getPage(customers, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ customer.customer_id }}</vs-td>
            <vs-td>{{ customer.name }}</vs-td>
            <vs-td>{{ customer.tel }}</vs-td>
            <vs-td>{{ customer.village }}</vs-td>
            <vs-td>{{ customer.district }}</vs-td>
            <vs-td>{{ customer.province }}</vs-td>
            <vs-td>{{ customer.created_by || 'ລົງທະບຽນເອງ' }}</vs-td>
            <vs-td>{{ customer.branch_name || 'ບໍ່ມີ' }}</vs-td>
            <vs-td>{{ customer_type_la[customer.customer_type] }}</vs-td>
            <vs-td>{{ moment(customer.created_at).format('DD/MM/YYYY HH:mm') }}</vs-td>
            <vs-td v-if="customer.approved_cod_at">
              {{ moment(customer.approved_cod_at).format('DD/MM/YYYY HH:mm') }}
            </vs-td>
            <td v-else>
            </td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
    <CustomerExcelTemplate v-show="false" ref="ExportExcel"/>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import {ModelListSelect} from 'vue-search-select';
import CustomerExcelTemplate from "@coms/printing/CustomerExcelTemplate";

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect,
    CustomerExcelTemplate
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    customer_type: '',
    branch: '',
    listBranches: [],
    register_type: '',
    customers: [],
    searchItem: "",
    oldVal: "",
    customer_type_la: {
      'general': 'ລູກຄ້າທົ່ວໄປ',
      'vip': 'ລູກຄ້າCOD',
      'company': 'ລູກຄ້າບໍລີສັດ'
    },
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    'end_date': function () {
      this.pagination.current_page = 1;
      this.FetchData();
    },
  },
  methods: {
    printShipment(start_date, end_date) {
      const loading = this.BtnLoading();
      let listForPrint;
      this.loading = true;
      const ref = this.$refs["ExportExcel"];
      this.$http.get('list/customer', {
        params: {
          tel: this.searchItem,
          start_date: this.start_date,
          end_date: this.end_date,
          branch: this.branch,
          customer_type: this.customer_type,
          register_type: this.register_type,
        }
      }).then((res) => {
        listForPrint = res.data;
        if (ref) {
          ref.PrintExcel(listForPrint, start_date, end_date);
          loading.close();
          this.loading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchBranches() {
      this.$http.get('v1/listing/branches').then((res) => {
        this.listBranches = res.data;
        this.listBranches.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.customers = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
          this.markSelectedItemsOnPageChanges(this.customers);
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/customer', {
        params: {
          tel: this.searchItem,
          start_date: this.start_date,
          end_date: this.end_date,
          branch: this.branch,
          customer_type: this.customer_type,
          register_type: this.register_type,
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },

    /**
     * @StartChecking
     */
    markSelectedItemsOnPageChanges(items) {
      const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
      const newSelectedItems = [];
      items.map((i) => {
        if (selectedInvoiceIds.includes(i.id)) {
          newSelectedItems.push(i);
        }
      });
      this.pageInvoices = newSelectedItems;
    },

    onCheckAllCheckBoxChanged(event) {
      if (this.isSelecting) {
        return;
      }
      /// If checked
      if (event.target.checked) {
        // if its not indeterminate
        if (!this.isIndeterminate) {
          const loading = this.BtnLoading();
          this.isSelecting = true;
          /// Select all current page
          this.pageInvoices = JSON.parse(JSON.stringify(this.customers));
          /// Select all from response data
          this.fetchInvoiceItemsResponse({
            current_page: 1,
            per_page: this.count
          }).then((res) => {
            this.actualSelectedItems = res.data.data;
          }).catch(() => {
          }).finally(() => {
            loading.close();
            this.isSelecting = false;
          });
        }
      } else {
        /// Clear all selected orders
        this.clearAllChecks();
      }
    },

    onItemCheckBoxChanged(invoice) {
      const invoiceId = invoice.id;
      let itemIndex = this.actualSelectedItems.findIndex((item) => {
        return item.id === invoiceId;
      });
      if (itemIndex !== -1) {
        this.actualSelectedItems.splice(itemIndex, 1);
      } else {
        this.actualSelectedItems.push(invoice);
      }
      this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
    },

    isRowSelected(id) {
      return this.pageInvoices.map((i) => i.id).includes(id);
    },

    clearAllChecks() {
      this.checkedAll = false;
      this.pageInvoices = [];
      this.actualSelectedItems = [];
    },

    /**
     * @EndChecking
     */
  },
  created() {
    this.fetchBranches();
    this.FetchData();
  },
  computed: {
    isSelectAllOrders() {
      return this.actualSelectedItems.length === this.count
          && this.count > 0 && !this.isSelecting;
    },
    isSelectingOrders() {
      return this.actualSelectedItems.length > 0;
    },
    isIndeterminate() {
      return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
    }
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
