<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <li v-if="$can(['accountingmanager','accountingoriginbranch','accountingoriginfranchise'])"
            @click="Tabs('origin_freight')" :class="{'is-active': isActive === 'origin_freight'}">
          <a>Invoices ເກັບເງິນຄ່າຕົ້ນທາງ</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingcdc'])" @click="Tabs('destination_freight')"
            :class="{'is-active': isActive === 'destination_freight'}">
          <a>Invoices CDC</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingcod'])" :class="{'is-active': isActive === 'parcel_price'}"
            @click="Tabs('parcel_price')">
          <a>Invoices COD ລູກຄ້າ</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingcod'])"
            :class="{'is-active': isActive === 'parcel_price_branch'}" @click="Tabs('parcel_price_branch')">
          <a>Invoices COD ສາຂາ</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingoriginbranch','accountingoriginfranchise'])"
            @click="Tabs('origin_company')" :class="{'is-active': isActive === 'origin_company'}">
          <a>Invoices ລູກຄ້າລາຍເດືອນ</a>
        </li>
      </ul>
    </div>

    <ListInvoiceCOD v-if="isActive === 'parcel_price'"/>
    <ListInvoiceCODBranch v-if="isActive === 'parcel_price_branch'"/>
    <ListInvoiceCDC v-if="isActive === 'destination_freight'"/>
    <ListInvoiceOrigin v-if="isActive === 'origin_freight'"/>
    <ListInvoiceCompany v-if="isActive=== 'origin_company'"/>
  </div>
</template>

<script>
import ListInvoiceCOD from './List-Invoice-COD';
import ListInvoiceCODBranch from './List-Invoice-COD-branch';
import ListInvoiceCDC from './List-Invoice-CDC';
import ListInvoiceOrigin from './List-Invoice-Origin';
import ListInvoiceCompany from './List-Invoice-Company';

export default {
  components: {
    ListInvoiceCOD,
    ListInvoiceCDC,
    ListInvoiceOrigin,
    ListInvoiceCompany,
    ListInvoiceCODBranch
  },

  data: () => ({
    isActive: '',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'account.tab.list.order', query: {tab: value}}).catch(() => {
      });
    }
  },
  created() {
    if (this.$route.query.tab === 'parcel_price') {
      this.isActive = 'parcel_price';
    } else if (this.$route.query.tab === 'parcel_price_branch') {
      this.isActive = 'parcel_price_branch';
    } else if (this.$route.query.tab === 'destination_freight') {
      this.isActive = 'destination_freight';
    } else if (this.$route.query.tab === 'origin_freight') {
      this.isActive = 'origin_freight';
    } else if (this.$route.query.tab === 'origin_company') {
      this.isActive = 'origin_company';
    } else {
      this.$router.push({name: 'account.tab.list.order', query: {tab: this.isActive}});
      this.isActive = 'origin_freight';
    }
  },
}
</script>

<style>

</style>
