<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ກ່ອງໄປສະນີທັງໝົດ
        <span class="countTotal">({{ count }})</span>
<!--        <span class="btn-add">-->
<!--          <div class="columns">-->
<!--              <div class="field" style="margin-right: 12px">-->
<!--                 <vs-button circle icon flat @click="addPostBox">-->
<!--                    <i class="fal fa-plus-circle"></i>-->
<!--                 </vs-button>-->
<!--              </div>-->
<!--           </div>-->
<!--        </span>-->
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label class="label">ຄົ້ນຫາ</label>
            <input class="input" placeholder="ຄົ້ນຫາ..." v-model="searchResult" @keyup.enter="searchBox">
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ສາຂາມີກ່ອງໄປສະນີ</label>
            <div :style="{marginTop: '2px'}">
              <vs-select
                  filter
                  placeholder="ເລືອກສາຂາ"
                  name="branch"
                  class="vs-select-filter-cs"
                  v-model="selectedBranch.id"
                  v-if="branches.length > 0">
                <vs-option
                    v-for="(item, index) in branches"
                    :label="`${item.prefix}-${item.name}(${(item.district || {}).name }, ${(item.province || {}).name})`"
                    :value="item.id"
                    :key="index">
                  {{ item.prefix }}-{{ item.name }}({{ (item.district || {}).name }},{{ (item.province || {}).name }})
                </vs-option>
              </vs-select>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
              <div style="display: flex;align-items: flex-end;width: 100%;height: 60px">
                <vs-button  style="margin-top: 12px;" success :loading="isBtnLoading" @click="exportPostBox">
                  <span style="margin-right: 4px"><i class="far fa-file-csv"></i></span> Export Excel
                </vs-button>
                <vs-button style="float: right;margin-top: 8px" @click="addPostBox" primary>
                  <span style="margin-right: 3px"> <i  class="fal fa-plus-circle"></i> </span> ເພີ່ມຂໍ້ມູນ
                </vs-button>
              </div>
          </div>
        </div>

      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດກ່ອງ</vs-th>
            <vs-th>ຂະຫນາດກ່ອງ</vs-th>
            <vs-th>ສາຂາກ່ອງໄປສະນີ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index" v-for="(item, index) in post_boxes">
            <vs-td :style="{textAlign: 'center'}">{{ index + 1 }}</vs-td>
            <vs-td :style="{maxWidth: '100px', overflow: 'hidden',
             textOverflow: 'ellipsis', color: 'crimson'}">
              <vs-tooltip>
                ( {{ item.code }} )
                <template #tooltip>
                  ( {{ item.code }} )
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{maxWidth: '60px'}" v-if="item.size">
              {{ item.size.size }}
            </vs-td>
            <vs-td :style="{maxWidth: '150px'}" v-if="item.contract">
              {{ item.contract.branch.prefix }}-{{ item.contract.branch.name }}
            </vs-td>
            <vs-td :style="{maxWidth: '150px'}" v-else>
              N/A
            </vs-td>
            <vs-td :style="{width: '20%'}">{{ item.customer.name }}</vs-td>
            <vs-td :style="{maxWidth: '150px'}">
              <vs-tooltip>
                {{ item.customer.phone_number }}
                <template #tooltip>
                  {{ item.customer.phone_number }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td>
              <span style="color: green" v-if="item.status === 1">ເປີດ</span>
              <span style="color: red;" v-else>ປິດ</span>
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="editPostBox(item.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="fetchData($route.params.type)"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

  </div>
</template>
<script>
import Loading from '@/views/Loading.vue';
import pagination from '@/Plugins/Paginations/Pagination';
import {BranchGetValueOnInput} from '@/Helpers/GetValue';

export default {
  name: "PostBoxIndex",
  components: {
    Loading,
    pagination
  },

  data: () => ({
    value: '',
    isLoading: true,
    post_boxes: [],
    branches: [],
    count: 0,
    isBtnLoading: false,

    disabled_SelectedProvince: true,
    disabled_SelectedDistrict: true,
    searchResult: '',
    oldVal: '',

    // Pagination
    offset: 10,
    pagination: {},
    per_page: 10,

    selectedBranch: {id: 'all', name: 'ທັງໝົດ'},
  }),
  methods: {
    addPostBox() {
      this.$router.push({name: 'post_box.add'});
    },
    editPostBox(id) {
      this.$router.push({name: 'post_box.edit', params: {id: id}});
    },
    fetchData() {
      const branchId = this.selectedBranch.id;
      this.isLoading = true;
      this.$http.get('v1/auth/post-box/boxes', {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          branch_id: branchId !== 'all' ? branchId : null,
          query: this.searchResult,
        }
      }).then(res => {
        this.post_boxes = res.data.data
        this.pagination = res.data;
        this.count = this.pagination.total;
      }).catch(() => {
      }).finally(() => this.isLoading = false);
    },
    fetchBranches() {
      this.$http.get('v1/listing/branches').then((res) => {
        this.branches = res.data;
        this.branches.unshift({
          id: "all",
          prefix: 'All ',
          name: 'ທັງໝົດ',
          district: {
            name: 'd'
          },
          province: {
            name: 'p'
          },
        });
      })
    },
    FetchData() {
      return this.fetchData()
    },
    searchBox() {
      BranchGetValueOnInput(this);
    },
    exportPostBox(){
      this.isBtnLoading = true;
      this.$store.dispatch('exportPostBoxToExcel', this.$http)
          .catch(() => {
          }).finally(() => {
        this.isBtnLoading = false;
      })
    }
  },
  created() {
    this.fetchData();
    this.fetchBranches();
  },
  watch: {
    'searchResult': function (value) {
      if (value === '') {
        this.fetchData();
      }
    },
    'selectedBranch.id': function () {
      this.pagination.current_page = 1;
      this.fetchData();
    }
  }
}
</script>
<style lang="scss">

.vs-select-filter-cs {
  .vs-select {
    .vs-icon-arrow {
      right: auto;
    }
  }
}

.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}
</style>
