<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
                <i class="fal fa-plus-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
                ເພີ່ມ ຂໍ້ມູນສູນຄັດເເຍກພັດສະດຸ
            </h4>
        </template>
        
        <div class="field" :style="{marginTop: '20px'}">
            <div class="control">
                <label class="label">
                    ລະຫັດສາຂາ 
                    <span class="text-danger">* {{ errors.first('prefix') }}</span>
                    <span class="text-danger">
                        {{server_errors.prefix}}
                    </span>
                </label>
                <input class="input" name="prefix" placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..." v-model="ListDistributionCenter.prefix" v-validate="'required'">
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ສູນຄັດເເຍກພັດສະດຸ
                    <span class="text-danger">* {{ errors.first('name') }}</span>
                    <span class="text-danger">
                        {{server_errors.name}}
                    </span>
                </label>
                <input class="input" name="name" placeholder="ກະລຸນາປ້ອນສາຂາສາງກ່ອນ..." v-model="ListDistributionCenter.name" v-validate="'required'">
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເບີໂທຕິດຕໍ່ 
                    <span class="text-danger">* {{ errors.first('tel') }}</span>
                    <span class="text-danger">
                        {{server_errors.tel}}
                    </span>
                </label>
                <input 
                    class="input" name="tel" 
                    placeholder="ກະລຸນາປ້ອນເບີໂທຕິດຕໍ່ກ່ອນ..." 
                    v-model="ListDistributionCenter.tel" 
                    v-validate="'required|min:6|max:15'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ອີເມວ 
                    <span class="text-danger">* {{ errors.first('email') }}</span>
                    <span class="text-danger">
                        {{server_errors.email}}
                    </span>
                </label>
                <input 
                    class="input" name="email" 
                    placeholder="ກະລຸນາປ້ອນອີເມວກ່ອນ..." 
                    v-model="ListDistributionCenter.email" 
                    v-validate="'required|max:255'"
                >
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກພາກ 
                    <span class="text-danger">* {{ errors.first('part') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedPart.partId" name="part" v-validate="'required'" @change="FilterProvinces(selectedPart.partId)">
                        <option :value="part.partId" v-for="(part, index) in parts" :key="index">
                            {{translateInloas[part.name]}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກເເຂວງ 
                    <span class="text-danger">* {{ errors.first('province') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedProvince.proId" name="province" v-validate="'required'" @change="FilterDistricts(selectedProvince.proId)">
                        <option :value="province.proId" v-for="(province, index) in provinces" :key="index">
                            {{province.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກເມືອງ 
                    <span class="text-danger">* {{ errors.first('district') }}</span>
                </label>
                <div class="select">
                    <select v-model="selectedDistrict.distId" name="district" v-validate="'required'" @change="FilterVillages(selectedDistrict.distId)">
                        <option :value="district.distId" v-for="(district, index) in districts" :key="index">
                            {{district.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກບ້ານ
                    <span class="text-danger">* {{ errors.first('village') }}</span>
                    <span class="text-danger">
                        {{server_errors.villageId}}
                    </span>
                </label>
                <div class="select">
                    <select v-model="checkVillage" v-if="!villages.length" :style="{color: 'grey'}">
                        <option :value="checkVillage">
                            ບໍ່ມີຂໍ້ມູນບ້ານ...
                        </option>
                    </select>

                    <select v-model="selectedVillage.village_id" name="village" v-validate="'required'" v-else>
                        <option :value="village.village_id" v-for="(village, index) in villages" :key="index">
                            {{village.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

         <div class="field">
            <div class="control">
                <label class="label">
                    ເລືອກປະເພດສູນຄັດເເຍກ
                    <span class="text-danger">* {{ errors.first('type') }}</span>
                    <span class="text-danger">
                        {{server_errors.type}}
                    </span>
                </label>
                <div class="select">
                    <select class="input" v-model="ListDistributionCenter.type" :style="{width: '100%'}">
                        <option v-for="(item, index) in types" :key="index" :value="item.id">
                            {{item.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="field" v-if="ListDistributionCenter.type == 'branch'">
            <div class="control">
                <label class="label">
                    ເລືອກສາຂາ
                    <span class="text-danger">* {{ errors.first('type') }}</span>
                    <span class="text-danger">
                        {{server_errors.type}}
                    </span>
                </label>
                <model-list-select 
                    :list="listBranches"
                    v-model="ListDistributionCenter.branch_id"
                    placeholder="ເລືອກສາຂາປາຍທາງ"
                    option-text="name"
                    option-value="id"
                    selectedItem="name"
                >
                </model-list-select>
            </div>
        </div>
   
        <div ref="target" id="target" class="center">
            <div class="con-btns">
                <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">ເເກ້ໄຂ ສູນຄັດເເຍກ</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import MixinsEditDistribution from '../../../../Mixins/Distribution-Center/Edit';
import { ValidateDistributionCenter } from '@coms/../../src/Plugins/Validation/Validate-Distribution-Center';
import { ModelListSelect } from 'vue-search-select';


export default {
    components:{
        ModelListSelect
    },
    
    props:[
        'ListDistributionCenter'
    ],

    mixins: [MixinsEditDistribution],

    created(){
        this.FetchData();
        ValidateDistributionCenter(this);
    }
}
</script>
<style lang="scss" scoped>
    
</style>