<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-biking-mountain"></i>
          ຂໍ້ມູນ ເອີ້ນຮັບເຄື່ອງ
          <span class="countTotal">( {{ total }} )</span>
        </h4>
      </div>
      <div class="column is-6">
        <div style="float: right">
          <div>
            <vs-button
                ref="button" flat
                @click="printShipment()"
                class="form-btn-loading"
                style="margin-top: 0"
                :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ສະຖານະ :</label>
            <div class="select">
              <select class="input" @change="ReportOrderRequests()" v-model="selectedStatus.value">
                <option :value="status.value" v-for="(status, index) in statuses" :key="index">
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ ລາຍການ</vs-th>
            <vs-th>ວັນທີ່ສ້າງລາຍການ</vs-th>
            <vs-th>ເວລານັດຮັບເຄື່ອງ</vs-th>
            <vs-th>ຊື່ລູກຄ້າ</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ປະເພດພັດສະດຸ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ປະເພດພາຫະນະ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th>

            <!-- <vs-th>ລຳດັບ</vs-th>
            <vs-th>ເລກທີ່ບີນ</vs-th>
            <vs-th>ປະເພດພັດສະດຸ</vs-th>
            <vs-th>ປະເພດພັດສະດຸອື່ນໆ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ຂະໜາດ</vs-th>
            <vs-th>ປະເພດພາຫະນາ</vs-th>
            <vs-th>ຊື່ລູກຄ້າ</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ເເຂວງ</vs-th>
            <vs-th>ວັນທີ ເເລະ ເວລານັດ</vs-th>
            <vs-th>ຄຳອະທິບາຍ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ເຫດຜົນຍົກເລີກ</vs-th>
            <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th> -->
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index" v-for="(item, index) in listOrderRequests"
          >
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td :style="{width: '15%', color: 'crimson'}">
              ( {{ item.order_id }} )
            </vs-td>
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td>{{ item.schedule_time !== null ? item.schedule_time : '...............' }}</vs-td>
            <vs-td>{{ item.customer_name }}</vs-td>
            <vs-td>{{ item.customer_tel }}</vs-td>
            <vs-td>{{ item.categ_name }}</vs-td>
            <vs-td>{{ item.amount }}</vs-td>
            <vs-td>{{ item.weight }} kg</vs-td>
            <vs-td>
              <span v-if="item.vehicle_type == 'motorcycle'">ລົດຈັກ</span>
              <span v-if="item.vehicle_type == 'car'">ລົດໃຫຍ່</span>
            </vs-td>
            <vs-td>
              <span v-if="item.status == 'pending'" :style="{color: '#F57920'}">ລໍຖ້າອະນຸມັດ</span>
              <span v-if="item.status == 'confirm'" :style="{color: '#195BFF'}">ອະນຸມັດເເລ້ວ</span>
              <span v-if="item.status == 'on_the_way'" :style="{color: 'green'}">ກຳລັງໄປຮັບເຄື່ອງ</span>
              <span v-if="item.status == 'success'" :style="{color: '#195BFF'}">ສຳເລັດເເລ້ວ</span>
              <span v-if="item.status == 'reject'" :style="{color: 'crimson'}">ຖືກປະຕິເສດ</span>
              <span v-if="item.status == 'cancel'" :style="{color: 'crimson'}">ຍົກເລີກເເລ້ວ</span>
            </vs-td>
            <vs-td>
                            <span :style="{color: '#195BFF'}">
                                ( {{ item.rider_name !== null ? item.rider_name : '.................' }} )
                            </span>
            </vs-td>

            <!-- <vs-td style="text-align: right; width: 100px">
                <div class="group-icon">
                    <vs-button circle icon flat @click="OpenModalViewOrderRequestProcessing(item.id)">
                        <i class="fad fa-eye"></i>
                    </vs-button>
                </div>
            </vs-td>   -->
          </vs-tr>
        </template>

        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="ReportOrderRequests()"
          ></pagination>

          <Loading v-if="isLoading"/>
        </template>

      </vs-table>
    </div>

  </div>
</template>

<script>
import mixinsAdminReportOrderRequests from '../../../../Mixins/Reports/Admin-Report-OrderRequest';
import DatePicker from "vue2-datepicker";
import pagination from '../../../../Plugins/Paginations/Pagination';
import Loading from '../../../Loading';
import moment from 'moment';


export default {
  components: {
    DatePicker,
    pagination,
    Loading
  },

  methods: {
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
  },

  mixins: [mixinsAdminReportOrderRequests],

}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 100px;
    height: 60px;
    border-radius: 5px;
  }
}
</style>
