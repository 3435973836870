<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-30px', marginLeft: '-30px'}">
      <ul>
        <li :class="{'is-active': isActive == 'pending'}" @click="Tabs('pending')">
          <a>ລາຍການ ກຳລັງດຳເນີນການ</a>
        </li>
        <li @click="Tabs('reject')" :class="{'is-active': isActive == 'reject'}">
          <a>ລາຍການ ຖືກປະຕິເສດ</a>
        </li>
        <li @click="Tabs('cancel')" :class="{'is-active': isActive == 'cancel'}">
          <a>ລາຍການ ຖືກຍົກເລີກ</a>
        </li>
        <li @click="Tabs('success')" :class="{'is-active': isActive == 'success'}">
          <a>ລາຍການ ສຳເລັດເເລ້ວ</a>
        </li>
      </ul>
    </div>
    <OrderRequestPending v-if="isActive == 'pending'"/>
    <OrderRequestReject v-if="isActive == 'reject'"/>
    <OrderRequestCancel v-if="isActive == 'cancel'"/>
    <OrderRequestSuccess v-if="isActive == 'success'"/>
  </div>
</template>

<script>
import OrderRequestPending from './List-Order-Pending';
import OrderRequestReject from './List-Order-Reject';
import OrderRequestCancel from './List-Order-Cancel';
import OrderRequestSuccess from './List-Order-Success';

export default {
  components: {
    OrderRequestPending,
    OrderRequestReject,
    OrderRequestCancel,
    OrderRequestSuccess
  },

  data: () => ({
    isActive: false,
  }),

  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'delivery.tab.order.request', query: {tab: value}});
    }
  },
  created() {
    if (this.$route.query.tab == 'pending') {
      this.isActive = 'pending';
    } else if (this.$route.query.tab == 'reject') {
      this.isActive = 'reject';
    } else if (this.$route.query.tab == 'cancel') {
      this.isActive = 'cancel';
    } else if (this.$route.query.tab == 'success') {
      this.isActive = 'success';
    } else {
      this.isActive = 'pending';
    }
  }
}
</script>

<style>

</style>
