<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-plus-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
        ຂໍ້ມູນສູນຄັດເເຍກພັດສະດຸ
      </h4>
    </template>

    <div class="field">
      <div class="control">
        <label class="label">
          ເລືອກສູນຄັດແຍກປາຍທາງ
          <span class="text-danger">* {{ errors.first('new_distribution_center') }}</span>
          <span class="text-danger">
                        {{ server_errors.new_distribution_center }}
                    </span>
        </label>
        <model-list-select
            :list="listDistributionCenters"
            v-model="selectedShipmentBag.distribution_center_id"
            placeholder="ເລືອກສູນຄັດແຍກປາຍທາງ"
            option-text="name"
            option-value="id"
            selectedItem="name">
        </model-list-select>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading"
                   :loading="btnLoading">ປ່ຽນປາຍທາງ ສູນຄັດເເຍກ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import MixinsEditDistribution from '../../../../Mixins/Distribution-Center/ChangeDistributionCenterDestination';
import {ValidateDistributionCenter} from '@coms/../../src/Plugins/Validation/Validate-Distribution-Center';
import {ModelListSelect} from 'vue-search-select';


export default {
  components: {
    ModelListSelect
  },

  props: [
    'selectedShipmentBag'
  ],

  mixins: [MixinsEditDistribution],

  created() {
    this.fetchDistributionCenters();
    ValidateDistributionCenter(this);
  }
}
</script>
<style lang="scss" scoped>

</style>
