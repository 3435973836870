<template>
  <div>
    <DistributionLinkMapDataTabMenu :tabs="tabsData"/>
  </div>
</template>

<script>
import DistributionLinkMapDataTabMenu
  from "@views/SuperAdmin/Distribution-Center/Links/DistributionLinkMapDataTabMenu.vue";

import ListDistributionCenterLinkTab
  from "@views/SuperAdmin/Distribution-Center/Links/List-Distribution-Center-Link-Tab.vue";
import ListDistributionCenterPackingLinkSpecialBranchTab
  from "@views/SuperAdmin/Distribution-Center/Links/ListDistributionCenterPackingLinkSpecialBranchTab.vue";
import ListDistributionCenterLinkTabMenu
  from "@views/SuperAdmin/Distribution-Center/Links/ListDistributionCenterPackingDirectLinkBranchTab.vue";

export default {
  components: {
    DistributionLinkMapDataTabMenu
  },

  data: () => ({
    isLoading: false,
    distributionCenterStrictTypes:
        {all: 'ຍິງເຄື່ອງເຂົ້າ-ອອກ', inbound: 'ຍິງເຄື່ອງເຂົ້າ', outbound: 'ຍິງເຄື່ອງອອກ'},

    tabsData: [
      {
        title: 'ສູນຄັດເເຍກທີ່ສາມາດເຊື່ອມໂຍງໄປຫາ',
        nameKey: 'DistributionCenterLinks',
        component: ListDistributionCenterLinkTab,
        count: 0,
      },
      {
        title: 'ແພັກຄິງກັບສູນຄັດແຍກອື່ນ',
        nameKey: 'DistributionCenterLinkSpecialBranches',
        component: ListDistributionCenterPackingLinkSpecialBranchTab,
        count: 0,
      },
      {
        title: 'ແພັກຄິງຫາສາຂາໂດຍກົງ',
        nameKey: 'DistributionCenterDirectLinkBranches',
        component: ListDistributionCenterLinkTabMenu,
        count: 0,
      },
    ],

  }),

  methods: {

  },
  created() {

  }

}
</script>
