export default {
    data:()=>({
        btnLoading: false,
        provinces: [],
        selectedProvince: {},
        districts: [],
        selectedDistrict: {},
        villages: [],
        selectedVillage: [],
        msgError: '',
        searchVillage: ''
    }),
    methods: {
        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if(result){
                    this.SaveItem();
                }
            });
        },

        SaveItem(){
            this.btnLoading = true;

            let villages = this.selectedVillage.map(item => {
                return item.id || item.name;
            }).join(',');

            this.$axios.post('add-villages',{
                villages: villages,
                districtId: this.selectedDistrict.id
            }).then((res)=>{
                if(res.data.success == true){
                    setTimeout(()=>{
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(()=>{
                this.btnLoading = false;
                this.$emit('close');
                this.$emit('success');
                this.$notification.OpenNotification_Error_Server('top-right', 'dark', 3000);
            });
        },

        FetchData(){
            this.$axios.get('list-addresses').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
                setTimeout(()=>{
                    this.provinces = res.data.provinces;
                    this.selectedProvince = {...this.provinces[0]};
                    this.districts = res.data.districts;
                    this.selectedDistrict = {...this.districts[0]};
                    this.villages = res.data.listVillages;
                }, 100);
            }).catch(()=>{});
        },

        addTag(newTag){
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.villages.push(tag);
            this.selectedVillage.push(tag);
        },
    },
    computed: {
        FilterDistricts() {
            const result = this.districts.filter(item => {
                return item.province_id == this.selectedProvince.id;
            });
            this.selectedDistrict = {...result[0]};
            return result;
        },
        FilterVillages() {
            const Villages = this.villages.filter(item => {
                return item.distId == this.selectedDistrict.id;
            });
            return Villages;
        },
    },

}