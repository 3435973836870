<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ພະເເນກພາຫະນະ
        <span class="countTotal">({{ vehicleDepartments.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-6">
          <div class="control">
            <label class="label">ຄົ້ນຫາ</label>
            <input class="input" placeholder="ຄົ້ນຫາ..." v-model="searchResult" @keyup.enter="Search">
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ເລືອກພາກ</label>
            <div class="select">
              <select class="input" v-model="selectedPart" @change="Filter_Branch_By_Part(selectedPart)">
                <option :value="selectedAll">ທັງໝົດ</option>
                <option v-for="(part, index) in parts" :key="index" :value="part.partId">
                  {{ translateInloas[part.name] }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ເລືອກເເຂວງ</label>
            <div class="select">
              <select class="input" v-model="selectedProvince" @change="Filter_Branch_By_Province(selectedProvince)"
                      :disabled="disabled_SelectedProvince">
                <option :value="selectedAll">ທັງໝົດ</option>
                <option v-for="(province, index) in provinces" :key="index" :value="province.proId">
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ເລືອກເມືອງ</label>
            <div class="select">
              <select class="input" v-model="selectedDistrict" @change="Filter_Branch_By_District(selectedDistrict)"
                      :disabled="disabled_SelectedDistrict">
                <option :value="selectedAll">ທັງໝົດ</option>
                <option v-for="(district, index) in districts" :key="index" :value="district.distId">
                  {{ district.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ພະເເນກພາຫະນະ</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ສະຖານທີ່</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index" :data="item"
              v-for="(item, index) in $vs.getPage(vehicleDepartments, page, max)">
            <vs-td :style="{textAlign: 'center'}">{{ index + 1 }}</vs-td>
            <vs-td :style="{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'crimson'}">
              <vs-tooltip>
                ( {{ item.prefix }} )
                <template #tooltip>
                  ( {{ item.prefix }} )
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{maxWidth: '150px'}">
              <vs-tooltip>
                {{ item.name }}
                <template #tooltip>
                  {{ item.name }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{width: '20%'}">{{ item.tel }}</vs-td>
            <vs-td :style="{maxWidth: '150px'}">
              <vs-tooltip>
                {{ item.email !== null ? item.email : '...' }}
                <template #tooltip>
                  {{ item.email !== null ? item.email : '...' }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td style="color: #195BFF; font-size: 16px">
              <vs-tooltip>
                {{ item.villageName }}
                <template #tooltip>
                  ( {{ translateInloas[item.partName] }},
                  {{ item.proName }},
                  {{ item.distName }},
                  {{ item.villageName }} )
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button v-if="$can('SuperAdmin')"  circle icon flat @click="AddUserVehicleDepartment(item.id)">
                  <i class="fal fa-plus-circle"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(item.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button v-if="$can('SuperAdmin')"  circle danger icon flat @click="OpenModalDelete(item.id)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></Pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd :scrollTo="scrollTo">
      <template v-slot="{ close }">
        <AddVehicleDepartments
            @close="close"
            @scrollTo="ScrollTo()"
            @success="FetchData()"
        />
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditVehicleDepartments :listVehicleDepartment="filterVehicleDepartment" @close="close" @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteDistributionCenters @close="close" :vehicleDepartmentId="vehicleDepartmentId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import Loading from '../../Loading';
import AddVehicleDepartments from './Dialog/Add';
import EditVehicleDepartments from './Dialog/Edit';
import DeleteDistributionCenters from './Dialog/Delete';
import {BranchGetValueOnInput} from "@/Helpers/GetValue";
import Pagination from "@/Plugins/Paginations/Pagination";

export default {
  components: {
    Loading,
    AddVehicleDepartments,
    EditVehicleDepartments,
    DeleteDistributionCenters,
    Pagination,
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,

    vehicleDepartments: [],
    filterVehicleDepartment: {},
    vehicleDepartmentId: '',
    translateInloas: {
      center: 'ພາກກາງ',
      south: 'ພາກໃຕ້',
      north: 'ພາກເໜືອ',
      northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
      southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
      southern: 'ພາກໃຕ້-1'
    },
    scrollTo: false,
    parts: [],
    selectedPart: '',
    selectedAll: 'all',

    provinces: [],
    selectedProvince: '',
    districts: [],
    selectedDistrict: '',

    count: 0,

    filterProvinces: [],
    filterDistricts: [],

    disabled_SelectedProvince: true,
    disabled_SelectedDistrict: true,
    searchResult: '',
    oldVal: '',

    // Pagination
    offset: 10,
    pagination: {},
    per_page: 10,
  }),
  watch: {
    'searchResult': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
  },
  methods: {
    ScrollTo() {
      this.scrollTo = true;
    },
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(vehicleDepartmentId) {
      this.filterVehicleDepartment = {...this.FilterItem(vehicleDepartmentId)};
      this.$store.commit('modalEdit_State', true);
    },
    FilterItem(vehicleDepartmentId) {
      return this.vehicleDepartments.filter(item => {
        return item.id == vehicleDepartmentId;
      })[0] || {};
    },
    OpenModalDelete(vehicleDepartmentId) {
      this.vehicleDepartmentId = vehicleDepartmentId;
      this.$store.commit('modalDelete_State', true);
    },
    AddUserVehicleDepartment(id) {
      this.$router.push({name: 'list.user.vehicle.departments', query: { id }});
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-vehicle-departments',
          {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
              partId: this.selectedPart,
              provinceId: this.selectedProvince,
              districtId: this.selectedDistrict,
              result: this.searchResult,
            },
          }
      ).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.vehicleDepartments = res.data.vehicleDepartments.data;
          this.pagination = res.data.vehicleDepartments;
          this.count = this.pagination.total;
        }, 100);
      }).catch(() => {
      });
    },
    FetchAddresses() {
      this.$axios.get('list-branch-address').then(res => {
        const items = res.data.data;
        this.parts = items.parts;
        this.selectedPart = this.selectedAll;
        this.provinces = items.provinces;
        this.selectedProvince = this.selectedAll;
        this.districts = items.districts;
        this.selectedDistrict = this.selectedAll;

        this.filterProvinces = items.provinces;
        this.filterDistricts = items.districts;
      }).catch(() => {
      });
    },

    Filter_Branch_By_Part(partId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedProvince = this.selectedAll;
      this.selectedDistrict = this.selectedAll;
      this.FilterProvince(partId);
      this.FetchData();
      setTimeout(() => {
        if (partId == 'all') {
          this.isLoading = false;
          this.disabled_SelectedProvince = true;
          this.disabled_SelectedDistrict = true;
          return;
        }
        this.isLoading = false;
        this.disabled_SelectedProvince = false;
      }, 200);
    },

    Filter_Branch_By_Province(provinceId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedDistrict = this.selectedAll;
      this.FilterDistrict(provinceId);
      this.FetchData();
      setTimeout(() => {
        if (provinceId == 'all') {
          this.disabled_SelectedDistrict = true;
          if (this.selectedPart == 'all') {
            this.isLoading = false;
          } else {
            this.Filter_Branch_By_Part(this.selectedPart);
          }
        } else {
          this.isLoading = false;
          this.disabled_SelectedDistrict = false;
        }
      }, 200);
    },

    Filter_Branch_By_District(districtId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.FetchData();
      setTimeout(() => {
        if (districtId == 'all') {
          if (this.selectedProvince == 'all') {
            this.isLoading = false;
            return;
          } else {
            this.Filter_Branch_By_Province(this.selectedProvince);
          }
        }
        this.isLoading = false;
      }, 200);
    },

    // Filter Provinces
    FilterProvince(partId) {
      const result = this.filterProvinces.filter(item => {
        return item.part_id == partId;
      });
      this.provinces = JSON.parse(JSON.stringify(result));
    },
    // Filter Districts
    FilterDistrict(districtId) {
      const result = this.filterDistricts.filter(item => {
        return item.province_id == districtId;
      });
      this.districts = JSON.parse(JSON.stringify(result));
    },
    Search() {
      BranchGetValueOnInput(this);
    },
  },
  created() {
    this.FetchData();
    this.FetchAddresses();
  }
}
</script>

<style>

</style>
