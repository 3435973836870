<template>
  <div>
    <div class="box" style="margin-top: -24px; padding: 10px">
      <div class="columns is-multiline">
        <div class="column is-3">
          <div class="control">
            <label class="label">ຄົ້ນຫາ:</label>
            <input class="input" placeholder="ຄົ້ນຫາຂໍ້ມູນ..." v-model="shipment_number"
                   @keyup.enter="FilterShipmentNumber()">
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ວັນທິສ້າງ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="createdDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ລູກຄ້າ:</label>
            <model-list-select
                :list="listCustomers"
                v-model="selectedCustomer"
                placeholder="ເລືອກລູກຄ້າ..."
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ສະຖານະອິນວອຍ:</label>
            <div class="select">
              <select class="input" v-model="selectedStatus.value" @change="FilterStatus()">
                <option v-for="(item, index) in status" :key="index" :value="item.value">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-12" :style="{textAlign: 'right'}">
          <button
              class="button btn-export-split"
              :style="{marginLeft: '10px'}"
              @click="OpenModalPrintInvoiceCOD_Split()"
              :disabled="!isSelectingOrders"
          >
            <i class="fal fa-file-excel"></i>
            Export - {{ actualSelectedItems.length }}
          </button>
        </div>
      </div>
      <hr :style="{marginTop: '-10px'}">
      <div class="center" :style="{marginTop: '-10px'}">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ລວມເປັນເງິນທັງໝົດ</vs-th>
              <vs-th>ລວມລູກຄ້າ</vs-th>
              <vs-th>ລວມບິນ</vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :is-selected="true">
              <vs-td v-if="summary.total_price !== 0">{{ FormatPrice(summary.total_price) }}</vs-td>
              <vs-td v-else>0 ກີບ</vs-td>
              <vs-td>{{ count }} ຄົນ</vs-td>
              <vs-td>{{ summary.shipment_amount }} ບິນ</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="center">
      <!-- //@changed -->
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <!-- //@changed -->
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th>ວັນທີສຳເລັດ</vs-th>
            <vs-th>ເລກອິນວອຍ</vs-th>
            <vs-th>ຊື່ລູກຄ້າ</vs-th>
            <vs-th>ຈຳນວນເງິນທັງໝົດ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ຫຼັກຖານການໂອນເງິນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in invoices"
              :is-selected="isRowSelected(item.id)"
              :data="item"
              @selected="onItemCheckBoxChanged(item)">
            <vs-td>
              <div class="center con-checkbox">
                <!-- //@changed -->
                <vs-checkbox danger
                             :checked-force="isRowSelected(item.id)"
                             :val="item" @click="onItemCheckBoxChanged(item)">
                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                </vs-checkbox>
              </div>
            </vs-td>
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td v-if="item.invoice_success_at && item.invoice_status === 'success'">
              {{ FormatDate(item.invoice_success_at) }}
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ item.invoice_id }}</vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ FormatPrice(item.invoice_total_price) }}</vs-td>
            <vs-td>{{ la_status[item.invoice_status] }}</vs-td>
            <vs-td>
              <div v-if="item.payment_img" class="img-size">
                <expandable-image
                    class="image"
                    :src=item.payment_img
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ item.invoice_remark }}</vs-td>
            <vs-td>
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="EditStatementOrigin(item)"
                >
                  <i class="fad fa-pencil"></i>
                </vs-button>
                <vs-button circle icon flat
                           @click="$router.push({name: 'accounting.invoice.company.detail', params: {id: item.id}})"
                >
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalDelete>
      <template v-slot="{ close }">
        <ExportExcelSplit @close="close" :invoiceId="actualSelectedItems"
                          @success="ExportSuccess"/>
      </template>
    </ModalDelete>

    <ModalEdit>
      <template v-slot="{ close }">
        <ExportExcelSum @close="close"
                        :invoiceId="actualSelectedItems"
                        @success="ExportSuccess()"/>
      </template>
    </ModalEdit>

    <ModalPreviewFile>
      <template v-slot="{ close }">
        <EditStatementCompany @close="close"
                              :invoiceId="invoice_id"
                              @success="FetchData()"
        />
      </template>
    </ModalPreviewFile>

  </div>
</template>

<script>
import mixinListInvoices from '../../Mixins/Accountings/Invoices/Invoice-Company';
import DatePicker from "vue2-datepicker";
import Loading from '../Loading';
import ExportExcelSplit from './Dialog/Company-Split';
import ExportExcelSum from './Dialog/CDC-Total';
import {ModelListSelect} from 'vue-search-select';
import EditStatementCompany from './Dialog/Edit-Statement-Company';
import pagination from '../../Plugins/Paginations/Pagination';

export default {
  mixins: [mixinListInvoices],

  components: {
    DatePicker,
    Loading,
    ExportExcelSplit,
    ExportExcelSum,
    ModelListSelect,
    EditStatementCompany,
    pagination
  },

  created() {
    this.FetchData();
    this.FetchCustomers();
  }
}
</script>

<style lang="scss" scoped>
.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

</style>
