<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{marginTop: '10px'}">
        <img :src="require('@/assets/delete-trash.png')" class="img-delete">
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{fontSize: '18px', color: '#195BFF'}">
        {{ $t('shipment.ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການລຶບຂໍ້ມູນນີ້') }}
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="DeleteItem(bannerId)">
          {{ $t('ຂໍ້ມູນOrderDetail.ຢືນຢັນ')}}
        </vs-button>
        <vs-button  @click="$emit('close')" dark transparent>
          {{ $t('ຂໍ້ມູນOrderDetail.ຍົກເລີກ')}}
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props:['bannerId'],

  data:()=>({
    loading: false
  }),
  methods:{
    DeleteItem(bannerId){
      this.loading = true;
      this.$axios.delete('delete-banners/' + bannerId).then(res => {
        if(res.data.success == true){
          setTimeout(() => {
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
          }, 200);
        }
      }).catch(()=>{

      });
    }
  }
}
</script>
<style lang="scss">

</style>
