import * as helper from '../../Helpers/GetValue';
import {FormatDate, FormatDateTime, FormatDateTimeBeforeSendToServer, FormatNumber} from '@/Helpers/Format';
import moment from 'moment'

export default {
    data: () => ({
        isLoading: true,
        listAllOrders: [],
        startBranches: [],
        selectedStartBranch: '',
        endBranches: [],
        selectedEndBranchType: '',
        selectedStartBranchType: '',
        selectedEndBranch: '',
        shipmentStatus: [],
        selectedStatus: '',
        selectedCustomer: '',
        customers: [],
        count: 0,
        // Value
        startDate: moment(new Date()).format('YYYY-MM-DD 00:00'),
        endDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        selectedAllStatus: null,
        searchItem: '',

        // GroupBy UserId
        groupBy: false,
        totalFreight: 0,
        totalPrice: 0,
        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10,

        //Export
        actualSelectedItems: [],
        isSelecting: false,
        pageInvoices: [],
        loading: false,
        checkedAll: false,

        oldVal: '',

        selectedSenderType: '',
        selectedReceiverType: '',
        selectedDeliverType: '',
        //LA_Status
        la_status: {
            'Pending': 'ລໍຖ້າດໍາເນີນການ',
            'Processing': 'ກຳລັງດຳເນີນການ',
            'Delivering': 'ກໍາລັງຈັດສົ່ງ',
            'Success': 'ຈັດສົ່ງສຳເລັດ',
            'Single Sign': 'ຮັບເຄື່ອງແບບແຍກ',
            'Timeout': 'ຈັດສົ່ງເຄື່ອງມື້ອື່ນ',
            'Contact Error': 'ເບີຕິດຕໍ່ບໍ່ໄດ້,ບໍ່ສາມາດຈັດສົ່ງ',
            'Lost': 'ເຄື່ອງເສຍ',
            'Return To Sender': 'ຈັດສົ່ງຄືນຜູ້ຝາກ',
            'Address Error': 'ທີ່ຢູ່ຜິດ(ປ່ຽນທີຢູ່ໃຫມ່)',
            'Trouble': 'ສະຖານະບໍ່ປົກກະຕິ',
            'Disposed': 'ທໍາລາຍ',
            'Customer Request Cancel': 'ລູກຄ້າຍົກເລີກ',
            'Wrong Flow': 'ເຄື່ອງທີ່ຍິງຂ້າມ',
            'Expires': 'ໝົດເຂດຮັບເຄື່ອງ',
            'BillWasSettled': 'ຮຽກເກັບເງິນສຳເລັດແລ້ວ',
            'SuccessfullyCompensated': 'ຊົດເຊີຍເງິນສຳເລັດ'
        },

        la_status_shipment_type: {
            'origin_freight_fees': 'ເກັບເງິນຕົ້ນທາງ',
            'destination_freight_fees': 'ເກັບເງິນ ( CDC )',
            'origin_freight_fees_cod': 'ເກັບເງິນ ( COD )',
            'destination_freight_fees_cod': 'ເກັບເງິນ ( COD & CDC )',
            'all': 'ທັງໝົດ'
        }
    }),
    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },

        printShipment() {
            if (this.actualSelectedItems.length > 0) {
                const shipment_id = this.actualSelectedItems.map(item => item.id)
                const loading = this.BtnLoading();
                this.loading = true;
                this.$http.post('marketing/print-shipment', {
                        shipment_id: shipment_id,
                        startDate: FormatDate(this.startDate),
                        endDate: FormatDate(this.endDate),
                        shipment_pay_type: this.$route.query.tab
                    },
                    {responseType: 'blob'}
                ).then((response) => {
                    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                    const fileLink = document.createElement('a');
                    fileLink.href = fileUrl;
                    if (FormatDateTimeBeforeSendToServer(this.startDate) === FormatDateTimeBeforeSendToServer(this.endDate)) {
                        fileLink.setAttribute('download', 'ລາຍການອໍເດີ' + this.la_status_shipment_type[this.$route.query.tab] + 'ປະຈຳວັນທີ ' + FormatDate(this.startDate) + '.xlsx');
                    } else {
                        fileLink.setAttribute('download', 'ລາຍການອໍເດີ' + this.la_status_shipment_type[this.$route.query.tab] + 'ປະຈຳວັນທີ ' + FormatDate(this.startDate) + 'ຫາວັນທີ ' + FormatDate(this.endDate) + '.xlsx');
                    }

                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.actualSelectedItems = [];
                    this.FetchData();
                    this.loading = false;
                    loading.close()
                }).catch(() => {
                    this.loading = false;
                    loading.close()
                })
            }
        },

        FormatDate(value) {
            return FormatDateTime(value);
        },

        FormatPrice(price) {
            if (price !== 0) {
                return FormatNumber(price);
            }
            return 0 + ' ກີບ';
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('marketing/list-shipment', {
                params: {
                    shipment_pay_type: this.$route.query.tab,
                    start_branch: this.selectedStartBranch,
                    end_branch: this.selectedEndBranch,
                    startBranchType: this.selectedStartBranchType,
                    endBranchType: this.selectedEndBranchType,
                    query: this.searchItem,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    deliverType: this.selectedDeliverType,
                    senderType: this.selectedSenderType,
                    customer: this.selectedCustomer,
                    receiverType: this.selectedReceiverType,
                    shipment_status_id: this.selectedStatus,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page,
                }
            })
        },

        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.listAllOrders = res.data.shipments.data;
                    this.pagination = res.data.shipments;
                    this.count = res.data.shipments.total;
                    this.totalFreight = res.data.totalFreight;
                    this.totalPrice = res.data.totalPrice;
                    this.isLoading = false;
                    this.markSelectedItemsOnPageChanges(this.listAllOrders);
                    // this.oldVal = '';
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        FetchListStartBranch() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedStartBranchType + '&include=excluded').then((res) => {
                this.startBranches = res.data;
                this.startBranches.unshift({
                    id: "",
                    name: 'ທັງໝົດ'
                });
            })
        },

        FetchListEndBranch() {
            this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedEndBranchType + '&include=excluded').then((res) => {
                this.endBranches = res.data;
                this.endBranches.unshift({
                    id: "",
                    name: 'ທັງໝົດ'
                });
            })
        },

        FetchShipmentStatus() {
            this.$http.get('marketing/shipment/statuses').then((res) => {
                this.shipmentStatus = res.data;
                this.shipmentStatus.splice(2, 0, {id: 'Arrived', name: 'Arrived'})
            }).catch(() => {
            });
        },

        ClearData() {
            this.startDate = moment(new Date()).format('YYYY-MM-DD 00:00');
            this.endDate = moment(new Date()).format('YYYY-MM-DD HH:mm');
            this.selectedStartBranch = '';
            this.selectedEndBranch = '';
            this.selectedStatus = '';
            this.selectedSenderType = '';
            this.selectedReceiverType = '';
            this.searchItem = '';
            this.selectedEndBranchType = '';
            this.selectedStartBranchType = '';
            this.FetchListStartBranch();
            this.FetchListEndBranch();
            this.FetchShipmentStatus();
            this.FilterCustomers();
        },

        FilterOrders() {
            this.pagination.current_page = 1;
            this.FetchData();
        },

        FilterByShipmentNumber() {
            helper.GetOldValueOnInput(this);
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) {
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },

        onCheckAllCheckBoxChanged(event) {
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if its not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.listAllOrders));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.shipments.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecks();
            }
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },

        isRowSelected(id) {
            return this.pageInvoices.map((i) => i.id).includes(id);
        },

        clearAllChecks() {
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },

        /**
         * @EndChecking
         */
    },
    watch: {
        'searchItem': function (value) {
            if (value === '') {
                this.FetchData();
            }
        },

        'startDate': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'endDate': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },

        'selectedEndBranch': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'selectedStartBranch': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'selectedStartBranchType': function () {
            this.pagination.current_page = 1;
            this.FetchListStartBranch();
            this.FetchData();
        },
        'selectedCustomer': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'selectedEndBranchType': function () {
            this.pagination.current_page = 1;
            this.FetchListEndBranch();
            this.FetchData();
        }
    },
    computed: {
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
        }
    },
}
