<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-gifts"></i>
          ສະຫຼຸບຍອດສາຂາທັງໝົດ
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="FetchData()"
                  class="form-btn-loading"
                  :loading="loading">
                ຄົ້ນຫາ
              </vs-button>
            </div>
          </div>
        </div>
<!--        <div class="column is-2" style="margin-top: 3px" v-if="this.listSummary.length > 0">-->
<!--          <vs-button-->
<!--              ref="button" flat-->
<!--              @click="printSummery()"-->
<!--              class="form-btn-loading"-->
<!--              :loading="loading">-->
<!--            export excel-->
<!--          </vs-button>-->
<!--        </div>-->
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ຍອດສ້າງບິນຕົ້ນທາງ</vs-th>
            <vs-th>ຍອດສ້າງບິນ CDC</vs-th>
            <vs-th>ຍອດສ້າງບິນ COD</vs-th>
            <vs-th>ຍອດຈັດສົ່ງບິນຕົ້ນທາງ</vs-th>
            <vs-th>ຍອດຈັດສົ່ງບິນ CDC</vs-th>
            <vs-th>ຍອດຈັດສົ່ງບິນ COD</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr>
            <vs-td>{{ FormatPrice(listSummary.receiveOriginQty) }} ບິນ | {{ FormatPrice(listSummary.receiveOriginFreight) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(listSummary.receiveCdcQty) }} ບິນ | {{ FormatPrice(listSummary.receiveCdcFreight) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(listSummary.receiveCodQty) }} ບິນ | {{ FormatPrice(listSummary.receiveCodFreight) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(listSummary.successOriginQty) }} ບິນ | {{ FormatPrice(listSummary.successOriginFreight) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(listSummary.successCdcQty) }} ບິນ | {{ FormatPrice(listSummary.successCdcFreight) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(listSummary.successCodQty) }} ບິນ | {{ FormatPrice(listSummary.successCodFreight) }} ກີບ</vs-td>
          </vs-tr>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
// import * as helper from "@/Helpers/GetValue";
import Loading from "@/views/Loading";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  components: {
    DatePicker,
    Loading
  },

  data: () => ({
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    listSummary: [],
    searchItem: "",
    oldVal: "",
    totalFreight: 0,
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    // FilterByShipmentNumber() {
    //   helper.GetOldValueOnInput(this);
    // },
    // printSummery() {
    //   const loading = this.BtnLoading();
    //   this.loading = true;
    //   this.$http.post('/print/list/sign/cod', {
    //     search: this.searchItem,
    //     start_date: this.start_date,
    //     end_date: this.end_date,
    //     print: 'yes',
    //   }, {responseType: 'blob'}).then((res) => {
    //     const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
    //     const fileLink = document.createElement('a');
    //     fileLink.href = fileUrl;
    //     if (this.start_date === this.end_date) {
    //       fileLink.setAttribute('download', 'ລາຍການເຊັນຮັບCODວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
    //     } else {
    //       fileLink.setAttribute('download', 'ລາຍການເຊັນຮັບCODວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
    //     }
    //     document.body.appendChild(fileLink);
    //     fileLink.click();
    //     document.body.removeChild(fileLink);
    //     this.loading = false;
    //     loading.close()
    //   }).catch(() => {
    //     this.loading = false;
    //     loading.close()
    //   });
    // },

    FormatPrice(price) {
      if (price < 1 && price >= 0) {
        return price
      }
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    FetchData() {
      this.loading = true;
      this.$http.get('branch/shipment/total', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
        }
      }).then((res) => {
        setTimeout(() => {
          this.listSummary = res.data;
          this.loading = false;
        }, 300);
      }).catch(() => {
        this.loading = false;
      })
    },

    // FilterBySearch() {
    //   helper.GetOldValueOnInput(this);
    // }
    // ,
    ...
        mapActions({
          LogoutUser: "User/destroyToken",
        }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    }
    ,
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
