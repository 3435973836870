<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ໜ້າທີ່ຂອງຜູ້ໃຊ້ລະບົບ 
                <span class="countTotal">({{permissions.length}})</span>
                <span class="btn-add">
                    <vs-button
                        circle
                        icon
                        flat
                        @click="OpenModalAdd()"
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>

        <vs-table>
            <template #thead>
            <vs-tr>
                <vs-th>
                    ລຳດັບ
                </vs-th>
                <vs-th>
                    ໜ້າທີ່ ຜູ້ໃຊ້ລະບົບ
                </vs-th>
                <vs-th>
                    displayName
                </vs-th>
                <vs-th>
                    description
                </vs-th>
                <vs-th></vs-th>
            </vs-tr>
            </template>
            <template #tbody>
            <vs-tr
                :key="index"
                v-for="(permission, index) in permissions"
                :data="permission">
                <vs-td>
                    {{ index + 1 }}
                </vs-td>
                <vs-td>
                    {{ permission.name }}
                </vs-td>
                <vs-td>
                    {{ permission.display_name }}
                </vs-td>
                <vs-td>
                    {{ permission.description !== null ? permission.description : '...........................' }}
                </vs-td>
                <vs-td style="text-align: right; width: 100px">
                    <div class="group-icon">
                        <vs-button
                            circle
                            icon
                            flat
                            @click="OpenModalEdit(permission.id)"
                        >
                        <i class="fad fa-pencil-alt"></i>
                        </vs-button>
                        <vs-button
                            circle
                            danger
                            icon
                            flat
                            @click="OpenModalDelete(permission.id)"
                        >
                        <i class="fad fa-trash-alt"></i>
                        </vs-button>
                    </div>
                </vs-td>
            </vs-tr>
            </template>
            <template #footer>
                <Loading v-if="isLoading"/>
            </template>
        </vs-table>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddPermissions @close="close" @success="FetchData()"/>
            </template>
        </ModalAdd>
        
        <ModalEdit>
            <template v-slot="{ close }">
                <EditPermissions :listPermission="filterPermission" @close="close" @success="FetchData()"/>
            </template>
        </ModalEdit>

         <ModalDelete>
            <template v-slot="{ close }">
                <DeletePermissions @close="close" :permission_id="permissionId" @success="FetchData()"/>
            </template>
        </ModalDelete>

    </div>
</template>
<script>
import AddPermissions from './Dialog/Add';
import EditPermissions from './Dialog/Edit';
import DeletePermissions from './Dialog/Delete';
import Loading from '../../Loading';

export default {
    components:{
        AddPermissions,
        EditPermissions,
        DeletePermissions,
        Loading
    },

    data:()=>({
        permissions: [],  
        filterPermission: {},
        permissionId: '',
        isLoading: true,
    }),
    methods:{

        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        OpenModalEdit(permissionId){
            this.filterPermission = {...this.FilterItem(permissionId)};
            this.$store.commit('modalEdit_State', true);
        },
        FilterItem(permissionId){
            return this.permissions.filter(item=>{
                return item.id == permissionId;
            })[0] || {};
        },
        OpenModalDelete(permissionId){
            this.permissionId = permissionId;
            this.$store.commit('modalDelete_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-permissions').then(res=>{
                setTimeout(() => {
                    this.$emit('close');
                    this.isLoading = false;
                }, 300);
                setTimeout(()=>{
                    this.permissions = res.data.permissions;
                }, 300);
    
            }).catch(()=>{});
        }
    },
    created(){
        this.FetchData();
    }
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}

.con-btns{
    display: flex;
    align-items: center;
    justify-content: center;
}
.center{
    flex-direction: column;
    .content-div{
        width: 100%;
        height: auto;
        //box-shadow: 0px 6px 25px -10px rgba(0,0,0,.1);
        border-radius: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: .6rem;
    }
}
</style>