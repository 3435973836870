<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເພີ່ມສາຂາທີ່ຈັດສົ່ງ
      </h4>
    </template>

    <div class="field" :style="{marginTop: '20px'}">
      <div class="control">
        <label class="label">
          ສາຂາທີ່ຈັດສົ່ງ
          <span class="text-danger">* {{ errors.first('branch_ids') }}</span>
          <span class="text-danger">
                        {{ server_errors.branch_ids }}
                    </span>
        </label>
        <div :style="{marginTop: '5px'}">
          <vs-select
              filter multiple
              placeholder="ກະລຸນາເລືອກສາຂາທີ່ຈັດສົ່ງ..."
              name="branch_ids"
              v-model="branch_ids"
              v-validate="'required'">
            <vs-option
                v-for="(item, index) in branches"
                :label="item.name"
                :value="item.id"
                :key="index">
              {{ item.name }}
            </vs-option>
          </vs-select>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="validateForm()" class="form-btn-loading" :loading="btnLoading">ບັນທຶກ
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>

const validateMessage = {
  custom: {
    branch_ids: {
      required: 'ກະລຸນາເລືອກສາຂາທີ່ຈັດສົ່ງ.',
    },
  }
};

export default {
  props: ['branch_id', 'branches'],

  data: () => ({
    server_errors: {
      links: '',
    },
    branch_ids: [],
    btnLoading: false,
  }),
  methods: {
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveItem();
        }
      });
    },
    saveItem() {
      this.btnLoading = true;
      this.$axios.post('branch-link/' + this.branch_id, {
        links: this.branch_ids,
      }).then(() => {
        this.$emit('close');
        this.$emit('success');
      }).catch(() => {
      }).finally(() => {
        this.btnLoading = false;
      });
    }
  },
  watch: {
    branch_id: function () {
      this.server_errors.links = '';
    },
  }, created() {
    this.$validator.localize('en', validateMessage);
  }
}
</script>
