export default {
    data: () => ({
        loading: false,
        shipment_number: '',
        listShipmentBag: [],
        isLoading: false,
        pageInvoices: [],
        checkedAll: false,
        pushShipments: [],
        // Pagination
        offset: 10,
        pagination: {},
        limit_page: 600,
        count: 0,

        //export
        actualSelectedItems: [],
        isSelecting: false,
    }),
    watch: {
        'shipment_number': function (value) {
            if (value === '') {
                this.FilterListOrders();
            }
        },
    },
    methods: {
        removeBill(bag_number) {
            if (this.actualSelectedItems.length > 0) {
                const loading = this.BtnLoading();
                this.loading = true;
                this.actualSelectedItems.map(item => {
                    this.pushShipments.push(item.parcel_bill_number);
                });
                this.$http.post('v1/auth/branches/shipments/orders/tracking/packing-parcel-orders/cancel/multi-parcel-orders', {
                    bag_bill_number: bag_number.bag_number,
                    bill_numbers: this.pushShipments
                }).then(() => {
                    setTimeout(() => {
                        this.loading = false;
                        loading.close();
                        this.FilterListOrders();
                        this.pushShipments = [];
                        this.actualSelectedItems = [];
                    }, 300);
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('distribution/center/shipment/bag/detail/' + this.$route.params.id, {
                params: {
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page,
                    shipment_number: this.shipment_number,
                }
            })
        },

        FilterListOrders() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.isLoading = false;
                    this.pagination = res.data;
                    this.listShipmentBag = res.data.data;
                    this.count = res.data.total;
                    this.markSelectedItemsOnPageChanges(this.listShipmentBag);
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) {
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },
        onCheckAllCheckBoxChanged(event) {
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if its not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.listShipmentBag));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecks();
            }
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },

        isRowSelected(id) {
            return this.pageInvoices.map((i) => i.id).includes(id);
        },

        clearAllChecks() {
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },
        /**
         * @EndChecking
         */
    },
    computed: {
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
        }
    },
}
