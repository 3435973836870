import { Validator } from 'vee-validate';

const validate = {
    custom: {
        email:{
            required: '( ກະລຸນາປ້ອນອີເມວກ່ອນ... )',
            email: '( ອີເມວຂອງທ່ານບໍ່ຖືກຕ້ອງ... )',
            max: '( ອີເມວຍາວເກີນໄປ... )',
        },
        password:{
            required: '( ກະລຸນາປ້ອນລະຫັດຜ່ານກ່ອນ... )',
            min: '( ລະຜ່ານບໍ່ຄວນສັ້ນກວ່າ 6 ໂຕ... )',
            max: '( ລະຫັດຜ່ານບໍ່ຄວນຫຼາຍກວ່າ 15 ໂຕ... )',
        },
    }
};

export const ValidateFormSignIn = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}