import { render, staticRenderFns } from "./Edit-Statement-Company.vue?vue&type=template&id=13b6a041&scoped=true"
import script from "./Edit-Statement-Company.vue?vue&type=script&lang=js"
export * from "./Edit-Statement-Company.vue?vue&type=script&lang=js"
import style0 from "./Edit-Statement-Company.vue?vue&type=style&index=0&id=13b6a041&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b6a041",
  null
  
)

export default component.exports