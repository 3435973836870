<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ marginTop: '10px' }">
        <img src="@/assets/icons8-checked.png" class="img-delete" />
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{ fontSize: '18px', color: '#195BFF' }">
        ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການເຂົ້າຮ່ວມຂອງລູກຄ້າ
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button
          :loading="loading"
          transparent
          :style="{ color: 'crimson' }"
          @click="approved(customerId)"
        >
          ຢືນຢັນ
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          ຍົກເລີກ
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ["customerId"],
  data: () => ({
    loading: false,
  }),
  methods: {
    approved(customerId) {
      this.loading = true;
      this.$http
        .post("update-invite-status/" + customerId)
        .then((res) => {
          if (res.data.success) {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_Celebrate_Joined(
                "top-right",
                "success",
                3000
              );
            }, 300);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
</style>