<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-users" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເເກ້ໄຂຂໍ້ມູນລູກຄ້າ
      </h4>
    </template>

    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ທະນາຄານ
          <span class="text-danger">* {{ errors.first('bank_name') }}</span>
          <span class="text-danger">
                        {{ server_errors.bank_name }}
                    </span>
        </label>
        <input class="input" name="bank_name" type="text"
               placeholder="ກະລຸນາປ້ອນຊື່ທະນາຄານ..." v-model="listCustomer.bank_name"
               v-validate="'required|max:225'">
      </div>
    </div>
    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ຊື່ບັນຊີ
          <span class="text-danger">* {{ errors.first('account_name') }}</span>
          <span class="text-danger">
                        {{ server_errors.account_name }}
                    </span>
        </label>
        <input class="input" name="account_name" type="text"
               placeholder="ກະລຸນາປ້ອນຊື່ບັນຊີ..." v-model="listCustomer.account_name"
               v-validate="{required:true, max:255, regex: '^[A-Z0-9-_.() ,\s]*$'}"
        >
        <!--             v-validate="'required|max:225|regex:^[A-Z_.() ,\\s]*$'"> -->
      </div>
    </div>
    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ເລກບັນຊີ
          <span class="text-danger">* {{ errors.first('account_number') }}</span>
          <span class="text-danger">
                        {{ server_errors.account_number }}
                    </span>
        </label>
        <input class="input" name="account_number" type="text"
               placeholder="ກະລຸນາປ້ອນເລກບັນຊີ..." v-model="listCustomer.account_number"
               v-validate="'required|min:13|max:18|numeric'">
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
          ເເກ້ໄຂຂໍ້ມູນລູກຄ້າ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import MixinsEditCustomer from '@/Mixins/Customer/Edit';
import {ValidateCustomer} from '@/Plugins/Validation/Validate-Customer';


export default {
  props: ['listCustomer'],

  mixins: [MixinsEditCustomer],

  created() {
    ValidateCustomer(this);
  }
}
</script>
<style lang="scss" scoped>
.label-title {
  text-align: left;
  margin-top: -15px;
}

.box-list-permission {
  text-align: left;
  margin-top: 10px;
  background: rgba(var(--vs-gray-2), 1);
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  max-width: 368px;

  .permission {
    background: $primary-color;
    padding: 5px 5px 5px 5px;
    color: $white;
    border-radius: 5px;
    width: auto;
    margin: 3px;
    text-align: center;
    font-size: 14px;
  }
}

.text-filename {
  .type-file {
    color: $primary-color !important;
  }
}

.text-size {
  font-size: 14px;

  .size {
    color: $primary-color !important;
  }
}
</style>
