<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-info-circle" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເພີ່ມ ສູນຄັດເເຍກ
      </h4>
    </template>

    <div class="field" :style="{marginTop: '20px'}">
      <div class="control">
        <label class="label">
          ສູນຄັດເເຍກ
          <span class="text-danger">* {{ errors.first('distributioncenterId') }}</span>
          <span class="text-danger">
                        {{ server_errors.distributionCenterId }}
                    </span>
        </label>
        <div :style="{marginTop: '5px'}">
          <vs-select
              filter multiple
              placeholder="ກະລຸນາເລືອກສູນຄັດເເຍກ..."
              name="distributioncenterId"
              v-model="distributionCenterId"
              v-validate="'required'">
            <vs-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in distributionCenters"
                :key="index">
              {{ item.name }}
            </vs-option>
          </vs-select>
        </div>
      </div>
    </div>

    <div class="field" :style="{marginTop: '20px'}">
      <div class="control">
        <label class="label">
          ກໍານົດການເຊື່ອມຕໍ່
          <span class="text-danger">* {{ errors.first('distribution_center_strict_type') }}</span>
          <span class="text-danger">
                        {{server_errors.distribution_center_strict_type}}
           </span>
        </label>
        <model-list-select
            v-validate="'required'"
            :list="distributionCenterStrictTypes"
            v-model="distribution_center_strict_type"
            placeholder="ເລືອກການເຊື່ອມຕໍ່"
            option-text="name"
            option-value="id"
            selectedItem="name">
        </model-list-select>
      </div>

    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">ບັນທຶກ
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import mixinsAddDistributionCenterLink from '@coms/../../src/Mixins/Distribution-Center/Links/Add';
import {ValidateDistributionCenter} from '@coms/../../src/Plugins/Validation/Validate-Distribution-Center';
import { ModelListSelect } from 'vue-search-select';


export default {
  components: {
    ModelListSelect,
  },

  props: ['distributionCenters'],

  mixins: [mixinsAddDistributionCenterLink],

  created() {
    ValidateDistributionCenter(this);
  }
}
</script>

<style>

</style>
