<template>
  <div>
    <div class="field" :style="{marginTop: '30px'}">
      <div class="control">
        <label class="label">ສະຖານະ</label>
        <div class="select">
          <select class="input" v-model="selectedStatus.value">
            <option v-for="(item, index) in status" :key="index" :value="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <label class="label">ໝາຍເຫດ</label>
          <input
              class="input"
              v-model="invoice_remark"
              placeholder="ໝາຍເຫດ..."
          >
        </div>
      </div>
    </div>
    <vs-button ref="button" flat
               @click="updateCodInvoiceStatus()"
               class="form-btn-loading"
               :loading="loading"
    >
      ເເກ້ໄຂສະຖານະ
    </vs-button>
  </div>
</template>

<script>

export default {
  props: [
    'invoiceId',
  ],


  data: () => ({
    payDate: new Date(),
    loading: false,
    invoice_remark: '',
    status: [
      {value: 'pending', name: 'ກຳລັງດຳເນີນການ'},
      {value: 'success', name: 'ສຳເລັດ'},
    ],
    selectedStatus: {
      value: 'pending', name: 'ເລືອກທັງໝົດ'
    },
  }),
  methods: {
    updateCodInvoiceStatus() {
      const invoiceId = this.invoiceId.map(item => item.id);
      this.loading = true;
      this.$http.put('admin-update-invoice', {
        invoiceId: invoiceId,
        invoice_status: this.selectedStatus.value,
        invoice_remark: this.invoice_remark,
        paid_type: 'parcel_price'
      }).then((res) => {
        if (res.data.success){
          setTimeout(() => {
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
      }).catch(() => {
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>
