<template>
    <div>
        <template>
            <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px' }">
                <i class="fal fa-info-circle" :style="{ width: '30px', fontSize: '18px' }"></i>
                ຂໍ້ມູນ Permission-Role
            </h4>
        </template>

        <div class="field" :style="{marginTop: '25px'}">
            <label class="label" :style="{textAlign: 'left'}">
                ໜ້າທີ່
                <span class="text-danger">* {{ errors.first('name') }}</span>
                <span class="text-danger">
                    {{server_errors.permissions}}
                </span>
            </label>
            <div :style="{marginTop: '5px'}">
                 <vs-select
                    filter
                    multiple
                    placeholder="ກະລຸນາເລືອກໜ້າທີ່..." 
                    v-if="permissions.length"
                    v-model="permissionId">
                    <vs-option :label="permission.name" :value="permission.id" v-for="(permission, i) in permissions" :key="i">
                       {{permission.name}}
                    </vs-option>
                </vs-select>
            </div>
        </div>
        <div class="box-btn">
            <button class="button" :class="{'is-loading': isLoading}" @click="ValdateForm()">
                ບັນທຶກ ບົດບາດ
            </button>
        </div>
    </div>
</template>
<script>


export default {
    props:['permissions'],

    data:()=>({
        active: false,
        isLoading: false,
        server_errors: {
            permissions: '',
        },
        permissionId: [],
    }),
     methods:{
        ValdateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveData();
                }
            });
        },
        SaveData(){
            this.isLoading = true;
            this.$axios.post('add-permission-roles/' + this.$route.params.id,{
                permissions: this.permissionId
            }).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.isLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                this.isLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
        // FetchRoles(){
        //     this.$axios.get('list-permissions').then(res=>{
        //         this.listpermissions = res.data.permissions;
        //     });
        // }
       
    },
    created(){
        //this.FetchRoles();
    }
}
</script>
<style lang="scss" scoped>
    
</style>