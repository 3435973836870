export default {
    data:()=>({
        isLoading: true,
        startDate: new Date(),
        endDate: new Date(),
        listOrderRequestSuccess: [],
        searchOrderId: '',
        orderRequestId: '',
        filterOrderRequest: {},

        // pagination
        offset: 10,
        pagination: {},
        per_page: 10,
        totalOrder: 0
    }),
    methods: {
        // Fetch Pending Order Request
        FetchSuccesstOrderRequest(){
            this.isLoading = true;
            this.$axiosDelivery.get('list-success-order-requests',{
                params:{
                    startDate: this.FormatStartDateAndEndDate(this.startDate),
                    endDate: this.FormatStartDateAndEndDate(this.endDate),
                    page: this.pagination.current_page,
                    per_page: this.per_page
                }
            }).then((res) => {
                setTimeout(()=>{
                    this.listOrderRequestSuccess = res.data.data;
                    this.pagination = res.data;
                    this.totalOrder = res.data.total;
                    this.isLoading = false;
                }, 300);
            }).catch(()=>{});
        },
        OpenModalViewOrderRequestSuccess(orderRequestId){
            this.filterOrderRequest = this.FilterOrderSuccess(orderRequestId);
            this.$store.commit('modalPreviewFile_State', true);
        },
        FilterOrderSuccess(orderRequestId){
            return this.listOrderRequestSuccess.filter(item=>{
                return item.id == orderRequestId;
            })[0] || {};
        },

        EscapeRegExp(string) {
            string = string.toString()
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
    },
    computed:{
        FilterOrderRequestSuccess(){
			var item = this.EscapeRegExp(this.searchOrderId)
            const pattern = new RegExp(item, 'i')
            return this.listOrderRequestSuccess.filter(item => {
                setTimeout(()=>{
                    this.isLoading = false;
                }, 300);
                return pattern.test(item.order_id);
            });
        },
    },
    watch:{
        'startDate': function(){
            this.FetchSuccesstOrderRequest();
        },
        'endDate': function(){
            this.FetchSuccesstOrderRequest();
        }
    }
}