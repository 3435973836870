export default {
    data:()=>({
        orderDetails: [],
        latestTrackingDetails: {},
        order: {},
        trackingStatues: [],
        isLoading: false
    }),
    methods: {
        fetchLatestTracking(parcelBillNumber) {
            this.$http.get('v1/orders/tracking/' + parcelBillNumber).then((res) => {
                const trackingStatues = res.data.tracking_events;
                const length = trackingStatues.length;
                this.$set(this.latestTrackingDetails, parcelBillNumber, trackingStatues[length - 1])
            }).catch((error) => {
                console.error("Failed to load latest tracking:", error)
            });
        },
        async fetchTrackingsSequentially(items) {
            for (const item of items) {
                // Make the API call
                await this.fetchLatestTracking(item.parcel_bill_number);
                // Wait for milliseconds
                await new Promise((resolve) => setTimeout(resolve, 700));
            }
        },
        FetchOrderDetails(){
            this.isLoading = true;
            this.$axios.get('account/shipment/order/detail/' + this.$route.params.id).then((res) => {
                this.orderDetails = res.data.parcels_in_shipment;
                this.fetchTrackingsSequentially(this.orderDetails).then(() => console.log("retrieved"));
                this.order = res.data.shipment;
                setTimeout(()=>{
                    this.isLoading = false;
                    this.TrackingStatus();
                }, 500);
            }).catch(()=>{});
        },
        TrackingStatus(){
            this.$http.get('v1/orders/tracking/' + this.order.shipment_number).then((res) => {
                this.trackingStatues = res.data.tracking_events;
            }).catch(()=>{});
        }
    },
}
