<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-47px', marginLeft: '-30px'}">
      <ul>
        <li v-if="$can(['accountingmanager','accountingoriginbranch','accountingoriginfranchise'])" @click="Tabs('origin_freight')" :class="{'is-active': isActive === 'origin_freight'}">
          <a>Orders ເກັບເງິນຄ່າຕົ້ນທາງ</a>
        </li>
        <li v-if="$can(['accountingmanager','accountingcdc'])" @click="Tabs('destination_freight')" :class="{'is-active': isActive === 'destination_freight'}">
          <a>Orders CDC</a>
        </li>
        <li  v-if="$can(['accountingmanager','accountingcod'])" :class="{'is-active': isActive === 'parcel_price'}" @click="Tabs('parcel_price')">
          <a>Orders COD ລູກຄ້າ</a>
        </li>
        <li  v-if="$can(['accountingmanager','accountingcod'])" :class="{'is-active': isActive === 'parcel_price_branch'}" @click="Tabs('parcel_price_branch')">
          <a>Orders COD ສາຂາ</a>
        </li>
        <li  v-if="$can(['accountingmanager','accountingoriginbranch','accountingoriginfranchise'])" @click="Tabs('origin_company')" :class="{'is-active': isActive === 'origin_company'}">
          <a>Orders ລູກຄ້າລາຍເດືອນ</a>
        </li>
      </ul>
    </div>

    <listShipmentCodBranch v-if="isActive === 'parcel_price_branch'"/>
    <listShipmentCod v-if="isActive === 'parcel_price'"/>
    <listShipmentCdc v-if="isActive === 'destination_freight'"/>
    <ListShipmentOrigin v-if="isActive === 'origin_freight'"/>
    <ListShipmentCompany v-if="isActive=== 'origin_company'"/>
  </div>
</template>

<script>
import listShipmentCod from '@/views/Accounting/ShipmentCompany/List-shipment-COD';
import listShipmentCodBranch from '@/views/Accounting/ShipmentCompany/List-shipment-COD-branch';
import listShipmentCdc from '@/views/Accounting/ShipmentCompany/List-shipment-CDC';
import ListShipmentOrigin from '@/views/Accounting/ShipmentCompany/List-shipment-Origin';
import ListShipmentCompany from '@/views/Accounting/ShipmentCompany/List-Shipments';


export default {
  components: {
    listShipmentCod,
    listShipmentCdc,
    ListShipmentOrigin,
    ListShipmentCompany,
    listShipmentCodBranch
  },

  data: () => ({
    isActive: 'origin_freight',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'account.tab.list.shipment', query: {tab: value}}).catch(() => {
      });
    },
  },
  created() {
    if (this.$route.query.tab === 'parcel_price') {
      this.isActive = 'parcel_price';
    } else if (this.$route.query.tab === 'parcel_price_branch') {
      this.isActive = 'parcel_price_branch';
    } else if (this.$route.query.tab === 'destination_freight') {
      this.isActive = 'destination_freight';
    } else if (this.$route.query.tab === 'origin_freight') {
      this.isActive = 'origin_freight';
    } else if (this.$route.query.tab === 'origin_company') {
      this.isActive = 'origin_company';
    } else {
      this.$router.push({name: 'account.tab.list.shipment', query: {tab: 'origin_freight'}});
      this.isActive = 'origin_freight';
    }
  },
}
</script>

<style>

</style>
