import { Validator } from 'vee-validate';

const validate = {
    custom: {
        name:{
            required: '( ກະລຸນາປ້ອນຊື່ບໍລິສັດກ່ອນ... )',
        },
        tel:{
            required: '( ກະລຸນາປ້ອນເບີໂທຕິດຕໍ່ກ່ອນ... )',
            min: '( ເບີໂທບໍ່ຄວນໜ້ອຍກວ່າ 6 ໂຕເລກ... )',
            max: '( ເບີໂທບໍ່ຄວນເກີນ 15 ໂຕເລກ... )',
        },
        email:{
            required: '( ກະລຸນາປ້ອນອີເມວກ່ອນ... )',
            max: '( ອີເມວບໍ່ຄວນຍາວກ່ວາ 255 ໂຕ... )',
            email: '(ອີເມວບໍ່ຖືກຕ້ອງ...)',
        },
        website:{
            required: '( ກະລຸນາປ້ອນເວັບໄຊລກ່ອນ... )',
        },
        facebook:{
            required: '( ກະລຸນາປ້ອນເຟສບຸກກ່ອນ... )',
        },
        address:{
            required: '( ກະລຸນາປ້ອນທີ່ຢູ່ກ່ອນ... )',
        },
    }
};

export const ValidateContactUs = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}