<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-users"></i>
          ຂໍ້ມູນຄະແນນລູກຄ້າ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2" style="margin-top: 7px">
          <div v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
            <vs-button
                ref="button" flat
                @click="printListPoint()"
                class="form-btn-loading"
                :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
      <hr :style="{marginTop: '-10px'}">
      <div class="center" :style="{marginTop: '-10px'}">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ຈຳນວນຄະແນນທັງໝົດ</vs-th>
              <vs-th>ຈຳນວນຄະແນນທີ່ໃຊ້</vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :is-selected="true">
              <vs-td>{{ FormatPrice(totalPoint) }}</vs-td>
              <vs-td>{{ FormatPrice(totalSpend) }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ຈຳນວນຄະແນນທັງໝົດ</vs-th>
            <vs-th>ຈຳນວນຄະແນນທີ່ໃຊ້ແລ້ວ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="customer"
              v-for="(customer, index) in $vs.getPage(customersPoint, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ customer.customer_id }}</vs-td>
            <vs-td>{{ customer.name }}</vs-td>
            <vs-td>{{ customer.tel }}</vs-td>
            <vs-td>{{ FormatPrice(customer.total) }}</vs-td>
            <vs-td>{{ FormatPrice(customer.spend) }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({name: 'list.point.detail', params:{id: customer.user_id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import moment from "moment/moment";

export default {
  components: {
    Loading,
    pagination,
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    customer_type: '',
    branch: '',
    listBranches: [],
    register_type: '',
    customersPoint: [],
    searchItem: "",
    oldVal: "",
    totalPoint: 0,
    totalSpend: 0,
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
  },
  methods: {
    printListPoint() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('/print/list/point', {
        search: this.searchItem,
        print: 'yes'
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'ລາຍການຄະແນນຂອງລູກຄ້າ '+ moment().format('DD-MM-YYYY') +'.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "") + " ຄະແນນ";
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.customersPoint = res.data.data.data;
          this.totalPoint = res.data.totalPoint;
          this.totalSpend = res.data.totalSpend;
          this.count = res.data.data.total;
          this.pagination = res.data.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/point', {
        params: {
          search: this.searchItem,
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
