<template>
  <div class="center">
    <vs-navbar center-collapsed v-model="active">
      <template #left>
        <vs-button
          class="reduce-icon menu"
          :class="{ 'not-active': !$store.state.sideBarCollapse }"
          @click="toggleSidebar"
          flat
          icon
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="bars"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="menu-icon"
          >
            <path
              fill="currentColor"
              d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
              class=""
            ></path>
          </svg>
        </vs-button>
      </template>
      <template #icon>
        <i class="fal fa-list"></i>
      </template>
      <template #right>
        <div class="control" style="display: flex; margin-right: 8px">
          <div class="select" style="display: flex">
            <span style="margin-top: 4px; margin-right: 8px">
              <img
                class="img-flag"
                v-if="$i18n.locale === 'LA'"
                src="https://cdn.countryflags.com/thumbs/laos/flag-400.png"
                alt=""
              />
              <img
                class="img-flag"
                v-if="$i18n.locale === 'CN'"
                src="https://cdn.countryflags.com/thumbs/china/flag-400.png"
                alt=""
              />
            </span>
            <select v-model="selectedLanguage" @change="switchLanguage(selectedLanguage)">
              <option
                v-for="locale in locales"
                :key="locale"
                :value="locale"
              >
                {{ localeNames[locale] }}
              </option>
            </select>
          </div>
        </div>
        <vs-button v-if="$can('chat-admin')" icon color="messenger" to="/chat">
          <i class="fa fa-message"></i>Chat
        </vs-button>
        <vs-button v-if="!isActorAsMobileApp" @click="LogoutUsers()">{{
          $t("Navbar.ອອກຈາກລະບົບ")
        }}</vs-button>
      </template>
    </vs-navbar>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    active: "guide",
    selectedLanguage: localStorage.getItem("Lang"),
    locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    localeNames: { CN: "China", LA: "ລາວ" },
  }),
  computed: {
    isActorAsMobileApp() {
      return this.$route.query.actor == "mobile_app";
    },
  },
  methods: {
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),
    LogoutUsers() {
      const loading = this.$vs.loading();
      this.LogoutUser()
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.$notification.OpenNotification_Logout_Success(
                "top-right",
                "danger",
                3000
              );
              loading.close();
            }, 500);
          }
        })
        .catch(() => {
          loading.close();
        });
    },
    toggleSidebar() {
      this.$store.commit("toggleSidebar");
    },
    switchLanguage(newValue) {
      if (this.$i18n.locale !== newValue) {
        this.$i18n.locale = newValue;
        localStorage.setItem("Lang", newValue);
        window.location.reload();
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.vs-navbar-content {
  position: fixed !important;
}
.reduce-icon {
  &.menu {
    padding-left: 1.3rem;

    &:focus {
      background: rgba(var(--vs-color), 0.15);
    }
  }

  &.not-active {
    margin-left: 236px;
  }

  svg.menu-icon {
    color: rgba(var(--vs-color), 1) !important;
    width: 13px;
  }
}

.img-flag {
  width: 50px;
  height: 20px;
  margin-top: 5px;
}
</style>
