<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ marginTop: '10px' }">
        <img :src="require('@/assets/cancel.png')" class="img-delete" />
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{ fontSize: '18px', color: '#195BFF' }">
        {{
          $t(
            "ຂໍ້ມູນOrderDetail.ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການຍົກເລີກຈັດສົ່ງສຳເລັດບິນ"
          )
        }}
      </p>
    </div>

    <template>
      <div class="con-footer" :style="{ marginTop: '25px' }">
        <vs-button
          :loading="loading"
          transparent
          :style="{ color: 'crimson' }"
          @click="Cancel()"
        >
          {{ $t("ຂໍ້ມູນOrderDetail.ຢືນຢັນ") }}
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          {{ $t("ຂໍ້ມູນOrderDetail.ຍົກເລີກ") }}
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ["shipment_number"],
  data() {
    return {
      active: false,
      loading: false,
    };
  },

  methods: {
    Cancel() {
      this.loading = true;
      this.$http
        .post("cancel-success-order/" + this.shipment_number)
        .then((res) => {
          if (res.data === "Success") {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_Cancel_SuccessOrder_Success(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          } else {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_Cancel_SuccessOrder_UnSuccess(
                "top-right",
                "warning",
                3000
              );
            }, 300);
          }
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
          this.$emit("success");
          setTimeout(() => {
            this.loading = false;
            this.$emit("close");
            this.$emit("success");
            this.$notification.OpenNotification_Cancel_SuccessOrder_UnSuccess(
              "top-right",
              "warning",
              3000
            );
          }, 300);
        });
    },

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
};
</script>
