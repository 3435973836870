<template>
  <div>
    <div class="field" :style="{marginTop: '30px'}">
      <div class="control">
        <label class="label">ຈຳນວນກ່ອງ:
          <span class="text-danger">* {{ errors.first('bagQty') }}</span>
          <span class="text-danger">{{ server_errors.bagQty }}</span>
        </label>
        <input
            class="input"
            type="text"
            name="bagQty"
            placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            v-model="bagQty"
            v-validate="'required|numeric|max_value:200|min_value:1'"
        />
      </div>
    </div>
    <vs-button ref="button" flat
               @click="generateBag()"
               class="form-btn-loading"
               :loading="loading"
    >
      ສ້າງກະເປົາ
    </vs-button>
  </div>
</template>

<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    bagQty: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      min_value: "(ປ້ອນຢ່າງຕ່ຳ 1 ກ່ອງ)",
      max_value: "(ປ້ອນໄດ້ສູງສຸດ 200 ກ່ອງ)",
      numeric: "(ກະລຸນາປ້ອນເປັນຕົວເລກ)"
    },
  },
};
Validator.localize("en", dict);
export default {
  data: () => ({
    loading: false,
    bagQty: '',
    server_errors: {
      bagQty: '',
    },
  }),
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.generateBag();
        }
      });
    },
    generateBag() {
      this.loading = true;
      this.$http.post('generate/reusable/bag', {
        'bagQty': this.bagQty
      }).then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.loading = false;
      });
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
  },
  watch: {
    'bagQty': function () {
      this.server_errors.bagQty = '';
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>
