<template>
    <vs-dialog prevent-close v-model="active">
       <slot v-bind="{ close }"/>
    </vs-dialog>
</template>
<script>

export default {
    computed: {
        active: {
            set(val) {
               this.$store.commit('modalPrint_State', val);
            },
            get() {
                return this.$store.state.modalPrintState;
            }
        }
    },
    methods: {
        close() {
            this.active = false;
        },
    }
}
</script>

<style lang="scss">
    .img-delete{
        width: 150px;
    }
    .con-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .vs-button{
        margin: 0px;
        font-size: 16px;
        .vs-button__content{
            padding: 10px 30px;
            ~ .vs-button{
                margin-left: 10px
            }
        }
    }
    .not-margin{
        margin: 0px;
        font-weight: normal;
        padding: 10px;
        padding-bottom: 0px;
    }
    .con-content{
        width: 100%;
        p{
            font-size: .8rem;
            padding: 0px 10px;
        }
        .vs-checkbox-label{
            font-size: .8rem
        }
        .vs-input-parent{
            width: 100%;
        }
        .vs-input-content{
            margin: 10px 0px;
            width: calc(100%);
        }

    }
    .footer-dialog{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100%);
        .new{
            margin: 0px;
            margin-top: 20px;
            padding: 0px;
            font-size: .7rem;
            a{
                color: getColor('primary') !important;
                margin-left: 6px;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .vs-button{
            margin: 0px;
        }
    }
}
</style>
