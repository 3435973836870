<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        {{ $t("ລາຍການOrderພັດສະດຸ.ລາຍການທັງໝົດ") }}
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add" :style="{marginTop: '-5px !important'}"
              v-if="$route.query.tab === 'origin_freight_fees'
              || $route.query.tab === 'destination_freight_fees'">
                   {{ $t("ລາຍການOrderພັດສະດຸ.ຍອດຄ່າຂົນສົ່ງທັງໝົດ") }}: {{ FormatPrice(totalFreight) }}
        </span>
        <span class="btn-add" :style="{marginTop: '-5px !important'}"
              v-else>
                     {{ $t("ລາຍການOrderພັດສະດຸ.ຍອດຄ່າຂົນສົ່ງທັງໝົດ") }}: {{ FormatPrice(totalFreight) }} |  {{ $t("ລາຍການOrderພັດສະດຸ.ຍອດຄ່າສິນຄ້າ") }}: {{ FormatPrice(totalPrice) }}
        </span>
        <span class="btn-add">
         <div class="columns">
            <div class="field" :style="{marginRight: '5px'}">
             <vs-button
                 ref="button" flat
                 @click="printShipment()"
                 class="form-btn-loading"
                 :loading="loading"
                 :disabled="listAllOrders.length <= 0">
               Export excel
             </vs-button>
            </div>
            <div class="field" style="margin-right: 12px">
              <vs-button
                  ref="button"
                  @click="ClearData()"
                  class="form-cls-loading"
                  :loading="loading">
                {{ $t("ລາຍການOrderພັດສະດຸ.ລ້າງຂໍ້ມູນ") }}
              </vs-button>
            </div>
          </div>
        </span>
      </h4>
    </div>

    <div class="box" style="margin-bottom: 12px;margin-top: -12px;">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ") }}:</label>
              <input class="input" :placeholder="`${$t('ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ')}...`" v-model="searchItem" @keyup.enter="FilterByShipmentNumber()">
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຂົນສົ່ງ") }}:</label>
            <div class="select">
              <select class="input" v-model="selectedDeliverType">
                <option value="">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option value="express">express</option>
                <option value="logistics">logistics</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດສາຂາຕົ້ນທາງ") }}:</label>
            <div class="select">
              <select class="input" v-model="selectedStartBranchType">
                <option value="">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option value="branch">
                  {{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາຫຼັກທັງໝົດ") }}
                </option>
                <option value="branch_capital">
                  {{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາຫຼັກນະຄອນຫຼວງ") }}
                </option>
                <option value="branch_province">
                  {{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາຫຼັກຕ່າງແຂວງ") }}
                </option>
                <option value="franchise">
                  {{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາແຟນຊາຍ") }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາປາຍທາງ") }}</label>
            <model-list-select
                :list="endBranches"
                v-model="selectedEndBranch"
                :placeholder="`${$t('ລາຍການOrderພັດສະດຸ.ເລືອກສາຂາປາຍທາງ')}`"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control field">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຈາກວັນທີ") }}:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຫາວັນທີ") }}:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ສະຖານະ") }}:</label>
            <div class="select">
              <select class="input" v-model="selectedStatus">
                <option :value="selectedAllStatus">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option v-for="(item, index) in shipmentStatus" :key="index" :value="item.id">
                  <span v-if="item.name === 'Pending'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ລໍຖ້າດໍາເນີນການ")
                    }}</span>
                  <span v-if="item.name === 'Processing'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ກຳລັງດຳເນີນການ")
                    }}</span>
                  <span v-if="item.name === 'Arrived'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ເຄື່ອງຮອດສາຂາປາຍທາງ")
                    }}</span>
                  <span v-if="item.name === 'Delivering'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ກໍາລັງຈັດສົ່ງ")
                    }}</span>
                  <span v-if="item.name === 'Success'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ຈັດສົ່ງສຳເລັດ")
                    }}</span>
                  <span v-if="item.name === 'Single Sign'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ຮັບເຄື່ອງແບບແຍກ")
                    }}</span>
                  <span v-if="item.name === 'Timeout'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ຈັດສົ່ງເຄື່ອງມື້ອື່ນ")
                    }}</span>
                  <span v-if="item.name === 'Contact Error'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ເບີຕິດຕໍ່ບໍ່ໄດ້,ບໍ່ສາມາດຈັດສົ່ງ")
                    }}</span>
                  <span v-if="item.name === 'Lost'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ເຄື່ອງເສຍ")
                    }}</span>
                  <span v-if="item.name === 'Return To Sender'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ຈັດສົ່ງຄືນຜູ້ຝາກ")
                    }}</span>
                  <span v-if="item.name === 'Address Error'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ທີ່ຢູ່ຜິດ(ປ່ຽນທີຢູ່ໃຫມ່)")
                    }}</span>
                  <span v-if="item.name === 'Trouble'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ສະຖານະບໍ່ປົກກະຕິ")
                    }}</span>
                  <span v-if="item.name === 'Disposed'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ທໍາລາຍ")
                    }}</span>
                  <span v-if="item.name === 'Customer Request Cancel'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ລູກຄ້າຍົກເລີກ")
                    }}</span>
                  <span v-if="item.name === 'Wrong Flow'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ເຄື່ອງທີ່ຍິງຂ້າມ")
                    }}</span>
                  <span v-if="item.name === 'Expires'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ໝົດເຂດຮັບເຄື່ອງ")
                    }}</span>
                  <span v-if="item.name === 'BillWasSettled'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ຮຽກເກັບເງິນສຳເລັດແລ້ວ")
                    }}</span>
                  <span v-if="item.name === 'SuccessfullyCompensated'">{{
                      $t("ລາຍການOrderພັດສະດຸ.ຊົດເຊີຍເງິນສຳເລັດ")
                    }}</span>
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຜູ້ສົ່ງ") }}:</label>
            <div class="select">
              <!--              <select class="input" v-model="selectedSenderType" @change="FetchData(groupBy = true)">-->
              <select class="input" v-model="selectedSenderType">
                <option value="">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option value="general">{{ $t("ລາຍການOrderພັດສະດຸ.ລູກຄ້າທົ່ວໄປ") }}</option>
                <option value="company"> {{ $t("ລາຍການOrderພັດສະດຸ.ລູກຄ້າລາຍເດືອນ") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຜູ້ຮັບ") }}:</label>
            <div class="select">
              <select class="input" v-model="selectedReceiverType">
                <option value="">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option value="general">{{ $t("ລາຍການOrderພັດສະດຸ.ລູກຄ້າທົ່ວໄປ") }}</option>
                <option value="company">{{ $t("ລາຍການOrderພັດສະດຸ.ລູກຄ້າລາຍເດືອນ") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຜູ້ສ້າງບິນ") }}:</label>
            <div class="select">
              <select class="input" v-model="selectedUserType">
                <option value="">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option value="branch">{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາ") }}</option>
                <option value="delivery">{{ $t("ລາຍການOrderພັດສະດຸ.ເດລີເວີລີ") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຜູ້ຈັດສົ່ງ") }}:</label>
            <div class="select">
              <select class="input" v-model="selectedSignUserType">
                <option value="">{{ $t("ລາຍການOrderພັດສະດຸ.ທັງໝົດ") }}</option>
                <option value="branch">{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາ") }}</option>
                <option value="delivery">{{ $t("ລາຍການOrderພັດສະດຸ.ເດລີເວີລີ") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຜູ້ສ້າງບິນ") }}:</label>
            <model-list-select
                :list="users"
                v-model="selectedUser"
                :placeholder="`${ $t('ລາຍການOrderພັດສະດຸ.ຜູ້ຮັບຜິດຊອບ') }`"
                option-text="name"
                option-value="id"
                selectedItem="name">
            </model-list-select>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">{{$t('ລາຍການOrderພັດສະດຸ.ຜູ້ຈັດສົ່ງ')}}:</label>
            <model-list-select
                :list="signUsers"
                v-model="selectedSignUser"
                :placeholder="`${$t('ລາຍການOrderພັດສະດຸ.ຜູ້ຈັດສົ່ງ')}`"
                option-text="name"
                option-value="id"
                selectedItem="name">
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="FetchData()"
                  class="form-btn-loading"
                  :loading="loading">
                {{ $t("ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ") }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center table-limit-height">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <!--            <vs-th>-->
            <!--              <div class="center">-->
            <!--                <vs-checkbox danger-->
            <!--                             @click="onCheckAllCheckBoxChanged"-->
            <!--                             :value="isIndeterminate || isSelectAllOrders"-->
            <!--                             :loading="isSelecting"-->
            <!--                             :checked-force="isSelectAllOrders"-->
            <!--                             :indeterminate="isIndeterminate">-->
            <!--                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>-->
            <!--                </vs-checkbox>-->
            <!--              </div>-->
            <!--            </vs-th>-->
            <vs-th></vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ເລກທີບິນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີຝາກເຄື່ອງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີແກ້ໄຂບິນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີເຄື່ອງຮອດ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ວັນທີຈັດສົ່ງສຳເລັດ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສະຖານະ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ລາຍລະອຽດບິນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຈຳນວນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ນ້ຳໜັກ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ບໍລິມາດ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສ່ວນຫຼຸດ") }}</vs-th>
            <vs-th v-if="$route.query.tab === 'origin_freight_fees'">{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງຕົ້ນທາງ") }}</vs-th>
            <vs-th v-else-if="$route.query.tab === 'destination_freight_fees'">{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງປາຍທາງ") }}</vs-th>
            <vs-th v-else-if="$route.query.tab === 'origin_freight_fees_cod'">{{  $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງຕົ້ນທາງ") }}</vs-th>
            <vs-th v-else>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຂົນສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ປະກັນໄພ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ລວມຄ່າຂົນສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຕີກັບ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າຝາກສາງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າສິນຄ້າ") }}</vs-th>

            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ບໍລິການເສີມ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຄ່າບໍລິການເສີມ") }}</vs-th>

            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຊື່ຜູ້ສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຊື່ຜູ້ຮັບ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາຕົ້ນທາງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ສາຂາປາຍທາງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດການຊຳລະ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ປະເພດຂົນສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຜູ້ຮັບຜິດຊອບ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຜູ້ຈັດສົ່ງ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ຮູບລາຍເຊັນ") }}</vs-th>
            <vs-th>{{ $t("ລາຍການOrderພັດສະດຸ.ໝາຍເຫດ") }}</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listAllOrders"
              :data="item">
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="$router.push({name: 'branch.list.order.detail.inbound', params:{id: item.id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ item.shipment_number }} )</vs-td>
            <vs-td v-if="item.shipment_status_id === 1">{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td v-else>{{ FormatDate(item.start_date_actual) }}</vs-td>
            <vs-td v-if="item.shipment_status_id !== 1
            && item.shipment_status_id !== 2
            && item.shipment_status_id !== 3
            && item.shipment_status_id !== 11">{{ FormatDate(item.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="item.tracking_state_id === 5 || item.tracking_state_id === 6">
              {{ FormatDate(item.end_date_actual) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="item.shipment_status_id === 11">
              {{ FormatDate(item.end_date_actual) }}
            </vs-td>
            <vs-td v-else-if="item.shipment_status_id === 3">
              {{ FormatDate(item.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td
                v-if="item.tracking_state_id === 5 && (item.shipment_status_id === 2 || item.shipment_status_id === 6 || item.shipment_status_id === 8)">
              {{ $t("ລາຍການOrderພັດສະດຸ.ເຄື່ອງຮອດສາຂາປາຍທາງ") }}
            </vs-td>
            <vs-td v-else> {{ $t('common.' + nullsafe(item, (t) => t.shipment_status.name)) }} </vs-td>
            <vs-td v-if="item.tracking">{{ item.tracking }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ item.pieces || 0 }}</vs-td>
            <vs-td>{{
                nullsafe(item, (t) => t.parcels_summary.total_weight) || 0
              }} {{
                nullsafe(item, (t) => t.parcels_summary.weight_unit) || 'kg'
              }}</vs-td>
            <vs-td>{{
                nullsafe(item, (t) => t.parcels_summary.total_volume) || 0
              }} {{
                nullsafe(item, (t) => t.parcels_summary.volume_unit) || 'm^3'
              }}</vs-td>
            <vs-td>
              <span v-if="item.total_discount_amount !== 0">{{ item.total_discount_amount }}{{ $t("ລາຍການOrderພັດສະດຸ.ກີບ") }}</span>
              <span v-if="item.total_discount_percent !== 0">{{ item.total_discount_percent }}%</span>
              <span v-if="item.total_discount_amount === 0 && item.total_discount_percent === 0">........</span>
            </vs-td>
            <vs-td>( {{ FormatPrice(item.sub_total_freight) }} )</vs-td>
            <vs-td>( {{ FormatPrice(nullsafe(item, t => t.parcel.insurance_price) || 0) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.reject_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_rental_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_price) }} )</vs-td>
            <vs-td :style="{ color: 'crimson' }">{{ nullsafe(item, (t) => t.add_service.name) || '' }}</vs-td>
            <vs-td :style="{ color: 'crimson' }">( {{ FormatPrice(
                nullsafe(item, (t) => t.add_service.price) || 0
            ) }} )</vs-td>
            <vs-td>{{ nullsafe(item, t => t.sender_customer.name) }}</vs-td>
            <vs-td>{{ nullsafe(item, t => t.receiver_customer.name) }}</vs-td>
            <vs-td>{{nullsafe(item, t => t.start_branch.province.prefix)}}-{{ nullsafe(item, t => t.start_branch.name) }}</vs-td>
            <vs-td>{{nullsafe(item, t => t.end_branch.province.prefix)}}-{{ nullsafe(item, t => t.end_branch.name) }}</vs-td>
            <vs-td>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees'">{{
                  $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນຕົ້ນທາງ")
                }}</span>
              <span
                  v-if="item.shipment_pay_type === 'destination_freight_fees'"
              >{{ $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນປາຍທາງ") }}</span
              >
              <span
                  v-if="item.shipment_pay_type === 'origin_freight_fees_cod'"
              >{{ $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນຕົ້ນທາງ ( COD )") }}</span
              >
              <span
                  v-if="item.shipment_pay_type === 'destination_freight_fees_cod'"
              >{{
                  $t("ລາຍການOrderພັດສະດຸ.ເກັບເງິນປາຍທາງ ( COD + CDC )")
                }}</span
              >
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              ( {{ item.shipment_type }} )
            </vs-td>
            <vs-td>{{ nullsafe(item, t => t.owner.name) }}</vs-td>
            <vs-td v-if="item.shipment_status_id === 3">{{ nullsafe(item, t => t.sign_user.name) }}</vs-td>
            <vs-td v-else></vs-td>


            <vs-td>
              <div v-if="item.signature_file" class="img-size">
                <expandable-image
                    class="image"
                    :src=item.signature_file
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ item.remark }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

import Loading from '../../Loading';
import moment from 'moment';
import MixinBranchListOrders from '../../../Mixins/BranchOrders/ListOrders';
import DatePicker from "vue2-datepicker";
import _ from 'lodash';
import {ModelListSelect} from 'vue-search-select';
import pagination from '../../../Plugins/Paginations/CusorPagination';

export default {
  components: {
    Loading,
    DatePicker,
    ModelListSelect,
    pagination
  },

  data: () => ({
    scanType: 'inbound'
  }),

  props: ['onclickEvent'],

  mixins: [MixinBranchListOrders],

  methods: {
    FormatPrice(num) {
      const p = (num || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, num, i) {
        return num === "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + ' ' + this.$t('common.ກີບ');
    },
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    FormatCreatedDate(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    FilterUsers() {
      const userId = this.userCreateBy.map(item => item.id);
      const groupByUserId = _.uniq(userId);

      this.$http.get('shipment/user', {
        params: {
          user: groupByUserId
        }
      }).then((res) => {
        this.users = res.data;
        this.users.unshift({
          id: '',
          name: 'ທັງຫມົດ'
        });
      }).catch(() => {
      });
    },
    FilterSignUsers() {
      const userId = this.signUserCreateBy.map(item => item.id);
      const groupByUserId = _.uniq(userId);

      this.$http.get('shipment/user', {
        params: {
          user: groupByUserId
        }
      }).then((res) => {
        this.signUsers = res.data;
        this.signUsers.unshift({
          id: '',
          name: 'ທັງຫມົດ'
        });
      }).catch(() => {
      });
    }
  },
  created() {
    this.FetchListStartBranch();
    this.FetchShipmentStatus();
    this.FetchData();
  },

  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.ClearData();
        this.FetchData();
      }
    }
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}

.table-limit-height {
  .vs-table {
    width: 100%;
    font-size: 0.9rem;
    margin: 0;
    max-height: 500px;
  }
}
</style>
