<template>
  <div>
    <div class="field" :style="{marginTop: '40px'}">
      <label class="label">ເລືອກຫຼັກຖານໂອນເງິນ
      </label>
      <div class="box-upload-file" @click="triggerInputClick()" v-if="!images.base64">
        <div class="text-msg">
          <i class="fal fa-cloud-upload"></i>
          <p class="choose">
            ກະລຸນາເລືອກຫຼັກຖານໂອນເງິນ
          </p>
        </div>
      </div>
      <div class="has-profile" @click="triggerInputClick()" :style="{ backgroundImage: `url(${images.base64})`}"
           v-if="images.base64"></div>

      <div v-show="false" class="control">
        <input
            @change="onFileChange" ref="file"
            class="input input-ctn" type="file" name="profile_picture"
            v-validate="'mimes:image/*|size:5120'"
        >
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">ສະຖານະ</label>
        <div class="select">
          <select class="input" v-model="invoiceId.invoice_status">
            <option v-for="(item, index) in status" :key="index" :value="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <label class="label">ໝາຍເຫດ</label>
        <input
            class="input"
            name="remark"
            placeholder="ໝາຍເຫດ..."
            v-model="invoiceId.invoice_remark"
            v-validate="'max:191'"
        />
      </div>
    </div>
    <vs-button ref="button" flat
               @click="UpdateStatementOrigin()"
               class="form-btn-loading"
               :loading="loading"
    >
      ເເກ້ໄຂ ຫຼັກຖານໂອນເງີນ
    </vs-button>
  </div>
</template>

<script>

export default {
  props: [
    'invoiceId',
  ],


  data: () => ({
    payDate: new Date(),
    loading: false,

    status: [
      {value: 'pending', name: 'ກຳລັງດຳເນີນການ'},
      {value: 'success', name: 'ສຳເລັດ'},
    ],
    images: {
      fileName: '',
      base64: '',
      size: '',
      file: '',
      type: '',
    }

  }),
  created() {
    this.images.base64 = this.invoiceId.payment_img;
  },
  methods: {
    UpdateStatementOrigin() {
      this.loading = true;
      let formData = new FormData();
      formData.append('paid_type', this.invoiceId.paid_type);
      formData.append('invoiceId', this.invoiceId.id);
      formData.append('payment_img', this.images.file);
      formData.append('invoice_status', this.invoiceId.invoice_status);
      formData.append('invoice_remark', this.invoiceId.invoice_remark ?? '');
      formData.append('_method', 'put')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.$http.post('admin-update-invoice/cod', formData, config).then(() => {
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      }).catch(() => {
      });
    },

    triggerInputClick() {
      let selector = this.$refs.file;
      if (selector) {
        selector.click();
      }
    }, // Trigger-Click

    async onFileChange(e) {
      const file = e.target.files[0];
      const FileSize = (file.size / 1024 / 1024).toFixed(2);
      const extentsions = file.type.split("/");
      const validate_Ext = this.ValidateExtensions(extentsions[1]);

      if (validate_Ext == true && FileSize < 6) {
        this.images.file = file;
        this.images.type = extentsions[1];
        this.images.size = this.FormatByte(file.size);
        this.images.base64 = await this.ToBase64(file);
      }
    }, // OnFile-Change

    ToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }, // Convert-Base64

    FormatByte(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }, // FormatByte

    ValidateExtensions(ext) {
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'bmp') {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}

.has-profile {
  border: none;
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;

  &:hover {
    cursor: pointer;
  }
}

.box-upload-file {
  border: 1px dashed rgba(100, 98, 98, 0.867);
  height: 200px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px dashed $primary-color;

    .text-msg {
      i {
        color: $primary-color;
      }

      p {
        color: $primary-color;
      }
    }
  }

  .text-msg {
    i {
      font-size: 25px;
      color: rgba(100, 98, 98, 0.867);
    }

    .choose {
      margin-top: 10px;
      color: rgba(100, 98, 98, 0.867);
    }
  }
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.35rem;
}

.box-statement {
  border-radius: 5px;
  border: 1px solid $primary-color;
  width: 100%;
  height: 50vh;
  padding: 10px;

  .img {
    height: 48vh;
  }
}
</style>
