import {Validator} from 'vee-validate';

const validate_Coupon = {
    custom: {
        name: {
            required: '(ກະລຸນາປ້ອນຊື່ກ່ອນ...)',
        },
        code: {
            required: '(ກະລຸນາປ້ອນລະຫັດກ່ອນ...)',
        },
        discount: {
            required: '(ກະລຸນາປ້ອນສ່ວນຫຼຸດ...)',
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        discount_cash: {
            required: '(ກະລຸນາປ້ອນສ່ວນຫຼຸດ...)',
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        total_amount: {
            required: '(ກະລຸນາປ້ອນຈຳນວນກ່ອນ...)',
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        total_amount_per_day: {
            required: '(ກະລຸນາປ້ອນຈຳນວນກ່ອນ...)',
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        amount_per_user: {
            required: '(ກະລຸນາປ້ອນຈຳນວນກ່ອນ...)',
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        start_weight: {
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        end_weight: {
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        minimum_amount: {
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        maximum_discount: {
            numeric: 'ກະລຸນາປ້ອນຈໍານວນໃຫ້ຖືກຕ້ອງ',
        },
        valid_date: {
            required: 'ກະລຸນາປ້ອນວັນທີ່ໝົດອາຍຸກ່ອນ',
        }
    },
};

export const ValidateCoupons = (Vue) => {
    Validator.localize('en', validate_Coupon);
    Vue.$validator.localize('en', validate_Coupon);
}
