export default {

    data:()=>({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            name: '',
            tel: '',
            email: '',
            prefix: '',
            villageId: '',
        },

        parts: [],
        provinces: [],
        districts: [],
        villages: [],

        selectedPart: {},
        selectedProvince: {},
        selectedDistrict: {},

        filterProvinces: [],
        filterDistricts: [],
        filterVillages: [],

        checkVillage: true,

        translateInloas:{
            north: 'ພາກເໜືອ',
            center: 'ພາກກາງ',
            south: 'ພາກໃຕ້',
            northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
            southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
            southern: 'ພາກໃຕ້-1'
        },
        types:[
            {id: 'general', name: 'ທົ່ວໄປ'},
            {id: 'branch', name: 'ສາຂາ'},
        ],
        selectedType: {
            id: 'general', name: 'ທົ່ວໄປ'
        },
        listBranches: [],
        selectedBranch: {},
    }),

    methods:{

        ValidateForm(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        }, // Validate-Form

        EditItem(){
            this.btnLoading = true;
            this.ListDistributionCenter.villageId = this.selectedVillage.village_id;
            this.$axios.put('edit-distribution-centers', this.ListDistributionCenter).then(res=>{
                if(res.data.success == true){
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error=>{
                this.btnLoading = false;
                if(error.response.status == 422){
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }, // EditItems

        FetchData(){
            this.$axios.get('list-branch-address').then(res=>{
                const items = res.data.data;

                this.parts = items.parts;
                this.provinces = items.provinces;
                this.districts = items.districts;
                this.villages = items.villages;

                this.selectedPart = this.ListDistributionCenter;
                this.selectedType = this.ListDistributionCenter;

                this.filterProvinces = items.provinces;
                this.filterDistricts = items.districts;
                this.filterVillages = items.villages;

                this.FilterProvinces(this.selectedPart.partId);
                this.selectedProvince = this.ListDistributionCenter;
                this.FilterDistricts(this.selectedProvince.proId);
                this.selectedDistrict = this.ListDistributionCenter;
                this.FilterVillages(this.selectedDistrict.distId);
                this.selectedVillage = this.ListDistributionCenter;

                this.FetchBranch();
            }).catch(()=>{});
        }, // FetchData-To-Server


        FetchBranch(){
            this.$axios.get('list-filter-distribution-center-branches/' + this.ListDistributionCenter.id).then((res) => {
                this.listBranches = res.data.listBranches;
                this.selectedBranch = {...this.listBranches[0]};
            }).catch(()=>{});
        },

        FilterProvinces(partId){
            const result_checking = this.filterProvinces.filter(item=>{
                if(item.part_id == partId){
                    return item;
                }
            });
            this.provinces = result_checking;
            this.selectedProvince = {...result_checking[0]};

            this.FilterDistricts(this.selectedProvince.proId);
        }, // Filter-Provinces

        FilterDistricts(proId){
            const result_checking = this.filterDistricts.filter(item=>{
                if(item.province_id == proId){
                    return item;
                }
            });
            this.districts = result_checking;
            this.selectedDistrict = {...result_checking[0]};
            this.FilterVillages(this.selectedDistrict.distId);
        }, // Filter-Distircts

        FilterVillages(distId){
            const result_checking = this.filterVillages.filter(item=>{
                if(item.district_id == distId){
                    return item;
                }
            });
            this.villages = result_checking;
            this.selectedVillage = {...result_checking[0]};
        }, // Filter-Villages
    },
    watch: {
        'selectedVillage.village_id':function(){
            this.server_errors.villageId = '';
        },
        'ListDistributionCenter.prefix':function(){
            this.server_errors.prefix = '';
        },
        'ListDistributionCenter.name':function(){
            this.server_errors.name = '';
        },
        'ListDistributionCenter.tel':function(){
            this.server_errors.tel = '';
        },
        'ListDistributionCenter.email':function(){
            this.server_errors.email = '';
        }
    },
}
