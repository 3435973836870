export default {
    data: () => ({
        active: false,
        isLoading: false,
        btnLoading: false,
        server_errors: {
            bank_name:'',
            account_name: '',
            account_number: '',
        },
    }),

    methods: {
        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditItem();
                }
            });
        },
        EditItem() {
            this.btnLoading = true;
            this.$http.put('admin/edit-cod', this.listCustomer).then(res => {
                if (res.data.success === true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error => {
                this.btnLoading = false;
                if (error.response.status === 422) {
                    const obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
    },

    watch: {
        'listCustomer.bank_name': function () {
            this.server_errors.bank_name = '';
        },
        'listCustomer.account_name': function () {
            this.server_errors.account_name = '';
        },
        'listCustomer.account_number': function () {
            this.server_errors.account_number = '';
        },
    },
}
