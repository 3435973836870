<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ຜູ້ໃຊ້ລະບົບສາງພັດສະດຸ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
            <div class="columns">
                <div class="field" :style="{marginRight: '2px'}">
                 <vs-button circle icon flat
                            style="padding-right: 10px;"
                            @click="exportUsersToExcel"
                            :loading="isExporting">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="18px"
                         viewBox="0 0 384 512"><path fill="currentColor"
                                                     d="M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM208 216c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88.02h-52.66c-11 0-20.59 6.41-25 16.72-4.5 10.52-2.38 22.62 5.44 30.81l68.12 71.78c5.34 5.59 12.47 8.69 20.09 8.69s14.75-3.09 20.09-8.7l68.12-71.75c7.81-8.2 9.94-20.31 5.44-30.83-4.41-10.31-14-16.72-25-16.72H208V216zm42.84 120.02l-58.84 62-58.84-62h117.68z"></path></svg>
                   <b>ລາຍຊື່ພະນັກງານ</b>
                 </vs-button>
                </div>
                <div class="field" style="margin-right: 12px">
                   <vs-button circle icon flat @click="OpenModalAdd()">
                      <i class="fal fa-plus-circle"></i>
                   </vs-button>
                </div>
            </div>
          </span>
      </h4>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <div class="select">
              <select v-model="selectedType.id" name="branch">
                <option :value="type.id" v-for="(type, index) in types" :key="index">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <model-list-select
                  :list="branches"
                  v-model="selectedBranch"
                  placeholder="ເລືອກສາຂາກ່ອນ"
                  option-text="name"
                  option-value="id"
                  selectedItem="name">
              </model-list-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດຜູ້ໃຊ້</vs-th>
            <vs-th>ໂປຮໄຟລ</vs-th>
            <vs-th>ຊື່ຜູ້ໃຊ້ລະບົບ</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ບົດບາດ</vs-th>
            <vs-th>ໜ້າທີ່</vs-th>
            <vs-th>ສາຂາ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(branchUers, index) in listBranchUsers">
            <vs-td :style="{maxWidth: '10px',}">{{ index + 1 }}</vs-td>
            <vs-td :style="{color: 'crimson',  maxWidth: '90px',
              overflow: 'hidden', 'text-overflow': 'ellipsis'}">
              <vs-tooltip>
                <span v-if="branchUers.userCardid !== null">
                ( {{ branchUers.userCardid }} )
                </span>
                <span v-else>( ............. )</span>
                <template #tooltip>
                  {{ branchUers.userCardid }}
                </template>
              </vs-tooltip>
              <div style="font-size: 12px;"> ວັນທີ: {{ moment(branchUers.created_at).format('DD-MM-YYYY') }}</div>
            </vs-td>
            <vs-td>
              <img :src="branchUers.url_profile" class="profile">
            </vs-td>
            <vs-td style="max-width: 120px;overflow: hidden;text-overflow: ellipsis;">{{ branchUers.userName }}</vs-td>
            <vs-td style="max-width: 140px;overflow: hidden;text-overflow: ellipsis;">
              <vs-tooltip>
                {{ branchUers.userEmail }}
                <template #tooltip>
                  {{ branchUers.userEmail }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '90px',
              overflow: 'hidden', textAlign: 'center' }">{{ branchUers.userTel }}
            </vs-td>
            <vs-td v-if="branchUers.is_active === 1" :style="{color: '#008000', maxWidth: '90px',
              overflow: 'hidden', textAlign: 'center' }">active
            </vs-td>
            <vs-td v-else :style="{color: '#FF0000', maxWidth: '90px',
              overflow: 'hidden', textAlign: 'center' }">disable
            </vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }" v-if="branchUers.roleUser.length">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ JoinRoleUsers(branchUers.roleUser) }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else></vs-td>
            <vs-td :style="{color: '#195BFF', maxWidth: '50px',
              overflow: 'hidden', textAlign: 'center' }" v-if="branchUers.permissionRole.length">
              <vs-tooltip>
                <i class="fal fa-info-square"></i>
                <template #tooltip>
                  {{ JoinPermissionUsers(branchUers.permissionRole) }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{color: '#195BFF'}" v-else>
            </vs-td>
            <vs-td style="max-width: 150px;overflow: hidden;text-overflow: ellipsis;">
              {{ branchUers.branch_name }}
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button
                    circle icon flat
                    @click="OpenModalChangePassword(branchUers.userId)">
                  <i class="fal fa-lock-alt"></i>
                </vs-button>
                <vs-button
                    circle icon flat
                    @click="OpenModalEdit(branchUers.branchUserId)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button
                    circle danger icon flat
                    @click="OpenModalDelete(branchUers.branchUserId)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()"
          >
          </pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddBranchUsers @close="close" @success="FetchData()" :roleUsers="roleUsers" :listProvinces="listProvinces"/>
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditBranchUsers :listBranchUser="branchUser" :roleUsers="roleUsers" :listProvinces="listProvinces"
                         @close="close" @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteBranchUsers @close="close" :branchUserId="branchUserId" @success="FetchData()"/>
      </template>
    </ModalDelete>

    <ModalClose>
      <template v-slot="{ close }">
        <ChangePassword @close="close" :userId="userId" @success="FetchData()"/>
      </template>
    </ModalClose>

  </div>
</template>
<script>
import AddBranchUsers from './Dialog/Add';
import EditBranchUsers from './Dialog/Edit';
import DeleteBranchUsers from './Dialog/Delete';
import ChangePassword from './Dialog/ChangePassword';
import Loading from '../../../Loading';
import {ModelListSelect} from 'vue-search-select';
import * as helper from '../../../../Helpers/GetValue';
import pagination from '../../../../Plugins/Paginations/Pagination';


export default {
  components: {
    AddBranchUsers,
    EditBranchUsers,
    DeleteBranchUsers,
    Loading,
    ModelListSelect,
    pagination,
    ChangePassword
  },

  data: () => ({
    isLoading: true,

    roleUsers: [],
    listProvinces: [],
    listBranches: [],
    listBranchUsers: [],
    branchUser: {},
    branchUserId: '',
    userId: '',
    branches: [],
    selectedBranch: {},
    searchItem: '',
    oldVal: '',

    // pagination
    offset: 10,
    count: 0,
    pagination: {},
    per_page: 10,
    isExporting: false,
    types: [
      {id: '', name: 'ທັງໝົດ'},
      {id: 'branch', name: 'ສາຂາທົ່ວໄປ'},
      {id: 'franchise', name: 'ສາຂາເເຟນຊາຍ'},
      {id: 'delivery', name: 'ສາຂາດີລີເວີລີ່'},
      {id: 'local_branch', name: 'ສາຂາຈຸດແຈກຢາຍ'}
    ],
    selectedType: {id: '', name: 'ທັງໝົດ'},
  }),

  methods: {
    OpenModalChangePassword(userId) {
      this.userId = userId;
      this.$store.commit('modalClose_State', true);
    },
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(branchUserId) {
      this.branchUser = {...this.filterItem(branchUserId)};
      this.$store.commit('modalEdit_State', true);
    },
    filterItem(branchUserId) {
      return this.listBranchUsers.filter(item => {
        return item.branchUserId == branchUserId;
      })[0] || {};
    },
    OpenModalDelete(branchUserId) {
      this.branchUserId = branchUserId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-branch-users', {
        params: {
          branch_type: this.selectedType.id,
          branchId: this.selectedBranch.id,
          searchData: this.searchItem,
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.listBranchUsers = res.data.listBranchUsers.data;
          this.pagination = res.data.listBranchUsers;
          this.count = this.pagination.total;
        }, 100);
      }).catch(() => {
      });

      this.$axios.get('list-roles').then(res => {
        const roles = [
          "branchmanager",
          "branchuser",
          "deliverymanager",
          "deliveryuser",
          "drivermanager",
          "driveruser",
          "distributioncentermanager",
          "distributioncenteruser",
          "franchise-manager",
          "saveboxdeliver",
          "province-manager",
          "c-logistics-branch-accounting",
          "franchise-manager",
          "dispose-shipment"
        ];
        this.roleUsers = res.data.roles.filter(item => roles.includes(item.name)
            || item.name.indexOf('user-branch-') === 0);
      });

      this.$http.get('province').then(res => {
        this.listProvinces = res.data.data
      });
    },

    FetchListBranches() {
      this.$axios.get('list/all/branches').then(res => {
        setTimeout(() => {
          this.branches = res.data.listBranches;
          this.branches.unshift({
            id: "",
            name: 'ທັງໝົດ'
          });
          if (!this.$route.query.branch_id) {
            this.selectedBranch = {...this.branches[0]};
          }
        }, 100);
      }).catch(() => {
      });
    },

    JoinRoleUsers(roleUser) {
      return roleUser.map(item => item.roleName).join(', ');
    },
    JoinPermissionUsers(perRoles) {
      return perRoles.map(item => item.permissionName).join(', ');
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    },
    exportUsersToExcel() {
      this.isExporting = true;
      this.$store.dispatch('exportBranchUsersToExcel', this.$http)
          .catch(() => {
          }).finally(() => {
        this.isExporting = false;
      })
    },
  },
  watch: {
    'selectedType.id': function () {
      this.FetchData();
    },
    'selectedBranch': function () {
      this.FetchData();
    },
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
  },
  created() {
    this.FetchListBranches();
    /// select by query
    const {query} = this.$route;
    if (query.branch_id) {
      this.selectedBranch = {id: query.branch_id};
    }
    this.FetchData();
  },
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
</style>
