<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal">({{ count }})</span>
        <span v-if="$can(['distributioncentermanager','distributioncenteruser'])" class="btn-add">
                    <vs-button :style="{marginTop: '30px'}"
                               ref="button" flat
                               @click="removeBill($route.query)"
                               class="form-btn-loading"
                               :loading="loading"
                               :disabled="!isSelectingOrders"
                    >
                       ລຶບອອກຈາກກະເປົາ
                    </vs-button>
        </span>
        <span v-if="$can(['distributioncentermanager','distributioncenteruser'])" class="btn-add">
                         <div class="control">
              <label>ຄົ້ນຫາເລກບິນ
                  <input class="input" placeholder="ຄົ້ນຫາເລກທີບີນ..." v-model="shipment_number"
                         @keyup.enter="FilterListOrders()">
              </label>
            </div>
        </span>
      </h4>
    </div>

    <div class="center">
      <vs-table v-model="pageInvoices" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>
              <div class="center">
                <vs-checkbox danger
                             @click="onCheckAllCheckBoxChanged"
                             :value="isIndeterminate || isSelectAllOrders"
                             :loading="isSelecting"
                             :checked-force="isSelectAllOrders"
                             :indeterminate="isIndeterminate">
                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>
                </vs-checkbox>
              </div>
            </vs-th>
            <vs-th>ເລກບິນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ເບີໂທຜູ້ຮັບ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listShipmentBag"
              :is-selected="isRowSelected(item.id)"
              :data="item"
              @selected="onItemCheckBoxChanged(item)">
            <vs-td>
              <div class="center con-checkbox">
                <vs-checkbox danger
                             :checked-force="isRowSelected(item.id)"
                             :val="item" @click="onItemCheckBoxChanged(item)">
                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>
                </vs-checkbox>
              </div>
            </vs-td>
            <vs-td>
              {{ item.parcel_bill_number }}
            </vs-td>
            <vs-td>
              {{ item.weight }}
            </vs-td>
            <vs-td>
              {{ item.start_branch }}
            </vs-td>
            <vs-td>
              {{ item.end_branch }}
            </vs-td>
            <vs-td>
              {{ item.receiver_tel }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FilterListOrders()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import mixinListShipmentBagDetail from '../../../Mixins/Distribution-Center/Shipment-Orders/List-Shipment-Bag-Detail';
import pagination from '../../../Plugins/Paginations/Pagination';
import Loading from '../../Loading';

export default {
  components: {
    pagination,
    Loading
  },

  mixins: [mixinListShipmentBagDetail],

  created() {
    this.FilterListOrders();
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}
</style>
