<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t('shipment.order_info') }}
      </h4>
      <span class="text-danger">
                    {{ server_errors.error }}
                  </span>
    </template>

    <div class="columns" :style="{ marginTop: '10px' }">
      <div class="field column">
        <div class="control">
          <label class="label">
            {{  $t('shipment.ເບີໂທຜູ້ຝາກໃໝ່') }}
            <span class="text-danger">* {{ errors.first("tel") }}</span>
            <span class="text-danger">
              {{ server_errors.tel }}
            </span>
          </label>
          <input
              class="input"
              type="text"
              name="tel"
              v-model="tel"
              v-validate="'required|numeric'"
          />
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >{{  $t('shipment.ປ່ຽນເບີໂທຜູ້ຝາກ') }}
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";

export default {
  props: ["shipment_number"],
  data() {
    return {
      tel: '',
      active: false,
      loading: false,
      shipment_detail: {},
      laError: {
        'ERROR ALL PARCEL NOT SAME STATE': 'ສະຖານະກ່ອງບໍ່ຢູ່ບ່ອນດຽວກັນ',
        'ERROR INVALID BILL NUMBER': 'ເລກບິນບໍ່ຖືກຕ້ອງ',
        'ERROR UN PROCESSABLE BILL NUMBER': 'ບິນບໍ່ຢູ່ໃນສະຖານະກຳລັງດຳເນີນການ',
        'ERROR NOT REGISTER COD': 'ລູກຄ້າບໍ່ໄດ້ລົງທະບຽນ COD'
      },
      server_errors: {
        tel: "",
        error: "",
      },
    };
  },
  watch: {
    "tel": function () {
      this.server_errors.tel = "";
    },
    "error": function () {
      this.server_errors.error = "";
    },
  },
  created() {
    const dict = {
      custom: {
        tel: {
          required: `(${this.$t('validator.required')}...)`,
          numeric: `(${this.$t('validator.numeric')})`,
        },
      },
    };
    this.laError = {
      'ERROR ALL PARCEL NOT SAME STATE': this.$t('shipment.ສະຖານະກ່ອງບໍ່ຢູ່ບ່ອນດຽວກັນ'),
      'ERROR INVALID BILL NUMBER': this.$t('shipment.ເລກບິນບໍ່ຖືກຕ້ອງ'),
      'ERROR UN PROCESSABLE BILL NUMBER': this.$t('shipment.ບິນບໍ່ຢູ່ໃນສະຖານະກຳລັງດຳເນີນການ'),
      'ERROR NOT REGISTER COD': this.$t('shipment.ລູກຄ້າບໍ່ໄດ້ລົງທະບຽນ COD')
    };
    Validator.localize("en", dict);
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditData();
        }
      });
    },

    EditData() {
      let data = {
        shipment_number: this.shipment_number,
        tel: this.tel
      }
      this.loading = true;
      this.$http.post("admin/account/change/sender", data)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_EditItem_OnSuccess(
                  "top-right",
                  "dark",
                  3000
              );
            }, 300);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 422) {
              const obj = err.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            } else {
              this.server_errors.error = err.response.data.message ?? null;
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
};
</script>
