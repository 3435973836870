<template>
  <div>
    <div class="field" :style="{marginTop: '40px'}">
      <div class="control">
        <label class="label">ວັນທີຊຳລະເງິນ</label>
        <DatePicker
            v-model="payDate"
            input-class="input"
            format="d/M/yyyy"
            name="valid_date"
            v-validate="'required'">
        </DatePicker>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">ເງິນໂອນເກີນຄັ້ງກ່ອນ</label>
        <input
            class="input"
            v-model="debt_last_time"
            placeholder="ຈຳນວນເງິນໂອນເກີນຄັ້ງກ່ອນ...">
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">ຄ່າທຳນຽມໂອນ</label>
        <input
            class="input"
            v-model="transfer_fee"
            placeholder="ຄ່າທຳນຽມໂອນ...">
      </div>
    </div>
    <vs-button
        ref="button"
        flat @click="exportsExcelByTotal()"
        class="form-btn-loading"
        :loading="loading">
      Export Excel
    </vs-button>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import {FormatDateTimeBeforeSendToServer, FormatDate} from '@/Helpers/Format';

export default {
  props: [
    'invoiceIds', 'type'
  ],
  components: {
    DatePicker
  },

  data: () => ({
    payDate: new Date(),
    loading: false,
    debt_last_time: 0,
    transfer_fee: 1000,
  }),
  methods: {
    exportsExcelByTotal() {
      const invoiceIds = this.invoiceIds.map(item => item.id);
      this.loading = true;
      this.$http.post('admin/export-cod-balance-details', {
            invoices: invoiceIds,
            paid_at: FormatDateTimeBeforeSendToServer(this.payDate),
            past_over_transfer: this.debt_last_time,
            transfer_fee: this.transfer_fee,
          },
          {responseType: 'blob'},
      ).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.type === 'before') {
          fileLink.setAttribute('download', 'Invoice COD ເຊົ້າ ແບບລວມ ວັນທີ ' + FormatDate(this.payDate) + '.xlsx');
        } else if (this.type === 'after') {
          fileLink.setAttribute('download', 'Invoice COD ແລງ ແບບລວມ ວັນທີ ' + FormatDate(this.payDate) + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'Invoice COD ແບບລວມ ວັນທີ ' + FormatDate(this.payDate) + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        this.$emit('close');
        this.$emit('success');
      }).catch(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:hover {
    color: $white !important;
  }
}
</style>
