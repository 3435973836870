<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ Call-Center
        <span class="countTotal">({{ ListCallCenters.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <!-- <vs-th>ລະຫັດ</vs-th>-->
            <vs-th>Call-Center</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ສະຖານທີ່</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index" :data="callcenter"
              v-for="(callcenter, index) in $vs.getPage(ListCallCenters, page, max)"
          >
            <vs-td :style="{textAlign: 'center'}">{{ index + 1 }}</vs-td>
            <!-- <vs-td :style="{width: '15%', color: 'crimson'}">
                ( {{ListCallCenters.prefix}} )
            </vs-td> -->
            <vs-td :style="{width: '50%'}">{{ callcenter.name }}</vs-td>
            <vs-td :style="{width: '50%'}">{{ callcenter.tel }}</vs-td>
            <vs-td>{{ callcenter.email }}</vs-td>
            <vs-td style="min-width: 400px; color: #195BFF; font-size: 16px">
              ( {{ translateInloas[callcenter.partName] }},
              {{ callcenter.proName }},
              {{ callcenter.distName }},
              {{ callcenter.villageName }} )
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat @click="AddCallCenterUsers()">
                  <i class="fal fa-plus-circle"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(callcenter.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button circle danger icon flat @click="OpenModalDelete(callcenter.id)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(ListCallCenters, max)"/>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddCallCenters
            @close="close"
            @success="FetchData()"
        />
      </template>
    </ModalAdd>

    <ModalEdit>
      <template v-slot="{ close }">
        <EditCallCenters :listCallCenter="filterCallCenter" @close="close" @success="FetchData()"/>
      </template>
    </ModalEdit>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteCallCenters @close="close" :callCenterId="callCenterId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import Loading from '../../Loading';
import AddCallCenters from './Dialog/Add';
import EditCallCenters from './Dialog/Edit';
import DeleteCallCenters from './Dialog/Delete';

export default {
  components: {
    Loading,
    AddCallCenters,
    EditCallCenters,
    DeleteCallCenters
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    ListCallCenters: [],
    filterCallCenter: {},
    callCenterId: '',
    translateInloas: {
      center: 'ພາກກາງ',
      south: 'ພາກໃຕ້',
      north: 'ພາກເໜືອ',
      northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
      southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
      southern: 'ພາກໃຕ້-1'
    },
  }),
  methods: {
    OpenModalAdd() {
      this.$store.commit('modalAdd_State', true);
    },
    OpenModalEdit(callCenterId) {
      this.filterCallCenter = {...this.FilterItem(callCenterId)};
      this.$store.commit('modalEdit_State', true);
    },
    FilterItem(callCenterId) {
      return this.ListCallCenters.filter(item => {
        return item.id == callCenterId;
      })[0] || {};
    },
    OpenModalDelete(callCenterId) {
      this.callCenterId = callCenterId;
      this.$store.commit('modalDelete_State', true);
    },
    AddCallCenterUsers() {
      this.$router.push({name: 'list.call.center.users'});
    },

    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-call-centers').then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.ListCallCenters = res.data.ListCallCenters;
        }, 100);
      }).catch(() => {
      });
    },
  },
  created() {
    this.FetchData();
  }
}
</script>

<style>

</style>
