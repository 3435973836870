// import store from '../store';
// import router from '../router/index';

export default function auth({next, router}){
    if(!localStorage.getItem('access_token')){
        return router.push({name: 'sign-in'});
    }

    // const item = window.localStorage.getItem('roleUser');
    // const roleUsers = JSON.parse(item);

    // console.log(roleUsers);
    // if(roleUsers.includes('accountingmanager')){
    //     // return router.push({name: 'list.accounting.user'});
    //     return next();
    // }
    return next();
}
