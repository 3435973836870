<template>
  <div class="container">
    <div class="box">
      <div class="field">
        <input class="input" placeholder="ຄົ້ນຫາເລກທີບີນ..." v-model="shipment_number"
               @keyup.enter="FilterShipmentNumber()">
      </div>
      <div class="center">
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>ລວມເປັນເງິນທັງໝົດ</vs-th>
              <vs-th>ລວມເງິນລູກຄ້າລາຍເດືອນ</vs-th>
              <vs-th>ລວມລາຍໄດ້ເຂົ້າສາຂາ</vs-th>
              <vs-th>ລວມຈຳນວນບິນ</vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :is-selected="true">
              <vs-td>{{ FormatPrice(summary.total_price) }}</vs-td>
              <vs-td>
                <span v-if="summary.monthly_company != 0">{{ FormatPrice(summary.monthly_company) }}</span>
                <span v-else>0 {{$t('common.ກີບ')}}</span>
              </vs-td>
              <vs-td>{{ FormatPrice(summary.branch) }}</vs-td>
              <vs-td>{{ count }} ບິນ</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ວັນທີສ້າງອິນວອຍ</vs-th>
            <vs-th>ຜູ້ສ້າງບິນ</vs-th>
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <!-- <vs-th>ຊື່ບັນຊີ (ທນຄ)</vs-th> -->
            <vs-th>ຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ປະກັນໄພ</vs-th>
            <vs-th>ລວມເງິນລູກຄ້າລາຍເດືອນ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <!-- <vs-th></vs-th> -->
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              v-for="(item, index) in invoiceDetails" :key="index"
          >
            <vs-td>{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td>{{ item.created_by }}</vs-td>
            <vs-td>{{ item.shipment_number }}</vs-td>
            <vs-td>{{ item.qty || 0 }}</vs-td>
            <vs-td>{{ item.weight || 0 }}</vs-td>
            <vs-td>{{ item.start_branch }}</vs-td>
            <vs-td>{{ item.end_branch }}</vs-td>
            <!-- <vs-td>{{item.account_name}}</vs-td> -->
            <vs-td>{{ FormatPrice(item.paid) }}</vs-td>
            <vs-td v-if="item.insurance_price">{{ FormatPrice(item.insurance_price) }}</vs-td>
            <vs-td v-else>0 ກີບ</vs-td>
            <vs-td :style="{textAlign: 'center'}">
              <span v-if="item.monthly_company == 0">0 ກີບ</span>
              <span>{{ FormatPrice(item.monthly_company) }}</span>
            </vs-td>
            <vs-td>{{ la_status[item.invoice_detail_status] }}</vs-td>

            <!-- <vs-td>
                <div class="group-icon">
                    <vs-button circle icon flat
                        @click="$router.push({name: 'accounting.invoice.cod.detail', params: {id: item.id}})"
                    >
                        <i class="fad fa-eye"></i>
                    </vs-button>
                </div>
            </vs-td> -->
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

  </div>
</template>

<script>
import mixinInvoiceCODDetail from '../../../Mixins/Accountings/Invoices/Details/COD-Detail';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
  components: {
    pagination
    // mixinInvoiceCODDetail
  },

  mixins: [mixinInvoiceCODDetail],

  created() {
    this.FetchData();
  }
}
</script>

<style lang="scss" scoped>
.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.box-summary {
  .box-total-price {
    border: 1px solid $crimson;
    // background: $crimson;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
  }

  .box-total-amount {
    border: 1px solid $primary-color;
    // background: $primary-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .box-total-shipment-bill {
    // background: $sub-color;
    border: 1px solid $sub-color;
    // color: $white;
    padding: 8px;
    border-radius: 5px;
    margin-left: 20px;
  }
}
</style>
