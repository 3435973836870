<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການນໍາໃຊ້ທັງໝົດ
        <span class="countTotal">({{ count }})</span>
      </h4>
    </div>

    <div class="center">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລໍາດັບ</vs-th>
            <vs-th>ປະເພດປາຍທາງ</vs-th>
            <vs-th>ປາຍທາງ</vs-th>
            <vs-th>ວັນທີ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in listShipmentBag"
              :data="item">
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>
              {{ item.target_received_type === 'branch' ? 'ສາຂາ' : 'ສູນຄັດແຍກ' }}
            </vs-td>
            <vs-td v-if="item.target_received_type == 'branch'">
              {{ item.branch_name }}
            </vs-td>
            <vs-td v-else>
              {{ item.distribution_center_name }}
            </vs-td>
            <vs-td>{{ moment(item.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
            <vs-td style="text-align: right; width: 50px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({ name: 'distribution.center.shipment.bag.reusable.recycling.detail',
                            params: { id: item.id } })">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FilterListOrders()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import mixinListShipmentBagDetail from '@/Mixins/Distribution-Center/Shipment-Orders/Resuable/List-Shipment-Bag-Detail';
import pagination from '@/Plugins/Paginations/Pagination';
import Loading from '@/views/Loading';

export default {
  components: {
    pagination,
    Loading
  },

  mixins: [mixinListShipmentBagDetail],

  created() {
    this.FilterListOrders();
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}
</style>
