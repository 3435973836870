import {Validator} from 'vee-validate';

const validate = {
    custom: {
        name: {
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        bag_bill_number: {
            required: '( ກະລຸນາປ້ອນກ່ອນເລກກະເປົ໋າ... )',
        },
    },
};

export const Validations = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}
