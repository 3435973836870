<template>
    <div>
        <template>
          <h4 class="not-margin" :style="{marginTop: '10px'}">
              <img src="../../../assets/delete-trash.png" class="img-delete">
          </h4>
        </template>

        <div class="con-content" :style="{ marginTop: '5px' }">
            <p :style="{fontSize: '18px', color: '#195BFF'}">
                ກະລຸນາກົດຢືນຢັນ ເພື່ອຢືນຢັນການອັບເດດ ສຳເລັດເເລ້ວ
            </p>
        </div>

        <template>
          <div class="con-footer" :style="{ marginTop: '25px' }">
            <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="DeleteItem(orderRequestId)">
              ຢືນຢັນ
            </vs-button>
            <vs-button  @click="$emit('close')" dark transparent>
              ຍົກເລີກ
            </vs-button>
          </div>
        </template>
    </div>
</template>
<script>
export default {
    props:['orderRequestId'],
    
    data:()=>({
     loading: false
    }),
     methods:{
        DeleteItem(orderRequestId){
          this.loading = true;
          this.$axiosDelivery.get('update-success-order-requests/' + orderRequestId).then(res=>{
              if(res.data.success == true){
                  setTimeout(() => {
                    this.loading = false;
                    this.$emit('close');
                    this.$emit('success');
                    this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                  }, 300);
              }
          }).catch(()=>{
            this.loading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_Error_Server('top-right', 'danger', 3000);
          });
        }
    }
}
</script>
<style lang="scss">

</style>