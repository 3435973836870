<template>
  <div>
    <template>
      <h4
          class="not-margin"
          :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
            class="fal fa-info-circle"
            :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t('shipment.order_info') }}
      </h4>
    </template>

    <div class="columns" :style="{ marginTop: '10px' }">
      <div class="field column">
        <div class="control">
          <div v-if="tab_query==='destination_freight_fees' || tab_query==='origin_freight_fees'" class="control">
            <label class="label">
              {{ $t('ຂໍ້ມູນOrderDetail.ຄ່າຂົນສົ່ງ') }}
              <span class="text-danger">* {{ errors.first("freight") }}</span>
              <span class="text-danger">
              {{ server_errors.freight }}
            </span>
            </label>
            <input
                class="input"
                type="text"
                name="freight"
                v-model="freight"
                v-validate="'max:191|required|numeric'"
            />
          </div>
          <div v-if="tab_query==='origin_freight_fees_cod'" class="control">
            <label class="label">{{ $t('ຂໍ້ມູນOrderDetail.ຄ່າສິນຄ້າ') }}
              <span class="text-danger">* {{ errors.first("price") }}</span>
              <span class="text-danger">
              {{ server_errors.price }}
            </span>
            </label>
            <input
                class="input"
                type="text"
                name="price"
                v-model="price"
                v-validate="'max:191|required|numeric'"
            />
          </div>
          <div class="control">
            <label class="label">{{ $t('ຂໍ້ມູນOrderDetail.ໝາຍເຫດ') }}
              <span class="text-danger">* {{ errors.first("remark") }}</span>
              <span class="text-danger">
              {{ server_errors.remark }}
            </span>
            </label>
            <input
                class="input"
                type="text"
                name="remark"
                v-model="remark"
                v-validate="'max:191|required'"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
            ref="button"
            flat
            @click="ValidateForm()"
            class="form-btn-loading"
            :loading="loading"
        >{{ $t('ຂໍ້ມູນOrderDetail.ເເກ້ໄຂລາຄາ') }}
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import {Validator} from "vee-validate";


export default {
  props: ["shipment_number", "tab_query"],
  data() {
    return {
      active: false,
      loading: false,
      freight: 0,
      price: 0,
      remark: '',
      shipment_detail: {},
      server_errors: {
        price: "",
        freight: "",
        insurance_price: "",
        remark: ""
      },
    };
  },
  watch: {
    "shipment_detail": function () {
      this.freight = this.shipment_detail.parcels[0].freight;
      this.price = this.shipment_detail.parcels[0].price;
      this.insurance_price = this.shipment_detail.parcels[0].insurance_price;
      this.branch = this.shipment_detail.parcels[0].receive_branch_id;
      this.remark = null;
      this.$validator.reset();
    },

    "freight": function () {
      this.server_errors.freight = "";
    },
    "price": function () {
      this.server_errors.price = "";
    },
    "remark": function () {
      this.server_errors.remark = "";
    },
  },
  created() {
    const dict = {
      custom: {
        freight: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          numeric: `(${this.$t('validator.numeric')})`
        },
        price: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
          numeric: `(${this.$t('validator.numeric')})`
        },
        remark: {
          required: `(${this.$t('validator.required')}...)`,
          max: `(${this.$t('validator.max_character', {max: 191})}...)`,
        },
      },
    };
    Validator.localize("en", dict);

    this.getDetail();
  },

  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditData();
        }
      });
    },

    getDetail() {
      this.$http.get('v1/auth/branches/shipments/orders/' + this.shipment_number + '/details')
          .then((res) => {
            this.shipment_detail = {
              id: res.data.id,
              receive_branch_id: res.data.end_branch_id,
              receiver: {
                full_name: !res.data.receiver_customer.surname ? res.data.receiver_customer.name : res.data.receiver_customer.name + " " + res.data.receiver_customer.surname,
                phone_number: res.data.receiver_customer.tel,
                location: res.data.optional_metas.shipment_address.location
              },
              village_name: res.data.optional_metas.shipment_address.village_name,
              remark: res.data.remark,
              parcels: res.data.parcel_in_shipments.map((item) => {
                const parcelMeta = item.parcel;
                return {
                  id: parcelMeta.id,
                  name: parcelMeta.name,
                  width: parcelMeta.width,
                  height: parcelMeta.height,
                  length: parcelMeta.length,
                  weight: parcelMeta.weight,
                  volume: parcelMeta.volume,
                  weight_unit: parcelMeta.weight_unit,
                  volume_unit: parcelMeta.volume_unit,
                  dimension_length: parcelMeta.dimension_length,
                  size: parcelMeta.size ?? null,
                  size_meta: parcelMeta.size_meta ?? null,
                  price: parcelMeta.price,
                  insurance_price: parcelMeta.insurance_price,
                  freight: parcelMeta.freight,
                  detail: parcelMeta.detail,
                  parcel_type: parcelMeta.parcel_type,
                  parcel_category_id: parcelMeta.parcel_category_id
                }
              })
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },

    EditData() {
      const {parcels} = this.shipment_detail;
      this.shipment_detail.remark = this.remark;
      this.shipment_detail.parcels = parcels.map((item) => {
        return {
          ...item,
          price: this.price,
          insurance_price: null,
          freight: this.freight
        }
      });
      this.loading = true;
      this.$http.post("v1/auth/branches/shipments/orders/update", this.shipment_detail)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_EditItem_OnSuccess(
                  "top-right",
                  "dark",
                  3000
              );
            }, 300);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
          });
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.button,
        scale: "0.6",
        background: "primary",
        opacity: 1,
        color: "#fff",
      });
    },
  },
};
</script>
