<template>
    <div class="container" :style="{marginTop: '20px'}">
        <div class="box-title">
            <h4>
                <i class="fal fa-gifts"></i>
                ລາຍການທັງໝົດ
                <span class="countTotal">({{totalOrder}})</span>
            </h4>
        </div>

        <div class="box">
            <div class="columns">
                <div class="column is-4">
                    <div class="control">
                        <div class="filed">
                            <label class="label">ຄົ້ນຫາ</label>
                            <div class="control">
                                <input class="input" placeholder="ຄົ້ນຫາ ລະຫັດລາຍການ..." v-model="searchOrderId">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="control">
                        <label class="label">ເເຕ່ວັນທີ</label>
                        <vuejs-datepicker 
                            v-model="startDate" 
                            input-class="input"
                            format="d-M-yyyy"
                            name="valid_date"
                            v-validate="'required'"
                        >
                        </vuejs-datepicker>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="control">
                        <label class="label">ຫາວັນທີ</label>
                        <vuejs-datepicker 
                            v-model="endDate" 
                            input-class="input"
                            format="d-M-yyyy"
                            name="valid_date"
                            v-validate="'required'"
                        >
                        </vuejs-datepicker>
                    </div>
                </div>
            </div>
        </div>

        <div class="center">
            <vs-table>
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ລະຫັດ ລາຍການ</vs-th>
                         <vs-th>ປະເພດພາຫະນະ</vs-th>
                        <vs-th>ຊື່ລູກຄ້າ</vs-th>
                        <vs-th>ປະເພດພັດສະດຸ</vs-th>
                        <vs-th>ຈຳນວນ</vs-th>
                        <vs-th>ນ້ຳໜັກ</vs-th>
                        <vs-th>ວັນທີ່ສ້າງລາຍການ</vs-th>
                        <vs-th>ເວລານັດຮັບເຄື່ອງ</vs-th>
                        <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th>
                        <vs-th>ສະຖານະ</vs-th>
                        <vs-th></vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                        :key="index" v-for="(orderSuccess, index) in FilterOrderRequestSuccess"
                    >
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '15%', color: 'crimson'}">
                            ( {{orderSuccess.order_id}} )
                        </vs-td>
                        <vs-td>
                            <span v-if="orderSuccess.vehicle_type == 'motorcycle'">ລົດຈັກ</span>
                            <span v-if="orderSuccess.vehicle_type == 'car'">ລົດໃຫຍ່</span>
                        </vs-td>
                        <vs-td>{{orderSuccess.customer_name}}</vs-td>
                        <vs-td>{{orderSuccess.categ_name}}</vs-td>
                        <vs-td>{{orderSuccess.amount}}</vs-td>
                        <vs-td>{{orderSuccess.weight}} kg</vs-td>
                        <vs-td>{{FormatDate(orderSuccess.created_at)}}</vs-td>
                        <vs-td>
                            {{FormatDateSchedule(orderSuccess.schedule)}}
                            <span>
                                - {{orderSuccess.schedule_time !== null ? orderSuccess.schedule_time : '..............'}}
                            </span>
                        </vs-td>
                        <vs-td>
                            <span :style="{color: '#195BFF'}">
                                ( {{orderSuccess.rider_name !== null ? orderSuccess.rider_name : '.................'}} )
                            </span>
                        </vs-td>
                        <vs-td>
                            <span v-if="orderSuccess.status == 'success'" :style="{color: '#195BFF'}">ສຳເລັດເເລ້ວ</span>
                        </vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalViewOrderRequestSuccess(orderSuccess.id)">
                                    <i class="fad fa-eye"></i>
                                </vs-button>
                            </div>
                        </vs-td>  
                    </vs-tr>
                </template>
                <template #footer>
                    <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset" @paginate="FetchSuccesstOrderRequest()"></pagination>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalPreviewFile>
            <template v-slot="{ close }">
                <PreviewOrderRequest @close="close" :OrderRequest="filterOrderRequest" @success="FetchOrderRequests()"/>
            </template>
        </ModalPreviewFile>

    </div>
</template>

<script>
import mixinsSuccessOrderRequests from '@coms/../../src/Mixins/Order-Requests/SuccessOrder';
import Loading from '../Loading';
import PreviewOrderRequest from './Dialog/View-Order';
import moment from 'moment';
import vuejsDatepicker from 'vuejs-datepicker';
import pagination from '../../Plugins/Paginations/Pagination';

export default {
    components:{
        Loading,
        PreviewOrderRequest,
        vuejsDatepicker,
        pagination
    },
    methods:{
        FormatDate(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY - HH:mm:ss')
            }
        },
        FormatDateSchedule(date){
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY')
            }
        },
        FormatStartDateAndEndDate(date){
            if (date) {
                return moment(String(date)).format('YYYY-MM-DD')
            }
        }
    },
    mixins:[mixinsSuccessOrderRequests],

    created(){
        this.FetchSuccesstOrderRequest();
    }
}
</script>

<style>

</style>