<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{ fontWeight: 'bold', color: '#195BFF', fontSize: '18px', textAlign: 'left' }">
        <i class="fal fa-users" :style="{ width: '25px', fontSize: '18px' }"></i>
        ເພີ່ມເລກກະເປົ໋າ
      </h4>
    </template>
    <div class="field" :style="{marginTop: '15px'}">
      <div class="control">
        <label class="label">
          ລະຫັດເລກກະເປົ໋າ
          <span class="text-danger">* {{ errors.first('bag_bill_number') }}</span>
          <span class="text-danger">
                    {{ server_errors.bag_bill_number }}
                </span>
        </label>
        <input class="input"
               name="bag_bill_number" type="text"
               placeholder="ກະລຸນາປ້ອນລະຫັດເລກກະເປົ໋າ..."
               v-model="parcelBag.bag_bill_number"
               v-validate="'required|max:225'">
      </div>
    </div>
    <div ref="target" id="target" class="center" :style="{marginTop: '30px'}">
      <div class="con-btns">
        <vs-button ref="button" flat @click="ValidateForm()" class="form-btn-loading" :loading="btnLoading">
          ເພີ່ມເລກກະເປົ໋າ
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import MixinAddParcelBag from '@/Mixins/Distribution-Center/Shipment-Orders/Resuable/Add';
import {Validations} from '@/Plugins/Validation/Validation';

export default {

  mixins: [MixinAddParcelBag],
  created() {
    Validations(this);
  }
}
</script>
<style lang="scss" scoped>
.label-title {
  text-align: left;
  margin-top: -15px;
}
</style>
