<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-30px', marginLeft: '-30px'}">
      <ul>
        <li :class="{'is-active': isActive == 'pending'}" @click="Tabs('pending')">
          <a>ລູກຄ້າ VIP ລໍຖ້າການອະນຸມັດ</a>
        </li>
        <li v-if="$can(['SuperAdmin','accountingcod','manager'])" @click="Tabs('approved')" :class="{'is-active': isActive == 'approved'}">
          <a>ລູກຄ້າ VIP ອະນຸມັດແລ້ວ</a>
        </li>
      </ul>
    </div>

    <PendingVIP v-if="isActive == 'pending'"/>
    <ApprovedVIP v-else/>
  </div>
</template>

<script>
import PendingVIP from './vip-customer-pending';
import ApprovedVIP from './vip-customer-approved';

export default {
  components:{
    PendingVIP,
    ApprovedVIP
  },

  data:()=>({
    isActive: false,
  }),

  methods:{
    Tabs(value){
      this.isActive = value;
      this.$router.push({name: 'tab.vip.customer', query:{tab: value}});
    }
  },
  created(){
    if(this.$route.query.tab == 'pending'){
      this.isActive = 'pending';
    }else if(this.$route.query.tab == 'approved'){
      this.isActive = 'approved';
    }else{
      this.isActive = 'pending';
    }
  }
}
</script>

<style>

</style>
