import { render, staticRenderFns } from "./Tab-Invoice-local.vue?vue&type=template&id=f784670e"
import script from "./Tab-Invoice-local.vue?vue&type=script&lang=js"
export * from "./Tab-Invoice-local.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports