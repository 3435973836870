<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-house"></i>
          ຄິດໄລ່ຍອດສາຂາ
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດແຂວງ:</label>
            <div class="select">
              <select class="input" v-model="branch_type">
                <option value="capital">ນະຄອນຫຼວງ</option>
                <option value="province">ຕ່າງແຂວງ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຍອດ:</label>
            <div class="select">
              <select class="input" v-model="shipment_type">
                <option value="branch">ຍອດສາຂາ</option>
                <option value="userBranch">ຍອດສະເພາະບຸກຄົນ</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="shipment_type === 'userBranch'" class="column is-2">
          <div class="control">
            <label class="label">ປະເພດບິນ:</label>
            <div class="select">
              <select class="input" v-model="shipment_pay_type">
                <option value="receive_origin">ຍອດຮັບຕົ້ນທາງ</option>
                <option value="receive_cdc">ຍອດຮັບປາຍທາງ</option>
                <option value="success_origin">ຍອດຈັດສົ່ງຕົ້ນທາງ</option>
                <option value="success_cdc">ຍອດຈັດສົ່ງປາຍທາງ</option>
                <option value="all">ທັງໝົດ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດລາຍງານ:</label>
            <div class="select">
              <select class="input" v-model="report_type">
                <option value="">ທັງໝົດ</option>
                <option value="delivery">ເດລີເວີລີ</option>
                <option value="branch">ສາຂາ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ພະນັກງານ:</label>
            <model-list-select
                :list="allUserBranches"
                v-model="selectedUserBranch"
                placeholder="ເລືອກພະນັກງານ"
                option-text="user_name"
                option-value="user_id"
                selectedItem="user_name"
            >
            </model-list-select>
          </div>
        </div>
        <div v-if="shipment_type === 'userBranch'" class="column is-2">
          <div class="control">
            <label class="label">ປະເພດສາຂາ:</label>
            <div class="select">
              <select class="input" v-model="branch_category">
                <option value="branch">ສາຂາ</option>
                <option value="franchise">ແຟນຊາຍ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="printSummaryBranch()"
              class="form-btn-loading"
              :loading="loading">
            export
          </vs-button>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ວັນທີສ້າງ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="branch"
              v-for="(branch, index) in $vs.getPage(listPrint, page, max)">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ moment(branch.created_at).format('DD-MM-YYYY HH:mm') }}</vs-td>
            <vs-td>{{ branch.name }}</vs-td>
            <vs-td>{{ laStatus[branch.status] }}</vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <div v-if="branch.status === 1">
                  <vs-button circle icon flat @click="download(branch.id, branch.name)">
                    <i class="fal fa-download"></i>
                  </vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect,
  },

  data: () => ({
    laStatus: {
      0: 'ກຳລັງດຳເນີນການ',
      1: 'ສຳເລັດ'
    },
    page: 1,
    max: 10,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    isLoading: false,
    branch: '',
    branches: [],
    searchItem: "",
    oldVal: "",
    totalPoint: 0,
    totalSpend: 0,
    transaction_type: '',
    transaction_status: '',
    branch_type: 'capital',
    shipment_type: 'branch',
    shipment_pay_type: 'receive_origin',
    report_type: '',
    branch_category: 'branch',
    allUserBranches: [],
    selectedUserBranch: '',
    listPrint: [],
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},

    //Export
    actualSelectedItems: [],
    isSelecting: false,
    pageInvoices: [],
    loading: false,
    checkedAll: false,
  }),
  methods: {
    FetchListUserBranches() {
      this.$http.get('all-user-branch').then((res) => {
        console.log(res.data)
        this.allUserBranches = res.data.data;
        this.allUserBranches.unshift({
          user_id: "",
          user_name: 'ທັງໝົດ'
        });
      })
    },
    download(id, filename) {
      this.$http.get(`download/summary/export/${id}`, {responseType: 'blob'})
          .then((response) => {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.setAttribute('download', filename)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
          }).catch((e) => console.log(e))
    },
    printSummaryBranch() {
      const loading = this.BtnLoading();
      this.loading = true;
      if (this.shipment_type === 'branch') {
        this.$http.post('/print/summary/branch', {
          start_date: this.start_date,
          end_date: this.end_date,
          branch_type: this.branch_type,
          shipment_type: this.shipment_type,
          report_type: this.report_type,
          print: 'yes',
        }).then(() => {
          setTimeout(() => {
            this.loading = false;
            loading.close();
            this.FetchData();
          }, 500);
        }).catch(() => {
          this.loading = false;
          loading.close()
        });
      } else {
        this.$http.post('/print/summary/user/branch', {
          start_date: this.start_date,
          end_date: this.end_date,
          branch_type: this.branch_type,
          shipment_type: this.shipment_type,
          shipment_pay_type: (this.shipment_type === 'branch') ? null : this.shipment_pay_type,
          selected_user_branch: this.selectedUserBranch,
          branch_category: this.branch_category,
        }).then(() => {
          setTimeout(() => {
            this.loading = false;
            loading.close();
            this.FetchData();
          }, 500);
        }).catch(() => {
          this.loading = false;
          loading.close()
        });
      }

    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableDateCustomer(date) {
      const fixDay = moment('2021-08-01 00:00:00');
      return date < fixDay;
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.listPrint = res.data.data;
          this.count = res.data.total;
          this.pagination = res.data;
          this.isLoading = false;
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list/print/summary/branch', {
        params: {
          page: current_page ?? this.pagination.current_page,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
    this.FetchListUserBranches();
  }
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
