<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນ ສາຂາທີ່ສາມາດເຊື່ອມໂຍງໄປຫາ
        <span class="countTotal">({{ branchLinked.length }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="openAddLinkModal()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຈາກສາຂາ</vs-th>
            <vs-th>ສາຂາຈັດສົ່ງ</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item, index) in branchLinked" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ item.owner_branch_name }}</vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>
              <vs-button circle icon flat danger
                         @click="openDeleteModal(item.id)">
                <i class="fad fa-trash-alt"></i>
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <AddLinking @close="close" @success="fetchAllData" :branches="branches"
                    :branch_id="branchId"/>
      </template>
    </ModalAdd>
    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteLinked @close="close" @success="fetchAllData"
                      :branch_id="branchId"
                      :id="targetDestinationId"/>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import AddLinking from './Dialog/AddBranchLink';
import DeleteLinked from './Dialog/DeleteBranchLink';
import Loading from '@/views/Loading';

export default {
  components: {
    AddLinking,
    DeleteLinked,
    Loading
  },

  data: () => ({
    branches: [],
    branchLinked: [],
    branchId: null,
    targetDestinationId: null,
    isLoading: false,
  }),

  methods: {
    tabs(tab) {
      this.isActive = tab;
    },
    openAddLinkModal() {
      this.$store.commit('modalAdd_State', true);
    },
    openDeleteModal(id) {
      this.targetDestinationId = id;
      this.$store.commit('modalDelete_State', true);
    },
    fetchBranches() {
      this.isLoading = true;
      this.$axios.get('branch-linkable/' + this.branchId).then(({data}) => {
        this.branches = data;
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false;
      });
    },
    fetchData() {
      this.isLoading = true;
      this.$axios.get('branch-linked/' + this.branchId).then(({data}) => {
        this.branchLinked = data;
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false;
      });
    },
    fetchAllData() {
      this.branchId = this.$route.params.id;
      this.fetchBranches();
      this.fetchData();
    },
  },
  created() {
    this.fetchAllData();
  },

}
</script>
