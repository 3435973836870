<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ສະຖານະການຈັດສົ່ງ
                <span class="countTotal">({{listShipmentStatuses.length}})</span>
                <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>

        <div class="center">
            <vs-table>
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ສະຖານະການຈັດສົ່ງ</vs-th>
                        <vs-th></vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                        :key="index" :data="status"
                        v-for="(status, index) in $vs.getPage(listShipmentStatuses, page, max)"
                    >
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '15%'}">
                            {{status.name}}
                        </vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalEdit(status.id)">
                                    <i class="fad fa-pencil-alt"></i>
                                </vs-button>
                            </div>
                        </vs-td>  
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listShipmentStatuses, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddShipmentStatus 
                    @close="close" 
                    @success="FetchData()"
                />
            </template>
        </ModalAdd>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditShipmentStatus 
                    :listShipmentStatus="filterListShipmentsStatus" 
                    @close="close" 
                    @success="FetchData()"
                />
            </template>
        </ModalEdit>

    </div>
</template>

<script>
import Loading from '../../Loading';
import AddShipmentStatus from './Dialog/Add';
import EditShipmentStatus from './Dialog/Edit';

export default {
    components:{
        Loading,
        AddShipmentStatus,
        EditShipmentStatus
    },

    data:()=>({
        page: 1,
        max: 15,
        isLoading: true,

        listShipmentStatuses: [],  
        filterListShipmentsStatus: {},
        statusId: '',
    }),
    methods:{
        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        OpenModalEdit(statusId){
            this.filterListShipmentsStatus = {...this.FilterParcelCategory(statusId)};
            this.$store.commit('modalEdit_State', true);
        },
        FilterParcelCategory(statusId){
            return this.listShipmentStatuses.filter(item=>{
                return item.id == statusId;
            })[0] || {};
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-shipment-statuses').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
                setTimeout(()=>{
                    this.listShipmentStatuses = res.data.listShipmentStatuses;
                }, 100);
            }).catch(()=>{});
        },
    },
    created(){
        this.FetchData();
    }
}
</script>
<style lang="scss">
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>