export default {
    data: () => ({
        AllBranches: 0,
        AllFranshises: 0,
        AllDistributionCenters: 0,
        AllUsers: 0,
        AllPackageExpress: 0,
        AllPackageLogistics: 0,
        AllPackageEnvelope: 0,
        AllPromotionOnlines: 0,
        AllPromotionOfflines: 0,
        AllCustomers: 0,
        AllPendingVipCustomer: 0,
        AllApprovedVipCustomer: 0,
        AllCompany: 0,
        AllCustomersByEmployee:0
    }),
    methods: {
        RouterPush(router) {
            if (router == 'allBranch') {
                this.$router.push({name: 'list.branches', params:{type: 'branch'}}).catch(() => {});
            } else if (router == 'allFranshise') {
                this.$router.push({name: 'list.branches', params:{type: 'franchise'}}).catch(() => {});
            } else if (router == 'allDistributionCenter') {
                this.$router.push({name: 'list.distribution.center'}).catch(() => {});
            } else if (router == 'allPackageExpress') {
                this.$router.push({name: 'tab.list.packages', params:{type: 'express'}}).catch(() => {});
            } else if (router == 'allPackageLogistics') {
                this.$router.push({name: 'tab.list.packages', params:{type: 'logistics'}}).catch(() => {});
            }else if (router == 'allPackageEnvelope') {
                this.$router.push({name: 'tab.list.packages', params:{type: 'envelope'}}).catch(() => {});
            } else if (router == 'allPromotionOnline') {
                this.$router.push({name: 'tab.list.promotion', query: {tab: 'online'}}).catch(() => {});
            } else if (router == 'allPromotionOffline') {
                this.$router.push({name: 'tab.list.promotion', query: {tab: 'offline'}}).catch(() => {});
            } else if (router == 'allCustomer' || router == 'allCustomerByEmployee') {
                this.$router.push({name: 'tab.general.customer', query: {tab: 'general'}}).catch(() => {});
            } else if (router == 'allVipCustomer') {
                this.$router.push({name: 'tab.vip.customer', query: {tab: 'pending'}}).catch(() => {});
            }
            else if (router == 'allCompany') {
                this.$router.push({name: 'tab.company.customer', query: {tab: 'company'}}).catch(() => {});
            }
        },
        FetchData() {
            this.$axios.get('list-dashboard').then((res) => {
                this.AllBranches = res.data.allBranches;
                this.AllDistributionCenters = res.data.allDistributionCenters;
                this.AllFranshises = res.data.allFranshises;
                this.AllUsers = res.data.allUsers;
                this.AllPackageExpress = res.data.allPackage_Express;
                this.AllPackageLogistics = res.data.allPackage_Logistics;
                this.AllPackageEnvelope = res.data.allPackage_Envelope;
                this.AllPromotionOnlines = res.data.allPromotionOnlines;
                this.AllPromotionOfflines = res.data.allPromotionOfflines;
                this.AllCustomers = res.data.allCustomers;
                this.AllPendingVipCustomer = res.data.allPendingVipCustomer;
                this.AllApprovedVipCustomer = res.data.allApprovedVipCustomer;
                this.AllCompany = res.data.allCompany;
                this.AllCustomersByEmployee = res.data.allCustomersByEmployee;
            }).catch(() => {

            });
        },
        numberFormatter(x) {
            if (x != null) {
                return x.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
    }
}
