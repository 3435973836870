<template>
  <div class="container">
    <div class="box-title">
      <h4>
        <i class="fal fa-users"></i>
        ຂໍ້ມູນສາຂາ ( Deliveries ) -
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
      </h4>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດ</vs-th>
            <vs-th style="width: 180px;">ສາຂາ ( Deliveries )</vs-th>
            <vs-th>ເບີໂທຕິດຕໍ່</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ສະຖານທີ່</vs-th>
            <vs-th></vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index" :data="delivery"
              v-for="(delivery, index) in $vs.getPage(Deliveries, page, max)">
            <vs-td :style="{textAlign: 'center'}">{{ index + 1 }}</vs-td>
            <vs-td :style="{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'crimson'}">
              <vs-tooltip>
                ( {{ delivery.prefix }} )
                <template #tooltip>
                  ( {{ delivery.prefix }} )
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td :style="{maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'crimson'}">
              {{ delivery.name }}
            </vs-td>
            <vs-td :style="{maxWidth: '90px'}">{{ delivery.tel }}</vs-td>
            <vs-td :style="{maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'crimson'}">
              <vs-tooltip>
                {{ delivery.email }}
                <template #tooltip>
                  {{ delivery.email }}
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td style="max-width: 100px; color: #195BFF; font-size: 16px">
              <vs-tooltip>
                {{ delivery.villageName }}
                <template #tooltip>
                  ({{ translateInloas[delivery.partName] }}, {{ delivery.proName }}, {{ delivery.distName }},
                  {{ delivery.villageName }})
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button v-if="$can('SuperAdmin')"  circle icon flat @click="AddDeliveryUsers()">
                  <i class="fal fa-plus-circle"></i>
                </vs-button>
                <vs-button circle icon flat @click="OpenModalEdit(delivery.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
                <vs-button v-if="$can('SuperAdmin')"  circle danger icon flat @click="OpenModalDelete(delivery.id)">
                  <i class="fad fa-trash-alt"></i>
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(Deliveries, max)"/>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteDeliveries @close="close" :deliveryId="deliveryId" @success="FetchData()"/>
      </template>
    </ModalDelete>

  </div>
</template>
<script>
import DeleteDeliveries from './Dialog/Delete';
import Loading from '../../Loading';

export default {
  components: {
    DeleteDeliveries,
    Loading
  },

  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    count: 0,
    Deliveries: [],
    deliveryId: '',
    translateInloas: {
      center: 'ພາກກາງ',
      south: 'ພາກໃຕ້',
      north: 'ພາກເໜືອ',
      northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
      southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
      southern: 'ພາກໃຕ້-1'
    }
  }),
  methods: {
    AddDeliveryUsers() {
      this.$router.push({name: 'list.delivery.user'});
    },
    OpenModalAdd() {
      this.$router.push({name: 'add.deliveries'});
    },
    OpenModalEdit(deliveryId) {
      this.$router.push({name: 'edit.deliveries', params: {id: deliveryId}});
    },
    OpenModalDelete(deliveryId) {
      this.deliveryId = deliveryId;
      this.$store.commit('modalDelete_State', true);
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get('list-deliveries').then(res => {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('close');
        }, 200);
        setTimeout(() => {
          this.Deliveries = res.data.listDeliveries;
          this.count = this.Deliveries.length;
        }, 100);
      }).catch(() => {
        this.isLoading = false;
        this.$emit('close');
      });
    },
  },
  created() {
    this.FetchData();
  }
}
</script>
<style lang="scss">
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}
</style>
