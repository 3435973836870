import { render, staticRenderFns } from "./ExportTotalListRequestedGenericCOD.vue?vue&type=template&id=1553b0a4&scoped=true"
import script from "./ExportTotalListRequestedGenericCOD.vue?vue&type=script&lang=js"
export * from "./ExportTotalListRequestedGenericCOD.vue?vue&type=script&lang=js"
import style0 from "./ExportTotalListRequestedGenericCOD.vue?vue&type=style&index=0&id=1553b0a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1553b0a4",
  null
  
)

export default component.exports