<template>
  <div class="container">
    <h4>
      <span class="countTotal">(ທັງໝົດ {{ count }})</span>
    </h4>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2" v-if="$can('head-franchise')">
          <div class="control">
            <label class="label">ປະເພດລາຍງານ:</label>
            <div class="select">
              <select class="input" v-model="report_type" @change="fetchData">
                <option value="shipment">ຈຳນວນບິນ</option>
                <option value="freight">ຄ່າຂົນສົ່ງ</option>
                <option value="price">ຄ່າສິນຄ້າ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຍອດ:</label>
            <div class="select">
              <select class="input" v-model="shipment_type" @change="fetchData">
                <option value="created">ຍອດສ້າງ</option>
                <option value="success">ຍອດຈັດສົ່ງ</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column columns is-2" style="margin-top: 25px">
          <button class="button is-rounded" style="margin-right: 5px" @click="printChartPDF()">
            <span class="icon">
               <i class='fal fa-file-pdf'></i>
            </span>
            <span>PDF</span>
          </button>
          <button class="button is-primary is-rounded" @click="printChartExcel()">
            <span class="icon">
                 <i class='fal fa-file-excel'></i>
            </span>
            <span>EXCEL</span>
          </button>
        </div>
      </div>
    </div>
    <div class="chartAreaWrapper">
      <barChart id="barChart"
                class="walking-speed-chart"
                v-if="!loaded"
                :chartData='chartData'
                :report_type="report_type"
                style="display: flex"
      />
    </div>
    <Loading v-if="loaded"/>
  </div>
</template>

<script>
import barChart from '@/components/Chart/BarChart'
import Exporter from "vue-chartjs-exporter";
import XLSX from 'xlsx'
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Loading from "@/views/Loading";

export default {
  name: "graph",
  data() {
    return {
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      end_date: moment(new Date()).format('YYYY-MM-DD'),
      loaded: true,
      chartData: {
        labels: [],
        qty: [],
        freight: [],
        price: [],
      },
      excelData: {},
      count: 0,
      chartOptions: {},
      date_type: 'day',
      report_type: 'shipment',
      shipment_type: 'created'
    };
  },
  methods: {
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    printChartPDF() {
      let bar = document.getElementById("barChart");
      const exp = new Exporter([bar]);
      exp.export_pdf().then((pdf) => pdf.save("charts " + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + ".pdf"));
    },
    printChartExcel() {
      const dataWS = XLSX.utils.json_to_sheet(this.excelData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'excels ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx')
    },
    fetchData() {
      this.loaded = true
      this.$http.get("report/chart", {
        params: {
          report_type: this.report_type,
          shipment_type: this.shipment_type,
          start_date: this.start_date,
          end_date: this.end_date
        }
      }).then((res) => {
        const dataSet = {
          labels: [],
          qty: [],
          freight: [],
          price: [],
        };
        res.data.branch.map((item) => {
          dataSet.labels.push(item.name);
          dataSet.qty.push(item.qty);
          dataSet.freight.push(item.freight);
          dataSet.price.push(item.price);
          return item;
        })
        this.chartData = dataSet;
        if (this.report_type === 'shipment') {
          this.count = this.formatNumber(res.data.total_qty) + ' ບິນ'
        } else if (this.report_type === 'freight') {
          this.count = this.formatNumber(res.data.total_freight) + ' ກີບ'
        } else {
          this.count = this.formatNumber(res.data.total_price) + ' ກີບ'
        }
        this.excelData = res.data.branch;
        this.loaded = false
      }).catch((error) => {
        console.log(error)
        this.loaded = false
      });
    },
    formatNumber(value) {
      let p = value.toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, value, i) {
        return value === "-" ? acc : value + (i && !(i % 3) ? "," : "") + acc;
      }, "")
    }
  },
  mounted() {
    this.fetchData();
  },

  watch: {
    'start_date': function () {
      this.fetchData();
    },
    'end_date': function () {
      this.fetchData();
    },
  },

  components: {
    barChart,
    DatePicker,
    Loading
  },
}
</script>

<style scoped lang="scss">
.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}

.walking-speed-chart {
  height: 100%;
  width: 3600px;
}
</style>
