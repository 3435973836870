<template>
  <div class="container">
    <div class="tabs" :style="{marginTop: '-48px', marginLeft: '-30px'}">
      <ul>
        <li :class="{'is-active': isActive === 'pending'}" @click="Tabs('pending')">
          <a>ລໍຖ້າ</a>
        </li>
        <li :class="{'is-active': isActive === 'rejected'}" @click="Tabs('rejected')">
          <a>ຂໍ້ມູນຜິດ</a>
        </li>
        <li :class="{'is-active': isActive === 'solved'}" @click="Tabs('solved')">
          <a>ລູກຄ້າແກ້ໄຂແລ້ວ</a>
        </li>
        <li :class="{'is-active': isActive === 'approved'}" @click="Tabs('approved')">
          <a>ຢືນຢັນ</a>
        </li>
      </ul>
    </div>

    <ListAuto :onclickEvent="onClick"/>
  </div>
</template>

<script>
import ListAuto from './ListUserKYC.vue';

export default {
  components: {
    ListAuto,
  },

  data: () => ({
    isActive: 'all',
    onClick: '',
  }),
  methods: {
    Tabs(value) {
      this.isActive = value;
      this.$router.push({name: 'tab.user.kyc', query: {tab: value}}).catch(() => {
      });
      this.onClick = value;
    }
  },
  created() {
    if (this.$route.query.tab === 'pending') {
      this.isActive = 'pending';
    } else if (this.$route.query.tab === 'rejected') {
      this.isActive = 'rejected';
    } else if (this.$route.query.tab === 'solved') {
      this.isActive = 'solved';
    } else if (this.$route.query.tab === 'approved') {
      this.isActive = 'approved';
    } else {
      this.$router.push({name: 'tab.user.kyc', query: {tab: 'pending'}});
      this.isActive = 'pending';
      this.onClick = 'pending';
    }
  }
}
</script>

<style>

</style>
