<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ບົດບາດຂອງຜູ້ໃຊ້ລະບົບ
                <span class="countTotal">({{roles.length}})</span>
                <span class="btn-add">
                    <vs-button
                        circle
                        icon
                        flat
                        @click="OpenModalAdd()"
                    >
                    <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>
                        ລຳດັບ
                    </vs-th>
                    <vs-th>
                        ບົດບາດ ຜູ້ໃຊ້ລະບົບ
                    </vs-th>
                    <vs-th>
                        displayName
                    </vs-th>
                    <vs-th>
                        description
                    </vs-th>
                     <vs-th>
                        Permission-Role
                    </vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                    :key="index" v-for="(role, index) in roles"
                >
                    <vs-td>
                        {{ index + 1 }}
                    </vs-td>
                    <vs-td>
                        {{ role.name }}
                    </vs-td>
                    <vs-td>
                        {{ role.display_name }}
                    </vs-td>
                    <vs-td>
                        {{ role.description !== null ? role.description : '..........................' }}
                    </vs-td>
                    <vs-td>
                        {{ role.countPerRole }}
                    </vs-td>
                    <vs-td style="text-align: right; width: 100px">
                        <div class="group-icon">
                            <vs-button
                                circle
                                icon
                                flat
                                @click="ViewDetailPermissionRoles(role.id)"
                            >
                            <i class="fal fa-plus-circle"></i>
                            </vs-button>
                            <vs-button
                                circle
                                icon
                                flat
                                @click="OpenModalEdit(role.id)"
                            >
                            <i class="fad fa-pencil-alt"></i>
                            </vs-button>
                            <vs-button
                                circle
                                danger
                                icon
                                flat
                                @click="OpenModalDelete(role.id)"
                            >
                            <i class="fad fa-trash-alt"></i>
                            </vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
                </template>
                <template #footer>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalAdd>
            <template v-slot="{ close }">
                <AddRoles @close="close" @success="FetchData()"/>
            </template>
        </ModalAdd>

        <ModalEdit>
            <template v-slot="{ close }">
                <EditRoles :listRole="filterRole" @close="close" @success="FetchData()"/>
            </template>
        </ModalEdit>

        <ModalDelete>
            <template v-slot="{ close }">
                <DeleteRoles @close="close" :role_id="roleId" @success="FetchData()"/>
            </template>
        </ModalDelete>

    </div>
</template>
<script>
import AddRoles from './Dialog/Add';
import EditRoles from './Dialog/Edit';
import DeleteRoles from './Dialog/Delete';
import Loading from '../../Loading';

export default {
    components:{
        AddRoles,
        EditRoles,
        DeleteRoles,
        Loading
    },

    data:()=>({
        active: 1,
        roles: [],  
        filterRole: {},
        roleId: '',
        isLoading: true,
    }),
    methods:{

        OpenModalAdd(){
            this.$store.commit('modalAdd_State', true);
        },
        ViewDetailPermissionRoles(roleId){
            this.$router.push({name: 'list.permission.roles', params: {id: roleId}}).catch(()=>{});
        },
        OpenModalEdit(roleId){
            this.filterRole = {...this.FilterItem(roleId)};
            this.$store.commit('modalEdit_State', true);
        },
        FilterItem(roleId){
            return this.roles.filter(item=>{
                return item.id == roleId;
            })[0] || {};
        },
        OpenModalDelete(roleId){
            this.roleId = roleId;
            this.$store.commit('modalDelete_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-roles').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.roles = res.data.roles;
                }, 100);
            }).catch(()=>{
                
            });
        },
    },
    created(){
        this.FetchData();
    }
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>