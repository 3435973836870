import {FormatDate, FormatDateTime, FormatNumber} from '@/Helpers/Format';
import * as helper from '../../../Helpers/GetValue';
import moment from "moment";

export default {

    data: () => ({
        checkedAll: false,
        isLoading: true,
        invoices: [],
        invoiceId: [],
        pageInvoices: [],
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        summary: {},
        listCustomers: [],
        selectedCustomer: '',
        // type: 'before',
        status: [
            {value: '', name: 'ທັງໝົດ'},
            {value: 'pending', name: 'ກຳລັງດຳເນີນການ'},
            {value: 'success', name: 'ສຳເລັດ'},
        ],
        la_status: {
            pending: 'ກຳລັງດຳເນີນການ',
            success: 'ສຳເລັດ',
        },

        selectedStatus: {
            value: '', name: 'ທັງໝົດ'
        },
        La_status: {
            'pending': 'ກຳລັງດຳເນີນການ',
            'success': 'ສຳເລັດ',
        },
        //export
        actualSelectedItems: [],
        isSelecting: false,

        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},

        shipment_number: '',
        oldVal: '',
    }), // Data

    methods: {
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        UpdateInvoices() {
            this.$store.commit('modalPreviewFile_State', true);
        },
        FilterShipmentNumber() {
            helper.AccountingGetValueOnInput(this);
        },
        FilterStatus() {
            this.FetchData();
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('list-invoice', {
                params: {
                    shipment_number: this.shipment_number,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    invoice_type: this.$route.query.tab,
                    // customer: this.selectedCustomer,
                    type: this.type,
                    invoice_status: this.selectedStatus.value,
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page
                }
            })
        },

        afterEdit() {
            this.FetchData();
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },

        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse()
                .then((res) => {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.pagination = res.data.invoices;
                        this.invoices = res.data.invoices.data;
                        this.summary = res.data.summary;
                        this.count = res.data.invoices.total;
                        this.markSelectedItemsOnPageChanges(this.invoices);
                    }, 300);
                }).catch(() => {
                this.isLoading = false;
            });
        },
        // FetchCustomers() {
        //     this.$http.get('list-invoice', {
        //         params: {
        //             startDate: this.startDate,
        //             endDate: this.endDate,
        //             invoice_type: this.$route.query.tab,
        //             // type: this.type,
        //         }
        //     }).then((res) => {
        //         setTimeout(() => {
        //             this.listCustomers = res.data.list_customers;
        //             this.listCustomers.unshift({
        //                 id: "",
        //                 name: 'ທັງໝົດ'
        //             });
        //         }, 300);
        //     }).catch(() => {
        //
        //     });
        // },

        OpenModalPrintInvoiceCOD_Split() {
            this.$store.commit('modalDelete_State', true);
        },
        ExportExcel_By_Sum() {
            this.$store.commit('modalEdit_State', true);
        },
        importExcelTemplate() {
            this.$store.commit('modalClose_State', true);
        },
        ExportExcel_Transaction(items, date) {
            const invoiceId = items.map(item => item.id);
            const loading = this.$vs.loading()
            this.$http.post('print-cod-transaction', {
                    invoices: invoiceId,
                    date: date,
                    // type: type,
                },
                {responseType: 'blob'}
            ).then((response) => {
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                // if (type === 'before'){
                //     fileLink.setAttribute('download', 'COD Transaction ເຊົ້າ ວັນທີ ' + FormatDate(date) + '.xlsx');
                // }else if (type === 'after'){
                //     fileLink.setAttribute('download', 'COD Transaction ແລງ ວັນທີ ' + FormatDate(date) + '.xlsx');
                // }
                fileLink.setAttribute('download', 'COD Transaction ວັນທີ ' + FormatDate(date) + '.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                loading.close();
            }).catch(() => {
                loading.close();
            })
        },

        FormatDate(value) {
            return FormatDateTime(value);
        },
        FormatPrice(price) {
            return FormatNumber(price);
        },
        ExportSuccess() {
            this.clearAllChecked();
        },

        /**
         * @StartChecking
         */
        markSelectedItemsOnPageChanges(items) {
            const selectedInvoiceIds = this.actualSelectedItems.map((i) => i.id);
            const newSelectedItems = [];
            items.map((i) => {
                if (selectedInvoiceIds.includes(i.id)) {
                    newSelectedItems.push(i);
                }
            });
            this.pageInvoices = newSelectedItems;
        },
        onCheckAllCheckBoxChanged(event) {
            if (this.isSelecting) {
                return;
            }
            /// If checked
            if (event.target.checked) {
                // if its not indeterminate
                if (!this.isIndeterminate) {
                    const loading = this.BtnLoading();
                    this.isSelecting = true;
                    /// Select all current page
                    this.pageInvoices = JSON.parse(JSON.stringify(this.invoices));
                    /// Select all from response data
                    this.fetchInvoiceItemsResponse({
                        current_page: 1,
                        per_page: this.count
                    }).then((res) => {
                        this.actualSelectedItems = res.data.invoices.data;
                    }).catch(() => {
                    }).finally(() => {
                        loading.close();
                        this.isSelecting = false;
                    });
                }
            } else {
                /// Clear all selected orders
                this.clearAllChecked();
            }
        },

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },

        onItemCheckBoxChanged(invoice) {
            const invoiceId = invoice.id;
            let itemIndex = this.actualSelectedItems.findIndex((item) => {
                return item.id === invoiceId;
            });
            if (itemIndex !== -1) {
                this.actualSelectedItems.splice(itemIndex, 1);
            } else {
                this.actualSelectedItems.push(invoice);
            }
            this.pageInvoices = JSON.parse(JSON.stringify(this.actualSelectedItems));
        },

        isRowSelected(id) { //@changed
            return this.pageInvoices.map((i) => i.id).includes(id);
        },
        clearAllChecked() { //@changed
            this.checkedAll = false;
            this.pageInvoices = [];
            this.actualSelectedItems = [];
        },
        /**
         * @EndChecking
         */
    },
    watch: {
        'startDate': function () {
            this.FetchData();
            // this.FetchCustomers();
        },
        'endDate': function () {
            this.FetchData();
            // this.FetchCustomers();
        },
        'type': function () {
            this.FetchData();
            // this.FetchCustomers();
        },
        // 'selectedCustomer': function () {
        //     this.FetchData();
        // },
        // 'shipment_number': function (value) {
        //     if (value == '') {
        //         this.FetchData();
        //     }
        // },
    },

    computed: {
        isSelectAllOrders() {
            return this.actualSelectedItems.length === this.count
                && this.count > 0 && !this.isSelecting;
        },
        isSelectingOrders() {
            return this.actualSelectedItems.length > 0;
        },
        isIndeterminate() {
            return this.isSelectingOrders && this.actualSelectedItems.length !== this.count;
        }
    },
}
