import {Validator} from 'vee-validate';

const validate = {
    custom: {
        name: {
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        tel: {
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
            min: '( ເບີໂທບໍ່ຄວນໜ້ອຍກວ່າ 6 ໂຕເລກ... )',
            max: '( ເບີໂທບໍ່ຄວນຫຼາຍກວ່າ 15 ໂຕເລກ... )'
        },
        email: {
            email: '( ອີເມວບໍ່ຖືກຕ້ອງ... )'
        },
        address: {
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        part: {
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        province: {
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        district: {
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        village: {
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        prefix: {
            required: '( ກະລຸນາປ້ອນລະຫັດກ່ອນ... )',
            min: '( ລະຫັດບໍ່ຄວນໜ້ອຍກວ່າ 3 ຕົວອັກສອນ... )',
            max: '( ລະຫັດບໍ່ຄວນຫຼາຍກວ່າ 25 ຕົວອັກສອນ... )'
        },
        profile: {
            required: '(ກະລຸນາເລືອກໂປຮໄຟລກ່ອນ)',
            mimes: '(ກະລູນາເລືອກ jpeg,jpg,png,gif ເທົ່ານັ້ນ...)',
            size: '(ຂະໜາດບໍ່ຄວນເກີນ 5 MB.)',
        },
        code: {
            required: 'ກະລຸນາປ້ອນລະຫັດກ່ອນ)',
            min: 'ລະຫັດບໍ່ຄວນໜ້ອຍກວ່າ 3 ຕົວອັກສອນ',
            max: 'ລະຫັດບໍ່ຄວນຫຼາຍກວ່າ 25 ຕົວອັກສອນ'
        },
        post_box_size: {
            required: 'ກະລຸນາເລືອກກ່ອນ',
        },
        customer_name: {
            required: 'ກະລຸນາປ້ອນກ່ອນ',
            min: 'ບໍ່ຄວນໜ້ອຍກວ່າ 2 ຕົວອັກສອນ',
            max: 'ບໍ່ຄວນຫຼາຍກວ່າ 25 ຕົວອັກສອນ'
        },
        customer_phone_number: {
            required: 'ກະລຸນາປ້ອນກ່ອນ',
            min: 'ເບີໂທບໍ່ຄວນໜ້ອຍກວ່າ 6 ໂຕເລກ',
            max: 'ເບີໂທບໍ່ຄວນຫຼາຍກວ່າ 15 ໂຕເລກ'
        },
        customer_address: {
            required: 'ກະລຸນາປ້ອນກ່ອນ',
            min: 'ບໍ່ຄວນໜ້ອຍກວ່າ 8 ໂຕເລກ',
            max: 'ບໍ່ຄວນຫຼາຍກວ່າ 80 ໂຕເລກ'
        },
        lat_lng: {
            required: 'ກະລຸນາປ້ອນກ່ອນ',
        }
    }
};

export const ValidateBranches = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}

