<template>
    <div class="container">
        <div class="box-title">
            <h4>
                <i class="fal fa-users"></i>
                  ຂໍ້ມູນ ຂ່າວສານທົ່ວໄປ
                <span class="countTotal">({{listNews.length}})</span>
                <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>

        <div class="center">
            <vs-table>
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ຂ່າວສານທົ່ວໄປ</vs-th>
                         <vs-th>ລາຍລະອຽດ</vs-th>
                        <vs-th></vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                        :key="index" :data="news"
                        v-for="(news, index) in $vs.getPage(listNews, page, max)"
                    >
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td :style="{width: '15%'}">
                            {{news.title}}
                        </vs-td>
                        <vs-td>{{news.description}}</vs-td>
                        <vs-td style="text-align: right; width: 100px">
                            <div class="group-icon">
                                <vs-button circle icon flat @click="OpenModalEdit(news.id)">
                                    <i class="fad fa-pencil-alt"></i>
                                </vs-button>
                            </div>
                        </vs-td>  
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :length="$vs.getLength(listNews, max)"/>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>
    </div>
</template>

<script>
import Loading from '../../Loading';

export default {
    components:{
        Loading,
    },

    data:()=>({
        page: 1,
        max: 15,
        isLoading: true,

        listNews: [],  
        filterNews: {},
        newsId: '',
    }),
    methods:{
        OpenModalAdd(){
            this.$router.push({name: 'add.news'}).catch(()=>{});
        },
        OpenModalEdit(newsId){
            this.$router.push({name: 'edit.news', params:{id: newsId}}).catch(()=>{});
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-news').then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
                setTimeout(()=>{
                    this.listNews = res.data.listNews;
                }, 100);
            }).catch(()=>{});
        },
    },
    created(){
        this.FetchData();
    }
}
</script>
<style lang="scss">
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>