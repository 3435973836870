<template>
  <div class="container">
    <div class="columns">
      <div class="column is-9">
        <!--Chat Message-->
        <div class="card scrollable" id="body-chat">
          <div class="card-content" v-if="chatId">
            <div class="columns" v-for="(message,index) in messages" :key="index">
              <div class="column">
                <div v-if="!message.image_url" class="is-receiver"
                     :class="{'is-sender': checkSender(message.sender_id)}">
                  <div v-if="!message.image_url" class="is-receiver-background"
                       :class="{'is-sender-background':checkSender(message.sender_id)}">
                    <p v-if="message.messages" v-html="urlDetect(message.messages)">
                    </p>
                  </div>
                  <p class="chat-date">{{ moment(message.timeToken).format('DD/MM/YYYY HH:mm') }}</p>
                </div>
                <div v-if="message.image_url">
                  <div class="is-receiver-file" :class="{'is-sender-file':checkSender(message.sender_id)}">
                    <img :src="message.image_url" alt="image" width="420" height="320"
                         @click="showImage(message.image_url)">
                  </div>
                  <p class="chat-date-file" :class="{'is-sender-file':checkSender(message.sender_id)}">
                    {{ moment(message.timeToken).format('DD/MM/YYYY HH:mm') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Chat Sidebar -->
    <vs-sidebar
        right
        v-model="active"
        open
        style="z-index: 0;border-text-outline: none;top: 52px;"
    >
      <template #header>
        <div style="margin-bottom: 8px; margin-top: 8px">
          <vs-input icon="search" placeholder="ເບີໂທ" v-model="message" @keyup.enter="getRooms()"/>
        </div>
      </template>
      <div>
        <vs-sidebar-item :id="customer.receiver_name" v-for="(customer,index) in customers" :key="index"
                         style="padding: 4px; margin-left: 4px;">
          <template #icon @click="selectChat(customer, false)">
            <div class="profile mr-8" @click="selectChat(customer, false)">
              <vs-avatar circle class="mr-8" badge-color="danger" badge-position="top-right">
                <img class="image is-58x52 is-rounded image chat"
                     :src="customer.receiver_profile"
                     width="52" height="52">
                <i class='bx bx-bell'></i>
                <template v-if="customer.unread" #badge>
                  {{ customer.unread }}
                </template>
              </vs-avatar>
            </div>
          </template>
          <div @click="selectChat(customer,false)" :style="unreadStyle(customer)">
            {{ customer.receiver_name }}
          </div>
          <div @click="selectChat(customer,false)" :style="unreadStyle(customer)">
            {{ customer.receiver_tel }}
          </div>
          <div class="chat-date" :style="unreadStyle(customer)">
            {{ customer.latest_message }}
          </div>
        </vs-sidebar-item>
      </div>
    </vs-sidebar>


    <!--    Send Message-->
    <nav class="navbar is-fixed-bottom border" v-if="chatId"
         style="margin-top: 0; z-index: 9999;
         justify-content: center;background-color: white;
         border-top: 1px solid #eee;margin-left: auto;
         margin-right: auto">
      <div class="navbar-brand">
        <a class="navbar-item" @click="OpenModalAdd">
          <i class="fas fa-image" style="color: #EB1D27"></i>
        </a>

        <div class="field navbar-item">
          <p class="control has-icons-right">
            <input class="input is-rounded" type="text" placeholder="Message" v-model="chat"
                   @keyup.enter="sendMessage()"
                   style="padding: 18px;width: 720px;border: 1px solid #EB1D27">
            <span class="icon is-small is-left">
                        </span>
            <span class="icon is-small is-right">
                            <i class="fas fa-paper-plane"></i>
                        </span>
          </p>
        </div>
      </div>
    </nav>
    <!--    <p><b>I. ເງື່ອນໄຂດ້ານການບໍລິການສຳຫຼັບການຮັບ - ຝາກພັດສະດຸ ແລະ ເກັບຮັກສາ.</b></p>-->
    <!--    <p>1) ພັດສະດຸເຄື່ອງຝາກທີ່ຝາກຈາກສາຂາຕົ້ນທາງ ຫາກຮອດສາຂາປາຍທາງແລ້ວ ລູກຄ້າປາຍທາງຕ້ອງເຂົ້າມາພົວພັນຮັບພັດສະດຸທີ່ສາຂາພາຍໃນ<b>10</b> ວັນ ນັບຕັ້ງແຕ່ມື້ທີ່ພັດສະດຸຮອດສາຂາປາຍທາງ, <b><i>ອີງຕາມການແຈ້ງເຕືອນພັດສະດຸຜ່ານແອັບ HAL EXPRESS LAOS.</i></b>-->
    <!--    <p>2) ພັດສະດຸເຄື່ອງຝາກທີ່ຄ້າງຢູ່ສາຂາປາຍທາງ ຫາກລູກຄ້າບໍ່ມາພົວພັນຮັບເອົາພັດສະດຸຕາມການກຳນົດຂໍ້ທີ 1) ແມ່ນຈະຖືກຕີກັບເຂົ້າສູນແກ້ໄຂປະຈຳແຂວງ (ສາຂາປາຍທາງຕ້ອງໂທແຈ້ງໃຫ້ລູກຄ້າຮັບຮູ້ກ່ອນຮອດກຳນົດມື້ຕີເຄື່ອງກັບ).</p>-->
    <!--    <p>3) ສູນແກ້ໄຂປະຈຳແຂວງ ຈະເກັບຮັກສາເຄື່ອງຂອງທ່ານໄວ້ພາຍໃນ <b>30</b> ວັນ ນັບຕັ້ງແຕ່ມື້ທີ່ເຄື່ອງຖືກຕີກັບເຂົ້າສາງ.</p>-->
    <!--    <p>4) ຫາກລູກຄ້າຝ່າຍໃດໜຶ່ງ ຫາກບໍ່ມີການພົວພັນມາຮັບເອົາພັດສະດຸຕາມການກຳນົດໃນຂໍ້ທີ 3) ທາງບໍລິສັດຈະຖືວ່າລູກຄ້າທັງສອງຝ່າຍສະລະສິດໃນການຮັບພັດສະດຸ ແລະ ທາງບໍລິສັດສາມາດສະສາງສິນຄ້າໄດ້ທັນທີທີ່ຄົບກຳນົດ.</p>-->
    <!--    <p>5) ກໍລະນີພັດສະດຸຂອງທ່ານຫາກບໍ່ມີການເຄື່ອນໄຫວ ຫຼື ບໍ່ຮອດສາຂາປາຍທາງຕາມກຳນົດເວລາ ລູກຄ້າຕ້ອງແຈ້ງຂໍ້ມູນຫາໜ່ວຍງານແກ້ໄຂບັນຫາ (020 52018888) ພາຍໃນ <b>10</b> ວັນ ນັບຕັ້ງແຕ່ມື້ທີ່ສຳເລັດການຝາກພັດສະດຸກັບບໍລິສັດ (ມື້ຝາກພັດສະດຸ) ຫາກເກີນ <b>10</b>ວັນ ເຮົາຖືວ່າລູກຄ້າສະລະສິດໃນການຮັບສິນຄ້າດັ່ງກ່າວ.</p>-->
    <!--    <p><b>II. ເງື່ອນໄຂການເກັບຮັກສາຖານຂໍ້ມູນບິນໃນແອັບລູກຄ້າ.</b></p>-->
    <!--    <p>1) ການຮັບຝາກພັດສະດຸທຸກຄັ້ງ ຂໍ້ມູນໃນແອັບລູກຄ້າ (HAL EXPRESS LAOS) ແມ່ນຈະຖືກເກັບຮັກສາໄວ້ພາຍໃນ <b>90</b> ວັນ ຫຼັງຈາກນັ້ນຂໍ້ມູນຈະຖືກແບັກອັບ (Back Up) ໄວ້, ແລ້ວຂໍ້ມູນຢູ່ຝັ່ງແອັບລູກຄ້າຈະຖືກເຄລຍອອກ.</p>-->
    <!--    <p>2) ລູກຄ້າຕົ້ນທາງ ແລະ ປາຍທາງທີ່ນຳໃຊ້ແອັບ (HAL EXPRESS LAOS) ຈຳເປັນຕ້ອງກວດສອບສະຖານະການເຄື່ອນໄຫວພັດສະດຸຂອງທ່ານທຸກໆ<b>03 - 07</b> ວັນ ພາຍຫຼັງການຝາກພັດສະດຸສຳເລັດແລ້ວ.</p>-->
    <!--    upload file-->
    <ModalAdd>
      <template v-slot="{ close }">
        <sendImage @close="close" :chatRoomId="chatId" :messages="messages" @success="scrollToBottom"/>
      </template>
    </ModalAdd>
  </div>
</template>

<script>
import sendImage from "@/views/Chat/Dialog/Add"

export default {
  components: {
    sendImage,
  },
  data: () => ({
    active: 'home',
    inactivityTimeout: null,
    message: '',
    chat: '',
    isLoading: false,
    show: false,
    openFile: false,
    imageUrl: '',
    file: '',
    customers: [],
    user: {},
    chatId: '',
    chatChannel: [],
    messageChannel: [],
    messages: [],
    updateChatRoom: {},
    getRoomsInterval: null,
  }),
  beforeRouteLeave(to, from, next) {
    this.unsubscribeFromChannels();
    next();
  },

  created() {
    // Load chat rooms and subscribe to channels
    this.getRooms();

    // Set up event listeners for inactivity and tab/browser close
    document.addEventListener('mousemove', this.resetInactivityTimer);
    document.addEventListener('keydown', this.resetInactivityTimer);
    window.addEventListener('beforeunload', this.unsubscribeFromChannels);

    // Start the inactivity timer
    this.resetInactivityTimer();
  },

  beforeDestroy() {
    // Cleanup event listeners
    document.removeEventListener('mousemove', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);
    window.removeEventListener('beforeunload', this.unsubscribeFromChannels);

    // Unsubscribe from channels
    this.unsubscribeFromChannels();
  },

  methods: {
    setupGetRoomsInterval() {
      this.getRoomsInterval = setInterval(() => {
        this.getRooms();
      }, 5 * 60 * 1000);
    },

    unreadStyle(customer) {
      return (customer.unread === null || customer.unread === 0) ? {color: 'lightgrey'} : {};
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      clearInterval(this.getRoomsInterval);
      this.inactivityTimeout = setTimeout(() => {
        this.unsubscribeFromChannels();
      }, 5 * 60 * 1000); // 5 minutes, for example

      this.getRoomsInterval = setInterval(() => {
        this.getRooms()
      }, 1 * 60 * 1000)
    },
    unsubscribeFromChannels() {
      this.chatChannel.forEach(channel => {
        this.$pnUnsubscribe({
          channels: [channel]
        });
      });
      console.log('Unsubscribed from channels');
    },
    checkSender(senderId) {
      let user = JSON.parse(localStorage.getItem('user_profile'))
      return user.userId === senderId;
    },

    getRooms() {
      this.isLoading = true
      const loading = this.BtnLoading();
      this.$http.get('getRooms', {
        params: {
          'search': this.message
        }
      }).then((res) => {
        if (!res.data.error) {
          this.customers = res.data.data;
          this.chatChannel = this.customers.map(item => {
            return item.channel
          })
          // this.chatChannel.forEach((subscribeChannel) => {
          //   this.$pnSubscribe({
          //     channels: subscribeChannel
          //   });
          // })


          for (let index in this.chatChannel) {
            this.$pnSubscribe({
              channels: [this.chatChannel[index]]
            });
            this.$pnGetMessage(this.chatChannel[index], this.receptor)
          }
        } else {
          this.customers = [];
        }
        this.isLoading = false;
        loading.close()
      }).catch(() => {
        this.customers = [];
        this.isLoading = false;
        loading.close()
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const element = document.getElementById("body-chat");
        element.scrollTop = 0;
        element.scrollTop = element.scrollHeight;
      })
    },

    sendMessage() {
      if (this.chat !== '') {
        const loading = this.BtnLoading();
        this.isLoading = true;
        this.$http.post('send-message', {
          'message': this.chat,
          'chatroom_id': this.chatId
        }).then((res) => {
          if (!res.data.error) {
            this.messages.push(res.data.data.chatMessage);
            this.chat = ''
          }
          this.scrollToBottom();
          this.isLoading = false;
          loading.close();
        }).catch(() => {
          this.customers = [];
          this.scrollToBottom();
          this.isLoading = false;
          loading.close()
        });
      }
    },

    showImage(url) {
      this.imageUrl = url;
      this.show = true;
    },

    fetchHistoryMessage(chatId, type) {
      this.isLoading = true
      const loading = this.BtnLoading();
      this.$http.get('getHistory-message/' + chatId, {
        params: {
          'get_type': type ? 'pubnub' : 'logistic'
        }
      }).then((res) => {
        if (!res.data.error) {
          this.messages = res.data.data;
          this.updateRoom(chatId);
          this.scrollToBottom();
          loading.close()
        } else {
          this.messages = [];
          this.scrollToBottom();
        }
        this.isLoading = false;
        loading.close()
      }).catch(() => {
        this.customers = [];
        this.scrollToBottom();
        this.isLoading = false;
        loading.close()
      });
    },

    selectChat(id, type) {
      this.chatId = id.chatroom_id;
      this.messages = [];
      this.fetchHistoryMessage(this.chatId, type);
    },
    receptor(msg) {
      if (this.chatId != null && (this.chatId === msg.message.chatroom_id)) {
        this.updateMessage(msg)
      }
      let user = JSON.parse(localStorage.getItem('user_profile'));
      if (msg.message.sender_id !== user.userId) {
        this.$notification.OpenNotification_New_Message('top-right', 'primary', 3000);
      }

      this.updateRoom(msg.message.chatroom_id)
    },

    updateMessage(msg) {
      const loading = this.BtnLoading();
      if (!this.checkSender(msg.message.sender_id)) {
        this.$http.put('update/chat-message/' + msg.message.id)
        this.messages.push(msg.message)
      }
      this.scrollToBottom()
      loading.close();
    },

    updateRoom(roomId) {
      this.isLoading = true
      const loading = this.BtnLoading();
      this.$http.get('update/room/' + roomId, {}).then((res) => {
        if (!res.data.error) {
          this.updateChatRoom = res.data.data;
          this.customers = this.customers.map(item =>
              item.chatroom_id === this.updateChatRoom.chatroom_id
                  ? {
                    ...item,
                    unread: (this.chatId === item.chatroom_id) ? 0 : this.updateChatRoom.unread,
                    latest_message: this.updateChatRoom.latest_message,
                    latest_time: this.updateChatRoom.latest_time
                  }
                  : item
          )
        }
        this.isLoading = false;
        loading.close();
      }).catch(() => {
        this.isLoading = false;
        loading.close();
      });
    },

    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
    urlDetect(text) {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '" style="color:#195BFF">' + url + "</a>";
      });
    },
  },

  watch: {
    'chatId': function () {
      if (this.chatId != null) {
        this.scrollToBottom();
      }
    },
    // 'chatChannel': function () {
    //   if (this.chatChannel != null) {
    //
    //   }
    // },
  }
}
</script>


<style lang="css" scoped>
.grey-out {
  color: lightgrey;
}

.chat-list {
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-top: auto;
  padding: 2px 18px 2px 0px;
  margin-bottom: auto;
  display: flex;
}

.chat-list:hover {
  transition: 0.4ms;
  opacity: 0.8;
  cursor: pointer;
}

.image.chat {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.text-message {
  width: 70%;
  text-align: left;
  padding-left: 20px;
}

.profile {
  width: 30%;
}


.chat-name {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 600;
}

.highlight-message {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.vs-navbar {
  align-items: start !important;
  padding-left: 40px;
}

.card {
  box-shadow: none;
}

.chat-section {
  /*width: 100% !important;*/
  /*background-color: #0f81cc;*/
}

.chat-sidebar-main {
  padding: 20px;
  /*width: 30% !important;*/
  /*background-color: #00b89c;*/
}

.chat-content {
  /*width: 70% !important;*/
  /*background-color: #1ca64c;*/
}

.chat-sidebar {
  margin-top: 1%;
  /*margin-bottom: 4% !important;*/
  /*position: fixed;*/
}

.chat-footer {
  /*bottom: 0;*/
  /*top: 400px !important;*/
  /*height: 60px;*/
  /*position: fixed !important;*/
  left: 10px;
  margin-top: auto;
  position: fixed !important;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
  background: #6cf;
}

.navbar-brand {
  margin-left: 20%;
}

.navbar {
  background-color: #0a0a0a;
  color: white;
  height: 9%;
  display: flex;
  justify-content: center;
}

/*.navbar .navbar-brand {*/
/*  text-align: center;*/
/*  display: block;*/
/*  width: 100%;*/
/*}*/

/*.navbar .navbar-brand > .navbar-item,*/
/*.navbar .navbar-brand .navbar-link {*/
/*  display: inline-block;*/
/*}*/
.is-receiver {
  align-items: start;
  justify-content: start;
  text-align: left;
  margin: 10px 200px 10px 0;

}

.is-receiver-background {
  width: fit-content;
  padding: 15px;
  border-radius: 50px 50px 50px 0;
  background-color: #eee;
  margin-bottom: 4px;
}

.is-sender {
  float: right;
  margin: 10px 0px 10px 200px;
  text-align: end;
  display: inline-block;

}

.is-sender-background {
  display: inline-block;
  align-items: end;
  justify-content: end;
  text-align: end;
  padding: 15px;
  width: fit-content;
  border-radius: 50px 50px 0px 50px;
  background-color: #EAFBFF;
  margin-bottom: 4px;
}

.is-receiver-file {
  align-items: start;
  justify-content: start;
  text-align: left;
}

.chat-date-file {
  font-size: 12px;
  font-weight: 400;
  align-items: start;
  justify-content: start;
  text-align: left;
}

.chat-date-file-sender {
  font-size: 12px;
  font-weight: 400;
  align-items: end;
  justify-content: end;
  text-align: right;
}

.is-sender-file {
  align-items: end;
  justify-content: end;
  text-align: right;
}

.message-body {
  width: auto;
  padding: 10px;
  border: 5px solid gray;
  margin: 0;
}

.chat-date {
  font-size: 12px;
  font-width: 400;
}

.vs-sidebar__item {
  margin-bottom: 8px !important;
  padding-left: 2px !important;
}

.vs-sidebar__item__text {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.active {
  color: #EB1D27 !important;
}

.vs-sidebar__item:after {
  background: #EB1D27 !important;
  padding-right: 8px !important;

}

.vs-sidebar__item.hasIcon.active {
  padding-left: 14px;
}

.scrollable {
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
}

.highlight-message {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0 !important;
}
</style>
