<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-history"></i>
          ລາຍການຍອດລູກຄ້າ
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາ:
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                placeholder="ເບິໂທລູກຄ້າ"
                @keyup.enter="FilterBySearch()"
            />
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="start_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="end_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>

        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="FetchData()"
              class="form-btn-loading"
              :loading="loading">
            ຄົ້ນຫາ
          </vs-button>
        </div>

        <div class="column is-2" style="margin-top: 3px" v-if="shipmentTotal.length > 0">
          <div v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])">
            <vs-button
                ref="button" flat
                @click="printPrizeResult()"
                class="form-btn-loading"
                :loading="loading">
              export excel
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຊື່</vs-th>
            <vs-th>ນາມສະກຸນ</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ຈຳນວນບິນ</vs-th>
            <vs-th>ຈຳນວນເງິນ</vs-th>
            <vs-th>ບ້ານ</vs-th>
            <vs-th>ເມືອງ</vs-th>
            <vs-th>ແຂວງ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              v-for="(item, index) in shipmentTotal"
              :data="item">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.name) }}</vs-td>
            <vs-td v-if="item.surname != null">{{ nullSafe(item, t => t.surname) }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ nullSafe(item, t => t.tel) }}</vs-td>
            <vs-td>{{ FormatPrice(nullSafe(item, t => t.qty)) }}</vs-td>
            <vs-td>{{ FormatPrice(nullSafe(item, t => t.freight)) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.village.name) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.village.district.name) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.village.district.province.name) }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from "@/Plugins/Paginations/CusorPagination";
import * as helper from "@/Helpers/GetValue";
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Loading,
    pagination,
    DatePicker
  },

  data: () => ({
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    page: 1,
    max: 10,
    isLoading: true,
    loading: false,
    shipmentTotal: [],
    item: '',
    searchItem: "",
    oldVal: "",
    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  methods: {
    nullSafe(instance, func) {
      console.log()
      try {
        return func(instance);
      } catch (_) {
        return undefined
      }
    },
    printPrizeResult() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post('print/customer/shipments/total', {
        start_date: this.start_date,
        end_date: this.end_date,
        search: this.searchItem,
        print: 'yes',
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        if (this.start_date === this.end_date) {
          fileLink.setAttribute('download', 'ລາຍການຍອດລູກຄ້າວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
        } else {
          fileLink.setAttribute('download', 'ລາຍການຍອດລູກຄ້າວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('DD-MM-YYYY') + '.xlsx');
        }
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },
    FetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        setTimeout(() => {
          this.shipmentTotal = res.data.data.data;
          this.pagination = res.data.data;
          this.isLoading = false;
          this.oldVal = ''
        }, 300);
      }).catch(() => {
        this.isLoading = false;
      });
    },


    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('customer/shipments/total', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          search: this.searchItem,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
