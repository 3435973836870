<template>
  <div>
    <template>
      <h4
        class="not-margin"
        :style="{
          fontWeight: 'bold',
          color: '#195BFF',
          fontSize: '18px',
          marginTop: '10px',
        }"
      >
        <i
          class="fal fa-info-circle"
          :style="{ width: '30px', fontSize: '18px' }"
        ></i>
        {{ $t("ຂໍ້ມູນOrderDetail.ຂໍ້ມູນການແປງ") }}
      </h4>
    </template>

    <div class="columns" :style="{ marginTop: '10px' }">
      <div class="field column">
        <div class="control">
          <div class="control">
            <label class="label"
              >{{ $t("ຂໍ້ມູນOrderDetail.ປະເພດການແປງ") }}:</label
            >
            <div class="select">
              <select class="input" v-model="statusType">
                <option value="6">
                  {{ $t("ຂໍ້ມູນOrderDetail.ປ່ຽນປາຍທາງ") }}
                </option>
                <option value="8">
                  {{ $t("ຂໍ້ມູນOrderDetail.ຈັດສົ່ງຄືນຜູ້ຝາກ") }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="isEnable" class="control">
            <label class="label"
              >{{ $t("ຂໍ້ມູນOrderDetail.ສາຂາປາຍທາງ") }}:</label
            >
            <model-list-select
              :list="branches"
              v-model="change_branch.end_branch_id"
              :placeholder="`${$t('ຂໍ້ມູນOrderDetail.ເລືອກສາຂາປາຍທາງ')}`"
              option-text="name"
              option-value="id"
              selectedItem="name"
            >
            </model-list-select>
          </div>

          <div v-if="!isEnable" class="control">
            <label class="label"
              >{{ $t("ຂໍ້ມູນOrderDetail.ສາຂາທີ່ຈະຕີກັບໄປຄືນ") }}:</label
            >
            <model-list-select
              :list="branches"
              v-model="change_branch.start_branch_id"
              :placeholder="`${$t('ຂໍ້ມູນOrderDetail.ເລືອກສາຂາປາຍທາງ')}`"
              option-text="name"
              option-value="id"
              selectedItem="name"
            >
            </model-list-select>
          </div>

          <div v-if="!isEnable" class="control">
            <label class="label"
              >{{ $t("ຂໍ້ມູນOrderDetail.ປະເພດຕີກັບ") }}:</label
            >
            <div class="select">
              <select class="input" v-model="rejectType">
                <option value="origin_freight_fees">
                  {{ $t("ຂໍ້ມູນOrderDetail.ຕົ້ນທາງ") }}
                </option>
                <option value="destination_freight_fees">CDC</option>
              </select>
            </div>
          </div>

          <div v-if="!isEnable" class="control">
            <label class="label"
              >{{ $t("ຂໍ້ມູນOrderDetail.ຄ່າຂົນສົ່ງ") }}
              <span class="text-danger">* {{ errors.first("price") }}</span>
              <span class="text-danger">
                {{ server_errors.price }}
              </span>
            </label>
            <input
              class="input"
              type="text"
              name="price"
              v-model="price"
              v-validate="isEnable ? '' : 'max:191|required|numeric'"
            />
          </div>

          <div class="control">
            <label class="label"
              >{{ $t("ຂໍ້ມູນOrderDetail.ໝາຍເຫດ") }}
              <span class="text-danger">* {{ errors.first("remark") }}</span>
              <span class="text-danger">
                {{ server_errors.remark }}
              </span>
            </label>
            <input
              class="input"
              type="text"
              name="remark"
              v-model="remark"
              v-validate="'max:191|required'"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="target" id="target" class="center">
      <div class="con-btns">
        <vs-button
          ref="button"
          flat
          @click="ValidateForm()"
          class="form-btn-loading"
          :loading="loading"
        >
          {{ $t("ຂໍ້ມູນOrderDetail.ເເກ້ໄຂ") }}
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Validator } from "vee-validate";
import { ModelListSelect } from "vue-search-select";

const dict = {
  custom: {
    remark: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
    },
    price: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
      max: "(ປ້ອນໄດ້ສູງສຸດ 191 ຕົວອັກສອນ...)",
      numeric: "(ກະລຸນາປ້ອນຕົວເລກ)",
    },
  },
};
Validator.localize("en", dict);
export default {
  props: ["change_branch"],
  components: { ModelListSelect },
  data: () => ({
    statusType: 6,
    rejectType: 'destination_freight_fees',
    isEnable: true,
    loading: false,
    branches: [],
    remark: "",
    price: null,
    server_errors: {
      remark: "",
      price: "",
    },
  }),
  created() {
    this.FetchListBranch();
  },
  watch: {
    remark: function () {
      this.server_errors.remark = "";
    },
    price: function () {
      this.server_errors.price = "";
    },
    statusType: function () {
      this.isEnable = this.statusType == 6;
    },
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.EditItem();
        }
      });
    },
    FetchListBranch() {
      this.$http.get("v1/listing/" + "branches").then((res) => {
        this.branches = res.data;
      });
    },
    EditItem() {
      this.loading = true;
      this.$http
        .post("v1/auth/branches/shipments/orders/tracking/issue-shipment", {
          bill_number: this.change_branch.shipment_number,
          shipment_status_id: parseInt(this.statusType),
          shipment_pay_type:
            this.statusType == 6 ? "origin_freight_fees" : this.rejectType,
          freight: this.statusType == 6 ? 0 : this.price,
          remark: this.remark,
          ////6 address error
          start_branch_id:
            this.statusType == 6
              ? this.change_branch.start_branch_id
              : this.change_branch.end_branch_id,
          receive_branch_id:
            this.statusType == 6
              ? this.change_branch.end_branch_id
              : this.change_branch.start_branch_id,
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.$emit("close");
            this.$emit("success");
            this.$notification.OpenNotification_EditItem_OnSuccess(
              "top-right",
              "dark",
              3000
            );
          }, 300);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            var obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
        });
    },
  },
};
</script>
<style lang="scss"></style>
