<template>
    <div class="container" :style="{marginTop: '5px'}">
        <div class="box-title">
            <h4>
                <i class="fal fa-box-full"></i>
                  ຂໍ້ມູນ ເເພ໋ກເເກ໋ດ
                <span class="countTotal">({{listPackages.length}})</span>
                <span class="btn-add">
                    <vs-button circle icon flat @click="OpenModalAdd()">
                        <i class="fal fa-plus-circle"></i>
                    </vs-button>
                </span>
            </h4>
        </div>
        <div class="center">
            <vs-table>
                <template #thead>
                <vs-tr>
                    <vs-th>ລຳດັບ</vs-th>
                    <vs-th>ເເພ໋ກເເກ໋ດ</vs-th>
                    <vs-th>ຂະໜາດພັດສະດຸ</vs-th>
                    <vs-th>ລາຄາຂົນສົ່ງ</vs-th>
                    <vs-th>ລາຄາກ່ອງ</vs-th>
                    <vs-th>ຮູບເເບບຂົນສົ່ງ</vs-th>
                    <vs-th>ປະເພດເເພ໋ດເເກ໋ດ</vs-th>
                    <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
                    <vs-th></vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                    :key="index" v-for="(packages, index) in listPackages"
                >
                    <vs-td>
                        {{ index + 1 }}
                    </vs-td>
                     <vs-td :style="{width: '230px'}">
                        {{ packages.name }}
                    </vs-td>
                    <vs-td>
                        {{ packages.sizeName }}
                    </vs-td>
                    <vs-td>
                        {{ FormatPrice(packages.price) }}
                    </vs-td>
                    <vs-td>
                        <span v-if="packages.packing_box !== 0">{{ FormatPrice(packages.packing_box) }}</span>
                        <span v-else>ບໍ່ມີເເກັດ</span>
                    </vs-td>
                    <vs-td>
                         <span v-if="packages.is_across == 'true'">
                            ຕ່າງເເຂວງ
                        </span>
                         <span v-if="packages.is_across == 'false'">
                            ພາຍໃນເເຂວງ
                        </span>
                    </vs-td>
                    <vs-td>
                        <span :class="{'text-danger': packages.typeName == 'Express'}">
                            ( {{packages.typeName}} )
                        </span>
                    </vs-td>
                    <vs-td>
                        <span v-if="packages.freight_type == 'origin'" :style="{color: 'green'}">( ຂົນສົ່ງຕົ້ນທາງ )</span>
                        <span v-if="packages.freight_type == 'destination'" :style="{color: '#195BFF'}">( ຂົນສົ່ງປາຍທາງ )</span>
                    </vs-td>
                    <vs-td style="text-align: right; width: 100px">
                        <div class="group-icon">
                            <vs-button circle icon flat danger @click="OpenModalClosed(packages.id)">
                                <i class="fal fa-times-circle"></i>
                            </vs-button>
                            <vs-button circle icon flat @click="OpenModalEdit(packages.id)">
                                <i class="fad fa-pencil-alt"></i>
                            </vs-button>
                            <vs-button circle danger icon flat @click="OpenModalDelete(packages.id)">
                                <i class="fad fa-trash-alt"></i>
                            </vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
                </template>
                <template #footer>
                    <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset" @paginate="FetchData()"></pagination>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>

        <ModalDelete>
            <template v-slot="{ close }">
                <DeletePackages @close="close" :packageId="packageId" @success="FetchData()"/>
            </template>
        </ModalDelete>

        <ModalClose>
             <template v-slot="{ close }">
                <ClosePackages @close="close" :packageId="packageId" @success="FetchData()"/>
            </template>
        </ModalClose>

    </div>
</template>
<script>
import DeletePackages from './Dialog/Delete';
import ClosePackages from './Dialog/Close';
import Loading from '../../Loading';
import pagination from '../../../Plugins/Paginations/Pagination';

export default {
    components:{
        DeletePackages,
        ClosePackages,
        Loading,
        pagination
    },
    data:()=>({
        active: 1,
        page: 1,
        max: 15,
        listPackages: [],  
        packageId: '',
        isLoading: true,
        filterPackage: {},

        // Pagination
        offset: 10,
        pagination: {},
        per_page: 10
    }),
    methods:{

        OpenModalAdd(){
            this.$router.push({name: 'add.packages'}).catch(()=>{});
        },
        OpenModalEdit(packageId){
            this.$router.push({name: 'edit.packages', params:{id: packageId}}).catch(()=>{});
        },
        FilterItem(packageId){
            return this.listPackages.filter(item=>{
                return item.id == packageId;
            })[0] || {};
        },
        OpenModalDelete(packageId){
            this.packageId = packageId;
            this.$store.commit('modalDelete_State', true);
        },
        OpenModalClosed(packageId){
            this.packageId = packageId;
            this.$store.commit('modalClose_State', true);
        },
        FetchData(){
            this.isLoading = true;
            this.$axios.get('list-packages/' + this.$route.params.type,{
                params:{
                    page: this.pagination.current_page,
                    per_page: this.per_page
                }
            }).then(res=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
                setTimeout(()=>{
                    this.listPackages = res.data.packageOnlines.data;
                    this.pagination = res.data.packageOnlines;
                }, 100);
            }).catch(()=>{
                setTimeout(() => {
                    this.isLoading = false;
                    this.$emit('close');
                }, 200);
            });
        },
        FormatPrice(num) {
            var p = num.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function(acc, num, i) {
                return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ກີບ";
        }
    },
    created(){
        this.FetchData();
    },
}
</script>
<style lang="scss" scoped>
.container{
    margin-top: 40px;
    .title-login{
       font-size: 20px;
       color: $primary-color;
    }
}
</style>