import { Validator } from 'vee-validate';

const validate_Promotion = {
    custom: {
        name:{
            required: '(ກະລຸນາປ້ອນໂປຮໂມຮຊັ່ນກ່ອນ...)',
        },
        discountType:{
            required: '(ກະລຸນາເລືອກປະເພດສ່ວນຫຼຸດ...)',
        },
        expired_date:{
            required: '(ກະລຸນາເລືອກວັນທີ່ໝົດອາຍຸກ່ອນ...)',
        },
        level:{
            required: '(ກະລຸນາເລືອກລະດັບກ່ອນ...)',
        }
    },
};

export const ValidatePromotions = (Vue) => {
    Validator.localize('en', validate_Promotion);
    Vue.$validator.localize('en', validate_Promotion);
}